import { ElementId, WordElement } from '@tikka/basic-types';
import {
  ChaatElementList,
  ChaatWordElement,
  EditorElementList,
  EditorWordElement,
} from './editorial-types';
import { CreateElementList } from '@tikka/elements/element-list';
import { WordRecordsData } from './editorial-types-aside';

type ChaatWordElementList = ChaatElementList<ChaatWordElement>;
type EditorWordElementList = EditorElementList<EditorWordElement>;

function _CreateEditorialWordElementList<
  ET extends WordElement,
  WRT extends NonNullable<WordRecordsData> | null = null
>({
  elements,
  episodeKey = '',
  wordRecordsData = null,
  idToIndexF,
}: {
  elements: ET[];
  wordRecordsData?: WRT;
  episodeKey?: string;
  idToIndexF?: (id: ElementId) => number;
}): any {
  // const wordList = new ElementList0(
  //   <FatElement[]>(<any>elements),
  //   episodeKey,
  //   <ElementList0>null
  // );

  const wordList = CreateElementList({ elements: elements, words: null });
  (wordList as any).setWordRecordsData(wordRecordsData, idToIndexF);
  return wordList;
}

export function getWordRecordsData(words0: any): WordRecordsData {
  const words = words0 as any;
  if (words.wordRecordsData) {
    return words.wordRecordsData;
  }
  throw Error('has no wordRecordsData');
}

export const CreateChaatEditorialWordElementList: <
  ET extends WordElement,
  WRT extends WordRecordsData = null
>({
  elements,
  episodeKey,
  wordRecordsData,
  idToIndexF,
}: {
  elements: ET[];
  wordRecordsData?: WRT;
  episodeKey?: string;
  idToIndexF?: (id: ElementId) => number;
}) => ChaatWordElementList = _CreateEditorialWordElementList as any;

export const CreateEditorEditorialWordElementList: <
  ET extends WordElement,
  WRT extends WordRecordsData = null
>({
  elements,
  episodeKey,
  wordRecordsData,
  idToIndexF,
}: {
  elements: ET[];
  wordRecordsData?: WRT;
  episodeKey?: string;
  idToIndexF?: (id: ElementId) => number;
}) => EditorWordElementList = _CreateEditorialWordElementList as any;
