import { epochSecondsFloat } from '@masala-lib/utils';
import { JobLogger, LogMessage, LogMessageMap } from './job-types';

import { createLogger } from '@app/logger';
const appLog = createLogger('job-logger');

export class JobLoggerImpl implements JobLogger {
  jobId: string;
  stageName: string;
  flushFn: (logMessage: LogMessage) => void; // function which incrementally writes to firebase
  messageMap: LogMessageMap;

  counter = 0;

  constructor({
    jobId,
    stageName,
    flushFn,
  }: {
    jobId: string;
    stageName: string;
    flushFn: (logMessage: LogMessage) => void;
  }) {
    this.jobId = jobId;
    this.stageName = stageName;
    this.flushFn = flushFn;
    this.messageMap = {};
  }

  log(level: string, ...args: any[]): void {
    const message = argsToString(...args);
    appLog.debug(`${this.jobId}-${this.stageName} ${level}: ${message}`);
    this.counter++;
    const timestamp = epochSecondsFloat() + this.counter / 1000000;
    const messageArray: string[] = [];
    messageArray.push(message.toString());
    const logMessage = {
      level,
      message,
      timestamp,
      counter: this.counter,
    };
    // this.logArray.push(logMessage);
    this.messageMap[this.counter] = logMessage;
    if (this.flushFn) {
      this.flushFn(logMessage);
    }
  }

  debug(...args: any[]) {
    this.log('DEBUG', ...args);
  }

  info(...args: any[]) {
    this.log('INFO', ...args);
  }

  warn(...args: any[]) {
    this.log('WARN', ...args);
  }

  error(...args: any[]) {
    this.log('ERROR', ...args);
  }

  // not sure yet if useful
  // filterLog(level: string) {
  //   const levels = this.levels.slice(this.levels.indexOf(level));
  //   const filtered = this.logArray.filter(message =>
  //     levels.includes(message.level)
  //   );
  //   return new Log(this.levels, filtered);
  // }
}

export function argsToString(...args: any[]): string {
  return args
    .map(arg => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)))
    .join(' ');
}
