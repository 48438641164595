import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ExcerptData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { ExcerptForm } from './excerpt-form';
import { useNavigate } from 'react-router-dom';

//
// beware, this file most likely isn't relevant
// soundbites are initially created via the script editor
//

export const ExcerptCreateScreen = observer(() => {
  const manager = ExcerptManager.getInstance();
  const navigate = useNavigate();
  const model = new Excerpt();

  const goBack = () => {
    navigate(`/excerpts`);
  };

  const onSubmit = (dto: ExcerptData) => {
    console.group('Creating excerpt');
    console.log('From data', dto);
    model.fromFormData(dto);
    manager.create(model).then(goBack);
    console.groupEnd();
  };

  return (
    <Page title={'Creating new excerpt'}>
      <OnePaneLayout>
        <ExcerptForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
          model={model}
        />
      </OnePaneLayout>
    </Page>
  );
});
