import { ScriptElement, ScriptElementKind } from '@masala-lib/llm/llm-types';

export type ElementStyleInfo = {
  prefix: string | ((s: ScriptElement) => string);
  styleName: string;
};

export const styleLookup: { [index in ScriptElementKind]?: ElementStyleInfo } =
  {
    CHAPTER_BREAK: {
      prefix: `CHAPTER ${String.fromCodePoint(0x21b5)}`,
      styleName: 'chapterBreak',
    },
    PASSAGE_BREAK: {
      // prefix: `PASSAGE ${String.fromCodePoint(0x21b5)}`,
      prefix: `PASSAGE `,
      styleName: 'passageBreak',
    },
    CHAPTER: {
      prefix: '# ', // TODO maybe function? should render ref number?
      styleName: 'chapter',
    },
    CHAPTER_SUMMARY: {
      prefix: '~ ',
      styleName: 'chapterSummary',
    },
    SPEAKER_LABEL: {
      prefix: '@', // TODO maybe function? should render ref number?
      styleName: 'speaker',
    },
    PASSAGE: {
      prefix: '## ', // TODO maybe function? should render ref number?
      styleName: 'passage',
    },
    PASSAGE_SUMMARY: {
      prefix: '~~ ',
      styleName: 'passageSummary',
    },
    SENTENCE: {
      prefix: '',
      styleName: 'sentence',
    },
    TRANSLATION: {
      prefix: '',
      styleName: 'translation',
    },
    VOCAB: {
      prefix: '',
      styleName: 'vocab', // TODO
    },
  };
