import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { FeaturedReleaseCreateScreen } from './featured-release-create-screen';
import { FeaturedReleaseDetailScreen } from './featured-release-detail-screen';
import { FeaturedReleaseEditScreen } from './featured-release-edit-screen';
import { FeaturedReleasesListScreen } from './featured-releases-list-screen';

export const FeaturedReleasesScreen = () => {
  return (
    <ModelScreen
      path={'/featured-releases'}
      renderList={() => <FeaturedReleasesListScreen />}
      renderDetail={controller => (
        <FeaturedReleaseDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <FeaturedReleaseEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <FeaturedReleaseCreateScreen />}
    />
  );
};
