import React from 'react';
import { observer } from 'mobx-react';
import { signOut } from '../../platform/firebase-auth';
import { scriptEditorModel } from '../models/app-root';
import { auth } from '../models/app-root';
import { openSidePopup } from '../../console/views/shared/side-popup';
import { HelpIcon } from '../../console/components/icons/help-icon';

export const SimpleStatusBar = observer(({ message }: { message: string }) => {
  const model = scriptEditorModel;
  const trackingMode = model.trackChanges;
  // const trackIndicatorStyle = trackingMode ? '' : 'strike';
  return (
    <div className={'status-bar'}>
      <div className="status-bar-element status-bar-chapter">{message}</div>
      <div style={{ display: 'inline-block', float: 'right' }}>
        {!model.writeAccessEnabled ? 'RO ' : null}
        {trackingMode ? 'Tr' : null}{' '}
        {/* <span className={'status-indicator tracking ' + trackIndicatorStyle}>T </span> */}
        [{auth.appUser?.alias}]{' '}
        {auth.authUser ? (
          <>
            <img
              style={{
                width: 35,
                height: 35,
                objectFit: 'cover',
                borderRadius: '50%',
                padding: 3,
              }}
              src={auth.authUser.photoURL}
              alt=""
            />
            <span
              style={{
                textDecoration: 'underline',
                marginLeft: 6,
                marginRight: 6,
              }}
              onClick={signOut}
            >
              (Sign-out)
            </span>
          </>
        ) : null}
        <span onClick={() => openSidePopup()}>
          <HelpIcon />
        </span>
      </div>
    </div>
  );
});
