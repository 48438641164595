/* eslint-disable jsx-a11y/anchor-has-content */
import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import ReactMarkdown from 'react-markdown';
import { Box } from 'rebass';
import { Auth } from '@masala-lib/editorial/db/auth';
import { useExcerpt } from './use-excerpt';
import { A, ActionLink } from '@masala-lib/editorial/ui/action-link';
import { NavLink } from 'react-router-dom';
import {
  AnswerMarkdown,
  QuestionMarkdown,
} from '@naan/primitives/text/soundbite-question-markdown';
import { VocablistMarkdown } from '@naan/primitives/text/soundbite-vocablist-markdown';
import { ExcerptImport } from './excerpt-import';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { styled } from '@naan/stitches.config';
import { loadScriptEpisodeData } from '@masala-lib/editorial/db/loader-funcs';
import {
  Excerpt as ExcerptElementType,
  WordGroupContent,
} from '@masala-lib/editorial-types';
import { IndexRange } from '@tikka/basic-types';
import { getWordGroupUsageText } from '@masala-lib/content-utils';
import { sleep } from '@utils/util';

import { markdownListStyles } from '@naan/global-styles';

const AnchorSpacingWrapper = styled('div', {
  height: '2.5em',
  fontWeight: 'bold',
});

const VocabularyBox = styled('div', {
  $$radius: '12px',
  $$maxWidth: '360px',
  backgroundColor: '$white',
  borderRadius: '$$radius',
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  width: 'min(calc(100% - 32px), $$maxWidth)',
  padding: 16,
  // maxWidth: 'fit-content',
  // maxHeight: '85vh',
});

const AnswerBox = styled('div', {
  $$backgroundColor: '$colors$green-200',
  background: '$$backgroundColor',
  padding: 16,
  width: 'min(calc(100% - 32px), 600px)',
  borderRadius: 12,

  '& .heading': {
    unset: 'all',
    textStyle: 'small-heading',
    color: '$colors$green-700',
    marginBottom: 16,
  },

  ...markdownListStyles,
});

const PromptBox = styled('div', {
  background: '$colors$white',
  padding: '16px 16px 4px 16px',
  transition: 'background-color 0.3s ease-out',

  '& > .heading': {
    unset: 'all',
    textStyle: 'small-heading',
    color: '$colors$orange-500',
    marginBottom: 16,
    mixBlendMode: 'multiply',
  },

  '@widerThanScript': {
    maxWidth: 600,
    margin: '16px 0 0',
    borderRadius: 12,
  },
});

interface ExcerptDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

async function getExcerptVocabMarkdown(excerpt: Excerpt): Promise<string> {
  const unitId = excerpt.unitId;
  const episodeData = await loadScriptEpisodeData(unitId);
  const content = episodeData.defaultContent;
  const excerptElement = content.getElement(
    excerpt.data.elementId
  ) as ExcerptElementType;
  const sentences = content.filterByKind('SENTENCE');
  const beginSentence = sentences.getElementContainingWordId(
    excerptElement.anchor.wordId
  );
  const endSentence = sentences.getElementContainingWordId(
    excerptElement.endAnchor.wordId
  );
  const regionRange: IndexRange = {
    begin: beginSentence.address,
    end: endSentence.endAddress,
  };
  const wordGroupElements = content.filterByKind('WORD_GROUP');
  const vocabElements = wordGroupElements.filterBySubKind('VOCAB');
  const regionVocabs =
    vocabElements.getElementsStartWithinWordIndexRange(regionRange);
  const translations = episodeData.translations;
  let result = '';
  for (const vocab of regionVocabs) {
    let headword = vocab.content.canonical;
    if (!headword) {
      headword = getWordGroupUsageText(vocab, content.words);
    }
    const definition = (translations[vocab.id].content as WordGroupContent)
      .note;
    result += `# ${headword}\n${definition}\n\n`;
  }
  return result;
}

async function copyTextToClipboard(text: string): Promise<void> {
  const permission = await (navigator.permissions as any).query({
    name: 'clipboard-write',
  });
  await navigator.clipboard.writeText(text);
}

async function copyExcerptVocabToClipboard(excerpt: Excerpt) {
  const markdown = await getExcerptVocabMarkdown(excerpt);
  await copyTextToClipboard(markdown);
  window.alert(
    'vocab of excerpt in script converted to markdown and copied to clipboard'
  );
}

export const ExcerptDetailScreen = observer(
  ({ closeDetail, startEditing }: ExcerptDetailsParams) => {
    const model = useExcerpt();
    const auth = Auth.getInstance();

    const handleIngest = async () => {
      try {
        await model.ingest();
      } catch (error) {
        model.clearPendingOperation();
        window.alert(error);
        throw error;
      }
    };

    if (!model) {
      return null;
    }

    return (
      <Page
        title={`${model.name} ${model.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.can('manage_excerpts') ? (
                <>
                  <button onClick={startEditing} className="primary-action">
                    Edit Soundbite
                  </button>
                  <button onClick={handleIngest}>Ingest Soundbite</button>
                </>
              ) : null}
              {model.soundbitePreviewUrl ? (
                <A href={model.soundbitePreviewUrl}>Preview</A>
              ) : null}
              <DeleteActions model={model} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <AnchorSpacingWrapper>
            <a href={'#main_section'}> Jump to main section</a>
          </AnchorSpacingWrapper>
          <Stack>
            <IngestionStatus model={model} />
            <Card>
              <DataItem
                label={'Operation status'}
                value={model.operationStatus}
              />
              <DataItem label={'Workflow name'} value={model.data.name} />
              <DataItem label={'Slug'} value={model.data.slug} />
              {/* <DataItem label={'Id'} value={model.id} /> */}
              {model.unit ? (
                <DataItem
                  label="Unit"
                  value={
                    <>
                      {model.unit.name}
                      {' - '}
                      <A href={model.scriptEditorUrl}>Script Editor^</A>
                      {' - '}
                      <NavLink to={model.unit.navPath}>Detail Screen</NavLink>
                    </>
                  }
                />
              ) : null}
              {model.volume ? (
                <DataItem
                  label="Volume"
                  value={
                    <>
                      {model.volume.name}
                      {' - '}
                      <NavLink to={model.volume.navPath}>Detail Screen</NavLink>
                    </>
                  }
                />
              ) : null}
              <DataItem label={'Element id'} value={model.data.elementId} />
              <DataItem label={'Kind'} value={model.data.excerptKind} />
              <DataItem label={'Status'} value={model.data.status} />

              {model.data.sourceDocUrl ? (
                <DataItem
                  label={'Google Doc URL'}
                  value={
                    <A href={model.data.sourceDocUrl}>
                      {model.data.sourceDocUrl}
                    </A>
                  }
                />
              ) : null}
              <DataItem
                // label={'Export raw script'}
                value={
                  <NavLink to={model.dumpScriptPath}>
                    [generate raw script]
                  </NavLink>
                }
              />
              <DataItem
                label={'Linear URL'}
                value={
                  <A href={model.data.linearUrl}>{model.data.linearUrl}</A>
                }
              />
              <DataItem
                label={'Internal notes'}
                value={
                  <Box py={3}>
                    <ReactMarkdown>{model.data.internalNotes}</ReactMarkdown>
                  </Box>
                }
              />
              <DataItem label={'Difficulty'} value={model.data.difficulty} />
              <DataItem label={'Categories'} value={model.data.categories} />
              <DataItem
                label={'Generate Script'}
                value={<NavLink to={model.dumpScriptPath}>[link]</NavLink>}
              />
            </Card>
            <a id={'main_section'} />
            <Card>
              <DataItem label={'Title'} value={model.data.title} />
              {model.volume.l1 !== 'en' ? (
                <DataItem
                  label={'English Prompt (Internal)'}
                  value={
                    <PromptBox>
                      <h2 className="heading ">Question</h2>
                      <QuestionMarkdown source={model.data.promptEn} />
                    </PromptBox>
                  }
                />
              ) : null}
              <DataItem
                label={`L1 [${model.volume.l1}] Prompt (User facing)`}
                value={
                  <PromptBox>
                    <h2 className="heading ">Question</h2>
                    <QuestionMarkdown source={model.data.prompt} />
                  </PromptBox>
                }
              />

              <AnchorSpacingWrapper>
                <ActionLink onPress={() => copyExcerptVocabToClipboard(model)}>
                  Click to copy script vocab to clipboard...
                </ActionLink>
              </AnchorSpacingWrapper>
              <DataItem
                label={'Vocabulary'}
                value={
                  <VocabularyBox>
                    <VocablistMarkdown source={model.data.vocabulary} />
                  </VocabularyBox>
                }
              />
              {model.volume.l1 !== 'en' ? (
                <DataItem
                  label={'English Explanation (Internal)'}
                  value={
                    <AnswerBox>
                      <h2 className="heading">Answer</h2>
                      <AnswerMarkdown source={model.data.explanationEn} />
                    </AnswerBox>
                  }
                />
              ) : null}
              <DataItem
                label={`L1 [${model.volume.l1}] Explanation (User facing)`}
                value={
                  <AnswerBox>
                    <h2 className="heading">Answer</h2>
                    <AnswerMarkdown source={model.data.explanation} />
                  </AnswerBox>
                }
              />
            </Card>
            <Card>
              <DataItem
                label={'Data URL'}
                value={<A href={model.data.dataUrl}>{model.data.dataUrl}</A>}
              />
              <DataItem
                label={'Debug Data Screen'}
                value={
                  <NavLink to={`/excerpts/${model.id}/data`}>[link]</NavLink>
                }
              />
            </Card>

            <ExcerptImport model={model} />
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);

export const IngestionStatus = observer(({ model }: { model: Excerpt }) => {
  // const user = Auth.getInstance().appUser;
  // const lockedForMe = !excerpt.hasWriteAccess(user);

  const channel = model.channel;

  const handleIngestAndPreview = async () => {
    try {
      await model.ingest();
      // JRW give time for soundbitePreviewUrl to become current via firestore listen
      await sleep(3000);
      window.open(model.soundbitePreviewUrl);
    } catch (error) {
      model.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const handlePublishReviewCatalog = async () => {
    try {
      await channel.publishCaliReviewCatalog();
    } catch (error) {
      channel.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  // const auth = Auth.getInstance();
  // const writable = volume.hasWriteAccess(auth.appUser);

  // const reviewVersion = volume.data.reviewVersion;
  // const stagedVersion = volume.data.stagedVersion;

  return (
    <Card header="Ingestion status">
      {/* <strong>Review version</strong> */}
      {/* <br /> */}
      {model.ingestionAgePretty || 'n/a'} (v
      {model.data.ingestVersion}) &mdash;{' '}
      <A href={model.data.dataUrl}>raw data url</A>
      <br />
      <A href={model.soundbitePreviewUrl}>[Soundbite preview]</A> &mdash;{' '}
      <ActionLink onPress={handleIngestAndPreview}>
        [Ingest and preview]
      </ActionLink>{' '}
      {/* &mdash;{' '} */}
      {/* <ActionLink onPress={handleIngestAndPublish}>
        [Ingest and update review catalog]
      </ActionLink> */}
      <br />
      Review catalog: <i>{channel.reviewCatalogSlug}</i> &mdash;{' '}
      {channel.reviewPublishAgePretty} (v
      {channel.data.reviewCaliVersion?.versionNumber || ' n/a'})
      {model.isCatalogStale ? (
        <>
          {' '}
          &mdash;{' '}
          <ActionLink onPress={handlePublishReviewCatalog}>
            [Publish review catalog]
          </ActionLink>
        </>
      ) : null}
      <br />
    </Card>
  );
});
