import * as React from 'react';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('img', {
  $$avatarSize: '48px',
  width: '$$avatarSize',
  borderRadius: '$space$2',
  variants: {
    square: {
      true: {
        height: '$$avatarSize',
        objectFit: 'cover',
      },
    },
  },
});

type AvatarProps = {
  src: string;
  size?: string;
} & React.ComponentProps<typeof Wrapper>;

export const Avatar = ({ src, size = '48px', ...props }: AvatarProps) => {
  return (
    <Wrapper
      css={{ $$avatarSize: size }}
      src={src}
      alt="avatar"
      {...props}
    ></Wrapper>
  );
};
