import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { useUnit } from './use-unit';
import { CreditsForm } from '../volumes/credits-form';
import { useNavigate } from 'react-router-dom';

export const CreditsEditScreen = observer(() => {
  const unit = useUnit();
  const navigate = useNavigate();

  if (!unit) {
    return null;
  }

  const formData = unit.volume.data.creditsData;

  const goBack = () => {
    navigate(unit.navPath);
  };

  const onSubmit = (dto: any) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    unit.volume.updatePartial({ creditsData: dto }).then(goBack);
  };

  return (
    <Page
      title={`Editing ${unit.name} - Credits (volume shared)`}
      onClose={goBack}
    >
      <CreditsForm
        dto={formData}
        onSubmit={onSubmit}
        cancelNavPath={unit.navPath}
      />
    </Page>
  );
});
