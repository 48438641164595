import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { UnitForm } from './unit-form';
import { UnitData } from '@masala-lib/catalog/catalog-types';

import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
import { Unit } from '@masala-lib/catalog/models/unit';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';

export const UnitCreateScreen = observer(() => {
  const unitManager = UnitManager.getInstance();
  const volumeManager = VolumeManager.getInstance();
  const location = useLocation();
  const navigate = useNavigate();
  const { volume: volumeId } = getQueryParamsFromLocation(location);
  const volume = volumeManager.getById(volumeId);
  // const flavor = volume?.data?.flavor;
  const l1Code = volume?.l1;
  const l2Code = volume?.l2;
  const unit = new Unit({ id: '', volumeId, /*flavor,*/ l1Code, l2Code });

  // TODO: default l1Code and l2Code from volume's channel metadata

  if (!unit) {
    return null;
  }

  const goBack = () => {
    // history.push(`/units/?volume=${volumeId}`);
    navigate(`/volumes/${volumeId}`);
  };

  const onSubmit = (dto: UnitData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    unit.fromFormData(dto);
    unitManager.create(unit).then(goBack);
  };

  return (
    <Page title={'New Unit'}>
      <UnitForm dto={unit.toFormData()} onSubmit={onSubmit} onCancel={goBack} />
    </Page>
  );
});
