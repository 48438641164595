import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Channel } from '@masala-lib/catalog/models/channel';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { ChannelsTable } from './channels-table';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

export const ChannelListScreen = observer(() => {
  const auth = Auth.getInstance();
  const manager = ChannelManager.getInstance();
  const list: Channel[] = manager.sortedList;

  return (
    <Page
      title="Manage Channels"
      controls={
        <PageActions>
          {auth.can('manage_channels') ? (
            <Link to={'/channels/new'} className="primary-action">
              Create new channel
            </Link>
          ) : null}
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <ChannelsTable channels={list} />
      </OnePaneLayout>
    </Page>
  );
});
