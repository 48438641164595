import { styled } from '@naan/stitches.config';

export const SidebarItem = styled('div', {
  $$backgroundColor: '$colors$transparent',
  $$headColor: '$colors$blue-600',
  $$metaColor: '$colors$textSecondary',
  $$iconColor: '$colors$blue-500',

  position: 'relative',
  overflow: 'hidden',
  cursor: 'default',
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 6,
  margin: '0 8px',
  backgroundColor: '$$backgroundColor',
  transition: 'background-color 0.16s ease-in-out, color 0.12s ease-in-out',
  '& .contents': {
    padding: '12px 18px',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    '& .head': {
      fontWeight: 500,
      color: '$$headColor',
      // truncate content
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(300px - 84px)',
    },
    '& .logo': {
      marginLeft: -8,
    },
  },

  '& .loading-indicator': {
    background: '$$backgroundColor',
    position: 'absolute',
    paddingRight: 8,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$$iconColor',
    opacity: 0,
    transition: 'opacity 0.23s ease-in-out, transform 0.12s ease-in-out',
    transform: 'translateX(100%)',

    '& svg': {
      width: 16,
      height: 16,
    },
  },

  '& .hover-controls': {
    position: 'absolute',
    // background: 'transparent',
    // background: 'blue',
    paddingRight: 4,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.16s ease-in-out',
    // display: 'none',
  },

  '&:hover': {
    $$backgroundColor: '$colors$blue-100',
    $$headColor: '$colors$blue-900',
    $$metaColor: '$colors$blue-500',
    '& .hover-controls': {
      opacity: 1,
    },
  },

  variants: {
    selected: {
      true: {
        $$backgroundColor: '$colors$blue-400',
        $$headColor: '$colors$blue-50',
        $$metaColor: '$colors$white-alpha-50',
        $$iconColor: '$colors$blue-100',
        '&:hover': {
          $$backgroundColor: '$colors$blue-400',
          $$headColor: '$colors$blue-50',
          $$metaColor: '$colors$white-alpha-50',
        },
      },
    },
    // used for both prior and forward
    thread: {
      true: {
        // paddingLeft: 12,
        $$backgroundColor: '$colors$blue-100',
        '&:hover': {
          $$backgroundColor: '$colors$blue-200',
        },
      },
    },
    // the 'fork' concept might be provide more confusion than value
    // fork: {
    //   true: {
    //     $$backgroundColor: '$colors$blue-150',
    //     '&:hover': {
    //       $$backgroundColor: '$colors$blue-400',
    //     },
    //   },
    // },

    loading: {
      true: {
        '& .loading-indicator': {
          opacity: 1,
          transform: 'translateX(0%)',
        },
      },
    },

    suppressed: {
      true: {
        '& .head': {
          textDecoration: 'line-through',
          opacity: 0.6,
        },
        '& .meta': {
          textDecoration: 'line-through',
        },
      },
    },
    terminal: {
      true: {
        border: '1px solid $colors$red-200',
      },
    },
  },
});
