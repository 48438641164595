import { observer } from 'mobx-react';
import { pick } from 'lodash';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { Volume } from '@masala-lib/catalog/models/volume';
import { NavLink } from 'react-router-dom';
import { DataCard } from '../../components/data-display/data-card';
import { Card } from '../../components/data-display/card';
import { Stack } from '../shared/stack';
import { UnitsTable } from '../units/units-table';
import { OnePaneLayout } from '../shared/layouts';
import { A, ActionLink } from '@masala-lib/editorial/ui/action-link';
import { SpeakersTable } from './speakers-table';
import { DataItem } from '../../components/data-display/data-item';
import { VolumeTagEditor } from './volume-tag-editor';
import { Auth } from '@masala-lib/editorial/db/auth';
import {
  VolumeIngestionVersion,
  WorkflowStatus,
} from '@masala-lib/catalog/catalog-types';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import { VolumeImport } from './volume-import';
import { ExcerptsTable } from '../excerpts/excerpts-table';
import { AssetCard } from '../units/unit-general-info';
import { VolumeFork } from './volume-fork';

interface VolumeDetailProps {
  model: Volume;
  manager: VolumeManager;
  unselect: () => void;
}
export const VolumeDetail = observer(({ model: volume }: VolumeDetailProps) => {
  // const handleDelete = () => {
  //   volume.destroy().then(unselect);
  // };

  const speakers = volume.data.speakers || [];
  const forkParentVolumeId = volume.forkParentId;

  return (
    <OnePaneLayout>
      <Stack>
        <IngestionStatus volume={volume} />
        <DataCard
          header={volume.name}
          data={{
            ...pick(volume, [
              'slug',
              // 'clientSlug',
              'forkParentId',
              'operationStatus',
              'totalDurationMinutes',
            ]),
            ...pick(volume.data.infoV5, [
              'titleL2',
              'taglineL1',
              'descriptionL1',
            ]),
            ...pick(volume.data, ['flavor']),
            linear: volume.data.linearUrl ? (
              <A href={volume.data.linearUrl} />
            ) : null,
            channel: volume.channel ? (
              <NavLink to={volume.channel.navPath}>
                {volume.channel.name}
              </NavLink>
            ) : null,
            forkParent: forkParentVolumeId ? (
              <NavLink to={`/volumes/${forkParentVolumeId}`}>
                {forkParentVolumeId}
              </NavLink>
            ) : null,
          }}
        />
        <AssetCard prefixes={['imageThumb']} model={volume} />

        <Card header={<NavLink to={volume.unitsNavPath}>Units</NavLink>}>
          <UnitsTable
            units={volume.units}
            swapUnitNumber={(unit, moveUp) => {
              volume.swapUnitNumber(unit, moveUp);
            }}
          />
        </Card>

        <Card
          header={<NavLink to={volume.excerptsNavPath}>Soundbites</NavLink>}
        >
          <ExcerptsTable list={volume.excerpts} />
        </Card>

        <TagsCard
          volume={volume}
          editable={Auth.getInstance().can('edit_items')}
        />

        <Card header={<NavLink to={volume.speakersNavPath}>Speakers</NavLink>}>
          <SpeakersTable volume={volume} />
        </Card>

        {forkParentVolumeId ? null : (
          <Card header="Fork">
            <VolumeFork model={volume} />
          </Card>
        )}

        <Card header="Debug">
          Volume catalog data:{' '}
          <NavLink to={`/volumes/${volume.id}/catalog-data/${volume.l1}`}>
            [cached]
          </NavLink>
          {' - '}
          <NavLink to={`/volumes/${volume.id}/catalog-data/${volume.l1}?gen=t`}>
            [render]
          </NavLink>
          <br />
          Volume detail data:{' '}
          {!!volume.data.reviewVersion?.dataUrl ? (
            <>
              <A href={volume.data.reviewVersion?.dataUrl}>
                [ingested - v{volume.data.reviewVersion.versionNumber}]
              </A>
              {' - '}
            </>
          ) : null}
          <NavLink
            to={`/volumes/${volume.id}/catalog-data/${volume.l1}?detail=t`}
          >
            [render]
          </NavLink>
          <br />
          <VolumeExportManagement volume={volume} />
          {/* <ActionLink onPress={() => volume.jwnextIngest()}>
            [Ingest story detail screen data]
          </ActionLink> */}
          {/* <ActionLink onPress={() => volume.jwnextIngest()}>
            [Ingest volume and its units (jwnext)]
          </ActionLink>
          <br /> */}
        </Card>

        {/* <Card
        footer={
          isNew ? null : (
            <>
              <ActionLink onPress={handleDelete}>[DELETE volume]</ActionLink> (and{' '}
              {model.units.length} unit(s))
            </>
          )
        }
      >
        Danger
      </Card> */}
      </Stack>
    </OnePaneLayout>
  );
});

export const IngestionStatus = observer(({ volume }: { volume: Volume }) => {
  const user = Auth.getInstance().appUser;
  const lockedForMe = !volume.hasWriteAccess(user);

  // TODO
  // let internalActions = null;
  // if (volume.showPromoteInternalToStaged()) {
  //   const handlePromoteToStaged = () => {
  //     if (window.confirm('Are you sure?')) {
  //       volume.promoteInternalToStaged();
  //     }
  //   };
  //   if (!lockedForMe && user.isAdmin) {
  //     internalActions = (
  //       <ActionLink onPress={handlePromoteToStaged}>
  //         [Stage for public release]
  //       </ActionLink>
  //     );
  //   } else {
  //     internalActions = <strong>"stage for public release" pending</strong>;
  //   }
  // }

  const handleIngestAndPreview = async () => {
    try {
      await volume.invokeCaliIngest({ autoPublish: false });
      window.open(volume.caliPreviewUrl);
    } catch (error) {
      volume.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const handleIngestAndPublish = async () => {
    try {
      await volume.invokeCaliIngest({ autoPublish: true });
    } catch (error) {
      volume.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const handlePublishReviewCatalog = async () => {
    try {
      await volume.channel.publishCaliReviewCatalog();
    } catch (error) {
      volume.channel.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const promoteToStaged = async () => {
    try {
      await volume.promoteCaliReviewToStaged({ autoPublish: true });
    } catch (error) {
      // volume.channel.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const auth = Auth.getInstance();
  const writable = volume.hasWriteAccess(auth.appUser);

  const channel = volume.channel;
  const reviewVersion = volume.data.reviewVersion;
  const stagedVersion = volume.isStagedReady ? volume.data.stagedVersion : null;

  return (
    <Card header="Web player (cali) ingestion status">
      <strong>Review version</strong>
      <br />
      {reviewVersion ? (
        <>
          {volume.reviewIngestionAgePretty || 'n/a'} (v
          {reviewVersion.versionNumber}) &mdash;{' '}
          <A href={reviewVersion.dataUrl}>raw data url</A>
          <br />
          <A href={volume.caliPreviewUrl}>[Web player preview]</A> &mdash;{' '}
        </>
      ) : null}
      {volume.canIngest && writable ? (
        <>
          <ActionLink onPress={handleIngestAndPreview}>
            [Ingest and preview]
          </ActionLink>{' '}
          &mdash;{' '}
          <ActionLink onPress={handleIngestAndPublish}>
            [Ingest and update review catalog]
          </ActionLink>
        </>
      ) : null}
      <br />
      {reviewVersion ? (
        <>
          Review catalog: <i>{channel.reviewCatalogSlug}</i> &mdash;{' '}
          {channel.reviewPublishAgePretty} (v
          {channel.data.reviewCaliVersion?.versionNumber || ' n/a'})
          {volume.isReviewCatalogStale ? (
            <>
              {' '}
              &mdash;{' '}
              <ActionLink onPress={handlePublishReviewCatalog}>
                [Publish review catalog]
              </ActionLink>
            </>
          ) : null}
          <br />
        </>
      ) : null}
      {writable &&
      reviewVersion?.versionNumber !== stagedVersion?.versionNumber ? (
        <>
          <ActionLink onPress={promoteToStaged}>
            [Promote v{reviewVersion.versionNumber} to staged]
          </ActionLink>
          <br />
        </>
      ) : null}
      {stagedVersion ? (
        <>
          <br />
          <strong>Staged version</strong>
          <br />
          {volume.stagedIngestionAgePretty} (v{stagedVersion.versionNumber})
          <br />
          <A href={volume.caliStagedPreviewUrl}>[Web player preview]</A>
          <br />
          Staged catalog: <i>{channel.stagedCatalogSlug}</i> &mdash;{' '}
          {channel.stagedPublishAgePretty} (v
          {channel.data.stagedCaliVersion?.versionNumber})
        </>
      ) : null}
      {/* <br />
      Staged catalog: v{
        channel.data.stagedCaliVersion?.versionNumber
      } &mdash; {channel.stagedPublishAgePretty || 'n/a'} &mdash;{' '}
      {volume.canIngest ? (
        <>
          <ActionLink onPress={handlePublishStaged}>
            [Republish staged catalog]
          </ActionLink>
        </>
      ) : null} */}
      {/* <DataItem
        label="Review version"
        value={
          <>
            <A href={reviewVersion.dataUrl}>raw data url</A> (v
            {reviewVersion.versionNumber}, {reviewVersion.ingestedAt}
            <br />
            <A href={volume.caliPreviewUrl}>[Web player preview]</A>
            {/* <VersionInfo
            data={volume.data.reviewVersion}
            actions={internalActions}
            volume={volume}
            level="review"
            locked={lockedForMe}
          />
          </>
        }
      /> */}
      {/* TODO <DataItem
          label="Staged version"
          value={
            <VersionInfo
              data={volume.data.stagedVersion}
              // actions={stagedActions}
              volume={volume}
              level="staged"
              locked={lockedForMe || !user.isAdmin}
            />
          }
        />
        <DataItem
          label="Live version"
          value={
            <VersionInfo
              data={volume.data.liveVersion}
              volume={volume}
              level="live"
              locked={true}
            />
          }
        /> */}
    </Card>
  );
});

export const VersionInfo = observer(
  ({
    data,
    volume,
    level,
    actions,
    locked,
  }: {
    data: VolumeIngestionVersion;
    volume: Volume;
    level: string; // review, staged, live
    actions?: React.ReactNode;
    locked: boolean; // no write access
  }) => {
    if (data) {
      // const publishHandler = () => unit.publishCatalogLevel(level);
      //TODO const stale = level === 'review' && volume.ingestionNeeded;

      return (
        <>
          <A href={data.dataUrl}>raw data url</A> (v
          {data.versionNumber}, {data.ingestedAt}
          <br />
          <A href={volume.caliPreviewUrl}>[Web player preview]</A>
          {/*uiTimeString(data.ingestedAt)*/}
          {/*TODO {stale ? ', stale ingestion' : null} */}
          {/*TODO {data.pendingPublish && !stale ? (
            <>
              {', '}
              publish pending
            </>
          ) : null}
          {actions ? <> - {actions}</> : null} */}
        </>
      );
    } else {
      return <>{actions}</>;
    }
  }
);

export const TagsCard = ({
  volume,
  editable = false,
}: {
  volume: Volume;
  editable?: boolean;
}) => (
  <Card>
    <DataItem label="Topics">
      <VolumeTagEditor
        volume={volume}
        tagType="topic"
        editable={editable}
      ></VolumeTagEditor>
    </DataItem>
    <DataItem label="Countries">
      <VolumeTagEditor
        volume={volume}
        tagType="country"
        editable={editable}
      ></VolumeTagEditor>
    </DataItem>
    <DataItem label="IB Tags">
      <VolumeTagEditor
        volume={volume}
        tagType="ib"
        editable={editable}
      ></VolumeTagEditor>
    </DataItem>
    <DataItem label="AP Tags">
      <VolumeTagEditor
        volume={volume}
        tagType="ap"
        editable={editable}
      ></VolumeTagEditor>
    </DataItem>
  </Card>
);

export const VolumeExportManagement = observer(
  ({ volume }: { volume: Volume }) => {
    const handleExportVolume = async () => {
      try {
        await volume.invokeExport();
      } catch (error) {
        volume.clearPendingOperation();
        window.alert(error);
        throw error;
      }
    };

    return (
      <>
        <ActionLink onPress={handleExportVolume}>
          [Generate new backup]
        </ActionLink>
        <br />
        {volume.data.exportedUrl ? (
          <>
            - <A href={volume.data.exportedUrl}>latest backup data</A> (
            {uiTimeString(volume.data.exportedAt)})
            <br />
          </>
        ) : null}
        <VolumeImport model={volume} />
      </>
    );
  }
);
