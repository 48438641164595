import { isEmpty, omit, pick, sortBy } from 'lodash';
import { makeObservable, runInAction } from 'mobx';
import {
  CatalogEntityKind,
  CatalogStage,
  ClientSpeakerData,
  ExcerptData,
  // MetadataFlavor,
  SpeakerData,
  VolumeData,
  VolumeIngestionVersion,
  WorkflowStatus,
} from '../catalog-types';
import { Channel } from './channel';
import { Unit } from './unit';
import { notEmptyOrNA, slugify } from '../../misc/editorial-string-utils';
import {
  CatalogEntity,
  EntityManager,
  ID_LENGTH,
} from '../db/catalog-entity-manager';
import { BaseCatalogEntity, NEW_MODEL_PATH_ID } from './base-catalog-entity';
import { /*CastV3Data,*/ SpeakerV5Data } from '../bogota/bogota-types';
// import { buildCatalogVolumeData } from '../bogota/bogota-player-data';
import {
  ageMinutes,
  agePretty,
  epochSecondsFloat,
  hasDirtyIdChars,
  invariant,
  randomSlug,
  randomString,
} from '../../utils';
import { deploymentConfig, volumePreviewL2Url } from '../../deployment-config';
import { TagType } from './tag';
import { LocaleCode } from '@utils/util-types';
import { TagManager } from '../db/tag-manager';
import { ChannelManager } from '../db/channel-manager';
import { VolumeManager } from '../db/volume-manager';
import { UnitManager } from '../db/unit-manager';
import {
  computeTrimmedLocaleTranslationVersion,
  consoleAddAuditLogMessage,
  getUnitExportData,
  importUnitData,
} from '../../editorial/db/mutation-actions';
import { L12String, LString } from '@utils/util-types';
import { CatalogCollections, docReference } from '../db/catalog-db-paths';
import { AppUser } from '@masala-lib/editorial/models/user-manager';
import {
  VolumeCaliDataBuilder,
  VolumeDataFlavor,
} from '../cali/volume-cali-data-builder';
import { fetchJson, swapArrayElements } from '@utils/util';
import { createLogger } from '@app/logger';
import { Excerpt } from './excerpt';
import {
  ActivityGuideV3Data,
  VolumeCaliData,
} from '../cali/cali-catalog-types';
import { ExcerptManager } from '../db/excerpt-manager';
import { UnitCrud } from '../db/unit-crud';
import { ChannelCrud } from '../db/channel-crud';
import { EntityCrud } from '../db/entity-crud';
import { VolumeCrud } from '../db/volume-crud';
import { FORKED_UNIT_SEPARATOR } from '@masala-lib/editorial/db/db';
import { EpisodeTranslationDoc } from '@masala-lib/editorial/db/firestore-doc-types';
import { MutationActionsServer } from '@masala-lib/editorial/db/mutation-actions-server';
import { StructuralWriteDto } from '@masala-lib/editorial-types';
import { loadScriptEpisodeData } from '@masala-lib/editorial/db/loader-funcs';
import { ETOf } from '@masala-lib/editor-aliases';
import { A } from '@masala-lib/editorial/ui/action-link';

const log = createLogger('volume');

export const emptyVolumeData: VolumeData = {
  kind: CatalogEntityKind.VOLUME,
  id: '',
  name: '',
  slug: '',
  // channelId: null as string,
} as VolumeData;

export class Volume
  extends BaseCatalogEntity<VolumeData>
  implements CatalogEntity<VolumeData>
{
  // used with 'load solo' mode to avoid dependency on singleton channel manager
  fetchedChannel: Channel;
  fetchedUnits: Unit[]; // prefetched units used by server-side operations
  fetchedMasterVolume: Volume; // for a fork, this points to the master story. used to resolve the clientSlug during catalog ingestion

  constructor(d?: Partial<VolumeData>) {
    super(d as VolumeData);
    this.data = { ...emptyVolumeData, ...d };
    makeObservable(this);
  }

  get channelId() {
    return this.data.channelId;
  }

  get channel(): Channel {
    if (this.fetchedChannel) {
      return this.fetchedChannel;
    }
    return ChannelManager.getInstance().getById(this.channelId);
  }

  async fetchChannel(): Promise<Channel> {
    if (!this.fetchedChannel) {
      this.fetchedChannel = await ChannelCrud.loadById(this.channelId, {});
    }
    return this.fetchedChannel;
  }

  async fetchMasterVolume(): Promise<Volume> {
    if (this.forkParentId) {
      if (!this.fetchedMasterVolume) {
        this.fetchedMasterVolume = await VolumeCrud.loadById(
          this.forkParentId,
          {}
        );
      }
      return this.fetchedMasterVolume;
    } else {
      return null;
    }
  }

  async resolveMatchedExcerpt(forkedExcerpt: Excerpt): Promise<Excerpt> {
    const excerpts = await this.fetchExcerpts();
    const matched = excerpts.find(
      excerpt => (excerpt.data.elementId = forkedExcerpt.data.elementId)
    );
    return matched;
  }

  // @computed
  get units(): Unit[] {
    if (this.fetchedUnits) {
      return this.fetchedUnits;
    }
    return sortBy(
      UnitManager.getInstance().byVolume(this.id),
      _ => _.data.unitNumber
    );
  }

  async fetchUnits(): Promise<Unit[]> {
    if (!this.fetchedUnits) {
      this.fetchedUnits = await UnitCrud.loadAllForVolume(this);
    }
    return this.fetchedUnits;
  }

  async fetchExcerpts(): Promise<Excerpt[]> {
    const result: Excerpt[] = [];
    const units = await this.fetchUnits();
    for (const unit of units) {
      const unitExcerpts = await unit.fetchExcerpts();
      result.push(...unitExcerpts);
    }
    return result;
  }

  // unnecessary, but helps in finding usages
  get slug(): string {
    return this.data.slug;
  }

  get clientSlug(): string {
    // use the master story slug if forked
    // if fork, then use master slug
    if (this.forkParentId) {
      if (this.fetchedMasterVolume) {
        return this.fetchedMasterVolume.clientSlug;
      } else {
        const message = `volume(${this.slug}): attempt to access clientSlug before master volume fetched`;
        log.error(message);
        return 'MISSING_MASTER_VOLUME';
        // throw Error(message); // fail fast for now
      }
    } else {
      return this.data.slug;
    }
  }

  get forkParentId(): string {
    return this.data.forkParentId;
  }

  get navPath(): string {
    // if (this.data.singleUnit) {
    //   return `/units/${this.id}/${this.id}`;
    // } else {
    return `/volumes/${this.id}${
      this.channelId ? `?channel=${this.channelId}` : ''
    }`;
    // }
  }

  get parentNavPath(): string {
    return this.channel.navPath;
  }

  get unitsNavPath(): string {
    return `/units?volume=${this.id}`;
  }

  get excerptsNavPath(): string {
    return `/excerpts?volume=${this.id}`;
  }

  get newUnitNavPath(): string {
    return `/units/${this.id}/${NEW_MODEL_PATH_ID}`;
  }

  get infoNavPath(): string {
    return `/volumes/${this.id}/info`;
  }

  get creditsNavPath(): string {
    return `/volumes/${this.id}/credits`;
  }

  get speakersNavPath(): string {
    return `/volumes/${this.id}/speakers`;
    // return `/speakers/${this.id}`;
  }

  get path(): string {
    return `${this.channel?.slug}/${this.slug}`;
  }

  get caliPreviewUrl(): string {
    return volumePreviewL2Url(this.data.reviewVersion?.dataUrl, this.l2);
  }

  get caliStagedPreviewUrl(): string {
    return volumePreviewL2Url(this.data.stagedVersion?.dataUrl, this.l2);
  }

  // fromFormData(d: VolumeData) {
  //   const title = (d.title || '').trim();
  //   const slug = this.sanitizedSlug(d);

  //   runInAction(() => {
  //     this.data = { ...this.data, ...d, title, slug };
  //   });
  // }

  // sanitizedSlug(d: VolumeData): string {
  //   let candidate = isEmpty(d.slug) ? d.title : d.slug;
  //   candidate = slugify(candidate);
  //   // todo check uniqueness
  //   return candidate;
  // }

  async invokeExport(): Promise<string> {
    this.setPendingOperation('exporting...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/exportVolume?volumeId=${this.id}`;
    log.info(`exportAllData - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    if (response.status !== 200) {
      const responseText: string = await response.text();
      throw new Error(`invoke failed: ${responseText}`);
    }
    const data = await response.json();
    log.info(`response: ${JSON.stringify(data)}`);
    this.clearPendingOperation();
    return data.dataUrl;
  }

  async importFromUrl(sourceUrl: string): Promise<void> {
    if (!sourceUrl.endsWith('.json')) {
      throw Error(
        `Volume.importFromExportedUrl - unexpected file extension: ${sourceUrl}`
      );
    }
    consoleAddAuditLogMessage(this.id, `importFromExportedUrl(${sourceUrl})`);
    this.setPendingOperation('importing export data...');
    const response = await fetch(sourceUrl);
    const text = await response.text();
    const data = JSON.parse(text);
    await this.importFromExportData(data);
    this.clearPendingOperation();
  }

  async importFromExportData(data: any): Promise<void> {
    if (!data.volumeDoc || !data.units) {
      throw Error(`invalid export data`);
    }

    const unitManager = UnitManager.getInstance();
    const excerptManager = ExcerptManager.getInstance();

    if (this.units.length === 0) {
      let unitSuffix = 1;
      const isForkData = data.isForkData;
      for (const sourceUnitData of data.units) {
        const volumeId = this.id;
        let id = '';
        if (isForkData) {
          id =
            sourceUnitData.unitMetadataDoc.id +
            FORKED_UNIT_SEPARATOR +
            randomString(ID_LENGTH);
        }
        const slug = `${this.slug}-${unitSuffix++}`;
        const unit = new Unit({ id, volumeId, slug });
        unit.fromFormData(unit.toFormData());
        await unitManager.create(unit);
      }
    }

    log.info(`before resolve - units.length: ${this.units.length}`);
    const existingUnits = this.units; // re-resolve list via UnitManager after potential creations above
    log.info(`after resolve - existingUnits.length: ${this.units.length}`);
    if (existingUnits.length !== data.units.length) {
      throw Error(
        `mismatched unit counts - source data: ${data.units.length}, target volume: ${this.units.length}`
      );
    }

    const volumeDoc = data.volumeDoc;
    const volumeUpdateData = omit(volumeDoc, [
      // undesired source data
      'id',
      'channelId',
      'showId',
      'slug',
      'name',
      'exportedUrl',
      'exportedAt',
      'reviewVersion',
      'stagedVersion',
      'liveVersion',
      'workflowStatus',
      'pendingOperation',
      'bogotaCatalogData',
      'pendingVersion',
    ]);
    log.info('importing volumeDoc');
    await this.updatePartial(volumeUpdateData);

    for (const [index, unitData] of data.units.entries()) {
      const targetUnit = existingUnits[index];
      const patchedUnitMetadataDoc = omit(unitData.unitMetadataDoc, [
        // undesired source data
        'pendingOperation',
        'version',
        'unitL1s',
        'bogotaCatalogData',
        'workflowStatus',
        'linearUrl',
        // this will be overwritten below anywayws
        'id',
        'volumeId',
        'slug',
        // 'name', - allow workflow name to be imported now
      ]);

      // copy in the target props needed for importUnitData() logic to work
      Object.assign(
        patchedUnitMetadataDoc,
        pick(targetUnit.data, ['id', 'volumeId', 'slug'])
      );

      unitData.unitMetadataDoc = patchedUnitMetadataDoc;
      log.info(
        `importing unit data - (${unitData?.unitMetadataDoc?.slug}, ${unitData?.unitMetadataDoc?.id}) -> (${targetUnit?.slug}, ${targetUnit?.id})`
      );
      await importUnitData(targetUnit.id, unitData);
    }

    if (data.excerpts) {
      for (const excerptData of data.excerpts) {
        const owningUnit = excerptData.unitNumber
          ? existingUnits[excerptData.unitNumber - 1]
          : unitManager.getBySlug(excerptData.unitSlug);
        if (!owningUnit) {
          throw Error(
            `failed to resolve unit for excerpt: ${excerptData.name}, ${excerptData.unitSlug}, ${excerptData.unitNumber}`
          );
        }
        if (owningUnit.volumeId !== this.id) {
          throw Error(
            `excerpt import unit mismatch, unit (${owningUnit.slug}) owned by wrong volume (${owningUnit.volume?.slug})`
          );
        }
        const targetPath = `${owningUnit.id}.${excerptData.elementId}`;
        const targetExcerpt = await excerptManager.ensuredEntityForPath(
          targetPath
        );
        if (!targetExcerpt) {
          // can occur with dangling excerpts for example
          continue;
        }

        // excerptData.id = targetExcerpt.id;
        // excerptData.unitId = owningUnit.id;
        // await excerptManager.store(targetExcerpt.id, excerptData);

        const updateData = omit(excerptData, ['id', 'unitId']);
        await targetExcerpt.updatePartial(updateData);
      }
    }
  }

  async createForkInChannel(channel: Channel): Promise<string> {
    const sourceVolume = this;
    const channelL1 = channel.l1;
    let forkedVolume = new Volume({
      ...sourceVolume.data,
      id: '',
      singleUnit: false,
      channelId: channel.id,
      forkParentId: this.id,
      name: `${sourceVolume.name}-${channelL1}`,
      slug: `${sourceVolume.slug}-${channelL1}}`,
    });
    forkedVolume.fromFormData(forkedVolume.toFormData());
    await this.setPendingOperation('creating language fork...');
    forkedVolume = await VolumeManager.getInstance().create(forkedVolume);

    // get export data of the source volume
    await UnitManager.getInstance({ listen: false }).ensureVolumeLoaded(
      sourceVolume.id
    );
    await ExcerptManager.getInstance().ensureLoaded(); // todo: confirm if needed

    const exportedAt = epochSecondsFloat();
    const data = {} as any;
    data['isForkData'] = true;
    data['volumeDoc'] = sourceVolume.data;

    const unitsData = [] as any[];
    data['units'] = unitsData;

    const excerptsData = [] as ExcerptData[];
    data['excerpts'] = excerptsData;

    const units = sourceVolume.units;
    for (const unit of units) {
      const unitData = await getUnitExportData(unit.id, false);
      // TODO unnecessary if assume translations are not populated for target language, then can just nuke all data?
      unitData.translationVersionsDoc = computeTrimmedLocaleTranslationVersion(
        channelL1,
        unitData.translationVersionsDoc
      );
      const translations: EpisodeTranslationDoc = unitData.translationsDoc;
      const translationSet = translations.items;
      for (const lang of Object.keys(translationSet)) {
        if (lang !== channelL1) {
          translationSet[lang] = { translations: {} };
        }
      }
      unitData.unitMetadataDoc.l1Code = channelL1;
      unitsData.push(unitData);
    }

    // TODO think about excerpts in forking case
    const excerpts = sourceVolume.excerpts;
    for (const excerpt of excerpts) {
      const excerptData = excerpt.data;
      // need something beyond id's to match up on import. not sure which is better
      excerptData.unitSlug = excerpt.unit?.slug;
      excerptData.unitNumber = excerpt.unit?.data?.unitNumber;
      excerptsData.push(excerptData);
    }

    // import the export data into the forked volume
    await forkedVolume.importFromExportData(data);
    await this.clearPendingOperation();
    return forkedVolume.id;
  }

  async cloneNewExcerptsToForkVolume(forkVolume: Volume): Promise<string[]> {
    const forkedVolume = forkVolume;
    const excerptManager = ExcerptManager.getInstance();
    await excerptManager.ensureLoaded(); // todo: confirm if needed

    const clonedExcerptElementIdSet = new Set<string>(
      forkedVolume.excerpts.map(excerpt => excerpt.data.elementId)
    );
    const newExcerpts = this.excerpts.filter(
      excerpt => !clonedExcerptElementIdSet.has(excerpt.data.elementId)
    );

    const forkedUnits = forkedVolume.units;
    const mutationActions = new MutationActionsServer();
    const clonedExcerptNames: string[] = [];
    for (const sourceExcerpt of newExcerpts) {
      const targetUnit = forkedUnits.find(unit =>
        unit.id.startsWith(sourceExcerpt.unitId)
      );
      if (!targetUnit) {
        continue;
      }
      const excerptElementId = sourceExcerpt.data.elementId;
      const episodeData = await loadScriptEpisodeData(sourceExcerpt.unitId);
      const sourceUnitElements = episodeData.structuralElementList;
      const sourceExcerptElement = sourceUnitElements.getElement(
        excerptElementId
      ) as ETOf<'EXCERPT_HEAD'>;
      if (!sourceExcerptElement) {
        continue;
      }
      mutationActions.setEpisodeKey(targetUnit.id);
      const excerptElementDTO: StructuralWriteDto = {
        kind: 'EXCERPT_HEAD',
        id: excerptElementId as any,
        content: sourceExcerptElement.content,
        anchor: sourceExcerptElement.anchor,
      };
      if (sourceExcerptElement.endAnchor) {
        (excerptElementDTO as any).endAnchor = sourceExcerptElement.endAnchor;
      }
      await mutationActions.addUpdateStructural(excerptElementDTO);
      const targetPath = `${targetUnit.id}.${excerptElementId}`;
      // TODO think about if better to create straight from data
      const targetExcerpt = await excerptManager.ensuredEntityForPath(
        targetPath
      );
      // TODO think about other data should omit
      const updateData = omit(sourceExcerpt.data, [
        'id',
        'unitId',
        'unitSlug',
        'releaseDate',
        'ingestVersion',
        'ingestedAt',
        'dataUrl',
      ]);
      // TODO tweak excerpt status?
      await targetExcerpt.updatePartial(updateData);
      clonedExcerptNames.push(sourceExcerpt.data.name);
    }
    return clonedExcerptNames;
  }

  async cloneNewExcerptsToAllForkVolumes(): Promise<string[]> {
    await this.setPendingOperation('cloning new excerpts...');
    const forkedVolumes = VolumeManager.getInstance().byForkParentId(this.id);
    const clonedExcerptNames: string[] = [];
    for (const forkedVolume of forkedVolumes) {
      const names = await this.cloneNewExcerptsToForkVolume(forkedVolume);
      clonedExcerptNames.push(...names);
    }
    await this.clearPendingOperation();
    return [...new Set(clonedExcerptNames)];
  }

  get backupNeeded(): boolean {
    const oneHalfDayAgo = epochSecondsFloat() - 12 * 60 * 60;
    if (
      this.data.updatedAt &&
      (!this.data.exportedAt ||
        (this.data.updatedAt > this.data.exportedAt &&
          this.data.exportedAt < oneHalfDayAgo))
    ) {
      return true;
    }
    return this.units.some(unit => unit.backupNeeded);
  }

  sanitizedSlug(slug: string, name: string): string {
    if (isEmpty(slug?.trim())) {
      let candidate = `${this.channel?.slug}-${name}`;
      candidate = slugify(candidate);
      const existing = VolumeManager.getInstance().getBySlug(candidate);
      if (existing && existing.id !== this.id) {
        console.log(
          `warning - default slug collision: ${candidate} - this.id: ${this.id}, exising.id: ${existing.id}`
        );
        candidate = `${candidate}-${randomSlug(3)}`; // todo: improve
        console.log(` uniquified to ${candidate}`);
      }
      return candidate;
    } else {
      let candidate = slugify(slug);
      const existing = VolumeManager.getInstance().getBySlug(candidate);
      if (existing && existing.id !== this.id) {
        const message = `error - slug collision: ${candidate} - this.id: ${this.id}, exising.id: ${existing.id}`;
        console.log(message);
        global.alert(message); // TODO: @Armando, please clean up the error flow here
        throw Error(message);
      }
      return candidate;
    }
  }

  async ensureParents(): Promise<Channel> {
    const channel = await ChannelManager.getInstance().loadById(this.channelId);
    return channel;
  }

  get baseMediaStoragePath(): string {
    invariant(!!this.channel, 'this.channel expected');
    // for devtest, use a shared base path for all audio, allows match and skip of audio files
    // between volumes during ingestion. (handy for testing)
    const volumePath = deploymentConfig.isDevtest ? '' : `/v/${this.slug}`;
    return `${this.channel.baseMediaStoragePath}${volumePath}`;
  }

  get baseAudioStoragePath(): string {
    return `${this.baseMediaStoragePath}/audio`;
  }

  get baseChaatStoragePath(): string {
    return `${this.baseMediaStoragePath}/chaat`;
  }

  get baseImageStoragePath(): string {
    return `${this.baseMediaStoragePath}/image`;
  }

  get baseDataStoragePath(): string {
    invariant(!!this.channel, 'this.channel expected');
    return `${this.channel.baseDataStoragePath}/v/${this.slug}/data`;
  }

  get detailDataPath(): string {
    // assumes that the ingest version counter has already been incremented
    const result = `${this.baseDataStoragePath}/volume-${this.l1}-${this.slug}-v${this.data.pendingVersion}.json`;
    return result;
  }

  hasWriteAccess(user: AppUser): boolean {
    if (user) {
      // if (this.data.lockedForAll) {
      //   return false;
      // }
      // return !this.isLocked || this.data.lockedByUserId === user.id;
      if (user.isAdmin) {
        return true;
      }
      if (user.scopedChannelId && this.channelId === user.scopedChannelId) {
        return true;
      }
    }
    return false;
  }

  async destroy(): Promise<void> {
    console.log(`Volume[${this.id}].destroy`);
    await this.destroyUnits();
    await VolumeManager.getInstance().delete(this);
  }

  async destroyUnits(): Promise<void> {
    const ids = this.units.map(unit => unit.id);
    for (let i = 0; i < ids.length; i++) {
      const unit = UnitManager.getInstance().getById(ids[i]);
      await unit.destroy();
    }
  }

  async archiveChildren(): Promise<void> {
    const ids = this.units.map(unit => unit.id);
    for (let i = 0; i < ids.length; i++) {
      const unit = UnitManager.getInstance().getById(ids[i]);
      await unit.archive();
    }
  }

  // should call this during ingestion
  async ensureUnitNumbers() {
    const toBeUpdated = [];
    for (const [i, unit] of this.units.entries()) {
      const expected = i + 1;
      if (unit.unitNumber !== expected) {
        console.log(
          `${unit.name} - updating unitNumber from ${unit.unitNumber} to ${expected}`
        );
        toBeUpdated.push({ unit, newNumber: expected });
      }
    }
    for (const { unit, newNumber } of toBeUpdated) {
      unit.updateUnitNumber(newNumber);
    }
  }

  /**This will swap unitNumber of adjoining units in the units table view.
   * The edge cases of the first-unit/move up and the last-unit/move down
   * are handled in a way that they will swap unitNumber with each other.
   * But that might confuse the users. Therefore UI logic in the units-table
   * is also guarding against those two cases.  */

  async swapUnitNumber(unit: Unit, moveUp: boolean) {
    await this.ensureUnitNumbers();
    if (this.unitCount <= 1) {
      return; // assume that the ensure took care of what was needed
    }
    const currentUnitNumber = unit.data.unitNumber;
    let swappingUnitNumber: number;

    if (currentUnitNumber === 1 && moveUp) {
      swappingUnitNumber = this.unitCount;
    } else if (currentUnitNumber === this.unitCount && !moveUp) {
      swappingUnitNumber = 0;
    } else {
      swappingUnitNumber = moveUp
        ? currentUnitNumber - 1
        : currentUnitNumber + 1;
    }

    const swappingUnit = this.units.find(
      unit => unit.data?.unitNumber === swappingUnitNumber
    );
    await unit.updateUnitNumber(swappingUnitNumber);
    await swappingUnit.updateUnitNumber(currentUnitNumber);
  }

  get crud(): EntityCrud<Volume, VolumeData> {
    return VolumeCrud;
  }

  theManager(): EntityManager<CatalogEntity<VolumeData>, VolumeData> {
    return VolumeManager.getInstance() as unknown as EntityManager<
      CatalogEntity<VolumeData>,
      VolumeData
    >;
  }

  get singleUnit(): Unit {
    if (this.data.singleUnit) {
      return this.units[0];
    } else {
      return null;
    }
  }

  get firstUnit(): Unit {
    return (
      this.units.find(unit => unit.data?.unitNumber === 1) || this.units[0]
    );
  }

  get firstUnitKey(): string {
    return this.firstUnit?.episodeKey || '';
  }

  // @computed
  get unitCount(): number {
    return this.units?.length || 0;
  }

  get unitSlugs(): string[] {
    return this.units.map(unit => unit.slug);
  }

  get isStagedReady(): boolean {
    return this.units.some(unit => unit.visible(WorkflowStatus.PUBLISHED));
  }

  visible(status: WorkflowStatus): boolean {
    return this.units.some(unit => unit.visible(status));
  }

  get excerpts(): Excerpt[] {
    const result: Excerpt[] = [];
    for (const unit of this.units) {
      result.push(...unit.excerpts);
    }
    return result;
  }

  get ingestedAt(): string {
    return this.data.reviewVersion?.ingestedAt || new Date(0).toISOString(); // ensure that sorts won't barf
  }

  // sum of the unit durations rounded to nearest unit
  // used for classroom sorting
  get totalDurationMinutes(): number {
    return this.units.reduce(
      (acc: number, unit) => acc + unit.durationMinutes,
      0
    );
  }

  get totalDownloadSize(): number {
    return this.units.reduce((acc: number, unit) => acc + unit.downloadSize, 0);
  }

  // get isLupa(): boolean {
  //   return this.data.flavor === MetadataFlavor.LUPA;
  // }

  // deduce primary l1 - used by volume speakers view
  // (actually expected to be the same for an entire channel now)
  get l1(): LocaleCode {
    return this.firstUnit?.l1 || this.channel.l1;
  }

  get l2(): LocaleCode {
    return this.firstUnit?.l2 || this.channel.data.l2;
  }

  get canIngest(): boolean {
    return this.units.some(unit => unit.canIngest);
  }

  // true if last cali review ingestion is longer ago than given number of minutes
  ingestionMinutesOlderThan(ageMin: number) {
    const lastIngestedAt = this.data.reviewVersion?.ingestedAt;
    if (!lastIngestedAt) return false;

    const age = this.ingestionAgeMinutes;
    log.info(`${this.slug} - last ingestion age: ${age}`);
    return age > ageMin;
  }

  get ingestionAgeMinutes(): number {
    return ageMinutes(this.data.reviewVersion?.ingestedAt);
  }

  get reviewIngestionAgePretty(): string {
    return agePretty(this.data.reviewVersion?.ingestedAt);
  }

  get stagedIngestionAgePretty(): string {
    return agePretty(this.data.stagedVersion?.ingestedAt);
  }

  get isReviewCatalogStale(): boolean {
    const ingestedAt = this.data.reviewVersion?.ingestedAt;
    if (!ingestedAt) return false;
    const publishedAt = this.channel.data?.reviewCaliVersion?.generatedAt;
    return !publishedAt || ingestedAt > publishedAt;
  }

  // the data needed to render the vocab and story notes panels
  async buildStoryDetailData(locale: LocaleCode = undefined) {
    const builder = await VolumeCaliDataBuilder.create(
      this,
      VolumeDataFlavor.DETAIL
    );
    const data = builder.buildCatalogData();
    return data;
  }

  ingestionVersionForStage = (stage: CatalogStage): VolumeIngestionVersion => {
    switch (stage) {
      case CatalogStage.REVIEW:
        return this.data.reviewVersion;
      case CatalogStage.STAGED:
        return this.data.stagedVersion;
      case CatalogStage.LIVE: // never published, only promoted
        return this.data.liveVersion;
      default:
        throw Error(`ingestionVersionForStage - unexpected stage: ${stage}`);
    }
  };

  async invokeCaliIngest({
    autoPublish = false,
  }: {
    autoPublish: boolean;
  }): Promise<string> {
    if (!this.hasSpeakerData) {
      throw Error('missing volume speaker data');
    }
    // consoleAddAuditLogMessage(this.episodeKey, 'ingest');
    await this.setPendingOperation('invoking ingestion...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/ingestVolume?volumeId=${
      this.id
    }&autoPublish=${String(autoPublish)}`;
    console.log(`ingest - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    if (response.status !== 200) {
      const responseText: string = await response.text();
      throw Error(`invoke failed: ${responseText}`);
    }
    const data = await response.json();
    console.log(`response: ${JSON.stringify(data)}`);

    await this.clearPendingOperation();
    return data.dataUrl;
  }

  // ensuring the thumb image has been fetched should just be automatic during ingestion
  // async fetchAssets(): Promise<string> {
  //   this.setPendingOperation('fetching assets...');
  //   const apiUrl = `${deploymentConfig.masalaServerUrl}/fetchAssets?volumeId=${this.id}`;
  //   console.log(`fetchAssets - apiUrl: ${apiUrl}`);
  //   let response = await fetch(apiUrl);
  //   const data = await response.json();
  //   console.log(`response: ${JSON.stringify(data)}`);
  //   if (data.error) {
  //     throw Error(data.error);
  //   }
  //   this.clearPendingOperation();
  //   return data.dataUrl;
  // }

  async promoteCaliReviewToStaged({ autoPublish }: { autoPublish: boolean }) {
    // consoleAddAuditLogMessage(this.episodeKey, 'promoteInternalToStaged');

    await this.updatePartialNoDirty(
      {
        stagedVersion: this.data.reviewVersion,
      },
      true /* use 'update' instead of set/merge */
    );
    if (autoPublish) {
      await this.channel.invokePublishCaliStagedCatalog();
    }
  }

  async updateSpeaker(oldLabel: string, newData: SpeakerV5Data): Promise<void> {
    console.log(`updateSpeaker - oldLabel: ${oldLabel}`);
    for (const [index, oldData] of this.data.speakers?.entries() || []) {
      if (oldData.label === oldLabel) {
        this.data.speakers[index] = newData;
        this.updatePartial({ speakers: this.data.speakers });
        consoleAddAuditLogMessage(
          this.firstUnitKey,
          `updateSpeaker(${oldLabel})`
        );
        return;
      }
    }
    throw Error(`existing speaker data not found for: ${oldLabel}`);
  }

  async resetAllSpeakerData(): Promise<void> {
    console.log(
      `resetAllSpeakerData - old data: ${JSON.stringify(this.data.speakers)}`
    );
    this.updatePartial({ speakers: [] });
  }

  get hasSpeakerData(): boolean {
    return this.data.speakers && this.data.speakers.length > 0;
  }

  async removeSpeaker(oldLabel: string): Promise<void> {
    console.log(`removeSpeaker - oldLabel: ${oldLabel}`);
    for (const [index, oldData] of this.data.speakers?.entries() || []) {
      if (oldData.label === oldLabel) {
        this.data.speakers.splice(index, 1);
        this.updatePartial({ speakers: this.data.speakers });
        consoleAddAuditLogMessage(
          this.firstUnitKey,
          `removeSpeaker(${oldLabel})`
        );
        return;
      }
    }
    throw Error(`speaker data not found for: ${oldLabel}`);
  }

  // think about support for ordering
  async createSpeaker(label: string, naBio: boolean): Promise<void> {
    console.log(`createSpeaker - label: ${label}`);
    // TODO or the default to [] should be in emptyVolumeData?
    const speakers = this.data.speakers ?? [];
    // const existing = this.data.speakers.find(_ => _.label === label);
    const existing = speakers.find(_ => _.label === label);

    if (existing) {
      throw Error(`"${label}" speaker label already exists`);
    }
    // TODO remove this direct modification of data
    // this.data.speakers.push({
    speakers.push({
      label,
      bio: naBio ? 'n/a' : '',
      accent: '',
      id: null,
      translations: {},
    });
    // this.updatePartial({ speakers: this.data.speakers });
    this.updatePartial({ speakers });
    consoleAddAuditLogMessage(this.firstUnitKey, `createSpeaker(${label})`);
  }

  // hack until we have an in-line / modal speaker editor
  resolveSpeakerEditUrl(label: string, locale: string = null): string {
    let path = `/volumes/${this.id}/speakers/${label}/edit`;
    const result = deploymentConfig.consoleUrl + path;
    return result;
  }

  speakerByLabel(label: string): SpeakerV5Data {
    if (!this.data.speakers) {
      this.data.speakers = [];
    }
    return this.data.speakers.find(speaker => speaker.label === label);
  }

  speakerPosition(label: string): number {
    return this.data.speakers.findIndex(speaker => speaker.label === label);
  }

  get speakerCount(): number {
    return this.data.speakers.length;
  }

  isFirstSpeaker(label: string): boolean {
    return this.speakerPosition(label) === 0;
  }

  isLastSpeaker(label: string): boolean {
    return this.speakerPosition(label) === this.speakerCount - 1;
  }

  swapSpeakerPosition(label: string, moveUp: boolean) {
    const currentPosition = this.speakerPosition(label);
    const newPosition = currentPosition + (moveUp ? -1 : 1);
    const speakers = [...this.data.speakers];
    swapArrayElements(speakers, currentPosition, newPosition);
    this.updatePartial({ speakers });
  }

  // buildCastV3Data(locale: string): CastV3Data[] {
  //   return this.data.speakers
  //     .filter(
  //       v5 =>
  //         notEmptyOrNA(v5.bio) ||
  //         notEmptyOrNA(speakerTranslation(v5, 'bio', locale))
  //     )
  //     .map(v5 => ({
  //       fullName: speakerTranslation(v5, 'label', locale) || v5.label,
  //       description: speakerTranslation(v5, 'bio', locale) || v5.bio,
  //       accent: speakerTranslation(v5, 'accent', locale) || v5.accent,
  //       bioL2: v5.bio,
  //       accentL2: v5.accent,
  //     }));
  // }

  localizedSpeakerLabel(label: string, locale: string): string {
    if (!label) {
      return label;
    }
    const speaker = this.speakerByLabel(label);
    if (speaker) {
      locale = locale || this.l1;
      return speakerTranslation(speaker, 'label', locale) || speaker.label;
    } else {
      console.error(
        `localizedSpeakerLabel - speaker data not found for label: ${label}`
      );
      return label;
    }
  }

  // transformed v5 format persisted schema into desired structure
  get speakerDatas() {
    return this.data.speakers.map(v5Data => fromSpeakerV5Data(v5Data));
  }

  clientSpeakerDatas(locale: LocaleCode) {
    return this.speakerDatas.map(data => toClientSpeakerData(data, locale));
  }

  async buildActivityGuideCatalogData(): Promise<ActivityGuideV3Data> {
    // assumes only one unit per volume has an activity guide
    for (const unit of this.units) {
      const data = await unit.buildActivityGuideCatalogData();
      if (data) {
        return data;
      }
    }
    return null;
  }

  /*
   *  Tag Stuff
   *
   *  beware: forked from unit.ts. old code needs to be removed once data fully migrated
   */

  tagSlugsByType(tagType: TagType) {
    const slugs = this.data.tagTypeSlugs
      ? this.data.tagTypeSlugs[tagType]
      : null;
    return slugs || [];
  }

  tagsByType(tagType: TagType) {
    return TagManager.getInstance().tagsByTypeSlugs(
      tagType,
      this.tagSlugsByType(tagType)
    );
  }

  tagNamesByType(tagType: TagType) {
    return this.tagsByType(tagType).map(tag => tag.name);
  }

  get apTagNames() {
    return this.tagNamesByType('ap');
  }

  get apTagSlugs() {
    return this.tagSlugsByType('ap');
  }

  get ibTagNames() {
    return this.tagNamesByType('ib');
  }

  get ibTagSlugs() {
    return this.tagSlugsByType('ib');
  }

  get countryTagNames() {
    return this.tagNamesByType('country');
  }

  get countryTagSlugs() {
    return this.tagSlugsByType('country');
  }

  get topicTagNames() {
    return this.tagNamesByType('topic');
  }

  get topicTagSlugs() {
    return this.tagSlugsByType('topic');
  }

  async addTypeTag(tagType: TagType, tagSlug: string) {
    const existingTagSlugs = this.tagSlugsByType(tagType);

    if (existingTagSlugs.includes(tagSlug)) {
      throw new Error('Tag slug is already included in the unit');
    }

    const tag = TagManager.getInstance().getByTypeSlug(tagType, tagSlug);

    if (!tag) {
      throw new Error('Invalid tag');
    }

    runInAction(() => {
      const tagTypeSlugs = this.data.tagTypeSlugs || {};
      if (!tagTypeSlugs[tagType]) {
        tagTypeSlugs[tagType] = [];
      }
      tagTypeSlugs[tagType].push(tagSlug);
      this.updatePartial({ tagTypeSlugs });
      consoleAddAuditLogMessage(
        this.firstUnitKey,
        `addVolumeTag(${tagType}, ${tagSlug})`
      );
    });
  }

  removeTypeTag(tagType: TagType, tagSlug: string) {
    const existingSlugs = this.tagSlugsByType(tagType);

    if (!existingSlugs.includes(tagSlug)) {
      throw new Error(
        'Cannot remove Tag because it is not included in the unit'
      );
    }

    runInAction(() => {
      const newTagSlugs = existingSlugs.filter(slug => slug !== tagSlug);
      const tagTypeSlugs = this.data.tagTypeSlugs || {};
      tagTypeSlugs[tagType] = newTagSlugs;
      // todo: consider using a deeper firebase doc path
      this.updatePartial({ tagTypeSlugs });
      consoleAddAuditLogMessage(
        this.firstUnitKey,
        `removeVolumeTag(${tagType}, ${tagSlug})`
      );
    });
  }

  // version which doesn't touch the 'updatedAt' timestamp
  // todo: refactor - duplicated from unit.ts
  async updatePartialNoDirty(
    data: object,
    useUpdate: boolean = false
  ): Promise<void> {
    // beware, won't correctly handle nested paths
    this.data = { ...this.data, ...data }; // need to reassign to trigger the reactions
    if (isEmpty(data)) {
      // an empty object object will nuke the entire doc!
      return;
    }
    const ref = docReference<VolumeData>(
      CatalogCollections.VOLUME_METADATA,
      this.id
    );
    if (useUpdate) {
      // todo: consider making the 'update' mode the default
      await ref.update(data);
    } else {
      await ref.set(data, { merge: true });
    }
  }

  // check if most recent ingestion has dirty vocab id's that need reingesting
  async hasDirtyVocabIds(): Promise<boolean> {
    const url = this.data.reviewVersion?.dataUrl;
    if (!url) return false;
    const data = (await fetchJson(url)) as VolumeCaliData;
    for (const unit of data.units) {
      for (const element of unit.elements) {
        const parts = element.id.split(':');
        invariant(parts.length === 2, `unexpected element id: ${element.id}`);
        if (hasDirtyIdChars(parts[1])) {
          return true;
        }
      }
    }
    return false;
  }
}

export const speakerTranslation = (
  speaker: SpeakerV5Data,
  prop: string,
  locale: string
) => (speaker.translations ? speaker.translations[`${prop}:${locale}`] : '');

export const setSpeakerTranslation = (
  speaker: SpeakerV5Data,
  prop: string,
  locale: string,
  value: string
) => {
  if (!speaker.translations) {
    speaker.translations = {};
  }
  speaker.translations[`${prop}:${locale}`] = value;
};

export const fromSpeakerV5Data = (v5Data: SpeakerV5Data): SpeakerData => {
  const result: SpeakerData = {
    id: v5Data.id,
    label: { l2: v5Data.label } as LString,
    bio: { l2: v5Data.bio } as LString,
    accent: { l2: v5Data.accent } as LString,
  };
  for (const [key, value] of Object.entries(v5Data.translations)) {
    const [prop, locale] = key.split(':');
    (result as any)[prop][locale] = value;
  }
  return result;
};

export const toClientSpeakerData = (
  data: SpeakerData,
  l1: LocaleCode
): ClientSpeakerData => {
  return {
    labelL12: toL12String(data.label, l1),
    bioL12: toL12String(data.bio, l1),
    accentL12: toL12String(data.accent, l1),
  };
};

export const toL12String = (lString: LString, l1: LocaleCode): L12String => {
  return {
    l2: lString.l2?.trim(),
    l1: lString[l1]?.trim(),
  };
};
