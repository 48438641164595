import { observer } from 'mobx-react';
import { FilterSelector } from './filter-selector';

const workflowOptions = [
  { label: 'Open', value: 'PLANNED|IN_PROGRESS|EXTERNAL_REVIEW' },
  { label: 'Planned', value: 'PLANNED' },
  { label: 'In progress', value: 'IN_PROGRESS' },
  { label: 'External review', value: 'EXTERNAL_REVIEW' },
  { label: 'Staged', value: 'PUBLISHED' },
  { label: 'Hold', value: 'HOLD' },
  { label: 'Cancelled', value: 'CANCELLED' },
  { label: 'None', value: '_' },
];
export const WorkflowStatusFilterSelector = observer(
  ({ path }: { path: string }) => {
    return (
      <FilterSelector
        path={path}
        fieldName="workflow_status"
        options={workflowOptions}
        label={'Filter by workflow status'}
        shortcutKey={'keyW'}
      />
    );
  }
);
