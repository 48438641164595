import * as React from 'react';
import { VolumeData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Checkbox } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';

interface Props {
  dto: VolumeData;
  onSubmit: (dto: VolumeData) => void;
  onCancel: () => void;
}

interface FormProps {
  id: string;
  name: string;
  slug: string;
  channelId?: string;
  forkParentId?: string;
  singleUnit?: boolean;
  trial?: boolean;
  releaseDate?: string;
}

// export const flavorOptions = [
//   {
//     label: 'Lupa',
//     value: 'LUPA',
//   },
//   {
//     label: 'Bolero',
//     value: 'BOLERO',
//   },
// ] as const;

export const VolumeForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues: FormProps = pick(dto, [
    'id',
    'name',
    'slug',
    'channelId',
    'forkParentId',
    'singleUnit',
    'releaseDate',
    'trial',
    // 'flavor',
    'linearUrl',
    'imageThumbUrl',
    'themeColor',
  ]);
  const { handleSubmit, reset, getFieldProps, register } = useMasalaForm<
    typeof defaultValues
  >({
    defaultValues,
  });

  React.useEffect(() => {
    return () => {
      //otherwise we stay in form mode, even when going to another item
      onCancel();
    };
  }, [onCancel]);

  const isNew = isEmpty(dto.id);
  const channelOptions = ChannelManager.getInstance().selectOptionsWithBlank;
  const volume = VolumeManager.getInstance().getById(dto.id);
  const isPublished = volume?.isStagedReady;

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          {...getFieldProps('channelId')}
          options={channelOptions}
          required
        />
        <hr />
        <Input {...getFieldProps('name')} required />
        {!isPublished ? (
          <Input
            {...getFieldProps('slug')}
            hint={"(will default to '[channel slug]-[slugified volume name])"}
          />
        ) : (
          <>Slug: {dto.slug}</>
        )}
        {/* <Select
          {...getFieldProps('flavor')}
          options={flavorOptions as any}
          required
        /> */}
        {/* <Input {...getFieldProps('linearUrl')} /> */}
        <Input
          {...getFieldProps('releaseDate')}
          validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
          hint={'YYYY-MM-DD - optional, expected here if same for all units'}
        />
        <Checkbox
          {...getFieldProps('singleUnit')}
          label="Single-unit volume"
        ></Checkbox>
        {/*todo: make these condition on lupa flavor volume*/}
        <Checkbox {...getFieldProps('trial')} label="Free trial"></Checkbox>

        <Input
          {...getFieldProps('imageThumbUrl')}
          hint="source google drive url of square story thumb to import from"
        />
        <Input {...getFieldProps('themeColor')} hint="provided by frank" />

        <Input
          {...getFieldProps('forkParentId')}
          hint={
            '(used to repair volumes not properly forked when originally created)'
          }
        />

        <FormButtons
          submitLabel={isNew ? 'Create volume' : 'Save changes'}
          onCancel={onCancel}
        />
      </form>
    </OnePaneLayout>
  );
};
