import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { TagCreateScreen } from './tag-create-screen';
import { TagEditScreen } from './tag-edit-screen';
import { TagsListScreen } from './tags-list-screen';
import { TagDetailScreen } from './tag-detail-screen';

export const TagsScreen = () => {
  return (
    <ModelScreen
      path={'/tags'}
      renderList={() => <TagsListScreen />}
      renderDetail={controller => (
        <TagDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <TagEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <TagCreateScreen />}
    />
  );
};
