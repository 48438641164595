import {
  BaseETForKT,
  CreateBase,
  ElementId,
  ElementList,
  EmptyWordElementList,
  ExtractETForKT,
  IElement,
  Indexed,
  IntervalIndexed,
  IntervalIndexedTimed,
  IntervalTimed,
  SystemBasicKinds,
  Timed,
  WordElement,
  WordId,
  WordIdRange,
} from '@tikka/basic-types';
import {
  DistributiveOmit,
  DistributivePartialBy,
  Merge,
  Simplify,
  Unionize,
} from '@tikka/type-utils';
import {
  BasicElementKind,
  EKind,
  ImportMetaElementKind,
  StructuralContentKind,
} from './element-kinds';
import { CreateElementList } from '@tikka/elements/element-list';

export type KindToString = {
  [index in EKind]?: string;
};

export type KindToNumber = {
  [index in EKind]?: number;
};

// ********* BASE FOR EDITORIAL/STORAGE TYPES AND BUILDING BLOCKS FOR ********
type CB<K extends SystemBasicKinds> = CreateBase<K>;

type _ESentence = CB<'SENTENCE'>;

// SOME BUILDING BLOCKS
// STRUCTURAL KINDS
export const StructuralSpanKindList = [
  'PARAGRAPH',
  'PASSAGE',
  'CHAPTER',
] as const;

export const StructuralPointKindList = [
  'CHAPTER_NOTE',
  'CHAPTER_COMPLETE',
  'EXCERPT_HEAD', // TODO get rid of HEAD
] as const;

export const StructuralKindList = [
  ...StructuralSpanKindList,
  ...StructuralPointKindList,
] as const;

export type StructuralKind = Unionize<typeof StructuralKindList>;
export type StructuralPointKinds = Unionize<typeof StructuralPointKindList>;
export type StructuralSpanKinds = Unionize<typeof StructuralSpanKindList>;

// STRUCTURAL CONTENT TYPES
export type Content = unknown;

export type TextContent = {
  text?: string;
  canonical?: string;
};

export type ChapterContent = {
  text: string;
};

export type PassageContent = {
  text: string;
};

export type ChapterNoteContent = {
  text: string;
};

export type ContentOfStructural =
  | TextContent
  | ChapterContent
  | ChapterNoteContent
  | PassageContent;

type _EChapter = CB<'CHAPTER'> & { content?: ChapterContent };

type _EPassage = CB<'PASSAGE'> & { content?: PassageContent };

type _EParagraph = CB<'PARAGRAPH'> & { content?: TextContent };

type _EChapterNote = CB<'CHAPTER_NOTE'> & { content?: TextContent };

type _EChapterComplete = CB<'CHAPTER_COMPLETE'> & { content?: TextContent }; // TODO fix this

// JRW TODO eliminate _HEAD stuff
export type _EExcerpt = BaseETForKT<'EXCERPT_HEAD'> & {
  content: TextContent;
  endAnchor: PointAnchor;
};

type _EStructural2Bsp = _EChapter | _EPassage | _EParagraph;
type _EStructural2Bpt = _EChapterNote | _EExcerpt | _EChapterComplete;

// WORD GROUPS CONTENT

type _BaseWordGroupContent = {
  note?: string;
  canonical?: string;
  usage?: string;
  preserveCase?: boolean;
};

export type VocabContent = _BaseWordGroupContent & { duplicateOk: boolean };

export type TrickyContent = _BaseWordGroupContent;

export type SicContent = _BaseWordGroupContent;

export type WordGroupContent = VocabContent | TrickyContent | SicContent;

// WORD GROUPS
type WGB = CB<'WORD_GROUP'>;
type VocabBase = WGB & { subKind: 'VOCAB' };
type TrickyBase = WGB & { subKind: 'TRICKY' };
type SicBase = WGB & { subKind: 'SIC' };

type _EVocab = VocabBase & { content: VocabContent };
type _ETricky = TrickyBase & { content: TrickyContent };
type _ESic = SicBase & { content: SicContent };

type _EWordGroup = _EVocab | _ETricky | _ESic;

type _ETranslation = BaseETForKT<'TRANSLATION'> & {
  content: ContentTranslation;
  elementId: ElementId;
  locale: string;
};

type _EMetadataBlock = BaseETForKT<typeof BasicElementKind.METADATA_BLOCK> & {
  subKind: ImportMetaElementKind;
  content: string; // unparsed body text
};

export type AdhocRangeElement = BaseETForKT<'ADHOC_RANGE'> &
  IntervalIndexed & {
    range: WordIdRange;
    subKind?: string;
  };

// ******** STORAGE TYPES AND BUILDING BLOCKS *********

// BUILDING BLOCKS
export type Deletable = { deleted?: boolean };
export type Timestamped = { timestamp: number };
export type Attributed = Timestamped & { author: string };
export type AttributedElement = IElement & Attributed;
export type Anchored = { anchor: unknown };
export type PointAnchor = { wordId: WordId };
export type PointAnchored = { anchor: PointAnchor };
export type SpanAnchor = { wordId: WordId; endWordId: WordId };
export type SpanAnchored = { anchor: SpanAnchor };

type StorageCommon = Attributed & Anchored & Deletable;

// STORAGE SENTENCE/WORD
export type StorageSentence = _ESentence &
  StorageCommon & { anchor: PointAnchor };
export type StorageWord = BaseETForKT<'WORD'> & { text: string };

// STORAGE STRUCTURAL

type _StorageStructural2Bsp = _EStructural2Bsp & StorageCommon & PointAnchored;
type _StorageStructural2Bpt = _EStructural2Bpt & StorageCommon & PointAnchored;
export type StorageStructural = _StorageStructural2Bsp | _StorageStructural2Bpt;

export type StorageWordGroup = _EWordGroup & StorageCommon & SpanAnchored;

export type StorageTranslation = _ETranslation & Attributed & Deletable;
export type StorageMetadataBlock = _EMetadataBlock & Attributed & Deletable;

// STORAGE COMPOSITE
export type StorageElement = Simplify<
  | StorageSentence
  | StorageWordGroup
  | StorageStructural
  | StorageTranslation
  | StorageMetadataBlock
>;

// ********* SCRIPT EDITOR TYPES ********
export type EditorWord = WordElement;
export type EditorWordElement = WordElement; // TODO consolidate
export type EditorSentence = StorageSentence & IntervalIndexed;
export type EditorWordGroup = StorageWordGroup & IntervalIndexed;
type _EditorStructuralSpans = _StorageStructural2Bsp & IntervalIndexed;
type _EditorStructuralPoints = _StorageStructural2Bpt & Indexed;
export type EditorStructural = _EditorStructuralSpans | _EditorStructuralPoints;
// JRW TODO eliminate HEAD
export type Excerpt = ExtractETForKT<EditorStructural, 'EXCERPT_HEAD'>;
export type EditorMetadataBlock = StorageMetadataBlock;

export type EditorElement = Simplify<
  | EditorWord
  | EditorSentence
  | EditorStructural
  | EditorWordGroup
  | EditorMetadataBlock
  | Translation
  | AdhocRangeElement
>;

// JRW TODO nuke this
export type Element = StorageElement | EditorElement;

// JRW TODO FIX THIS
export type EditorStructuralContent = EditorStructural;
// export interface EditorStructuralContent extends EditorStructural {
//   kind: StructuralContentKind;
//   content: TextContent;
// }

// ********** CHAAT TYPES *********
export type ChaatWordElement = WordElement;
export type ChaatSentence = EditorSentence & IntervalTimed;
export type ChaatStructural = ExtractETForKT<
  _EStructural2Bsp & IntervalIndexed,
  'PARAGRAPH' | 'CHAPTER'
>;
// TODO put this in here for the review tool, make separate type space for that
export type ChaatWordGroup = EditorWordGroup;

export type ChaatElement = Simplify<
  | ChaatWordElement
  | ChaatSentence
  | ChaatStructural
  | ChaatWordGroup
  | AdhocRangeElement
>;

// ********** EPHEMERAL INGESTION TYPES ***********
export type IngestionWord = WordElement;
export type IngestionSentence = _ESentence & IntervalIndexedTimed;
export type IngestionStructuralSpans = _EStructural2Bsp & IntervalIndexedTimed;
export type IngestionStructuralPoints = _EStructural2Bpt & Indexed & Timed;
export type IngestionWordGroup = _EWordGroup & IntervalIndexed;
export type IngestionGap = BaseETForKT<'SEGMENT_GAP'> & Indexed & IntervalTimed;
export type IngestionStructural =
  | IngestionStructuralSpans
  | IngestionStructuralPoints;

export type IngestionElement = Simplify<
  | IngestionWord
  | IngestionSentence
  | IngestionWordGroup
  | IngestionStructural
  | IngestionGap
>;

// ********** VERSION TYPES *********
export type Version = { baseVersionId: ElementId };

export type WordGroupVersionData = StorageWordGroup & Version;
export type StructuralVersionData = StorageStructural & Version;
export type SentenceVersionData = StorageSentence &
  Version & {
    words?: WordElement[];
  };
export type TranslationVersionData = StorageTranslation & Version;

export type ElementVersionData =
  | WordGroupVersionData
  | StructuralVersionData
  | SentenceVersionData
  | TranslationVersionData;

export type VersionItem = Attributed & {
  content: string;
  versionData: ElementVersionData | ElementVersionData[];
};

export type VersionableElement =
  | EditorSentence
  | EditorStructural
  | EditorWordGroup
  | Translation;
//  | EditorStructuralContent
//  | EditorStructuralMarker

// ********* SCRIPT EDITOR DTOs *********

// TODO make DTOs consistent with regard to id included or external?
export type WordGroupWriteDto = Simplify<
  Merge<
    DistributivePartialBy<DistributiveOmit<_EWordGroup, 'kind'>, 'id'> & {
      anchor: SpanAnchor;
    }
  >
>;

export type SentenceWriteDto = {
  id?: _ESentence['id'];
  anchor: PointAnchor;
  deleted?: boolean;
};

export type StructuralWriteDto = BaseETForKT<StructuralKind> & {
  anchor: PointAnchor;
  content?: TextContent;
};

export type TranslationWriteDto = {
  parentElementId: ElementId;
  contentFieldName: string;
  locale: string;
  translatedContent: string;
};

export type MetadataBlockWriteDto = {
  subKind: ImportMetaElementKind;
  content: string;
  id?: string;
};

// WHAT IS THIS?
export interface RangeElement // jrw: does this have a kind????
  extends IElement,
    IntervalIndexed,
    IntervalTimed {}

type k = ExtractETForKT<IngestionElement, 'PARAGRAPH'>;

export type ContentTranslation = string | WordGroupContent;

export type Translation = StorageTranslation;

export const EmptyElementList = CreateElementList({
  elements: [] as Element[],
  words: EmptyWordElementList,
}) as unknown as any;

export type ChaatElementList<ET extends ChaatElement> = ElementList<ET>;
export type EditorElementList<ET extends EditorElement> = ElementList<ET>;

export const CreateEditorElementList: <ET extends EditorElement>({
  elements,
  words,
}: {
  elements: ET[];
  words?: ElementList<WordElement>;
}) => ElementList<ET> = CreateElementList as any;

export const CreateChaatElementList: <ET extends ChaatElement>({
  elements,
  words,
}: {
  elements: ET[];
  words?: ElementList<WordElement>;
}) => ElementList<ET> = CreateElementList as any;

export enum WordStyle {
  // NORMAL = 'NORMAL', // assumed if unassigned
  ITALIC = 'italic',
  // BOLD = 'BOLD', // future?
}

export type ElementIdToTranslation = {
  [index in ElementId]: Translation;
};
