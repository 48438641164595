import { camelCase, kebabCase } from 'lodash';
import * as React from 'react';

export const WordGroup = ({ group }: { group: any }) => {
  return (
    <React.Fragment>
      {group.elements?.map((word: any) => {
        // console.log(word);
        return (
          <>
            <span
              key={word.address}
              className={'type-' + kebabCase(group.groupType)}
            >
              {word.text}
            </span>{' '}
          </>
        );
      })}
      {group.groupType === 'tricky' ? (
        <span className="tricky-label">(tricky bit) </span>
      ) : null}
      {group.groupType === 'sic' ? (
        <span className="tricky-label">(sic) </span>
      ) : null}
    </React.Fragment>
  );
};
