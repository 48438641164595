import { observer } from 'mobx-react';
import { FilterSelector } from './filter-selector';

const warningsStatusOptions = [
  { label: 'Has Warnings', value: 'HAS_WARNINGS' },
  { label: 'Has Unresolved', value: 'HAS_UNRESOLVED' },
  { label: 'Any', value: '_' },
];
export const WarningsStatusSelector = observer(({ path }: { path: string }) => {
  return (
    <FilterSelector
      path={path}
      fieldName="warnings_status"
      options={warningsStatusOptions}
      label={'Filter by warnings/unresolved status'}
    />
  );
});
