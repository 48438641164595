import { observer } from 'mobx-react';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { FilterSelector } from './filter-selector';

export const ChannelFilterSelector = observer(({ path }: { path: string }) => {
  const channelOptions = ChannelManager.getInstance().selectOptionsWithAll;
  return (
    <FilterSelector
      path={path}
      fieldName="channel"
      options={channelOptions}
      placeholder="Select a channel…"
      label={'Filter by channel'}
      shortcutKey={'keyC'}
    />
  );
});
