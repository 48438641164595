import { ary, create, first, isEmpty, pick } from 'lodash';
import stringHash from 'string-hash';
import {
  stripUnderscores,
  toUnicodePunctuation,
  transformPlayerText,
} from '@utils/content-string-utils';
import { LocaleCode } from '@utils/util-types';
import {
  ElementList,
  IDTOfKT,
  IndexRange,
  IntervalIndexed,
  isIntervalTimed,
} from '@tikka/basic-types';
import { getWordGroupTranscriptText } from '../../content-utils';
import {
  ContentTranslation,
  RangeElement,
  Excerpt as ExceptElement,
  EditorStructural,
  IngestionStructural,
  IngestionElement,
} from '../../editorial-types';
import {
  BasicElementKind,
  StructuralContentKind,
  StructuralKind,
  EKind,
  WordGroupSubKind,
  StructuralMarkerKind,
} from '../../element-kinds';
import {
  onlyFirstCapitalized,
  stripChapterNoteOrderAnnotation,
  trimWordGroupUsagePunctuation,
} from '../../misc/editorial-string-utils';
import { AudioSlice } from '../catalog-types';
import {
  ClientChapter,
  ClientChapterComplete,
  ClientChapterNote,
  ClientElement,
  ClientNotation,
  ClientParagraph,
  ClientPassage,
  ClientSentence,
  ClientSic,
  ClientTricky,
} from '@tikka/client/client-types';
import {
  Element,
  Chapter,
  Sentence,
  Word,
  WordGroup,
  Paragraph,
  Structural,
  ElementNode,
} from '../ingestion-aliases';
import { Excerpt } from '../models/excerpt';
import { Unit } from '../models/unit';
import { UnitCaliDataLoader } from './unit-cali-data-loader';
import { CreateElementTreeRenderer } from '@tikka/elements/element-tree-renderer';
import { CreatePrecedence } from '@tikka/elements/precedence';
import { createLogger } from '@app/logger';
import { prefixed } from 'eventemitter3';
import { notEmpty } from '@utils/conditionals';
import {
  hasDirtyIdChars,
  randomString,
  sanitizeDirtyId,
} from '@masala-lib/utils';
import { ExcerptData, StudyData } from '@tikka/client/catalog-types';
const log = createLogger('player-cali-data-builder');

// needs a better home and typing
export function isSic(el: any): el is WordGroup {
  return el.subKind === WordGroupSubKind.SIC;
}

export function isVocab(el: any): el is WordGroup {
  return el.subKind === WordGroupSubKind.VOCAB;
}

type NumberedPassage = ClientPassage & {
  beginLineNumber?: number; // used for structural prompt example generation
  endLineNumber?: number;
  passageNumber?: number;
};

type NumberedChapter = ClientChapter & {
  beginLineNumber?: number; // used for structural prompt example generation
  endLineNumber?: number;
};

// copied from BogotaPlayerData
export const playerTreePrecedence = CreatePrecedence([
  EKind.CHAPTER, // synthetic interval
  EKind.CHAPTER_NOTE, // point
  EKind.CHAPTER_COMPLETE, // point
  EKind.PASSAGE, // synthetic interval
  EKind.PARAGRAPH, // synthetic interval
  // EKind.SEGMENT, TODO
  EKind.SENTENCE, // node
  EKind.WORD_GROUP, // node
  // WORD - implied
] as const);

export class PlayerCaliDataBuilder {
  unit: Unit;
  locale: LocaleCode;
  loader: UnitCaliDataLoader;
  // audioSlicings: AudioSlice[];
  excerpt: Excerpt;

  static async forExcerpt(excerpt: Excerpt) {
    await excerpt.unit.ensureFetchedVolume();
    // await excerpt.ensureClientSlug();
    const result = new PlayerCaliDataBuilder(
      excerpt.unit,
      excerpt.unit.defaultLocale
    );
    result.excerpt = excerpt;
    return result;
  }

  static async create(
    unit: Unit,
    locale: LocaleCode = undefined,
    loader: UnitCaliDataLoader = undefined
  ) {
    await unit.ensureFetchedVolume();
    const builder = new PlayerCaliDataBuilder(unit, locale, loader);
    await builder.loadElements();
    return builder;
  }

  constructor(
    unit: Unit,
    locale: LocaleCode = undefined,
    loader: UnitCaliDataLoader = undefined
  ) {
    this.unit = unit;
    if (!unit.fetchedVolume) {
      throw Error('PlayerCaliDataBuilder - fetchedVolume not populated');
    }
    this.locale = locale || unit.l1Default;
    this.loader = loader;
  }

  async loadElements(): Promise<ElementList<Element>> {
    if (!this.loader) {
      this.loader = await UnitCaliDataLoader.create(this.unit);
    }
    // keep handy for chapter data gen - not helpful - doesn't have full audio url
    // this.audioSlicings = this.chapterAudioSlicings();
    return this.loader.elements;
  }

  get elementNodes(): ElementNode[] {
    const treeModel = CreateElementTreeRenderer(
      this.elements,
      this.elements.words.values,
      playerTreePrecedence,
      [EKind.CHAPTER_NOTE, EKind.PASSAGE, EKind.CHAPTER_COMPLETE] as const,
      [
        EKind.CHAPTER,
        // beware, including PASSAGE in the span types resulted in passage nodes replicated for
        // each paragraph when generating the volume level data for the listening guide generation
        // EKind.PASSAGE,
        EKind.PARAGRAPH,
        EKind.SENTENCE,
        EKind.WORD_GROUP,
      ] as const
    );
    const tree = treeModel.getTreeOfNodes();
    return tree;
  }

  get elements(): ElementList<Element> {
    return this.loader.elements;
  }

  resolveTranslation(element: Element): ContentTranslation {
    // todo: think more about empty string vs null/undefined
    return this.loader.translationSet[element.id]?.content; // as string) || null;
  }

  resolveExcerptSentences(): [Sentence, Sentence] {
    const excerptElement = this.loader.elements.getElement(
      this.excerpt.data.elementId
    ) as unknown as ExceptElement;
    if (!excerptElement) {
      throw Error(
        `excerpt element not found for element id: ${this.excerpt.data.elementId}, excerpt id: ${this.excerpt.id}`
      );
    }

    const sentences = this.loader.elements.filterByKind(EKind.SENTENCE);
    const beginSentence = sentences.getElementContainingWordId(
      excerptElement.anchor.wordId
    );
    const endSentence = sentences.getElementContainingWordId(
      excerptElement.endAnchor.wordId
    );
    if (!beginSentence || !endSentence) {
      throw Error(
        `unable to resolve excerpt sentence range - excerpt id: ${this.excerpt.id}`
      );
    }
    return [beginSentence, endSentence];
  }

  // async asyncBuildData(): Promise<object> {
  //   await this.loadElements();
  //   return this.buildData();
  // }

  cleanupElement(element: any /*IntervalIndexedElement*/): any {
    const elementAny = element as any; // todo: revisit typing
    elementAny.l1 = this.resolveTranslation(element as Element);
    if (isSic(element)) {
      if (elementAny.l1?.note && !element.content.canonical) {
        console.log(
          `patching sic(${element.id}), with only note: ${elementAny.l1.note}`
        );
        // hack legacy imported sic data
        element.content.canonical = elementAny.l1.note;
        elementAny.l1.note = undefined;
      }
    }
    // derive default vocab canonical text when not explicitly provided
    if (isVocab(element)) {
      if (!element.content.canonical) {
        const usageText = this.getWordGroupUsageText(element);
        element.content.canonical = usageText;
      }
    }

    return element;
  }

  cleanupWord(word: Word): Word {
    word.text = toUnicodePunctuation(word.text);
    word.kind = undefined;
    // wordAny.debugId = word.id;
    word.id = undefined;
    if (isEmpty((word as any).style)) {
      (word as any).style = undefined;
    }
    (word as any).active = undefined;
    return word;
  }

  // buildData(): object /*JwnextScriptData*/ {
  //   if (this.excerpt) {
  //     return this.buildExcerptData();
  //   } else {
  //     return this.buildUnitData();
  //   }
  // }

  // buildUnitData(): object {
  //   const chapterDatas: object[] = [];
  //   for (const chapterSpan of this.loader.chapterSpansEL.values) {
  //     chapterDatas.push(this.buildChapterData(chapterSpan));
  //   }
  //   return { chapters: chapterDatas };
  // }

  buildChapterData(chapterSpan: Chapter): Partial<StudyData> {
    const rangeData = this.buildRangeData(chapterSpan);

    // not needed here, handled by similar code in volume-cali-data-builder
    // const audioUrl = this.unit.data.audioSlicings[chapterSpan.position].finalAudioUrl;

    const title = this.chapterTitle(chapterSpan);
    const result = {
      ingestVersion: this.unit.volume.data.pendingVersion,
      ingestedAt: new Date().toISOString(),
      volumeDataUrl: this.unit.volume.data.pendingDataUrl,

      storySlug: this.unit.volume.clientSlug,
      // unitSlug: this.unit.slug,
      unitNumber: this.unit.data.unitNumber,
      // todo: provide chapterNumber here also?
      title,

      // ingestVersion: this.unit.data.jwnextIngestVersion,
      // audioUrl: this.unit.data.audioFinalStorageUrl,
      ...rangeData,
    };
    return result;
  }

  // note, also used by volume-cali-data-builder
  chapterTitle(chapterSpan: Chapter): string {
    // let text = undefined;
    // if (this.unit.data.structuralContentInL1) {
    //   const translation = this.resolveTranslation(chapterSpan);
    //   if (translation && typeof translation === 'string') {
    //     text = translation;
    //   }
    // }
    // if (!text) {
    //   text = chapterSpan.content?.text;
    // }
    // return transformPlayerText(text);

    const clientData = this.toClientChapter(chapterSpan);
    return clientData.title.l2;
  }

  buildExcerptData(): ExcerptData {
    const [beginSentence, endSentence] = this.resolveExcerptSentences();
    const rangeData = this.buildRangeData(beginSentence, endSentence);

    // use review catalog for now
    const volumeDataUrl = this.unit.volume.data.reviewVersion?.dataUrl;

    const result = {
      slug: this.excerpt.clientSlug,
      volumeSlug: this.unit.volume?.clientSlug,
      unitSlug: this.unit.clientSlug,
      title: this.excerpt.data.title,
      ingestVersion: this.excerpt.data.ingestVersion,
      // ingestedAtTs: epochSecondsFloat(), // todo: use different props for iso string, vs timestamp num
      ingestedAt: new Date().toISOString(),
      l2: this.unit.data.l2Code,
      l1: this.locale,
      volumeDataUrl,
      audioUrl: this.excerpt.data.slicedAudioUrl,
      // soundbite specific data
      // beware, largely duplicated w/ excerpt-catalog-cali-data-builder
      // todo: refactor to share
      prompt: this.excerpt.data.prompt || this.excerpt.data.promptEn,
      vocabulary: this.excerpt.data.vocabulary,
      explanation:
        this.excerpt.data.explanation || this.excerpt.data.explanationEn,
      releaseDate: this.excerpt.data.releaseDate,
      category: this.excerpt.categorySlug,
      // categories: this.excerpt.data.categories,
      ...rangeData,
    };
    return result as unknown as ExcerptData; // todo: tighten up typing to avoid hard cast
  }

  buildRangeData(
    beginElement: RangeElement,
    endElement: RangeElement = undefined
  ) /*: object*/ {
    if (!endElement) {
      endElement = beginElement;
    }
    const allWords = this.loader.elements.words.values; // already filtered to active words

    const beginAddress = beginElement.address;
    const beginMillis = beginElement.time;

    const endAddress = endElement.endAddress;
    let endMillis = endElement.endTime;
    // TODO pad at end of unit not currently handled
    if (endAddress + 1 < allWords.length) {
      const afterWord = allWords[endAddress + 1];
      const gap = afterWord.time - endMillis;
      const halfGap = Math.floor(gap / 2);
      const adjust = Math.min(halfGap, 400);
      console.log('adjust: ' + adjust);
      endMillis += adjust;
    }

    const durationMillis = endMillis - beginMillis;

    const range: IndexRange = { begin: beginAddress, end: endAddress }; // confirm inclusive
    const masalaElements =
      this.loader.elements.getElementsStartWithinWordIndexRange(
        range
      ) as (Element &
        IntervalIndexed & {
          subKind?: string;
        })[];

    let firstParagraph = masalaElements.find(
      el => el.kind === EKind.PARAGRAPH && !!el.content
    ) as IngestionStructural;
    let currentSearchElement: IngestionElement = firstParagraph;

    if (firstParagraph?.address !== beginAddress) {
      log.error(
        `first paragraph unexpectedly not aligned with range conent start, fpa: ${String(
          firstParagraph?.address
        )}, ba: ${String(beginAddress)}`
      );
      firstParagraph = {
        kind: 'PARAGRAPH',
        id: `PARAGRAPH:${randomString(12)}`,
        content: {
          text: null as any,
        },
        address: beginAddress,
        endAddress: firstParagraph ? firstParagraph.address : endAddress,
        time: beginMillis,
        endTime: firstParagraph ? firstParagraph.time : endMillis,
      };
      currentSearchElement = masalaElements[0];
      masalaElements.unshift(firstParagraph);

      // todo: insert paragraph element - could happen for soundbite starting mid paragraph
      // @jason, what's the best way to do this?
    }

    if (firstParagraph && isEmpty(firstParagraph.content?.text)) {
      log.warn(`missing initial speaker, scanning...`);
      for (;;) {
        const priorId = this.loader.elements.prevId(currentSearchElement.id);
        const candidate = this.loader.elements.findPreviousOfKinds(
          // currentParagraph.id, // resulted in infinite loop and wedged browser, seems like this is poorly named and the search is inclusive
          priorId,
          ['PARAGRAPH']
        );
        log.info(
          `candidate - id: ${candidate?.id}, content: ${candidate?.content?.text}`
        );
        if (candidate) {
          if (notEmpty(candidate.content?.text)) {
            firstParagraph.content = candidate.content;
            break;
          } else {
            currentSearchElement = candidate;
            // loop
          }
        } else {
          log.error(`unable to find previous speaker of ${firstParagraph.id}`);
          break;
        }
      }
    }

    // // derive default vocab canonical text when not explicitly provided
    // for (const element of masalaElements) {
    //   if (isVocab(element)) {
    //     if (!element.content.canonical) {
    //       const usageText = this.getWordGroupUsageText(element);
    //       element.content.canonical = usageText;
    //     }
    //   }
    // }

    for (const element of masalaElements) {
      // consider cloning instead of mutating
      element.address = element.address - beginAddress;
      element.endAddress = element.endAddress - beginAddress;
      // note, sentences have timing data, but paragraphs and word groups don't
      if (isIntervalTimed(element)) {
        element.time = element.time - beginMillis;
        element.endTime = element.endTime - beginMillis;
      }

      this.cleanupElement(element);
    }

    const navStopWordIndexes: number[] = [];
    const navStopWordIds = this.loader.navStopWordIds;

    const sentenceHeadWordIds = this.loader.elements
      .filterByKind(EKind.SENTENCE)
      .values.map(el => (el as any).anchor.wordId);

    // todo: confirm implication of delete words and figure out how to best
    // normalize the serialized player data
    // const words = allWords.filter(
    //   // could probably also just slice the array
    //   word => word.time >= beginMillis && word.time <= endMillis
    // );

    // beware, there is still the potential for the a word timestamp out overhang the chapter time range
    const words = allWords.slice(beginAddress, endAddress + 1);

    // for (const word of words) {
    // is there a cleaner way to get an index with a for/of loop?
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      // is there a better way to shift the indexes from raw list?
      if (
        navStopWordIds.includes(word.id) ||
        sentenceHeadWordIds.includes(word.id) // implicitly include sentence headwords in nav stop list
      ) {
        navStopWordIndexes.push(i);
        // todo, consider cross-checking with the beginWordIndex
      }
      word.time = word.time - beginMillis;
      word.endTime = word.endTime - beginMillis;
      this.cleanupWord(word);
    }

    // const speakers = this.unit.volume.clientSpeakerDatas(this.locale);

    // contains both non-word groups + sics
    const elements = [] as ClientElement[];
    for (const element of masalaElements) {
      let handled = false;
      // if ((element as any).l1?.note) {
      //   elements.push(this.toClientNotation(element as WordGroup));
      //   handled = true;
      // }
      if (element.subKind === WordGroupSubKind.VOCAB) {
        elements.push(this.toClientNotation(element as WordGroup));
      } else if (element.subKind === WordGroupSubKind.SIC) {
        elements.push(this.toClientSic(element as WordGroup));
      } else if (element.subKind === WordGroupSubKind.TRICKY) {
        elements.push(this.toClientTricky(element as WordGroup));
      } else if (element.kind === EKind.SENTENCE) {
        elements.push(this.toClientSentence(element));
      } else if (element.kind === EKind.PARAGRAPH) {
        elements.push(this.toClientParagraph(element));
      } else if (element.kind === EKind.PASSAGE) {
        elements.push(this.toClientPassage(element));
      } else if (element.kind === EKind.CHAPTER) {
        elements.push(this.toClientChapter(element));
      } else if (element.kind === EKind.CHAPTER_NOTE) {
        elements.push(this.toClientChapterNote(element));
      } else if (element.kind === EKind.CHAPTER_COMPLETE) {
        elements.push(this.toClientChapterComplete(element));
      } else {
        if (!handled) {
          console.log(`unhandled element type: ${element.kind}`); //return element;
        }
      }
    }

    const result = {
      durationMillis,
      elements,
      words,
      navStopWordIndexes,
      // speakers, // todo: consider letting client pull from catalog volume data instead of duplicating into chapter payload
    };
    return result;
  }

  buildStoryDetailElementsData(): ClientElement[] {
    const masalaElements = this.loader.elements.values as (Element &
      IntervalIndexed & {
        subKind?: string;
      })[]; // duplicated his messy typing from above, @jason how should be hadled?

    const elements = [] as ClientElement[];
    for (const element of masalaElements) {
      this.cleanupElement(element);
      if (element.kind === EKind.CHAPTER) {
        elements.push(this.toClientChapter(element));
      } else if (element.kind === EKind.CHAPTER_NOTE) {
        elements.push(this.toClientChapterNote(element));
      } else if (element.subKind === WordGroupSubKind.VOCAB) {
        // } else if ((element as any).l1?.note) {
        elements.push(this.toClientNotation(element as WordGroup));
      }
    }

    return elements;
  }

  buildStructuralExampleText(): string[] {
    const masalaElements = this.loader.elements.values as (Element &
      IntervalIndexed & {
        subKind?: string;
      })[]; // duplicated his messy typing from above, @jason how should be hadled?

    let lineNumber = 0;
    let chapterNumber = 0;
    let passageNumber = 0;
    let lastChapter: NumberedChapter = undefined;
    let lastPassage: NumberedPassage = undefined;
    const elements: (NumberedChapter | NumberedPassage)[] = [];
    const output: string[] = [];

    for (const element of masalaElements) {
      this.cleanupElement(element);
      if (element.kind === EKind.SENTENCE) {
        lineNumber++;
      }
      if (element.kind === EKind.CHAPTER) {
        chapterNumber++;
        if (lastChapter) {
          lastChapter.endLineNumber = lineNumber;
        }
        const chapter = this.toClientChapter(element) as NumberedChapter;
        chapter.beginLineNumber = lineNumber + 1;
        chapter.position = chapterNumber;
        elements.push(chapter);
        lastChapter = chapter;
      } else if (element.kind === EKind.PASSAGE) {
        passageNumber++;
        if (lastPassage) {
          lastPassage.endLineNumber = lineNumber;
        }
        const passage = this.toClientPassage(element) as NumberedPassage;
        passage.beginLineNumber = lineNumber + 1;
        passage.passageNumber = passageNumber;
        elements.push(passage);
        lastPassage = passage;
      }
    }
    if (lastChapter) {
      lastChapter.endLineNumber = lineNumber;
    }
    if (lastPassage) {
      lastPassage.endLineNumber = lineNumber;
    }
    const lX = this.locale === 'en' ? 'l1' : 'l2';

    for (const element of elements) {
      if (element.kind === EKind.CHAPTER) {
        output.push(
          `Chapter ${element.position}: [Lines ${element.beginLineNumber}-${element.endLineNumber}]: ${element.title?.[lX]}`
        );
      } else if (element.kind === EKind.PASSAGE) {
        output.push(
          `Scene ${element.passageNumber}: [Lines ${element.beginLineNumber}-${element.endLineNumber}]: ${element.hint?.[lX]}`
        );
      }
    }
    return output;
  }

  toClientNotation(wordGroup: WordGroup): ClientNotation {
    // TODO: match existing vocab slug generation
    // const firstWord: Word = this.loader.words.values[wordGroupElement.address];
    // const id = `${Math.round(firstWord.time / 100)}-${slugify(firstWord.text)}`;

    let usageText = this.getWordGroupUsageText(wordGroup);
    if (usageText === wordGroup.content.canonical) {
      usageText = undefined; // only include when not implicitly used as canonical
    }

    return {
      // id: `NOTATION:${wordGroup.id}`,
      id: updateIdWithKind(wordGroup.id, BasicElementKind.NOTATION),
      kind: BasicElementKind.NOTATION,
      ...pick(wordGroup, 'subKind', 'address', 'endAddress'),
      headword: wordGroup.content.canonical,
      usageText,

      // (this.resolveTranslation(wordGroup) as WordGroupContent).note,
      // translation already resolved and patched in as 'l1' within cleanupElements()
      note: (wordGroup as any).l1?.note,
    };
  }

  getWordGroupUsageText(group: WordGroup) {
    if (group.content.usage && group.content.usage.trim().length > 0) {
      return group.content.usage.trim();
    }
    let text = stripUnderscores(
      getWordGroupTranscriptText(group as any, this.loader.elements.words)
    );
    if (onlyFirstCapitalized(text) && !group.content.preserveCase) {
      text = text.toLocaleLowerCase();
    }
    return trimWordGroupUsagePunctuation(text).trim(); // need one more trim for the stupid em-dashes!
  }

  // todo: consider omitting id's aside from notations (which need to support vocab refs)
  // and letting the client assign upon dataloading
  toClientSic(wordGroup: WordGroup): ClientSic {
    return {
      id: updateIdWithKind(wordGroup.id, BasicElementKind.SIC),
      kind: BasicElementKind.SIC,
      ...pick(wordGroup, 'address', 'endAddress'),
      intended: wordGroup.content.canonical,
    };
  }

  toClientTricky(wordGroup: WordGroup): ClientTricky {
    return {
      id: updateIdWithKind(wordGroup.id, BasicElementKind.TRICKY),
      kind: BasicElementKind.TRICKY,
      ...pick(wordGroup, 'address', 'endAddress'),
    };
  }

  toClientSentence(element: Sentence): ClientSentence {
    return {
      id: updateIdWithKind(element.id, BasicElementKind.SENTENCE),
      kind: BasicElementKind.SENTENCE,
      ...pick(element, 'address', 'endAddress', 'time', 'endTime'),
      translation: (element as any).l1,
    };
  }

  toClientParagraph(element: Paragraph): ClientParagraph {
    return {
      id: updateIdWithKind(element.id, StructuralKind.PARAGRAPH),
      kind: StructuralKind.PARAGRAPH,
      ...pick(element, 'address', 'endAddress', 'time', 'endTime'),
      speakerLabel: element.content?.text,
    };
  }

  toClientPassage(element: Structural): ClientPassage {
    // beware, largely duplicated logic with toClientChapter
    let l1 = transformPlayerText((element as any).l1);
    let l2 = transformPlayerText(element.content?.text);

    if (this.unit.data.structuralContentInL1) {
      const temp = l1;
      l1 = l2;
      l2 = temp;
    }

    if (!l1) {
      // a lot of older lupa content is missing l2 structural content, so need to hack around that here
      l1 = l2;
      l2 = '';
    }

    return {
      id: updateIdWithKind(element.id, StructuralContentKind.PASSAGE),
      kind: StructuralContentKind.PASSAGE,
      ...pick(element, 'address', 'time'),
      hint: { l2, l1 },
    };
  }

  toClientChapter(element: Chapter): ClientChapter {
    // beware, largely duplicated logic with toClientPassage
    let l1 = transformPlayerText((element as any).l1);
    let l2 = transformPlayerText(element.content?.text);
    if (this.unit.data.structuralContentInL1) {
      const temp = l1;
      l1 = l2;
      l2 = temp;
    }

    return {
      id: updateIdWithKind(element.id, StructuralKind.CHAPTER),
      kind: StructuralKind.CHAPTER,
      ...pick(element, 'address', 'endAddress', 'time', 'endTime'),
      title: { l2, l1 },
    };
  }

  toClientChapterNote(element: Structural): ClientChapterNote {
    // todo: factor with passage hints
    let l2 = element.content?.text;
    let l1 = (element as any).l1;
    if (!l1) {
      // a lot of older lupa content is missing l2 structural content, so need to hack around that here
      l1 = l2;
      l2 = '';
    }
    l1 = stripChapterNoteOrderAnnotation(l1);
    l2 = stripChapterNoteOrderAnnotation(l2);

    return {
      id: updateIdWithKind(element.id, StructuralContentKind.CHAPTER_NOTE),
      kind: StructuralContentKind.CHAPTER_NOTE,
      // ...pick(element, 'address'),
      address: element.address,
      text: { l2, l1 },
    };
  }

  toClientChapterComplete(element: Structural): ClientChapterComplete {
    return {
      id: updateIdWithKind(element.id, StructuralMarkerKind.CHAPTER_COMPLETE),
      kind: StructuralMarkerKind.CHAPTER_COMPLETE,
      address: element.address,
    };
  }

  chapterAudioSlicings(): AudioSlice[] {
    const audioSlicings: AudioSlice[] = [];
    if (!this.unit.canIngest) {
      return audioSlicings;
    }

    // normal ingestion
    const sourceUrlHash: number = stringHash(
      this.unit.data.audioFinalStorageUrl
    );
    const audioPathPrefix = `${
      this.unit.baseAudioStoragePath
    }/${sourceUrlHash.toString(16)}`;

    const chapterSpansEL: ElementList<Chapter> = this.loader.chapterSpansEL;

    for (const chapterSpan of chapterSpansEL.values) {
      const position = chapterSpansEL.getIndex(chapterSpan.id);
      const startMillis = chapterSpan.time;
      const finishMillis = chapterSpan.endTime;
      const finalAudioPath = `${audioPathPrefix}-${startMillis}-${finishMillis}.mp3`;
      // const finalAudioUrl = publicUrlForPath(finalAudioPath);
      // chapter.normalAudioUrl = finalAudioUrl;

      const data = {
        position,
        startMillis,
        finishMillis,
        finalAudioPath,
        // finalAudioUrl, // resolved later
      };
      audioSlicings.push(data);
    }
    console.log(`chapterAudioSlicing: ${JSON.stringify(audioSlicings)} `);
    return audioSlicings;
  }
}

const updateIdWithKind = <KT extends EKind>(
  id: string,
  kind: KT
): IDTOfKT<KT> => {
  const parts = id.split(':');
  if (parts.length !== 2) {
    // throw Error(`unexpected id format: ${id}`);
    // note, this currently gets triggered for implicit paragraph heads at passage starts
    console.error(`unexpected id format: ${id}`);
  }
  if (parts.length < 2) {
    parts.unshift(String(kind));
  } else {
    parts[0] = String(kind);
  }
  // note, must only sanitize the tail part of the id because we have `_` in some of the element kind prefixes
  if (hasDirtyIdChars(parts[1])) {
    const sanitized = sanitizeDirtyId(parts[1]);
    log.warn(`transforming dirty id: ${parts[1]} -> ${sanitized}`);
    parts[1] = sanitized;
  }
  const result = parts.join(':');
  return result as any; // @jason is the `any` necessary here?
};
