import * as React from 'react';

export function ArrowRightCircleIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9.83579 9.75L7.91789 11.6679L9.33211 13.0821L12.7071 9.70711L13.4142 9L12.7071 8.29289L9.33211 4.91789L7.91789 6.33211L9.83579 8.25H5.25V9.75H9.83579Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
