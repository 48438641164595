import * as React from 'react';
import { pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { SpeakerV5Data } from '@masala-lib/catalog/bogota/bogota-types';
import { NavLink } from 'react-router-dom';
import { Button } from 'rebass';
import { isNA } from '@masala-lib/misc/editorial-string-utils';

interface Props {
  dto: SpeakerV5Data;
  onSubmit: (dto: SpeakerV5Data) => void;
  cancelNavPath: string;
  onCancel?: () => void;
  l1Locale: string;
  l2Locale: string;
}

export const SpeakerForm = ({
  dto,
  onSubmit,
  cancelNavPath,
  onCancel,
  l1Locale,
  l2Locale,
}: Props) => {
  const defaultValues = pick(dto, [
    'label',
    'bio',
    'accent',
    'label-l1',
    'bio-l1',
    'accent-l1',
  ]);

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const hasNaBio = isNA(dto.bio);

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          {...getFieldProps('label')}
          label={`Label L2 (${l2Locale})`}
          hint="do not edit unless you understand the implications"
          required
        />
        {hasNaBio ? (
          <Input
            {...getFieldProps('label-l1')}
            label={`Label L1 (${l1Locale})`}
            hint="optional. only needed for generic L2 labels (i.e. 'Periodista')"
          />
        ) : null}
        <Input
          {...getFieldProps('bio')}
          label={`Bio L2 (${l2Locale})`}
          hint="expected once 'structural content ready', 'n/a' will omit from 'Voices' story info section"
        />
        {hasNaBio ? null : (
          <>
            <Input
              {...getFieldProps('bio-l1')}
              label={`Bio L1 (${l1Locale})`}
              hint="leave blank if Bio L2 is 'n/a'"
            />
            <Input
              {...getFieldProps('accent')}
              label={`Accent L2 (${l2Locale})`}
              hint="optional"
            />
            <Input
              {...getFieldProps('accent-l1')}
              label={`Accent L1 (${l1Locale})`}
              hint="optional. will default to Accent L2 if blank"
            />
          </>
        )}
        {onCancel ? (
          <FormButtons submitLabel={'Save changes'} onCancel={onCancel} />
        ) : (
          <>
            {cancelNavPath ? (
              <>
                <NavLink to={cancelNavPath}>[Cancel]</NavLink> &nbsp;{' '}
              </>
            ) : null}
            <Button type="submit" backgroundColor={'teal'} px={32}>
              Save changes
            </Button>
          </>
        )}
      </form>
    </OnePaneLayout>
  );
};
