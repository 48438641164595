import { padStart } from 'lodash';

export const formatDuration = (millis: number) => {
  const durationInSeconds = millis / 1000;
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds - minutes * 60);
  return `${padStart(minutes.toString(), 2, '0')}:${padStart(
    seconds.toString(),
    2,
    '0'
  )}`;
};
