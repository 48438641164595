import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  & > button {
    --button-background-color: var(--color-transparent);
    --button-color: var(--color-gray500);
    --button-border-color: var(--color-gray100);
    display: flex;
    background: none;
    background: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    color: var(--button-color);
    padding: 4px 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    &[disabled] {
      --button-background-color: var(--color-gray100);
      --button-color: var(--color-gray300);
      cursor: not-allowed;
    }

    &:hover {
      --button-background-color: var(--color-gray50);
      --button-color: var(--color-gray600);
    }

    & > .btn-icon {
      color: var(--color-gray200);
      width: 20px;
      height: 20px;
    }
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const Button = ({
  label,
  onClick,
  icon,
  ...buttonProps
}: ButtonProps) => {
  return (
    <Wrapper>
      <button onClick={onClick} {...buttonProps}>
        <span className="btn-label">{label}</span>
        {icon ? <span className="btn-icon">{icon}</span> : null}
      </button>
    </Wrapper>
  );
};
