import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UnitData } from '../catalog-types';
import { get, isEmpty, isNil, sortBy } from 'lodash';
import { Unit } from '../models/unit';
import { EMPTY_FILTER, EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { VolumeManager } from './volume-manager';
import { sniffUnitHasUpperCaseWordIds } from '../../editorial/db/mutation-actions';
import { createLogger } from '@app/logger';

const log = createLogger('unit-manager');
export class UnitManager extends EntityManager<Unit, UnitData> {
  // episodeList: EpisodeList;
  // audioProcessingJobList: AudioProcessingJobList;
  // transcriptionJobList: TranscriptionJobList;

  // modelMap: ObservableMap<string, Unit>;

  @observable.ref
  volumeIdFilter: string = null;

  disposers: (() => void)[] = [];

  constructor() {
    super();
    this.modelMap = observable(new Map(), { deep: false });
    makeObservable(this);

    // this.episodeList = new EpisodeList();
    // this.audioProcessingJobList = new AudioProcessingJobList();
    // this.transcriptionJobList = new TranscriptionJobList();

    this.disposers.push(() => this.close());
  }

  // setVolumeIdFilter(volumeId: string): void {
  //   if (volumeId) {
  //     this.setFilter('volumeId', volumeId);
  //   }
  //   // this.volumeIdFilter = volumeId;
  // }

  setFilterText(text: string) {
    runInAction(() => {
      this.filterText = text;
    });
  }

  baseList(): Unit[] {
    let list = this.rawList;
    return list;
  }

  @computed
  get filteredList() {
    let list = this.rawList;

    if (!isEmpty(this.filterText)) {
      list = list.filter(model => model.filterMatch(this.filterText));
    }

    /// the filters get composed. I'm not sure the order is important here.
    // maybe we should take the order from `filterableFields`
    this.filters.forEach((filter, filterName) => {
      const fvalue: string = filter.value;
      if (fvalue) {
        list = list.filter(item => {
          if (filterName === 'warningsStatus') {
            if (fvalue === 'HAS_WARNINGS') {
              return item.data.warningCount > 0;
            }
            if (fvalue === 'HAS_UNRESOLVED') {
              return item.data.openThreadCount > 0;
            }
          }

          const value = get(item, filterName);
          // console.log('FILTER', { filter, filterName, value, expected: fvalue });
          if (fvalue === EMPTY_FILTER) {
            return isEmpty(value);
          }

          return fvalue.split('|').includes(value);

          // if (fvalue.includes('|')) {
          // } else {
          //   return isEqual(value, fvalue);
          // }
        });
      }
    });

    switch (this.sorting.fieldName) {
      case 'unitName':
        list = sortBy(list, ['normalizedName']);
        break;
      case 'releaseDate':
        list = sortBy(list, ['data.releaseDate', 'normalizedName']);
        break;
      case 'volumeName':
        list = sortBy(list, ['volumeNormalizedName', 'normalizedName']);
        break;
      case 'createdAt':
        list = sortBy(list, ['data.createdAt', 'normalizedName']);
        break;
      case 'updatedAt':
        list = sortBy(list, ['data.updatedAt', 'normalizedName']);
        break;

      default:
        break;
    }

    if (this.sorting.order === 'desc') {
      list = list.reverse();
    }

    return list;
  }

  // todo: utilize db query level filter for this
  byVolume(volumeId: string): Unit[] {
    // const count = this.rawList.filter(model => model.volumeId === volumeId).length;
    // console.log(`byVolume(${volumeId}), count: ${count}`);
    if (isEmpty(volumeId)) {
      return this.rawList;
    } else {
      return this.rawList.filter(
        model =>
          model.volumeId === volumeId ||
          (isEmpty(model.volumeId) && volumeId === '_')
      );
    }
  }

  async destroyOrphans(): Promise<void> {
    const orphanIds: string[] = [];
    this.rawList.forEach(unit => {
      if (isEmpty(unit.volumeId)) {
        orphanIds.push(unit.id);
      }
    });
    console.log(`orphans to destroy: ${JSON.stringify(orphanIds)}`);
    orphanIds.forEach(id => this.delete({ id: id }));
  }

  get collectionName(): string {
    return CatalogCollections.UNIT_METADATA;
  }

  // better name here would probably be `buildModel`
  createModel(data: { id: string }): Unit {
    return new Unit(data);
  }

  async createWithDto(dto: UnitData): Promise<Unit> {
    const result = await super.createWithDto(dto);
    if (result.volume) {
      result.volume.ensureUnitNumbers();
    }
    return result;
  }

  // listenAll(): void {
  //   super.listenAll();
  // }

  // async loadAll(): Promise<void> {
  //   await super.loadAll();
  // }

  close() {
    // no longer needed
  }

  // just returns cached instance if available, otherwise return placeholder
  // and do an async load
  fetchById(id: string): Unit {
    console.log(`UnitManager.fetchById(${id})`);
    if (isEmpty(id)) {
      return null;
    }
    let candidate = this.modelMap.get(id);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
      this.loadById(id); // will run async and update the already returned instance
    }
    return candidate;
  }

  async loadById(id: string): Promise<Unit> {
    const model = await super.loadById(id);
    if (model) {
      await model.ensureParents();
    }
    return model;
  }

  getOrCreateById(id: string): Unit {
    let candidate = this.modelMap.get(id);
    // console.log(`getOrCreate(${id}) candidate: ${JSON.stringify(candidate)}`);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
    }
    return candidate;
  }

  async loadSoloById(id: string): Promise<Unit> {
    const model = await super.loadSoloById(id);
    if (model) {
      model.fetchedVolume = await new VolumeManager().loadSoloById(
        model.volumeId
      );
    }
    return model;
  }

  async loadSoloBySlug(slug: string): Promise<Unit> {
    const model = await super.loadSoloBySlug(slug);
    if (model) {
      model.fetchedVolume = await new VolumeManager().loadSoloById(
        model.volumeId
      );
    }
    return model;
  }

  async ensureVolumeLoaded(volumeId: string): Promise<void> {
    log.info(
      `ensureVolumeLoaded(${volumeId}) - current count: ${this.modelMap.size}`
    );
    if (isEmpty(volumeId)) {
      log.error('ensureVolumeLoaded - missing volumeId');
      return null;
    }
    const querySnapshot = await this.collectionRef()
      .where('volumeId', '==', volumeId)
      .get();
    this.applyQuerySnapshot(querySnapshot);
    log.info(`current count: ${this.modelMap.size}`);
  }

  // async backupIfNeededForAll() {
  //   console.log(`backupIfNeededForAll()`);
  //   await this.loadAll();
  //   let count = 0;
  //   for (const unit of this.rawList) {
  //     const needed = await unit.backupIfNeeded();
  //     if (needed) {
  //       count++;
  //     }
  //     if (count > 5) {
  //       break; // temp
  //     }
  //   }
  //   console.log(`backup count: ${count}`);
  // }

  async trimTranslationVersionsForAll() {
    console.log(`trimTranslationVersions()`);
    // beware, this doesn't honor the list view structured filters, only the text filter
    const units = this.list.filter(unit => unit.scriptInitted);
    console.log(`matching unit count: ${units.length}`);
    for (const unit of units) {
      console.log(`trimming unit: ${unit.slug} (${unit.id})`);
      await unit.trimTranslationVersions();
    }
  }

  async repairWordIdsForAll() {
    console.log(`repairWordIdsForAll()`);
    // beware, this doesn't honor the list view structured filters, only the text filter
    const units = this.list.filter(unit => unit.scriptInitted);
    console.log(`matching unit count: ${units.length}`);
    let repairCount = 0;
    for (const unit of units) {
      console.log(`repairing unit: ${unit.slug} (${unit.id})`);
      const status = await unit.repairWordIds();
      if (status === 'complete') {
        repairCount++;
      }
    }
    console.log(`repair count: ${repairCount}`);
    window.alert(`repair count: ${repairCount}`);
  }

  async countRepairNeeded() {
    const units = this.list.filter(unit => unit.scriptInitted);
    let repairCount = 0;
    for (const unit of units) {
      if (await sniffUnitHasUpperCaseWordIds(unit.episodeKey)) {
        repairCount++;
      }
    }
    console.log(`repair count: ${repairCount}`);
    window.alert(`repair count: ${repairCount}`);
  }

  async sniffAllForDuplicateWordIds() {
    const units = this.list.filter(unit => unit.scriptInitted);
    let repairCount = 0;
    for (const unit of units) {
      if (await unit.sniffForDuplicateWordIds()) {
        console.log(`corrupted unit: ${unit.name} (${unit.slug})`);
        repairCount++;
      }
    }
    console.log(`corrupted count: ${repairCount}`);
    window.alert(`corrupted count: ${repairCount}`);
  }

  // async patchAndPromoteAllWithoutVolumeDuration() {
  //   console.log(`patchIngestAllWithoutVolumeDuration()`);
  //   const units = this.allWithoutVolumeDuration();
  //   console.log(`unit count: ${units.length}`);
  //   for (const unit of units) {
  //     console.log(`patching unit: ${unit.slug} (${unit.id})`);
  //     await unit.ingest();
  //     await unit.promoteInternalToStaged();
  //   }
  //   console.log(`patching complete`);
  // }

  // allWithoutVolumeDuration(): Unit[] {
  //   console.log(`patchIngestAllWithoutVolumeDuration()`);
  //   const units = this.list.filter(
  //     unit => unit.canPatchIngest && !unit.data.volumeDurationMinutes
  //   );
  //   console.log(`matching unit count: ${units.length}`);
  //   return units;
  // }

  private static instance: UnitManager;

  public static getInstance({
    listen = true, // todo: should be able to make default false, only needed for console
  }: {
    listen?: boolean;
  } = {}): UnitManager {
    if (!UnitManager.instance) {
      UnitManager.instance = new UnitManager();
      if (listen) {
        UnitManager.instance.listenAll();
      }
    }
    return UnitManager.instance;
  }
}
