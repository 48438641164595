import * as React from 'react';
import { MasterSidebar } from '../master-sidebar';
// import { SimpleStatusBar } from '../simple-status-bar';

import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';

import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Wrapper = styled.div`
  --color-sidebar: var(--color-gray50);

  display: grid;
  grid-template-columns: 220px 1fr;
  grid-template-areas: 'sidebar main';
  grid-column-gap: 0;
  min-height: 100vh;
  & > .master-sidebar {
    grid-area: sidebar;
    background: var(--color-sidebar);
    border-right: 1px solid var(--color-gray100);
  }

  & > .master-main {
    grid-area: main;
    height: 100%;
  }
`;

interface MasterLayoutProps {
  children?: React.ReactNode;
}

export const MasterLayout = ({ children }: MasterLayoutProps) => {
  // useTitle(title);
  return (
    <Wrapper>
      <div className="master-sidebar">
        <MasterSidebar />
      </div>
      <main className="master-main">
        <Outlet />
      </main>
    </Wrapper>
  );
};
