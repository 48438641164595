import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { Author } from '@masala-lib/catalog/models/author';
import { Avatar } from '../../components/avatar';
import { Shelf } from '../shared/shelf';
import { Table } from '../shared/table';

interface AuthorsTableProps {
  authors: Author[];
}

export const AuthorsTable = observer(({ authors }: AuthorsTableProps) => {
  return (
    <Table
      data={authors}
      cells={[
        {
          header: 'Name',
          render: author => {
            return (
              <Flex alignItems={'center'}>
                <Box mr={2}>
                  <Avatar src={author.data.avatarUrl} />
                </Box>
                <Link to={author.navPath}>
                  {author.name} ({author.data.title})
                </Link>
              </Flex>
            );
          },
        },
      ]}
    />
  );
});
