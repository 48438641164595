// // open EntityFuncs0

import { ElementId } from '@tikka/basic-types';
import { isNull } from 'lodash';
import { notNull } from '@utils/conditionals';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';

// // TODO blurred wording between return screen placement and set placement on scroll
export enum Location {
  NotExist = 0,
  BelowScreen = 1,
  OnScreen = 2,
  AboveScreen = 3,
}

export function getVisibilityDomId(domId: string, containerDomId: string) {
  const node = document.getElementById(domId);
  if (isNull(node)) {
    return Location.NotExist;
  } else {
    let top = 0.0;
    let bottom = window.innerHeight;
    if (notNull(containerDomId)) {
      const container = document.getElementById(containerDomId);
      const containerRect = container.getBoundingClientRect();
      top = containerRect.top;
      bottom = containerRect.bottom;
    }
    const rect = node.getBoundingClientRect();
    if (rect.top < top) {
      return Location.AboveScreen;
    } else if (rect.bottom > bottom) {
      return Location.BelowScreen;
    } else {
      return Location.OnScreen;
    }
  }
}

export function scrollIfNotVisibleDomId(
  domId: string,
  placement: ScrollLogicalPosition,
  containerDomId: string
) {
  const currentLocation = getVisibilityDomId(domId, containerDomId);
  if (
    currentLocation !== Location.OnScreen &&
    currentLocation !== Location.NotExist
  ) {
    const node = document.getElementById(domId);
    let options: ScrollIntoViewOptions = { block: placement };
    node.scrollIntoView(options);
  }
}

export function getVisibility(elementId: ElementId, containerDomId: string) {
  const domId = elementIdToDomId(null, elementId);
  return getVisibilityDomId(domId, containerDomId);
}

export function scrollIfNotVisible(
  elementId: ElementId,
  placement: ScrollLogicalPosition,
  containerDomId: string
) {
  if (elementId) {
    const domId = elementIdToDomId(null, elementId);
    scrollIfNotVisibleDomId(domId, placement, containerDomId);
  }
}
