import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ExcerptData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { ExcerptForm } from './excerpt-form';
import { useExcerpt } from './use-excerpt';

interface Params {
  stopEditing: () => void;
}

export const ExcerptEditScreen = observer(({ stopEditing }: Params) => {
  const manager = ExcerptManager.getInstance();
  const model = useExcerpt();

  if (!model) {
    return null;
  }

  const onSubmit = (dto: ExcerptData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    model.fromFormData(dto);
    manager.update(model).then(stopEditing);
  };

  return (
    <Page title={`Editing ${model.name}`}>
      <OnePaneLayout>
        <ExcerptForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
          model={model} // reference data needed adapt form to L1
        />
      </OnePaneLayout>
    </Page>
  );
});
