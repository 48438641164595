import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { styled } from '@naan/stitches.config';

const DialogContainer = styled(Dialog.Container, {
  // top: '50%',
  // width: 'min( 100% - 32px, 920px )',
  // height: '95%',
  '&  h2': {
    textStyle: 'body-bold',
    color: '$colors$textPrimary',
  },
});

//
// main body panel item, when current selection is the reference script
//
export const ActionDialog: React.FC<{
  onDismiss: () => void;
  title: string;
  disableContainerDismissal?: boolean;
  // dismissOnInteractOutside: boolean;
}> = ({ onDismiss, title, children, disableContainerDismissal = false }) => {
  const onContainerDismiss = disableContainerDismissal ? undefined : onDismiss;
  return (
    <DialogContainer
      open
      onDismiss={onContainerDismiss}
      // onEscapeKeyDown={onDismiss}
    >
      <Dialog.Heading>
        {title}
        {/* <Dialog.CloseButton /> */}
        <Dialog.HtmlCloseButton onDismiss={onDismiss} />
      </Dialog.Heading>
      <Dialog.Body>{children}</Dialog.Body>
    </DialogContainer>
  );
};

const MediumDialogContainer = styled(Dialog.Container, {
  top: '50%',
  width: '480px',
  // height: '95%',
  '&  h2': {
    textStyle: 'body-bold',
    color: '$colors$textPrimary',
  },
});

//
// main body panel item, when current selection is the reference script
//
export const ActionMediumDialog: React.FC<{
  onDismiss: () => void;
  title: string;
}> = ({ onDismiss, title, children }) => {
  return (
    <MediumDialogContainer open onDismiss={onDismiss}>
      <Dialog.Heading>
        {title}
        <Dialog.CloseButton />
      </Dialog.Heading>
      <Dialog.Body>{children}</Dialog.Body>
    </MediumDialogContainer>
  );
};

const WideDialogContainer = styled(Dialog.Container, {
  // top: '50%',
  width: 'min(720px, 100% - 32px)',
  // height: '95%',
  '&  h2': {
    textStyle: 'body-bold',
    color: '$colors$textPrimary',
  },
});

//
// main body panel item, when current selection is the reference script
//
export const ActionWideDialog: React.FC<{
  onDismiss: () => void;
  disableContainerDismissal?: boolean;
  title: string;
}> = ({ onDismiss, disableContainerDismissal, title, children }) => {
  const onContainerDismiss = disableContainerDismissal ? undefined : onDismiss;
  return (
    <WideDialogContainer open onDismiss={onContainerDismiss}>
      <Dialog.Heading>
        {title}
        {/* <Dialog.CloseButton /> */}
        <Dialog.HtmlCloseButton onDismiss={onDismiss} />
      </Dialog.Heading>
      <Dialog.Body>{children}</Dialog.Body>
    </WideDialogContainer>
  );
};
