import * as React from 'react';
import { MDBModal, MDBModalBody } from 'mdbreact';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

class SidePopupState {
  @observable.ref open = false;
  constructor() {
    makeObservable(this);
  }
}

const _state = new SidePopupState();

export function openSidePopup() {
  _state.open = true;
}

const close = () => {
  _state.open = false;
};

export const SidePopup = observer((props: { children: React.ReactChild }) => {
  const { open } = _state;
  return (
    <React.Fragment>
      <MDBModal
        isOpen={open}
        size="lg"
        toggle={close}
        position="right"
        fullHeight={true}
      >
        <MDBModalBody>
          <div>{props.children}</div>
        </MDBModalBody>
      </MDBModal>
    </React.Fragment>
  );
});
