// // TODO move
import { deploymentConfig } from '../deployment-config';

export function googleFileIdFromUrl(url: string) {
  const m = url.match(/\/file\/d\/([^/]+)\//);
  let docId = null;
  if (m) {
    docId = m[1];
  } else {
    docId = url.split('=')[1].trim();
  }
  return docId;
}

export function googleDocIdFromUrl(url: string) {
  const m = url.match(/\/d\/([^/]+)\//);
  let docId = null;
  if (m) {
    docId = m[1];
  } else {
    docId = url.split('=')[1].trim();
  }
  return docId;
}

export function audioUrlFromGoogleUrl(url: string) {
  const audioDocId = googleFileIdFromUrl(url);
  return `https://drive.google.com/uc?export=download&id=${audioDocId}`;
}

// const masalaServer = 'https://masala-v1-server.jiveworld.com';
// const masalaServer = deploymentConfig.falconServerUrl;
// export async function fetchTextFromGoogleDoc(url: string) {
//   let result = await global.fetch(
//     `${masalaServer}/jw_script_text_from_url?url=${url}`
//   );
//   const text = await result.text();
//   return text;
// }

export async function fetchGoogleDocText(url: string) {
  const fetchUrl = `${deploymentConfig.falconServerUrl}/jw_script_text_from_url?url=${url}`;
  console.log(`fetchUrl: ${fetchUrl}`);
  const response = await fetch(
    `${deploymentConfig.falconServerUrl}/jw_script_text_from_url?url=${url}`
  );
  const text = await response.text();
  if (text.includes('File not found:')) {
    throw Error(
      `'File not found' error encounted. Are document permissions correct?`
    );
  }
  return text;
}
