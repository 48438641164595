import { StrategyProps } from '@masala-lib/editorial/ui/completion/core';
import { userManager } from '../models/app-root';

export const userAliasStartsWith = (term: string, limit = 10): string[] => {
  const results: string[] = [];
  // Whether previous key started with the term
  let prevMatch = false;
  for (const username of userManager.slackMembers.map(user => user.alias)) {
    if (username.startsWith(term)) {
      results.push(username);
      if (results.length === limit) break;
      prevMatch = true;
    } else if (prevMatch) {
      break;
    }
  }
  return results;
};

export const MENTION_STRATEGY: StrategyProps = {
  id: 'emoji',
  match: /\B@([-+\w]*)$/,
  search: (term, callback) => {
    callback(userAliasStartsWith(term));
  },
  replace: username =>
    `<span contenteditable=false class="mention">@${username}</span>&nbsp;`,
  template: username => `<small>@${username}</small>`,
};
