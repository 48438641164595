import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { VolumeForm } from './volume-form';
import { VolumeData } from '@masala-lib/catalog/catalog-types';
import { Volume } from '@masala-lib/catalog/models/volume';

import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
// import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';

export const VolumeCreateScreen = observer(() => {
  const volumeManager = VolumeManager.getInstance();
  // const channelManager = ChannelManager.getInstance();
  const location = useLocation();
  const navigate = useNavigate();
  const { channel: channelId } = getQueryParamsFromLocation(location);
  // const channel = channelManager.getById(channelId);
  const volume = new Volume({
    id: '',
    channelId,
    // flavor: channel?.data?.flavor,
    // l1: channel?.data?.l1, TODO
  });

  if (!volume) {
    return null;
  }

  const goBack = () => {
    navigate(`/volumes/?channel=${channelId}`);
  };

  const onSubmit = (dto: VolumeData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    // dto.infoV5 = {} as InfoV5Data; // ensure nested object exists
    volume.fromFormData(dto);
    volumeManager.create(volume).then(goBack);
  };

  return (
    <Page title={'New volume'}>
      <VolumeForm
        dto={volume.toFormData()}
        onSubmit={onSubmit}
        onCancel={goBack}
      />
    </Page>
  );
});
