import { observer } from 'mobx-react';
import { A, ActionLink } from '@masala-lib/editorial/ui/action-link';
import { Unit } from '@masala-lib/catalog/models/unit';
import { isEmpty } from 'lodash';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Auth } from '@masala-lib/editorial/db/auth';
import { NavLink } from 'react-router-dom';

interface UnitDetailActionsProps {
  unit: Unit;
  startEditing: () => void;
}

export const UnitDetailActions = observer(
  ({ unit, startEditing }: UnitDetailActionsProps) => {
    const auth = Auth.getInstance();

    // const handleBogotaIngest = async () => {
    //   try {
    //     unit.setPendingOperation('bogota ingestion...');
    //     await unit.ingest();
    //   } catch (error) {
    //     window.alert(error);
    //     throw error;
    //   } finally {
    //     unit.clearPendingOperation();
    //   }
    // };

    const writable = unit.hasWriteAccess(auth.appUser);

    if (!unit.hasReadAccess(auth.appUser)) {
      return <>{'[No access]'}</>;
    }

    return (
      <PageActions>
        {auth.can('manage_units') && writable ? (
          <button onClick={startEditing} className="primary-action">
            Edit
          </button>
        ) : null}
        {unit.scriptInitted ? (
          <>
            <NavLink to={unit.assistPath}>Assist</NavLink>
            <A href={unit.resolveEditorUrl()}>
              Script editor
              {/* ({unit.l1Default}) */}
            </A>
            {/* {unit.auxL1s.map(locale => (
              <A href={unit.resolveEditorUrl(locale)}>{locale}</A>
            ))} */}
            {unit.chaatInitted && writable ? (
              unit.chaatReady ? (
                <A href={unit.chaatUrl}>Chaat</A>
              ) : null // this state now covered in Util.operationStatus
            ) : unit.data.audioTranscribeUrl && writable ? (
              auth.can('manage_units') ? (
                <>
                  {/* <ActionLink onPress={() => unit.oldInitChaat()}>
                    Old Init chaat
                  </ActionLink> */}
                  <ActionLink onPress={() => unit.newInitChaat()}>
                    Init chaat
                  </ActionLink>
                </>
              ) : null
            ) : null}
          </>
        ) : null}

        {unit.scriptReviewPath ? (
          <A href={unit.scriptReviewPath}>Review script</A>
        ) : null}

        {writable ? <DeleteActions model={unit} /> : null}
      </PageActions>
    );
  }
);
