import { AuthorData } from '../catalog-types';
import { Author } from '../models/author';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';

export class AuthorManager extends EntityManager<Author, AuthorData> {
  get collectionName(): string {
    return CatalogCollections.AUTHOR_METADATA;
  }

  createModel(data: { id: string }): Author {
    return new Author(data);
  }

  private static instance: AuthorManager;

  public static getInstance(): AuthorManager {
    if (!AuthorManager.instance) {
      AuthorManager.instance = new AuthorManager();
      AuthorManager.instance.listenAll();
    }
    return AuthorManager.instance;
  }
}
