import { isFunction } from 'lodash';
import * as React from 'react';
import { Popover } from 'react-tiny-popover';
import { useSwitch, StateSwitch } from '../../lib/hooks/use-switch';

type RenderFunction = (stateSwitch: StateSwitch) => React.ReactNode;
export type ManagedPopoverProps = {
  renderButton: RenderFunction;
  content: React.ReactNode | RenderFunction;
  openByDefault?: boolean;
  positions?: ('bottom' | 'right' | 'left' | 'top')[];
};

export const ManagedPopoverContainer = ({
  renderButton,
  content,
  openByDefault = false,
  positions = ['bottom', 'right', 'left'],
}: ManagedPopoverProps) => {
  const openSwitch = useSwitch(openByDefault);
  return (
    <Popover
      isOpen={openSwitch.value}
      positions={positions} // preferred positions by priority
      onClickOutside={openSwitch.off}
      content={<>{isFunction(content) ? content(openSwitch) : content} </>}
    >
      <span>{renderButton(openSwitch)}</span>
    </Popover>
  );
};
