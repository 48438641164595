import {
  makeObservable,
  observable,
  computed,
  reaction,
  untracked,
  when,
  $mobx,
  IReactionDisposer,
} from 'mobx';
import { isArray, once } from 'lodash';
import { ElementId } from '@tikka/basic-types';

// @jason is this the intended type?
function successor(id: ElementId) {
  return typeof id === 'number' ? id + 1 : 1;
}

const PRIMITIVE = 'PRIMITIVE';
const AGGREGATE = 'AGGREGATE';
const FUNCTION = 'FUNCTION';

export type Bus = {
  subscribe: (key: string, cb: (o: any) => void) => () => void;
  emit: (key: string, o: any) => void;
};

export type CallbackFn = (...args: any) => void;

interface StringToAny {
  [index: string]: any;
}

export const createBus = (initialState: any): Bus => {
  const __map: StringToAny = {};
  let returnNullOnUndefined = true;

  class Boxed {
    observed: any; // value or function
    type: string;
    x: any;

    constructor() {
      this.observed = null;
      this.type = PRIMITIVE;
      this.x = {};

      makeObservable(this, {
        observed: observable,
        value: computed,
      });
    }

    static box(value: any) {
      const b = new Boxed();
      b.set(value);
      return b;
    }

    get value(): any {
      if (this.type === PRIMITIVE) {
        return this.observed;
      } else if (this.type === FUNCTION) {
        return this.observed();
      } else if (this.type === AGGREGATE) {
        const result: StringToAny = {};
        this.observed.forEach((path: string) => {
          result[path] = get(path);
        });
        return result;
      } else {
        // default
        return this.observed;
      }
    }

    set(value: any) {
      this.observed = value;
    }

    getX() {
      return this.x;
    }

    setX(value: any) {
      this.x = value;
    }

    aggregate(paths: string[]) {
      this.type = AGGREGATE;
      this.observed = paths;
      //this.observed = successor(this.observed);
    }

    computeWith(fn: Function) {
      this.observed = fn;
      this.type = FUNCTION;
    }
  }

  const boxed = (key: string) => {
    //TODO rearrange to make more efficient
    const box = __map[key] || Boxed.box(undefined);
    __map[key] = box;
    return box;
  };

  const get = (key: string) => {
    const result = boxed(key).value;
    if (returnNullOnUndefined && typeof result === 'undefined') {
      return null;
    }
    return result;
  };

  const getUntracked = (key: string) => {
    return untracked(() => get(key));
  };

  const set = (key: string, value: any, emitGlobal = true) => {
    boxed(key).set(value);
    if (emitGlobal) {
      emit('*');
    }
  };

  const emit = (key: string, ...args: any[]) => {
    const box = boxed(key);
    if (args) {
      box.setX(args);
    }
    box.set(successor(box.value));
  };

  const subscribe = (name: string, callback: CallbackFn, once = false) => {
    const box = boxed(name);
    const value = box.value;
    const sense = once ? () => box.value !== value : () => box.value;
    const cb = () => callback(...box.getX());
    const unsubscribe = once ? when(sense, cb) : reaction(sense, cb);
    return unsubscribe;
  };

  const subscribeOnce = (name: string, callback: CallbackFn) => {
    return subscribe(name, callback, true);
  };

  const channelIsEmpty = (name: string) => {
    const box = boxed(name);
    return box[$mobx].values.get('observed').observers.length === 0;
  };

  const touch = (paths: string[]) => {
    paths.forEach(path => {
      get(path);
    });
  };

  const onUpdate = (
    paths: string[],
    callback = () => {}
  ): IReactionDisposer[] => {
    if (isArray(paths)) {
      //const options = { equals: (a, b) => false, delay: 30 };
      const options = { equals: (a: any, b: any) => false };
      const unsubscribe = once(reaction(() => touch(paths), callback, options));
      return [unsubscribe];
    }
    return onUpdate(paths, callback);
  };

  const aggregate = (key: string, paths: string[]) => {
    const box = boxed(key);
    box.aggregate(paths);
  };

  if (initialState) {
    for (const [key, value] of Object.entries(initialState)) {
      set(key, value);
    }
  }

  set('*', 1);

  // TODO work on typing
  return <Bus>{
    get,
    getUntracked,
    set,
    onUpdate,
    aggregate,
    emit,
    subscribe,
    subscribeOnce,
    channelIsEmpty,
  };
};
