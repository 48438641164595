import { alertMessages, mutationActions, scriptEditorModel } from '../app-root';
import { WordGroupActions } from '@masala-lib/editorial/models/actions/word-group-actions';

export class ScriptEditorWordGroupActions extends WordGroupActions {
  get mutationActions() {
    return mutationActions;
  }
  get content() {
    return scriptEditorModel.elements;
  }
  get currentWordSelection() {
    return scriptEditorModel.wordRangeSelection;
  }
  get currentFocusedElement() {
    return scriptEditorModel.focusedElement;
  }
  get alertMessages() {
    return alertMessages;
  }
}
