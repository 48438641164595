import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { useVolume } from './use-volume';
import { CreditsForm } from './credits-form';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { useNavigate } from 'react-router-dom';

export const VolumeCreditsEditScreen = observer(() => {
  const volume = useVolume();
  const navigate = useNavigate();

  if (!volume) {
    return null;
  }

  const formData = volume.data.creditsData || {};

  const goBack = () => {
    navigate(volume.navPath);
  };

  const onSubmit = (dto: any) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    volume.updatePartial({ creditsData: dto }).then(goBack);
    consoleAddAuditLogMessage(volume.firstUnitKey, `updateVolumeCredits`);
  };

  return (
    <Page title={`Editing ${volume.name} - Credits`} onClose={goBack}>
      <CreditsForm
        dto={formData}
        onSubmit={onSubmit}
        cancelNavPath={volume.navPath}
      />
    </Page>
  );
});
