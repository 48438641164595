import * as React from 'react';

export function CheckmarkCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.75 7.25516L12.6715 6.5L9.0216 11.7126L6.50516 9.9506L5.75 11.0291L9.34492 13.5463L13.75 7.25516Z"
        fill={color}
      />
    </svg>
  );
}

export function CheckmarkCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.8284 8.67738L15.5034 7.74963L11.0193 14.1536L7.92775 11.9889L7 13.3138L11.4165 16.4063L16.8284 8.67738Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CheckmarkCircleExtraExtraLargeIcon({
  width = 34,
  height = 34,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17ZM25.2083 11.3516L22.9559 9.77441L15.3329 20.6612L10.0772 16.9811L8.5 19.2335L16.0081 24.4908L25.2083 11.3516Z"
        fill={color}
      ></path>
    </svg>
  );
}
