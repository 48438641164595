import { observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';
import { Volume } from '@masala-lib/catalog/models/volume';
import { Table } from '../shared/table';
import { Shelf } from '../shared/shelf';
import styled from 'styled-components';
import { makeQueryPath } from '../../lib/query-stuff';
import { formatDate } from '../../lib/formatters';

interface VolumesTableProps {
  volumes: Volume[];
}

const VolumeCell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 500px);
  min-width: 500px;

  > * + * {
    margin-top: 6px;
  }

  .channel-volume {
    font-size: 14px;
    line-height: 21px;
    display: flex;
    .channel-link {
      color: var(--color-gray600);
      font-weight: 400;
      max-width: 30%;
      text-overflow: ellipsis;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        color: var(--color-gray800);
        text-decoration: underline;
        text-decoration-color: var(--color-gray300);
      }
    }
    .volume-link {
      color: var(--color-gray700);
      font-weight: 500;

      &:hover {
        color: var(--color-blue800);
        text-decoration: underline;
        text-decoration-color: var(--color-gray300);
      }
    }
    .volume-link + .channel-link {
      max-width: 65%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &::before {
        content: '#';
        padding-left: 1ch;
        padding-right: 0.25ch;
        color: var(--color-gray200);
      }
    }
  }

  .unit-meta {
    > * + * {
      &::before {
        content: '|';
        margin-left: 8px;
        margin-right: 8px;
        color: var(--color-gray200);
      }
    }
    .release-date {
    }
  }

  .operation-status {
    padding: 6px 12px;
    background-color: var(--color-yellow100);
    color: var(--color-gray900);
    align-self: flex-start;
    /* border-radius: 20px; */
  }
`;

export const VolumesTable = observer(({ volumes }: VolumesTableProps) => {
  // const isOpen = useSwitch(false);
  return (
    <>
      <Table
        data={volumes}
        cells={[
          {
            header: 'Volume name',
            render: volume => (
              <VolumeCell>
                <div className="channel-volume">
                  <Link className="volume-link" to={`/volumes/${volume.id}`}>
                    {volume.name} ({volume.slug})
                  </Link>
                  {volume.channel /*  && unit.volumeName !== unit.name */ ? (
                    <Link
                      className={'channel-link'}
                      to={makeQueryPath(`/volumes`, {
                        channel: volume.channelId,
                      })}
                    >
                      {volume.channel.name}
                    </Link>
                  ) : null}
                </div>
                <div className="unit-meta">
                  <span className="release-date">
                    Created on <b>{formatDate(volume.data.createdAt)}</b>
                  </span>
                  <span className="release-date">
                    Updated on <b>{formatDate(volume.data.updatedAt)}</b>
                  </span>
                  <span className="release-date">
                    Ingested at <b>{volume.data.reviewVersion?.ingestedAt}</b>
                  </span>
                </div>
                {volume.operationStatus ? (
                  <div className="operation-status">
                    {volume.operationStatus}
                  </div>
                ) : null}
              </VolumeCell>
            ),
          },
          {
            header: 'Units',
            render: volume => (
              <Shelf>
                <>
                  <NavLink
                    className="primary-action"
                    to={`/units?volume=${volume.id}`}
                  >
                    {volume.unitCount} unit(s)
                  </NavLink>
                </>
              </Shelf>
            ),
          },
        ]}
      />
    </>
  );
});
