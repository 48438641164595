import { Interval } from '@tikka/intervals/intervals';
import * as moment from 'moment-timezone';

export function msToMinSecString(ms: number) {
  let seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

export function msToMinSecDecimalString(ms: number) {
  let seconds = ms / 1000.0;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  return `${minutes}:${seconds.toFixed(3)}`;
}

export function msToSecString(ms: number) {
  let seconds = Math.floor(ms / 1000);
  return `${seconds.toString()}`;
}

export function msToSecDecimalString(ms: number) {
  let seconds = ms / 1000.0;
  return `${seconds.toFixed(3)}`;
}

// export function utcStringFromEpoch(timestamp: number) {
//   return moment.utc(moment.unix(timestamp)).format('MMM Do H:mm:ss') + ' UTC';
// }

export function localStringFromEpoch(timestamp: number) {
  // return moment.unix(timestamp).local().format('MMM Do h:mm:ssa Z');
  return moment.unix(timestamp).local().format('YYYY-MM-DD, h:mm:ssa Z');
}

export function auditLogTimeString(timestamp: number) {
  // return utcStringFromEpoch(timestamp);
  // display pacific time for JFE's convenience
  // return moment.unix(timestamp).tz('America/Los_Angeles').format('MMM Do h:mm:ssa z');
  return moment
    .unix(timestamp)
    .tz('America/Los_Angeles')
    .format('YYYY-MM-DD, h:mm:ssa z');
}

export function uiTimeString(timestamp: number) {
  return localStringFromEpoch(timestamp);
}

export function intervalToMinSecMsDurationString(interval: Interval) {
  let ms = interval.begin;
  const baseStr = msToMinSecString(ms);
  ms = ms % 1000;
  const duration = interval.end - interval.begin;
  return `${baseStr}.${ms.toString().padStart(3, '0')} (${duration} ms)`;
}
