import { useState } from 'react';
import { UserManager } from '@masala-lib/editorial/models/user-manager';
import { Auth } from '@masala-lib/editorial/db/auth';
import { UserDetail } from './user-detail';
import { UserEdit } from './user-edit';
import { useSwitch } from '../../lib/hooks/use-switch';
import { Lightbox } from '../../components/modals';

export const UserDetailScreen = () => {
  const editSwitch = useSwitch(false);

  const [manager] = useState(UserManager.getInstance());

  const auth = Auth.getInstance();

  return (
    <>
      <Lightbox
        showing={editSwitch.value}
        title={`Edit user`}
        onDismiss={editSwitch.off}
      >
        <UserEdit manager={manager} onStopEditing={editSwitch.off} />
      </Lightbox>
      <UserDetail
        manager={manager}
        onStartEditing={editSwitch.on}
        auth={auth}
      />
    </>
  );
};
