import * as React from 'react';
import styled from 'styled-components';
import { Button } from './button';

const Wrapper = styled.div`
  button {
    --button-border-color: var(--color-transparent);
  }
`;

type TextButtonProps = {
  label: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

export const TextButton = ({
  label,
  onClick,
  ...buttonProps
}: TextButtonProps) => {
  return (
    <Wrapper>
      <Button label={label} onClick={onClick} {...buttonProps} />
    </Wrapper>
  );
};
