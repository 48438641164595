import React, { ChangeEvent } from 'react';
// import styled from "styled-components";
import { observer } from 'mobx-react';
import {
  transportState,
  audioSource,
  audioTransport,
  appBus,
  appRoot,
  chaatToolModel,
} from '../models/app-root';
import { expr } from 'mobx-utils';
import {
  msToMinSecDecimalString,
  msToMinSecString,
  msToSecDecimalString,
  msToSecString,
} from '@masala-lib/misc/timestamp-formats';
import { audioSourceKeyToUIText } from '../models/chaat-audio-sources';
import { openSidePopup } from '../../console/views/shared/side-popup';
import { HelpIcon } from '../../console/components/icons/help-icon';

// const StatusBarContainer = styled.div`
//   font-family: ${p => p.theme.sansSerif};
//   width: 1000px;
//   overflow: hidden;
//   white-space: nowrap;
//   padding: 4px;
//   border-bottom: 3px solid lightgrey;
// `;

let showFractionalTime = false;
appBus.subscribe('timeZoom', (direction: boolean) => {
  if (direction) {
    showFractionalTime = true;
  }
});

const StatusBarContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="chaat-status-bar-container">{children}</div>;
};

// const StatusBarElement = styled.div`
//   overflow: hidden;
//   display: inline-block;
//   padding: 0px 40px;
//   margin: 0px;
//   vertical-align: bottom;
// `;

const StatusBarElement = ({ children }: { children: React.ReactNode }) => {
  return <div className="chaat-status-bar-element">{children}</div>;
};

const AudioTrackSelect = observer(() => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    audioSource.setCurrentAudioSourceKey(event.target.value);
  };

  const options = audioSource.sourceKeys;

  return (
    <select
      value={audioSource.currentAudioSourceKey}
      onChange={handleChange}
      disabled={transportState.isPlaying}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {(audioSourceKeyToUIText as any)[option]}
        </option>
      ))}
    </select>
  );
});

function progressString() {
  if (transportState.isPlaying) {
    const progressSeconds = Math.floor(transportState.audioPosition / 1000.0);
    if (showFractionalTime) {
      return msToSecString(progressSeconds * 1000);
    } else {
      return msToMinSecString(progressSeconds * 1000);
    }
  } else {
    if (showFractionalTime) {
      return msToSecDecimalString(transportState.audioPosition);
    } else {
      return msToMinSecString(transportState.audioPosition);
    }
  }
}

function durationString() {
  if (showFractionalTime) {
    return msToSecDecimalString(audioTransport.audioDuration);
  } else {
    return msToMinSecString(audioTransport.audioDuration);
  }
}

const ChaatStatusBar = observer(() => {
  const speedState = transportState.playbackRate.toFixed(1);
  const kerningState = transportState.kerningEnabled ? 'ON' : 'OFF';
  const progress = expr(() => progressString());
  const duration = expr(() => durationString());

  return (
    <StatusBarContainer>
      <StatusBarElement> {` Speed: ${speedState} `}</StatusBarElement>
      <StatusBarElement> {` Kerning: ${kerningState} `} </StatusBarElement>
      {/*<StatusBarElement>*/}
      {/*  <a*/}
      {/*    href="#"*/}
      {/*    style={{ color: colors.darkerBlue, textDecoration: "underline" }}*/}
      {/*    onClick={() => openCheatSheetModal()}*/}
      {/*  >*/}
      {/*    {" "}*/}
      {/*    Open Cheat Sheet{" "}*/}
      {/*  </a>*/}
      {/*</StatusBarElement>*/}
      <StatusBarElement>
        {' '}
        {` Progress: ${progress}/${duration} `}{' '}
      </StatusBarElement>
      <StatusBarElement>
        <AudioTrackSelect />
      </StatusBarElement>
      <StatusBarElement>
        <div style={{ width: '200px' }}>
          <span
            onClick={() => openSidePopup()}
            style={{ alignSelf: 'flex-end', float: 'right' }}
          >
            <HelpIcon />
          </span>
        </div>
      </StatusBarElement>
    </StatusBarContainer>
  );
});

const TrickyStatusBar = observer(() => {
  // const speedState = transportState.playbackRate.toFixed(1);
  const progress = expr(() => progressString());
  const duration = expr(() => durationString());
  let compressionSettingsString = null;
  let globalDataString = `O: ${chaatToolModel.finalTrickyWordsRatio.toFixed(
    2
  )}`;
  if (chaatToolModel.hasExistingTrickys) {
    globalDataString += `, E: ${chaatToolModel.existingTrickyWordsRatio.toFixed(
      2
    )}`;
    globalDataString += `, R: ${chaatToolModel.globalOutputTrickyRatioInExisting.toFixed(
      2
    )}`;
    globalDataString += `/${chaatToolModel.existingTrickyRatioInGlobalOutput.toFixed(
      2
    )}`;
    // globalDataString += `/${chaatToolModel.existingAndGlobalOutputRatioOverlap.toFixed(
    //   2
    // )}`;
    globalDataString += `/${chaatToolModel.existingAndGlobalOutputOddsRatio.toFixed(
      2
    )}`;
  }
  compressionSettingsString = ` C:${
    chaatToolModel.getCompressionSetting('enabled') ? 'ON' : 'OFF'
  } `;
  compressionSettingsString += `L:${(
    chaatToolModel.getCompressionSetting('targetLevel') as number
  ).toFixed(2)} `;
  compressionSettingsString += `B:${(
    chaatToolModel.getCompressionSetting('boostDamper') as number
  ).toFixed(2)} `;
  compressionSettingsString += `W:${(
    ((chaatToolModel.getCompressionSetting('windowSize') as number) * 25.0) /
    1000.0
  ).toFixed()} `;

  let selectedDataString = `O: ${chaatToolModel.selectedTrackTrickyWordsRatio.toFixed(
    2
  )}`;
  if (chaatToolModel.hasExistingTrickys) {
    selectedDataString += `, R: ${chaatToolModel.selectedRatioInExistingTricky.toFixed(
      2
    )}`;
    selectedDataString += `/${chaatToolModel.existingTrickyRatioInSelected.toFixed(
      2
    )}`;
    // selectedDataString += `/${chaatToolModel.selectedAndExistingRatioOverlap.toFixed(
    //   2
    // )}`;
    selectedDataString += `/${chaatToolModel.selectedAndExistingOddsRatio.toFixed(
      2
    )}`;
    // selectedDataString += `/${chaatToolModel.selectedAndExistingYulesY.toFixed(
    //   2
    // )}`;
  }

  return (
    <StatusBarContainer>
      {/* <StatusBarElement>
        {' '}
        {` Progress: ${progress}/${duration} `}{' '}
      </StatusBarElement> */}
      <StatusBarElement> {`Global (${globalDataString})`} </StatusBarElement>
      <StatusBarElement> {`Selected (${selectedDataString})`}</StatusBarElement>
      {/* <StatusBarElement>
        <div style={{ width: '200px' }}>
          <span
            onClick={() => openSidePopup()}
            style={{ alignSelf: 'flex-end', float: 'right' }}
          >
            <HelpIcon />
          </span>
        </div>
      </StatusBarElement> */}
      {compressionSettingsString}
    </StatusBarContainer>
  );
});

export const StatusBar = observer(() => {
  if (appRoot.mode === 'chaat') {
    return <ChaatStatusBar />;
  } else {
    return <TrickyStatusBar />;
  }
});

// export default StatusBar;
