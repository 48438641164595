export const gray6 = '#444444';
export const gray5 = '#626262';
export const gray4 = '#999999';
export const gray3 = '#BBBBBB';
export const gray2 = '#D0D0D0';
export const gray1 = '#E5E5E5';
export const offOffWhite = '#F0F0F0';
export const offWhite = '#FAFAFA';
export const white = '#FFFFFF';
export const darkerBlue = '#45769F';
export const darkBlue = '#4C7FAA';
export const darkTeal = '#00ABC6';
export const teal = '#19BBD4';
export const brightTeal = '#40E6FF';
export const darkGreen = '#45B248';
export const green = '#44C548';
export const lightGreen = '#4ED152';
export const veryDarkYellow = '#DEB100';
export const darkYellow = '#F7C70A';
export const yellow = '#FFDF5E';
export const lightYellow = '#FFEA95';
export const orange = '#F5832B';
export const lightOrange = '#FFA000';
export const red = '#FF6767';
export const darkRed = '#DE676A';
export const lightRed = '#FF00002C';
