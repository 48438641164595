import * as React from 'react';
// import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { createLogger } from '@app/logger';
import { Input } from '@console/components/forms';
import { useMasalaForm } from '@console/lib/hooks/use-masala-form';
import { styled } from '@naan/stitches.config';
import { Button, IconButton } from '@naan/primitives/button';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { useKeyboardBinding } from '@console/lib/hooks/use-keyboard-binding';
import { KeyboardService } from 'lib/services/keyboard-service';
import { useRestoreFocus } from '@naan/primitives/modals/dialog/use-restore-focus';

const log = createLogger('override-entry');

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 48,
  '& > input': {
    margin: 0,
  },
  '& > .buttons': {
    display: 'flex',
    gap: 8,
  },
});

//
// Inline single text field form - used for merge view line edit
//

interface FormData {
  text: string;
}

export const TextFormInline = observer(
  ({
    label,
    text,
    setText,
    onDismiss,
  }: {
    label?: string;
    text: string;
    setText: (text: string) => void;
    onDismiss: () => void;
  }) => {
    const ref = React.useRef<HTMLFormElement>(null);

    const { handleSubmit, getFieldProps } = useMasalaForm<FormData>({
      defaultValues: {
        text,
      },
    });

    useKeyboardBinding('Escape', onDismiss);

    useRestoreFocus();

    React.useEffect(() => {
      const resetKeyboardSHortcuts =
        KeyboardService.instance.pauseCurrentShortcutSet();

      const input = ref.current?.querySelector(
        'input[type=text]'
      ) as HTMLInputElement;
      input?.focus();

      /// @elliottjf this adhoc escape handler is still necessary to prevent
      /// the parent modal to close automatically
      const escapeHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          event.stopPropagation();
          onDismiss();
        }
      };
      input.addEventListener('keydown', escapeHandler);
      return () => {
        input.removeEventListener('keydown', escapeHandler);
        resetKeyboardSHortcuts();
      };
    }, [onDismiss]);

    const onSubmit = async (
      data: FormData
      // event: any /*React.BaseSyntheticEvent*/
    ) => {
      log.info(`onSubmit - text: ${data.text}`);
      onDismiss(); // not sure why this needed to be before the await
      setText(data.text);
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Wrapper>
          <Input
            {...getFieldProps('text')}
            label={label}
            css={{
              $$maxWidth: 'auto',
            }}
          />
          {/* // i think we're good with just enter and escape and don't need the buttons */}
          {/* <div className="buttons">
            <Button
              // type="submit"
              leftIcon={<CloseSmallIcon />}
              presentation={'grayLight'}
              size="extraSmall"
              radius="squared"
              label="Cancel"
              onClick={onDismiss}
            />

            <Button
              type="submit"
              leftIcon={<CheckmarkHeavySmallIcon />}
              presentation={'blue'}
              size="extraSmall"
              radius="squared"
              label="Save"
            />
          </div> */}
        </Wrapper>
      </form>
    );
  }
);
