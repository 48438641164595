import { isEmpty } from 'lodash';

export function confirm(
  action: () => void,
  question = 'Are you sure?',
  answer = ''
) {
  if (isEmpty(answer)) {
    return (...args: any[]) => {
      if (window.confirm(question)) {
        action.call(args);
      }
    };
  } else {
    return (...args: any[]) => {
      const response = window.prompt(question);
      if (response && response.toLowerCase() === answer.toLowerCase()) {
        action.call(args);
      }
    };
  }
}
