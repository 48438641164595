import * as React from 'react';
// import { SimpleStatusBar } from '../simple-status-bar';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 16px;
  padding-left: 24px;
`;

interface OnePaneLayoutProps {
  children: React.ReactNode;
}

export const OnePaneLayout = ({ children }: OnePaneLayoutProps) => {
  return <Wrapper className="one-pane">{children}</Wrapper>;
};

// export const OnePaneRouteLayout = () => {
//   return (
//     <Wrapper className="one-pane">
//       <Outlet />
//     </Wrapper>
//   );
// };
