import {
  ElementId,
  ElementIdToString,
  idIsOfKind,
  StringToElementId,
} from '@tikka/basic-types';
import { LocaleCode } from '@utils/util-types';
import { Element, ElementList } from '../../editor-aliases';
import { getElementEditableContentString } from '../../content-utils';
import { EKind } from '../../element-kinds';
import { epochSecondsFloat } from '../../utils';
import {
  basicScriptElementsFromEpisodeData,
  loadScriptContent,
  loadScriptEpisodeData,
} from '../db/loader-funcs';
import {
  addAuditLogMessage,
  resetTranslationVersionDoc,
  updateTranslations,
} from '../db/mutation-actions';
import _, { isEmpty, isNil } from 'lodash';
import {
  normalizeSpecialChars,
  strongNormalizeWordArray,
} from '../../misc/editorial-string-utils';
import { UnitManager } from '../../catalog/db/unit-manager';
import { getTranslationId } from '@tikka/elements/element-id-utils';
import { loadTranslationsDoc, loadVerbatimDoc } from '../db/versions-db';
import { fetchGoogleDocText } from '../../misc/google-drive-utils';
import { stripLinefeeds } from '@utils/content-string-utils';
import { ElementIdToTranslation } from '../../editorial-types';
import { entries, Entries } from '@tikka/type-utils';
import { splitLinesFiltered } from '@tikka/misc/string-utils';

// todo, move this into a shared utility file (currently duplicated in a few places)
// export async function fetchGoogleDocText(url: string) {
//   const fetchUrl = `${deploymentConfig.falconServerUrl}/jw_script_text_from_url?url=${url}`;
//   console.log(`fetchUrl: ${fetchUrl}`);
//   const response = await fetch(
//     `${deploymentConfig.falconServerUrl}/jw_script_text_from_url?url=${url}`
//   );
//   const text = await response.text();
//   if (text.includes('File not found:')) {
//     throw Error(
//       `'File not found' error encounted. Are document permissions correct?`
//     );
//   }
//   return text;
// }

interface StringDictionary {
  [index: string]: string;
}

function escapeQuotes(text: string) {
  text = text || '';
  if (!text.includes('"')) {
    return text;
  }
  text = text.replace(/"/g, '""');
  return `"${text}"`;
}

// both unescapes the tsv quotes and normalize other special chars
function unescapeQuotes(text: string) {
  if (text.startsWith('"')) {
    text = text.slice(1, text.length - 1);
    text = text.replace(/""/g, '"');
  }
  text = normalizeSpecialChars(text);
  return text.trim();
}

export function translationsToTSVText(
  translations: any,
  content: ElementList<Element>,
  exportKinds: Set<EKind>
) {
  const skipKinds = new Set(<string[]>[
    EKind.METADATA_BLOCK,
    EKind.WORD_GROUP,
    EKind.TRANSLATION,
  ]);
  // TODO better convenience for set creation
  const noTranslationKinds = new Set(<string[]>[
    EKind.PARAGRAPH,
    EKind.CHAPTER_COMPLETE,
  ]);
  // TODO better solution to create JS obj with keys that are constant defines
  const kindPrefixes: StringDictionary = {
    [EKind.CHAPTER]: '# ',
    [EKind.PASSAGE]: '## ',
    [EKind.CHAPTER_COMPLETE]: '//! CHAPTER-COMPLETE',
    [EKind.PARAGRAPH]: '@',
    // [EKind.CHAPTER_SUMMARY]: '%% ',
    [EKind.CHAPTER_NOTE]: '% ',
    [EKind.SENTENCE]: '',
  };

  const elements = content.values;
  const parts = [];
  const columns = 'ID\tCONTENT\tTRANSLATION\tNOTE';
  parts.push(columns);
  let segmentCount = 1;
  let note = '';
  for (const element of elements) {
    if (exportKinds && !exportKinds.has(element.kind)) {
      continue;
    }
    if (skipKinds.has(element.kind)) {
      continue;
    }
    const id = element.id;
    const kind = element.kind;
    if (kind === EKind.SENTENCE) {
      note = `[${segmentCount}] `;
      segmentCount++;
    } else {
      note = '';
    }
    const prefix = kindPrefixes[kind];
    const text = stripLinefeeds(
      getElementEditableContentString(element, content.words)
    );
    const translation0 = noTranslationKinds.has(kind) ? '-' : translations[id];
    const translation = translation0 ?? '';
    const line = `${id}\t${escapeQuotes(prefix + text)}\t${escapeQuotes(
      translation
    )}\t${escapeQuotes(note)}`;
    parts.push(line);
  }

  return parts.join('\n');
}

export async function getTranslationsTSV(
  episodeKey: string,
  locale: LocaleCode
) {
  const episodeData = await loadScriptEpisodeData(episodeKey, locale);
  const content = basicScriptElementsFromEpisodeData(episodeData);
  const translations: ElementIdToTranslation = {};
  const translations0 =
    episodeData.translationsDoc.items[episodeData.locale]?.translations;
  if (translations0) {
    for (const translation of Object.values(translations0) as any[]) {
      translations[translation.elementId] = translation.content;
    }
  }
  return translationsToTSVText(translations, content, null);
}

export async function slugForEpisodeKey(episodeKey: string) {
  const unit = await new UnitManager().loadSoloById(episodeKey);
  if (!unit) {
    throw Error(`failed to load unit by id: ${episodeKey}`);
  }
  return unit.slug;
}

// export function sniffTsvHasKeys(tsv: string, errorWithNoKeys = true) {
//   // TODO
//   const lines = splitLinesFiltered(tsv);
//   let hasKeys = true;
//   for (let i = 1; i < 10 && i < lines.length; i++) {
//     const line = lines[i];
//     const m = line.match(/^(.*?)\t.*$/);
//     const id = m[1];
//     if (isNil(id)) {
//       if (errorWithNoKeys) {
//         throw new Error('no id in line: ' + (i + 1) + ' : ' + line);
//       }
//       hasKeys = false;
//     }
//     if (!id.includes(':')) {
//       if (errorWithNoKeys) {
//         throw new Error('no id in line: ' + (i + 1) + ' : ' + line);
//       }
//       hasKeys = false;
//     }
//   }
//   return hasKeys;
// }

export function sniffTsvFirstLine(tsv: string) {
  const lines = splitLinesFiltered(tsv);
  const m = lines[0].match(/^ID\tCONTENT\tTRANSLATION\t/);
  return !!m;
}

export function checkTsvFormat(tsv: string) {
  return sniffTsvFirstLine(tsv);
}

export async function saveTranslations(
  episodeKey: string,
  translations0: ElementIdToString,
  language: string,
  merge: boolean
) {
  console.log(`saveTranslations`);
  const existingTranslationsDoc = await loadTranslationsDoc(episodeKey);
  const existing: any =
    existingTranslationsDoc?.items[language]?.translations ?? {};
  const translations: ElementIdToTranslation = {};
  for (const [id, translation] of entries(translations0)) {
    if (existing[id] && existing[id].content?.trim() === translation?.trim()) {
      console.log(
        `skipping update for id: ${id}, matching content: ${translation}`
      );
      continue;
    }
    console.log(`updating translations[${id}] to ${translation}`);
    translations[id] = {
      kind: EKind.TRANSLATION,
      id: getTranslationId(id, language),
      locale: language,
      elementId: id,
      content: translation,
      author: 'IMPORT',
      timestamp: epochSecondsFloat(),
    };
  }

  const result = await updateTranslations(
    episodeKey,
    translations,
    language,
    merge
  );
}

export async function importTranslationsTsvWithKeys(
  episodeKey: string,
  tsv: string,
  language: string
) {
  console.log(
    `importTranslationsTsvWithKeys - unitId: ${episodeKey}, lang: ${language}`
  );
  const existingSentencesDoc = await loadVerbatimDoc(episodeKey);
  const existingSentenceIds = new Set(
    Object.values(existingSentencesDoc.sentences).map(s => s.id)
  );
  const skipKinds = new Set(<string[]>[
    EKind.PARAGRAPH,
    EKind.CHAPTER_COMPLETE,
  ]);
  const lines = splitLinesFiltered(tsv);
  // TODO: improve the confusing naming, sometimes 'translations' maps to a string other times a record
  const translations: ElementIdToString = {};
  lines.shift();
  for (const [index, line] of lines.entries()) {
    let m = line.match(/^(.*?)\t(.*?)\t(.*?)\t/);
    if (!m) {
      throw new Error('error parsing line: ' + (index + 2) + ' : ' + line);
    }
    const id = m[1] as ElementId;
    const translation = unescapeQuotes(m[3]);
    m = id.match(/(.*?):/);
    if (!m) {
      throw new Error(
        'error parsing id for line: ' + (index + 2) + ' : ' + line
      );
    }
    // if (getKindFromId(id) === EKind.SENTENCE && !existingSentenceIds.has(id)) {
    if (idIsOfKind(id, EKind.SENTENCE) && !existingSentenceIds.has(id)) {
      throw new Error(
        'no match for sentence id in line:' + (index + 2) + ' : ' + line
      );
    }
    const kind = m[1];
    if (skipKinds.has(kind)) {
      continue;
    }
    translations[id] = translation;
  }
  await saveTranslations(episodeKey, translations, language, true);
  // todo: pass attribution into the server calls
  addAuditLogMessage(
    episodeKey,
    `Imported translations tsv for ${language}`,
    'IMPORT' /* author */
  );
}

function normalizeContent(content0: string) {
  let content = content0.split(/\s+/);
  content = strongNormalizeWordArray(content);
  return content.join(' ');
}

function stripPrefix(content: string) {
  // TODO
  return '';
}

export async function importTranslationsTsvNoKeys(
  episodeKey: string,
  tsv: string,
  language: string
) {
  console.log(
    `importTranslationsTsvNoKeys - unitId: ${episodeKey}, lang: ${language}`
  );
  const computeTranslations = (content: ElementList<Element>) => {
    const elements = content.values;
    const words = content.words;
    const contentMap: StringToElementId = {};
    for (const element of elements) {
      if (
        element.kind === EKind.WORD_GROUP ||
        element.kind === EKind.TRANSLATION
      ) {
        continue;
      }
      const content = getElementEditableContentString(element, words);
      if (isEmpty(content)) {
        continue;
      }
      const normalizedContent = normalizeContent(content);
      contentMap[normalizedContent] = element.id;
    }

    let lines = splitLinesFiltered(tsv);
    lines.shift();
    const translations: ElementIdToString = {};
    let index = 0;
    for (const [index, line] of lines.entries()) {
      const m = line.match(/^(.*?)\t(.*?)\t/);
      if (m) {
        continue;
      }
      let content = m[1];
      let translation = m[2];
      if (content.startsWith('@') || content.startsWith('###')) {
        continue;
      }
      content = normalizeContent(stripPrefix(content));
      let id: ElementId = contentMap[content];
      if (isNil(id)) {
        if (index < lines.length - 1) {
          const nextLine = lines[index + 1];
          const m = nextLine.match(/^(.*?)\t(.*?)\t/);
          if (!m) {
            continue;
          }
          const nextContent = m[1];
          const nextTranslation = m[2];
          if (nextContent.startsWith('@') || nextContent.startsWith('###')) {
            continue;
          }
          content = normalizeContent(stripPrefix(content + ' ' + nextContent));
          id = contentMap[content];
          if (isNil(id)) {
            continue;
          }
          translation += ' ' + nextTranslation;
        } else {
          continue;
        }
      }

      translations[id] = unescapeQuotes(translation);
    }

    const skipKinds = new Set(<string[]>[
      EKind.METADATA_BLOCK,
      EKind.PARAGRAPH,
      EKind.PARAGRAPH,
      EKind.CHAPTER_COMPLETE,
    ]);
    for (const element of elements) {
      if (skipKinds.has(element.kind)) {
        continue;
      }
      const matchedTranslation = translations[element.id];
      if (isNil(matchedTranslation)) {
        translations[element.id] = 'NO_IMPORT_MATCH';
      }
    }

    return translations;
  };

  const episodeContent = await loadScriptContent(episodeKey);
  const translations = computeTranslations(episodeContent);
  saveTranslations(episodeKey, translations, language, true);
}

export async function importTranslationsTsv(
  episodeKey: string,
  tsv: string,
  language: string
) {
  const unit = await new UnitManager().loadSoloById(episodeKey);
  if (!unit) {
    throw Error(`failed to load unit by id: ${episodeKey}`);
  }

  // assuming backup is performed with the node code before calling the masala-lib code
  // no good way to invoke the backup from this level
  // await unit.exportAllData();

  if (isEmpty(language)) {
    language = unit.l1Default;
  }
  tsv = tsv.replace(/\r\n/g, '\n').replace(/\r/g, '\n').replace(/\n"/g, '"');
  if (!checkTsvFormat(tsv)) {
    throw new Error(`not a translation TSV file`);
  }
  await importTranslationsTsvWithKeys(episodeKey, tsv, language);
  // importTranslationsTsvNoKeys(episodeKey, tsv, language);
}

export async function importTranslationsRaFlatText(
  episodeKey: string,
  text0: string,
  language: string
) {
  const unit = await new UnitManager().loadSoloById(episodeKey);
  if (!unit) {
    throw Error(`failed to load unit by id: ${episodeKey}`);
  }
  // ensure data backup before potentially destructive operation
  // assumes server is running and this is run from the console
  // TODO: config flag to disable this for dev builds
  try {
    await unit.exportAllData();
  } catch (error) {
    console.error(error);
    window.alert(error);
  }

  let text = text0.replace(/\ufeff/, '');

  // strip speaker labels
  text = text.replace(/^\[[^\]]+]:?/gm, '');
  // strip google comment markers
  text = text.replace(/\[[a-zA-Z\s]+?\]/g, '');

  // @jason, i hate these !@#$ reg-ex's. please recognize how much of both our time you burned with this
  // untested code, instead of a simple line oriented filter
  text = text.replace(/^\s*@[^\r\n]+/gm, ''); // TODO check regex
  // remove whitespace from beginning lines
  text = text.replace(/^[ \t\r]+/gm, ''); // TODO check regex

  const scriptContent = await loadScriptContent(episodeKey);
  const sentences = scriptContent.filterByKind(EKind.SENTENCE).values;
  const ids = sentences.map(s => s.id);
  let translationLines = splitLinesFiltered(text);
  translationLines = translationLines.filter(l => !isEmpty(l));
  // this have be a lot simpler that the !@#$ reg-ex
  // translationLines = translationLines.filter(l => !l.trim().startsWith('@'));
  translationLines = translationLines.slice(0, ids.length);
  const translations: ElementIdToString = {};
  for (const [id, translation] of _.zip(ids, translationLines)) {
    console.log(`id: ${id}, translation: ${translation}`);
    // TODO not python used zip_longest and fillvalue=""
    if (!isEmpty(translation)) {
      translations[id] = unescapeQuotes(translation);
    } else {
      console.log(`empty 'translation' for id: ${id}`);
      // indicates mismatched zip, should provide more details to user
    }
  }

  // reset the existing translation versions data when importing from google doc
  await resetTranslationVersionDoc(episodeKey);

  await saveTranslations(episodeKey, translations, language, true);
  const status = `sentence count: ${ids.length}, translation count: ${translationLines.length}`;
  console.log(`status: ${status}`);
  return status;
}

export async function importTranslationsFromRaGoogleDoc(
  episodeKey: string,
  url: string,
  language: string
) {
  const text = await fetchGoogleDocText(url);
  return await importTranslationsRaFlatText(episodeKey, text, language);
}
