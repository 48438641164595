import { DocumentChange } from '@firebase/firestore-types';
import {
  cyrb53a,
  elementCategory,
  elementIsDecorator,
  elementIsOutput,
  elementIsReference,
  epochSeconds,
  filterOnKind,
  filterOnKinds,
  filterReferenceWithScriptOptions,
  filterUniqueHashes,
  getLlmReferenceTextForTranslation,
  getReferenceToMasalaAnchorMap,
  getReferenceToMasalaIdMap,
  hasExistingContent,
  importLlmStructuralIntoMasala,
  importLlmTranslationIntoMasala,
  importLlmVocabIntoMasala,
  lintVocab,
  sortScriptElements,
} from '@masala-lib/llm/llm-funcs';
import {
  computeForwardThread,
  computeReverseThread,
  createIndexOnPriorId,
  filterToActiveAlerts,
  invokeLlm,
  llmMessagesFromExchanges,
  minusSet,
  numberSetFromRanges,
  rangesFromNumberSet,
  mergeElementEdits,
  scriptElementsFromParsedResponses,
  sortOnTimestamp,
  setSuppressExchange,
  listenAllProjectDocs,
  saveProjectDoc,
  handleProjectDocChange,
  createProjectMetadata,
  projectSummary,
  fetchAllProjectDatas,
  handleUpdatedProjectData,
  allocateDocId,
  defaultLlmOptions,
  refsOfResponse,
  translationTaskComputeReferenceConflicts,
  countsPerInterval,
  boundariesToRanges,
  insertOrRemoveOnSortedArray,
  initReferenceScriptFromMasala,
  mergeProjectData,
  filterScriptElementsByRange,
  isTerminalStep,
  deleteProjectDoc,
  counterToLetterCode,
  initElementOverridesDoc,
  rangeFromNumbers,
  initNotesDoc,
  initSignoffsDoc,
  getElementKindsFromScriptOptions,
  mergeNotes,
  mergeSignoffs,
  translationResponseParsers,
  structuralResponseParsers,
  initExchangeFlags,
  initElementFlags,
  getSuppressedFlag,
  mergeElementFlags,
  initAddedElementsDoc,
  mergeAddedElements,
  getSamosaFlag,
  initUnitDataFromMasala,
  setSuppressedFlag,
  exchangesByIntervals,
  reallocateOverridesToAdded,
  structuralTaskSyntheticElementFor,
  translationTaskComputeElementKeys,
  structuralTaskComputeElementKeys,
  vocabTaskComputeElementKeys,
  vocabResponseParsers,
  initAlertSuppressionsDoc,
  mergeAlertSuppressions,
} from '@masala-lib/llm/project/llm-project-funcs';
import {
  Range,
  ElementOverrideLookup,
  Exchange,
  LintAlert,
  ProjectMetadata,
  ParsedResponse,
  ProjectModel,
  ReferenceScript,
  SuppressionsData,
  ProjectDocIds,
  MessageEditor,
  LLMOptions,
  ProjectDoc,
  Step,
  StepListLookup,
  ImportScript,
  CreateProjectParams,
  ProjectTask,
  SectionInfo,
  SectionsInfo,
  RawResponse,
  Prompt,
  ScriptOptions,
  ProjectData,
  SidebarMode,
  FlagsData,
  NotesData,
  SignoffsData,
  ResponseParserFn,
  AddedElementsLookup,
  SynthElementFunction,
} from '@masala-lib/llm/project/llm-project-types';
import {
  ElementCategory,
  ScriptElement,
  ScriptElementKind,
} from '@masala-lib/llm/llm-types';
import { createLogger } from '@app/logger';
import { get, isEmpty } from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { fromIntervals, Intervals, NO_INDEX } from '@tikka/intervals/intervals';
import { alertError } from 'ui/misc-utils';
import {
  TouchUpEditorModal,
  TouchupEditorOptions,
} from './touchup-editor-modal';
import { runStructuralMergeModal } from 'samosa/ui/merge/structural-merge-modal';
import { bugsnagNotify } from '@app/notification-service';
import {
  fetchScopedPrompts,
  fetchTaskPrompts,
  savePrompt,
  sortPrompts,
} from '@masala-lib/llm/project/prompt-funcs';
import { runColumnMergeDialog } from '../ui/merge/column-merge-modal';
import { runSimpleConfirmation } from '../ui/components/simple-confirm';
import { runTextFormModal } from 'samosa/ui/components/text-form-modal';
import { runTranslationMergeModal } from 'samosa/ui/merge/translation-merge-modal';
import { ScriptInjectParams } from './script-inject-params';
import { roundToTenth } from '@utils/util';
import { PromptEntryModel } from 'samosa/ui/message-entry/prompt-entry-model';
import { AppUser } from '@masala-lib/editorial/models/user-manager';
import { Auth } from '@masala-lib/editorial/db/auth';
import { StructuredPlayer } from '@tikka/player/structured-player';
import { createAudioSource } from 'script-editor/models/script-editor-audio-sources';
import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import { Script } from 'vm';
import { epochSecondsFloat } from '@masala-lib/utils';
import { t } from '@cassiozen/usestatemachine';

/**
 * Interpolates a string with values from a context object.
 * @param str - The string to interpolate. include keys in the form of {key} to be replaced.
 * @param context - The context object containing key-value pairs to replace in the string.
 * @returns The interpolated string.
 */
function interpolateString(str: string, context: Record<string, string>) {
  let interpolated = str;

  const keys = Object.keys(context);

  keys?.forEach((key: string) => {
    const regex = new RegExp(`{${key}}`, 'g');
    interpolated = interpolated.replace(regex, context[key]);
  });

  return interpolated;
}

// FILE TODO LIST
// TODO factor merge script computation - raw merge script, output kinds merge script, output merge script etc
// TODO factor implementation all touchupX methods
// TODO remove all unwanted mutation ops - setSuppressedElement etc
// TODO look at all awaits to catch other unwanted mutation ops
// TODO remove all unwanted element status ops - isSectionHead etc?

const log = createLogger('samosa-model');

function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export async function loadSamosaModel(
  projectId: string
  // { listen }: { listen: boolean } // not sure yet if there utility in a non-listen mode
) {
  const model = new SamosaModel({ projectId });
  await model.initialize();
  return model;
}

export async function createSamosaModel(
  params: CreateProjectParams
): Promise<SamosaModel> {
  if (!params.unitId) {
    return null;
  }
  const metadata = await createProjectMetadata(params);
  const model = await loadSamosaModel(metadata.projectId);
  return model;
}

export type ThreadStatus = 'current' | 'prior' | 'forward' | 'fork' | '';

export type BuildMergeScriptOptions = {
  range?: Range;
  mergeAddedElements?: boolean;
  computeRangeFromElements?: boolean;
  mergeAllGlobalReferences?: boolean;
  preserveOverridenInOutput?: boolean;
  preserveNonUniqueInOutput?: boolean;
};

export class SamosaModel implements ProjectModel {
  projectId: string;

  @observable
  projectMetadata: ProjectMetadata;

  // probably doesn't need to be observable
  @observable.ref
  referenceScript: ReferenceScript;

  // includes both the exchanges and a redundant index to the referenceScript
  @observable.ref
  stepMap: Map<string, Step> = observable.map({}, { deep: false });

  @observable.ref
  editLookup: ElementOverrideLookup = {};

  @observable.ref
  addedElementsLookup: AddedElementsLookup;

  @observable.ref
  exchangeFlags: FlagsData;

  @observable.ref
  elementFlags: FlagsData;

  @observable.ref
  suppressedAlerts: SuppressionsData = {};

  @observable.ref
  samosaFlags: FlagsData = {};

  @observable.ref
  masalaFlags: FlagsData = {};

  @observable.ref
  notes: NotesData = {};

  @observable.ref
  signoffs: SignoffsData = {};

  // reflects what was actually imported back to script editor
  @observable.ref
  importScript: ImportScript;

  @observable
  currentStepId: string = null;

  // prompt template library filtered for project task type
  @observable.ref
  // prompts: Map<string, Prompt> = observable.map({}, { deep: false });
  prompts: Prompt[] = [];

  scriptInjectParams: ScriptInjectParams = new ScriptInjectParams();

  @observable.ref
  modal: any = null;

  docIds: ProjectDocIds = {};

  @observable
  messageEditor: MessageEditor;

  outputKinds: ScriptElementKind[] = [];
  referenceKinds: ScriptElementKind[] = [];

  elementIsReference: (element: ScriptElement) => boolean;
  elementIsOutput: (element: ScriptElement) => boolean;
  elementIsDecorator: (element: ScriptElement) => boolean;
  elementIsComparison: (element: ScriptElement) => boolean;
  elementCategory: (element: ScriptElement) => ElementCategory;
  mergeCanHaveConflicts: boolean = false;
  mergeCanHaveMissing: boolean = false;

  computeElementKeys: (element: ScriptElement, override?: boolean) => void;
  synthElementFunction: SynthElementFunction = null;
  postParseLint: (parsedResponse: ParsedResponse) => void = null;

  @observable
  showSectionManager: boolean = false;

  @observable
  showExploratoryUI: boolean = false; // inject script flags, project menu items

  _player: StructuredPlayer = null;

  // current authenticated user
  appUser: AppUser;
  mergeModal: TouchUpEditorModal = null;

  constructor({ projectId }: { projectId: string }) {
    this.projectId = projectId;
    makeObservable(this);
  }

  async initialize() {
    log.debug('initialize');
    this.appUser = Auth.getInstance().appUser;

    await this.loadAllProjectDocs();
    // the listen isn't guaranteed to return all data in it's first querysnapshot result
    // so load explicitly first, then listen
    this.listenAllProjectDocs();

    // assuming project always created with at least a metadata doc
    if (!this.projectMetadata) {
      throw Error(`missing project metadata - projectId: ${this.projectId}`);
    }
    if (!this.referenceScript) {
      log.info(`initializing reference script - projectId: ${this.projectId}`);
      await initReferenceScriptFromMasala(this.projectId, this.unitId, {
        task: this.task,
        subtask: this.projectMetadata.subtask,
      });
      await initUnitDataFromMasala(this.projectMetadata, this.unitId);
    }

    if (!this.docIds.exchangeFlagsDocId) {
      log.info(`initializing suppressed exchanges`);
      await initExchangeFlags(this.projectId);
    }
    if (!this.docIds.elementFlagsDocId) {
      log.info(`initializing suppressed elements`);
      await initElementFlags(this.projectId);
    }
    if (!this.docIds.editsDocId) {
      log.info(`initializing element overrides`);
      await initElementOverridesDoc(this.projectId);
    }
    if (!this.docIds.addedElementsDocId) {
      log.info(`initializing added elements`);
      await initAddedElementsDoc(this.projectId);
    }
    if (!this.docIds.notesDocId) {
      log.info(`initializing notes`);
      await initNotesDoc(this.projectId);
    }
    if (!this.docIds.signoffsDocId) {
      log.info(`initializing signoffs`);
      await initSignoffsDoc(this.projectId);
    }
    if (!this.docIds.suppressedAlertsDocId) {
      log.info(`initializing suppressed alerts`);
      await initAlertSuppressionsDoc(this.projectId);
    }
    this.setCurrentStepId(this.latestStep?.id);

    if (!this.projectMetadata.llmOptions) {
      await this.updateLlmOptions(defaultLlmOptions());
    }
    await this.loadPrompts();

    this.taskConfigInitialize();
    this.subtaskInitialize();

    const outputKinds = this.outputKinds;
    const referenceKinds = this.referenceKinds;
    this.elementIsReference = (el: ScriptElement) =>
      elementIsReference(el, referenceKinds);
    this.elementIsOutput = (el: ScriptElement) =>
      elementIsOutput(el, outputKinds);
    this.elementIsDecorator = (el: ScriptElement) =>
      elementIsDecorator(el, referenceKinds, outputKinds);
    this.elementCategory = (el: ScriptElement) =>
      elementCategory(el, referenceKinds, outputKinds);

    this.ensureInitialSectionHead();

    // automatically use translations for structural work when the l1 is english
    if (this.task === 'structural' && this.projectMetadata.l0IsTranslation) {
      this.scriptInjectParams.setScriptOption('sub_translations', true);
    }
  }

  get player(): StructuredPlayer {
    if (this._player) {
      return this._player;
    }
    if (!this.referenceScript.audioUrl) {
      return null;
    }
    const audioSource = createAudioSource();
    audioSource.setAudioSourceDefinitions(this.unitId, {
      audioUrl: this.referenceScript.audioUrl,
    });
    const transportState = new TransportState();
    const audioTransport = new AudioTransport(transportState, {
      exactPauseAfter: true,
    });
    audioTransport.setAudioSource(audioSource);
    const player = new StructuredPlayer(audioTransport, transportState);
    this._player = player;
    return player;
  }

  get activeScopes(): string[] {
    return [this.appUser.id, this.unitId, this.projectId];
  }

  get languagesKey(): string {
    return `${this.projectMetadata.l2Code}:${this.projectMetadata.l1Code}`;
  }

  async loadPrompts() {
    const fetched = await fetchScopedPrompts(this.task, this.activeScopes);
    this.prompts = sortPrompts(fetched);
  }

  // async createPrompt(text: string) {
  //   const prompt: Prompt = {
  //     slug: randomString(8),
  //     title: randomString(5),
  //     task: this.task,
  //     text,
  //   };
  //   await savePrompt(prompt);
  //   await this.loadPrompts();
  // }

  async updatePrompt(prompt: Prompt) {
    await savePrompt(prompt);
    await this.loadPrompts();
  }

  async swapPromptPosition(prompt: Prompt, direction: -1 | 1) {
    const currentPosition = this.prompts.indexOf(prompt);
    const length = this.prompts.length;
    const newPosition = (currentPosition + direction + length) % length;
    prompt.position = newPosition;
    const otherPrompt = this.prompts[newPosition];
    otherPrompt.position = currentPosition;
    this.prompts = sortPrompts(this.prompts); // sort locally first for immediate UI feedback
    await savePrompt(prompt);
    await savePrompt(otherPrompt);
    await this.loadPrompts();
  }

  get selectedScriptOptions(): ScriptOptions {
    // return selectedScriptOptions(this.selectedScriptOptionsMap);
    return this.scriptInjectParams.selectedScriptOptions;
  }

  selectionScriptText(option: ScriptOptions): string {
    const [text] = this.selectedScript(option);
    return text;
  }

  selectedScript(options: ScriptOptions): [string, ScriptElement[]] {
    // TODO naming
    // if (anySectionsSelected(this.sectionSelectionMap)) {
    if (this.scriptInjectParams.anySectionsSelected) {
      const sectionElements = [];
      const selectedKinds = getElementKindsFromScriptOptions(
        this.selectedScriptOptions
      );
      for (const [
        index,
        selected,
      ] of this.scriptInjectParams.sections.entries()) {
        if (selected) {
          sectionElements.push(
            this.referenceElementsForSection(index, selectedKinds)
          );
        }
      }
      const referenceElements = sectionElements.flat();
      const referenceScripts = sectionElements.map(section =>
        getLlmReferenceTextForTranslation(
          section,
          this.referenceScript.elementIdToTranslation,
          options,
          this.task
        )
      );
      const referenceText = referenceScripts.join('\n\n');
      return [referenceText, referenceElements];
    } else {
      return [this.referenceText, this.referenceElements];
    }
  }

  // intentionally not @computed
  buildSelectedScriptText(): string {
    const [result] = this.selectedScript(this.selectedScriptOptions);
    return result;
  }

  substitutePrompt(text: string): [string, ScriptElement[]] {
    // todo: support replace key permutations which override current selection
    // const scriptNoStruct = this.selectedScript({ speakers: true, numbers: true });
    // result = text.replace('{scriptNoStruct}', scriptNoStruct);
    if (text.includes('{script}')) {
      const [script, elements] = this.selectedScript(
        this.selectedScriptOptions
      );
      const resultText = text.replace('{script}', script);
      return [resultText, elements];
    } else {
      return [text, null];
    }
  }

  get sidebarMode(): SidebarMode {
    return this.projectMetadata.sidebarMode || 'thread';
  }

  async toggleSidebarMode() {
    const mode = this.sidebarMode;
    const nextMode: SidebarMode = mode === 'exchange' ? 'thread' : 'exchange';
    await mergeProjectData<ProjectMetadata>(this.projectMetadata, {
      sidebarMode: nextMode,
    });
  }

  async toggleTwoColumnMerge() {
    await mergeProjectData<ProjectMetadata>(this.projectMetadata, {
      twoColumnMergeEnabled: !this.projectMetadata.twoColumnMergeEnabled,
    });
  }

  taskConfigInitialize() {
    switch (this.task) {
      case 'translation':
        // this.taskParseResponse = translationTaskParsedResponse;
        // this.defaultPrompt = defaultTranslationPrompt;
        // this.promptRepositoryUrl =
        //   'https://jiveworld.slite.com/app/docs/TmXivF07oa_P5D?source=default_subdocs_directory_block';
        this.mergeCanHaveConflicts = true;
        this.mergeCanHaveMissing = true;
        this.referenceKinds = [
          'SENTENCE',
          'CHAPTER',
          'PASSAGE',
          'SPEAKER_LABEL',
        ];
        this.outputKinds = ['TRANSLATION'];
        this.computeElementKeys = translationTaskComputeElementKeys;
        break;
      case 'structural':
        // this.taskParseResponse = structureTaskParsedResponse;
        // this.defaultPrompt = defaultStructuralPrompt;
        // this.promptRepositoryUrl =
        //   'https://jiveworld.slite.com/app/docs/BS-PoXNYVqFGW9?source=default_subdocs_directory_block';
        this.mergeCanHaveConflicts = false;
        this.mergeCanHaveMissing = false;
        this.referenceKinds = ['SENTENCE'];
        this.outputKinds = [
          'CHAPTER_BREAK',
          'PASSAGE_BREAK',
          'CHAPTER',
          'PASSAGE',
          'CHAPTER_SUMMARY',
          'PASSAGE_SUMMARY',
        ];
        this.synthElementFunction = structuralTaskSyntheticElementFor;
        this.computeElementKeys = structuralTaskComputeElementKeys;
        break;
      case 'vocab':
        this.mergeCanHaveConflicts = false;
        this.mergeCanHaveMissing = false;
        this.outputKinds = ['VOCAB'];
        this.computeElementKeys = vocabTaskComputeElementKeys;
        this.postParseLint = (parsedResponse: ParsedResponse) =>
          this.vocabPostParseLint(parsedResponse);
        // don't include translations into LLM reference script by default
        // future: possibly make a project setting and provide toggle UI
        this.scriptInjectParams.setScriptOption('translations', false);
        break;
      default:
        assertUnreachable(this.task);
    }
  }

  subtaskInitialize() {
    this.referenceKinds = ['SENTENCE'];
    switch (this.projectMetadata.subtask) {
      case 'transcript':
        this.showSectionManager = false;
        this.showExploratoryUI = false;
        // we need to omit during initial import. filtering at this level still treated as 'missing'
        // this.scriptInjectParams.setScriptOption('chapters', false);
        // this.scriptInjectParams.setScriptOption('passages', false);
        break;

      case 'structural': // translation
        this.showSectionManager = false;
        this.showExploratoryUI = false;
        this.scriptInjectParams.setScriptOption('sentences', false);
        this.scriptInjectParams.setScriptOption('chapters', true);
        this.scriptInjectParams.setScriptOption('passages', true);

        this.referenceKinds = ['CHAPTER', 'PASSAGE'];
        // todo: think about L0 modes implications here
        break;

      case 'exploratory':
      default:
        this.showSectionManager = true;
        this.showExploratoryUI = true;
    }
  }

  async loadAllProjectDocs() {
    const datas = await fetchAllProjectDatas(this.projectId);
    for (const data of datas) {
      handleUpdatedProjectData(this, data);
    }
  }

  @computed
  get completed(): boolean {
    return !!this.projectMetadata?.completedTimestamp;
  }

  @computed
  get state(): string {
    // note, this is orthogonal to 'archived'
    return this.completed ? 'completed' : 'open';
  }

  get task(): ProjectTask {
    return this.projectMetadata.task || 'translation';
  }

  get subTask(): string {
    return this.projectMetadata.subtask || 'transcript';
  }

  get llmOptions(): LLMOptions {
    return this.projectMetadata.llmOptions || defaultLlmOptions();
  }

  increaseTemperature() {
    const options = this.llmOptions;
    options.temperature = roundToTenth(options.temperature + 0.1);
    this.updateLlmOptions(options).catch(alertError);
  }

  decreaseTemperature() {
    const options = this.llmOptions;
    options.temperature = roundToTenth(options.temperature - 0.1);
    this.updateLlmOptions(options).catch(alertError);
  }

  setLlm(modelKey: string) {
    const options = this.llmOptions;
    options.model = modelKey;
    this.updateLlmOptions(options).catch(alertError);
  }

  toggleStreaming() {
    PromptEntryModel.instance.setShowSettings(true);
    const options = this.llmOptions;
    options.streamingDisabled = !options.streamingDisabled;
    this.updateLlmOptions(options).catch(alertError);
  }

  setNewThread() {
    this.setCurrentStepId(this.referenceScript.id);
    PromptEntryModel.instance.focusPromptArea();
  }

  setCurrentStepId(id: string) {
    if (!id) {
      alertError('setCurrentStepId - id required');
      return;
    }
    console.log('SET CURRENT: ' + id);
    this.currentStepId = id;
    console.log(this.sectionsInfo);
    PromptEntryModel.instance.focusPromptArea();
  }

  async updateLlmOptions(options: LLMOptions) {
    this.projectMetadata.llmOptions = options || defaultLlmOptions();
    await saveProjectDoc<ProjectMetadata>(this.projectId, this.projectMetadata);
  }

  get referenceElements(): ScriptElement[] {
    return filterReferenceWithScriptOptions(
      this.referenceScript.elements,
      this.referenceScript.elementIdToTranslation,
      this.selectedScriptOptions
    );
  }

  get referenceText(): string {
    return getLlmReferenceTextForTranslation(
      this.referenceElements,
      this.referenceScript.elementIdToTranslation,
      this.selectedScriptOptions,
      this.task
    );
  }

  referenceElementsForRange(
    range: Range,
    kinds: ScriptElementKind[]
  ): ScriptElement[] {
    const rangeFiltered = filterScriptElementsByRange(
      this.referenceScript.elements,
      range
    );
    return filterOnKinds(rangeFiltered, kinds);
  }

  referenceElementsForSection(
    index: number,
    kinds: ScriptElementKind[]
  ): ScriptElement[] {
    const range = this.referenceScript.sectionRanges[index];
    return this.referenceElementsForRange(range, kinds);
  }

  listenAllProjectDocs() {
    log.debug(`listenAllProjectDocs - initiated`);
    listenAllProjectDocs(this.projectId, querySnapshot => {
      runInAction(() => {
        const changes =
          querySnapshot.docChanges() as DocumentChange<ProjectDoc>[];
        log.debug(`listenAllProjectDocs - changes len: ${changes.length}`);
        for (const change of changes) {
          handleProjectDocChange(this, change);
        }
      });
    });
  }

  get projectName(): string {
    return this.projectMetadata.name || this.projectMetadata.projectId;
  }

  get unitName(): string {
    return this.projectMetadata.unitName || this.unitId;
  }

  get projectSummary(): string {
    return projectSummary(this.projectMetadata);
  }

  async parallelSend(basePrompt: string) {
    if (isEmpty(basePrompt)) {
      alertError('prompt text required');
      return;
    }

    const priorStep = this.referenceScript;
    const selectedKinds = getElementKindsFromScriptOptions(
      this.selectedScriptOptions
    );
    for (const range of this.referenceScript.sectionRanges) {
      let referenceElements = this.referenceElementsForRange(
        range,
        selectedKinds
      );
      let referenceText = getLlmReferenceTextForTranslation(
        referenceElements,
        this.referenceScript.elementIdToTranslation,
        this.selectedScriptOptions,
        this.task
      );
      // const message = [basePrompt, script].join('\n');
      const message = basePrompt.replace('{script}', referenceText);

      this.sendMessage(
        message,
        priorStep,
        referenceElements,
        basePrompt,
        true /*streamingDisabled*/
      ).catch(alertError);
    }
  }

  // todo: give this some actual thought
  abortResponse() {
    // todo: error handling
    this.currentExchange.aborted = true;
    this.currentExchange.pendingResponse = false;
  }

  async createImportScript(): Promise<ImportScript> {
    // TODO factor this out into a function?
    const referenceElements = this.referenceScript.elements;
    const elements = this.outputMergeScript;
    console.log('elements', elements);
    const referenceToId = getReferenceToMasalaIdMap(referenceElements);
    console.log('referenceToId', referenceToId);
    const referenceToAnchor = getReferenceToMasalaAnchorMap(referenceElements);

    const importScript: ImportScript = {
      id: this.importScript?.id, // replace existing doc if already exists
      kind: 'IMPORT_SCRIPT',
      projectId: this.projectId,
      unitId: this.unitId,
      elements,
      referenceToId,
      referenceToAnchor,
    };
    const savedData = await saveProjectDoc<ImportScript>(
      this.projectId,
      importScript
    );
    this.importScript = savedData;
    return savedData;
  }

  async importBackToUnit(merge: boolean) {
    // TODO func configured on this like style of other task variants
    let proceed = true;
    if (
      await hasExistingContent(this.unitId, {
        task: this.task,
        subtask: this.subTask,
      })
    ) {
      proceed = await runSimpleConfirmation(
        'This action may overwrite some existing content in the unit. Proceed?'
      );
    }
    if (!proceed) {
      return;
    }
    switch (this.task) {
      case 'translation':
        await this.importTranslationBackToUnit();
        break;
      case 'structural':
        await this.importStructuralBackToUnit(merge);
        break;
      case 'vocab':
        await this.importVocabBackToUnit(merge);
        break;
    }
  }

  async saveToMasala(editor: TouchUpEditorModal): Promise<void> {
    if (this.mergeModal !== editor) {
      return null;
    }
    await this.persistTouchupEditorModal(editor);
    await this.importBackToUnit(false); // TODO merge??
  }

  async importTranslationBackToUnit() {
    const unitId = this.unitId;
    const importScript = await this.createImportScript();
    await importLlmTranslationIntoMasala({
      unitId,
      elements: importScript.elements,
      locale: this.projectMetadata.l1Code,
      referenceToId: importScript.referenceToId,
      elementFlags: this.elementFlags,
      notes: this.notes,
      noPersist: false,
      swapped: this.projectMetadata.l0IsTranslation,
    });
    await this.markCompleted();
  }

  async importVocabBackToUnit(merge: boolean) {
    // TODO
    const importScript = await this.createImportScript();

    await importLlmVocabIntoMasala({
      importScript,
      elementFlags: this.elementFlags,
      notes: this.notes,
      locale: this.projectMetadata.l1Code,
      merge,
    });
    await this.markCompleted();

    // const unitId = this.unitId;
    // const importScript = await this.createImportScript();
    // await importLlmTranslationIntoMasala({
    //   unitId,
    //   elements: importScript.elements,
    //   locale: this.projectMetadata.l1Code,
    //   referenceToId: importScript.referenceToId,
    //   elementFlags: this.elementFlags,
    //   notes: this.notes,
    //   noPersist: false,
    // });
    // await this.markCompleted();
  }

  async importStructuralBackToUnit(merge: boolean) {
    const importScript = await this.createImportScript();
    await importLlmStructuralIntoMasala({
      importScript,
      elementFlags: this.elementFlags,
      notes: this.notes,
      merge,
    });
    await this.markCompleted();
  }

  async sendMessage(
    message: string,
    priorStep: Step,
    referenceElements0: ScriptElement[],
    prompt: string, // = null,
    streamingDisabled: boolean
  ): Promise<Exchange> {
    if (isEmpty(message)) {
      throw Error('message data required');
    }
    if (message === 'fail') {
      throw Error('simulated failure');
    }

    const parserKey = 'standard'; // todo: should be determined from prompt once multiple parsers are supported
    let [scriptText, referenceElements] = this.substitutePrompt(message);
    prompt = prompt || message;
    message = scriptText;
    referenceElements ||= referenceElements0;

    priorStep ||= this.currentStep || this.referenceScript;
    const priorLabel =
      priorStep.kind === 'EXCHANGE' ? priorStep.label : undefined;

    const priorExchanges = this.reverseExchangesFor(priorStep);
    const priorMessages = llmMessagesFromExchanges(priorExchanges);

    const code = await this.nextCodeForPriorStep(priorStep);

    const timestamp = epochSeconds();
    const exchange: Exchange = {
      kind: 'EXCHANGE',
      projectId: this.projectId,
      id: allocateDocId(this.projectId),
      code,
      label: priorLabel,
      timestamp,
      priorId: priorStep.id,
      request: {
        text: message,
        promptText: prompt,
        promptHash: cyrb53a(prompt).toString(36),
        parserKey,
        llmOptions: this.llmOptions,
        user: this.appUser?.alias,
        timestamp,
      },
      referenceElements,
      // `observable` here is needed for the UI to update as text is streamed back
      rawResponse: observable({
        text: '', // will be streamed into
        usedTokens: 0,
        llmExtra: {},
        timestamp,
      }),
      parsedResponse: { elements: [], alerts: [] }, // to be populated after response
      pendingResponse: true,
      usedTokens: 0, // TODO
    };

    // set transient exchange into model (will get persisted after response complete)
    this.stepMap.set(exchange.id, exchange);
    this.currentStepId = exchange.id;
    const streamCallback = /*this.*/ streamingDisabled
      ? null
      : (text: string) => (exchange.rawResponse.text = text);

    try {
      const reply = await invokeLlm(
        priorMessages,
        message,
        this.llmOptions,
        streamCallback,
        // (text: string) => (exchange.rawResponse.text = text), // streamCallback
        () => exchange.aborted // shouldCancelCallback, signal to interrupt the streamed response
      );
      // this.messageEditor.clear();

      const finishTimestamp = epochSeconds();
      exchange.rawResponse = {
        text: reply,
        usedTokens: 0, // TODO
        llmExtra: {},
        timestamp: finishTimestamp,
      };
      exchange.parsedResponse = this.parseExchangeResponse(exchange);
      exchange.pendingResponse = false;
      exchange.requestDurationSeconds = finishTimestamp - timestamp;
    } catch (e) {
      bugsnagNotify(e as Error);
      exchange.rawResponse.error = String(e);
      exchange.pendingResponse = false;
    }

    // hack in suppress all parsed elements
    // const parsedResponse = exchange.parsedResponse;
    // const parsedElementIds = parsedResponse.elements
    //   .filter(el => el.kind !== 'UNRECOGNIZED')
    //   .map(element => element.id);
    // const currentFlagData = this.elementFlags;
    // const flags: FlagsData = {};
    // for (const id of parsedElementIds) {
    //   const currentFlags = currentFlagData[id];
    //   flags[id] = setSuppressedFlag(currentFlags, true);
    // }
    // await mergeElementFlags(this.docIds.elementFlagsDocId, flags);

    await saveProjectDoc<Exchange>(this.projectId, exchange);
    return exchange;
  }

  async nextCodeForPriorStep(priorStep: Step) {
    if (priorStep.kind === 'EXCHANGE' && !!priorStep.code) {
      const priorCode = priorStep.code;
      // base a string in the format of "ABC234" into alpha and numeric parts
      const alpha = priorCode.replace(/\d/g, '');
      const numeric = Number(priorCode.replace(/\D/g, ''));
      if (this.isTerminalStep(priorStep)) {
        return `${alpha}${numeric + 1}`;
      } else {
        // handle the case where the prior step is a fork
        // todo: consider basing forked base on prior base, i.e. A -> AA
        const base = await this.allocateThreadCode();
        const priorExchanges = this.reverseExchangesFor(priorStep);
        return `${base}${priorExchanges.length + 1}`;
      }
    } else {
      const base = await this.allocateThreadCode();
      return `${base}1`;
    }
  }

  async allocateThreadCode(): Promise<string> {
    const counter = (this.projectMetadata.threadCounter || 0) + 1;
    // need to update within model immediately for the parallel send to flow
    this.projectMetadata.threadCounter = counter;
    await mergeProjectData<ProjectMetadata>(this.projectMetadata, {
      threadCounter: counter,
    });
    return counterToLetterCode(counter);
  }

  get streamingDisabled(): boolean {
    return this.llmOptions.streamingDisabled;
    // || this.llmOptions.model.startsWith('anthropic')
  }

  // todo: remove once message-entry revised (after merging armando's latest work)
  get pendingResponse(): boolean {
    return this.currentExchange?.pendingResponse;
  }

  async deleteExchange(exchange: Exchange) {
    if (!this.isTerminalStep(exchange)) {
      throw Error('cannot delete non-terminal exchange');
    }
    await deleteProjectDoc(exchange.id);
  }

  async confirmAndDeleteExchange(exchange: Exchange) {
    const confirmed = await runSimpleConfirmation('Are you sure?');
    if (confirmed) {
      this.deleteExchange(exchange).catch(alertError);
    }
  }

  async handleEditExchangeLabel(exchange: Exchange) {
    const text = await runTextFormModal({
      label: 'Edit label',
      text: exchange.label,
    });
    if (text) {
      this.updateExchangeLabel(exchange, text).catch(alertError);
    }
  }

  async updateExchangeLabel(exchange: Exchange, label: string) {
    await mergeProjectData(exchange, {
      label,
    });
  }

  async overrideExchangeResponse(exchange: Exchange, text: string) {
    log.info(`overrideExchangeResponse`);
    const rawResponse: RawResponse = {} as any;
    if (!exchange.rawResponse.originalLlmText) {
      rawResponse.originalLlmText = exchange.rawResponse.text;
    }
    rawResponse.text = text;
    rawResponse.overridden = true;
    exchange.rawResponse = rawResponse;
    const parsedResponse = this.parseExchangeResponse(exchange);
    await mergeProjectData(exchange, {
      rawResponse,
      parsedResponse,
    });
  }

  async resetResponseOverride(exchange: Exchange) {
    log.info(`resetResponseOverride`);
    const rawResponse: RawResponse = {} as any;
    rawResponse.text = exchange.rawResponse.originalLlmText;
    rawResponse.overridden = false;
    rawResponse.originalLlmText = undefined;
    // @jason, updating directly here because i don't trust firebase listeing to update in time for the
    // below refreshParsedRespose. can you think of a better approach/solution
    exchange.rawResponse = rawResponse;
    const parsedResponse = this.parseExchangeResponse(exchange);
    await mergeProjectData(exchange, {
      rawResponse,
      parsedResponse,
    });
  }

  asyncSetResponseParsingDisabled(exchange: Exchange, isDisabled: boolean) {
    this.setResponseParsingDisabled(exchange, isDisabled).catch(alertError);
  }

  async setResponseParsingDisabled(exchange: Exchange, isDisabled: boolean) {
    const request = exchange.request;
    request.llmOptions.parsingDisabled = isDisabled;
    await mergeProjectData(exchange, {
      request,
    });
    await this.refreshParsedResponse(exchange);
  }

  async refreshParsedResponse(exchange: Exchange) {
    const parsedResponse = this.parseExchangeResponse(exchange);
    await mergeProjectData(exchange, {
      parsedResponse,
    });
  }

  postParseSynthesize(parsedResponse: ParsedResponse, timestamp: number): void {
    if (!this.synthElementFunction) {
      return;
    }

    const elements = parsedResponse.elements;
    const outputElements: ScriptElement[] = [];
    const keyOf = (element: ScriptElement) => element.kind + element.reference;
    // perhaps cleaner
    // const keyOf = (element: ScriptElement) => [element.kind, element.reference].join(':');

    const existingBreaks = this.outputMergeScript.filter(el =>
      el.kind.endsWith('_BREAK')
    );
    const existingBreakKeys = existingBreaks.map(keyOf);
    const synthKeys = new Set<string>(existingBreakKeys);

    for (const element of elements) {
      const synthElement = this.synthElementFunction(
        element,
        'PARSE',
        timestamp
      );
      if (synthElement && !synthKeys.has(keyOf(synthElement))) {
        outputElements.push(synthElement);
        synthKeys.add(keyOf(synthElement));
      }
      outputElements.push(element);
    }
    parsedResponse.elements = outputElements;
  }

  vocabPostParseLint(parsedResponse: ParsedResponse): void {
    const elements = parsedResponse.elements;
    const vocabs = elements.filter(el => el.kind === 'VOCAB');
    const referenceElements = this.referenceElements;
    const sentences = referenceElements.filter(el => el.kind === 'SENTENCE');
    const refToSentence: { [index in number]: ScriptElement } = {};
    for (const sentence of sentences) {
      refToSentence[sentence.reference] = sentence;
    }
    const alerts = lintVocab(vocabs, refToSentence);
    if (alerts) {
      parsedResponse.alerts.push(...alerts);
    }
  }

  // add new break elements if needed by parsed responses
  postProcessParsed(parsedResponse: ParsedResponse, timestamp: number) {
    this.postParseSynthesize(parsedResponse, timestamp);
    if (this.postParseLint) {
      this.postParseLint(parsedResponse);
    }
  }

  parseExchangeResponse(exchange: Exchange): ParsedResponse {
    if (exchange.request.llmOptions.parsingDisabled) {
      return {
        elements: [],
        alerts: [],
      };
    } else {
      try {
        const parserFn = this.resolveParserFn(exchange);
        const parsed = parserFn(exchange.rawResponse.text, exchange.timestamp);
        this.postProcessParsed(parsed, exchange.timestamp);
        return parsed;
      } catch (e) {
        bugsnagNotify(e as Error);
        return {
          elements: [],
          alerts: [],
          errors: [String(e)],
        };
      }
    }
  }

  resolveParserFn(exchange: Exchange): ResponseParserFn {
    const parserKey = exchange.request.parserKey || 'standard';
    let result: ResponseParserFn;
    switch (this.task) {
      case 'translation':
        result = translationResponseParsers[parserKey];
        break;
      case 'structural':
        result = structuralResponseParsers[parserKey];
        break;
      case 'vocab':
        result = vocabResponseParsers[parserKey];
        break;
      default:
        throw Error(`resolveParserFn - unexpected task: ${this.task}`);
    }
    if (!result) {
      throw Error(`resolveParserFn - unexpected parserKey: ${parserKey}`);
    }
    return result;
  }

  get unitId(): string {
    return this.projectMetadata?.unitId;
  }

  // soft-delete entire project
  async archive(bool: boolean = true) {
    log.info('archive');
    await mergeProjectData(this.projectMetadata, {
      archived: bool,
      state: this.state,
    });
  }

  async updateProjectName(name: string) {
    log.info('updateProjectName');
    await mergeProjectData(this.projectMetadata, {
      name,
    });
  }

  async markCompleted() {
    log.info('mark completed');
    await mergeProjectData(this.projectMetadata, {
      completedTimestamp: epochSeconds(),
      state: 'completed',
    });
  }

  get archived(): boolean {
    return this.projectMetadata.archived;
  }

  // drives what's displayed in the main thread/body area
  get visibleThread(): Step[] {
    // should never be possible. (we've dropped the "show all exchanges" view for now)
    if (!this.currentStepId) {
      return this.steps;
    }

    switch (this.currentKind) {
      case 'REFERENCE_SCRIPT':
        return [this.referenceScript];
      case 'EXCHANGE':
        return this.currentReverseExchanges;
      default:
        log.error(`unexpected currentKind: ${this.currentKind}`);
        return []; // never expected
    }
  }

  @computed
  get stepsBySection(): [Step[][], Step[]] {
    const exchanges = this.sidebarExchanges;
    const sectionIntervals = this.sectionIntervals;
    return exchangesByIntervals(exchanges, sectionIntervals);
  }

  @computed
  get sectionsInfo(): SectionsInfo {
    let [bySection, noFit] = this.stepsBySection;
    // const hasSectionedResponses = bySection.filter(s => s.length).length > 0;
    const hasSections = bySection.length > 1;

    const missingCounts = this.outputIncompleteCountsBySection;
    const conflictCounts = this.mergeConflictsCountsBySection;
    const samosaFlaggedCounts = this.samosaFlaggedCountsBySection;
    const nonSignoffCounts = this.nonSignoffCountsBySection;

    const sectionInfos = bySection.map((steps, index) => {
      const sectionInfo: SectionInfo = {
        // title: `---chapter ${index + 1}---`,
        title: `${this.sectionLabel} ${index + 1}`,
        steps,
        missingCount: missingCounts[index],
        conflictCount: conflictCounts[index],
        completeButNotSignedOffCount: nonSignoffCounts[index],
        samosaFlaggedCount: samosaFlaggedCounts[index],
      };
      return sectionInfo;
    });
    return {
      // hasSectionedResponses,
      hasSections,
      nonFitting: noFit,
      sections: sectionInfos,
    };
  }

  // context basis for new exchanges
  isCurrentStep(step: Step): boolean {
    return step && step.id === this.currentStepId;
  }

  @computed
  get currentStep() {
    if (!this.currentStepId) {
      return null;
    }
    return this.stepMap.get(this.currentStepId);
  }

  @computed
  get steps(): Step[] {
    const list = [...this.stepMap.values()];
    return sortOnTimestamp(list);
  }

  @computed
  get timestampToStep(): Map<number, Step> {
    const result = new Map<number, Step>();
    for (const step of this.steps) {
      result.set(step.timestamp, step);
    }
    return result;
  }

  exchangeForElement(el: ScriptElement): Exchange {
    const step = this.timestampToStep.get(el.timestamp);
    if (step?.kind === 'EXCHANGE') {
      return step;
    }
    return null;
  }

  // default selection upon project load
  get latestStep(): Step {
    const result = this.steps[this.steps.length - 1];
    if (!result) {
      throw Error('unable to resolve latest step');
    }
    return result;
  }

  get currentKind() {
    return this.currentStep?.kind;
  }

  get exchangeSelected(): boolean {
    return !!this.currentExchange;
  }

  @computed
  get currentExchange(): Exchange {
    const currentStep = this.currentStep;
    if (currentStep?.kind === 'EXCHANGE') {
      return currentStep;
    }
    return null;
  }

  get currentExchangeId(): string {
    return this.currentExchange?.id;
  }

  @computed
  get currentReverseThread(): Step[] {
    return computeReverseThread(this.currentStep, this.stepMap);
  }

  // context to be sent along with next llm message
  @computed
  get currentReverseExchanges(): Exchange[] {
    return filterOnKind(this.currentReverseThread, 'EXCHANGE');
  }

  reverseExchangesFor(step: Step): Exchange[] {
    const reverseThread = computeReverseThread(step, this.stepMap);
    const result = filterOnKind(reverseThread, 'EXCHANGE');
    return result;
  }

  isTerminalStep(step: Step): boolean {
    return isTerminalStep(step, this.stepsWithPriorId);
  }

  isSingleStepThread(step: Step): boolean {
    return (
      this.isTerminalStep(step) && this.reverseExchangesFor(step).length === 1
    );
  }

  // TODO ref equality
  @computed
  get stepsWithPriorId(): StepListLookup {
    return createIndexOnPriorId(this.steps);
  }

  @computed
  get currentForwardThread(): Step[] {
    if (!this.currentExchange) {
      return [];
    }
    return computeForwardThread(this.currentStep, this.stepsWithPriorId);
  }

  @computed
  get currentForwardForks(): Step[] {
    if (!this.currentStep) {
      return [];
    }
    const forwardThread = this.currentForwardThread;
    const terminal = forwardThread.length
      ? forwardThread.at(-1)
      : this.currentStep;
    return this.stepsWithPriorId[terminal.id];
  }

  threadStatus(step: Step): ThreadStatus {
    if (this.isCurrentStep(step)) {
      return 'current';
    }
    if (this.currentReverseThread.includes(step)) {
      return 'prior';
    }
    if (this.currentForwardThread.includes(step)) {
      return 'forward';
    }
    if (this.currentForwardForks.includes(step)) {
      return 'fork';
    }
    return '';
  }

  @computed
  get exchanges(): Exchange[] {
    return filterOnKind(this.steps, 'EXCHANGE');
  }

  @computed
  get sidebarExchanges(): Exchange[] {
    if (this.sidebarMode === 'thread') {
      return this.exchanges.filter(ex => this.isTerminalStep(ex));
    } else {
      return this.exchanges;
    }
  }

  @computed
  get activeExchanges(): Exchange[] {
    const exchanges = filterOnKind(this.steps, 'EXCHANGE') as Exchange[];
    return exchanges.filter(
      ex => !getSuppressedFlag(this.exchangeFlags[ex.id])
    );
  }

  @computed
  get activeResponses(): ParsedResponse[] {
    return this.activeExchanges.map(ex => ex.parsedResponse);
  }

  get lastPromptText(): string {
    const last = this.exchanges.slice(-1)[0];
    return last?.request?.promptText;
  }

  @computed
  get addedElements(): ScriptElement[] {
    return [
      ...(Object.values(this.addedElementsLookup).filter(
        el => el
      ) as ScriptElement[]),
    ];
  }

  collectLintAlerts(exchanges: Exchange[]): LintAlert[] {
    if (this.task === 'vocab') {
      let result: LintAlert[] = [];
      const responses = exchanges.map(ex => ex.parsedResponse);
      for (const response of responses) {
        if (response.alerts) {
          result.push(...response.alerts);
        }
      }
      // TODO unique on something?
      return result;
    }
    return [];
  }

  buildBaseMergeScript(
    exchanges: Exchange[],
    options: BuildMergeScriptOptions = {}
  ): ScriptElement[] {
    // TODO tidy initialize
    const preserveOverridenInOutput = options.preserveOverridenInOutput;
    let range = options.range;
    const computeRangeFromElements = options.computeRangeFromElements;
    const mergeAllGlobalReferences = options.mergeAllGlobalReferences;
    const responses = exchanges.map(ex => ex.parsedResponse);
    const timestamps = exchanges.map(ex => ex.timestamp);
    const responseElements = scriptElementsFromParsedResponses(responses);
    const sorted = sortScriptElements(responseElements);
    const rawParsedElements = filterUniqueHashes(sorted);
    const processedParsedElements: ScriptElement[] = [];
    for (const el of rawParsedElements) {
      const edit = this.editLookup[el.id];
      if (edit && timestamps.includes(edit.timestamp)) {
        if (preserveOverridenInOutput) {
          processedParsedElements.push(el);
        }
        processedParsedElements.push(edit);
      } else {
        processedParsedElements.push(el);
      }
    }
    const filterOut = ['UNRECOGNIZED', ...this.referenceKinds];
    const parsedElements = processedParsedElements.filter(
      el => !filterOut.includes(el.kind)
    );
    let generatedElements = parsedElements.slice();
    const addedElements = this.addedElements;
    // TODO optimize for all exchanges case (timestamps always includes timestamp of added)
    // TODO handle added in global scope case - timestamp is zero etc
    // TODO think method of suppress added elements,
    //    note suppressed elements are not processed at this level
    if (mergeAllGlobalReferences) {
      // TODO reason about if this is the correct approach and flag to handle global scope added elements
      generatedElements = generatedElements.concat(addedElements);
    } else {
      for (const el of addedElements) {
        if (timestamps.includes(el.timestamp)) {
          generatedElements.push(el);
        }
      }
    }
    if (computeRangeFromElements) {
      const refs = generatedElements.map((el: ScriptElement) => el.reference);
      range = rangeFromNumbers(refs);
    }
    let referenceElements: ScriptElement[] = null;
    const refsUsed = exchanges
      .map(ex => ex.referenceElements) // beware, exchange scoped structural merge view doesn't honor L0
      .filter(ref => ref);
    if (mergeAllGlobalReferences || refsUsed.length === 0) {
      // referenceElements = this.referenceScript.elements;
      referenceElements = this.referenceElements; // honor L0 (always use english for structural work)
    } else {
      referenceElements = [].concat(...refsUsed);
      referenceElements = filterUniqueHashes(
        sortScriptElements(referenceElements)
      );
    }
    // for now, force exclude any imported structural content from structural project merge view
    if (this.task === 'structural') {
      referenceElements = referenceElements.filter(
        el =>
          ![
            'CHAPTER',
            'PASSAGE',
            'CHAPTER_SUMMARY',
            'PASSAGE_SUMMARY',
          ].includes(el.kind)
      );
    }
    // force include translations into vocab merge view regardless of inclusion in LLM script
    if (
      this.task === 'vocab' &&
      !this.scriptInjectParams.isScriptOptionEnabled('translations')
    ) {
      const rawReferenceElements = this.referenceScript.elements;
      const translationElements = rawReferenceElements.filter(
        el => el.kind === 'TRANSLATION'
      );
      referenceElements.push(...translationElements);
    }
    let mergeScript = [].concat(referenceElements, generatedElements);
    if (range) {
      mergeScript = filterScriptElementsByRange(mergeScript, range);
    }
    mergeScript = sortScriptElements(mergeScript);
    return mergeScript;
  }

  @computed
  get baseMergeScript(): ScriptElement[] {
    // TODO consider optimal refactor
    // const responses = this.activeResponses;
    // const responseElements = scriptElementsFromParsedResponses(responses);
    // return this.buildRawMergeScript(responseElements);
    return this.buildBaseMergeScript(this.activeExchanges, {
      mergeAllGlobalReferences: true,
      mergeAddedElements: true,
    });
  }

  filterIsOutputElement(elements: ScriptElement[]): ScriptElement[] {
    return elements.filter(el => !el.id.includes(':'));
  }

  @computed
  get outputKindsMergeScript() {
    return this.filterIsOutputElement(this.baseMergeScript);
  }

  async stripReferenceStructural(kind: 'CHAPTER' | 'PASSAGE') {
    // TODO consider best approach
    const filtered = this.referenceScript.elements.filter(
      el => el.kind !== kind
    );
    await mergeProjectData(this.referenceScript, {
      elements: filtered,
    });
  }

  @computed
  get outputMergeScript(): ScriptElement[] {
    // TODO consider optimal refactor
    const output = this.outputKindsMergeScript;
    const flags = this.elementFlags;
    return output.filter(el => !getSuppressedFlag(flags[el.id]));
  }

  @computed
  get mergeReadinessStats() {
    const conflict = this.mergeConflictCount;
    const missing = this.outputIncompleteReferenceCount;
    const nonSignoff = this.nonSignoffCount;
    const incomplete = conflict + missing + nonSignoff;
    const flagged = this.samosaFlaggedCount;
    const ready = conflict === 0 && missing === 0 && flagged === 0;
    return { incomplete, conflict, missing, flagged, ready };
  }

  @computed
  get mergeConflicts(): number[] {
    const mergeScript = this.outputMergeScript;
    return translationTaskComputeReferenceConflicts(mergeScript);
  }

  @computed
  get mergeConflictCount(): number {
    return this.mergeConflicts.length;
  }

  isSectionHead(ref: number): boolean {
    // TODO reconsider
    if (!this.referenceScript.sectionBoundaries) {
      log.error('section boundary data missing');
      return false;
    }
    return this.referenceScript.sectionBoundaries.includes(ref);
  }

  ensureInitialSectionHead() {
    const firstSentence = this.referenceScript.elements.find(
      el => el.kind === 'SENTENCE'
    );
    if (firstSentence) {
      const ref = firstSentence.reference;
      if (!this.isSectionHead(ref)) {
        log.info('assigning initial section head');
        this.toggleSectionHead(ref).catch(alertError);
      }
    }
  }

  async toggleSectionHead(ref: number) {
    // TODO reconsider
    const sectionBoundaries = [...this.referenceScript.sectionBoundaries];
    insertOrRemoveOnSortedArray(sectionBoundaries, ref);
    const sectionRanges = boundariesToRanges(sectionBoundaries);
    await mergeProjectData(this.referenceScript, {
      sectionBoundaries,
      overriddenSections: true,
      sectionRanges,
    });
  }

  get sectionLabel(): string {
    // TODO reconsider
    return this.referenceScript.overriddenSections ? 'Section' : 'Chapter';
  }

  // TODO ref equality
  @computed
  get sectionIntervals(): Intervals {
    const sectionRanges = this.referenceScript.sectionRanges;
    return fromIntervals(sectionRanges);
  }

  @computed
  get sectionCount(): number {
    // @jason, which is the preferable implemention?
    // return this.sectionIntervals?.startPoints?.length || 0;
    return this.referenceScript.sectionRanges?.length || 0;
  }

  @computed
  get hasSections(): boolean {
    return this.sectionCount > 1;
  }

  @computed
  get mergeConflictsCountsBySection(): number[] {
    if (!this.mergeCanHaveConflicts) {
      return [];
    }
    const conflicts = this.mergeConflicts;
    const sectionIntervals = this.sectionIntervals;
    return countsPerInterval(conflicts, sectionIntervals);
  }

  @computed
  get outputReferences(): Set<number> {
    return new Set(this.outputMergeScript.map(el => el.reference));
  }

  // TODO ref equality
  @computed
  get outputIncompleteReferencesSet(): Set<number> {
    const needed = numberSetFromRanges(this.referenceScript.referenceRanges);
    const have = this.outputReferences;
    const missing = minusSet(needed, have);
    return missing;
  }

  // TODO ref equality
  @computed
  get outputIncompleteReferences(): number[] {
    return [...this.outputIncompleteReferencesSet.values()];
  }

  @computed
  get outputIncompleteReferenceCount(): number {
    return this.outputIncompleteReferences.length;
  }

  @computed
  get outputIncompleteReferenceRanges(): Range[] {
    return rangesFromNumberSet(this.outputIncompleteReferencesSet);
  }

  @computed
  get outputIncompleteCountsBySection(): number[] {
    if (!this.mergeCanHaveMissing) {
      return [];
    }
    const missings = this.outputIncompleteReferences;
    const sectionIntervals = this.sectionIntervals;
    return countsPerInterval(missings, sectionIntervals);
  }

  @computed
  get nonSignoffReferences(): number[] {
    const conflicts = new Set(this.mergeConflicts);
    const refs = this.outputReferences;
    const result: number[] = [];
    for (const ref of refs) {
      if (conflicts.has(ref)) {
        continue;
      }
      const signoff = this.signoffs[ref];
      if (!signoff) {
        result.push(ref);
        continue;
      }
      const signoffElementId = signoff.elementId;
      const flags = this.elementFlags[signoffElementId];
      if (getSuppressedFlag(flags)) {
        result.push(ref);
      }
    }
    return result;
  }

  @computed
  get nonSignoffCount(): number {
    return this.nonSignoffReferences.length;
  }

  @computed
  get nonSignoffCountsBySection(): number[] {
    const nonSignoffReferences = this.nonSignoffReferences;
    const sectionIntervals = this.sectionIntervals;
    return countsPerInterval(nonSignoffReferences, sectionIntervals);
  }

  filterToActiveAlerts(alerts: LintAlert[]) {
    return filterToActiveAlerts(
      alerts,
      this.suppressedAlerts,
      this.elementFlags
    );
  }

  responseActiveAlerts(parseResponse: ParsedResponse) {
    return this.filterToActiveAlerts(parseResponse.alerts);
  }

  // async editResponse() {
  //   // TODO not currently used, consolidate with current approach
  //   const exchange = this.currentExchange;
  //   const textEditor: ModalTextEditor = null; // TODO
  //   this.modal = textEditor;
  //   const save = await textEditor.run(exchange.rawResponse.text);
  //   this.modal = null;
  //   if (save) {
  //     const parsedResponse = this.parseResponse(
  //       textEditor.text,
  //       exchange.timestamp
  //     );
  //     await mergeProjectData(exchange, {
  //       parsedResponse,
  //     });
  //   }
  // }

  async touchupStructuralMergeScript() {
    await runStructuralMergeModal();
  }

  generateTouchupEditorOptions(
    options: Partial<TouchupEditorOptions>
  ): TouchupEditorOptions {
    const defaults: TouchupEditorOptions = {
      elementCategory: this.elementCategory,
      elementIsOutput: this.elementIsOutput,
      elementIsReference: this.elementIsReference,
      elementIsDecorator: this.elementIsDecorator,
      elementIsComparison: this.elementIsComparison,
      computeElementKeys: this.computeElementKeys,
      synthElementFunc: this.synthElementFunction,
      task: this.task,
    };
    return { ...defaults, ...options };
  }

  async runTouchupEditorModal(editorModal: TouchUpEditorModal) {
    this.mergeModal = editorModal;
    this.setupAutoSaveForEditorModal(editorModal);
    const confirm = await runTranslationMergeModal(editorModal);
    this.mergeModal = null;
    editorModal.dispose();
    this.player?.pause();
    if (!editorModal.dirty) {
      return;
    }
    await this.persistTouchupEditorModal(editorModal);
    editorModal.dispose();
  }

  async runColumnMergeDialog(editorModal: TouchUpEditorModal) {
    // return openModal(MergeScriptModalDialog, modal);
    this.mergeModal = editorModal;
    this.setupAutoSaveForEditorModal(editorModal);
    await runColumnMergeDialog(editorModal);
    this.mergeModal = null;
    editorModal.dispose();
    this.player?.pause();
    if (!editorModal.dirty) {
      return;
    }
    await this.persistTouchupEditorModal(editorModal);
  }

  setupAutoSaveForEditorModal(editorModal: TouchUpEditorModal) {
    const timerId = setInterval(() => {
      if (editorModal !== this.mergeModal) {
        clearInterval(timerId);
        return;
      }
      if (editorModal.lastSaveTime < editorModal.lastUpdateTime) {
        this.persistTouchupEditorModal(editorModal).catch(alertError);
      }
    }, 5000);
  }

  async persistTouchupEditorModal(editorModal: TouchUpEditorModal) {
    const newElementFlags = editorModal.elementFlags;
    editorModal.lastSaveTime = epochSecondsFloat();
    mergeElementFlags(this.docIds.elementFlagsDocId, newElementFlags);
    const newEdits = editorModal.edits;
    let [updateEdits, updateAdded] = reallocateOverridesToAdded(
      newEdits,
      this.addedElementsLookup
    );
    const newAddedElements = editorModal.addedElements;
    updateAdded = { ...newAddedElements, ...updateAdded };
    mergeElementEdits(this.docIds.editsDocId, updateEdits);
    mergeAddedElements(this.docIds.addedElementsDocId, updateAdded);
    const newNotes = editorModal.notes;
    mergeNotes(this.docIds.notesDocId, newNotes);
    const newSignoffs = editorModal.signoffs;
    mergeSignoffs(this.docIds.signoffsDocId, newSignoffs);
    const newSuppressedAlerts = editorModal.alertSuppressions;
    mergeAlertSuppressions(
      this.docIds.suppressedAlertsDocId,
      newSuppressedAlerts
    );
  }

  async touchupExchangeResponse(exchange: Exchange) {
    const editorModal = new TouchUpEditorModal(
      this.buildBaseMergeScript([exchange], { computeRangeFromElements: true }),
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts([exchange]),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: false,
        canHaveMissingReferences: false,
        requireSignoff: false,
        editIsAdd: true,
      })
    );

    await this.runTouchupEditorModal(editorModal);
  }

  async touchupTranslationsMergeScript(sectionNumber: number) {
    // TODO consider optimal refactor
    let range = null;
    if (sectionNumber) {
      range = this.referenceScript.sectionRanges[sectionNumber - 1];
    }

    // return openMergeScriptModal();
    const editorModal = new TouchUpEditorModal(
      this.buildBaseMergeScript(this.activeExchanges, {
        range,
        mergeAllGlobalReferences: true,
      }),
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts(this.activeExchanges),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: true,
        canHaveMissingReferences: true,
        requireSignoff: true,
        editIsAdd: true,
        enableMerge: !sectionNumber,
      })
    );

    await this.runTouchupEditorModal(editorModal);
  }

  async touchupVocabMergeScript(sectionNumber: number) {
    // TODO consider optimal refactor
    let range = null;
    if (sectionNumber) {
      range = this.referenceScript.sectionRanges[sectionNumber - 1];
    }

    // return openMergeScriptModal();
    const editorModal = new TouchUpEditorModal(
      this.buildBaseMergeScript(this.activeExchanges, {
        range,
        mergeAllGlobalReferences: true,
      }),
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts(this.activeExchanges),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: false,
        canHaveMissingReferences: false,
        canHaveLintAlerts: true,
        requireSignoff: false,
        editIsAdd: false,
        enableMerge: !sectionNumber,
        disablePick: true,
      })
    );

    await this.runTouchupEditorModal(editorModal);
  }

  // for now this is just used for structural mode projects
  async openColumnOldMergeDialog() {
    const modal = new TouchUpEditorModal(
      this.baseMergeScript,
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts(this.activeExchanges),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: false,
        canHaveMissingReferences: false,
        requireSignoff: true,
        editIsAdd: true,
      })
    );

    // return openModal(MergeScriptModalDialog, modal);
    await runColumnMergeDialog(modal);
  }

  async openStructuralMergeDialog() {
    const modal = new TouchUpEditorModal(
      this.baseMergeScript,
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts(this.activeExchanges),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: true,
        canHaveMissingReferences: true,
        requireSignoff: true,
        editIsAdd: true,
        enableMerge: true,
      })
    );
    this.runTouchupEditorModal(modal);
    // this.runColumnMergeDialog(modal);
  }

  async touchupThread(exchange: Exchange) {
    // TODO consider optimal refactor
    const activeExchanges = this.reverseExchangesFor(exchange).filter(
      ex => !getSuppressedFlag(this.exchangeFlags[ex.id])
    );

    const editorModal = new TouchUpEditorModal(
      this.buildBaseMergeScript(activeExchanges, {
        computeRangeFromElements: true,
      }),
      this.elementFlags,
      this.notes,
      this.signoffs,
      this.collectLintAlerts(this.activeExchanges),
      this.suppressedAlerts,
      this.referenceScript.timesLookup,
      this.player,
      this.generateTouchupEditorOptions({
        canHaveConflicts: true,
        canHaveMissingReferences: true,
        requireSignoff: true,
        editIsAdd: true,
      })
    );

    await this.runTouchupEditorModal(editorModal);
  }

  async touchupMergeScript(sectionNumber: number = 0) {
    // TODO consider alternatives to all these conditionals
    switch (this.task) {
      case 'translation':
        return this.touchupTranslationsMergeScript(sectionNumber);
      case 'structural':
        // return this.touchupStructuralMergeScript();
        // return this.openColumnMergeDialog();
        return this.openStructuralMergeDialog();
      case 'vocab':
        return this.touchupVocabMergeScript(sectionNumber);
    }
  }

  // async openDefaultMergeView() {
  //   // if (this.projectMetadata.twoColumnMergeEnabled) {
  //   //   this.openColumnMergeDialog();
  //   // } else {
  //   this.touchupMergeScript();
  //   // }
  // }

  // async manageSections() {
  //   const modal = new SectionManagerModal(this);
  //   await modal.run();
  //   if (!modal.save) {
  //     return;
  //   }
  // }

  async toggleSuppressExchange(exchange: Exchange) {
    this.setSuppressExchange(exchange, !this.isExchangeSuppressed(exchange));
  }

  asyncSetSuppressExchange(exchange: Exchange, value: boolean) {
    this.setSuppressExchange(exchange, value).catch(alertError);
  }

  async setSuppressExchange(exchange: Exchange, value: boolean) {
    log.info(`setSuppressExchange(${exchange.id}, ${String(value)})`);
    await setSuppressExchange(
      this.docIds.exchangeFlagsDocId,
      this.exchangeFlags,
      exchange.id,
      value
    );
  }

  async toggleSuppressThread(exchange: Exchange) {
    this.setSuppressThread(exchange, !this.isThreadSuppressed(exchange));
  }

  asyncSetSuppressThread(exchange: Exchange, value: boolean) {
    this.setSuppressThread(exchange, value).catch(alertError);
  }
  async setSuppressThread(exchange: Exchange, value: boolean) {
    for (const ex of this.reverseExchangesFor(exchange)) {
      await this.setSuppressExchange(ex, value);
    }
  }

  isExchangeSuppressed(exchange: Exchange): boolean {
    return getSuppressedFlag(this.exchangeFlags[exchange.id]);
  }

  setHoveredExchange(exchange?: Exchange | null) {
    // @elliottjf you don't seem to be doing anythiong with this and it's super noisy in the console
    // console.log('hovered exchange', exchange);
  }

  isThreadSuppressed(exchange: Exchange): boolean {
    return this.reverseExchangesFor(exchange).every(ex =>
      this.isExchangeSuppressed(ex)
    );
  }

  // get samosaFlaggedCount(): number {
  //   this.samosaFlags.length;
  // }
  get samosaFlaggedCount() {
    return this.referenceScript.elements.reduce(
      (acc, element) => (this.isSamosaFlagged(element.id) ? acc + 1 : acc),
      0
    );
  }

  isSamosaFlagged(elementId: string): boolean {
    return getSamosaFlag(this.elementFlags[elementId]);
  }

  @computed
  get samosaFlaggedReferenceNumbers(): number[] {
    const result: number[] = [];
    for (const element of this.referenceScript.elements) {
      if (this.isSamosaFlagged(element.id)) {
        result.push(element.reference);
      }
    }
    return result;
  }

  @computed
  get samosaFlaggedCountsBySection(): number[] {
    const flagged = this.samosaFlaggedReferenceNumbers;
    const sectionIntervals = this.sectionIntervals;
    return countsPerInterval(flagged, sectionIntervals);
  }

  enableExperimentalUI() {
    this.showExploratoryUI = true;
    this.showSectionManager = true;
  }

  //
  // exploratory structural prompt generation
  //

  injectChapterInfoPrompt(substitions: { num: string; lineRange: string }) {
    const { num, lineRange } = substitions;
    const promptTemplate = this.findPromptByTitle(chapterInfoPromptTitle);
    if (!promptTemplate) {
      alertError(`unable to find prompt template: "${chapterInfoPromptTitle}"`);
      return;
    }

    const promptText = interpolateString(promptTemplate.text, {
      chapterNum: num,
      lineRange,
    });

    PromptEntryModel.instance.setPromptText(promptText);
    // PromptEntryModel.instance.submitToModel(this, false);
  }

  injectPassageInfoPrompt(substitions: { num: string; lineRange: string }) {
    const { num, lineRange } = substitions;
    const promptTemplate = this.findPromptByTitle(passageInfoPromptTitle);
    if (!promptTemplate) {
      alertError(`unable to find prompt template: "${passageInfoPromptTitle}"`);
      return;
    }

    const promptText = interpolateString(promptTemplate.text, {
      chapterNum: num,
      lineRange,
    });

    PromptEntryModel.instance.setPromptText(promptText);
    // PromptEntryModel.instance.submitToModel(this, false);
  }

  injectTranslateLinePrompt(lineNumber: string) {
    const promptTemplate = this.findPromptByTitle(translateLinePromptTitle);
    if (!promptTemplate) {
      alertError(
        `unable to find prompt template: "${translateLinePromptTitle}"`
      );
      return;
    }

    const promptText = interpolateString(promptTemplate.text, {
      lineNumber,
    });
    PromptEntryModel.instance.setPromptText(promptText);
  }

  // todo: match by slug
  findPromptByTitle(title: string) {
    return this.prompts.find(p => p.title === title);
  }
}

const chapterInfoPromptTitle = 'chapter title and summary by line range';
const passageInfoPromptTitle = 'passage title and summary by line range';
const translateLinePromptTitle = 'translate line';
