//
// shared layout for non-editor views to match layout style of the editor view

import * as React from 'react';
import { SimpleStatusBar } from './simple-status-bar';
import { isFunction } from 'lodash';
import cx from 'classnames';
import { useTitle } from '../lib/hooks/use-title';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import '../../index.css';
import './console.css';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: var(--console-status-bar-height) 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  min-height: 100vh;

  .top {
    grid-area: 1 / 1 / 2 / 13;
  }
  .left {
    padding: 12px;
    background-color: #f9f9f9;
    grid-area: 2 / 1 / 3 / 13;
  }

  &.--has-rightpanel {
    .left {
      padding-right: 24px;
      grid-area: 2 / 1 / 3 / 6;
    }
  }

  .right {
    padding: 12px;
    grid-area: 2 / 6 / 3 / 13;
  }
`;

interface Props {
  title: string;
  children?: React.ReactNode;
  rightPanel?: React.ReactNode;
}
export const ConsoleLayout = ({ title, children, rightPanel }: Props) => {
  const hasRightPanel = !!rightPanel;
  useTitle(title);
  return (
    <Wrapper className={cx({ '--has-rightpanel': hasRightPanel })}>
      <div className="top">
        <SimpleStatusBar title={title} />
      </div>
      <div className="left">{children}</div>
      <div className={'right'}>
        {isFunction(rightPanel) ? rightPanel() : rightPanel}
      </div>
    </Wrapper>
  );
};
