import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const markdown = `
# Chaat Cheat Sheet

**Space bar** = toggle play/pause

**Ctrl + Space bar** = play a section

**Right Arrow/Left Arrow** = move forward and backward to next navigation point (you can select different navigation tracks in the UI, currently segments and notches)

**Click Word** = moves audio position to start of word, works in track area and script area

**Click in Waveform area** = move the audio position and if not playing set the audio rewind position

**Return** = if paused rewind to current audio rewind position and play, if playing then pause

**Shift+Return** = play from 1.5 seconds before to the current audio rewind position

**Shift+(Right Arrow/Left Arrow)** = move forward or back by 1.5 seconds

**Alt+(Right Arrow/Left Arrow)** = nudge forward or back by 20 milliseconds

**Q** = section play, will play then pause at the next navigation point

**Alt+Click on word** = set cue insert position, you will see the text to be cued in the very top of the track UI, Shift+Click works in track area and also in the script area

**C** = add or change cue at the current insert position to the current audio position

**Shift+C** = remove cue at the current insert position

**A** = add auto cue (left), will set the cue insert position to the word that is under the current audio position and add or change cue to current audio position shifting the start of the word the audio position is in

**S** = add auto cue (right), will set the cue insert position to the word after the word under the current audio position and add or change cue to current audio position shifting the end of the word the audio position is in

**Up Arrow/Down Arrow** = time stretching

**= or + and -** = UI time scale zooming

**M** = will create audio marker at current audio position, used for Chapter and Passage breaks

**Shift+M** = will delete audio marker if audio position is inside its "handle"

**R** = if there is current selected audio turns it into a non word audio region

**Shift+R** = will delete non word audio region if the audio position is inside it

**L** = if there is current selected audio turns it into a forced linear interpolation audio region

**Shift+L** = will delete forced linear interpolation audio region if the audio position is inside it

**O** = will "signoff" the current sentence, removing the yellow background color, if the timestamping of any words in the sentence are changed by any action the sentence will revert to not signoff state

**Shift+O** = revert sentence to not signoff state

**X**(eXamine audio) = peek forward (plays 1.5 seconds of audio then goes back to start position)

**Shift+X** = peek backward (starts playing 1.5 before current audio position and stops at current position)

**Shift+Click in waveform** part of track area = make audio selection from current audio position to click position

**K** = turn on kerning on nav stops

**N** = toggle nav stops

**Shift+K** = disable kerning on nav stops

**Ctrl+K** = open kerning / nav stop config

**+** = zoom in track view

**-** = zoom out track view

**0** = run timestamping
`;

export const ChaatCheetSheet = () => (
  <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} />
);
