import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import { Avatar } from '../../components/avatar';
import { useVideoGuides } from './use-video-guides';
import { Auth } from '@masala-lib/editorial/db/auth';
import { A } from '@masala-lib/editorial/ui/action-link';
import { VIDEO_GUIDE_GCS_FOLDER } from '@masala-lib/catalog/catalog-types';

interface VideoGuideDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const VideoGuideDetailScreen = observer(
  ({ closeDetail, startEditing }: VideoGuideDetailsParams) => {
    const model = useVideoGuides();
    const auth = Auth.getInstance();

    if (!model) {
      return null;
    }

    return (
      <Page
        title={`${model.name} ${model.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.isAdmin ? (
                <button onClick={startEditing} className="primary-action">
                  Edit Video Guide
                </button>
              ) : null}
              <DeleteActions model={model} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <Card>
              <DataItem label={'Id'} value={model.id} />
              <DataItem label={'Title'} value={model.data.title} />
              <DataItem label={'Slug'} value={model.data.slug} />
              <DataItem
                label={'Landscape Video ID'}
                value={model.data.videoIdLandscape}
              />
              <DataItem
                label={'Landscape Image GCS URL'}
                value={
                  <>
                    <Avatar src={model.data.imageUrlLandscape} size={'192px'} />
                    {' - '}
                    <A href={VIDEO_GUIDE_GCS_FOLDER}>GCS Folder</A>
                  </>
                }
              />

              <DataItem
                label={'Portrait Image GCS URL'}
                value={
                  <Avatar
                    src={model.data.imageUrlPortrait}
                    size={'96px'}
                  ></Avatar>
                }
              />
              {/* <DataItem
                label={'Portrait Video ID'}
                value={model.data.videoIdPortrait}
              /> */}

              <DataItem
                label={'Duration (seconds)'}
                value={model.data.durationSeconds}
              />
              <DataItem
                label={'Position (sorting)'}
                value={model.data.position}
              />
              <DataItem
                label={'Active'}
                value={JSON.stringify(model.data.active)}
              />
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
