import { autorun, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { auth, scriptEditorModel, unit, volume } from '../models/app-root';
import { ConversationView } from './conversation-view';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import { LintWarningItem } from './lint-warning';
import {
  setSpeakerTranslation,
  speakerTranslation,
} from '@masala-lib/catalog/models/volume';
import { SpeakerV5Data } from '@masala-lib/catalog/bogota/bogota-types';
import { SpeakerForm } from '../../console/views/speakers/speaker-form';
import _, { pick } from 'lodash';
import { notEmpty } from '@utils/conditionals';
import { openReplaceSpeakerLabelsDialog } from './replace-speaker-labels-dialog';
import { DataCard } from '../../console/components/data-display/data-card';
import { SpeakerAttr } from '../../console/views/volumes/speakers-table';
import { useSwitch } from '../../console/lib/hooks/use-switch';
import { MDBBtn } from 'mdbreact';
import styled from 'styled-components';
import { InputStyleWrapper } from '../../console/components/forms/input-style-wrapper';
import { EditorStructuralContent } from '@masala-lib/editorial-types';

export const SpeakerSidepanel = observer(
  ({
    element,
    warnings,
    l1locale,
    l2locale,
    onMasterLanguageFork,
  }: {
    element: EditorStructuralContent;
    warnings: LintWarning[];
    l1locale: string;
    l2locale: string;
    onMasterLanguageFork: boolean;
  }) => {
    const speakerElement = element;
    const speakerLabelText = speakerElement.content.text;
    const speaker: SpeakerV5Data = volume.speakerByLabel(speakerLabelText);
    const hasUnmatchedLabel = notEmpty(speakerLabelText) && !speaker;
    console.log(l1locale, l2locale, onMasterLanguageFork);

    const mayUpdate = unit.hasWriteAccess(auth.appUser) && !auth.isReviewer;

    const editSwitch = useSwitch(false);

    const handleRemoveSpeakerData = () => {
      if (
        window.confirm(
          'Discard the speaker data record, including all bio and accent form field data.\nAre you sure?'
        )
      ) {
        scriptEditorModel.removeSpeakerDataForFocus();
      }
    };

    const handleCreateSpeakerWithBio = () => {
      scriptEditorModel.createSpeakerLabelForFocus({ naBio: false });
    };

    const handleCreateSpeakerNoBio = () => {
      scriptEditorModel.createSpeakerLabelForFocus({ naBio: true });
    };

    const handleRename = () => {
      openReplaceSpeakerLabelsDialog(true);
    };

    // copied from form-buttons.tsx
    const ButtonWrapper = styled(InputStyleWrapper)`
      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `;

    return (
      <>
        <div className="right-sidepanel-header">Speaker Editor</div>
        {warnings
          ? warnings.map(warning => <LintWarningItem warning={warning} />)
          : null}
        <div className="speaker-editor">
          {speaker ? (
            <div className="speaker-edit-form" key={element.id}>
              <br />
              {editSwitch.value ? (
                <SpeakerEditor
                  element={element}
                  l1locale={l1locale}
                  l2locale={l2locale}
                  warnings={warnings}
                  onMasterLanguageFork={scriptEditorModel.onMasterLanguageFork}
                  onCancel={editSwitch.off}
                />
              ) : (
                <>
                  <SpeakerDetails speaker={speaker} />
                  {mayUpdate ? (
                    <ButtonWrapper>
                      <div className="buttons">
                        <MDBBtn
                          color="default"
                          size="sm"
                          onClick={editSwitch.on}
                        >
                          Edit speaker data
                        </MDBBtn>
                        {/* // beware, there is some sort of bug which can nuke all speaker data on occasion
                        // when using this 'remove' action */}
                        {/* {auth.isAdmin ? (
                          <MDBBtn
                            color="warning"
                            size="sm"
                            onClick={handleRemoveSpeakerData}
                          >
                            Discard speaker data ...
                          </MDBBtn>
                        ) : null} */}
                      </div>
                    </ButtonWrapper>
                  ) : null}
                </>
              )}
            </div>
          ) : hasUnmatchedLabel ? (
            <div className="speaker-edit-form" key={element.id}>
              <br />
              {mayUpdate ? (
                <>
                  <MDBBtn
                    color="secondary"
                    size="sm"
                    onClick={handleCreateSpeakerWithBio}
                  >
                    Create new speaker record with bio expected
                  </MDBBtn>

                  <br />
                  <MDBBtn
                    color="secondary"
                    size="sm"
                    onClick={handleCreateSpeakerNoBio}
                  >
                    Create new speaker record with `n/a` bio
                  </MDBBtn>
                  <br />
                  <MDBBtn color="secondary" size="sm" onClick={handleRename}>
                    Rename speaker label globally
                  </MDBBtn>
                  <br />
                </>
              ) : null}
            </div>
          ) : null}
          <ConversationView element={element} showChanges />
        </div>
      </>
    );
  }
);

interface Props {
  l1locale: string;
  l2locale: string;
  onMasterLanguageFork: boolean;
  element: EditorStructuralContent;
  warnings: LintWarning[];
  onCancel: () => void;
}

@observer
export class SpeakerEditor extends React.Component<Props> {
  disposers: (() => void)[] = [];

  @observable.ref formData: any = {};

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.disposers.push(autorun(() => this.configureFormData()));
  }

  get speakerElement() {
    return this.props.element;
  }

  get speakerLabelText() {
    return this.speakerElement.content.text;
  }

  get lintWarnings(): LintWarning[] {
    return this.props.warnings;
  }

  get onMasterLanguageFork() {
    return this.props.onMasterLanguageFork;
  }

  @computed
  get speaker(): SpeakerV5Data {
    return volume.speakerByLabel(this.speakerLabelText);
  }

  get hasUnmatchedLabel(): boolean {
    return notEmpty(this.speakerLabelText) && !this.speaker;
  }

  get l1locale() {
    return this.props.l1locale;
  }

  get l2locale() {
    return this.props.l2locale;
  }

  get onCancel() {
    return this.props.onCancel;
  }

  configureFormData() {
    const speaker = this.speaker;
    if (speaker) {
      const formData = pick(speaker, ['label', 'bio', 'accent']) as any;
      formData['label-l1'] = speakerTranslation(
        speaker,
        'label',
        this.l1locale
      );
      formData['bio-l1'] = speakerTranslation(speaker, 'bio', this.l1locale);
      formData['accent-l1'] = speakerTranslation(
        speaker,
        'accent',
        this.l1locale
      );
      this.formData = formData;
    }
  }

  handleSubmit(dto: any) {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    const l2Props = pick(dto, ['label', 'bio', 'accent']);
    const speaker = this.speaker;
    const newData = { ...speaker, ...l2Props };
    // todo: think about how to better factor this
    setSpeakerTranslation(speaker, 'label', this.l1locale, dto['label-l1']);
    setSpeakerTranslation(speaker, 'bio', this.l1locale, dto['bio-l1']);
    setSpeakerTranslation(speaker, 'accent', this.l1locale, dto['accent-l1']);
    volume.updateSpeaker(this.speakerLabelText, newData);
    this.onCancel(); // close edit mode
  }

  render() {
    const element = this.speakerElement;

    return (
      <div className="speaker-edit-form" key={element.id}>
        <SpeakerForm
          dto={this.formData}
          onSubmit={(dto: any) => this.handleSubmit(dto)}
          onCancel={this.onCancel}
          cancelNavPath={''}
          l1Locale={this.l1locale}
          l2Locale={this.l2locale}
        />
      </div>
    );
  }
}

export const SpeakerDetails = observer(
  ({ speaker }: { speaker: SpeakerV5Data }) => {
    const locale = scriptEditorModel.l1locale;
    return (
      <DataCard
        data={{
          label: (
            <SpeakerAttr speaker={speaker} attr={'label'} locale={locale} />
          ),
          bio: <SpeakerAttr speaker={speaker} attr={'bio'} locale={locale} />,
          accent: (
            <SpeakerAttr speaker={speaker} attr={'accent'} locale={locale} />
          ),
        }}
      />
    );
  }
);

// @observer
// export class OldSpeakerEditor extends React.Component<Props> {
//   disposers: (() => void)[] = [];

//   @observable.ref formData: any = {};

//   constructor(props: Props) {
//     super(props);
//     makeObservable(this);
//     this.disposers.push(autorun(() => this.configureFormData()));
//   }

//   get speakerElement() {
//     return this.props.element;
//   }

//   get speakerLabelText() {
//     return this.speakerElement.content.text;
//   }

//   get lintWarnings(): LintWarning[] {
//     return this.props.warnings;
//   }

//   get onMasterLanguageFork() {
//     return this.props.onMasterLanguageFork;
//   }

//   @computed
//   get speaker(): SpeakerV5Data {
//     return volume.speakerByLabel(this.speakerLabelText);
//   }

//   get hasUnmatchedLabel(): boolean {
//     return notEmpty(this.speakerLabelText) && !this.speaker;
//   }

//   get l1locale() {
//     return this.props.l1locale;
//   }

//   get l2locale() {
//     return this.props.l2locale;
//   }

//   configureFormData() {
//     const speaker = this.speaker;
//     if (speaker) {
//       const formData = pick(speaker, ['label', 'bio', 'accent']) as any;
//       formData['label-l1'] = speakerTranslation(
//         speaker,
//         'label',
//         this.l1locale
//       );
//       formData['bio-l1'] = speakerTranslation(speaker, 'bio', this.l1locale);
//       formData['accent-l1'] = speakerTranslation(
//         speaker,
//         'accent',
//         this.l1locale
//       );
//       this.formData = formData;
//     }
//   }

//   handleSubmit(dto: any) {
//     console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
//     const l2Props = pick(dto, ['label', 'bio', 'accent']);
//     const speaker = this.speaker;
//     const newData = { ...speaker, ...l2Props };
//     // todo: think about how to better factor this
//     setSpeakerTranslation(speaker, 'label', this.l1locale, dto['label-l1']);
//     setSpeakerTranslation(speaker, 'bio', this.l1locale, dto['bio-l1']);
//     setSpeakerTranslation(speaker, 'accent', this.l1locale, dto['accent-l1']);
//     volume.updateSpeaker(this.speakerLabelText, newData);
//   }

//   handleRemoveSpeakerData() {
//     if (window.confirm('Are you sure?')) {
//       scriptEditorModel.removeSpeakerDataForFocus();
//     }
//   }

//   handleCreateSpeakerWithBio() {
//     scriptEditorModel.createSpeakerLabelForFocus({ naBio: false });
//   }

//   handleCreateSpeakerNoBio() {
//     scriptEditorModel.createSpeakerLabelForFocus({ naBio: true });
//   }

//   handleRename() {
//     openReplaceSpeakerLabelsDialog(true);
//   }

//   render() {
//     const warnings = this.lintWarnings;
//     const element = this.speakerElement;
//     const speaker = this.speaker;

//     return (
//       <>
//         <div className="right-sidepanel-header">Speaker Editor</div>
//         {warnings
//           ? warnings.map(warning => <LintWarningItem warning={warning} />)
//           : null}
//         <div className="speaker-editor">
//           {speaker ? (
//             <div className="speaker-edit-form" key={element.id}>
//               <SpeakerDetails speaker={speaker} />
//               <SpeakerForm
//                 dto={this.formData}
//                 onSubmit={(dto: any) => this.handleSubmit(dto)}
//                 cancelNavPath={''}
//                 l1Locale={this.l1locale}
//                 l2Locale={this.l2locale}
//               />
//               <hr />
//               <Button
//                 onClick={this.handleRemoveSpeakerData}
//                 label="Remove speaker data"
//               />
//             </div>
//           ) : this.hasUnmatchedLabel ? (
//             <div className="speaker-edit-form" key={element.id}>
//               <br />
//               <Button
//                 onClick={this.handleCreateSpeakerWithBio}
//                 label="Create new speaker record with bio expected"
//               />
//               <br />
//               <Button
//                 onClick={this.handleCreateSpeakerNoBio}
//                 label="Create new speaker record with `n/a` bio"
//               />
//               <br />
//               <Button
//                 onClick={this.handleRename}
//                 label="Rename speaker label globally"
//               />
//               <br />
//             </div>
//           ) : (
//             `unmatchedLabel: ${JSON.stringify(this.hasUnmatchedLabel)}`
//           )}
//           <ConversationView element={element} showChanges />
//         </div>
//       </>
//     );
//   }
// }
