import {
  castElements,
  ElementId,
  ElementList,
  EmptyElementList,
  IElement,
  KTOfELT,
} from '@tikka/basic-types';
import { Chapter, Element, Structural, Word } from '../ingestion-aliases';
import { EKind } from '../../element-kinds';
import { loadChaatEpisodeData } from '../../editorial/db/loader-funcs';
import { EpisodeDataBase } from '../../editorial/episode-data/episode-data';
import { sortElements } from '../../element-sort';
import { Unit } from '../models/unit';
import { Intervals } from '@tikka/intervals/intervals';
// import { MetadataFlavor } from '../catalog-types';
import { BreakWordMap } from '../../editorial-types-aside';
import { ElementIdToTranslation } from '../../editorial-types';
import { adjustTimesAndAddresses, FatElement } from '../ingestion-adjustments';
import { CreateElementList } from '@tikka/elements/element-list';

export class UnitCaliDataLoader {
  unit: Unit;
  episodeData: EpisodeDataBase;

  // flavor: MetadataFlavor;
  // content elements
  elements: ElementList<Element> = EmptyElementList;

  chapterSpansEL: ElementList<Chapter>;

  // map from word indexes to an associated just prior audioMarker
  breakWordMap: BreakWordMap;

  // beware, this isn't guaranteed to include the sentence heads yet
  navStopWordIds: ElementId[];

  translationSet: ElementIdToTranslation;
  audioUrls: any = {};

  static async create(unit: Unit) {
    const result = new UnitCaliDataLoader(unit);
    await result.init();
    return result;
  }

  constructor(unit: Unit) {
    this.unit = unit;
    // this.locale = locale;
  }

  get episodeKey(): string {
    return this.unit.episodeKey;
  }

  get words(): ElementList<Word> {
    return this.elements.words;
  }

  async init() {
    const episodeData: EpisodeDataBase = await loadChaatEpisodeData(
      this.episodeKey
    );

    this.episodeData = episodeData;

    this.audioUrls = episodeData.audioUrls;

    this.breakWordMap = episodeData.breakWordMap;
    this.elements = this.assembleContentElements(episodeData);
    this.transformStructural();
    this.chapterSpansEL = this.elements.filterByKind(
      EKind.CHAPTER
    ) as unknown as ElementList<Chapter>;

    this.translationSet = episodeData.translations;
    this.navStopWordIds = episodeData.segmentStopWords.values.map(el => el.id);
  }

  assembleContentElements(episodeData: EpisodeDataBase): ElementList<Element> {
    const elements: IElement[] = [];
    elements.push(...episodeData.sentencesWithTimes);
    elements.push(...episodeData.wordGroups);
    const chaatDisplayStructuralKinds: string[] = [
      EKind.CHAPTER, // transformed to span
      EKind.PASSAGE, // left as point element (should also transform?)
      EKind.PARAGRAPH, // transformed to span
      EKind.EXCERPT,
      // point elements
      EKind.CHAPTER_NOTE,
      EKind.CHAPTER_COMPLETE,
    ];
    elements.push(
      ...episodeData.structuralPointAddresses.filter((el: any) =>
        chaatDisplayStructuralKinds.includes(el.kind)
      )
    );
    sortElements(elements);

    const wordElementList = episodeData.wordsWithTimes;

    return CreateElementList({
      elements: castElements<Element>(elements),
      words: wordElementList,
    });
  }

  transformStructural(): void {
    this.elements = this.inferSpeakerLabels();
    const elements = this.elements;
    const words = elements.words.values;

    const elementsOfKind = (kind: KTOfELT<typeof elements>) => {
      return elements.filterByKind(kind).values as FatElement[];
    };

    adjustTimesAndAddresses(elementsOfKind(EKind.CHAPTER), words, {
      expandIntoGaps: true,
      breakWordMap: this.breakWordMap,
      audioEndTime: this.episodeData.audioEndTime,
    });
    // this.elements.transformHeadsToSpans(
    //   EKind.CHAPTER_HEAD,
    //   BasicElementKind.CHAPTER_SPAN,
    //   {
    //     expandIntoGaps: true,
    //     breakWordMap: this.breakWordMap,
    //     audioEndTime: this.episodeData.audioEndTime,
    //   }
    // );
    // this.elements.transformHeadsToSpans(
    //   EKind.PASSAGE_HEAD,
    //   BasicElementKind.PASSAGE_SPAN,
    //   {
    //     expandIntoGaps: true,
    //     breakWordMap: this.breakWordMap,
    //     audioEndTime: this.episodeData.audioEndTime,
    //   }
    // );
    adjustTimesAndAddresses(elementsOfKind(EKind.PARAGRAPH), words, {
      expandIntoGaps: false,
    });
    // this.elements.transformHeadsToSpans(
    //   EKind.PARAGRAPH_HEAD,
    //   BasicElementKind.PARAGRAPH_SPAN,
    //   {
    //     expandIntoGaps: false,
    //   }
    // );
  }

  // add ephemeral blank speaker labels after passage heads
  // (so we get our full coverage of paragraph spans)
  inferSpeakerLabels() {
    const passageEList = this.elements.filterByKind(EKind.PASSAGE);
    const inferredSpeakers: Structural[] = [];
    for (const passage of passageEList.values as Structural[]) {
      const nextSpeaker = this.elements.findNext(
        passage.id,
        el => el.kind === EKind.PARAGRAPH
      ) as Structural;
      if (nextSpeaker?.address !== passage.address) {
        inferredSpeakers.push({
          kind: EKind.PARAGRAPH,
          address: passage.address,
          id: `PARAGRAPH:${passage.id}`,
        } as Structural);
      }
    }
    const newElements: Element[] = [
      ...this.elements.values,
      ...inferredSpeakers,
    ];
    sortElements(newElements);
    return CreateElementList({
      elements: newElements,
      words: this.elements.words,
    });
  }

  buildIntervalsBreakTimes(episodeData: EpisodeDataBase): Intervals {
    const audioBreakTimes: number[] = episodeData.audioMarkers.map(
      marker => marker.time
    );
    return new Intervals(audioBreakTimes);
  }

  // no longer a need to include in the catalog level data
  // // todo: scope to chapter
  // // logic cloned from base-player-model
  // get markCompleteMillis(): number {
  //   const chapterCompletes = this.elements.filterByKind('CHAPTER_COMPLETE');
  //   if (chapterCompletes.values.length > 0) {
  //     const chapterComplete = chapterCompletes.values[0];
  //     const endOfChapterWordAddress = chapterComplete.address;
  //     const result = this.words.timeIntervals.intervalAt(
  //       endOfChapterWordAddress
  //     ).begin;
  //     return result;
  //   }

  //   // defaults to last sentence end time
  //   const sentences = this.elements.filterByKind('SENTENCE');
  //   const sentenceEndTimes = sentences.timeIntervals.endPoints;
  //   const endSpokenTime = sentenceEndTimes[sentenceEndTimes.length - 1];
  //   return endSpokenTime;
  // }
}
