import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { UnitData } from '@masala-lib/catalog/catalog-types';
import { useUnit } from './use-unit';
import { UnitForm } from './unit-form';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { useNavigate } from 'react-router-dom';

interface UnitEditScreenParams {
  stopEditing: () => void;
}

export const UnitEditScreen = observer(
  ({ stopEditing }: UnitEditScreenParams) => {
    const navigate = useNavigate();
    const manager = UnitManager.getInstance();
    const unit = useUnit();

    if (!unit) {
      return null;
    }

    const goBack = () => {
      // @armando, the 'stopEditing' wasn't working on its own
      stopEditing();
      navigate(unit.navPath);
    };

    const onSubmit = (dto: UnitData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);

      unit.fromFormData(dto);
      manager.update(unit).then(goBack);
      consoleAddAuditLogMessage(unit.episodeKey, `updateUnitDetails`);
    };

    return (
      <Page title={unit.name} onClose={goBack}>
        <UnitForm
          dto={unit.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
        />
      </Page>
    );
  }
);
