import classNames from 'classnames';
import * as React from 'react';
import styled from 'styled-components';
import { MenuStyleWrapper, PopoverButton } from '../../components/popover-menu';
import { StateSwitch } from '../../lib/hooks/use-switch';
import { makeQueryPath } from '../../lib/query-stuff';
import { useLocation, useNavigate } from 'react-router-dom';

const Label = styled.div`
  display: flex;
  align-items: center;

  .current-button {
    display: flex;

    i {
      margin-top: -1px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      color: var(--color-gray200);
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
`;

const Wrapper = styled(MenuStyleWrapper)`
  .item {
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    color: var(--color-gray500);

    &.current {
      color: var(--color-blue700);
    }

    &::after {
      position: absolute;
      content: '';
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 1px;
      background: var(--color-gray100);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }

    .arrows {
      margin-left: 24px;

      button {
        padding: 6px 12px;
        background: none;
        border: none;
        color: var(--color-gray300);

        &.selected {
          color: var(--color-blue500);
          background-color: var(--color-blue50);
          border-radius: 4px;
        }

        &:hover {
          color: var(--color-blue500);
          background-color: var(--color-blue20);
        }

        svg {
          margin-top: -4px;
        }
      }

      > button + button {
        margin-left: 6px;
      }
    }
  }
`;

const iconSize = 16;
const sortIcon = (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00001 0.333252L0.700012 7.39992L2.30001 8.59992L5.00001 4.99992L5.00001 20.9999H7.00001L7.00001 4.99992L9.70001 8.59992L11.3 7.39992L6.00001 0.333252Z"
      fill="currentColor"
    />
    <path
      d="M17 16.9999L17 0.999918L15 0.999918L15 16.9999L12.3 13.3999L10.7 14.5999L16 21.6666L21.3 14.5999L19.7 13.3999L17 16.9999Z"
      fill="currentColor"
    />
  </svg>
);

const arrows: { [key: string]: React.ReactElement } = {
  asc: (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0121 19.162L14.8241 17.3397L16.2423 18.75L12.0116 23.0045L7.75684 18.7738L9.16708 17.3557L11.0121 19.1904L10.9976 0.99696L12.9976 0.995361L13.0121 19.162Z"
        fill="currentColor"
      />
    </svg>
  ),
  desc: (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0319 1.01709L7.75732 5.22758L9.16082 6.65243L11.0195 4.82162L10.9642 22.9768L12.9642 22.9828L13.0194 4.86971L14.8175 6.69522L16.2423 5.29172L12.0319 1.01709Z"
        fill="currentColor"
      />
    </svg>
  ),
};

interface SortItem {
  fieldName: string;
  label: string;
}

interface SortControlsProps {
  fields: Array<SortItem>;
  currentSortingFieldName?: string;
  currentSortingOrder?: string;
}

export const SortControls = ({
  fields = [],
  currentSortingFieldName,
  currentSortingOrder,
}: SortControlsProps) => {
  const location = useLocation();
  const currentField = fields.find(
    field => field.fieldName === currentSortingFieldName
  );
  const path = location.pathname;

  return (
    <PopoverButton
      label={
        <Label>
          <span className="current-button">
            <i>{sortIcon}</i>
            {currentField?.label} ({currentSortingOrder})
          </span>
        </Label>
      }
      content={(openSwitch: StateSwitch) => (
        <Wrapper>
          {fields.map(({ fieldName, label }) => {
            const isCurrent = currentSortingFieldName === fieldName;

            return (
              <div className={classNames('item', { current: isCurrent })}>
                <span className="label">{label}</span>
                <span className={'arrows'}>
                  <SortControlItem
                    path={path}
                    fieldName={fieldName}
                    order={'asc'}
                    selected={isCurrent && currentSortingOrder === 'asc'}
                    after={openSwitch.off}
                  />
                  <SortControlItem
                    path={path}
                    fieldName={fieldName}
                    order={'desc'}
                    selected={isCurrent && currentSortingOrder === 'desc'}
                    after={openSwitch.off}
                  />
                </span>
              </div>
            );
          })}
        </Wrapper>
      )}
    />
  );
};

interface SortControlItemProps {
  path: string;
  fieldName: string;
  order: string;
  selected: boolean;
  after: () => void;
}

const SortControlItem = ({
  path,
  fieldName,
  order,
  selected,
  after,
}: SortControlItemProps) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(makeQueryPath(path, { sort: fieldName, order }), {
      replace: true,
    });
    after();
  };
  return (
    <button
      className={classNames({ selected })}
      onClick={handleClick}
      title={`sort by ${fieldName} in ${order} order`}
    >
      {arrows[order]}
    </button>
  );
};
