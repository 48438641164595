import { computed, makeObservable, observable } from 'mobx';
import {
  ElementId,
  ExtractIntervalIndexedET,
  idIsOfKind,
  IElement,
} from '@tikka/basic-types';
import { Unit } from '../../catalog/models/unit';
import {
  getSentenceWordStrings,
  getWordGroupTranscriptText,
} from '../../content-utils';
import {
  Element,
  ElementList,
  Sentence,
  Structural,
} from '../../editor-aliases';
import { getKindFromId } from '@tikka/elements/element-id-utils';
import {
  EKind,
  isStructuralElement,
  StructuralContentKind,
  WordGroupSubKind,
} from '../../element-kinds';
import { isEmpty } from 'lodash';
import { notEmpty } from '@utils/conditionals';
import {
  hasVerbatimDisallowedChars,
  hasOtherDisallowedChars,
  hasLeadingTrailingWhitespace,
} from '../../misc/editorial-string-utils';
import {
  EpisodeDataBase,
  fullWidthCharsLanguage,
} from '../episode-data/episode-data';
import { warningUiDescriptionLookup } from '../chaat/chaat-types';
import {
  EditorStructural,
  EditorWordGroup,
  Translation,
} from '../../editorial-types';

export type LintWarning = {
  key: string;
  elementId: ElementId;
  message: string;
  suppressed?: boolean;
  infoOnly?: boolean;
};

const paragraphBreakKinds = [EKind.PARAGRAPH, EKind.CHAPTER, EKind.PASSAGE];

const MAX_PARAGRAPH_WORD_LEN = 70;
const MAX_SENTENCE_WORD_LEN = 40;

export class Linter {
  episodeData: EpisodeDataBase;
  filterSuppressed: boolean = true;

  @observable.ref unit: Unit = null;

  constructor(episodeData0: EpisodeDataBase) {
    this.episodeData = episodeData0;
    makeObservable(this);
  }

  setUnit(unit: Unit) {
    this.unit = unit;
  }

  @computed({ keepAlive: true })
  get allWarnings(): LintWarning[] {
    if (!this.unit) {
      return [];
    }
    const verbatimHasDisallowedChars = this.verbatimHasDisallowedChars;
    const content = this.episodeData.defaultContent;
    const structural = this.episodeData.structuralElementList;
    const wordGroups = this.episodeData.wordGroupElementList.values;
    const sentences = this.episodeData.sentencesList;
    const words = content.words;
    const locale = this.episodeData.locale;
    const contentLanguage = this.episodeData.contentLanguage;
    const lastWordIndex = content.words.values.length - 1;
    const l2FullWidth = fullWidthCharsLanguage(contentLanguage);
    const l1FullWidth = fullWidthCharsLanguage(locale);
    const charCountWarningKey = (element: Element) => `${element.id}:charCount`;
    const wordCountWarningKey = (element: Element) => `${element.id}:wordCount`;
    const adjacentWarningKey = (element: Element) => `${element.id}:adjacent`;
    const structuralContentReady = this.unit.data?.structuralContentReady;

    const result: LintWarning[] = [];

    const checkCharCount = (
      element: Structural | Translation,
      limit: number,
      checkFilled = false
    ) => {
      const content = element.content;
      const text = typeof content === 'string' ? content : (<any>content).text;
      const charCount = text?.length;
      if (charCount > limit) {
        const warning = {
          key: charCountWarningKey(element),
          elementId: element.id,
          message: `Exceeded char limit ${charCount}/${limit}`,
        };
        result.push(warning);
      }
      if (checkFilled) {
        const trimmedText = text?.trim();
        if (!trimmedText) {
          const warning = {
            key: `${element.id}:emptyContent`,
            elementId: element.id,
            message: 'Element has empty content',
          };
          result.push(warning);
        }
      }
    };

    const checkSpanWordCount = (
      element: ExtractIntervalIndexedET<Structural> | Sentence,
      limit: number
    ) => {
      const wordCount = element.endAddress - element.address;
      if (wordCount > limit) {
        const warning = {
          key: wordCountWarningKey(element),
          elementId: element.id,
          message: `Exceeded word limit for sentence ${wordCount}/${limit}`,
        };
        result.push(warning);
      }
    };

    const elements = content.values;
    for (const [index, element] of elements.entries()) {
      const nextElement =
        index < elements.length - 1 ? elements[index + 1] : null;
      if (isStructuralElement(element)) {
        if (hasOtherDisallowedChars(element.content.text)) {
          const warning = {
            key: `${element.id}:disallowedChars`,
            elementId: element.id,
            message: 'Element has disallowed characters',
          };
          result.push(warning);
        }
        if (hasLeadingTrailingWhitespace(element.content.text)) {
          const warning = {
            key: `${element.id}:disallowedWhitespace`,
            elementId: element.id,
            message: 'Element has leading or trailing whitespace',
          };
          result.push(warning);
        }
      }
      if (element.kind === EKind.PASSAGE) {
        const passageHintCharLimit = l2FullWidth ? 30 : 60;
        checkCharCount(element, passageHintCharLimit, true);
      }
      if (element.kind === EKind.PARAGRAPH) {
        if (
          nextElement &&
          nextElement.kind === EKind.PARAGRAPH &&
          element.address === nextElement.address
        ) {
          const warning = {
            key: adjacentWarningKey(element),
            elementId: element.id,
            message: 'Speaker label adjacent another speaker label',
          };
          result.push(warning);
        }
        if (element.content?.text) {
          if (!this.unit.validateSpeakerLabel(element.content.text)) {
            const warning = {
              key: `${element.id}:speakerMissing`,
              elementId: element.id,
              message: 'Speaker not in cast / voice data',
            };
            result.push(warning);
          } else {
            if (!this.unit.validateSpeakerBio(element.content.text)) {
              const warning = {
                key: `${element.id}:bioMissing`,
                elementId: element.id,
                message: 'Speaker bio missing',
              };
              result.push(warning);
            }
          }
        }
      }
      if (paragraphBreakKinds.includes(element.kind as any)) {
        const breakElement: EditorStructural = element as EditorStructural;
        if (this.unit.data.structuralContentReady) {
          const nextStructuralId = structural.nextId(breakElement.id);
          if (nextStructuralId) {
            let wordsUntilBreak = lastWordIndex - breakElement.address;
            const nextBreak = structural.findNextOfKinds(
              nextStructuralId,
              paragraphBreakKinds
            ) as Structural;
            if (nextBreak) {
              wordsUntilBreak = nextBreak.address - breakElement.address;
            }
            // TODO handle case with long paragraph at end of doc
            if (wordsUntilBreak > MAX_PARAGRAPH_WORD_LEN) {
              const warning = {
                key: wordCountWarningKey(element),
                elementId: element.id,
                message: `Exceeded word limit for paragraph ${wordsUntilBreak}/${MAX_PARAGRAPH_WORD_LEN}`,
              };
              result.push(warning);
            }
          }
        }
      }
      if (element.kind === EKind.CHAPTER) {
        const chapterTitleCharLimit = l2FullWidth ? 15 : 30;
        checkCharCount(element, chapterTitleCharLimit, true);

        const passages = structural.filterByKind(EKind.PASSAGE);
        const containedPassages = passages.getElementsIntersectRangeOf(element);
        if (
          !containedPassages ||
          containedPassages[0].address !== element.address
        ) {
          const warning = {
            key: `${element.id}:missingPassage`,
            elementId: element.id,
            message: 'Chapter missing first passage',
          };
          result.push(warning);
        }
      }
      if (element.kind === EKind.SENTENCE) {
        checkSpanWordCount(element, MAX_SENTENCE_WORD_LEN);
        if (verbatimHasDisallowedChars) {
          const sentenceText = getSentenceWordStrings(
            element,
            content.words
          ).join(' ');
          if (hasVerbatimDisallowedChars(sentenceText)) {
            const warning = {
              key: `${element.id}:disallowedChars`,
              elementId: element.id,
              message: 'Verbatim has disallowed characters',
            };
            result.push(warning);
          }
        }
        if (structuralContentReady) {
          if (
            !nextElement ||
            (nextElement && nextElement.kind !== EKind.TRANSLATION)
          ) {
            const warning = {
              key: `${element.id}:sentenceMissingTranslation`,
              elementId: element.id,
              message: 'Sentence is missing translation',
            };
            result.push(warning);
          }
        }
      }
      if (element.kind === EKind.TRANSLATION) {
        // const contentKind = getKindFromId(element.elementId);
        // if (contentKind === EKind.PASSAGE) {
        if (idIsOfKind(element.elementId, EKind.PASSAGE)) {
          const charLimit = l1FullWidth ? 30 : 60;
          checkCharCount(element, charLimit);
        }
        if (typeof element.content === 'string') {
          if (hasOtherDisallowedChars(element.content)) {
            const warning = {
              key: `${element.id}:disallowedChars`,
              elementId: element.id,
              message: 'Translation has disallowed characters',
            };
            result.push(warning);
          }
          if (hasLeadingTrailingWhitespace(element.content)) {
            const warning = {
              key: `${element.id}:disallowedWhitespace`,
              elementId: element.id,
              message:
                'Translation has disallowed leading or trailing whitespace',
            };
            result.push(warning);
          }
          if (!element.content) {
            const warning = {
              key: `${element.id}:emptyTranslation`,
              elementId: element.id,
              message: 'Translation is empty.',
            };
            result.push(warning);
          }
        }
      }
    }
    const paragraphList = structural.filterByKind(EKind.PARAGRAPH);
    if (paragraphList.notEmpty()) {
      const paragraphElements = paragraphList.values as Structural[];
      const firstParagraphHead = paragraphElements[0];
      if (isEmpty(firstParagraphHead.content.text)) {
        const warning = {
          key: `${firstParagraphHead.id}:missingContent`,
          elementId: firstParagraphHead.id,
          message: 'First paragraph head missing speaker value',
        };
        result.push(warning);
      }
    }

    const usedVocab: Map<string, EditorWordGroup> = new Map();

    for (const wordGroup of wordGroups) {
      if (wordGroup.subKind === WordGroupSubKind.VOCAB) {
        const transcript = getWordGroupTranscriptText(wordGroup, words, true);
        if (usedVocab.has(transcript)) {
          const matchingGroup = usedVocab.get(transcript);
          const sentence = sentences.getElementContainingWordAddress(
            matchingGroup.address
          );
          const sentenceNumber = sentences.getIndex(sentence.id) + 1;
          const warning = {
            key: `${wordGroup.id}:dupVocab`,
            elementId: wordGroup.id,
            message: `Duplicate vocab words, first occurence sentence ${sentenceNumber}`,
          };
          result.push(warning);
        } else {
          usedVocab.set(transcript, wordGroup);
        }
        const canonical = wordGroup.content.canonical;
        if (notEmpty(canonical)) {
          if (hasOtherDisallowedChars(canonical)) {
            const warning = {
              key: `${wordGroup.id}:disallowedChars`,
              elementId: wordGroup.id,
              message: 'Vocab headword has disallowed chars',
            };
            result.push(warning);
          }
          if (usedVocab.has(canonical)) {
            const matchingGroup = usedVocab.get(canonical);
            const sentence = sentences.getElementContainingWordAddress(
              matchingGroup.address
            );
            const sentenceNumber = sentences.getIndex(sentence.id) + 1;
            const warning = {
              key: `${wordGroup.id}:dupVocab`,
              elementId: wordGroup.id,
              message: `Duplicate vocab words, first occurence sentence ${sentenceNumber}`,
            };
            result.push(warning);
          } else {
            usedVocab.set(canonical, wordGroup);
          }
        }
      }
    }

    const suppressions = this.warningSuppressions;
    for (const warning of result) {
      warning.suppressed = suppressions.has(warning.key);
    }
    return result;
  }

  get warningSuppressions() {
    return this.episodeData.warningSuppressions;
  }

  @computed({ keepAlive: true })
  get activeWarnings() {
    if (!this.filterSuppressed) {
      return this.allWarnings;
    } else {
      // TODO include warning kind in warning struct and in key for suppression
      return this.allWarnings.filter(warning => !warning.suppressed);
    }
  }

  setFilterSuppressed(value: boolean) {
    this.filterSuppressed = value;
  }

  @computed({ keepAlive: true })
  get verbatimHasDisallowedChars(): boolean {
    const wordRecords = this.episodeData.wordRecordsData.activeWordRecords;
    for (const word of wordRecords) {
      if (hasVerbatimDisallowedChars(word.text)) {
        return true;
      }
    }
    return false;
  }
}
