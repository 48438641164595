import React, { useEffect } from 'react';
import { Channel } from '@masala-lib/catalog/models/channel';
import { useParams } from 'react-router-dom';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';

export function useChannel() {
  const { id = null } = useParams<{ id: string }>();
  const manager = ChannelManager.getInstance();
  const [currentVolume, setCurrentVolume] = React.useState<Channel>(null);
  useEffect(() => {
    setCurrentVolume(manager.fetchById(id));
  }, [manager, id]);

  return currentVolume;
}
