import { isEmpty } from 'lodash';
import { computed, observable, makeObservable, runInAction, toJS } from 'mobx';
import { BaseMetadata, CatalogEntityKind, ChannelData } from '../catalog-types';
import {
  slugify,
  strongNormalizeWord,
} from '../../misc/editorial-string-utils';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { randomSlug } from '../../utils';
import { notEmpty } from '@utils/conditionals';
import { EntityCrud } from '../db/entity-crud';

// todo: can hopefully remove the need for this
export const NEW_MODEL_PATH_ID = '_new_';

export abstract class BaseCatalogEntity<Data extends BaseMetadata>
  implements CatalogEntity<Data>
{
  @observable
  id: string;

  @observable.ref
  data: Data;

  constructor(d?: { id: string }) {
    this.id = d?.id || '';
    this.data = {} as Data;
    this.data.archived = false;

    this.archive.bind(this);
    this.unarchive.bind(this);

    makeObservable(this);
  }

  // beware, this can't currently be relied upon existing for at least some migrated volumes
  get kind(): CatalogEntityKind {
    // hack to deal with missing 'kind' properties
    if (this.data.kind === undefined) {
      if (notEmpty((this.data as any).volumeId)) {
        return CatalogEntityKind.UNIT;
      }
      if (notEmpty((this.data as any).channelId)) {
        return CatalogEntityKind.VOLUME;
      }
    }
    return this.data.kind;
  }

  get name(): string {
    return this.data.name;
  }

  get normalizedName(): string {
    return strongNormalizeWord(this.name);
  }

  get slug(): string {
    return this.data.slug;
  }

  get archived(): boolean {
    return this.data.archived === true;
  }

  get operationStatus(): string {
    return this.data.pendingOperation || '';
  }

  async setPendingOperation(text: string): Promise<void> {
    await this.updatePartialNoDirty({ pendingOperation: text });
  }

  async clearPendingOperation(): Promise<void> {
    await this.updatePartialNoDirty({ pendingOperation: null });
  }

  async archive(): Promise<void> {
    await this.archiveChildren();
    await this.updatePartialNoDirty({ archived: true });
  }

  // overriden by Volume and Channel
  async archiveChildren(): Promise<void> {}

  async unarchive(): Promise<void> {
    await this.updatePartialNoDirty({ archived: false });
  }

  async updatePartial(data: object): Promise<void> {
    // Object.assign(this.data, data); <-- didn't quite work as desired
    this.data = { ...this.data, ...data }; // need to reassign to trigger the reactions
    if (this.crud) {
      await this.crud.updatePartial(this.id, data);
    } else {
      await this.theManager().updatePartial(this.id, data);
    }
  }

  // variation which doesn't touch the updatedAt flag in the Unit override
  async updatePartialNoDirty(data: object): Promise<void> {
    await this.updatePartial(data);
  }

  abstract theManager(): EntityManager<CatalogEntity<Data>, Data>;

  get crud(): EntityCrud<CatalogEntity<Data>, Data> {
    // todo: make this abstract once fully implemented
    return null;
  }

  toStorageData(): Data {
    return this.data;
  }

  fromStorageData(d: Data) {
    runInAction(() => {
      this.data = d;
    });
  }

  toFormData(): Data {
    return this.data;
  }

  // fromFormData(d: Data) {
  //   runInAction(() => {
  //     this.data = { ...this.data };
  //   });
  // }

  fromFormData(d: Data): void {
    let name = d.name?.trim(); // exploratory input cleanup
    if (isEmpty(name)) {
      // guarantee we always have a name so UI isn't broken if client doesn't properly validate
      name = randomSlug(5);
    }
    const slug = this.sanitizedSlug(d.slug, name);

    runInAction(() => {
      this.data = { ...this.data, ...d, name, slug };
    });
  }

  sanitizedSlug(slug: string, name: string): string {
    let candidate = isEmpty(slug?.trim()) ? name : slug;
    candidate = slugify(candidate);
    // todo check uniqueness
    return candidate;
  }

  // matches against either name or slug, ignoring case, accents and punctuation
  filterMatch(text: string): boolean {
    if (isEmpty(text)) {
      return true;
    }
    const match = strongNormalizeWord(text);
    return (
      strongNormalizeWord(this.name).includes(match) ||
      strongNormalizeWord(this.slug).includes(match)
    );
  }

  /**
   * Leverages MobX‘s toJS to convert a MobX proxied object into a POJO
   */
  inspect() {
    return toJS(this);
  }

  /**
   * Represent the MobX proxied object as a JSON string.
   */
  toJson() {
    return JSON.stringify(this.inspect());
  }

  // input select option display
  get selectLabel(): string {
    return this.name;
  }
}
