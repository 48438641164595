import { Exchange } from '@masala-lib/llm/project/llm-project-types';
import { OverrideResponseForm } from './override-response-form';
// import * as Dialog from '@naan/primitives/modals/dialog';
import { styled } from '@naan/stitches.config';
import { SimpleDialog } from '../components/simple-dialog';
import { DialogPresenter } from '../dialog-presenter';

export function presentResponseOverrideModal(exchange: Exchange) {
  DialogPresenter.present(onDismiss => (
    <ResponseOverrideModal exchange={exchange} onDismiss={onDismiss} />
  ));
}

const ResponseOverrideModal = ({
  exchange,
  onDismiss,
}: {
  exchange: Exchange;
  onDismiss: () => void;
}) => {
  return (
    // <Dialog.Container open onDismiss={onDismiss}>
    //   <Dialog.Heading>Response override</Dialog.Heading>
    //   <OverrideResponseForm exchange={exchange} />
    // </Dialog.Container>
    <SimpleDialog title="Response override" onDismiss={onDismiss}>
      <OverrideResponseForm exchange={exchange} onDismiss={onDismiss} />
    </SimpleDialog>
  );
};
