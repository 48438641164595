import { styled } from '@naan/stitches.config';
import { getSamosaModel } from 'samosa/models/samosa-model-handle';

const TabsWrapper = styled('div', {
  padding: 8,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  background: '$blue-50',
  '& > .tabs': {
    display: 'flex',
    border: '1px solid $colors$blue-100',
    borderRadius: 4,
    overflow: 'hidden',
    '& > .tab': {
      cursor: 'default',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textStyle: 'small-text',
      color: '$blue-400',
      height: 36,
      backgroundColor: '$blue-50',
      transition: 'all 0.3s ease-in-out',
      userSelect: 'none',
      // boxShadow: '0 1px 1px $colors$black-alpha-10 inset',
      '& + &': {
        borderLeft: '1px solid $colors$blue-100',
      },
      '&:hover': {
        color: '$blue-700',
        backgroundColor: '$blue-100',
        // boxShadow: '0 0 2px $colors$blue-300 inset',
      },
      '&.active': {
        backgroundColor: '$blue-10',
        color: '$blue-900',
        // boxShadow: '0 0 2px $colors$blue-10 inset',
        '&:hover': {
          color: '$blue-900',
        },
      },
    },
  },
});

const sidebarTabs = {
  sections: () => 'Sections',
  chron: () =>
    getSamosaModel().sidebarMode === 'exchange' ? 'All' : 'Threads',
} as const;

export type TabName = keyof typeof sidebarTabs;

export const SidebarTabs = ({
  currentTab,
  setCurrentTab,
}: {
  currentTab: TabName;
  setCurrentTab: (tab: TabName) => void;
}) => {
  return (
    <TabsWrapper>
      <div className="tabs">
        {Object.entries(sidebarTabs).map(([key, valueFn]) => (
          <div
            key={key}
            className={`tab ${key === currentTab ? 'active' : ''}`}
            onClick={() => {
              setCurrentTab(key as TabName);
            }}
          >
            {valueFn()}
          </div>
        ))}
      </div>
    </TabsWrapper>
  );
};
