import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { ActivityGuideCreateScreen } from './activity-guide-create-screen';
import { ActivityGuideEditScreen } from './activity-guide-edit-screen';
import { ActivityGuidesListScreen } from './activity-guides-list-screen';
import { ActivityGuideDetailScreen } from './activity-guide-detail-screen';

export const ActivityGuidesScreen = () => {
  return (
    <ModelScreen
      path={'/activity-guides'}
      renderList={() => <ActivityGuidesListScreen />}
      renderDetail={controller => (
        <ActivityGuideDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <ActivityGuideEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <ActivityGuideCreateScreen />}
    />
  );
};
