import { observer } from 'mobx-react';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { Exchange, Step } from '@masala-lib/llm/project/llm-project-types';

import { displayPropsForExchange } from '@masala-lib/llm/project/llm-project-funcs';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { SuppressToggle } from './supress-toggle';
import { SidebarItem } from './sidebar-item';
import { SpinnerIcon } from '../components/icons';
import { RequestLogo } from '../components/request-logo';

//
// left nav list of selectable exchanges, etc
//
export const ExchangeItem = observer(({ step }: { step: Step }) => {
  const model = useSamosaModel();
  const exchange = step as Exchange;

  const { requestHead } = displayPropsForExchange(exchange);
  const selected = model.isCurrentStep(step);
  const status = model.threadStatus(exchange);
  // shade the associated thread steps with selected exchange,
  // or thread heads when reference script selected
  const thread =
    status === 'prior' ||
    status === 'forward' ||
    (model.currentExchange === null && status === 'fork');
  // the 'fork' concept might be provide more confusion than value
  // const fork = status === 'fork';

  const isSuppressed = model.isExchangeSuppressed(exchange);
  const isTerminal =
    model.isTerminalStep(step) && model.sidebarMode === 'exchange';

  return (
    <SidebarItem
      selected={selected}
      thread={thread}
      suppressed={isSuppressed}
      loading={exchange.pendingResponse}
      terminal={isTerminal}
    >
      <div className="contents" onClick={() => model.setCurrentStepId(step.id)}>
        <div className="logo">
          <RequestLogo
            modelName={exchange?.request?.llmOptions?.model}
            size={'small'}
          />
        </div>
        <div className="head">{requestHead}</div>
      </div>
      <div className="hover-controls">
        <SuppressToggle exchange={exchange} />
      </div>
      <div className="loading-indicator">
        <SpinnerIcon />
      </div>
    </SidebarItem>
  );
});
