import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import ReactMarkdown from 'react-markdown';
import { Box } from 'rebass';
import { Avatar } from '../../components/avatar';
import { useFeaturedRelease } from './use-featured-release';
import { Auth } from '@masala-lib/editorial/db/auth';
import { NavLink } from 'react-router-dom';

interface FeaturedReleaseDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const FeaturedReleaseDetailScreen = observer(
  ({ closeDetail, startEditing }: FeaturedReleaseDetailsParams) => {
    const model = useFeaturedRelease();
    const auth = Auth.getInstance();

    if (!model) {
      return null;
    }

    return (
      <Page
        title={`${model.name} ${model.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.isAdmin ? (
                <button onClick={startEditing} className="primary-action">
                  Edit Collection
                </button>
              ) : null}
              <DeleteActions model={model} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <Card>
              <DataItem label={'Id'} value={model.id} />
              {model.channel ? (
                <DataItem
                  label="Channel"
                  value={
                    <NavLink to={model.channel.navPath}>
                      {model.channel.name}
                    </NavLink>
                  }
                />
              ) : null}
              <DataItem label={'Title'} value={model.data.title} />
              <DataItem label={'Slug'} value={model.data.slug} />
              <DataItem
                label={'Illustration'}
                value={
                  <Avatar
                    src={model.data.illustrationUrl}
                    size={'24px'}
                  ></Avatar>
                }
              />
              <DataItem
                label={'Article text'}
                value={
                  <Box py={3}>
                    <ReactMarkdown>{model.data.article}</ReactMarkdown>
                  </Box>
                }
              />
              <DataItem
                label={'Preview article text'}
                value={
                  <Box py={3}>
                    <ReactMarkdown>{model.data.preview}</ReactMarkdown>
                  </Box>
                }
              />
              <DataItem label={'Story slugs'} value={model.data.storySlugs} />
              <DataItem label={'Release date'} value={model.data.releaseDate} />
              <DataItem
                label={'Accessible date'}
                value={model.data.accessibleDate}
              />
              <DataItem
                label={'Active'}
                value={JSON.stringify(model.data.active)}
              />
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
