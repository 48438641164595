import { isEmpty } from 'lodash';
import { makeObservable, observable, when } from 'mobx';
import {
  COMPLETE,
  ERROR_NOT_LOADABLE,
  NOT_INITIATED,
  IN_PROGRESS,
  FirestoreDocSet,
} from './firestore-doc-set';

// TODO rename to FirestoreDocLoader and also put "Doc" in all subclasses, reflects actual purpose
export class FirestoreLoader<T extends FirestoreDocSet> {
  docSet: T;
  key: string;
  factory: (key: string, listenMode?: boolean) => T;

  constructor(factory: (key: string, listenMode?: boolean) => T) {
    this.docSet = null;
    this.key = null;
    this.factory = factory;
    makeObservable(this, {
      docSet: observable.ref,
    });
  }

  async loadEpisode(key: string, listenMode = true): Promise<void> {
    console.log(`loadEpisode(${key})`);
    if (key === this.key) {
      console.log(`ignoring redundant loadEpisode(${key})`);
      return;
    }

    // don't close during initial load
    // this was some defeating the review tool player loading
    if (this.docSet) {
      console.log(`oldKey: ${JSON.stringify(this.key)}, newKey: ${key}`);
      this.closeEpisode();
    }
    this.key = key;
    this.docSet = this.factory(key, listenMode);
    this.docSet.load();
  }

  closeEpisode(): void {
    console.log('fs-loader.closeEpisode()');
    this.key = null;
    if (this.docSet) {
      this.docSet.close();
    }
    this.docSet = null;
  }

  getStateVersion(): string {
    if (!this.docSet) {
      return '';
    }
    return (
      this.docSet.key +
      '_' +
      this.docSet.status +
      '_' +
      this.docSet.stateVersion
    );
  }

  getStatus(): string {
    if (!this.docSet) {
      return '';
    }
    return this.docSet.status;
  }

  promiseWhenComplete() {
    const completeOrError = () => {
      if (!this.docSet) {
        return false; // TODO or true?
      }
      const status = this.docSet.status;
      return (
        status === COMPLETE ||
        (status !== NOT_INITIATED && status !== IN_PROGRESS)
      );
    };
    return when(completeOrError, {});
  }
}
