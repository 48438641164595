import {
  ElementId,
  ELTOf,
  EmptyElementList,
  WideElementList,
  IDTOfET,
  IndexedElement,
  TimedElement,
} from '../basic-types';
import { Intervals } from '../intervals/intervals';
import { CreateTracker, Tracker } from './tracker';
import { getKindFromId } from '../elements/element-id-utils';
import { Tracking } from './tracking';
import { LoosenConstraints } from '../type-utils';

export class TrackingImpl<
  ET extends TimedElement | IndexedElement,
  ITT extends Tracker<IDTOfET<ET>, ET> = Tracker<IDTOfET<ET>, ET>
> implements LoosenConstraints<Tracking<ET, ElementId>>
{
  triggerFunction: () => any;
  positionFunction: () => number;
  getIntervals: (arg: any) => Intervals;
  trackers: Map<string, ITT>;
  elements: WideElementList;

  constructor(
    positionFunction0: () => number,
    getIntervals0: (arg: any) => Intervals
  ) {
    this.positionFunction = positionFunction0;
    this.getIntervals = getIntervals0;
    this.trackers = new Map();
    this.elements = EmptyElementList;
  }

  setElements({
    elements,
    triggerFunction,
  }: {
    elements: ELTOf<ET>;
    triggerFunction: () => any;
  }) {
    this.elements = elements as unknown as WideElementList;
    this.triggerFunction = triggerFunction;
    // if (
    //   this.elements &&
    //   newElements &&
    //   this.elements.episodeKey !== newElements.episodeKey
    // ) {
    //   for (const tracker of this.trackers.values()) {
    //     tracker.dispose();
    //   }
    //   this.trackers.clear();
    // } else {
    for (const kind of this.trackers.keys()) {
      const tracker = this.trackers.get(kind);
      tracker.configure({
        elements: this.elements.filterByKind(kind) as any,
        triggerFunction: this.triggerFunction,
      });
    }
  }

  _createTrackerWithKind(kind: string) {
    const kindList = this.elements.filterByKind(kind);
    const tracker = CreateTracker<ElementId, TimedElement>({
      elements: kindList,
      positionFunction: this.positionFunction,
      triggerFunction: this.triggerFunction,
      intervals: this.getIntervals,
    }) as ITT;
    return tracker;
  }

  getTrackerWithKind(kind: string) {
    const tracker = this.trackers.get(kind);
    if (tracker) {
      return tracker;
    } else {
      const tracker = this._createTrackerWithKind(kind);
      this.trackers.set(kind, tracker);
      return tracker;
    }
  }

  getTracker(elementId: ElementId) {
    const kind = getKindFromId(elementId);
    return this.getTrackerWithKind(kind);
  }

  currentIsUnder(kind: string) {
    const tracker = this.getTrackerWithKind(kind);
    return tracker.currentIsUnder();
  }

  isUnder(elementId: ElementId) {
    return this.getTracker(elementId).isUnder(elementId);
  }

  isBefore(elementId: ElementId) {
    return this.getTracker(elementId).isBefore(elementId);
  }

  isVisited(elementId: ElementId) {
    return this.getTracker(elementId).isVisited(elementId);
  }

  isUnderSignal(elementId: ElementId) {
    return this.getTracker(elementId).isUnderSignal(elementId);
  }

  isBeforeSignal(elementId: ElementId) {
    return this.getTracker(elementId).isBeforeSignal(elementId);
  }

  isVisitedSignal(elementId: ElementId) {
    return this.getTracker(elementId).isVisitedSignal(elementId);
  }

  watchIsUnder(elementId: ElementId) {
    return this.getTracker(elementId).watchIsUnder(elementId);
  }

  watchIsBefore(elementId: ElementId) {
    return this.getTracker(elementId).watchIsBefore(elementId);
  }

  watchIsVisited(elementId: ElementId) {
    return this.getTracker(elementId).watchIsVisited(elementId);
  }

  changedSignal(elementId: ElementId) {
    return this.getTracker(elementId).changedSignal(elementId);
  }

  anyIsChangedSignal(kind: string) {
    const tracker = this.getTrackerWithKind(kind);
    return tracker.anyIsChangedSignal;
  }
}
