import * as React from 'react';
import { observer } from 'mobx-react';
import { ChannelFilterSelector } from './channel-filter-selector';
import { VolumeFilterSelector } from './volume-filter-selector';
import { WorkflowStatusFilterSelector } from './workflow-status-filter-selector';
import { Lightbox, Modal } from '../../../components/modals';
import styled from 'styled-components';
import { useSwitch } from '../../../lib/hooks/use-switch';
import { PopButton } from '../../../components/popover-menu/pop-button';
import { useKeyboardBinding } from '../../../lib/hooks/use-keyboard-binding';
import { WarningsStatusSelector } from './warnings-status-filter-selector';
import { SoundbiteStatusFilterSelector } from './soundbite-status-filter-selector';

const iconSize = 16;
const filterIcon = (
  <svg
    width={iconSize}
    height={iconSize}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.73702 4L10.4961 9.63868C10.8247 10.1315 11 10.7105 11 11.3028V19.2639L13 18.2639V11.3028C13 10.7105 13.1753 10.1315 13.5038 9.63868L17.263 4H6.73702ZM14.4472 19.7764L10.4472 21.7764C9.7823 22.1088 8.99999 21.6253 8.99999 20.882V11.3028C8.99999 11.1054 8.94155 10.9123 8.83204 10.7481L4.03645 3.5547C3.59342 2.89015 4.06981 2 4.8685 2H19.1315C19.9302 2 20.4066 2.89015 19.9635 3.5547L15.1679 10.7481C15.0584 10.9123 15 11.1054 15 11.3028V18.882C15 19.2607 14.786 19.607 14.4472 19.7764Z"
      fill="currentColor"
    />
  </svg>
);

const Wrapper = styled.div`
  width: max(50vw, 500px);
  background: var(--color-white);
  box-shadow: 0 0 12px #00000030, 0 6px 6px #00000015;
  grid-template-rows: 48px 1fr;
  margin-top: -96px;
  border-radius: 8px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;

  .current-button {
    display: flex;

    i {
      margin-top: -1px;
      width: 16px;
      height: 16px;
      margin-right: 4px;
      color: var(--color-gray200);
    }

    span {
      text-transform: uppercase;
      font-size: 12px;
    }
  }
`;

interface FilterComponentProps {
  path: string;
}
type FilterComponent = (props: FilterComponentProps) => JSX.Element;

const filterComponentsMap: { [key: string]: FilterComponent } = {
  channel: ChannelFilterSelector,
  volume: VolumeFilterSelector,
  workflowStatus: WorkflowStatusFilterSelector,
  warningsStatus: WarningsStatusSelector,
  soundbiteStatus: SoundbiteStatusFilterSelector,
};

type FilterComponentName = keyof typeof filterComponentsMap;

interface FilterControlsProps {
  path: string;
  filters: Array<FilterComponentName>;
}
export const FilterControls = observer(
  ({ path, filters }: FilterControlsProps) => {
    const modalSwitch = useSwitch(false);
    useKeyboardBinding('Enter', modalSwitch.off);
    useKeyboardBinding('keyf', modalSwitch.on);

    React.useEffect(() => {
      if (modalSwitch.value) {
        (document.activeElement as any).blur();
      }
    }, [modalSwitch.value]);

    return (
      <>
        <PopButton
          label={
            <Label>
              <span className="current-button">
                <i>{filterIcon}</i>
                Filters
              </span>
            </Label>
          }
          onClick={modalSwitch.on}
          isOpen={modalSwitch.value}
        ></PopButton>
        {modalSwitch.value ? (
          <Modal onEscape={modalSwitch.off} onClickOutside={modalSwitch.off}>
            <Wrapper>
              {filters.map(filterName => {
                const Component = filterComponentsMap[filterName];
                return <Component path={path} />;
              })}
            </Wrapper>
          </Modal>
        ) : null}
      </>
    );
  }
);
