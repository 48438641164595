import * as React from 'react';
import { useForm, UseFormProps } from 'react-hook-form';
import { phraseize } from '../phraseize';

export function useMasalaForm<T>(args: UseFormProps<T>) {
  const formContext = useForm<T>(args);
  const getFieldProps = React.useCallback(
    name => {
      return {
        name,
        label: phraseize(name),
        formContext,
      };
    },
    [formContext]
  );
  return { ...formContext, getFieldProps };
}
