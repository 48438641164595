import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ChannelData } from '@masala-lib/catalog/catalog-types';
import { Channel } from '@masala-lib/catalog/models/channel';
import { ChannelForm } from './channel-form';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { useNavigate } from 'react-router-dom';

export const ChannelCreateScreen = observer(() => {
  const channelManager = ChannelManager.getInstance();
  const navigate = useNavigate();
  const channel = new Channel();

  const goBack = () => {
    navigate(`/channels`);
  };

  const onSubmit = (dto: ChannelData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    channel.fromFormData(dto);
    channelManager.create(channel).then(goBack);
  };

  return (
    <Page title={'Creating new channel'}>
      <ChannelForm
        dto={channel.toFormData()}
        onSubmit={onSubmit}
        onCancel={goBack}
      />
    </Page>
  );
});
