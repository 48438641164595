import { observer } from 'mobx-react';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { ExcerptsTable } from './excerpts-table';
import { PageHeading } from '../shared/page-heading';
import { SortControls } from '../units/sort-controls';
import { getQueryParamsFromLocation } from '../../../console/lib/query-stuff';
import { useEffect } from 'react';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { FilterControls } from '../shared/filters/filter-controls';
import { FiltersBar } from '../shared/filters-bar';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { alertError } from '@app/notification-service';

export const ExcerptListScreen = observer(() => {
  const location = useLocation();
  const {
    channel: channelId,
    volume: volumeId,
    soundbiteStatus,
    sort = 'updatedAt',
    order = 'desc',
  }: {
    channel?: string;
    volume?: string;
    soundbiteStatus?: string;
    sort?: string;
    order?: string;
  } = getQueryParamsFromLocation(location);

  const auth = Auth.getInstance();
  const manager = ExcerptManager.getInstance();
  const channelManager = ChannelManager.getInstance();

  useEffect(() => {
    console.log(`useEffect - setChannelFilter: ${channelId}`);
    // manager.setFilter('channelId', channelId, 'channel');
    if (channelId) {
      const channel = channelManager.getById(channelId);
      manager.channelFilter = channel;
      if (channel.excerptCount === 0) {
        // async load if needed
        channel.fetchExcerpts().catch(alertError);
      }
    }
    manager.setFilter('volumeId', volumeId, 'volume');
    manager.setFilter('data.status', soundbiteStatus, 'soundbite_status');
    manager.setSorting(sort, order);
  }, [
    manager,
    channelManager,
    channelId,
    volumeId,
    soundbiteStatus,
    sort,
    order,
  ]);

  const channelOptions = ChannelManager.getInstance().selectOptionsWithAll;
  const volumeOptions = VolumeManager.getInstance().selectOptionsWithAll;

  const filterValueDecorator = (filterName: string, value: string) => {
    if (filterName === 'channelId') {
      return channelOptions.find(ch => ch.value === value)?.label;
    }
    if (filterName === 'volumeId') {
      return volumeOptions.find(v => v.value === value)?.label;
    }
    return value;
  };

  const list = manager.filteredList;

  return (
    <Page
      title="Manage Soundbites"
      controls={
        <PageActions>
          {auth.can('manage_excerpts') ? (
            <Link to={'/excerpts/new'} className="primary-action">
              Create new Soundbite
            </Link>
          ) : null}
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <PageHeading>
          <SortControls
            fields={[
              { fieldName: 'releaseDate', label: 'Release date' },
              { fieldName: 'name', label: 'Name' },
              { fieldName: 'slug', label: 'Slug' },
              { fieldName: 'volumeName', label: 'Volume' },
              { fieldName: 'createdAt', label: 'Created at' },
              { fieldName: 'updatedAt', label: 'Updated at' },
            ]}
            currentSortingFieldName={manager.sorting.fieldName}
            currentSortingOrder={manager.sorting.order}
          />
          <FilterControls
            path="/excerpts"
            filters={['channel', 'volume', 'soundbiteStatus']}
          />
          <FiltersBar
            filters={manager.filters}
            nameMap={{ channelId: 'Channel' }}
            valueDecorator={filterValueDecorator}
          />
        </PageHeading>
        <ExcerptsTable list={list} />
      </OnePaneLayout>
    </Page>
  );
});
