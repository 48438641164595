import * as React from 'react';
import { PopButton } from './pop-button';
import { StateSwitch } from '../../lib/hooks/use-switch';
import { ManagedPopoverContainer } from './managed-popover-container';

type RenderFunction = (stateSwitch: StateSwitch) => React.ReactNode;

type PopoverButtonProps = {
  label: React.ReactNode;
  content: React.ReactNode | RenderFunction;
  openByDefault?: boolean;
};

export const PopoverButton = ({ label, ...props }: PopoverButtonProps) => {
  return (
    <ManagedPopoverContainer
      {...props}
      renderButton={openSwitch => (
        <PopButton
          label={label}
          onClick={openSwitch.toggle}
          isOpen={openSwitch.value}
        />
      )}
    />
  );
};
