import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { VideoGuideManager } from '@masala-lib/catalog/db/video-guide-manager';
import { VideoGuide } from '@masala-lib/catalog/models/video-guide';

export function useVideoGuides() {
  const { id } = useParams<{ id: string }>();
  const manager = VideoGuideManager.getInstance();
  const [model, setModel] = React.useState<VideoGuide>(null);

  // console convenience
  (window as any).currentVideoGuide = model;

  useEffect(() => {
    setModel(manager.fetchById(id));
  }, [manager, id]);

  return model;
}
