import {
  IDTOfET,
  KTOfET,
  universalETOf,
  universalIDTOf,
  ElementList as _ElementList,
  SimpleElementList as _SimpleElementList,
  ExtractTimedET,
  ExtractIndexedET,
} from '@tikka/basic-types';
import {
  EditorElement,
  EditorSentence,
  EditorStructural,
  EditorWord,
  EditorWordGroup,
  CreateEditorElementList,
  EditorStructuralContent,
  Translation,
  EditorMetadataBlock,
} from './editorial-types';
import { ElementNodeType } from '@tikka/elements/element-tree';
import { Tracker } from '@tikka/tracking/tracker';

export type Element = EditorElement;
export type Word = EditorWord;
export type Sentence = EditorSentence;
export type Structural = EditorStructural;
export type WordGroup = EditorWordGroup;
export type StructuralContent = EditorStructuralContent;
// JRW TODO nuke this?
export type MetadataBlock = EditorMetadataBlock;
// JRW TODO move to editorial types
export type EditorLineElement = Structural | Sentence | Translation;

export type ElementId = IDTOfET<Element>;
export type ElementKind = KTOfET<Element>;

export type IDTOf<T extends ElementKind | Element> = universalIDTOf<Element, T>;
export type ETOf<T extends ElementId | ElementKind> = universalETOf<Element, T>;

export type ElementNode = ElementNodeType<
  ExtractIndexedET<Element> | Word,
  ExtractIndexedET<Element> | Word
>;
export type ElementTracker<
  ET extends ExtractTimedET<Element> | ExtractIndexedET<Element>
> = Tracker<IDTOfET<ET>, ET>;

export const CreateElementList = CreateEditorElementList;
// JRW TODO should be generic and constrain to Editor Element
// or just reexport instead of define?
export type ElementList<ET extends Element> = _ElementList<ET>;
// JRW TODO generic empty element list would be fine here
export const SimpleElementList: <ET extends Element>(
  elements: ET[]
) => _ElementList<ET> = _SimpleElementList as any;
