import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { createLogger } from '@app/logger';
import { Exchange } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';

const log = createLogger('override-entry');

//
// experimental UI to override the response text for an exchange
//

interface FormData {
  exchangeId: string;
  text: string;
}

export const OverrideResponseForm = observer(
  ({ exchange, onDismiss }: { exchange: Exchange; onDismiss: () => void }) => {
    const model = useSamosaModel();

    const currentText = exchange.rawResponse.text;

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = async (
      data: FormData
      // event: any /*React.BaseSyntheticEvent*/
    ) => {
      log.info(`onSubmit - text: ${data.text}`);
      onDismiss(); // not sure why this needed to be before the await
      await model.overrideExchangeResponse(exchange, data.text);
    };
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        Override response text
        <br />
        {/* <input type="hidden" {...register('exchangeId')} value={exchange.id} /> */}
        <textarea
          {...register('text')}
          defaultValue={currentText}
          rows={15}
          cols={100}
        />
        <br />
        <input type="submit" value="Save" />
        {' - '}
        <ActionLink onPress={onDismiss}>[Cancel]</ActionLink>
      </form>
    );
  }
);
