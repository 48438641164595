import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: 24px;

  ul {
    list-style-type: none;

    li + li {
      border-top: 1px solid #e6e6e6;
    }

    li > a {
      display: block;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 12px;

      &:hover {
        color: inherit;
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

export const ListOfLinks = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
