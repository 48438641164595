//
// hack to deal deal with shared code depdency from the audio-transport to the spa onboarding-service
//

export class OnboardingService {
  onSmartPause(): void {}

  private static _instance: OnboardingService;

  static get instance() {
    if (!OnboardingService._instance) {
      OnboardingService._instance = new OnboardingService();
    }
    return OnboardingService._instance;
  }
}
