import { NavigationTrackGadget } from './navigation-gadget';
import {
  Intervals,
  EmptyIntervals,
  Interval,
} from '@tikka/intervals/intervals';
import { makeObservable, observable, reaction } from 'mobx';
import { TransportState } from '@tikka/player/audio-transport';
import { Context2D } from './canvas';

export class AudioRegionSelectionTrackGadget extends NavigationTrackGadget {
  @observable notifyRegionSelect = 0;
  transportState: TransportState = null;

  constructor() {
    super();
    makeObservable(this);
  }

  drawHandle(ctx: Context2D, index: number) {
    super.drawHandle(ctx, index);
    this.drawHandleEdges(ctx, index, this.height);
  }

  setTransportState(transportState: TransportState) {
    if (this.transportState === transportState) {
      return;
    }
    this.transportState = transportState;
  }

  get selectionIntervals() {
    return this.handleIntervals;
  }

  get selectionInterval() {
    if (this.selectionIntervals.length) {
      return this.selectionIntervals.intervalAt(0);
    }
    return null;
  }

  setSelectionInterval(interval: Interval) {
    // TODO make simple constructor not needing domain params
    const newSelectionIntervals = new Intervals(
      [interval.begin],
      [interval.end]
    );
    this.setHandleIntervals(newSelectionIntervals);
    this.notifyRegionSelect++;
  }

  clearSelection() {
    // TODO really
    this.setHandleIntervals(EmptyIntervals);
    this.notifyRegionSelect++;
  }

  onRegionSelect(f: (g: any, i: Interval) => void) {
    this.disposers.push(
      reaction(
        () => this.notifyRegionSelect,
        () => f(this, this.selectionInterval)
      )
    );
  }

  handleMouseClickAtTime(x: number, y: number, time: number, e: MouseEvent) {
    if (!e.getModifierState('Shift')) {
      return;
    }
    const audioPosition = this.transportState.audioPosition;

    let selection = this.selectionInterval;
    if (selection) {
      selection =
        time > selection.begin
          ? { begin: selection.begin, end: time }
          : { begin: time, end: selection.end };
    } else {
      selection =
        time < audioPosition
          ? { begin: time, end: audioPosition }
          : { begin: audioPosition, end: time };
    }
    this.setSelectionInterval(selection);
    return true;
  }
}
