import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'rebass';
import { NavLink, Route, Routes } from 'react-router-dom';
import { signOut } from '../../platform/firebase-auth';
import styled from 'styled-components';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { Auth } from '@masala-lib/editorial/db/auth';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f0f0f0;
  height: var(--console-status-bar-height);
  border-bottom: 1px solid #e0e0e0;

  & > * {
    font-size: 16px;
    line-height: 24px;
  }

  & a {
    text-decoration: underline;
  }

  .home-link {
    padding: 12px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
      height: 48px;
      border-right: 1px solid #ccc;
      transform: skewX(-8deg);
    }
  }

  .title {
    margin-right: auto;
    font-weight: 700;
    padding: 12px;
  }

  .user {
    display: flex;
    place-items: center;
    padding: 12px;
  }

  .avatar {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 18px;
    padding: 3px;
    margin-right: 6px;
  }
`;

export const SimpleStatusBar = observer(({ title }: { title: string }) => {
  const auth = Auth.getInstance();
  return (
    <Wrapper>
      <Routes>
        <Route path="/home" />
        <Route
          element={
            <div className="home-link">
              <NavLink to="/home">Home</NavLink>
              <span className="separator" />
            </div>
          }
        />
      </Routes>

      <div className="title">{title}</div>

      <div className="user">
        {`[${deploymentConfig.firebaseConfigKey}] \u00a0 `}{' '}
        {/* non-breaking space */}
        <img className="avatar" src={auth.authUser.photoURL} alt="" />
        {auth.appUser?.alias}
      </div>
      {auth.authUser ? (
        <Button className={'logout-btn'} onClick={signOut} color={'gray'}>
          Sign-out
        </Button>
      ) : null}
    </Wrapper>
  );
});
