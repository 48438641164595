import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
// import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import { Table } from '../shared/table';
import { SoundbiteEdition } from '@masala-lib/catalog/models/soundbite-edition';

export const SoundbiteEditionsTable = observer(
  ({ list }: { list: SoundbiteEdition[] }) => {
    return (
      <Table
        data={list}
        cells={[
          {
            header: 'Volume',
            render: model => model.volume?.name,
          },
          {
            header: 'Editorial summary',
            render: model => (
              <Link to={model.navPath}>
                {model.data.calendarSummary || '[blank]'}
              </Link>
            ),
          },
          {
            header: 'Soundbites',
            render: model => model.soundbiteCount,
          },
          {
            header: 'Week',
            render: model => model.data.visibilityWeekPostEpoch,
          },
          // { header: 'Date', render: model => model.data.releaseDate },
          // {
          //   header: 'Updated At',
          //   render: model => uiTimeString(model.data.updatedAt),
          // },
        ]}
      />
    );
  }
);
