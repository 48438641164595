import { CloseSmallIcon } from '@naan/icons/close-icon';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { EditingActionBar } from '../editing-action-bar';
import { AirplaneIcon, SpinnerIcon } from '../components/icons';
import { ResizeControl } from '../components/resize-control';
import { useResizeOverride } from './use-resize-override';
// import { usePromptForm } from './use-prompt-form';
import { keynameForEvent } from 'lib/services/keyboard-service';
import { PromptEntryModel } from './prompt-entry-model';

// import { useKeyboardBinding } from '@console/lib/hooks/use-keyboard-binding';

const Wrapper = styled('div', {
  '& > .inner': {
    width: 'min( 100% - 32px, var(--maxChatWidth) )',
    margin: '0 auto',
    padding: '12px 0 18px 0',

    '& .input-area': {
      display: 'flex',
      flexDirection: 'row',
      gap: 12,

      '& > textarea': {
        all: 'unset',
        border: '1px solid $colors$gray-200',
        background: 'white',
        flex: '1 1 auto',
        resize: 'none',
        padding: '12px',
        borderRadius: 8,
        color: '$colors$textPrimary',
        fontWeight: '400',
        '&[disabled]': {
          color: '$colors$black-alpha-50',
          cursor: 'not-allowed',
        },
        '&:focus': {
          outline: 'none',
          boxShadow: '0 0 2px 2px $colors$blue-200',
        },
        '&::placeholder': {
          color: '$colors$gray-300',
        },
      },
      '& > .toolbar': {
        // borderTop: '1px solid $colors$gray-100',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 12,
        position: 'relative',
        // padding: '8px 16px',
        '& > .resize-button-positioner': {
          position: 'absolute',
          width: 36,
          height: 36,
          bottom: 7,
          left: -56, // button-width + padding + margin,
        },
      },
    },
  },
});

const isThisWindows = navigator?.platform?.indexOf('Win') > -1;
const metaLabel = isThisWindows ? 'ctrl' : '⌘';

export const MessageEntry = observer(() => {
  const promptModel = PromptEntryModel.instance;
  const model = useSamosaModel();

  // @armando, todo: i'd like for the prompt area to be expanded by default when starting a new thread
  // @joseph: if this variable is true, the model will default to expanded
  // but the condition doesn't seem to work as you expected
  const initiallyExpand = !model.currentExchange;
  const { getRows, handleResizeOverride } = useResizeOverride(
    initiallyExpand ? 'EXPANDED' : 'AUTO'
  );

  const rows = getRows(promptModel.promptText);

  const emptyPrompt = promptModel.emptyPrompt;

  return (
    <>
      <EditingActionBar />
      <Wrapper>
        <div className="inner">
          <form
            onSubmit={e => {
              e.preventDefault();
              console.log('SUBMIT', promptModel.promptText);
              promptModel.submitToModel(model, false);
            }}
          >
            <div className="input-area">
              <textarea
                id={promptModel.promptAreaId}
                disabled={model.pendingResponse}
                autoFocus
                defaultValue={''}
                value={promptModel.promptText}
                // placeholder={`type here, use ${metaLabel}+enter to submit, ${metaLabel}+k for help`}
                placeholder={`type here, use ^enter to submit, ^? for help`}
                rows={rows}
                onChange={event => {
                  promptModel.setPromptText(event.target.value);
                }}
                onKeyDown={event => {
                  const eventName = keynameForEvent(event as any);
                  if (
                    eventName === 'meta+enter' ||
                    eventName === 'ctrl+enter'
                  ) {
                    // handleSubmit(onSubmit)();
                    /// kinda hacky, but it works.
                    document.getElementById('main-send-button')?.click();
                    event.preventDefault();
                    return;
                  }

                  /// prevent global shortcuts from firing
                  // event.stopPropagation();
                }}
              />
              <div className="toolbar">
                {/* <div>Status: {model.status}</div> */}
                {emptyPrompt || model.pendingResponse ? null : (
                  <>
                    <div className="resize-button-positioner">
                      <ResizeControl
                        onClick={() => handleResizeOverride(rows)}
                      />
                    </div>
                    <Button
                      id="main-send-button"
                      name="send"
                      label={'Send'}
                      size={'large'}
                      presentation={'green'}
                      disabled={model.pendingResponse}
                      leftIcon={
                        model.pendingResponse ? (
                          <SpinnerIcon width={16} height={16} />
                        ) : (
                          <AirplaneIcon width={16} height={16} />
                        )
                      }
                      type="submit"
                    />
                    {model.exchangeSelected ? (
                      <Button
                        name="start-new"
                        label={'Start new'}
                        size={'large'}
                        presentation={'grayDark'}
                        type="button"
                        onClick={() => {
                          promptModel.submitToModel(model, true);
                        }}
                      />
                    ) : null}
                  </>
                )}
                {model.pendingResponse ? (
                  <Button
                    label={'Stop'}
                    size={'large'}
                    presentation={'redLight'}
                    leftIcon={<CloseSmallIcon />}
                    type="button"
                    onClick={() => model.abortResponse()}
                  />
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </Wrapper>
    </>
  );
});
