import { computed, makeObservable, observable, runInAction } from 'mobx';
import { SoundbiteEditionData } from '../catalog-types';
import { get, isEmpty, isEqual, isNil, sortBy } from 'lodash';
import { EMPTY_FILTER, EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { numberProjectionSort, randomSlug } from '../../utils';
import {
  emptySoundbiteEditionData,
  SoundbiteEdition,
} from '../models/soundbite-edition';
import { VolumeManager } from './volume-manager';
import { Excerpt } from '../models/excerpt';

export class SoundbiteEditionManager extends EntityManager<
  SoundbiteEdition,
  SoundbiteEditionData
> {
  // modelMap: ObservableMap<string, Excerpt>;

  // @observable.ref
  // channelIdFilter: string = null;

  @observable.ref
  channelIdFilter: string = null;

  disposers: (() => void)[] = [];

  constructor() {
    super();
    this.modelMap = observable(new Map(), { deep: false });
    makeObservable(this);

    this.disposers.push(() => this.close());
  }

  setFilterText(text: string) {
    this.filterText = text;
  }

  baseList(): SoundbiteEdition[] {
    // return this.byChannel(this.channelIdFilter);
    let list = this.rawList;
    // should provide a better way to sort within the volume detail view
    return list.sort((a, b) => b.data.updatedAt - a.data.updatedAt);
  }

  // todo: utilize db query level filter for this
  byChannel(channelId: string): SoundbiteEdition[] {
    if (isEmpty(channelId)) {
      console.log(`empty channelId, returning raw list`);
      return this.rawList;
    } else {
      return this.rawList.filter(model => model.channelId === channelId);
    }
  }

  @computed
  get filteredList() {
    let list = this.rawList;

    if (!isEmpty(this.filterText)) {
      list = list.filter(model => model.filterMatch(this.filterText));
    }

    /// the filters get composed. I'm not sure the order is important here.
    // maybe we should take the order from `filterableFields`
    this.filters.forEach((filter, filterName) => {
      if (filter.value) {
        list = list.filter(item => {
          const value = get(item, filterName);
          // console.log('FILTER', { filter, filterName, value, expected: filter.value });
          if (filter.value === EMPTY_FILTER) {
            return isEmpty(value);
          }

          return isEqual(value, filter.value);
        });
      }
    });

    switch (this.sorting.fieldName) {
      case 'name':
        list = sortBy(list, ['normalizedName']);
        break;
      case 'releaseDate':
        list = sortBy(list, ['releaseDateSort', 'normalizedName']);
        break;
      case 'volumeName':
        list = sortBy(list, ['volumeNormalizedName', 'normalizedName']);
        break;
      case 'createdAt':
        list = sortBy(list, ['data.createdAt', 'normalizedName']);
        break;
      case 'visibilityWeekPostEpoch':
        list = sortBy(list, [
          edition => Number(edition.data.visibilityWeekPostEpoch),
          'normalizedName',
        ]);
        break;
      case 'updatedAt':
      default:
        list = sortBy(list, ['data.updatedAt', 'normalizedName']);
        break;

      // default:
      //   break;
    }

    if (this.sorting.order === 'desc') {
      list = list.reverse();
    }

    return list;
  }

  get collectionName(): string {
    return CatalogCollections.SOUNDBITE_EDITION_METADATA;
  }

  // better name here would probably be `buildModel`
  createModel(data: { id: string }): SoundbiteEdition {
    return new SoundbiteEdition(data);
  }

  async createWithDto(dto: SoundbiteEditionData): Promise<SoundbiteEdition> {
    const data = { ...emptySoundbiteEditionData, ...dto };
    const { volumeId } = data;
    const volume = VolumeManager.getInstance().getById(volumeId);
    const units = volume.units;
    const sortedVolumeExcerpts: Excerpt[] = [];
    for (const unit of units) {
      const excerpts = unit.excerpts;
      const sortedExcerpts = [...excerpts];
      numberProjectionSort(
        sortedExcerpts,
        excerpt => excerpt.data.roughSortIndex
      );
      sortedVolumeExcerpts.push(...sortedExcerpts);
    }
    const excerptIds = sortedVolumeExcerpts.map(excerpt => excerpt.id);
    data.calendarSoundbiteIds = excerptIds;
    const result = await super.createWithDto(data);
    return result;
  }

  close() {
    // no longer needed
  }

  // just returns cached instance if available, otherwise return placeholder
  // and do an async load
  fetchById(id: string): SoundbiteEdition {
    console.log(`ExcerptManager.fetchById(${id})`);
    if (isEmpty(id)) {
      return null;
    }
    let candidate = this.modelMap.get(id);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
      this.loadById(id); // will run async and update the already returned instance
    }
    return candidate;
  }

  async loadById(id: string): Promise<SoundbiteEdition> {
    const model = await super.loadById(id);
    if (model) {
      await model.ensureParents();
    }
    return model;
  }

  async loadSoloById(id: string): Promise<SoundbiteEdition> {
    const model = await super.loadSoloById(id);
    if (model) {
      model.fetchedVolume = await new VolumeManager().loadSoloById(
        model.data.volumeId
      );
    }
    return model;
  }

  getOrCreateById(id: string): SoundbiteEdition {
    let candidate = this.modelMap.get(id);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
    }
    return candidate;
  }

  // async loadSoloById(id: string): Promise<Excerpt> {
  //   const model = await super.loadSoloById(id);
  //   // if (model) {
  //   //   model.fetchedUnit = await new UnitManager().loadSoloById(
  //   //     model.unitId
  //   //   );
  //   // }
  //   return model;
  // }

  // async loadSoloBySlug(slug: string): Promise<Unit> {
  //   const model = await super.loadSoloBySlug(slug);
  //   if (model) {
  //     model.fetchedVolume = await new VolumeManager().loadSoloById(
  //       model.volumeId
  //     );
  //   }
  //   return model;
  // }

  private static instance: SoundbiteEditionManager;

  public static getInstance(): SoundbiteEditionManager {
    if (!SoundbiteEditionManager.instance) {
      SoundbiteEditionManager.instance = new SoundbiteEditionManager();
      // should probably only listen for the console usage
      SoundbiteEditionManager.instance.listenAll();
    }
    return SoundbiteEditionManager.instance;
  }
}
