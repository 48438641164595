import { observer } from 'mobx-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { OnePaneLayout } from '../shared/layouts';
import { Page } from '../shared/page';
import { useUnit } from '../units/use-unit';
import { SpeakersTable } from '../volumes/speakers-table';

// subtle variation of the VolumeSpeakersScreen, except for the back navigation

export const UnitSpeakersScreen = observer(() => {
  const unit = useUnit();
  const volume = unit?.volume;
  const navigate = useNavigate();

  if (!volume) {
    return null;
  }

  const goBack = () => {
    navigate(unit.navPath);
  };

  const resetAllSpeakerData = async () => {
    if (
      window.confirm('Will reset speaker data for entire volume. Are you sure?')
    ) {
      await volume.resetAllSpeakerData();
    }
  };

  return (
    <Page title={`Speakers (volume shared)`} onClose={goBack}>
      <OnePaneLayout>
        <h4>{volume.name} speakers</h4>
        <p>
          <NavLink to={unit.navPath}>[Back to unit detail]</NavLink>
        </p>
        <SpeakersTable volume={volume} />
        <hr />
        <p>
          <ActionLink onPress={resetAllSpeakerData}>
            [reset speaker data for entire volume]
          </ActionLink>
        </p>
      </OnePaneLayout>
    </Page>
  );
});
