import { VersionsClient } from '@masala-lib/editorial/models/versions-client-base';
import {
  mutationActions,
  scriptEditorModel,
} from '../../script-editor/models/app-root';

export class ScriptEditorVersions extends VersionsClient {
  get mutationActions() {
    return mutationActions;
  }

  get words() {
    return scriptEditorModel.words;
  }

  get l1locale() {
    return scriptEditorModel.l1locale;
  }

  get activeContent() {
    return scriptEditorModel.elements;
  }

  get episodeKey() {
    return scriptEditorModel.episodeKey;
  }
}
