import { Alert, AlertMessages } from '../../../misc/alert-messages';
import { idIsOfKind, IDTOfKT } from '@tikka/basic-types';
import {
  ElementList,
  Element,
  Sentence,
  Structural,
  StructuralContent,
  ElementId,
} from '../../../editor-aliases';
import { EKind, StructuralKind } from '../../../element-kinds';
import { randomString } from '../../../utils';
import { MutationActions } from '../../db/mutation-actions';
import { notNull } from '@utils/conditionals';
import { getKindFromId } from '@tikka/elements/element-id-utils';
import {
  hasOtherDisallowedChars,
  normalizeSpecialChars,
} from '../../../misc/editorial-string-utils';
import { ContentOfStructural, PointAnchor } from '../../../editorial-types';

// [<AbstractClass>]
export abstract class StructuralActions {
  getSentences() {
    return this.content.filterByKind(EKind.SENTENCE);
  }

  getAnchorWordId(kind: EKind, initialTargetId: ElementId) {
    let targetSentence: Sentence = null;
    // if (getKindFromId(initialTargetId) === EKind.SENTENCE) {
    if (idIsOfKind(initialTargetId, EKind.SENTENCE)) {
      targetSentence = this.content.getElement(initialTargetId) as Sentence;
    } else {
      const targetId = this.getSentences().stepId(
        initialTargetId,
        1,
        this.content
      );
      targetSentence = this.content.getElement(targetId) as Sentence;
    }
    if (targetSentence) {
      return targetSentence.anchor.wordId;
    } else {
      return null;
    }
  }

  create(kind: StructuralKind, initialTargetId: ElementId): ElementId {
    const anchorWordId = this.getAnchorWordId(kind, initialTargetId);
    if (notNull(anchorWordId)) {
      // TODO is there null version 0 wordId problem?
      const newId = `${kind}:${randomString(12)}` as IDTOfKT<StructuralKind>; // TODO factor to mutation actions?
      this.mutationActions.addUpdateStructural({
        kind,
        id: newId,
        content: { text: '' },
        anchor: { wordId: anchorWordId },
      });
      return newId;
    } else {
      return null;
      // TODO alert can't find sentence anchor to create structural content
    }
  }

  move(id: ElementId, initialTargetId: ElementId): void {
    const element = this.content.getElement(id) as Structural;
    const newAnchorWordId = this.getAnchorWordId(element.kind, initialTargetId);
    if (notNull(newAnchorWordId)) {
      // TODO is there null version 0 wordId problem?
      this.mutationActions.addUpdateStructural({
        kind: element.kind,
        id: element.id,
        content: element.content, // TODO use strong typing
        anchor: { wordId: newAnchorWordId },
      });
    } else {
      this.alertMessages.add({
        ...Alert,
        text: "can't find sentence anchor to move structural content",
      });
    }
  }

  updateTextContent(id: ElementId, content: string): void {
    content = normalizeSpecialChars(content);
    if (hasOtherDisallowedChars(content)) {
      this.alertMessages.add({
        ...Alert,
        text: `edit contained disallowed characters: ${hasOtherDisallowedChars}`,
      });
      return;
    }
    this.mutationActions.updateTextContentStructural(id, content);
  }

  updateContent(id: ElementId, content: ContentOfStructural): void {
    this.mutationActions.updateContentStructural(id, content);
  }

  updateExcerptEnd(id: ElementId, anchor: PointAnchor) {
    this.mutationActions.updateExcerptEnd(id, anchor);
  }

  // TODO some other actions classes are contextual and operate on the focused object in the editor
  // TODO should incorporate that here to be consistent? removeFocused or something??
  remove(id: ElementId): void {
    this.mutationActions.removeStructural(id);
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList<Element>;
  abstract get alertMessages(): AlertMessages;
}
