import { observable, computed, makeObservable } from 'mobx';
import {
  ElementId,
  ElementIdToString,
  IWord,
  TimedElement,
  NO_INDEX,
  IntervalIndexedTimed,
  idIsOfKind,
  IDTOfKT,
  WordElement,
  ExtractIntervalIndexedET,
  SentenceId,
  WordId,
} from '@tikka/basic-types';
import { UnitData } from '../../catalog/catalog-types';
import { getSentenceWordIdRange } from '../../content-utils';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';
import { EKind } from '../../element-kinds';
import { sortElements } from '../../element-sort';
import { CreatePrecedence, Precedence } from '@tikka/elements/precedence';
import {
  fromIntervals,
  size,
  Intervals,
  Interval,
} from '@tikka/intervals/intervals';
import {
  ChaatAudioAnalysisDoc,
  ChaatAudioMarkersDoc,
  ChaatAudioRegionsDoc,
  ChaatCuesDoc,
  ChaatSignoffsDoc,
  ChaatSpeechTranscriptsDoc,
  ChaatTimestampsDoc,
  ChaatTrickyEditsDoc,
  EpisodeMetadataBlockDoc,
  EpisodeStructuralDoc,
  EpisodeTranslationDoc,
  // EpisodeMetadataDoc,
  EpisodeVerbatimDoc,
  EpisodeWordGroupDoc,
  TrickyEdit,
  WarningSuppressionsDoc,
} from '../db/firestore-doc-types';
import { computeWordStyles, createWordRecordsData } from '../ids/word-ids';
import { isNull } from 'lodash';
import { notEmpty } from '@utils/conditionals';
import { numberProjectionSort } from '../../utils';
import { defaultKerningConfigData } from '../../misc/constants';
import {
  AudioMarker,
  ChaatInputCue,
  Cue,
  KerningConfigData,
  WarningKeys,
} from '../chaat/chaat-types';
import {
  AdhocRangeElement,
  ChaatElementList,
  ChaatSentence,
  ChaatWordElement,
  CreateChaatElementList,
  CreateEditorElementList,
  Deletable,
  EditorElement,
  EditorElementList,
  EditorMetadataBlock,
  EditorSentence,
  EditorStructural,
  EditorWordElement,
  EditorWordGroup,
  ElementIdToTranslation,
  SpanAnchor,
} from '../../editorial-types';
import {
  BreakWordMap,
  StorageWord,
  WordRecord,
  WordRecordsData,
} from '../../editorial-types-aside';
import {
  CreateChaatEditorialWordElementList,
  CreateEditorEditorialWordElementList,
} from '../../editorial-word-element-list';
import { ETOf as EditorETOf } from '@masala-lib/editor-aliases';
import {
  CompressionSettings,
  TrickyBitsSignalTransforms,
} from '../chaat/quanta-funcs';

export function wordElementsFromWordRecords(
  wordRecords: WordRecord[]
): StorageWord[] {
  return wordRecords.map(record => {
    return { kind: EKind.WORD, ...record };
  });
}

function mapPointAnchorToSpanAddresses(
  elements: (EditorSentence | ExtractIntervalIndexedET<EditorStructural>)[],
  words: EditorElementList<WordElement>,
  sentenceIntervals: Intervals = null
): void {
  for (const element of elements) {
    const anchor = element.anchor;
    let address = words.getIndex(anchor.wordId);
    if (sentenceIntervals && address !== NO_INDEX) {
      const index = sentenceIntervals.containing(address);
      const sentenceInterval = sentenceIntervals.intervalAt(index);
      address = sentenceInterval.begin;
    }
    element.address = address === NO_INDEX ? 0 : address;
  }
  elements = [...elements];
  sortElements(elements);
  for (let i = 1; i < elements.length; i++) {
    elements[i - 1].endAddress = elements[i].address - 1;
  }
  elements[elements.length - 1].endAddress = words.values.length - 1;
}

function mapStucturalKindsAddresses(
  elements: EditorStructural[],
  words: EditorElementList<WordElement>,
  sentenceIntervals: Intervals,
  keepAsPoints: EKind[] = []
) {
  const kindMap: { [index in EKind]: EditorStructural[] } = {} as any;
  for (const element of elements) {
    const kindList = kindMap[element.kind] ?? [];
    kindList.push(element);
    if (kindList.length === 1) {
      kindMap[element.kind] = kindList;
    }
  }
  for (const [kind, kindList] of Object.entries(kindMap)) {
    if (keepAsPoints.includes(kind as EKind)) {
      mapPointAnchorToAddresses(kindList, words, sentenceIntervals);
    } else {
      mapPointAnchorToSpanAddresses(kindList as any, words, sentenceIntervals);
    }
  }
}

function mapPointAnchorToAddresses(
  structural: EditorStructural[],
  words: EditorElementList<WordElement>,
  sentenceIntervals: Intervals
): void {
  // TODO needs to use sentence intervals
  for (const element of structural) {
    const wordId = element.anchor.wordId;
    const address = words.getIndex(wordId);
    if (address !== NO_INDEX) {
      const index = sentenceIntervals.containing(address);
      const sentenceInterval = sentenceIntervals.intervalAt(index);
      element.address = sentenceInterval.begin;
    } else {
      element.address = 0;
    }
  }
}

function mapWordGroupAnchorToAddresses(
  wordGroups: EditorWordGroup[],
  wordRecordsData: WordRecordsData
): void {
  const getIndex = wordRecordsData.getIndexActive;
  for (const wordGroup of wordGroups) {
    const wordGroupAnchor = <SpanAnchor>wordGroup.anchor;
    wordGroup.address = getIndex(wordGroupAnchor.wordId);
    const endWordId = wordGroupAnchor.endWordId;
    let index0 = getIndex(endWordId);
    if (wordRecordsData.isRemapped(endWordId) && index0 > 0) {
      index0--;
    }
    wordGroup.endAddress = index0;
  }
}

function partitionOrphanedWordGroups(wordGroups: EditorWordGroup[]) {
  const nonOrphaned = [];
  const orphaned = [];
  for (const g of wordGroups) {
    if (g.address > g.endAddress) {
      orphaned.push(g);
    } else {
      nonOrphaned.push(g);
    }
  }
  return { orphaned, nonOrphaned };
}

function mapElementsAddressesToTimes(
  elements: IntervalIndexedTimed[],
  words: ChaatWordElement[]
): void {
  for (const elem of elements) {
    elem.time = words[elem.address].time;
    elem.endTime = words[elem.endAddress].endTime;
  }
}

function getElementsTimestampingSignature(elements: TimedElement[]): string {
  let result = '';
  for (const element of elements) {
    result += element.id + '_' + element.time;
  }
  return result;
}

export function getSentenceTimestampingSignature(
  sentence: ChaatSentence,
  words: ChaatElementList<ChaatWordElement>
) {
  const wordRange = getSentenceWordIdRange(sentence, words);
  // TODO optimize, put some more efficient method on element list to get element array from IdRange?/
  const sentenceWords = words.idRangeAsElements(wordRange);
  return getElementsTimestampingSignature(sentenceWords);
}

// TODO direction Enum
export function interleave(
  elements: EditorElement[],
  lookup: (id: ElementId) => EditorElement,
  direction: number
) {
  const result = [];
  for (const el of elements) {
    const found = lookup(el.id);
    if (found) {
      if (direction === -1) {
        result.push(found);
        result.push(el);
      } else {
        result.push(el);
        result.push(found);
      }
    } else {
      result.push(el);
    }
  }
  return result;
}

// TODO move?
export const metadataOrder = CreatePrecedence([
  'METADATA-URL',
  'NOTES',
  'MISC',
  'ASSET-LINKS',
  'CAST',
  'SPEAKERS',
] as const);

// TODO needed?
const languageCodeToLanguage = {
  'en-US': 'english',
  'es-US': 'spanish',
  'de-DE': 'german',
  'pt-BR': 'portuguese',
  da: 'danish',
};

const fullWidthCharsLanguages = new Set(['ja']);

export function fullWidthCharsLanguage(lang: string) {
  return fullWidthCharsLanguages.has(lang);
}

function cuedWordsFromCues(
  cues: ChaatInputCue[],
  words: ChaatElementList<ChaatWordElement>
): ChaatElementList<ChaatWordElement> {
  const wordIds = cues.map(cue => cue.wordId);
  return words.fromIds(wordIds);
}

function segmentStopWordsFromWords(
  words: ChaatElementList<ChaatWordElement>
): ChaatElementList<ChaatWordElement> {
  const allGaps = words.timeIntervals.fromGapIntervals(-3).asIntervals();
  let lastUsedGapEnd = 0;
  const wordIndexes = [];
  for (const [index, gap] of allGaps.entries()) {
    const gapSize = gap.end - gap.begin;
    if (gapSize >= 30) {
      if (gap.begin - lastUsedGapEnd < 1500 && gapSize < 225) {
      } else {
        wordIndexes.push(index);
        lastUsedGapEnd = gap.end;
      }
    }
  }
  return words.fromIndexes(wordIndexes);
}

function computeSegmentStopWords(
  words: ChaatElementList<ChaatWordElement>,
  wordMarkers: { [index: string]: Cue },
  kerningConfig: KerningConfigData
) {
  const { minGapSize, minSegmentLength, forcedGapSize } = kerningConfig;
  const allGaps = words.timeIntervals
    .getGapIntervalIndexPairs(-3)
    .map(p => p[0]);
  let lastUsedGapEnd = 0;
  const stopWordIndexes = [];
  const candidateStopWordIndexes = [];
  for (const [index, gap] of allGaps.entries()) {
    const gapSize = gap.end - gap.begin;
    const bigEnough = gapSize > minGapSize;
    if (bigEnough) {
      candidateStopWordIndexes.push(index);
    }
    const wordId = words.getId(index);
    const marker = wordMarkers[wordId];
    if (marker) {
      if (marker.navStop === false) {
        if (!bigEnough) {
          candidateStopWordIndexes.push(index);
        }
        continue;
      }
      if (marker.navStop === true) {
        stopWordIndexes.push(index);
        lastUsedGapEnd = gap.end;
        continue;
      }
    }
    if (
      bigEnough &&
      !(
        gap.begin - lastUsedGapEnd < minSegmentLength * 1000 &&
        gapSize < forcedGapSize
      )
    ) {
      stopWordIndexes.push(index);
      lastUsedGapEnd = gap.end;
    }
  }
  return {
    stopWords: words.fromIndexes(stopWordIndexes),
    candidates: words.fromIndexes(candidateStopWordIndexes),
  };
}

export function computeElementsTimeRanges(
  els: EditorElementList<
    EditorSentence | EditorETOf<'PASSAGE'> | EditorETOf<'CHAPTER'>
  >,
  wordTimes: { [index: string]: Interval }
): { [index: string]: Interval } {
  if (!wordTimes) {
    return null;
  }
  const result: { [index: string]: Interval } = {};

  for (const element of els.values) {
    const indexRange = {
      begin: element.address,
      end: element.endAddress,
    };
    const words = els.words.rangeAsElements(indexRange);
    const elementTimes = {} as Interval;
    for (const word of words) {
      const times = wordTimes[word.id];
      if (!times) {
        continue;
      }
      elementTimes.begin = times.begin;
      break;
    }
    for (const word of words.reverse()) {
      const times = wordTimes[word.id];
      if (!times) {
        continue;
      }
      elementTimes.end = times.end;
      break;
    }
    if (elementTimes.end && elementTimes.end > elementTimes.begin) {
      result[element.id] = elementTimes;
    }
  }
  return result;
}

function wordIdRangesFromTimeIntervals(
  intervals: Intervals,
  words: ChaatElementList<ChaatWordElement>
): AdhocRangeElement[] {
  const wordTimeIntervals = words.timeIntervals;
  const indexRanges = wordTimeIntervals.mapRangesContained(
    intervals.asIntervals()
  );
  const resultElements: AdhocRangeElement[] = [];
  for (const range of indexRanges) {
    if (range) {
      const idRange = words.indexRangeToIdRange(range);
      resultElements.push(makeAdhocRangeElement(idRange, words));
    } else {
      resultElements.push(null);
    }
  }
  return resultElements;
}

function filterActive<T extends Deletable>(elements: T[]): T[] {
  const results = [];
  for (const element of elements) {
    if (!element.deleted) {
      results.push(element);
    }
  }
  return results;
}

function filterValidAnchor<T extends { anchor: SpanAnchor }>(elements: T[]) {
  const results = [];
  for (const element of elements) {
    const anchor = element.anchor;
    if (typeof anchor.wordId !== 'string') {
      continue;
    }
    const t = typeof anchor.endWordId;
    if (t !== 'string' && t !== 'undefined') {
      continue;
    }
    results.push(element);
  }
  return results;
}

// function splitWarningElementsByWarningType(
//   idRanges: IdRange[],
//   warningData: any[],
//   words: ElementList<WordElement>
// ) {
//   const minorWarningRanges: IdRange[] = [];
//   const majorWarningRanges: IdRange[] = [];
//   for (const [index, range] of idRanges.entries()) {
//     if (range) {
//       if (warningData[index] === 'silence') {
//         majorWarningRanges.push(range);
//       } else {
//         minorWarningRanges.push(range);
//       }
//     }
//   }
//   return {
//     // is this a valid usage of ElementList?
//     minorWarnings: new ElementList(
//       minorWarningRanges as unknown as Element[],
//       null,
//       null,
//       words,
//       null,
//       null,
//       null
//     ),
//     majorWarnings: new ElementList(
//       majorWarningRanges as unknown as Element[],
//       null,
//       null,
//       words,
//       null,
//       null,
//       null
//     ),
//   };
// }

// TODO: sort out proper types for the firestore docs
export class EpisodeDataBase {
  @observable.ref unitMetadataDoc: UnitData = null;

  // @observable.ref episodeMetadataDoc: EpisodeMetadataDoc = null;

  @observable.ref verbatimDoc: EpisodeVerbatimDoc = null;

  @observable.ref structuralDoc: EpisodeStructuralDoc = null;

  @observable.ref wordGroupsDoc: EpisodeWordGroupDoc = null;

  @observable.ref translationsDoc: EpisodeTranslationDoc = null;

  @observable.ref metadataBlocksDoc: EpisodeMetadataBlockDoc = null;

  @observable.ref warningSuppressionsDoc: WarningSuppressionsDoc = null;

  @observable.ref episodeKey = '';

  // audioLanguageCode = 'es-US'; // TODO get from the chaat metadata doc

  @observable.ref timestampsDoc: ChaatTimestampsDoc = null;

  @observable.ref cuesDoc: ChaatCuesDoc = null;

  @observable.ref speechTranscriptDoc: ChaatSpeechTranscriptsDoc = null;

  @observable.ref trickyEditsDoc: ChaatTrickyEditsDoc = null;

  @observable.ref audioAnalysisDoc: ChaatAudioAnalysisDoc = null;

  @observable.ref audioRegionsDoc: ChaatAudioRegionsDoc = null;

  @observable.ref audioMarkersDoc: ChaatAudioMarkersDoc = null;

  // @observable.ref chaatMetadataDoc: EpisodeChaatMetadataDoc = null;

  // @observable.ref audioProcessingJobDoc: any = null;

  // @observable.ref transcriptionJobDoc: any = null;

  @observable.ref chaatSignoffsDoc: ChaatSignoffsDoc = null;

  // drives 'locale' getter
  // when assigned, will override the default value held in the metadata doc
  @observable.ref
  _locale: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  get wordRecordsData(): WordRecordsData {
    const data = createWordRecordsData(this.verbatimDoc?.wordRecords ?? []);
    computeWordStyles(data.activeWordRecords);
    return data;
  }

  @computed
  get activeWordElements(): StorageWord[] {
    return wordElementsFromWordRecords(this.wordRecordsData.activeWordRecords);
  }

  // TODO as these are not keep alive make sure words0/words1 are only accessed in workable contexts
  @computed({ keepAlive: true })
  get words(): EditorElementList<EditorWordElement> {
    const wordRecordsData = this.wordRecordsData;
    // return CreateEditorElementList({
    //   elements: this.activeWordElements,
    //   episodeKey: this.episodeKey,
    //   wordRecordsDataParam: wordRecordsData,
    //   idToIndexF: wordRecordsData.getIndexActive,
    // });

    return CreateEditorEditorialWordElementList({
      elements: this.activeWordElements as WordElement[],
      wordRecordsData: wordRecordsData,
    });
    // TODO seems like address is not mapped on word objects, need it?
  }

  // the version with time data
  @computed({ keepAlive: true })
  get wordsWithTimes(): ChaatElementList<ChaatWordElement> {
    const wordRecordsData = this.wordRecordsData;
    const words: ChaatWordElement[] = this.activeWordElements as any[];
    const startTimes: number[] =
      this.timestampsDoc.wordTimeIntervals.startTimes;
    const endTimes: number[] = this.timestampsDoc.wordTimeIntervals.endTimes;
    for (let i = 0; i <= words.length - 1; i++) {
      const word = words[i];
      word.time = startTimes[i];
      word.endTime = endTimes[i];
    }
    // beware, assuming these id's are numbers
    // return CreateChaatElementList({
    //   elements: words,
    //   episodeKey: this.episodeKey,
    //   wordRecordsDataParam: wordRecordsData,
    //   idToIndexF: wordRecordsData.getIndexActive,
    // });
    return CreateChaatEditorialWordElementList({
      elements: words,
      episodeKey: this.episodeKey,
      wordRecordsData: wordRecordsData,
    });
  }

  // // todo: rework the except ingester and remove this from here
  // excerptedWordsWithTimes(
  //   startMillis: number,
  //   finishMillis: number
  // ): ChaatElementList<ChaatWordElement> {
  //   const wordRecordsData = this.wordRecordsData;
  //   const allWords: ChaatWordElement[] = this.activeWordElements as any[];
  //   const startTimes: number[] =
  //     this.timestampsDoc.wordTimeIntervals.startTimes;
  //   const endTimes: number[] = this.timestampsDoc.wordTimeIntervals.endTimes;
  //   for (let i = 0; i <= allWords.length - 1; i++) {
  //     const word = allWords[i];
  //     word.time = startTimes[i];
  //     word.endTime = endTimes[i];
  //   }

  //   const words = allWords.filter(
  //     word => word.time >= startMillis && word.time <= finishMillis
  //   );
  //   for (const word of words) {
  //     word.time = word.time - startMillis;
  //     word.endTime = word.endTime - startMillis;
  //   }

  //   // beware, assuming these id's are numbers
  //   return CreateChaatElementList({
  //     elements: words,
  //     episodeKey: this.episodeKey,
  //     wordRecordsData: wordRecordsData, // todo: filter this also
  //     idToIndexF: wordRecordsData.getIndexActive,
  //   });
  // }

  get audioLanguage(): string {
    return 'spanish'; // TODO lookup with audio language code
  }

  @computed
  get sentences(): EditorSentence[] {
    if (isNull(this.verbatimDoc)) {
      return [];
    }
    const sentences: EditorSentence[] = Object.values(
      this.verbatimDoc.sentences
    ) as any[];
    const activeSentences = filterActive(sentences);
    mapPointAnchorToSpanAddresses(activeSentences, this.words);
    return activeSentences;
  }

  @computed
  get sentencesWithTimes() {
    const sentences = <ChaatSentence[]>this.sentences;
    mapElementsAddressesToTimes(sentences, this.wordsWithTimes.values);
    return sentences;
  }

  @computed
  get sentencesList() {
    const sentences: EditorSentence[] = [...this.sentences];
    sortElements(sentences);
    return CreateEditorElementList({
      elements: sentences,
    });
  }

  @computed
  get structural() {
    // TODO but null test conditional in other x0 funcs?
    if (isNull(this.structuralDoc)) {
      return [];
    } else {
      const structural: EditorStructural[] = Object.values(
        this.structuralDoc.items
      ) as EditorStructural[];
      const activeStructural = filterActive(structural);
      mapStucturalKindsAddresses(
        activeStructural,
        this.words,
        this.sentencesList.wordIntervals,
        [EKind.CHAPTER_NOTE, EKind.CHAPTER_COMPLETE]
      );
      return activeStructural;
    }
  }

  // this flavor is needed by the bogota data assemble content
  @computed
  get structuralPointAddresses() {
    // TODO but null test conditional in other x0 funcs?
    if (isNull(this.structuralDoc)) {
      return [];
    } else {
      const structural: EditorStructural[] = Object.values(
        this.structuralDoc.items
      ) as EditorStructural[];
      const activeStructural = filterActive(structural);
      mapPointAnchorToAddresses(
        activeStructural,
        this.words,
        this.sentencesList.wordIntervals
      );
      return activeStructural;
    }
  }

  @computed
  get orphanedAndNonOrphanedWordGroups() {
    const wordGroups: EditorWordGroup[] = this.wordGroupsDoc
      ? (Object.values(this.wordGroupsDoc?.items) as EditorWordGroup[])
      : [];
    const validWordGroups = filterValidAnchor(wordGroups);
    const activeWordGroups = filterActive(validWordGroups);
    if (notEmpty(activeWordGroups)) {
      mapWordGroupAnchorToAddresses(activeWordGroups, this.wordRecordsData);
    }
    return partitionOrphanedWordGroups(activeWordGroups);
  }

  get orphanedWordGroups(): EditorWordGroup[] {
    return this.orphanedAndNonOrphanedWordGroups.orphaned;
  }

  get wordGroups(): EditorWordGroup[] {
    return this.orphanedAndNonOrphanedWordGroups.nonOrphaned;
  }

  @computed
  get wordGroupsWithTimes(): EditorWordGroup[] {
    // TODO put time data on word groups
    return this.wordGroups;
  }

  get locale(): string {
    // return this._locale || this.episodeMetadataDoc.learnersLanguage;
    return this._locale || this.unitMetadataDoc?.l1Code;
  }

  set locale(locale: string) {
    this._locale = locale;
  }

  get contentLanguage() {
    return this.unitMetadataDoc?.l2Code ?? 'es';
  }

  get kerningConfig() {
    return this.unitMetadataDoc?.kerningConfig ?? defaultKerningConfigData;
  }

  get primaryL1() {
    return this.unitMetadataDoc?.l1Code ?? 'en';
  }

  @computed({ keepAlive: true })
  get translations(): ElementIdToTranslation {
    const result = this.translationsDoc?.items[this.locale]?.translations;
    return result ?? {};
  }

  @computed
  get metadataBlocks(): EditorMetadataBlock[] {
    // TODO presort?
    return Object.values(this.metadataBlocksDoc.items);
  }

  @computed({ keepAlive: true })
  get warningSuppressions(): Set<string> {
    if (isNull(this.warningSuppressionsDoc)) {
      return new Set([]);
    } else {
      return new Set(this.warningSuppressionsDoc.items);
    }
  }

  @computed({ keepAlive: true })
  get normalizedCues() {
    const normalized = {} as ChaatCuesDoc['cues'];
    for (const cue of Object.values(this.cuesDoc.cues)) {
      const normalizedCue = {
        ...cue,
        wordId: cue.wordId.toLowerCase() as WordId,
      };
      normalized[normalizedCue.wordId] = normalizedCue;
    }
    return normalized;
  }

  @computed({ keepAlive: true })
  get wordMarkers(): Cue[] {
    const wordRecordsData = this.wordRecordsData;
    return <Cue[]>(
      Object.values(this.normalizedCues).filter(
        m => !wordRecordsData.isRemapped(m.wordId)
      )
    );
  }

  @computed({ keepAlive: true })
  get chaatInputCues(): ChaatInputCue[] {
    // TODO includes all cues not just Chaat input type
    const result: ChaatInputCue[] = this.wordMarkers.filter(m => m.input);
    numberProjectionSort(result, (cue: ChaatInputCue) => cue.timestamp);
    return result;
  }

  @computed({ keepAlive: true })
  get cuedWords(): ChaatElementList<ChaatWordElement> {
    return cuedWordsFromCues(this.chaatInputCues, this.wordsWithTimes);
  }

  @computed({ keepAlive: true })
  get cueTimestamps(): number[] {
    return this.chaatInputCues.map(cue => cue.timestamp);
  }

  @computed({ keepAlive: true })
  get cueDisplayTimeIntervals(): Intervals {
    const timestamps = this.cueTimestamps;
    const startPoints = timestamps.map(t => t - 2);
    const endPoints = timestamps.map(t => t + 10);
    return new Intervals(startPoints, endPoints);
  }

  @computed({ keepAlive: true })
  get segmentTimeIntervals(): Intervals {
    return this.wordsWithTimes.timeIntervals.fromGapIntervals(20);
  }

  @computed({ keepAlive: true })
  get activeAndCandidateStopWords() {
    const kerningConfig = this.kerningConfig;
    return computeSegmentStopWords(
      this.wordsWithTimes,
      this.normalizedCues,
      kerningConfig
    );
  }

  @computed({ keepAlive: true })
  get segmentStopWords(): ChaatElementList<ChaatWordElement> {
    return this.activeAndCandidateStopWords.stopWords;
  }

  @computed({ keepAlive: true })
  get candidateSegmentStopWords(): ChaatElementList<ChaatWordElement> {
    return this.activeAndCandidateStopWords.candidates;
  }

  @computed({ keepAlive: true })
  get notchTimeIntervals(): Intervals {
    const intervals = this.audioAnalysisDoc.notchTimeIntervals;
    return new Intervals(intervals.startTimes, intervals.endTimes);
  }

  @computed({ keepAlive: true })
  get silenceTimeIntervals(): Intervals {
    const notches = this.notchTimeIntervals;
    const silences = [];
    const intervals = notches.asIntervals();
    const lastIndex = intervals.length - 1;
    for (const [index, interval] of intervals.entries()) {
      if (size(interval) >= 125 || index === 0 || index === lastIndex) {
        silences.push(interval);
      }
    }
    return fromIntervals(silences);
  }

  @computed({ keepAlive: true })
  get audioEndTime(): number {
    const notchEnds = this.audioAnalysisDoc.notchTimeIntervals.endTimes;
    return notchEnds[notchEnds.length - 1];
  }

  @computed({ keepAlive: true })
  get nonVoiceAudioRegions(): any[] {
    const audioRegions = [...Object.values(this.audioRegionsDoc.items)].filter(
      r => r.kind === 'NON_VOICE' || !r.kind
    );
    numberProjectionSort(audioRegions, r => r.startTime);
    return audioRegions;
  }

  @computed({ keepAlive: true })
  get nonVoiceAudioRegionIntervals(): Intervals {
    const intervals = this.nonVoiceAudioRegions.map(r => {
      return { begin: r.startTime, end: r.endTime };
    });
    // TODO figure out how to do access qualified by Intervals -> Intervals.fromIntervals(..)
    return fromIntervals(intervals);
  }

  @computed({ keepAlive: true })
  get forceLinearAudioRegions(): any[] {
    const audioRegions = [...Object.values(this.audioRegionsDoc.items)].filter(
      r => r.kind === 'FORCE_LINEAR'
    );
    numberProjectionSort(audioRegions, r => r.startTime);
    return audioRegions;
  }

  @computed({ keepAlive: true })
  get forceLinearAudioRegionIntervals(): Intervals {
    const intervals = this.forceLinearAudioRegions.map(r => {
      return { begin: r.startTime, end: r.endTime };
    });
    // TODO figure out how to do access qualified by Intervals -> Intervals.fromIntervals(..)
    return fromIntervals(intervals);
  }

  @computed({ keepAlive: true })
  get audioMarkers(): AudioMarker[] {
    // this was null when attempting to ingest an excerpt
    if (!this.audioMarkersDoc) {
      console.log(`warning - missing audioMarkersDoc`);
      return [];
    }
    const markers = [
      ...Object.values(this.audioMarkersDoc.items),
    ] as AudioMarker[];
    numberProjectionSort(markers, m => m.time);
    return markers;
  }

  @computed({ keepAlive: true })
  get audioMarkerHitIntervals(): Intervals {
    const intervals = this.audioMarkers.map(m => {
      return { begin: m.time, end: m.time + 35 };
    });
    return fromIntervals(intervals);
  }

  // map from word indexes to an associated just prior audioMarker
  @computed({ keepAlive: true })
  get breakWordMap(): BreakWordMap {
    const audioBreakTimes: number[] = this.audioMarkers.map(
      marker => marker.time
    );
    const wordTimeIntervals = this.wordsWithTimes.timeIntervals;
    const audioBreakWordTuples: [number, number][] = audioBreakTimes.map(
      time => [wordTimeIntervals.firstStartsAfterOrAt(time), time]
    );
    return new Map(audioBreakWordTuples);
  }

  @computed({ keepAlive: true })
  get interpolatedTimeIntervals(): Intervals {
    // TODO consistent naming
    const interpolatedIntervals0 =
      this.timestampsDoc.interpolationTimeIntervals;
    return new Intervals(
      interpolatedIntervals0.startTimes,
      interpolatedIntervals0.endTimes
    );
  }

  @computed({ keepAlive: true })
  get interpolatedData(): string[] {
    return this.timestampsDoc.interpolationData;
  }

  @computed({ keepAlive: true })
  get warningTimeIntervals(): Intervals {
    const warningIntervals0 = this.timestampsDoc.warningTimeIntervals;
    return new Intervals(
      warningIntervals0.startTimes,
      warningIntervals0.endTimes
    );
  }

  @computed({ keepAlive: true })
  get sentenceTimestampingSignatures() {
    const words = this.wordsWithTimes;
    const sentences = this.sentencesWithTimes;
    const result: ElementIdToString = {};
    for (const sentence of sentences) {
      result[sentence.id] = getSentenceTimestampingSignature(sentence, words);
    }
    return result;
  }

  @computed({ keepAlive: true })
  get chaatSignoffs(): Set<string> {
    if (isNull(this.chaatSignoffsDoc)) {
      return new Set([]);
    } else {
      return new Set(this.chaatSignoffsDoc.items);
    }
  }

  @computed({ keepAlive: true })
  get chaatUnsignedoffSentences(): ChaatElementList<ChaatSentence> {
    const result: ChaatSentence[] = [];
    const sentences = this.sentencesWithTimes;
    const signatures = this.sentenceTimestampingSignatures;
    const signoffs = this.chaatSignoffs;
    for (const sentence of sentences) {
      const signature: string = signatures[sentence.id];
      if (!signoffs.has(signature)) {
        result.push(sentence);
      }
    }
    return CreateChaatElementList({
      elements: result,
      words: this.wordsWithTimes,
    });
  }

  get warningData(): WarningKeys[] {
    return this.timestampsDoc.warningData;
  }

  @computed({ keepAlive: true })
  get warnings(): ChaatElementList<AdhocRangeElement> {
    const warningElements = wordIdRangesFromTimeIntervals(
      this.warningTimeIntervals,
      this.wordsWithTimes
    );
    const warningData: string[] = this.timestampsDoc.warningData;
    const matchedWarningElements: AdhocRangeElement[] = [];
    for (let i = 0; i < warningElements.length - 1; i++) {
      const warningElement = warningElements[i];
      if (warningElement) {
        const data = warningData[i];
        const subKind = data === 'silences' ? 'MAJOR' : 'MINOR';
        warningElement.subKind = subKind;
        matchedWarningElements.push(warningElement);
      }
    }

    return CreateChaatElementList({
      elements: matchedWarningElements,
      words: this.wordsWithTimes,
    });
  }

  @computed({ keepAlive: true })
  get majorWarnings(): ChaatElementList<AdhocRangeElement> {
    // TODO use subkind filter?
    return this.warnings.filter(e => e.subKind === 'MAJOR');
  }

  @computed({ keepAlive: true })
  get minorWarnings(): ChaatElementList<AdhocRangeElement> {
    return this.warnings.filter(e => e.subKind === 'MINOR');
  }

  @computed({ keepAlive: true })
  get warningSentenceIds(): SentenceId[] {
    const warnIntervals = this.warningTimeIntervals;
    const result: SentenceId[] = [];
    for (const s of this.sentencesWithTimes) {
      if (warnIntervals.hasIntersecting(s.time, s.endTime)) {
        result.push(s.id);
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get transcriptWords(): string[] {
    return this.speechTranscriptDoc.transcriptWords;
  }

  @computed({ keepAlive: true })
  get transcriptWordTimeIntervals(): Intervals {
    const intervals = this.speechTranscriptDoc.transcriptWordTimeIntervals;
    return new Intervals(intervals.startTimes, intervals.endTimes);
  }

  @computed({ keepAlive: true })
  get trickyAsrIntervals(): Intervals {
    const intervals = this.speechTranscriptDoc?.trickyAsrWordTimeIntervals;
    if (!intervals) {
      return null;
    }
    return new Intervals(intervals.startTimes, intervals.endTimes);
  }

  @computed({ keepAlive: true })
  get trickyAsrConfidenceScores(): number[] {
    return this.speechTranscriptDoc?.trickyAsrConfidenceScores;
  }

  @computed({ keepAlive: true })
  get trickyEdits(): Map<number, TrickyEdit> {
    if (this.trickyEditsDoc?.items == null) {
      return new Map();
    }
    const result = new Map<number, TrickyEdit>();
    for (const [index, edit] of Object.entries(this.trickyEditsDoc.items)) {
      result.set(Number(index), edit);
    }
    return result;
  }

  @computed({ keepAlive: true })
  get trickyBitsSignalTransforms(): TrickyBitsSignalTransforms {
    return this.trickyEditsDoc?.transforms;
  }

  @computed({ keepAlive: true })
  get trickyBitsTrackDisable(): { [index: number]: boolean } {
    return this.trickyEditsDoc?.trackDisable;
  }

  @computed({ keepAlive: true })
  get trickyBitsTrackScaleFactor(): { [index: number]: number } {
    return this.trickyEditsDoc?.trackScaleFactor;
  }

  @computed({ keepAlive: true })
  get trickyCompressionSettings(): { [index: string]: any } {
    return this.trickyEditsDoc?.compressionSettings;
  }

  get audioUrls() {
    // // legacy derived URL logic. expected now to be explicitly set on the chaat metadata doc when
    // // first processed or imported
    // const bucket = deploymentConfig.gcsAudioStorageBucket;
    // const audioStorageId = this.audioProcessingJobDoc.m16000AudioId;
    // // TODO put base url to cloud storage bucket somewhere else
    // const downsampledAudioURL = `https://storage.googleapis.com/${bucket}/${audioStorageId}`;

    return {
      audioUrl: this.unitMetadataDoc.audioFinalStorageUrl, // || this.chaatMetadataDoc.finalAudioUrl,
      // transcribeAudioUrl: this.chaatMetadataDoc.m16000AudioUrl, // || downsampledAudioURL,
      transcribeAudioUrl: this.unitMetadataDoc.audioM16000StorageUrl, // || downsampledAudioURL,
      // not currently used
      noMusicAudioUrl: this.unitMetadataDoc.audioTranscribeStorageUrl, // this.chaatMetadataDoc.audioNoMusicUrl,
    };
  }

  @computed({ keepAlive: true })
  get nonWordGroupTranslations() {
    const translations = this.translations;
    const nonWordGroupTranslations: ElementIdToTranslation = {};
    for (const [key, value] of Object.entries(translations)) {
      // if (getKindFromId(value.elementId) !== EKind.WORD_GROUP) {
      if (!idIsOfKind(value.elementId, EKind.WORD_GROUP)) {
        nonWordGroupTranslations[key as ElementId] = value;
      }
    }
    return nonWordGroupTranslations;
  }

  @computed({ keepAlive: true })
  get defaultContent(): EditorElementList<EditorElement> {
    let result: EditorElement[] = [];
    result.push(...this.sentences);
    result.push(...this.wordGroups);
    result.push(...this.structural);
    sortElements(result);
    const nonWordGroupTranslations = this.nonWordGroupTranslations;
    const translationsLookup = (id: ElementId) => nonWordGroupTranslations[id];
    result = interleave(result, translationsLookup, 1);
    return CreateEditorElementList({
      elements: result,
      words: this.words,
    });
  }

  @computed({ keepAlive: true })
  get confidenceScores(): number[] {
    return this.speechTranscriptDoc?.confidenceScores?.map(v => Number(v));
  }

  @computed({ keepAlive: true })
  get wordUncertainties(): number[] {
    return this.confidenceScores?.map(score => 1 - score);
  }

  @computed({ keepAlive: true })
  get trickyAsrWordUncertainties(): number[] {
    return this.trickyAsrConfidenceScores?.map(score => 1 - score);
  }

  @computed
  get structuralElementList(): EditorElementList<EditorStructural> {
    let result: EditorStructural[] = [];
    result.push(...this.structural);
    sortElements(result);
    return CreateEditorElementList({
      elements: result,
    });
  }

  @computed
  get wordGroupElementList(): EditorElementList<EditorWordGroup> {
    let result: EditorWordGroup[] = [];
    result.push(...this.wordGroups);
    sortElements(result);
    return CreateEditorElementList({
      elements: result,
    });
  }
}
