import * as React from 'react';
import { isFunction } from 'lodash';
import { Button } from 'rebass';
import styled from 'styled-components';
import { InputStyleWrapper } from './input-style-wrapper';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled(InputStyleWrapper)`
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export interface FormButtonProps {
  onCancel?: () => void;
  cancelLabel?: string;
  submitLabel?: string;
}

export const FormButtons = ({
  onCancel,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
}: FormButtonProps) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    if (isFunction(onCancel)) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  return (
    <Wrapper>
      <div className="buttons">
        <Button
          onClick={handleCancel}
          backgroundColor={'white'}
          color="gray"
          type="button"
        >
          {cancelLabel}
        </Button>
        <Button type="submit" backgroundColor={'teal'} px={32}>
          {submitLabel}
        </Button>
      </div>
    </Wrapper>
  );
};
