import { makeObservable, observable } from 'mobx';
import { KeyboardShortcutsSet } from '../keyboard-service/types';

import { createLogger } from '@app/logger';
const log = createLogger('picker-controller');

export class ItemsPickerController {
  public index = 0;
  private rows = 0;
  private onEnter: (index: number) => void;
  public allowWrap = true; // enable by default

  constructor({
    rows,
    columns = 1, /// for future use
    initialIndex = 0,
  }: {
    rows: number;
    columns?: number;
    initialIndex?: number;
  }) {
    log.debug('constructor', { rows, columns, initialIndex });
    this.rows = rows;
    this.index = initialIndex;

    makeObservable(this, {
      index: observable,
    });
  }

  is(index: number) {
    return this.index === index;
  }

  next = () => {
    log.debug('next', this.index);
    if (this.allowWrap) {
      this.index = (this.index + 1) % this.rows;
    } else {
      this.index = Math.min(this.index + 1, this.rows - 1);
    }
  };

  previous = () => {
    log.debug('prev', this.index);
    if (this.allowWrap) {
      this.index = (this.index - 1 + this.rows) % this.rows;
    } else {
      this.index = Math.max(this.index - 1, 0);
    }
  };

  getKeybindings = (): KeyboardShortcutsSet => ({
    down: [this.next, null], // 'Select next'],
    up: [this.previous, null], // 'Select previous'],
  });
}
