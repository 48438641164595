import { UnitData } from '@masala-lib/catalog/catalog-types';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import {
  StorageElement,
  StorageSentence,
  StorageStructural,
} from '@masala-lib/editorial-types';
import { WordRecord } from '@masala-lib/editorial-types-aside';
import { EKind } from '@masala-lib/element-kinds';
import { epochSecondsFloat, randomString } from '@masala-lib/utils';
import { IDTOfKT, StringToElement, WordId } from '@tikka/basic-types';
import {
  assertExistChaatDocs,
  createUpdateEpisodeDB,
} from '../db/mutation-actions';
import { createIdValues } from '../ids/word-ids';
import { hasTag, ScriptScanner } from './jwscript-scanner';

export interface ParsedScriptData {
  wordRecords: WordRecord[];
  sentences: StorageSentence[];
  paragraphs: StorageStructural[];
  timestamp: number;
}

function newElementId<KT extends EKind>(elementKind: KT): IDTOfKT<KT> {
  return (elementKind + ':' + randomString(12)) as any; // TODO move
}

export function validateWords(words: string[]) {
  const japChars =
    /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g;
  const illegalChars = /[><]/g; // TODO should + also be illegal?
  const noStandalone = /^[.,""'?!\-¡¿;\\:—+_–]+$"/;
  const allowedStandalone = ['...', '--'];

  for (const [index, word] of words.entries()) {
    if (!word) {
      throw Error('empty string for word');
    } else if (word.match(japChars)) {
      throw Error('japanese characters found in verbatim');
    } else if (word.match(illegalChars)) {
      const context = words.slice(index, index + 4).join(' ');
      throw Error(`illegal characters found in verbatim: "${context}"`);
    } else if (word.match(noStandalone)) {
      if (!allowedStandalone.includes(word)) {
        throw Error('illegal standalone punctuation found in verbatim:' + word);
      }
    }
  }
}
function elementArrayToFirestoreKV(elements: StorageElement[]): any {
  const result: StringToElement<Element> = {};
  for (const element of elements) {
    result[element.id] = element as any;
  }
  return result;
}

function parseSpeakerLabel(line: string): string {
  return line.replace(/^@+\s*/, '');
}

export function parseJWScript(text: string): ParsedScriptData {
  const scanner = new ScriptScanner(text);
  scanner.doScan();
  const melements = scanner.matchElements;
  const words = scanner.words;
  validateWords(words);
  const timestamp = epochSecondsFloat();

  const wordRecordsText = ['', ...words, ''];
  const wordRecordIds = createIdValues(wordRecordsText.length) as WordId[];
  const wordRecords: WordRecord[] = [];
  for (let i = 0; i < wordRecordIds.length; i++) {
    const record = {
      id: wordRecordIds[i] as WordId,
      text: wordRecordsText[i],
      active: true,
    };
    wordRecords.push(record);
  }
  wordRecords[0].active = false;
  wordRecords[wordRecords.length - 1].active = false;
  const wordIdLookup = wordRecordIds.slice(1);

  const sentences: StorageSentence[] = [];
  const paragraphs: StorageStructural[] = [];

  for (const melement of melements.filter(m => hasTag(m, 'SENTENCE'))) {
    const id = newElementId(EKind.SENTENCE);
    const sentence: StorageSentence = {
      kind: 'SENTENCE',
      id,
      anchor: {
        wordId: wordIdLookup[melement.wordPos],
      },
      timestamp,
      author: 'IMPORT',
    };
    sentences.push(sentence);
  }

  for (const melement of melements.filter(m => hasTag(m, 'PARAGRAPH'))) {
    const id = newElementId(EKind.PARAGRAPH);
    const speakerLabel = parseSpeakerLabel(melement.line);
    const paragraph: StorageStructural = {
      kind: 'PARAGRAPH',
      id,
      content: {
        text: speakerLabel,
      },
      anchor: {
        wordId: wordIdLookup[melement.wordPos],
      } as any,
      timestamp,
      author: 'IMPORT',
    };
    paragraphs.push(paragraph);
  }

  return {
    wordRecords,
    sentences,
    paragraphs,
    timestamp,
  };
}

export async function saveScriptDataToFirestore(
  episodeKey: string,
  parsed: ParsedScriptData
): Promise<void> {
  const sentences = elementArrayToFirestoreKV(parsed.sentences);
  const verbatim = {
    wordRecords: parsed.wordRecords,
    sentences,
    timestamp: parsed.timestamp,
  };

  const structural = elementArrayToFirestoreKV(parsed.paragraphs);
  const firestoreRepresentations = {
    verbatim,
    wordGroups: {},
    structural,
    metadataBlocks: {},
    translations: {},
  };

  const unitData: UnitData = await new UnitManager().fetchMetadataById(
    episodeKey
  );
  await createUpdateEpisodeDB(episodeKey, firestoreRepresentations, unitData);
  await assertExistChaatDocs(episodeKey);
}
