import React from 'react';
import {
  appBus,
  appRoot,
  chaatToolModel,
  player,
  tracking,
  transportState,
} from './models/app-root';
import Keyboardist from 'keyboardist';

import {
  idIsOfKind,
  IndexRange,
  WordElement,
  WordId,
} from '@tikka/basic-types';
import { EKind } from '@masala-lib/element-kinds';
import {
  SimpleElementList,
  Element,
  Sentence,
  Structural,
  ElementId,
  CreateElementList,
} from '@masala-lib/chaat-aliases';
import { autorun, reaction } from 'mobx';
import { scrollIfNotVisible } from '@masala-lib/editorial/ui/dom-scroll';

// import { renderView as renderChaatToolView } from './views/chaat-tool-view.js';
import { observer } from 'mobx-react';
import {
  domIdToElementId,
  elementIdToDomId,
  getKindFromId,
} from '@tikka/elements/element-id-utils';
import { openKerningConfigDialog } from './ui/kerning-config-dialog';
import { openSidePopup } from '../console/views/shared/side-popup';
import { renderStylesForDomId } from '@masala-lib/editorial/ui/style-painting/dom-styles';
import { VersionableElement } from '@masala-lib/editorial-types';
import {
  MembershipList,
  CreateMembershipList,
  CreateMembershipReconciler,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';

// TODO move somewhere in masala-lib
function versionedElementKey(element: VersionableElement) {
  return element.id + ':' + element.timestamp;
}

interface Props {}

@observer
export class ChaatTool extends React.Component<Props> {
  model = chaatToolModel;

  disposers: (() => void)[] = [];

  unsubscribeWordIsUnderNotify = null as () => void | null;
  unsubscribeSentenceIsUnderNotify = null as () => void | null;

  // inputHandler = createListener() as KeyboardistListener; // check for the 'false' response value?
  inputHandler = new Keyboardist();

  constructor(props: Props) {
    super(props);
    this.initializeInputHandlers();
    this.disposers.push(
      reaction(
        () => this.membershipLists(),
        () => this.renderMembershipLists(),
        { fireImmediately: true }
      )
    );
    autorun(() => this.setupCursorTracking());
    this.disposers.push(
      reaction(
        () => this.model.currentSentenceId,
        () => scrollIfNotVisible(this.model.currentSentenceId, 'center', null)
      )
    );
  }

  initializeChaatInputHandlers() {
    // TODO
    const handler = this.inputHandler;
    // TODO actual forwards
    handler.subscribe('KeyC', () => this.model.addCue());
    handler.subscribe('Shift+KeyC', () => this.model.removeCueAtCurrent());
    handler.subscribe('KeyN', () => this.model.toggleNavStop());

    handler.subscribe('KeyR', () => this.model.createAudioRegion());
    handler.subscribe('Shift+KeyR', () => this.model.removeAudioRegion());
    handler.subscribe('KeyL', () => this.model.createForceLinearAudioRegion());
    handler.subscribe('Shift+KeyL', () =>
      this.model.removeForceLinearAudioRegion()
    );

    handler.subscribe('KeyM', () => this.model.createAudioMarker());
    handler.subscribe('Shift+KeyM', () => this.model.removeAudioMarker());

    handler.subscribe('KeyA', () => this.model.addShiftCue());
    handler.subscribe('KeyS', () => this.model.addShiftEndCue());

    handler.subscribe('KeyO', () => this.model.setCurrentSentenceSignoff(true));
    handler.subscribe('KeyK', () => this.model.initKerning());
    handler.subscribe('Shift+KeyK', () => player.kerningEnable(false));
    handler.subscribe('Ctrl+KeyK', () => openKerningConfigDialog());
    handler.subscribe('Shift+KeyO', () =>
      this.model.setCurrentSentenceSignoff(false)
    );
    handler.subscribe('Shift+Slash', () => {
      openSidePopup();
    });

    // TODO change param value to enum is now a bool but thought was int
    handler.subscribe('Equal', () => appBus.emit('timeZoom', true));
    handler.subscribe('Plus', () => appBus.emit('timeZoom', true));
    handler.subscribe('KeyZ', () => appBus.emit('timeZoom', true));
    handler.subscribe('KeyF', () => appBus.emit('timeZoom', true));
    handler.subscribe('NumpadAdd', () => appBus.emit('timeZoom', true));

    handler.subscribe('Minus', () => appBus.emit('timeZoom', false));
    handler.subscribe('Shift+KeyZ', () => appBus.emit('timeZoom', false));
    handler.subscribe('Shift+KeyF', () => appBus.emit('timeZoom', false));
    handler.subscribe('NumpadSubtract', () => appBus.emit('timeZoom', false));

    handler.subscribe('Escape', () => this.model.deselect());

    handler.subscribe('Space', () => player.togglePlay());
    handler.subscribe('Ctrl+Space', () => player.playSection());
    handler.subscribe('KeyQ', () => player.playSection());
    handler.subscribe('Enter', () => player.rewind());
    handler.subscribe('Shift+Enter', () => player.playPeekBack());
    handler.subscribe('KeyX', () => player.playPeek());
    handler.subscribe('Shift+KeyX', () => player.playPeekBack());
    handler.subscribe('Up', () => player.adjustPlaybackRateAction(true)); // TODO
    handler.subscribe('Down', () => player.adjustPlaybackRateAction(false)); // TODO
    handler.subscribe('Shift+Left', () =>
      player.seek(transportState.audioPosition - 1500)
    ); // TODO

    handler.subscribe('Shift+Right', () =>
      player.seek(transportState.audioPosition + 1500)
    ); // TODO
    handler.subscribe('Alt+Left', () => player.nudge(-20));
    handler.subscribe('Alt+Right', () => player.nudge(20));
    handler.subscribe('Left', () => player.prevClosest());
    handler.subscribe('Right', () => player.nextClosest());
    handler.subscribe('Digit0', () => appRoot.runTimestamping());
    // handler.subscribe('Digit2', () =>
    //   this.model.toggleTimestampingMethodAndDebug()
    // );
  }

  initializeTrickyInputHandlers() {
    // TODO
    const handler = this.inputHandler;
    // TODO actual forwards

    handler.subscribe('Digit1', () => this.model.toggleRedactTrickyBits());
    handler.subscribe('Shift+KeyR', () => this.model.removeAudioRegion());
    handler.subscribe('Tilde', () => this.model.toggleRedactTrickyBits());

    // TODO change param value to enum is now a bool but thought was int
    handler.subscribe('Equal', () => appBus.emit('timeZoom', true));
    handler.subscribe('Plus', () => appBus.emit('timeZoom', true));
    handler.subscribe('KeyZ', () => appBus.emit('timeZoom', true));
    handler.subscribe('KeyF', () => appBus.emit('timeZoom', true));
    handler.subscribe('NumpadAdd', () => appBus.emit('timeZoom', true));

    handler.subscribe('Minus', () => appBus.emit('timeZoom', false));
    handler.subscribe('Shift+KeyZ', () => appBus.emit('timeZoom', false));
    handler.subscribe('Shift+KeyF', () => appBus.emit('timeZoom', false));
    handler.subscribe('NumpadSubtract', () => appBus.emit('timeZoom', false));

    handler.subscribe('Escape', () => this.model.deselect());

    handler.subscribe('Space', () => player.togglePlay());
    handler.subscribe('Ctrl+Space', () => player.playSection());
    handler.subscribe('KeyQ', () => player.playSection());
    handler.subscribe('Enter', () => player.rewind());
    handler.subscribe('Shift+Enter', () => player.playPeekBack());
    // handler.subscribe('KeyX', () => player.playPeek());
    // handler.subscribe('Shift+KeyX', () => player.playPeekBack());
    handler.subscribe('KeyX', () => this.model.toggleExcludeCurrentTrack());
    handler.subscribe('Up', () => this.model.adjustCurrentTrackScale('UP'));
    handler.subscribe('Down', () => this.model.adjustCurrentTrackScale('DOWN'));
    handler.subscribe('KeyW', () =>
      this.model.adjustTrickyCompressionSettingsValue('windowSize', 1)
    );
    handler.subscribe('Shift+KeyW', () =>
      this.model.adjustTrickyCompressionSettingsValue('windowSize', -1)
    );
    handler.subscribe('KeyB', () =>
      this.model.adjustTrickyCompressionSettingsValue('boostDamper', 1)
    );
    handler.subscribe('Shift+KeyB', () =>
      this.model.adjustTrickyCompressionSettingsValue('boostDamper', -1)
    );
    handler.subscribe('KeyL', () =>
      this.model.adjustTrickyCompressionSettingsValue('targetLevel', 1)
    );
    handler.subscribe('Shift+KeyL', () =>
      this.model.adjustTrickyCompressionSettingsValue('targetLevel', -1)
    );
    handler.subscribe('KeyC', () =>
      this.model.adjustTrickyCompressionSettingsValue('enabled')
    );
    // handler.subscribe('Up', () => player.adjustPlaybackRateAction(true)); // TODO
    // handler.subscribe('Down', () => player.adjustPlaybackRateAction(false)); // TODO
    handler.subscribe('Shift+Left', () =>
      player.seek(transportState.audioPosition - 1500)
    ); // TODO

    handler.subscribe('Shift+Right', () =>
      player.seek(transportState.audioPosition + 1500)
    ); // TODO
    handler.subscribe('Alt+Left', () => player.nudge(-20));
    handler.subscribe('Alt+Right', () => player.nudge(20));
    handler.subscribe('Left', () => player.prevClosest());
    handler.subscribe('Right', () => player.nextClosest());
    handler.subscribe('KeyT', () => this.model.forceTrickyForSelection());
    handler.subscribe('Shift+KeyT', () =>
      this.model.forceNoTrickyForSelection()
    );
    handler.subscribe('Shift+Ctrl+KeyT', () =>
      this.model.clearTrickyOverrideForSelection()
    );
    // handler.subscribe('Digit2', () =>
    //   this.model.toggleTimestampingMethodAndDebug()
    // );
  }

  initializeInputHandlers() {
    if (appRoot.mode === 'tricky') {
      this.initializeTrickyInputHandlers();
    } else {
      this.initializeChaatInputHandlers();
    }
  }

  handleCursorChange(id: WordId) {
    const under = tracking.isUnder(id);
    this.membershipListsReconciler.simpleSetMembershipsForElementId(
      id,
      ['cursor-is-under'],
      !under
    );
  }

  handleWordClick(event: MouseEvent, id: WordId) {
    if (appRoot.mode === 'tricky' && event.shiftKey) {
      this.model.wordRangeSelectTo(id);
    } else if (
      appRoot.mode === 'chaat' &&
      (event.altKey || event.ctrlKey || event.shiftKey)
    ) {
      appBus.emit('setCuePoint', id);
    } else {
      player.seekElement(id);
    }
  }

  handleLineClick(event: MouseEvent, domId: string) {
    const id = domIdToElementId(null, domId);
    const kind = getKindFromId(id);
    if (idIsOfKind(id, EKind.WORD)) {
      this.handleWordClick(event, id);
    } else {
    }
  }

  setupCursorTracking() {
    if (this.unsubscribeWordIsUnderNotify) {
      this.unsubscribeWordIsUnderNotify();
    }

    if (this.unsubscribeSentenceIsUnderNotify) {
      this.unsubscribeSentenceIsUnderNotify();
    }

    this.unsubscribeWordIsUnderNotify = this.model.wordTracker.subscribeIsUnder(
      (id: WordId) => this.handleCursorChange(id)
    );
    this.unsubscribeSentenceIsUnderNotify =
      this.model.sentenceTracker.subscribeIsUnder((id: ElementId) =>
        this.handleCursorChange(id as WordId)
      );
  }

  minorWarningsMembershipList(): MembershipList {
    const elements = this.model.minorWarnings;
    return CreateMembershipList({
      memberships: ['minor-warning'],
      useRanges: true,
      elements,
    });
  }

  // TODO computedFunc?
  majorWarningsMembershipList(): MembershipList {
    const elements = this.model.majorWarnings;
    return CreateMembershipList({
      memberships: ['major-warning'],
      useRanges: true,
      elements,
    });
  }

  // TODO computed?
  segmentsMembershipList(): MembershipList {
    const elements = this.model.segmentStopWords;
    return CreateMembershipList({
      memberships: ['segment-stop-word'],
      elements: elements,
    });
  }

  candidateSegmentsMembershipList(): MembershipList {
    const elements = this.model.candidateSegmentStopWords;
    return CreateMembershipList({
      memberships: ['candidate-stop-word'],
      elements: elements,
    });
  }

  cueMarkersMembershipList(): MembershipList {
    const elements = this.model.cuedWords;
    return CreateMembershipList({
      memberships: ['cue-marker'],
      elements: elements,
    });
  }

  cueInsertPointMembershipList(): MembershipList {
    const cuePointWordId = this.model.currentCuePointWordId;
    const currentCuePointElement = cuePointWordId
      ? this.model.words.getElement(cuePointWordId)
      : null;
    const elements = currentCuePointElement ? [currentCuePointElement] : [];
    return CreateMembershipList({
      memberships: ['cue-insert-point'],
      elements,
    });
  }

  unsignedoffSentencesMembershipList(): MembershipList {
    const elements = this.model.unsignedoffSentences;
    return CreateMembershipList({
      memberships: ['unsignedoff'],
      elements: this.model.unsignedoffSentences,
    });
  }

  trickyMembershipList(): MembershipList {
    const trickyWords = this.model.trickyWords;
    // const words = this.model.words.values;
    // for (let i = 0; i < words.length; i++) {
    //   const word = words[i];
    //   if (i % 2) {
    //     dummyTrickyWords.push(word);
    //   }
    // }
    return CreateMembershipList({
      memberships: ['tricky-word'],
      elements: trickyWords,
    });
  }

  existingTrickyMembershipList(): MembershipList {
    const trickyWords = this.model.existingTrickyWords;
    // const words = this.model.words.values;
    // for (let i = 0; i < words.length; i++) {
    //   const word = words[i];
    //   if (i % 2) {
    //     dummyTrickyWords.push(word);
    //   }
    // }
    return CreateMembershipList({
      memberships: ['existing-tricky-word'],
      elements: trickyWords,
    });
  }

  get wordSelectionMembershipList(): MembershipList {
    const els = this.model.wordRangeSelection
      ? [makeAdhocRangeElement(this.model.wordRangeSelection, this.model.words)]
      : [];
    const elements = CreateElementList({
      elements: els,
      words: this.model.elements.words,
    });
    return CreateMembershipList({
      memberships: ['selected'],
      useRanges: true,
      elements,
    });
  }

  trickyMembershipLists(): Map<string, MembershipList> {
    const result: Map<string, MembershipList> = new Map();
    // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
    result.set('trickyWords', this.trickyMembershipList());
    result.set('existingTrickyWords', this.existingTrickyMembershipList());
    result.set('wordSelection', this.wordSelectionMembershipList);
    return result;
  }

  chaatMembershipLists(): Map<string, MembershipList> {
    const result: Map<string, MembershipList> = new Map();
    // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
    result.set('minorWarnings', this.minorWarningsMembershipList());
    result.set('majorWarnings', this.majorWarningsMembershipList());
    result.set('segmentStopWords', this.segmentsMembershipList());
    result.set('candidateStopWords', this.candidateSegmentsMembershipList());
    result.set('cueMarkers', this.cueMarkersMembershipList());
    result.set('cueInsertPoint', this.cueInsertPointMembershipList());
    result.set(
      'unsignedOffSentences',
      this.unsignedoffSentencesMembershipList()
    );
    return result;
  }

  membershipLists() {
    if (appRoot.mode === 'tricky') {
      return this.trickyMembershipLists();
    } else {
      return this.chaatMembershipLists();
    }
  }

  membershipListsReconciler = CreateMembershipReconciler(renderStylesForDomId);

  renderMembershipLists() {
    this.membershipListsReconciler.reconcileMembershipLists(
      this.model.episodeKey,
      this.membershipLists()
    );
  }

  wordRangeRender(indexRange: IndexRange): string {
    let html = '';
    const words = this.model.words.values;
    const wordTracker = chaatToolModel.wordTracker;
    const membershipLists = this.membershipListsReconciler;
    for (let i = indexRange.begin; i <= indexRange.end; i++) {
      const word = words[i];
      const wordId = word.id;
      const domId = elementIdToDomId('', wordId);
      let wordStyles =
        membershipLists.getJoinedMembershipStringForAddress(i) +
        ' ' +
        membershipLists.getJoinedMembershipStringForElement(wordId);
      if (wordTracker.isUnder(wordId)) {
        wordStyles += ' cursor-is-under';
      }
      html =
        html +
        `<span class="word ${wordStyles}" id="${domId}">${word.text} </span>`;
    }
    return html;
  }

  SentenceView = ({
    element,
    prefix,
  }: {
    element: Sentence;
    prefix: string;
  }) => {
    const startIndex = element.address;
    const endIndex = element.endAddress;
    const sentenceHTML = this.wordRangeRender({
      begin: startIndex,
      end: endIndex,
    });
    const style = this.lineViewerConfigurations[EKind.SENTENCE].style;
    prefix = `<span>${prefix}</span>`;
    return (
      <div
        className={style}
        dangerouslySetInnerHTML={{ __html: prefix + sentenceHTML }}
      />
    );
  };

  ScriptLineView = ({
    element,
    prefix,
  }: {
    element: Structural;
    prefix: string;
  }) => {
    const config = this.lineViewerConfigurations[element.kind];
    // TODO move styling to App.css??
    return (
      <div className={config.style}>
        <span
          className="markdown-prefix"
          style={{ verticalAlign: 'top', userSelect: 'none' }}
        >
          {prefix}
        </span>
        <span className="line-content">{element.content.text}</span>
      </div>
    );
  };

  ScriptElement = ({
    element,
    onClick,
  }: {
    element: Element;
    onClick: any;
  }) => {
    const SentenceView = this.SentenceView;
    const ScriptLineView = this.ScriptLineView;
    const membershipListsRenderer = this.membershipListsReconciler;
    const kind = element.kind;
    const config = this.lineViewerConfigurations[kind];
    const prefixing = config.prefix;
    const prefix: string =
      typeof prefixing === 'function' ? prefixing(element) : prefixing;
    let LineComponent = (
      element.kind === 'SENTENCE' ? SentenceView : ScriptLineView
    ) as any;
    const renderedStyles =
      'script-line ' +
      membershipListsRenderer.getJoinedMembershipStringForElement(element.id);
    return (
      <div
        className={renderedStyles}
        id={elementIdToDomId(null, element.id)}
        onClick={onClick}
        prefix={prefix}
      >
        <LineComponent element={element as any} prefix={prefix} />
      </div>
    );
  };

  lineViewerConfigurations: { [index in EKind]?: any } = {
    [EKind.CHAPTER]: {
      // prefix: '# ',
      prefix: '',
      style: 'chapter-title',
    },
    // [EKind.CHAPTER_SUMMARY]: {
    //   prefix: '%% ',
    //   style: 'chapter-summary',
    // },
    [EKind.CHAPTER_NOTE]: {
      prefix: '% ',
      style: 'cultural-note',
    },
    [EKind.PASSAGE]: {
      // prefix: '## ',
      prefix: '',
      style: 'passage-hint',
    },
    [EKind.PARAGRAPH]: {
      // prefix: (element: Structural) => (isEmpty(element.content.text) ? '###' : '@'),
      prefix: '@',
      style: 'speaker-label',
    },
    [EKind.SENTENCE]: {
      prefix: (element: Sentence) => {
        const sentenceNum = this.model.sentences.getIndex(element.id) + 1;
        return `[${sentenceNum}] `;
      },
      style: 'chaat-sentence',
    },
    [EKind.CHAPTER_COMPLETE]: {
      prefix: '//! CHAPTER-COMPLETE',
      containerClassName: 'chapter-complete',
    },
  };

  render() {
    const ScriptElement = this.ScriptElement;
    const model = this.model;
    if (appRoot.mode === 'tricky') {
      // TODO hacking
      const touch = model.stateVersion;
    }
    let elements = model.elements?.values;
    elements = elements || [];

    const handleLineClick = (event: MouseEvent, id: string) => {
      if (event.target instanceof HTMLElement) {
        if (event.target.id) {
          this.handleLineClick(event, event.target.id);
        } else {
          this.handleLineClick(event, id);
        }
      }
    };

    return (
      <div className="script">
        {elements.map(el => (
          <ScriptElement
            key={versionedElementKey(el as VersionableElement)}
            element={el}
            onClick={(e: MouseEvent) =>
              handleLineClick(e, elementIdToDomId(null, el.id))
            }
          />
        ))}
      </div>
    );
  }
}
