import { LocaleCode } from '@utils/util-types';
import {
  CreateElementList,
  Element,
  ElementList,
  MetadataBlock,
} from '../../editor-aliases';
import { runInAction } from 'mobx';
import { EpisodeDataBase, metadataOrder } from '../episode-data/episode-data';
import { sortElements } from '../../element-sort';
import { FirestoreLoader } from '../../firestore-db/firestore-loader';
import { ChaatDocSet } from './chaat-doc-set';
import { ScriptDocSet } from './script-doc-set';
import { numberProjectionSort } from '../../utils';

export async function loadScriptEpisodeData(
  episodeKey: string,
  locale?: LocaleCode
) {
  // TODO need to call closeEpisode on these loaders to release resources??
  const loader = new FirestoreLoader(ScriptDocSet);
  loader.loadEpisode(episodeKey, false);
  const loaded = loader.promiseWhenComplete();

  const doneLoading = await loaded;
  const episodeData = new EpisodeDataBase();
  episodeData.locale = locale;
  runInAction(() => {
    episodeData.episodeKey = episodeKey;
    loader.docSet.copyTo(episodeData);
  });
  return episodeData;
}

export function basicScriptElementsFromEpisodeData(
  episodeData: EpisodeDataBase
): ElementList<Element> {
  const contentElements: Element[] = [];
  contentElements.push(...episodeData.sentences);
  contentElements.push(...episodeData.structural);
  contentElements.push(...episodeData.wordGroups);
  sortElements(contentElements);
  const metadataToOrderFunc = (m: MetadataBlock) =>
    metadataOrder.keyToPrecedenceLevel[m.subKind];
  const metadata = [...episodeData.metadataBlocks];
  numberProjectionSort(metadata, metadataToOrderFunc);
  const result = [...metadata, ...contentElements] as Element[];

  return CreateElementList({
    elements: result,
    words: episodeData.words,
  });
}

export async function loadScriptContent(episodeKey: string) {
  // TODO need to call closeEpisode on these loaders to release resources??
  const episodeData = await loadScriptEpisodeData(episodeKey);
  return basicScriptElementsFromEpisodeData(episodeData);
}

export async function loadChaatEpisodeData(episodeKey: string) {
  const loader = new FirestoreLoader(ChaatDocSet);
  loader.loadEpisode(episodeKey, false);
  const loaded = loader.promiseWhenComplete();

  const doneLoading = await loaded;
  const episodeData = new EpisodeDataBase();
  runInAction(() => {
    episodeData.episodeKey = episodeKey;
    loader.docSet.copyTo(episodeData);
  });
  return episodeData;
}

// // TODO move, and deal with platform functions that need alternate implemenations in node vs browser better
// [<ImportMember("./loader-funcs.js")>]
// import fetch from 'node-fetch';
