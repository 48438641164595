import { FeaturedReleaseData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import {
  Checkbox,
  FormButtons,
  Input,
  Select,
  Textarea,
} from '../../components/forms';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';

interface Props {
  dto: FeaturedReleaseData;
  onSubmit: (dto: FeaturedReleaseData) => void;
  onCancel: () => void;
}

export const FeaturedReleaseForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'channelId',
    'title',
    'slug',
    'illustrationUrl',
    'article',
    'preview',
    'storySlugs',
    'releaseDate',
    'accessibleDate',
    'active',
  ]);

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);
  const channelOptions = ChannelManager.getInstance().selectOptionsWithBlank;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select
        {...getFieldProps('channelId')}
        options={channelOptions}
        required={false}
      />
      <Input {...getFieldProps('title')} label={'Title'} required />
      <Input {...getFieldProps('slug')} label={'Slug'} required />
      <Input {...getFieldProps('illustrationUrl')} label={'Illustration URL'} />
      <Textarea
        {...getFieldProps('article')}
        label={'Article text'}
        hint={'You can use **bold** _italics_'}
        rows={6}
      />
      <Textarea
        {...getFieldProps('preview')}
        label={'Preview article text shown on dashboard'}
        hint={
          'You can use **bold** _italics_. Will default to first paragraph of article if text not explicitly provided here.'
        }
        rows={3}
      />
      <Input
        {...getFieldProps('storySlugs')}
        label={'Story slugs'}
        hint={'comma separated'}
      />
      <Input
        {...getFieldProps('releaseDate')}
        validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
        hint="yyyy-mm-dd - drives displayed month in UI"
        required
      />
      <Input
        {...getFieldProps('accessibleDate')}
        validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
        hint="yyyy-mm-dd - drives when visible to customers"
        required
      />
      <Checkbox {...getFieldProps('active')} label="Active"></Checkbox>
      <FormButtons
        submitLabel={isNew ? 'Create' : 'Save changes'}
        onCancel={onCancel}
      />
    </form>
  );
};
