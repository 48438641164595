import { autorun, computed, reaction, runInAction } from 'mobx';
import { makeObservable, observable } from 'mobx';
import { ElementId, WordElement } from '@tikka/basic-types';
import {
  EKind,
  isStructuralKind,
  StructuralKindsList,
} from '../../element-kinds';
import { FirestoreLoader } from '../../firestore-db/firestore-loader';
import { VersionsDocSet } from '../db/versions-doc-set';
import {
  getKindFromId,
  getTranslationId,
} from '@tikka/elements/element-id-utils';
import { epochSecondsFloat, numberProjectionSort } from '../../utils';
import {
  generateChangeMessageDataForChangeRecord,
  getChangeRecordFromBaseline,
  MutationActions,
} from '../db/mutation-actions';
import {
  getElementVersionDescriptiveContent,
  getWordGroupJWScriptRepresentation,
} from '../../content-utils';
import { deploymentConfig } from '../../deployment-config';
import { COMPLETE } from '../../firestore-db/firestore-doc-set';
import { VersionsBase } from './versions-base';
import {
  EditorElement,
  EditorElementList,
  ElementVersionData,
  Timestamped,
  Translation,
  TranslationVersionData,
  VersionItem,
  WordGroupContent,
  WordGroupVersionData,
} from '../../editorial-types';

export function maxTimestamp(elements: Timestamped[]) {
  let max = 0;
  for (const element of elements) {
    if (element.timestamp > max) {
      max = element.timestamp;
    }
  }
  return max;
}

const TOO_STALE = 30; // seconds

function tooStale(versionsMaxTimestamp: number, contentMaxTimestamp: number) {
  if (epochSecondsFloat() < contentMaxTimestamp + TOO_STALE - 1) {
    return false;
  }
  return versionsMaxTimestamp + TOO_STALE < contentMaxTimestamp;
}

export abstract class VersionsClient extends VersionsBase {
  @observable.ref contentUpdatedTickle = 0;

  disposers: (() => void)[] = [];

  loader = new FirestoreLoader(VersionsDocSet);

  constructor() {
    super();
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.loader.getStateVersion(),
        () => this.versionsUpdated()
      )
    );
    this.disposers.push(autorun(() => this.updateVersionsIfTooStale()));
    this.disposers.push(autorun(() => this.updateContentTickle()));
  }

  setBaselineTimestamp(timestamp: number = null) {
    if (!timestamp) {
      timestamp = epochSecondsFloat();
    }
    this.baselineTimestamp = timestamp;
  }

  @computed
  get maxSentenceVersionTimestamp() {
    return maxTimestamp(this.sentenceVersionsData);
  }

  @computed
  get maxWordGroupVersionTimestamp() {
    return maxTimestamp(this.wordGroupVersionsData);
  }

  @computed
  get maxStructuralVersionTimestamp() {
    return maxTimestamp(this.structuralVersionsData);
  }

  @computed
  get maxTranslationVersionTimestamp() {
    return maxTimestamp(this.translationVersionsData);
  }

  // TODO make these actually run separately, now will all run on every modification because activeContent changes
  @computed
  get maxSentenceTimestamp() {
    return maxTimestamp(this.activeContent.filterByKind(EKind.SENTENCE).values);
  }

  @computed
  get maxWordGroupTimestamp() {
    return maxTimestamp(
      this.activeContent.filterByKind(EKind.WORD_GROUP).values
    );
  }

  @computed
  get maxStructuralTimestamp() {
    return maxTimestamp(
      this.activeContent.filterByKinds(StructuralKindsList).values
    );
  }

  @computed
  get maxTranslationTimestamp() {
    return maxTimestamp(
      this.activeContent.filterByKind(EKind.TRANSLATION).values as Translation[]
    );
  }

  get sentenceVersionsTooStale() {
    return tooStale(
      this.maxSentenceVersionTimestamp,
      this.maxSentenceTimestamp
    );
  }

  get wordGroupVersionsTooStale() {
    return tooStale(
      this.maxWordGroupVersionTimestamp,
      this.maxWordGroupTimestamp
    );
  }

  get structuralVersionsTooStale() {
    return tooStale(
      this.maxStructuralVersionTimestamp,
      this.maxStructuralTimestamp
    );
  }

  get translationVersionsTooStale() {
    return tooStale(
      this.maxTranslationVersionTimestamp,
      this.maxTranslationTimestamp
    );
  }

  updateVersionsIfTooStale() {
    if (!this.versionsLoaded) {
      return;
    }
    const touch = this.contentUpdatedTickle;
    const key = this.episodeKey;
    if (this.sentenceVersionsTooStale) {
      const apiUrl = `${deploymentConfig.masalaServerUrl}/update_sentence_versions?key=${key}`;
      fetch(apiUrl);
    }
    if (this.wordGroupVersionsTooStale) {
      const apiUrl = `${deploymentConfig.masalaServerUrl}/update_word_group_versions?key=${key}`;
      fetch(apiUrl);
    }
    if (this.structuralVersionsTooStale) {
      const apiUrl = `${deploymentConfig.masalaServerUrl}/update_structural_versions?key=${key}`;
      fetch(apiUrl);
    }
    if (this.translationVersionsTooStale) {
      const apiUrl = `${deploymentConfig.masalaServerUrl}/update_translation_versions?key=${key}`;
      fetch(apiUrl);
    }
  }

  updateContentTickle() {
    const tickleValue = Math.max(
      this.maxSentenceTimestamp,
      this.maxStructuralTimestamp,
      this.maxWordGroupTimestamp,
      this.maxTranslationTimestamp
    );

    setTimeout(
      () => (this.contentUpdatedTickle = tickleValue),
      (TOO_STALE + 5) * 1000
    );
  }

  lazyLoadVersions(episodeKey: string) {
    setTimeout(() => this.loader.loadEpisode(episodeKey, true), 1500);
  }

  getVersions(id: ElementId): VersionItem[] {
    const kind = getKindFromId(id);

    const createElementVersionItems = (vdata: ElementVersionData[]) => {
      vdata = vdata.filter((el: ElementVersionData) => el.id === id);
      numberProjectionSort(vdata, el => -el.timestamp);

      if (kind === EKind.WORD_GROUP) {
        const translationId = getTranslationId(id, this.l1locale);
        const wordGroupNoteVersions = this.translationVersionsData.filter(
          (el: ElementVersionData) => el.id === translationId
        );
        const result: VersionItem[] = [];
        for (const groupData of vdata as WordGroupVersionData[]) {
          const matchingNoteTranslation = wordGroupNoteVersions.find(
            note => note.timestamp === groupData.timestamp
          ) as TranslationVersionData;
          const note = (matchingNoteTranslation?.content as WordGroupContent)
            ?.note;
          const versionData: ElementVersionData[] = [groupData];
          if (matchingNoteTranslation) {
            versionData.push(matchingNoteTranslation);
          }
          result.push({
            content: getWordGroupJWScriptRepresentation(
              groupData,
              this.words,
              note
            ),
            author: groupData.author,
            timestamp: groupData.timestamp,
            versionData,
          });
        }
        return result;
      } else {
        return vdata.map(data => {
          return {
            content: getElementVersionDescriptiveContent(data),
            author: data.author,
            timestamp: data.timestamp,
            versionData: data,
          };
        });
      }
    };

    if (kind === EKind.SENTENCE) {
      return createElementVersionItems(this.sentenceVersionsData);
    } else if (kind === EKind.WORD_GROUP) {
      return createElementVersionItems(this.wordGroupVersionsData);
    } else if (kind === EKind.TRANSLATION) {
      return createElementVersionItems(this.translationVersionsData);
    } else {
      return createElementVersionItems(this.structuralVersionsData);
    }
  }

  getChangeRecords(id: ElementId) {
    const kind = getKindFromId(id);
    const getChangeRecordsWithVersionsData = (
      id: ElementId,
      vdata: ElementVersionData[]
    ) => {
      vdata = vdata.filter((el: ElementVersionData) => el.id === id);
      numberProjectionSort(vdata, el => el.timestamp);
      return vdata
        .map(version => this.getChangeRecord(version))
        .filter(record => record);
    };

    if (kind === EKind.SENTENCE) {
      return getChangeRecordsWithVersionsData(id, this.sentenceVersionsData);
    } else if (kind === EKind.WORD_GROUP) {
      const records = getChangeRecordsWithVersionsData(
        id,
        this.wordGroupVersionsData
      );
      records.push(
        ...getChangeRecordsWithVersionsData(
          getTranslationId(id, this.l1locale),
          this.translationVersionsData
        )
      );
      numberProjectionSort(records, record => record.version.timestamp);
      return records;
    } else if (kind === EKind.TRANSLATION) {
      return getChangeRecordsWithVersionsData(id, this.translationVersionsData);
    } else {
      return getChangeRecordsWithVersionsData(id, this.structuralVersionsData);
    }
  }

  getChangeRecordsFromBaseline(id: ElementId) {
    if (!this.baselineTimestamp) {
      return [];
    }
    const kind = getKindFromId(id);
    let activeVersions: ElementVersionData[] = [];
    if (kind === EKind.SENTENCE) {
      activeVersions = [this.sentenceActiveVersionsMap.get(id)];
    }
    if (isStructuralKind(kind)) {
      activeVersions = [this.structuralActiveVersionsMap.get(id)];
    }
    if (kind === EKind.WORD_GROUP) {
      activeVersions = [this.wordGroupActiveVersionsMap.get(id)];
      const noteActiveVersion = this.translationActiveVersionsMap.get(
        getTranslationId(id, this.l1locale)
      );
      if (noteActiveVersion) {
        activeVersions.push(noteActiveVersion);
      }
    }
    if (kind === EKind.TRANSLATION) {
      activeVersions = [this.translationActiveVersionsMap.get(id)];
    }
    activeVersions = activeVersions.filter(version => version);
    return activeVersions
      .map(version =>
        getChangeRecordFromBaseline(version, this.baselineVersions)
      )
      .filter(record => record);
  }

  getChangeMessagesFromBaseline(id: ElementId) {
    return this.getChangeRecordsFromBaseline(id)
      .map(record => generateChangeMessageDataForChangeRecord(record))
      .filter(item => item);
  }

  getChangeMessages(id: ElementId) {
    return this.getChangeRecords(id)
      .map(record => generateChangeMessageDataForChangeRecord(record))
      .filter(item => item);
  }

  versionsUpdated() {
    console.log('versions updated: ' + this.loader.getStatus());
    if (this.loader.getStatus() === 'COMPLETE') {
      console.log('COMPLETE: ' + this.loader.key);
      runInAction(() => {
        const docSet = this.loader.docSet;
        this.sentenceVersions = docSet.docs.sentenceVersionsDoc.items;
        this.sentenceActiveVersionIdMap =
          docSet.docs.sentenceVersionsDoc.activeVersionIdMap;
        this.wordGroupVersions = docSet.docs.wordGroupVersionsDoc.items;
        this.wordGroupActiveVersionIdMap =
          docSet.docs.wordGroupVersionsDoc.activeVersionIdMap;
        this.structuralVersions = docSet.docs.structuralVersionsDoc.items;
        this.structuralActiveVersionIdMap =
          docSet.docs.structuralVersionsDoc.activeVersionIdMap;
        this.translationVersions = docSet.docs.translationVersionsDoc.items;
        this.translationActiveVersionIdMap =
          docSet.docs.translationVersionsDoc.activeVersionIdMap;
      });
    }
  }

  get versionsLoaded() {
    return (
      this.loader.getStatus() === COMPLETE &&
      this.loader.key === this.episodeKey
    );
  }

  abstract get mutationActions(): MutationActions;
  abstract get activeContent(): EditorElementList<EditorElement>;
  abstract get episodeKey(): string;
  abstract get words(): EditorElementList<WordElement>;
  abstract get l1locale(): string;
}
