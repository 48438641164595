import * as React from 'react';
import styled from 'styled-components';
import { Modal } from './modal';

const Wrapper = styled.div`
  display: grid;
  background: var(--color-white);
  max-height: calc(100vh - 144px);
  box-shadow: 0 0 12px #00000030, 0 6px 6px #00000015;
  grid-template-rows: 48px 1fr;
  margin-top: -96px;
  border-radius: 8px;

  & > .lightbox-header {
    grid-row: 1/2;
    background: var(--color-gray50);
    border-radius: 8px 8px 0 0;
    height: 48px;
    padding: 0 24px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h3 {
      margin: 0;
      padding: 0;
      font-family: roboto;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      user-select: none;
    }
  }
  & > .lightbox-content {
    grid-row: 2/3;
    overflow: auto;
  }
`;

interface LightboxProps {
  children: React.ReactNode;
  showing: boolean;
  title?: string;
  onDismiss?: () => void;
}

export const Lightbox = ({
  children,
  showing = false,
  title,
  onDismiss = () => {},
}: LightboxProps) => {
  const handleDismiss = () => {
    onDismiss();
  };

  if (showing === false) {
    return null;
  }

  return (
    <Modal onClickOutside={handleDismiss} onEscape={handleDismiss}>
      <Wrapper>
        <header className="lightbox-header">
          {title ? <h3>{title}</h3> : null}
        </header>
        <div className="lightbox-content">{children}</div>
      </Wrapper>
    </Modal>
  );
};
