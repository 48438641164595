import { makeObservable } from 'mobx';
import { createLogger } from '@app/logger';
import { SamosaModel } from 'samosa/models/samosa-model';
import { alertError } from 'ui/misc-utils';
import { showLlmPickerDialog } from '../editing-action-bar/llm-picker-dialog';
import { getSamosaModel } from 'samosa/models/samosa-model-handle';

// import { useKeyboardBinding } from '@console/lib/hooks/use-keyboard-binding';

const log = createLogger('prompt-entry-model');

export class PromptEntryModel {
  private static _instance: PromptEntryModel | null = null;

  public promptText = '';

  public showSettings: boolean = false;

  public promptAreaId = 'prompt-area';

  constructor() {
    makeObservable(this, {
      promptText: true,
      showSettings: true,
    });
  }

  setPromptText(prompt: string) {
    this.promptText = prompt;
  }

  appendToPromptText(newPromptText: string) {
    this.promptText = [this.promptText, newPromptText].join('\n\n');
  }

  get emptyPrompt() {
    return !this.promptText || this.promptText.trim().length === 0;
  }

  get promptAreaNode() {
    return document.getElementById(this.promptAreaId);
  }

  focusPromptArea() {
    this.setShowSettings(false);
    const promptArea = this.promptAreaNode;
    if (promptArea) {
      // if we focus imemdiately we will reace against other focus related things, most of the time
      // so a small delay here is needed and not really noticeable
      setTimeout(() => {
        promptArea.focus();
        // scroll area to bottom
        promptArea.scrollTop = promptArea.scrollHeight;
      }, 100);
    }
  }

  submitToModel = (model: SamosaModel, startNew: boolean) => {
    const priorStep = startNew ? model.referenceScript : model.currentStep;

    if (this.emptyPrompt) {
      log.warn('Prompt cannot be empty');
      throw new Error('Prompt cannot be empty');
    }

    const promptText = this.promptText;

    this.setPromptText('');

    model
      .sendMessage(promptText, priorStep, null, null, model.streamingDisabled)
      .catch(error => {
        alertError(error);
      });
  };

  setShowSettings(show: boolean) {
    this.showSettings = show;
  }

  toggleShowSettings() {
    this.showSettings = !this.showSettings;
  }

  showLlmPicker() {
    this.setShowSettings(true);
    showLlmPickerDialog();
  }

  increaseTemperature() {
    this.setShowSettings(true);
    getSamosaModel().increaseTemperature();
  }

  decreaseTemperature() {
    this.setShowSettings(true);
    getSamosaModel().decreaseTemperature();
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new PromptEntryModel();
    }
    return this._instance;
  }

  static destroyInstance() {
    this._instance = null;
  }
}

(window as any).PromptEntryModel = PromptEntryModel;
