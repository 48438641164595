import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { JobsListScreen } from './jobs-list-screen';
import { JobDetailScreen } from './job-detail-screen';

export const JobsScreen = () => {
  return (
    <ModelScreen
      path={'/jobs'}
      renderList={() => <JobsListScreen />}
      renderDetail={controller => (
        <JobDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller =>
        /*<AuthorEditScreen stopEditing={controller.stopEditing} />*/ null
      }
      renderCreateForm={_ => /*<AuthorCreateScreen />*/ null}
    />
  );
};
