import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ElementList, Element } from '@masala-lib/editor-aliases';
import { ConversationView } from './conversation-view';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import { LintWarningItem } from './lint-warning';
import { scriptEditorModel } from '../models/app-root';
import { getGlobalExcerptId } from '@tikka/elements/element-id-utils';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { A } from '@masala-lib/editorial/ui/action-link';
import { Excerpt, Translation } from '@masala-lib/editorial-types';

interface Props {
  l1locale: string;
  onMasterLanguageFork: boolean;
  translations: { [index: string]: Translation };
  element: Excerpt;
  content: ElementList<Element>;
  warnings: LintWarning[];
}

@observer
export class ExcerptEditor extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get excerpt(): Excerpt {
    return this.props.element;
  }

  @computed
  get globalExcerptId() {
    return getGlobalExcerptId(scriptEditorModel.episodeKey, this.excerpt.id);
  }

  get lintWarnings(): LintWarning[] {
    return this.props.warnings;
  }

  render() {
    const element = this.excerpt;
    const warnings = this.lintWarnings;
    const excerptUrl = `${deploymentConfig.consoleUrl}/excerpts/${this.globalExcerptId}`;

    return (
      <>
        <div className="right-sidepanel-header">Excerpt Editor </div>
        {warnings
          ? warnings.map(warning => <LintWarningItem warning={warning} />)
          : null}
        <div className="excerpt-editor">
          <div className="excerpt-link">
            <A href={excerptUrl}>[Edit Excerpt ^]</A>
          </div>
          <ConversationView element={element} />
        </div>
      </>
    );
  }
}
