import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { AuthorData } from '@masala-lib/catalog/catalog-types';
import { AuthorForm } from './author-form';
import { Author } from '@masala-lib/catalog/models/author';
import { AuthorManager } from '@masala-lib/catalog/db/author-manager';
import { useNavigate } from 'react-router-dom';

export const AuthorCreateScreen = observer(() => {
  const authorManager = AuthorManager.getInstance();
  const navigate = useNavigate();
  const author = new Author();

  const goBack = () => {
    navigate(`/authors`);
  };

  const onSubmit = (dto: AuthorData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    author.fromFormData(dto);
    authorManager.create(author).then(goBack);
  };

  return (
    <Page title={'Creating new author'}>
      <AuthorForm
        dto={author.toFormData()}
        onSubmit={onSubmit}
        onCancel={goBack}
      />
    </Page>
  );
});
