import { computed, makeObservable } from 'mobx';
import { EpisodeDataBase } from '../../episode-data/episode-data';
import {
  AudioRegionsSpan,
  ContentSpan,
  CuesSpan,
  interleavedToStartsEnds,
  reconcileAudioIntervals,
  startsAndEndsToInterleaved,
  TranscriptSpan,
} from './core/spans';
import { strongNormalizeWordArray } from '../../../misc/editorial-string-utils';
import { unionIntervals } from './core/util_ts';

export class TimestamperEpisodeData {
  episodeData: EpisodeDataBase;
  startTime = 0;
  endTime = 0;

  constructor(episodeData0: EpisodeDataBase) {
    this.episodeData = episodeData0;
    const notchEnds = this.episodeData.notchTimeIntervals.endPoints;
    this.endTime = notchEnds[notchEnds.length - 1];
    makeObservable(this);
  }

  @computed({ keepAlive: true })
  get contentSpan(): ContentSpan {
    const wordElements = this.episodeData.words.values;
    const originalWords: string[] = wordElements.map(el => el['text']);
    const normalizedWords = strongNormalizeWordArray(originalWords);
    const globalPositions = [...normalizedWords.keys()];

    return {
      data: {
        word: normalizedWords,
        globalPosition: globalPositions,
        originalWord: originalWords,
      },
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }

  get cuesSpan(): CuesSpan {
    const words = this.episodeData.words;
    const cues = this.episodeData.chaatInputCues;
    const cueTimestamps = cues.map(cue => cue.timestamp);
    const cuePositions = cues.map(cue => words.getIndex(cue.wordId));
    return {
      data: {
        globalPosition: cuePositions,
        timestamp: cueTimestamps,
      },
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }

  @computed({ keepAlive: true })
  get audioRegionsSpan(): AudioRegionsSpan {
    const regionIntervals = this.episodeData.nonVoiceAudioRegionIntervals;
    const regionStarts = regionIntervals.startPoints;
    const regionEnds = regionIntervals.endPoints;
    const silenceIntervals = this.episodeData.silenceTimeIntervals;
    const silenceStarts = silenceIntervals.startPoints;
    const silenceEnds = silenceIntervals.endPoints;
    const [mergedStarts, mergedEnds] = unionIntervals(
      silenceStarts,
      silenceEnds,
      regionStarts,
      regionEnds
    );
    const timestamps = startsAndEndsToInterleaved(
      mergedStarts,
      mergedEnds,
      false,
      0
    );
    // TODO can this be different from mergedStarts/Ends??
    const noVoiceStarts: number[] = [];
    for (let i = 0; i < timestamps.length; i = i + 2) {
      noVoiceStarts.push(timestamps[i]);
    }
    const noVoiceEnds: number[] = [];
    for (let i = 1; i < timestamps.length; i = i + 2) {
      noVoiceEnds.push(timestamps[i]);
    }

    return {
      data: {
        timestamp: timestamps,
        silenceStartTime: noVoiceStarts,
        silenceEndTime: noVoiceEnds,
      },
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }

  @computed({ keepAlive: true })
  get transcriptSpan(): TranscriptSpan {
    const originalWords = this.episodeData.transcriptWords; // TODO this is actually not the raw transcript words need to save raw data
    const normalizedWords = strongNormalizeWordArray(originalWords);
    const rawTranscriptIntervals = this.episodeData.transcriptWordTimeIntervals; // TODO this is actually not raw need to save raw!!!
    const rawTranscriptStarts = rawTranscriptIntervals.startPoints;
    const rawTranscriptEnds = rawTranscriptIntervals.endPoints;

    // TODO feel this is wrong result format does not include 0 and endTime???
    const transcriptInterleavedTimes = startsAndEndsToInterleaved(
      rawTranscriptStarts,
      rawTranscriptEnds,
      true,
      this.endTime
    );

    // TODO to duplicating existing logic applying reconcile for silence and regions in succesion, don't know if makes any difference
    const regionIntervals = this.episodeData.nonVoiceAudioRegionIntervals;
    const regionStarts = regionIntervals.startPoints;
    const regionEnds = regionIntervals.endPoints;
    const notchIntervals = this.episodeData.notchTimeIntervals;
    const notchStarts = notchIntervals.startPoints;
    const notchEnds = notchIntervals.endPoints;

    const conflict = (idx: number) => !!(idx % 2);
    reconcileAudioIntervals(
      transcriptInterleavedTimes,
      notchStarts,
      notchEnds,
      conflict
    );
    reconcileAudioIntervals(
      transcriptInterleavedTimes,
      regionStarts,
      regionEnds,
      conflict
    );
    const [adjustedStarts, adjustedEnds] = interleavedToStartsEnds(
      transcriptInterleavedTimes,
      true
    );

    return {
      data: {
        word: normalizedWords,
        originalWord: originalWords,
        startTime: adjustedStarts,
        endTime: adjustedEnds,
      },
      startTime: this.startTime,
      endTime: this.endTime,
    };
  }
}
