import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { useUnit } from './use-unit';
import { InfoV5Data } from '@masala-lib/catalog/catalog-types';
import { InfoV5Form } from './info-v5-form';
import { capitalize } from 'lodash';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { useNavigate } from 'react-router-dom';

export const UnitInfoEditScreen = observer(() => {
  const unit = useUnit();
  const navigate = useNavigate();

  if (!unit) {
    return null;
  }

  const formData = unit.data.infoV5;

  const goBack = () => {
    navigate(unit.navPath);
  };

  const onSubmit = (dto: InfoV5Data) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    unit.updatePartial({ infoV5: dto }).then(goBack);
    consoleAddAuditLogMessage(unit.episodeKey, `updateUnitInfo`);
  };
  // const flavor = capitalize(unit.data.flavor);

  const handleMoveToVolume = () => {
    if (
      window.confirm(
        'This will overwrite existing Volume level info with the data from this screen and clear that data from the Unit level.\nAre you sure?'
      )
    ) {
      unit.moveInfoToVolume().then(goBack);
    }
  };

  return (
    <Page
      // title={`Editing ${unit.name} - Unit level Info (${flavor} flavor)`}
      title={`Editing ${unit.name} - Unit level Info`}
      onClose={goBack}
    >
      <InfoV5Form
        dto={formData}
        onSubmit={onSubmit}
        cancelNavPath={unit.navPath}
        entity={unit}
        l2Locale={unit.data.l2Code}
        l1Locale={unit.l1Default}
      />
      <hr />
      <ActionLink onPress={handleMoveToVolume}>
        [Move the Unit level info to the Volume level]
      </ActionLink>
    </Page>
  );
});
