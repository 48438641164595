import styled from 'styled-components';
import { ChapterNotes } from './chapter-notes';
import { ChapterHeader } from './chapter-header';
import { ChapterElements } from './chapter-elements';

export const Chapter = ({ chapter }: { chapter: any }) => {
  return (
    <>
      <ChapterHeader chapter={chapter} />
      <ChapterNotes chapter={chapter} />
      <ChapterElements chapter={chapter} />
    </>
  );
};
