import * as React from 'react';
import styled from 'styled-components';
import { useKeyboardBinding } from '../../lib/hooks/use-keyboard-binding';

import { Portal } from './portal';

const Wrapper = styled.div`
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;

  & > .modal-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    & > .outside {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
    }

    & > .inside {
      position: relative;
      z-index: 1;
    }
  }
`;

interface ModalProps {
  children: React.ReactNode;
  onEscape?: () => void;
  onClickOutside?: () => void;
}

export const Modal = ({
  children,
  onEscape = () => {},
  onClickOutside = () => {},
}: ModalProps) => {
  useKeyboardBinding('ESCAPE', onEscape);
  // this adds a special class to the body that would prevent it scrolling while the modal is being shown
  React.useLayoutEffect(() => {
    document.body.classList.add('__no-scroll');
    return () => {
      document.body.classList.remove('__no-scroll');
    };
  });
  return (
    <Portal>
      <Wrapper className="__modal-container modal-outer">
        <div className="modal-inner">
          <div className="outside" onClick={onClickOutside} />
          <div className="inside">{children}</div>
        </div>
      </Wrapper>
    </Portal>
  );
};
