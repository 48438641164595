import React from 'react';
import { observer } from 'mobx-react';
import { ConsoleLayout } from '../../console/views/console-layout';
import { useEffect } from 'react';
import { initFirestoreCollections } from '@masala-lib/firestore-db/init-collections';

// boot strap the firestore collections and populate and admin user
// currently the app.tsx must be temporarily edited to remove the authenticated user guard
// todo: sort out a better bootstrap solution, probably from the masala-server repo

export const InitFirestoreScreen = observer(() => {
  useEffect(() => {
    initFirestoreCollections();
  }, []);

  return (
    <ConsoleLayout title="Firebase Init">
      <h3>...</h3>
    </ConsoleLayout>
  );
});
