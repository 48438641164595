import { makeObservable, reaction, observable } from 'mobx';
import { chaatToolModel } from '../models/app-root';
import { TrackWidget } from '@masala-lib/editorial/chaat/ui/track-area/track-widget';
import { AudioPositionsTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/audio-positions-gadget';
import { NavigationTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/navigation-gadget';
import { WordsTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/words-gadget';
import { WaveformTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/waveform-gadget';
import { AudioRegionSelectionTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/audio-region-selection-gadget';
import * as colors from './colors';

import {
  player,
  navigationState,
  transportState,
  appBus,
} from '../models/app-root';
import { TransportState } from '@tikka/player/audio-transport';
// import { openAudioRegionLabelDialog } from "../audio-region-label-dialog";
import { intervalToMinSecMsDurationString } from '@masala-lib/misc/timestamp-formats';
import { getWaveformWithCache } from '@masala-lib/editorial/chaat/waveform-data';
import { QuantaTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/quanta-track-gadget';
import { TrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/track-gadget';

export class TrickyMainTrackWidget extends TrackWidget {
  audioPositionGadget: AudioPositionsTrackGadget = null;
  waveformGadget: WaveformTrackGadget = null;
  wordsGadget: WordsTrackGadget = null;
  uncertaintiesGadget: QuantaTrackGadget = null;
  linearInterpolationsGadget: QuantaTrackGadget = null;
  quantaMS = 25; // TODO
  charsPaceGadget: QuantaTrackGadget = null;
  wordsPaceGadget: QuantaTrackGadget = null;
  computedQuantaGadget: QuantaTrackGadget = null;
  userQuantaGadget: QuantaTrackGadget = null;
  finalQuantaGadget: QuantaTrackGadget = null;
  existingQuantaGadget: QuantaTrackGadget = null;
  audioRegionSelectionGadget: AudioRegionSelectionTrackGadget = null;
  episodeKey = '';
  @observable.ref waveformData: number[] = [];
  showWordsGadget = true; // TODO

  constructor() {
    super(transportState, appBus);
    makeObservable(this);
  }

  createGadgets() {
    this.audioPositionGadget = new AudioPositionsTrackGadget();
    this.audioPositionGadget.setTransportState(transportState);
    this.audioPositionGadget.cursorWidth = 25;
    this.audioPositionGadget.cursorFillStyle = colors.teal;
    this.gadgets.push(this.audioPositionGadget);

    if (this.showWordsGadget) {
      this.wordsGadget = new WordsTrackGadget();
      this.wordsGadget.handleFillStyle = colors.white;
      this.wordsGadget.rolloverHandleFillStyle = colors.lightYellow;
      this.wordsGadget.font = '300 30px Arial';
      this.gadgets.push(this.wordsGadget);
    }

    this.uncertaintiesGadget = new QuantaTrackGadget();
    this.uncertaintiesGadget.color = colors.darkYellow;
    this.uncertaintiesGadget.highlightColor = colors.lightOrange;
    this.gadgets.push(this.uncertaintiesGadget);

    // this.linearInterpolationsGadget = new QuantaTrackGadget();
    // this.linearInterpolationsGadget.color = colors.darkTeal;
    // this.gadgets.push(this.linearInterpolationsGadget);

    this.charsPaceGadget = new QuantaTrackGadget();
    this.charsPaceGadget.color = colors.darkTeal;
    this.charsPaceGadget.highlightColor = colors.lightOrange;
    this.gadgets.push(this.charsPaceGadget);

    this.wordsPaceGadget = new QuantaTrackGadget();
    this.wordsPaceGadget.color = colors.darkGreen;
    this.wordsPaceGadget.highlightColor = colors.lightOrange;
    this.gadgets.push(this.wordsPaceGadget);

    this.computedQuantaGadget = new QuantaTrackGadget();
    this.computedQuantaGadget.color = colors.lightOrange;
    this.gadgets.push(this.computedQuantaGadget);

    this.userQuantaGadget = new QuantaTrackGadget();
    this.userQuantaGadget.color = colors.green;
    this.userQuantaGadget.color2 = colors.darkRed;
    this.gadgets.push(this.userQuantaGadget);

    this.finalQuantaGadget = new QuantaTrackGadget();
    this.finalQuantaGadget.color = colors.darkRed;
    this.gadgets.push(this.finalQuantaGadget);

    this.existingQuantaGadget = new QuantaTrackGadget();
    this.existingQuantaGadget.color = colors.yellow;
    this.gadgets.push(this.existingQuantaGadget);

    this.waveformGadget = new WaveformTrackGadget();
    this.waveformGadget.fillStyle = colors.darkBlue;
    this.gadgets.push(this.waveformGadget);

    this.audioRegionSelectionGadget = new AudioRegionSelectionTrackGadget();
    this.audioRegionSelectionGadget.handleFillStyle = '#00995560';
    this.audioRegionSelectionGadget.setTransportState(transportState);
    this.audioRegionSelectionGadget.doesRollover = false;
    this.gadgets.push(this.audioRegionSelectionGadget);

    // TODO implement this as autorun??
    // TODO disposers
    reaction(
      () => chaatToolModel.stateVersion,
      () => this.initGadgetsData(),
      { fireImmediately: true }
    );
    //appBus.subscribe('audioRegionEdit', () => this.audioRegionEdit());
    reaction(
      () => this.waveformData,
      () => this.waveformGadget.setWaveformData(this.waveformData),
      { fireImmediately: true }
    );
    appBus.subscribe('deselect', () => this.audioRegionDeselect());
  }

  layoutGadgetSlices() {
    let totalHeight = 0;
    let height;

    height = 23;
    const audioPositionStartY = totalHeight;
    this.audioPositionGadget.setLayout(totalHeight, height);
    totalHeight += 10; // TODO hacking
    height = 65;
    this.waveformGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.audioPositionGadget.positionLineExtension =
      totalHeight - audioPositionStartY;
    this.audioRegionSelectionGadget.setLayout(
      audioPositionStartY,
      totalHeight - audioPositionStartY
    );
    if (this.showWordsGadget) {
      height = 40;
      this.wordsGadget.setLayout(totalHeight, height);
      totalHeight += height;
    }

    const quantaBarsHeight = 25;
    height = quantaBarsHeight;
    this.finalQuantaGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.existingQuantaGadget.setLayout(totalHeight, height);
    totalHeight += height;
    totalHeight += 15;
    this.uncertaintiesGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = quantaBarsHeight;
    this.charsPaceGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = quantaBarsHeight;
    this.wordsPaceGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = quantaBarsHeight;
    // this.linearInterpolationsGadget.setLayout(totalHeight, height);
    // totalHeight += height;
    // height = quantaBarsHeight;
    this.computedQuantaGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = quantaBarsHeight;
    this.userQuantaGadget.setLayout(totalHeight, height);
    totalHeight += height;
  }

  initGadgetsData() {
    const chaat = chaatToolModel;

    const handleTrackSelect = (gadget: TrackGadget, key: string) => {
      chaatToolModel.setQuantaTrackSelection(key);
    };

    const trackSelectionKeySource = () => chaatToolModel.quantaTrackSelection;

    this.quantaMS = chaat.quantaMS;
    if (this.showWordsGadget) {
      this.wordsGadget.setWordsIntervals(
        chaat.wordStrings,
        chaat.wordTimeIntervals
      );
      this.wordsGadget.onNavigationSelect((_, key, select) => {
        player.seek(select.time);
      });
    }

    if (this.episodeKey !== chaat.episodeKey) {
      this.waveformData = [];
      if (chaat.audioUrls.transcribeAudioUrl) {
        getWaveformWithCache(chaat.audioUrls.transcribeAudioUrl).then(
          waveformData => (this.waveformData = waveformData as any)
        );
      }
    }
    // done by reaction this.waveformGadget.setWaveformData(this.waveformData);
    // TODO configure promise to load waveform data here and reaction to set again on waveformGadget
    this.waveformGadget.onTimeSelect(() => {
      const seekTo = this.waveformGadget.lastTimeSelect;
      // TODO factor this logic to player
      if (transportState.isPlaying) {
        player.seek(seekTo, true);
      } else {
        player.seek(seekTo);
      }
    });

    this.audioRegionSelectionGadget.onRegionSelect((_, region) => {
      chaat.setAudioRegionSelection(region); // TODO really
    });

    if (this.showWordsGadget) {
      this.defaultSetupNavigation(this.wordsGadget);
    }
    const quantaMS = this.quantaMS;

    this.uncertaintiesGadget.setQuantaValues(chaat.uncertaintiesQuantas);
    this.uncertaintiesGadget.quantaMS = quantaMS;
    this.uncertaintiesGadget.trackKey = 'UNCERTAINTY'; // TODO typing
    this.uncertaintiesGadget.dimmed =
      !!chaat.quantaTracksDisabledState.get('UNCERTAINTY');
    this.uncertaintiesGadget.highlightCutoff = chaat.trickyCutoffValue;
    this.uncertaintiesGadget.setTrackSelectionSource(trackSelectionKeySource);
    this.uncertaintiesGadget.onTrackSelect(handleTrackSelect);

    // this.linearInterpolationsGadget.setQuantaValues(
    //   chaat.linearInterpolationQuantas
    // );
    // this.linearInterpolationsGadget.quantaMS = quantaMS;
    this.charsPaceGadget.setQuantaValues(chaat.charsPaceQuantas);
    this.charsPaceGadget.quantaMS = quantaMS;
    this.charsPaceGadget.trackKey = 'CHARS_PACE'; // TODO typing
    this.charsPaceGadget.dimmed =
      !!chaat.quantaTracksDisabledState.get('CHARS_PACE');
    this.charsPaceGadget.highlightCutoff = chaat.trickyCutoffValue;
    this.charsPaceGadget.setTrackSelectionSource(trackSelectionKeySource);
    this.charsPaceGadget.onTrackSelect(handleTrackSelect);

    this.wordsPaceGadget.setQuantaValues(chaat.wordsPaceQuantas);
    this.wordsPaceGadget.quantaMS = quantaMS;
    this.wordsPaceGadget.trackKey = 'WORDS_PACE'; // TODO typing
    this.wordsPaceGadget.dimmed =
      !!chaat.quantaTracksDisabledState.get('WORDS_PACE');
    this.wordsPaceGadget.highlightCutoff = chaat.trickyCutoffValue;
    this.wordsPaceGadget.setTrackSelectionSource(trackSelectionKeySource);
    this.wordsPaceGadget.onTrackSelect(handleTrackSelect);

    this.computedQuantaGadget.setQuantaValues(chaat.computedQuantas);
    this.computedQuantaGadget.quantaMS = quantaMS;

    this.userQuantaGadget.setQuantaValues(chaat.userQuantas);
    this.userQuantaGadget.quantaMS = quantaMS;

    this.finalQuantaGadget.setQuantaValues(chaat.finalQuantas);
    this.finalQuantaGadget.quantaMS = quantaMS;

    this.existingQuantaGadget.setQuantaValues(chaat.existingTrickyQuantas);
    this.existingQuantaGadget.quantaMS = quantaMS;
  }

  defaultNavigationReaction(gadget: NavigationTrackGadget) {
    gadget.onNavigationSelect((gadget, key, select) => {
      if (gadget.navigatorKey) {
        player.navigate(key, select.index);
      } else {
        player.seek(select.time);
      }
    });
  }

  defaultSetupNavigation(gadget: NavigationTrackGadget) {
    if (gadget.navigatorKey) {
      gadget.setNavigation(navigationState);
    }
    this.defaultNavigationReaction(gadget);
  }

  drawForegroundLayer() {
    super.drawForegroundLayer();
  }

  doHover() {
    const hoverState = this.lastHoverState;
    const gadget = hoverState.gadget;
    const interval = hoverState.interval;

    const posY = this.waveformGadget.originY;
    let hoverText = null;
    if (gadget === this.charsPaceGadget) {
      hoverText = 'chars pace';
    } else if (gadget === this.wordsPaceGadget) {
      hoverText = 'words pace';
    } else if (gadget === this.uncertaintiesGadget) {
      hoverText = 'transcribe uncertainty';
      // } else if (gadget === this.linearInterpolationsGadget) {
      //   hoverText = 'chaat no match';
    } else if (gadget === this.computedQuantaGadget) {
      hoverText = 'combined';
    } else if (gadget === this.userQuantaGadget) {
      hoverText = 'user override tricky regions';
    } else if (gadget === this.finalQuantaGadget) {
      hoverText = 'output';
    } else if (gadget === this.existingQuantaGadget) {
      hoverText = 'existing tricky regions';
    }

    if (hoverText) {
      this.showHover(gadget, hoverText, { interval, absolute: posY });
    }
  }

  audioRegionDeselect() {
    this.audioRegionSelectionGadget.clearSelection();
  }

  toggleEnabled() {
    super.toggleEnabled();
    if (this.enabled) {
      this.audioPositionGadget.setTransportState(transportState);
    } else {
      this.audioPositionGadget.setTransportState(new TransportState());
    }
  }
}
