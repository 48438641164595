import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Table } from '../shared/table';
import { SpeakerV5Data } from '@masala-lib/catalog/bogota/bogota-types';
import { DataCard } from '../../components/data-display/data-card';
import { speakerTranslation, Volume } from '@masala-lib/catalog/models/volume';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';

interface SpeakersTableProps {
  volume: Volume;
}

export const SpeakerAttr = observer(
  ({
    speaker,
    attr,
    locale,
  }: {
    speaker: SpeakerV5Data;
    attr: keyof SpeakerV5Data;
    locale: string;
  }) => {
    const translation = speakerTranslation(speaker, attr, locale);
    return (
      <>
        {speaker[attr]}
        {translation ? (
          <>
            <br />
            {locale.toUpperCase()}: {speakerTranslation(speaker, attr, locale)}
          </>
        ) : null}
      </>
    );
  }
);

export const SpeakersTable = observer(
  ({ volume, ...props }: SpeakersTableProps) => {
    const speakers = volume.data.speakers || [];
    const locale = volume.l1;

    const removeSpeaker = (label: string) => {
      if (window.confirm('Are you sure?')) {
        volume.removeSpeaker(label);
      }
    };

    return (
      <Table
        data={speakers || []}
        cells={[
          {
            header: 'Speaker',
            render: (speaker: SpeakerV5Data) => (
              <DataCard
                data={{
                  label: (
                    <SpeakerAttr
                      speaker={speaker}
                      attr={'label'}
                      locale={locale}
                    />
                  ),
                  bio: (
                    <SpeakerAttr
                      speaker={speaker}
                      attr={'bio'}
                      locale={locale}
                    />
                  ),
                  accent: (
                    <SpeakerAttr
                      speaker={speaker}
                      attr={'accent'}
                      locale={locale}
                    />
                  ),
                  actions: () => (
                    <>
                      <NavLink
                        to={`/volumes/${volume.id}/speakers/${speaker.label}/edit`}
                      >
                        [edit]
                      </NavLink>
                      {' - '}
                      <ActionLink onPress={() => removeSpeaker(speaker.label)}>
                        [remove]
                      </ActionLink>
                    </>
                  ),
                }}
              />
            ),
          },
          {
            show: true,
            header: 'Re-Order',
            render: speaker => {
              const isFirst = volume.isFirstSpeaker(speaker.label);
              const isLast = volume.isLastSpeaker(speaker.label);
              return (
                <>
                  {!isFirst ? (
                    <ActionLink
                      onPress={() =>
                        volume.swapSpeakerPosition(speaker.label, true /*up*/)
                      }
                    >
                      [↑]
                    </ActionLink>
                  ) : null}{' '}
                  {!isLast ? (
                    <ActionLink
                      onPress={() =>
                        volume.swapSpeakerPosition(
                          speaker.label,
                          false /*down*/
                        )
                      }
                    >
                      [↓]
                    </ActionLink>
                  ) : null}
                </>
              );
            },
          },
        ]}
      />
    );
  }
);
