import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2rem;
  background: #f9f9f9;
  margin-left: -2rem;
  margin-top: 2rem;
  .item {
    display: flex;
    .item-text {
      font-weight: 500;
    }
    span + span {
      margin-left: 0.5rem;
    }
  }
`;

export const VocabList = ({ vocabs }: { vocabs: any[] }) => {
  return (
    <Wrapper>
      {vocabs.map(vocab => {
        return (
          <div className="item" key={vocab.id}>
            <span className="item-text">{vocab.text}</span>
            {vocab.head ? (
              <span className="item-head">[{vocab.head}]</span>
            ) : null}
            <span className="emdash">—</span>
            <span>{vocab.note}</span>
          </div>
        );
      })}
    </Wrapper>
  );
};
