// slightly simplified version of common/log/logger.ts

export const logLevels = {
  none: 0,
  trace: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  fatal: 6,
} as const;

type LogLevelKey = keyof typeof logLevels;

export type LogLevel = typeof logLevels[LogLevelKey];

export type LogFunction = typeof console.log;

function getLevelKey(level: LogLevel): LogLevelKey {
  const keys = Object.keys(logLevels) as LogLevelKey[];
  return keys[level];
}

export class Logger {
  constructor(private name: string) {}

  private output(level: LogLevel, message: any, optionalParams: any[]) {
    const minimumLogLevel = logLevels.debug;
    const logFunction = console.log;

    if (minimumLogLevel > level) {
      return;
    }

    const levelPrefix = `[${getLevelKey(level)}]`;
    const namePrefix = `[${this.name}]`;
    const output = [levelPrefix, namePrefix, message].join(' ');
    logFunction(output, ...optionalParams);
  }

  trace(message: any, ...optionalParams: any[]) {
    this.output(logLevels.trace, message, optionalParams);
  }

  debug(message: any, ...optionalParams: any[]) {
    this.output(logLevels.debug, message, optionalParams);
  }

  info(message: any, ...optionalParams: any[]) {
    this.output(logLevels.info, message, optionalParams);
  }

  warn(message: any, ...optionalParams: any[]) {
    this.output(logLevels.warn, message, optionalParams);
  }

  error(message: any, ...optionalParams: any[]) {
    this.output(logLevels.error, message, optionalParams);
  }

  fatal(message: any, ...optionalParams: any[]) {
    this.output(logLevels.fatal, message, optionalParams);
  }
}
