import { normalizeSpecialChars } from '@masala-lib/misc/editorial-string-utils';
import { ScriptElement } from '../llm-types';
import { LintAlert } from './llm-project-types';
import { epochSeconds, hashForElement } from '../llm-funcs';
import { createLogger } from '@app/logger';
import { trimPunctuation } from '@tikka/misc/string-utils';
import { structuralTaskComputeElementKeys } from './llm-project-funcs';
import { alertError } from '@app/notification-service';

const log = createLogger('structural-parsers');

interface StructuralData {
  kind: 'CHAPTER' | 'PASSAGE';
  position: number;
  startLine: number;
  // endLine: number;
  title: string;
  summary: string;
}

export function llmParseStructuralResponse(
  text: string,
  alerts: LintAlert[],
  timestamp0: number = 0
): ScriptElement[] {
  // try {
  text = normalizeSpecialChars(text);
  const datas = parseStructuralData(text); //, alerts);

  const timestamp = timestamp0 || epochSeconds();
  const elements: ScriptElement[] = [];
  for (const data of datas) {
    if (!data.startLine) {
      alertError(`missing start line for ${data.kind} ${data.title}`);
      continue;
    }
    // todo: should be able to factor some of this with the guts of llmResponseParseScriptLines
    // TODO all script element creation should have same order of fields
    const element: ScriptElement = {
      kind: data.kind,
      text: data.title,
      reference: data.startLine,
      claimedSlot: null,
      slots: null,
      groupKey: null,
      timestamp,
      id: null,
      origin: 'PARSE',
      hash: null,
    };
    // const hash = hashForElement(element);
    // element.id = hash;
    // element.hash = hash;
    structuralTaskComputeElementKeys(element);
    elements.push(element);

    if (data.summary) {
      const element: ScriptElement = {
        kind: `${data.kind}_SUMMARY`,
        text: data.summary,
        reference: data.startLine,
        claimedSlot: null,
        slots: null,
        groupKey: null,
        timestamp,
        id: null,
        origin: 'PARSE',
        hash: null,
      };
      structuralTaskComputeElementKeys(element);
      // const hash = hashForElement(element);
      // element.id = hash;
      // element.hash = hash;
      elements.push(element);
    }
  }
  return elements;
}

// mostly copilot code
export function parseStructuralData(data: string): StructuralData[] {
  // Split the input data by newline to process each scene separately
  const lines = data.split('\n');

  // Define the array to hold the parsed scene information
  const result: StructuralData[] = [];

  // Define regular expressions to capture necessary data
  const chapterPositionRegex = /Chapter (.*):/; // to capture scene position
  const scenePositionRegex = /Scene (.*):/; // to capture scene position
  // const scenePositionRegex = /Scene (\d+):/; // to capture scene position
  // const linesRegex = /Lines (\d+)-(\d+)/; // to capture start and end lines
  const startLineRegex = /Lines? (\d+)/; // to capture starting line number
  const startLineRegex2 = /\[[^0-9\]]*(\d+)[^\]]*\]/; // match `[12-34]` style
  // const sceneTitleRegex = /Scene \d+: \[.*?\] - (.*)/; // to capture scene title
  const titleRegex = /.*: \[.*?\][^\w]* (.*)/; // to capture title

  // Process each scene
  // lines.forEach((scene, index) => {
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    if (!line) {
      // ignore blank lines
      continue;
    }

    const data: StructuralData = {
      kind: undefined,
      // position: index + 1, // position is the index in the array + 1
      position: 0, // position is the index in the array + 1
      startLine: 0, // default value, will be updated
      // endLine: 0, // default value, will be updated
      title: '', // default value, will be updated
      summary: '', // default value, will be updated
    };

    // Match scene position
    const sceneMatch = line.match(scenePositionRegex);
    const chapterMatch = line.match(chapterPositionRegex);

    if (sceneMatch) {
      data.kind = 'PASSAGE';
      data.position = parseInt(sceneMatch[1], 10);
    } else if (chapterMatch) {
      data.kind = 'CHAPTER';
      data.position = parseInt(chapterMatch[1], 10);
    } else {
      log.warn(`unable to parse scene line: ${line}`);
      continue;
    }

    // // Match startLine and endLine
    // const lineMatch = line.match(linesRegex);
    // if (lineMatch) {
    //   data.startLine = parseInt(lineMatch[1], 10);
    //   data.endLine = parseInt(lineMatch[2], 10);
    // }

    // Match startLine and endLine
    const lineMatch = line.match(startLineRegex);
    if (lineMatch) {
      data.startLine = parseInt(lineMatch[1], 10);
    } else {
      const lineMatch2 = line.match(startLineRegex2);
      if (lineMatch2) {
        data.startLine = parseInt(lineMatch2[1], 10);
      }
    }

    // Match title
    const titleMatch = line.match(titleRegex);
    if (titleMatch) {
      data.title = trimPunctuation(titleMatch[1]);
    }

    let following = lines[i + 1]?.trim();
    if (!following) {
      // swallow one blank line between title and summary
      i++;
      following = lines[i + 1]?.trim();
    }
    if (
      following &&
      !following.match(scenePositionRegex) &&
      !following.match(chapterPositionRegex)
    ) {
      data.summary = following;
      i++;
    }

    // Add the parsed scene information to the array
    result.push(data);
  }

  return result;
}

// // Example usage:
// const data = `Scene 1: [Lines 1-11] - Introduction
// Christian McBride is on stage, sharing his aspirations as a jazz musician. He talks about his admiration for Art Blakey, who was an idol to many jazz musicians, including greats like Freddie Hubbard and Wynton Marsalis.

// Scene 2: [Lines 12-20] – First Experience with Freddie Hubbard
// McBride talks about his first encounter with Freddie Hubbard during a 1987 performance in Philadelphia. He likens Hubbard's energy and intensity to that of James Brown and decides that Hubbard is number two on his list of musicians he wants to play with.

// Scene 3: [Lines 21-32] – Moving to New York and His Friends
// He describes his move to New York in 1989 and the friendships he forms with fellow musicians who also happen to be part of Hubbard's band. McBride ponders upon missed opportunities with Art Blakey and starts focusing on potentially collaborating with Freddie Hubbard.`;

// console.log(parseSceneData(data));

//
// attic
//

// export function llmParseStructuralResponse(
//   text: string,
//   alerts: LintAlert[],
//   timestamp0: number = 0
// ): ScriptElement[] {
//   // try {
//   text = normalizeSpecialChars(text);
//   const parsedTitles = parseStructural(text, alerts);

//   const timestamp = timestamp0 || epochSeconds();
//   const elements: ScriptElement[] = parsedTitles.map(data => {
//     // todo: should be able to factor some of this with the guts of llmResponseParseScriptLines
//     // TODO all script element creation should have same order of fields
//     const element: ScriptElement = {
//       kind: data.kind,
//       text: data.title,
//       reference: data.startingLine,
//       timestamp,
//       id: null,
//       origin: 'PARSE',
//       hash: null,
//     };
//     const hash = hashForElement(element);
//     element.id = hash;
//     element.hash = hash;
//     return element;
//   });
//   return elements;
// }

// export function llmParseStructuralResponse(text: string): ScriptElement[] {
//   text = normalizeSpecialChars(text);
//   const chapters = parseChapters(text);
//   const passages = parsePassages(text);

//   const timestamp = epochSeconds();
//   const chapterElements: ScriptElement[] = chapters.map(ch => {
//     // todo: should be able to factor some of this with the guts of llmResponseParseScriptLines
//     const element: ScriptElement = {
//       kind: 'CHAPTER',
//       text: ch.title,
//       reference: ch.startingLine,
//       timestamp,
//       id: null,
//       hash: null,
//     };
//     const hash = hashForElement(element);
//     element.id = hash;
//     element.hash = hash;
//     return element;
//   });
//   const passageElements: ScriptElement[] = passages.map(data => {
//     // todo: should be able to factor some of this with the guts of llmResponseParseScriptLines
//     const element: ScriptElement = {
//       kind: 'PASSAGE',
//       text: data.title,
//       reference: data.startingLine,
//       timestamp,
//       id: null,
//       hash: null,
//     };
//     const hash = hashForElement(element);
//     element.id = hash;
//     element.hash = hash;
//     return element;
//   });
//   return [...chapterElements, ...passageElements];
// }

// function parseStructural(input: string, alerts: LintAlert[]) {
//   const result: { kind: any; title: string; startingLine: number }[] = [];
//   const lines: string[] = input.trim().split(/\r?\n/);
//   for (let line of lines) {
//     line = line.trim();
//     if (line.startsWith('##')) {
//       const regex = /## (.*?) \((\d+)-\d+\)/g;
//       const match = regex.exec(line);
//       if (match) {
//         const title = match[1];
//         const startingLine = parseInt(match[2]);
//         result.push({ kind: 'PASSAGE', title, startingLine });
//       } else {
//         const message = `unable to parse ## line: ${line}`;
//         log.warn(message);
//         // to be revisited
//         alerts.push({
//           kind: 'UNRECOGNIZED',
//           key: undefined, // todo
//           elementId: undefined, // todo
//           reference: undefined, // todo //number;
//           level: 'WARNING',
//           message,
//         });
//       }
//     } else if (line.startsWith('#')) {
//       const regex = /# (.*?) \((\d+)-\d+\)/g;
//       const match = regex.exec(line);
//       if (match) {
//         const title = match[1];
//         const startingLine = parseInt(match[2]);
//         result.push({ kind: 'CHAPTER', title, startingLine });
//       } else {
//         const message = `unable to parse # line: ${line}`;
//         log.warn(message);
//         alerts.push({
//           kind: 'UNRECOGNIZED',
//           key: undefined, // todo
//           elementId: undefined, // todo
//           reference: undefined, // todo //number;
//           level: 'WARNING',
//           message,
//         });
//       }
//     }
//   }
//   return result;
// }

//
// chaptgpt generated chapter title parsing function
//
// @jason, i'll let you take a pass at a better parsing approach when you have a chance.
// function parseChapters(input: string) {
//   // Define a regex to match the required pattern
//   const regex = /^# (.*?) \((\d+)-\d+\)/g;

//   const result = [];
//   let match;

//   // Use regex.exec() in a loop to find all matches
//   while ((match = regex.exec(input))) {
//     result.push({
//       title: match[1], // Extracted title
//       startingLine: parseInt(match[2], 10), // Extracted starting line number
//     });
//   }

//   return result;
// }

// function parsePassages(input: string) {
//   // Define a regex to match the required pattern
//   const regex = /^## (.*?) \((\d+)-\d+\)/g;

//   const result = [];
//   let match;

//   // Use regex.exec() in a loop to find all matches
//   while ((match = regex.exec(input))) {
//     result.push({
//       title: match[1], // Extracted title
//       startingLine: parseInt(match[2], 10), // Extracted starting line number
//     });
//   }

//   return result;
// }
