import * as React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-left: 8px;
  }

  & > a,
  & > button,
  & .page-action-button {
    --button-background-color: var(--color-transparent);
    --button-color: var(--color-gray500);
    --button-border-color: var(--color-gray100);
    background: none;
    background: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    color: var(--button-color);
    padding: 4px 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    border-radius: 16px;
    outline: none;
    &:hover {
      --button-background-color: var(--color-gray600);
      --button-color: var(--color-gray50);
      --button-border-color: var(--color-gray600);
    }

    &.primary-action {
      --button-background-color: var(--color-gray900);
      --button-color: var(--color-white);
      --button-border-color: var(--color-gray900);
      &:hover {
        --button-background-color: var(--color-gray700);
        --button-color: var(--color-white);
        --button-border-color: var(--color-gray700);
      }
    }
    &.danger-action {
      &:hover {
        --button-background-color: var(--color-red400);
        --button-color: var(--color-white);
        --button-border-color: var(--color-red500);
      }
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

export const PageActions = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
