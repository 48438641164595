import { ActivityGuideData } from './../catalog-types';
import { CatalogEntityKind } from '../catalog-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { runInAction } from 'mobx';
import { AuthorManager } from '../db/author-manager';
import { ActivityGuideManager } from '../db/activity-guide-manager';
import { UnitManager } from '../db/unit-manager';

export const emptyActivityGuideData = {
  kind: CatalogEntityKind.ACTIVITY_GUIDE,
  id: '',
  name: '',
  slug: '',
  unitId: '',
  authorId: '',
  authorNote: '',
  resourceUrl: '',
};

export class ActivityGuide
  extends BaseCatalogEntity<ActivityGuideData>
  implements CatalogEntity<ActivityGuideData>
{
  constructor(data?: { id: string; authorId: string }) {
    super(data);
    this.data = { ...emptyActivityGuideData, ...data };
  }

  theManager(): EntityManager<
    CatalogEntity<ActivityGuideData>,
    ActivityGuideData
  > {
    return ActivityGuideManager.getInstance() as unknown as EntityManager<
      CatalogEntity<ActivityGuideData>,
      ActivityGuideData
    >;
  }

  get name() {
    return this.unit?.name + ' activity guide';
  }

  get author() {
    return AuthorManager.getInstance().getById(this.data.authorId);
  }

  get unit() {
    return UnitManager.getInstance().getById(this.data.unitId);
  }

  get navPath(): string {
    return `/activity-guides/${this.id}`;
  }

  destroy = async () => {
    await ActivityGuideManager.getInstance().delete(this);
  };

  dettachFromUnit = () => {
    runInAction(() => {
      this.updatePartial({ unitId: null });
    });
  };
}
