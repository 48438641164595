import * as React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 8px;

  & > .cardheader {
    padding: 12px;
    font-weight: 300;
    color: #666;
    border-bottom: 1px solid #eee;
  }

  & > .cardfooter {
    padding: 12px;
    border-top: 1px solid #eee;
    background-color: #f9f9f9;
    border-radius: 0 0 8px 8px;
  }

  & > .inner {
    padding: 12px;
  }
`;

interface CardProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}

export const Card = ({ header = null, footer = null, children }: CardProps) => {
  return (
    <Wrapper>
      {header ? <CardHeader header={header} /> : null}
      <div className="inner">{children}</div>
      {footer ? <CardFooter footer={footer} /> : null}
    </Wrapper>
  );
};

interface CardHeaderProps {
  header: React.ReactNode;
}

export const CardHeader = ({ header }: CardHeaderProps) => {
  return <header className="cardheader">{header}</header>;
};

interface CardFooterProps {
  footer: React.ReactNode;
}

export const CardFooter = ({ footer }: CardFooterProps) => {
  return <footer className="cardfooter">{footer}</footer>;
};
