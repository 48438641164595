import { observer } from 'mobx-react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { PlusIcon } from '../../components/icons/plus-icon';
import { XIcon } from '../../components/icons/x-icon';
import { UserClientData } from './user-client-data';

const Wrapper = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 20px;
  color: var(--color-sidebar-tl-navitem);
  font-weight: 600;
  padding: 8px;
  padding-left: 16px;
  background: var(--color-sidebar-tl-background);
  border-radius: 4px;
  cursor: default;

  button {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: var(--color-blue100);
    background-color: var(--color-blue200);
    border: none;
    outline: none;
    margin-left: auto;
    margin-right: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: var(--color-blue100);
      background-color: var(--color-blue600);
    }
  }
`;

const BookmarkItem = styled.li`
  position: relative;
  padding-right: 20px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 4px;
    color: var(--color-blue500);
    border: none;
    background: none;
    outline: none;
    &:hover {
      color: var(--color-blue600);
      background-color: var(--color-gray20);
    }
  }
`;

function createFavorite(location = window.location.href) {
  const name = window.prompt(
    'Set a title for your favorite',
    window.document.title
  );
  const now = new Date();
  const id = now.getTime().toString(16);
  if (!name) {
    return null;
  }
  return {
    id,
    location,
    name,
  };
}

export const Favorites = observer(() => {
  const location = useLocation();
  const userClientData = UserClientData.getInstance();

  React.useEffect(() => {
    userClientData.start();
  }, [userClientData]);

  const add = () => {
    const locationString = `${location.pathname}${location.search}`;
    const newFave = createFavorite(locationString);
    if (newFave) {
      userClientData.addFavorite(newFave);
    }
  };

  const remove = (id: string) => {
    if (window.confirm('You sure?')) {
      userClientData.removeFavorite(id);
    }
  };

  const faves = userClientData.favorites;
  return (
    <>
      <Wrapper>
        Favorites
        <button onClick={add}>
          <PlusIcon size={16} />
        </button>
      </Wrapper>
      <nav>
        <ul>
          {faves.map(fave => (
            <BookmarkItem key={fave.id}>
              <Link to={fave.location} className="sl-link">
                <span className="label">{fave.name}</span>
              </Link>
              <button onClick={() => remove(fave.id)}>
                <XIcon size={12} />{' '}
              </button>
            </BookmarkItem>
          ))}
          {faves.length === 0 && (
            <li>
              <a
                href={'#'}
                onClick={add}
                className="sl-link"
                style={{ color: 'var(--color-gray500)' }}
              >
                <span className="label">Add first favorite</span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
});
