import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { JobManager } from '@masala-lib/jobs/job-manager';
import { Job } from '@masala-lib/jobs/job';

interface LoaderState {
  model?: Job;
  failure?: Error;
}

async function loadListenJob(id: string): Promise<Job> {
  const model = await JobManager.loadById(id);
  if (model) {
    model.listen();
    return model;
  } else {
    throw new Error(`Job not found: ${id}`);
  }
}

export function useJob(): LoaderState {
  const { id = null } = useParams<{ id: string }>();

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { model } = loaderState;

  console.log('ID', id);

  useEffect(() => {
    if (model === undefined) {
      loadListenJob(id)
        .then(model => {
          setLoaderState({ model });
        })
        .catch(failure => {
          setLoaderState({ failure });
        });
    }

    return () => {
      if (model) {
        model.stopListening();
      }
    };
  }, [id, model]);

  return loaderState;
}
