import { ScriptElement } from '@masala-lib/llm/llm-types';
import { useTouchupEditorModal } from './use-editor-modal';
import { styled } from '@naan/stitches.config';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { styleLookup } from './style-lookup';
import { ScriptElementItem } from './script-element-item';
import { useStructuralKeyboardBindings } from './structural-keyboard-bindings';
import { observer } from 'mobx-react';

const Row = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
});

const Left = styled('div', {
  paddingTop: '1em',
  paddingLeft: '1em',
  display: 'inline-block',
  width: '45%',
  paddingBottom: '1em',
  variants: {
    wide: {
      true: {
        width: '100%',
      },
    },
  },
});

const Right = styled('div', {
  paddingTop: '1em',
  display: 'inline-block',
  width: '45%',
  paddingBottom: '1em',
});

const ScriptElementView = styled('div', {
  width: '100%',
});

// const OutputView = styled('div', {
//   width: '100%',
//   marginBottom: '1em',
//   fontWeight: 'bold',
// });

// const Divider = styled('div', {
//   width: '100%',
//   borderBottom: '1px solid #aaa',
// });

export const ColumnStructuralMergeView = observer(
  ({ onResolve }: { onResolve: (value: boolean) => void }) => {
    const modal = useTouchupEditorModal();
    useStructuralKeyboardBindings(modal, onResolve);

    // const samosaModal = useSamosaModel();
    const referenceScript = modal.referenceScript;
    const outputKindScript = modal.outputKindScript;
    const outputsByKey: Map<string, ScriptElement[]> = new Map();

    const elementKey = (element: ScriptElement) => String(element.reference); // + element.kind;

    for (const output of outputKindScript) {
      if (!output.reference || output.kind === 'SPEAKER_LABEL') {
        continue;
      }
      const key = elementKey(output);
      const list = outputsByKey.get(key) ?? [];
      list.push(output);
      outputsByKey.set(key, list);
    }

    const collapsed = modal.collapsed;
    const wideLeft = collapsed;

    return (
      <div>
        {referenceScript.map(ref => {
          // const style = styleLookup[ref.kind];
          // let prefix = typeof style.prefix === 'string' ? style.prefix : '';
          const key = elementKey(ref);
          const isSpeakerLabel = ref.kind === 'SPEAKER_LABEL';
          const output = outputsByKey.get(key);
          // debugger;
          return (
            <>
              {!collapsed || output ? (
                <Row key={key}>
                  <Left wide={wideLeft}>
                    {isSpeakerLabel
                      ? null
                      : (outputsByKey.get(key) ?? []).map(output => {
                          return (
                            <ScriptElementItem
                              key={output.id}
                              element={output}
                              modal={modal}
                            />
                          );
                        })}
                  </Left>
                  {!collapsed ? (
                    <Right>
                      <ScriptElementItem
                        key={ref.id}
                        element={ref}
                        modal={modal}
                      />
                    </Right>
                  ) : null}
                </Row>
              ) : null}
            </>
          );
        })}
      </div>
    );
  }
);
