import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { useForm } from 'react-hook-form';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { LLMOptions } from '@masala-lib/llm/project/llm-project-types';
import {
  llmModelChoices,
  llmModelKeys,
} from '@masala-lib/llm/project/llm-project-funcs';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { GearIcon } from '@naan/icons/gear-icon';
import { IconButton } from '@naan/primitives/button';
import { useMasalaForm } from '@console/lib/hooks/use-masala-form';
import { Checkbox, Input, Select } from '@console/components/forms';
import { RequestLogo } from '../components/request-logo';
import { HStack } from '@naan/primitives/stack';
import { DialogPresenter } from '../dialog-presenter';
import { HSpacer } from '@naan/primitives/spacer';
import {
  KeyboardService,
  usePauseKeyboardService,
} from 'lib/services/keyboard-service';
// import { createLogger } from '@app/logger';
// const log = createLogger('project-name-form');

// const modelOptions = llmModelChoices.map(choice => ({
//   value: choice.key,
//   label: choice.key,
// }));

const LlmSettingsFormModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const model = useSamosaModel();

  // usePauseKeyboardService();
  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('llm-settings', {
      escape: [onDismiss, 'Close'],
    });
  }, [onDismiss]);

  const currentData = model.llmOptions;
  const defaultValues = {
    model: currentData.model,
    temperature: currentData.temperature,
    systemPrompt: currentData.systemPrompt,
    streamingDisabled: currentData.streamingDisabled,
    parsingDisabled: currentData.parsingDisabled,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getFieldProps,
    watch,
  } = useMasalaForm<typeof defaultValues>({ defaultValues });

  const onSubmit = async (
    data: LLMOptions
    // event: any /*React.BaseSyntheticEvent*/
  ) => {
    await model.updateLlmOptions(data);
    onDismiss();
  };

  const options: { value: string; label: string }[] = llmModelKeys.map(key => ({
    value: key,
    label: key,
  }));

  const modelName = watch('model');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog.Container open onDismiss={onDismiss} css={{ minHeight: 500 }}>
        <Dialog.Heading icon={<RequestLogo modelName={modelName} />}>
          <HSpacer size={2} />
          LLM Settings
        </Dialog.Heading>
        <Dialog.Body>
          <Select
            {...getFieldProps('model')}
            label={'Model'}
            options={options}
            // hint={'beware, openai 32k model not currently working'}
          />
          <Input {...getFieldProps('temperature')} label="Temperature" />
          <Input {...getFieldProps('systemPrompt')} label="System prompt" />
          <Checkbox
            {...getFieldProps('streamingDisabled')}
            label="Disable response streaming"
            hint="(currently needed and implicit for claude)"
          />
          {model.showExploratoryUI ? (
            <Checkbox
              {...getFieldProps('parsingDisabled')}
              label="Disable response parsing"
              hint="(omit from merge view)"
            />
          ) : null}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.SmallButton
            onClick={onDismiss}
            label={'Cancel'}
            presentation={'grayLight'}
            type="button"
          />
          <Dialog.SmallButton
            onClick={handleSubmit(onSubmit)}
            label={'Save'}
            presentation={'blue'}
            type="submit"
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    </form>
  );
};

export function presentLlmSettingsModal() {
  DialogPresenter.present(onDismiss => (
    <LlmSettingsFormModal onDismiss={onDismiss} />
  ));
}

export const LlmSettingsForm = () => {
  return (
    <IconButton
      icon={<GearIcon />}
      size={'small'}
      onClick={presentLlmSettingsModal}
      testId="present-llm-settings-modal"
    />
  );
};
