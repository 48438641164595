import { comparer } from 'mobx';
import { NO_INDEX, WordId } from '@tikka/basic-types';
import { WordRecord, WordRecordsData } from '../../editorial-types-aside';
import { WordStyle } from '../../editorial-types';

function parseBigInt(value: string, radix: number) {
  const size = 10;
  const factor = BigInt(radix ** size);
  let i = value.length % size || size;
  const parts = [value.slice(0, i)];

  while (i < value.length) parts.push(value.slice(i, (i += size)));

  return parts.reduce((r, v) => r * factor + BigInt(parseInt(v, radix)), 0n);
}

const B36 = 36;

function padTrailingZeroes(val: string, len: number) {
  return val + '0'.repeat(len - val.length);
}

function padLeadingZeroes(val: string, len: number) {
  return '0'.repeat(len - val.length) + val;
}

function trimTrailingZeroes(val: string) {
  return val.replace(/0*$/, '');
}

function trimLeadingZeroes(val: string) {
  return val.replace(/^0*/, '');
}

function trimToSize(val: string, len: number) {
  return val.slice(0, len);
}

export function getIdBetween(id1: string, id2: string): string {
  return getIdsBetween(1, id1, id2)[0];
}

export function getIdsBetween(
  count: number,
  id1: string,
  id2: string
): string[] {
  [id1, id2] = id1 < id2 ? [id1, id2] : [id2, id1];
  let digitCount = Math.max(id1.length, id2.length);
  const _id1 = trimLeadingZeroes(padTrailingZeroes(id1, digitCount)) || '0';
  const _id2 = trimLeadingZeroes(padTrailingZeroes(id2, digitCount)) || '0';
  let num1N = parseBigInt(_id1, B36);
  let num2N = parseBigInt(_id2, B36);

  const divisor = count + 1;
  const divisorN = BigInt(divisor);
  let incrementN;
  while (true) {
    const differenceN = num2N - num1N;
    incrementN = differenceN / divisorN;
    const fraction = Number(differenceN % divisorN) / divisor;
    if (incrementN > 0 && fraction / Number(incrementN) < 0.26) {
      break;
    }
    digitCount++;
    num1N = num1N * 36n;
    num2N = num2N * 36n;
  }

  const betweens: BigInt[] = [];
  let lastValue = num1N;
  for (let i = 0; i < count; i++) {
    const value = lastValue + incrementN;
    betweens.push(value);
    lastValue = value;
  }

  return betweens.map(num =>
    trimTrailingZeroes(padLeadingZeroes(num.toString(B36), digitCount))
  );
}

export function validateIdsBetween(ids: string[], id1: string, id2: string) {
  // ids should between id1 and id2, in order, unique, not too big relative to inputs
  const inOrder = [id1, ...ids, id2];
  if (!comparer.structural(inOrder, [...inOrder].sort())) {
    return false; // jrw or exception here?
  }
  const unique = [...new Set(inOrder)];
  if (unique.length !== inOrder.length) {
    return false; // jrw or exception here?
  }
  const lengths = inOrder.map(value => value.length);
  const maxLengthOut = Math.max(...lengths);
  const maxLengthIn = Math.max(id1.length, id2.length);
  if (maxLengthOut > maxLengthIn + 2) {
    // TODO not really a good test
    return false; // jrw or exception here?
  }
  return true;
}

export function testIdsBetween(count: number, id1: string, id2: string) {
  const ids = getIdsBetween(count, id1, id2);
  return validateIdsBetween(ids, id1, id2);
}

export function testIdsBetween1() {
  return testIdsBetween(1000, '001', '001a');
}

export function createIdValues(count: number) {
  const values = Array(count);
  for (let i = 0; i < count; i++) {
    values[i] = i;
  }
  const base36Values = values.map((val: number) => val.toString(B36));
  const maxUsedDigits = base36Values[base36Values.length - 1].length;
  return base36Values.map((val: string) =>
    padLeadingZeroes(val, maxUsedDigits)
  );
}

export interface WordsDataMutationFuncs {
  recordInsertBeforePos: (pos: number) => void;
  recordInsertAfterPos: (pos: number) => void;
  recordRemoveAtPos: (pos: number) => void;
}

export function createWordRecords(words: string[]): WordRecord[] {
  return null; // TODO when needed
}

export function indexNthActiveWord(wordRecords: WordRecord[], nth: number) {
  let count = 0;
  for (const [index, record] of wordRecords.entries()) {
    if (record.active) {
      if (count === nth) {
        return index;
      }
      count++;
    }
  }
  return NO_INDEX;
}

function insertWordRecordAtIndex(
  wordRecords: WordRecord[],
  index: number,
  text: string,
  direction: number
): WordRecord {
  const id1 = wordRecords[index].id;
  const id2 = wordRecords[index + direction].id;
  const newId = getIdBetween(id1, id2) as WordId;
  const pos = direction === 1 ? index + 1 : index;
  const newRecord = {
    id: newId,
    text,
    active: true,
  };
  wordRecords.splice(pos, 0, newRecord);
  return newRecord;
}

function insertWordRecordBeforeIndex(
  wordRecords: WordRecord[],
  index: number,
  text: string
): WordRecord {
  return insertWordRecordAtIndex(wordRecords, index, text, -1);
}

function insertWordRecordAfterIndex(
  wordRecords: WordRecord[],
  index: number,
  text: string
): WordRecord {
  return insertWordRecordAtIndex(wordRecords, index, text, 1);
}

export function insertWordRecordBeforeNthActive(
  wordRecords: WordRecord[],
  nth: number,
  text: string
): WordRecord {
  let index = indexNthActiveWord(wordRecords, nth);
  if (index !== NO_INDEX) {
    return insertWordRecordBeforeIndex(wordRecords, index, text);
  } else {
    if (nth === 0) {
      // no active words case insert after starting bookend
      return insertWordRecordAfterIndex(wordRecords, 0, text);
    }
    index = indexNthActiveWord(wordRecords, nth - 1);
    return insertWordRecordAfterIndex(wordRecords, index, text);
  }
}

export function insertWordRecordAfterNthActive(
  wordRecords: WordRecord[],
  nth: number,
  text: string
): WordRecord {
  const index = indexNthActiveWord(wordRecords, nth);
  return insertWordRecordAfterIndex(wordRecords, index, text);
}

export function createWordRecordsBetweenIds(
  words: string[],
  id1: string,
  id2: string
): WordRecord[] {
  const ids = getIdsBetween(words.length, id1, id2) as WordId[];
  return [...words.entries()].map(([index, word]) => {
    return {
      id: ids[index],
      text: word,
      active: true,
    };
  });
}

export function insertWordsBetweenIds(
  words: string[],
  wordRecordsData: WordRecordsData,
  id1: string,
  id2: string
) {
  const insertAfterIndex = wordRecordsData.getIndexAllRecords(id1);
  if (wordRecordsData.getIndexAllRecords(id2) !== insertAfterIndex + 1) {
    throw Error('insertWordsBetweenIds: non adjacent ids');
  }
  const newWordRecords = createWordRecordsBetweenIds(words, id1, id2);
  wordRecordsData.allWordRecords.splice(
    insertAfterIndex + 1,
    0,
    ...newWordRecords
  );
}

function markDeactivatedWordRecordIndex(
  wordRecords: WordRecord[],
  index: number
): WordRecord {
  const wordRecord = wordRecords[index];
  wordRecord.active = false;
  return wordRecord;
}

export function markDeactivedNthActiveWordRecord(
  wordRecords: WordRecord[],
  nth: number
): WordRecord {
  const index = indexNthActiveWord(wordRecords, nth);
  return markDeactivatedWordRecordIndex(wordRecords, index);
}

export function getActiveWordRecords(wordRecords: WordRecord[]): WordRecord[] {
  return wordRecords.filter(record => record.active);
}

export function copyWordRecords(wordRecords: WordRecord[]): WordRecord[] {
  const result: WordRecord[] = [];
  for (const record of wordRecords) {
    result.push({ ...record });
  }
  return result;
}

const trailingPunctuationRegex = /([!"'()+,\-./:;<=>?[\]^`{|}~¡¿—–]+)\s*$/g;
const leadingPunctuationRegex = /^([!"'()+,\-./:;<=>?[\]^`{|}~¡¿—–]+)\s*/g;
function trimPunctuation(str: string) {
  return str
    .replace(trailingPunctuationRegex, '')
    .replace(leadingPunctuationRegex, '');
}

export function computeWordStyles(wordRecords: WordRecord[]) {
  let transition: boolean;
  let beginItalics: boolean;
  let endItalics: boolean;
  let inItalics = false;
  for (const word of wordRecords) {
    beginItalics = false;
    endItalics = false;
    let text = word.text;
    transition = text.includes('_');
    if (transition) {
      text = trimPunctuation(text);
      beginItalics = text.startsWith('_');
      endItalics = text.endsWith('_') || text.includes('_ ');
    }
    inItalics = inItalics || beginItalics;
    word.style = inItalics ? WordStyle.ITALIC : '';
    inItalics = inItalics && !endItalics;
  }
}

export function createWordRecordsData(
  wordRecords: WordRecord[]
): WordRecordsData {
  const activeWordRecords: WordRecord[] = [];
  const idToActiveRecordIndex = {} as any;
  const idToAllRecordIndex = {} as any;
  let activeCount = -1;
  for (const [index, record] of wordRecords.entries()) {
    idToAllRecordIndex[record.id] = index;
    idToActiveRecordIndex[record.id] = activeCount;
    if (record.active) {
      activeCount++;
      idToActiveRecordIndex[record.id] = activeCount;
      activeWordRecords.push(record);
    }
  }
  return {
    allWordRecords: wordRecords,
    activeWordRecords,
    getIndexActive: (id: string) => idToActiveRecordIndex[id],
    getIndexAllRecords: (id: string) => idToAllRecordIndex[id],
    isRemapped: (id: string) => !wordRecords[idToAllRecordIndex[id]].active,
  };
}

export function copyWordRecordsRangeWithOuterBounds(
  wordRecordsData: WordRecordsData,
  begin: string,
  end: string
): WordRecord[] {
  const getIndex = wordRecordsData.getIndexAllRecords;
  const beginIndex = getIndex(begin) - 1;
  let endIndex = getIndex(end) + 1;
  endIndex = endIndex > beginIndex ? endIndex : beginIndex + 1;
  const records = copyWordRecords(
    wordRecordsData.allWordRecords.slice(beginIndex, endIndex + 1)
  );
  records[0].active = false;
  records[records.length - 1].active = false;
  return records;
}

export function replaceWordRecordsRangeWithOuterBounds(
  wordRecordsData: WordRecordsData,
  wordRecords: WordRecord[]
) {
  const getIndex = wordRecordsData.getIndexAllRecords;
  const beginIndex = getIndex(wordRecords[0].id) + 1;
  const endIndex = getIndex(wordRecords[wordRecords.length - 1].id) - 1;
  const toSplice = wordRecords.slice(1, wordRecords.length - 1);
  wordRecordsData.allWordRecords.splice(
    beginIndex,
    endIndex - beginIndex + 1,
    ...toSplice
  );
}

export function getMutationFuncs(
  wordRecords: WordRecord[]
): WordsDataMutationFuncs {
  return {
    recordInsertBeforePos: pos =>
      insertWordRecordBeforeNthActive(wordRecords, pos, ''),
    recordInsertAfterPos: pos =>
      insertWordRecordAfterNthActive(wordRecords, pos, ''),
    recordRemoveAtPos: pos =>
      markDeactivedNthActiveWordRecord(wordRecords, pos),
  };
}
