import styled from 'styled-components';

const IconWrapper = styled.span`
  margin-right: 4px;
  margin-left: 4px;
`;

const HelpIconSVG = ({ size = 20, color = 'currentColor' }) => (
  <svg
    width={size}
    height={size}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 137.144 137.144"
    enable-background="new 0 0 137.144 137.144"
  >
    <g>
      <g>
        <path d="m68.576,89.144c-3.802,0-6.884,3.07-6.884,6.857 0,3.787 3.082,6.857 6.884,6.857s6.884-3.07 6.884-6.857c0.001-3.787-3.082-6.857-6.884-6.857zm41.139-89.144h-82.286c-15.149,0-27.429,12.28-27.429,27.428v82.287c0,15.149 12.28,27.429 27.429,27.429h82.286c15.149,0 27.429-12.28 27.429-27.429v-82.287c0-15.148-12.28-27.428-27.429-27.428zm13.715,102.858c0,11.361-9.21,20.571-20.572,20.571h-68.572c-11.361,0-20.572-9.211-20.572-20.571v-68.572c0-11.361 9.21-20.572 20.572-20.572h68.572c11.361,0 20.572,9.21 20.572,20.572v68.572zm-50.017-68.572c-26.582,0.095-25.605,15.568-25.412,20.572h10.353c0.611-8.289 4.781-13.792 12.896-13.792 5.744,0 10.94,3.255 10.94,9.239 0,5.337-4.584,6.761-9.473,11.499-6.584,6.402-8.584,10.257-8.584,14.63 0,4.719 0.285,3.897 1.752,5.853h7.101c-0.489-1.841-0.208-1.401-0.208-2.897 0-5.389 3.239-8.218 10.327-12.706 5.01-3.107 12.831-5.17 12.831-15.527-0.001-12.889-11.921-16.909-22.523-16.871z" />
      </g>
    </g>
  </svg>
);

export const HelpIcon = ({ size = 20, color = 'currentColor' }) => (
  <IconWrapper>
    <HelpIconSVG size={size} color={color} />
  </IconWrapper>
);
