import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';

type DismissableComponent = React.ComponentType<{ onDismiss: () => void }>;

export class DialogPresenter {
  private static _instance: DialogPresenter;

  elements: JSX.Element[] = [];

  constructor() {
    makeObservable(
      this,
      {
        elements: observable,
        present: action,
        dismiss: action,
      },
      {
        deep: false,
      }
    );

    // bind methods
    this.present = this.present.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  present(presenter: (onDismiss: () => void) => JSX.Element | null) {
    const element = presenter(this.dismiss);
    if (element) {
      this.elements.push(element);
    }
  }

  dismiss() {
    console.log(`dismiss - count: ${this.elements.length}`);
    this.elements.pop();
  }

  topElement() {
    return this.elements[this.elements.length - 1];
  }

  static get instance() {
    if (!DialogPresenter._instance) {
      DialogPresenter._instance = new DialogPresenter();
    }
    return DialogPresenter._instance;
  }

  static get elements() {
    return this.instance.elements;
  }

  static get topElement() {
    return this.instance.topElement;
  }

  static dismiss() {
    this.instance.dismiss();
  }

  static present(presenter: (onDismiss: () => void) => JSX.Element | null) {
    this.instance.present(presenter);
  }

  static makePresenter<T extends DismissableComponent>(Component: T) {
    return (props?: Omit<React.ComponentProps<T>, 'onDismiss'>) =>
      this.instance.present(onDismiss =>
        // mount the component without using jsx
        React.createElement(Component, { onDismiss, ...props })
      );
  }
}

(window as any).DialogPresenter = DialogPresenter;
