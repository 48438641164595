import { makeObservable, observable } from 'mobx';
import { ProjectMetadata } from './llm-project-types';
import { toggleArchived } from './llm-project-funcs';

import { createLogger } from '@app/logger';
import { agePrettyTimestamp } from '@masala-lib/utils';

const log = createLogger('project-meta');

export interface ProjectMeta extends ProjectMetadata {
  toggleArchived: () => Promise<void>;
  agePretty: string;
  navPath: string;
}

// beware, this actually mutates the passed in object, but using an return value to making the typing smoother
export function enhanceProjectMetadata(metadata: ProjectMetadata): ProjectMeta {
  if (metadata.archived === undefined) {
    metadata.archived = false;
  }
  if (!metadata.name) {
    metadata.name = metadata.projectId;
  }
  makeObservable(metadata, { archived: observable });

  const meta = metadata as ProjectMeta;

  meta.toggleArchived = async () => {
    log.debug(`toggleArchived(${metadata.id})`);
    toggleArchived(metadata);
  };

  Object.defineProperty(meta, 'agePretty', {
    get: () => agePrettyTimestamp(metadata.timestamp),
  });

  Object.defineProperty(meta, 'navPath', {
    get: () => `/samosa/${meta.projectId}`,
  });

  return meta;
}

// export class ProjectMeta implements ProjectMetadata {
//   // not expected to change
//   id: string;
//   projectId: string;
//   kind: 'PROJECT_METADATA';
//   unitId: string;
//   task: ProjectTask;

//   @observable
//   name: string; // project name to be displayed in list view and summary panel

//   @observable
//   state: string;

//   @observable
//   archived?: boolean;

//   @observable
//   completedTimestamp?: number;

//   // not relevant to list view UI
//   llmOptions: LLMOptions;
//   timestamp?: number; // assigned when persisted
//   sidebarMode?: SidebarMode;
//   twoColumnMergeEnabled?: boolean;
//   threadCounter: number; // used to generate letter codes for exchanges

//   unsubscribeFn: () => void;

//   constructor(data: Partial<ProjectMetadata>) {
//     makeObservable(this);
//     Object.assign(this, data);
//   }

//   get navPath(): string {
//     return `/jobs/${this.id}`;
//   }

//   get agePretty(): string {
//     return agePrettyTimestamp(this.timestamp);
//   }

//   async archive(): Promise<void> {
//     await this.updatePartial({ archived: true });
//   }

//   async unarchive(): Promise<void> {
//     await this.updatePartial({ archived: false });
//   }

//   async updatePartial(data: Partial<ProjectMetadata>): Promise<void> {
//     Object.assign(this, data); // todo: think about this more

//     await ProjectMetadataManager.updatePartial(this.id, data);
//   }

//   // destroy = async () => {
//   //   await JobManager.destroy(this);
//   // };

//   listen() {
//     this.stopListening();
//     log.debug(`job(${this.id}).listen`);
//     this.unsubscribeFn = JobManager.docRef(this.id).onSnapshot(docSnapshot => {
//       const data = docSnapshot.data();
//       log.debug(`job(${this.id}).onSnapshot, data:`, data);
//       Object.assign(this, data); // todo: think about this more
//       this.archived = data.archived;
//     });
//   }

//   stopListening() {
//     if (this.unsubscribeFn) {
//       log.debug(`job(${this.id}).stopListening`);
//       this.unsubscribeFn();
//       this.unsubscribeFn = undefined;
//     }
//   }

//   // matches against either name or slug, ignoring case, accents and punctuation
//   filterMatch(text: string): boolean {
//     if (isEmpty(text)) {
//       return true;
//     }
//     const match = strongNormalizeWord(text);
//     return (
//       strongNormalizeWord(this.id).includes(match) ||
//       strongNormalizeWord(this.kind).includes(match) ||
//       strongNormalizeWord(this.name).includes(match) ||
//       strongNormalizeWord(this.state).includes(match)
//       // todo: log search
//     );
//   }
// }
