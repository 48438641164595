import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';
import { SoundbiteEdition } from '@masala-lib/catalog/models/soundbite-edition';

export function useSoundbiteEdition() {
  const { id = null } = useParams<{ id: string }>();
  const manager = SoundbiteEditionManager.getInstance();
  const [model, setModel] = React.useState<SoundbiteEdition>(null);

  // console convenience
  (window as any).currentSoundbiteEdition = model;

  useEffect(() => {
    manager.loadById(id).then(model => setModel(model));
  }, [manager, id]);

  return model;
}
