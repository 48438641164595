import React from 'react';
import styled from 'styled-components';
import { MenuItem, MenuItemRow } from './menu-item';

export const MenuStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  .menu-item {
    padding: 16px;
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 1px;
      background: var(--color-gray100);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:hover {
      color: var(--color-teal600);
    }

    &.item-is-disabled {
      color: var(--color-gray300);
      cursor: not-allowed;
    }

    &.item-is-destructive {
      color: var(--color-red500);
    }
  }
`;

export interface MenuProps {
  items?: Array<MenuItemRow>;
  children?: React.ReactElement;
  afterAction?: () => void;
}

export const Menu = ({ items = [], children, afterAction }: MenuProps) => {
  return (
    <MenuStyleWrapper>
      {items.map((item, index) => {
        return (
          <MenuItem
            {...item}
            key={`menu-item-${index}`}
            afterAction={afterAction}
          />
        );
      })}
      {children}
    </MenuStyleWrapper>
  );
};
