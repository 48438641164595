import * as React from 'react';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ListWrapper } from '../../components/list';

import { addShortcutSet } from 'lib/services/keyboard-service';
import { ItemsPickerController } from 'lib/services/ui-controllers/picker-controller';
import {
  FilterCheckbox,
  InjectAllListItem,
  InjectSectionListItem,
} from './components';
import { showSectionsDialog } from '../manage-sections-dialog';

export const useKeyboardUi = (onDismiss: () => void) => {
  const model = useSamosaModel();
  const { scriptInjectParams } = model;
  const sectionCount = model.referenceScript.sectionRanges.length;

  const picker = React.useMemo<ItemsPickerController>(() => {
    const initialIndex = model.scriptInjectParams.defaultPickerIndex;
    const picker = new ItemsPickerController({
      rows: model.referenceScript.sectionRanges.length + 1,
      initialIndex,
    });
    return picker;
  }, [model]);

  const toggleSelection = React.useCallback(
    (index: number) => () => {
      scriptInjectParams.toggleSectionSelection(index);
    },
    [scriptInjectParams]
  );

  const enterSelection = React.useCallback(() => {
    const index = picker.index - 1; // have to compensate for the full script item
    if (index === -1) {
      // do nothing
      return;
    }

    if (scriptInjectParams.isSectionSelected(index)) {
      onDismiss();
    } else {
      scriptInjectParams.unselectAllSections();
      scriptInjectParams.selectSection(index, true);
      onDismiss();
    }
  }, [onDismiss, picker.index, scriptInjectParams]);

  const onFullScriptClick = React.useCallback(() => {
    scriptInjectParams.toggleAllSections(sectionCount);
  }, [scriptInjectParams, sectionCount]);

  const onSpace = React.useCallback(() => {
    const index = picker.index;
    if (index === 0) {
      onFullScriptClick();
    } else {
      toggleSelection(index - 1)();
      picker.next();
    }
  }, [onFullScriptClick, picker, toggleSelection]);

  React.useEffect(() => {
    return addShortcutSet('section-selection', {
      ...picker.getKeybindings(),
      escape: [onDismiss, null],
      enter: [enterSelection, 'Apply selection'],
      space: [onSpace, 'Toggle section'],
      m: [showSectionsDialog, 'Manage sections'],
    });
  }, [enterSelection, onSpace, onDismiss, picker]);

  return { picker, toggleSelection, onFullScriptClick };
};

export const SectionSelection = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const model = useSamosaModel();
    const { scriptInjectParams } = model;
    const sectionCount = model.referenceScript.sectionRanges.length;

    const { picker, toggleSelection, onFullScriptClick } =
      useKeyboardUi(onDismiss);

    const { sectionLabel } = model;

    return (
      <ListWrapper>
        {/* <ActionButton label={'Inject full script'} onClick={onFullRefScript} /> */}
        <InjectAllListItem
          onClick={onFullScriptClick}
          keyboardSelected={picker.is(0)}
        >
          <FilterCheckbox
            status={
              scriptInjectParams.allSectionsSelected(sectionCount)
                ? 'checked'
                : scriptInjectParams.someButNotAllSectionsSelected(sectionCount)
                ? 'partial'
                : 'unchecked'
            }
          />
          <span className="label">Full Script</span>
        </InjectAllListItem>

        {model.hasSections ? (
          <>
            {/* {console.log(scriptInjectParams.sections)} */}
            {model.referenceScript.sectionRanges.map((range, index) => {
              const label = `${sectionLabel} ${index + 1} (${range.begin}-${
                range.end
              })`;
              const selected = scriptInjectParams.isSectionSelected(index);
              return (
                <InjectSectionListItem
                  selected={selected}
                  onClick={toggleSelection(index)}
                  keyboardSelected={picker.is(index + 1)}
                >
                  <FilterCheckbox status={selected ? 'checked' : 'unchecked'} />
                  <span className="label">{label}</span>
                </InjectSectionListItem>
              );
            })}
          </>
        ) : null}
      </ListWrapper>
    );
  }
);
