import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { AuthorCreateScreen } from './author-create-screen';
import { AuthorEditScreen } from './author-edit-screen';
import { AuthorsListScreen } from './authors-list-screen';
import { AuthorDetailScreen } from './author-detail-screen';

export const AuthorsScreen = () => {
  return (
    <ModelScreen
      path={'/authors'}
      renderList={() => <AuthorsListScreen />}
      renderDetail={controller => (
        <AuthorDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <AuthorEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <AuthorCreateScreen />}
    />
  );
};
