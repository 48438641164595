import { createLogger } from '@app/logger';
import { keynameForEvent } from './keyname-for-event';
import { denormalizeKeys } from './denormalize-keys';
import { KeyboardShortcutsSet } from './types';
import { makeObservable, observable } from 'mobx';

const log = createLogger('keyboard-service');

/**
 * Service for managing keyboard shortcuts.
 */
export class KeyboardService {
  private shortcutSets: Map<string, KeyboardShortcutsSet> = new Map();
  private monitorKeys = false;

  public currentShortcutSetKey: string | null = null;
  public showHelp = false;
  public helpKeys = ['ctrl+slash', 'f1', 'ctrl+h', 'meta+k' /*'shift+slash'*/];

  private static _instance: KeyboardService;

  /**
   * Creates an instance of KeyboardService.
   */
  constructor() {
    this.startListening();
    makeObservable(this, {
      currentShortcutSetKey: observable,
      showHelp: observable,
    });
  }

  /**
   * Enables debug mode for monitoring key events.
   */
  debugMode() {
    this.monitorKeys = true;
  }

  /**
   * Starts listening for key events.
   */
  startListening() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Stops listening for key events.
   */
  stopListening() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  /**
   * Handles keydown events.
   */
  handleKeyDown = (event: KeyboardEvent) => {
    const keyname = keynameForEvent(event);

    if (this.helpKeys.includes(keyname)) {
      event.preventDefault();
      // this.showHelp = !this.showHelp;
      this.toggleHelp();
      return;
    }

    /// this will also close the current modal if it has an onEscapeKeyDown handler.
    /// we should remove the explicit escape keys with most modals
    /// and manage it using keyboard service
    if (
      this.showHelp &&
      (keyname === 'escape' || keyname === 'slash' || keyname === 'shift+slash')
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.showHelp = false;
      return;
    }

    if (event.defaultPrevented) {
      log.debug(`handleKeyDown(${keyname}) - default prevented - ignoring`);
      return;
    }

    if (this.monitorKeys) {
      console.log('keydown', keyname);
    }

    if (this.currentShortcutSetKey) {
      const shortcutSet = this.shortcutSets.get(this.currentShortcutSetKey);
      const [shortcut] = shortcutSet[keyname] ?? [];

      if (typeof shortcut === 'function') {
        // console.log('shortcut', keyname);
        this.showHelp = false;
        event.preventDefault();
        shortcut();
      }
    }
  };

  /**
   * Adds a new set of keyboard shortcuts.
   * @param setName - The key to identify the set.
   * @param shortcuts - The set of keyboard shortcuts.
   * @returns A function to restore the previous set of shortcuts.
   */
  addShortcutSet(setName: string, shortcuts: KeyboardShortcutsSet) {
    const denormalizedShortcuts = denormalizeKeys(shortcuts);
    this.shortcutSets.set(setName, denormalizedShortcuts);
    return this.setCurrentShortcutSet(setName);
  }

  /**
   * Overrides the current set of keyboard shortcuts.
   * @param shortcutSet - The new set of keyboard shortcuts.
   */
  overrideCurrentSet(shortcutSet: KeyboardShortcutsSet) {
    this.shortcutSets.set(this.currentShortcutSetKey, shortcutSet);
  }

  /**
   * Removes a set of keyboard shortcuts.
   * @param key - The key of the set to remove.
   */
  removeShortcutSet(key: string) {
    this.shortcutSets.delete(key);
    if (this.currentShortcutSetKey === key) {
      this.currentShortcutSetKey = null;
    }
  }

  /**
   * Gets the current set of keyboard shortcuts.
   * @returns The current set of keyboard shortcuts.
   */
  getCurrentShortcutSet() {
    return this.shortcutSets.get(this.currentShortcutSetKey);
  }

  /**
   * Sets the current set of keyboard shortcuts.
   * @param newKey - The key of the new set of keyboard shortcuts.
   * @returns A function to restore the previous set of shortcuts.
   */
  setCurrentShortcutSet(newKey: string | null) {
    const previousKey = this.currentShortcutSetKey;
    this.currentShortcutSetKey = newKey;

    const restorePreviousKey = () => {
      this.currentShortcutSetKey = previousKey;
    };

    return restorePreviousKey;
  }

  /**
   * Pauses the current set of keyboard shortcuts.
   * @returns A function to restore the previous set of shortcuts.
   */
  pauseCurrentShortcutSet() {
    const oldSetKey = this.currentShortcutSetKey;
    this.currentShortcutSetKey = null;
    const reset = () => {
      this.currentShortcutSetKey = oldSetKey;
    };
    return reset;
  }

  toggleHelp() {
    this.showHelp = !this.showHelp;
  }

  getCurrentDocumentation(): {
    keyname: string;
    documentation: string;
  }[] {
    const currentSet = KeyboardService.instance.getCurrentShortcutSet();
    if (currentSet) {
      const docs = Object.entries(currentSet)
        // .sort()
        .map(([keyname, [_, documentation]]) => ({
          keyname,
          documentation,
        }));
      return docs.filter(({ documentation }) => documentation !== null);
    }

    return [];
    // console.log({ currentSet });
  }

  /**
   * Gets the singleton instance of KeyboardService.
   * @returns The singleton instance of KeyboardService.
   */
  static get instance() {
    if (!this._instance) {
      this._instance = new KeyboardService();
      (window as any).ks = this._instance;
    }
    return this._instance;
  }

  /**
   * Destroys the singleton instance of KeyboardService.
   */
  static destroyInstance() {
    this._instance = null;
  }
}

export function addShortcutSet(
  setName: string,
  shortcuts: KeyboardShortcutsSet
) {
  return KeyboardService.instance.addShortcutSet(setName, shortcuts);
}

export const slashHelpBinding = {
  'shift-slash, slash': [() => KeyboardService.instance.toggleHelp(), null],
};
