import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ActionDialog, ActionWideDialog } from '../../components/action-dialog';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { PromptEntryModel } from '../../message-entry/prompt-entry-model';
import { DialogPresenter } from '../../dialog-presenter';
import { showSectionsDialog } from '../manage-sections-dialog';
import { InjectFlagSelection } from './inject-flag-selection';
import { InjectButton, LeftPane, Panes, RightPane } from './components';
import { SectionSelection } from './section-selection';

export const InjectScriptDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const model = useSamosaModel();
    const promptModel = PromptEntryModel.instance;
    // usePauseKeyboardService();

    const apppendScript = () => {
      promptModel.appendToPromptText(model.buildSelectedScriptText());
      onDismiss();
    };

    if (model.showExploratoryUI) {
      return (
        <ActionWideDialog
          title="Script selection / injection"
          disableContainerDismissal
          onDismiss={onDismiss}
        >
          <Panes>
            <LeftPane>
              <SectionSelection onDismiss={onDismiss} />
              <ActionLink onPress={showSectionsDialog}>
                Manage sections
              </ActionLink>{' '}
            </LeftPane>
            <RightPane>
              <InjectFlagSelection />
              <InjectButton onClick={apppendScript} />
            </RightPane>
          </Panes>
        </ActionWideDialog>
      );
    } else {
      return (
        <ActionDialog
          onDismiss={onDismiss}
          disableContainerDismissal
          title="Script selection"
        >
          <SectionSelection onDismiss={onDismiss} />
          {/* <InjectButton onClick={apppendScript} /> */}
          <ActionLink onPress={showSectionsDialog}>
            <u>M</u>anage sections
          </ActionLink>{' '}
          (experimental)
        </ActionDialog>
      );
    }
  }
);

export const showInjectDialog =
  DialogPresenter.makePresenter(InjectScriptDialog);
