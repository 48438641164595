import * as React from 'react';

export interface StateSwitch {
  value: boolean;
  toggle: () => void;
  on: () => void;
  off: () => void;
}

export function useSwitch(initialState: boolean): StateSwitch {
  const [value, setValue] = React.useState(initialState);
  const toggle = () => setValue(!value);
  const on = () => setValue(true);
  const off = () => setValue(false);
  return { value, toggle, on, off };
}

// export default useSwitch;
