import { SoundbiteEditionData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Textarea } from '../../components/forms';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';

interface Props {
  dto: SoundbiteEditionData;
  onSubmit: (dto: SoundbiteEditionData) => void;
  onCancel: () => void;
}

export const SoundbiteEditionForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'name',
    'channelId',
    'volumeId',
    'calendarSummary',
    'visibilityWeekPostEpoch',
    'linearUrl',
  ]);

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);
  const channelOptions = ChannelManager.getInstance().selectOptionsWithBlank;

  const channel = ChannelManager.getInstance().getById(dto.channelId);
  const volumeOptions =
    channel?.volumeSelectOptions ||
    VolumeManager.getInstance().selectOptionsWithBlank;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select
        {...getFieldProps('channelId')}
        options={channelOptions}
        required
      />
      <hr />
      <Select {...getFieldProps('volumeId')} options={volumeOptions} required />
      <Input
        {...getFieldProps('name')}
        label={'Editorial workflow name (shown on list views)'}
        required
      />
      <Input
        {...getFieldProps('linearUrl')}
        label={'Linear URL'}
        hint="(optional)"
      />
      <hr />
      <Textarea
        {...getFieldProps('calendarSummary')}
        label={'Summary (shown in calendar view)'}
        hint={'Markdown (you can use **bold** _italics_ etc)'}
        rows={3}
      />
      <Input
        {...getFieldProps('visibilityWeekPostEpoch')}
        label={'release week number (relative to deployment server epoch)'}
      />
      <FormButtons
        submitLabel={isNew ? 'Create' : 'Save changes'}
        onCancel={onCancel}
      />
    </form>
  );
};
