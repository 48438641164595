import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box } from 'rebass/styled-components';
import { Volume } from '@masala-lib/catalog/models/volume';
import { SearchBar } from '../shared/search-bar';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
import { PageActions } from '../shared/page-actions';
import { PageHeading } from '../shared/page-heading';
import { VolumesTable } from './volumes-table';
import { FiltersBar } from '../shared/filters-bar';
import { SortControls } from '../units/sort-controls';
import { FilterControls } from '../shared/filters/filter-controls';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { useLocation, useNavigate } from 'react-router-dom';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

interface RouteSearchParams {
  channel?: string;
  sort?: string;
  order?: string;
}

export const VolumeListScreen = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    channel,
    sort = 'updatedAt',
    order = 'desc',
  }: RouteSearchParams = getQueryParamsFromLocation(location);
  const auth = Auth.getInstance();
  const manager = VolumeManager.getInstance();

  const channelOptions = ChannelManager.getInstance().selectOptionsWithAll;
  // const [channelIdFilter, setChannelIdFilter] = React.useState(channelId || '');

  useEffect(() => {
    console.log(`useEffect - setChannelFilter: ${channel}`);
    manager.setFilter('channelId', channel, 'channel');
    manager.setSorting(sort, order);
  }, [manager, channel, sort, order]);

  const filterValueDecorator = (filterName: string, value: string) => {
    if (filterName === 'channelId') {
      return channelOptions.find(ch => ch.value === value)?.label;
    }
    return value;
  };

  const list: Volume[] = manager.filteredList;

  return (
    <Page
      title="Volumes"
      controls={
        <PageActions>
          <SearchBar manager={manager} />
          {auth.can('manage_volumes') ? (
            <button
              onClick={() => navigate(`/volumes/new?channel=${channel}`)}
              className={'primary-action'}
            >
              New Volume
            </button>
          ) : null}
        </PageActions>
      }
    >
      <OnePaneLayout>
        <PageHeading>
          {/* {!!channel ? (
            <>
              <NavLink to={`/channels/${channel}`}>Channel details</NavLink>
              <Link className="secondary-action" to={`/units?channel=${channel}&sort=volumeName`}>
                Units
              </Link>
            </>
          ) : null} */}
          <SortControls
            fields={[
              { fieldName: 'name', label: 'Name' },
              { fieldName: 'slug', label: 'Slug' },
              { fieldName: 'createdAt', label: 'Created at' },
              { fieldName: 'updatedAt', label: 'Updated at' },
            ]}
            currentSortingFieldName={manager.sorting.fieldName}
            currentSortingOrder={manager.sorting.order}
          />
          <FilterControls path="/volumes" filters={['channel']} />
          <FiltersBar
            filters={manager.filters}
            nameMap={{ channelId: 'Channel' }}
            valueDecorator={filterValueDecorator}
          />
        </PageHeading>
        <Stack>
          {list.length > 0 ? (
            <VolumesTable volumes={list} />
          ) : (
            <Box p={4} style={{ backgroundColor: 'var(--color-yellow100)' }}>
              No volumes found
            </Box>
          )}

          {/* <ActionLink onPress={handleRefresh}>[refresh]</ActionLink> */}
        </Stack>
      </OnePaneLayout>
    </Page>
  );
});

// export const VolumeList = observer(({ list, selected }: { list: Volume[]; selected: Volume }) => {
//   console.log(`VolumeList-selected: ${JSON.stringify(selected)}`);
//   return (
//     <ListOfLinks>
//       <ul>
//         {list.map((model: Volume) => (
//           <li key={model.id}>
//             <NavLink to={`/volumes/${model.id}`}>
//               <Row model={model} isSelected={model.id === selected?.id} />
//             </NavLink>
//           </li>
//         ))}
//       </ul>
//     </ListOfLinks>
//   );
// });

// const Row = observer(({ model, isSelected }: { model: Volume; isSelected: boolean }) => {
//   const style = isSelected ? 'console-selected-row' : '';
//   return (
//     <div className={style}>
//       {model.name} ({model.path})
//     </div>
//   );
// });
