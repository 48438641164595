import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Table } from '../shared/table';
import { VideoGuide } from '@masala-lib/catalog/models/video-guide';

export const VideoGuidesTable = observer(({ list }: { list: VideoGuide[] }) => {
  return (
    <Table
      data={list}
      cells={[
        {
          header: 'Title',
          render: model => <Link to={model.navPath}>{model.name}</Link>,
        },
      ]}
    />
  );
});
