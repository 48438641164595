import * as React from 'react';
// import { styled } from '@naan/stitches.config';
// import { observer } from 'mobx-react';
// import { MdOutlineKeyboardAlt } from 'react-icons/md';
// import {
//   BsArrowRightShort as Right,
//   BsArrowLeftShort as Left,
//   BsArrowUpShort as Up,
//   BsArrowDownShort as Down,
// } from 'react-icons/bs';
// import { useSwitch2 } from '@naan/hooks/use-switch-2';

type Handler = (event: MouseEvent | TouchEvent) => void;

/// we can move it somewhere else if we need it in other places
export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: Handler,
  options: {
    exception?: React.RefObject<HTMLElement>;
  } = {}
) {
  const { exception = null } = options;

  React.useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref) {
          return;
        }

        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        if (exception && exception?.current?.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, exception]
  );
}

// const KeyboardHelpWrapper = styled('div', {
//   $$scale: 0.8,
//   $$opacity: 0,

//   '& .icon': {
//     all: 'unset',
//     color: '$colors$gray-500',
//     width: 24,
//     height: 24,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     borderRadius: 4,
//   },

//   '&:hover .icon': {
//     color: '$colors$gray-900',
//     background: '$colors$gray-100',
//   },

//   '& > .pop': {
//     pointerEvents: 'none',
//     position: 'absolute',
//     left: 8,
//     bottom: 40,
//     background: '$colors$yellow-50',
//     boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
//     borderRadius: 8,
//     padding: 12,
//     transform: 'scale($$scale)',
//     //put transform origin in the bottom left corner
//     transformOrigin: 'bottom left',
//     opacity: '$$opacity',
//     transition: 'transform 0.2s, opacity 0.2s',
//   },

//   // '&:has(.icon:hover) > .pop': {
//   //   $$scale: 1,
//   //   $$opacity: 1,
//   // },

//   '&.open > .pop': {
//     $$scale: 1,
//     $$opacity: 1,
//   },

//   dl: {
//     margin: 0,
//     padding: 4,
//     display: 'grid',
//     gap: 8,
//     gridTemplateColumns: 'auto 1fr',
//     alignContent: 'center',
//     '& + &': {
//       borderTop: '1px solid $colors$gray-50',
//     },
//     dt: {
//       display: 'flex',
//       gap: 8,
//     },
//   },

//   '& kbd': {
//     all: 'unset',
//     display: 'inline-flex',
//     color: '$colors$gray-500',
//     backgroundColor: '$colors$gray-100',
//     borderRadius: 4,
//     border: '1px solid $colors$gray-200',
//     justifyContent: 'center',
//     alignItems: 'center',
//     width: 24,
//     height: 18,
//     fontSize: 11,
//     lineHeight: 1,
//     fontWeight: '700',
//   },
// });

// export const TranslationMergeKeyboardHelp = observer(() => {
//   const popSwitch = useSwitch2(false);
//   const popRef = React.useRef<HTMLDivElement>(null);
//   const btnRef = React.useRef<HTMLButtonElement>(null);
//   useOnClickOutside(
//     popRef,
//     () => {
//       if (popSwitch.value) {
//         popSwitch.off();
//       }
//     },
//     {
//       exception: btnRef,
//     }
//   );

//   React.useEffect(() => {
//     // listen for '?' key globally
//     const onKeydown = (event: KeyboardEvent) => {
//       event.stopPropagation();
//       if (event.key === '?' || event.key === '/') {
//         popSwitch.toggle();
//       }
//     };
//     document.addEventListener('keydown', onKeydown);
//     return () => {
//       document.removeEventListener('keydown', onKeydown);
//     };
//   });

//   return (
//     <KeyboardHelpWrapper className={popSwitch.value ? 'open' : ''}>
//       <div className="pop" ref={popRef}>
//         <dl>
//           <dt>
//             <kbd>
//               <Left />
//             </kbd>
//             <kbd>
//               <Right />
//             </kbd>
//           </dt>
//           <dd>Move between warnings</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>
//               <Up />
//             </kbd>
//             <kbd>
//               <Down />
//             </kbd>
//           </dt>
//           <dd>Move between elements</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>W</kbd>
//           </dt>
//           <dd>Pick winning translation</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>SPC</kbd>
//           </dt>
//           <dd>Pick and advance</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>A</kbd>
//           </dt>
//           <dd>Add free-form translation</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>Enter</kbd>
//           </dt>
//           <dd>Edit/clone translation</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>N</kbd>
//           </dt>
//           <dd>
//             Add/edit note
//             <br />
//             (Automatically flags)
//           </dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>T</kbd>
//           </dt>
//           <dd>
//             Flag
//             <br />
//             (To be addressed before sending back to Script editor)
//           </dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>L</kbd>
//           </dt>
//           <dd>
//             Defer
//             <br />
//             (Note will be sent back to Script editor as comment)
//           </dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>R</kbd>
//           </dt>
//           <dd>
//             Resolve
//             <br />
//             (Clears flagged/deferred state)
//           </dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>Shift-N</kbd>
//           </dt>
//           <dd>Show/hide notes</dd>
//         </dl>
//         <dl>
//           <dt>
//             <kbd>O</kbd>
//           </dt>
//           <dd>Show/hide unpicked</dd>
//         </dl>
//       </div>
//       <button className="icon" onClick={popSwitch.toggle} ref={btnRef}>
//         <MdOutlineKeyboardAlt />
//       </button>
//     </KeyboardHelpWrapper>
//   );
// });
