import * as React from 'react';
import { markderp } from '../../lib/markderp';

interface FormatTextProps {
  text: string;
}

export const FormatText = ({ text }: FormatTextProps) => {
  const parts = markderp(text);
  const Italic = 'em';
  return (
    <>
      {parts.map(part => {
        if (part.type === 'i') {
          return <Italic>{part.content}</Italic>;
        }
        return part.content;
      })}
    </>
  );
};
