import React from 'react';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import { Auth } from '@masala-lib/editorial/db/auth';
import {
  AppUser,
  UserManager,
} from '@masala-lib/editorial/models/user-manager';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';
import { pick } from 'lodash';
import { DataCard } from '../../components/data-display/data-card';
import { Card } from '../../components/data-display/card';
import { Stack } from '../shared/stack';
import { PageActions } from '../shared/page-actions';
import { ListOfLinks } from '../shared/list-of-links';
import { notEmpty } from '@utils/conditionals';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';

interface Props {
  manager: FilterViewManager<AppUser>; //UserManager;
  onStartEditing: () => void;
  auth: Auth;
}

export const UserDetail = observer(
  ({ manager, auth, onStartEditing: startEditing }: Props) => {
    const { id } = useParams<{ id: string }>();
    const model = manager.fetchById(id);
    const scopedChannelName = notEmpty(model.scopedChannelId)
      ? ChannelManager.getInstance().getById(model.scopedChannelId)?.name
      : 'n/a';
    const handleDelete = () => {
      if (
        window.confirm(
          `Are you sure you want to delete ${model.name}. This is not undoable`
        )
      ) {
        const deletableName = window.prompt(
          `Type the name of the user ${model.name} to confirm deletion`
        );
        if (deletableName.toLowerCase() === model.name.toLowerCase()) {
          // manager.delete(model).then(history.goBack);
        }
      }
    };

    const data = pick(model, [
      'id',
      'alias',
      'email',
      'name',
      'slackMemberId',
      'isReviewer',
      'isAdmin',
      'isSuperAdmin',
      'mayMakeLive',
    ]) as any;
    // hack in the display value because we don't have a class to define a getter on
    // can be cleaned up if we refactor to use TST
    data.partnerAccessRestrictionChannel = scopedChannelName;

    return (
      <Stack>
        <DataCard
          data={data}
          footer={
            <PageActions>
              {auth.isAdmin ? (
                <>
                  <button onClick={startEditing} className="primary-action">
                    Edit
                  </button>
                  {auth.isSuperAdmin ? (
                    <button onClick={handleDelete} className="danger-action">
                      Delete
                    </button>
                  ) : null}
                </>
              ) : null}
            </PageActions>
          }
        />
        <Card header={`${model.name} conversations`}>
          <ListOfLinks>
            <ul>
              <li>
                <Link to={`/conversations/participant/${model.id}`}>
                  As participant
                </Link>
              </li>
              <li>
                <Link to={`/conversations/assignee/${model.id}`}>
                  As assignee
                </Link>
              </li>
            </ul>
          </ListOfLinks>
        </Card>
      </Stack>
    );
  }
);
