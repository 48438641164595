import * as React from 'react';
import type { SamosaModel } from 'samosa/models/samosa-model';
import { observer } from 'mobx-react';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { ReferenceScriptStep } from '../reference-script/reference-script-step';
import { ExchangeStep } from './exchange-step';
import { styled } from '@naan/stitches.config';
import { Step } from '@masala-lib/llm/project/llm-project-types';
// import { useKeyboardBinding } from '@console/lib/hooks/use-keyboard-binding';
// import { presentLlmSettingsModal } from '../sidebar/llm-settings-form';
import { KeyboardService } from 'lib/services/keyboard-service';
import { showInjectDialog } from '../editing-action-bar/inject-script-modal';
import { showAutomationDialog } from '../editing-action-bar/automation-modal';
import { PromptEntryModel } from '../message-entry/prompt-entry-model';
import { showPromptPicker } from '../prompts/prompt-picker-modal';

const Wrapper = styled('div', {
  width: 'min( 100% - 32px, var(--maxChatWidth) )',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

const stepTypeMap: {
  [index: string]: ({ step }: { step: Step }) => JSX.Element;
} = {
  REFERENCE_SCRIPT: ReferenceScriptStep,
  EXCHANGE: ExchangeStep,
};

function useCurrentThreadAreaKeyboardBindings(
  model: SamosaModel,
  promptModel: PromptEntryModel
) {
  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('main', {
      // section scoped merge should be primarily used, so hot key not needed for global merge
      // 'ctrl+m': [() => model.touchupMergeScript(), 'Open merge view'],
      // 'meta+o': [presentLlmSettingsModal, 'Open LLM settings modal'],
      'ctrl+p': [
        () => {
          promptModel.setShowSettings(false);
          showPromptPicker();
        },
        'Use / manage prompts',
      ],
      'ctrl+s': [
        () => {
          promptModel.setShowSettings(false);
          showInjectDialog();
        },
        'Manage script selection',
      ], // probably make this 's'
      'ctrl+u': [showAutomationDialog, null], // omit from help
      // todo: figure out better key bindings
      'ctrl+l': [() => promptModel.showLlmPicker(), 'Pick LLM'],
      'ctrl+equal': [
        () => promptModel.increaseTemperature(),
        'Increase temperature',
      ],
      'ctrl+minus': [
        () => promptModel.decreaseTemperature(),
        'Decrease temperature',
      ],
      // let's keep ^e for the default 'end of line'. not sure if the streaming toggle deserves a hot key
      // 'ctrl+e': [() => model.toggleStreaming(), 'Toggle response streaming'],
      'ctrl+g': [
        () => promptModel.toggleShowSettings(),
        'Toggle action / settings bar',
      ],
      'ctrl+enter': [
        () => promptModel.submitToModel(model, false),
        'Submit request (append)',
      ],
      'shift+ctrl+enter': [
        () => promptModel.submitToModel(model, true),
        'Submit into new thread',
      ],
      // i think ^n is potentially too disorienting to invoke accidentally instead of "next line"
      // 'ctrl+n': [() => model.setNewThread(), 'Select new thread'],
      'ctrl+f': [
        () => PromptEntryModel.instance.focusPromptArea(),
        'Focus prompt text area',
      ],
    });
  }, [model, promptModel]);
}

//
// main thread area, displaying the steps for what's selected from the sidebar
//
export const CurrentThreadArea = observer(() => {
  const model = useSamosaModel();
  const promptModel = PromptEntryModel.instance;

  useCurrentThreadAreaKeyboardBindings(model, promptModel);

  return (
    <Wrapper>
      {model.visibleThread.map(step => {
        const BodyComponent = stepTypeMap[step.kind];
        return <BodyComponent key={step.id} step={step} />;
      })}
    </Wrapper>
  );
});
