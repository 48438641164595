import { makeObservable } from 'mobx';
import {
  deploymentConfig,
  soundbitePreviewL2Url,
} from '../../deployment-config';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';

import { UnitManager } from '../db/unit-manager';
import {
  CatalogEntityKind,
  CatalogStage,
  ExcerptData,
  ExcerptKind,
  ExcerptStatus,
} from '../catalog-types';
import { Unit } from './unit';
import { ExcerptManager } from '../db/excerpt-manager';
import { Channel } from './channel';
import { Volume } from './volume';
import { invariant } from 'mobx-utils';
import { createLogger } from '@app/logger';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { difference, omit, pick, snakeCase } from 'lodash';
// import stripBom from 'strip-bom'; // this is breaking the ability to run jset tests
import { StringToString } from '@tikka/basic-types';
import { agePretty } from '@masala-lib/utils';
import {
  notEmptyOrNA,
  strongNormalizeWord,
} from '@masala-lib/misc/editorial-string-utils';
import { UnitCrud } from '../db/unit-crud';
import { EntityCrud } from '../db/entity-crud';
import { ExcerptCrud } from '../db/excerpt-crud';

const log = createLogger('excerpt');

export const emptyExcerptData: ExcerptData = {
  kind: CatalogEntityKind.EXCERPT,
  excerptKind: ExcerptKind.SOUNDBITE,
  status: ExcerptStatus.SUGGESTED,
  id: '',
  name: '',
  slug: '',
  title: '',
  unitId: '',
};

export class Excerpt
  extends BaseCatalogEntity<ExcerptData>
  implements CatalogEntity<ExcerptData>
{
  fetchedUnit: Unit;
  fetchedClientSlug: string;

  constructor(d?: Partial<ExcerptData>) {
    super(d as ExcerptData);
    this.data = { ...emptyExcerptData, ...d };
    makeObservable(this);
  }

  get slug() {
    return this.data.slug;
  }

  get name() {
    return this.data.name || this.data.title;
  }

  get unitId() {
    return this.data.unitId;
  }

  get channelId() {
    return this.volume?.channelId;
  }

  get volumeId() {
    return this.volume?.id;
  }

  // the soundbite slugs don't actually get munged when a volume is forked
  get clientSlug() {
    // if (this.fetchedClientSlug) {
    //   return this.fetchedClientSlug;
    // } else {
    //   throw Error('clientSlug not pre-fetched');
    // }
    return this.slug;
  }

  get navPath(): string {
    return `/excerpts/${this.id}`;
  }

  get scriptEditorUrl(): string {
    return this.unit.resolveEditorUrl(null, this.data.elementId);
  }

  get soundbitePreviewUrl(): string {
    if (this.data.dataUrl) {
      return soundbitePreviewL2Url(this.data.dataUrl, this.unit.l2);
    } else {
      return null;
    }
  }

  get unit(): Unit {
    if (this.fetchedUnit) {
      return this.fetchedUnit;
    } else {
      return UnitManager.getInstance().getById(this.unitId);
    }
  }

  async fetchUnit(): Promise<Unit> {
    if (!this.fetchedUnit) {
      this.fetchedUnit = await UnitCrud.loadById(this.unitId, {
        fetchParents: true,
      });
    }
    return this.fetchedUnit;
  }

  // should remove usages of this
  async ensureParents(): Promise<Unit> {
    this.fetchedUnit = await UnitManager.getInstance().loadById(this.unitId);
    this.fetchedUnit?.ensureParents();
    return this.fetchedUnit;
  }

  get volume(): Volume {
    return this.unit?.volume;
  }

  get channel(): Channel {
    return this.volume?.channel;
  }

  get channelSlug(): string {
    const result = this.channel?.slug;
    invariant(!!result, 'Excerpt: unresolved channelSlug');
    return result;
  }

  get categorySlug(): string {
    return (this.data.categories || '').split(',')[0].trim();
  }

  get volumeNormalizedName(): string {
    return this.volume?.normalizedName;
  }

  // get isPublished(): boolean {
  //   return this.data.status === ExcerptStatus.PUBLISHED;
  // }

  includedForStage(stage: CatalogStage): boolean {
    if (stage === CatalogStage.REVIEW) {
      return [
        ExcerptStatus.PUBLISHED,
        ExcerptStatus.DONE,
        ExcerptStatus.QA,
        ExcerptStatus.IN_PROGRESS,
      ].includes(this.data.status);
    } else {
      return this.data.status === ExcerptStatus.PUBLISHED;
    }
  }

  get releaseDateSort(): string {
    return notEmptyOrNA(this.data.releaseDate) ? this.data.releaseDate : null;
  }

  get ingestedAt(): string {
    return this.data.ingestedAt || new Date(0).toISOString(); // ensure that sorts won't barf
  }

  get ingestionAgePretty(): string {
    return agePretty(this.data.ingestedAt);
  }

  get isCatalogStale(): boolean {
    const ingestedAt = this.data.ingestedAt;
    if (!ingestedAt) return false;
    const publishedAt = this.channel.data?.reviewCaliVersion?.generatedAt;
    return !publishedAt || ingestedAt > publishedAt;
  }

  filterMatch(text: string) {
    const baseResult = super.filterMatch(text);
    if (baseResult) {
      return true;
    }
    if (!this.data?.title) {
      return false;
    }
    const match = strongNormalizeWord(text);
    return strongNormalizeWord(this.data.title).includes(match);
  }

  async ensureClientSlug(): Promise<string> {
    if (!this.fetchedClientSlug) {
      this.fetchedClientSlug = await this.resolveClientSlug();
    }
    return this.fetchedClientSlug;
  }

  // this wasn't actually needed
  async resolveClientSlug(): Promise<string> {
    // await this.unit.ensureFetchedVolume();
    // // await this.ensureParents();
    // const parentVolume = await this.volume.fetchMasterVolume();
    // if (parentVolume) {
    //   const matchedExcerpt = await parentVolume.resolveMatchedExcerpt(this);
    //   if (matchedExcerpt) {
    //     return matchedExcerpt.slug;
    //   } else {
    //     // todo: should probably make this less fatal once confirmed basically working
    //     throw Error(`unable to resolve master excerpt for ${this.slug}`);
    //   }
    // }
    return this.slug;
  }

  async ingest(): Promise<string> {
    this.setPendingOperation('ingesting excerpt...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/ingestExcerpt?excerptId=${this.id}`;
    console.log(`excerpt.ingest - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    if (response.status !== 200) {
      const responseText: string = await response.text();
      throw Error(`invoke failed: ${responseText}`);
    }
    const data = await response.json();
    console.log(`response: ${JSON.stringify(data)}`);
    this.clearPendingOperation();
    return data.dataUrl;
  }

  async importFromUrl(
    sourceUrl: string
  ): Promise<{ result?: string; error?: string }> {
    log.info(`importFromUrl ${sourceUrl}`);
    if (!sourceUrl.endsWith('.json')) {
      throw Error(
        `Volume.importFromExportedUrl - unexpected file extension: ${sourceUrl}`
      );
    }
    consoleAddAuditLogMessage(
      this.unit.id,
      `import excerpt (${this.slug} from: ${sourceUrl}`
    );
    this.setPendingOperation('importing export data...');
    const response = await fetch(sourceUrl);
    const text = await response.text();
    const data = JSON.parse(text);

    if (!data.volumeDoc || !data.units) {
      throw Error(`invalid export data`);
    }

    let matched = false;
    if (data.excerpts) {
      for (const excerptData of data.excerpts) {
        if (excerptData.elementId === this.data.elementId) {
          log.info(
            `importFromUrl - matched ${excerptData.slug} - updating with version ${excerptData.ingestVersion}, ${excerptData.ingestedAt}`
          );
          const updateData = omit(excerptData, ['id', 'unitId']);
          await this.updatePartial(updateData);
          matched = true;
        }
      }
    }

    this.clearPendingOperation();
    return matched
      ? { result: 'Success' }
      : { error: 'Matching source data not found' };
  }

  async importFromScript(
    script: string
  ): Promise<{ result?: string; error?: string }> {
    log.info(`importFromScript`);

    consoleAddAuditLogMessage(
      this.unit.id,
      `import excerpt (${this.slug} from script`
    );

    const data = parseScriptBlocks(script);
    const updateData = pick(data, scriptKeys);

    const keys = Object.keys(updateData);
    const count = keys.length;
    if (count === 0) {
      throw Error(`unable to parse data`);
    }
    const missing = difference(scriptKeys, keys);

    await this.updatePartial(updateData);
    const message =
      missing.length > 0
        ? `Partially imported. Missing keys: ${JSON.stringify(missing)}`
        : 'Success';

    return { result: message };
  }

  // get generateScriptUrl(): string {
  //   return `${deploymentConfig.masalaServerUrl}/generateExcerptScript?id=${this.id}&download=false`;
  // }

  get dumpScriptPath(): string {
    return `/excerpts/${this.id}/dump`;
  }

  get dumpScript(): string {
    const {
      title,
      categories,
      promptEn,
      prompt,
      vocabulary,
      explanationEn,
      explanation,
    } = this.data;

    const lines: string[] = [];
    lines.push(`[TITLE]\n${title}\n`);
    lines.push(`[CATEGORIES]\n${categories}\n`);
    lines.push(`[ENGLISH PROMPT]\n${promptEn}\n`);
    lines.push(`[PROMPT]\n${prompt}\n`);
    lines.push(`[VOCABULARY]\n${vocabulary}\n`);
    lines.push(`[ENGLISH EXPLANATION]\n${explanationEn}\n`);
    lines.push(`[EXPLANATION]\n${explanation}\n`);
    lines.push('[END]\n');

    return lines.join('\n');
  }

  get crud(): EntityCrud<Excerpt, ExcerptData> {
    return ExcerptCrud;
  }

  theManager(): EntityManager<CatalogEntity<ExcerptData>, ExcerptData> {
    return ExcerptManager.getInstance() as unknown as EntityManager<
      CatalogEntity<ExcerptData>,
      ExcerptData
    >;
  }
}

const scriptKeys = [
  'title',
  'prompt',
  'vocabulary',
  'explanation',
  'categories',
];

// extracts each [{key}]\n{body} section
export const parseScriptBlocks = (rawText: string): StringToString => {
  // console.log(`raw text: ${rawText}`);
  const matches = stripBom(rawText).matchAll(/^\[(.*?)\]\n([^[]*)/gms); // m=multiline, s='allows . to match newline', ?=don't be greedy
  const result = {} as StringToString;
  for (const match of matches) {
    console.log(`match: ${JSON.stringify(match)}`);
    const [_, rawKey, rawValue] = match;
    const key = snakeCase(rawKey.trim()).replace(/ /g, '_');
    const value = rawValue.trim();
    console.log(`block: (${key}) -> (${value})`);
    result[key] = value;
  }
  return result;
};

// copilot generated
function stripBom(str: string): string {
  if (str.charCodeAt(0) === 0xfeff) {
    return str.slice(1);
  }
  return str;
}
