import { observer } from 'mobx-react';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { ActivityGuidesTable } from './activity-guides-table';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';

export const ActivityGuidesListScreen = observer(() => {
  const manager = ActivityGuideManager.getInstance();
  const list = manager.list;

  return (
    <Page
      title="Manage ActivityGuides"
      controls={
        <PageActions>
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <ActivityGuidesTable activityGuides={list} />
      </OnePaneLayout>
    </Page>
  );
});
