import * as React from 'react';
import { DataItem } from './data-item';
import { Card } from './card';
import { phraseize } from '../../lib/phraseize';
import { isBoolean } from 'lodash';

const defaultDecorator = (value: any) => {
  if (isBoolean(value)) {
    return value ? 'yes' : 'no';
  }

  return value;
};

type dataType = { [key: string]: any };

interface DataCardProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  data: dataType;
  valueDecorator?: (value: any) => React.ReactNode;
}

export const DataCard = ({
  header = null,
  footer = null,
  valueDecorator = defaultDecorator,
  data,
}: DataCardProps) => {
  const keys = Object.keys(data);

  return (
    <Card header={header} footer={footer}>
      {keys.map(key => (
        <DataItem
          key={key}
          label={phraseize(key)}
          value={valueDecorator(data[key])}
        />
      ))}
    </Card>
  );
};
