import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { styled } from '@naan/stitches.config';
import { KeyboardService } from 'lib/services/keyboard-service';

const DialogContainer = styled(Dialog.Container, {
  top: '50%',
  width: 'min( 100% - 32px, 920px )',
  maxHeight: 'calc( 100% - 32px)',
  '&  h2': {
    textStyle: 'body-bold',
    color: '$colors$textPrimary',
  },
});

export const SimpleDialog = ({
  onDismiss,
  disableContainerDismissal = false,
  children = null,
  title,
}: {
  onDismiss: () => void;
  disableContainerDismissal?: boolean;
  children?: React.ReactNode;
  title?: React.ReactNode;
}) => {
  const onContainerDismiss = disableContainerDismissal ? undefined : onDismiss;

  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('simple', {
      escape: [onDismiss, 'Close'],
    });
  }, [onDismiss]);

  return (
    <DialogContainer
      open
      onDismiss={onContainerDismiss}
      // onEscapeKeyDown={onDismiss}
    >
      <Dialog.Heading>
        {title}
        {/* <Dialog.CloseButton /> */}
        <Dialog.HtmlCloseButton onDismiss={onDismiss} />
      </Dialog.Heading>
      <Dialog.Body>{children}</Dialog.Body>
    </DialogContainer>
  );
};
