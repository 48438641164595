import * as React from 'react';
import { UseFormReturn, Path, FieldErrors, get } from 'react-hook-form';
import {
  InputStyleWrapperCss,
  InputStyleWrapper as Wrapper,
} from './input-style-wrapper';
import cx from 'classnames';
import { useUniqueId } from '../../lib/hooks/use-unique-id';
import { ErrorMessage } from './error-message';
import { InputProps } from './input';

type TextareaProps<T> = InputProps<T> & {
  rows?: number;
  // css?: object;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
};

export function Textarea<T>({
  name,
  label,
  hint,
  formContext,
  required = false,
  css,
  ...inputProps
}: TextareaProps<T> & InputStyleWrapperCss) {
  const {
    register,
    formState: { errors },
  } = formContext;

  const id = useUniqueId();
  const validationRules = { required };
  const error = get(errors, name, null);

  return (
    <Wrapper className={cx({ 'with-error': !!error })} css={css}>
      <label htmlFor={id}>{label}</label>
      <textarea id={id} {...register(name, validationRules)} {...inputProps} />
      <ErrorMessage name={name} error={error} />
      {hint ? <div className="hint">{hint}</div> : null}
    </Wrapper>
  );
}
