import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { LocaleCode } from '@utils/util-types';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { Volume } from '@masala-lib/catalog/models/volume';
import { stringToBool } from '@utils/string-utils';
import {
  VolumeCaliDataBuilder,
  VolumeDataFlavor,
} from '@masala-lib/catalog/cali/volume-cali-data-builder';
import { getQueryParamsFromLocation } from '../console/lib/query-stuff';

export const VolumeCatalogDataScreen = () => {
  const { volumeId, locale } =
    useParams<{ volumeId: string; locale: LocaleCode }>();

  // needs react router upgrade
  // const [queryParams] = useSearchParams();
  // const gen = stringToBool(queryParams.get('gen'));

  const location = useLocation();
  // generate dynamically (vs displayed last ingestion)
  const detail = stringToBool(getQueryParamsFromLocation(location).detail);
  const gen = stringToBool(getQueryParamsFromLocation(location).gen) || detail; // also render the detail view

  const [data, setData] = useState<object>({ status: 'loading...' });

  React.useEffect(() => {
    VolumeManager.getInstance()
      .loadSoloById(volumeId)
      .then(async (volume: Volume) => {
        if (volume) {
          if (gen) {
            const flavor = detail
              ? VolumeDataFlavor.DETAIL
              : VolumeDataFlavor.CATALOG;
            VolumeCaliDataBuilder.create(volume, flavor).then(builder => {
              const data = builder.buildData();
              setData(data);
            });
          } else {
            const data = volume.data.reviewVersion?.catalogData || {
              error: 'cali catalog data not yet generated',
            };
            setData(data);
          }
        } else {
          setData({ error: `Failed to load volume[${volumeId}]` });
        }
      });
  }, [volumeId, locale, gen, detail]);

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
