import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { IconButton } from '@naan/primitives/button';
import { PromptEntryModel } from '../message-entry/prompt-entry-model';
import { CloseLargeIcon } from '@naan/icons/close-icon';
import { SettingsBar } from './settings-bar';
import { QuickActions } from './quick-actions';
import classNames from 'classnames';

const Wrapper = styled('div', {
  height: 44,
  top: -44,
  left: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background:
    'linear-gradient(180deg, rgba(255,255,255,0) 50%, rgba(238,238,238,0.5) 100%)',

  '& > .inner': {
    width: 'min( 100% - 32px, var(--maxChatWidth) )',
    // background: 'blue',
    position: 'relative',
    height: 44,

    '& > .toggler': {
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
    },

    '& > .controls-container': {
      position: 'absolute',
      top: 0,
      left: 44,
      right: 44,
      height: 44,
      // width: '100%',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      // background: 'red',
      transform: 'translateX(0%)',
      opacity: 1,
      transition:
        'transform 0.16s cubic-bezier(0.9,0.03,0.69,0.22), opacity 0.16s linear',
    },

    // '& > .quick': {
    //   transform: 'translateX(0%)',
    // },

    '& > .llm-settings': {
      transform: 'translateX(-60%)',
      opacity: 0,
      pointerEvents: 'none',
      // justifyContent: 'flex-start',
    },

    '&.show-settings': {
      '& > .quick': {
        transform: 'translateX(50%)',
        opacity: 0,
        pointerEvents: 'none',
      },

      '& > .llm-settings': {
        transform: 'translateX(0%)',
        opacity: 1,
        pointerEvents: 'auto',
      },
    },

    // zIndex: -1,
  },
});

export const EditingActionBar = observer(() => {
  const promptModel = PromptEntryModel.instance;

  const toggleSettings = (): void => promptModel.toggleShowSettings();

  return (
    <>
      <Wrapper>
        <div
          className={classNames('inner', {
            'show-settings': promptModel.showSettings,
          })}
        >
          <div className="toggler">
            <IconButton
              icon={
                promptModel.showSettings ? <CloseLargeIcon /> : <SettingsIcon />
              }
              onClick={toggleSettings}
              size={'small'}
              tabIndex={-1}
              title="Toggle LLM Settings"
              testId="action-bar-settings-toggle"
            />
          </div>
          <div className="llm-settings controls-container">
            <SettingsBar />
          </div>
          <div className="quick controls-container">
            <QuickActions />
          </div>
        </div>
      </Wrapper>
    </>
  );
});
