import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { FeaturedReleaseData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { useFeaturedRelease } from './use-featured-release';
import { FeaturedReleaseForm } from './featured-release-form';
import { FeaturedReleaseManager } from '@masala-lib/catalog/db/featured-release-manager';

interface Params {
  stopEditing: () => void;
}

export const FeaturedReleaseEditScreen = observer(({ stopEditing }: Params) => {
  const manager = FeaturedReleaseManager.getInstance();
  const model = useFeaturedRelease();

  if (!model) {
    return null;
  }

  const onSubmit = (dto: FeaturedReleaseData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    model.fromFormData(dto);
    manager.update(model).then(stopEditing);
  };

  return (
    <Page title={`Editing ${model.name}`}>
      <OnePaneLayout>
        <FeaturedReleaseForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
        />
      </OnePaneLayout>
    </Page>
  );
});
