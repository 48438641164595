import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { UnitListScreen } from './unit-list-screen';
import { UnitDetailScreen } from './unit-detail-screen';
import { UnitEditScreen } from './unit-edit-screen';
import { UnitCreateScreen } from './unit-create-screen';
import { Route, Routes } from 'react-router-dom';
import { UnitSpeakersScreen } from '../speakers/unit-speakers-screen';
import { CreditsEditScreen } from './credits-edit-screen';
import { UnitInfoEditScreen } from './unit-info-edit-screen';
import { UnitAssistScreen } from './unit-assist-screen';

export const UnitsScreen = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ModelScreen
            path={'/units/*'}
            renderList={() => <UnitListScreen />}
            renderDetail={controller => (
              <UnitDetailScreen
                closeDetail={controller.closeDetail}
                startEditing={controller.startEditing}
              />
            )}
            renderEditForm={controller => (
              <UnitEditScreen stopEditing={controller.stopEditing} />
            )}
            renderCreateForm={controller => <UnitCreateScreen />}
          />
        }
      />
      <Route path=":id/credits" element={<CreditsEditScreen />} />
      <Route path=":id/info" element={<UnitInfoEditScreen />} />
      <Route path=":id/assist" element={<UnitAssistScreen />} />
      {/* alias for volume speakers screen */}
      <Route path=":id/speakers" element={<UnitSpeakersScreen />} />
      {/* <Route path=":id/asset-links/edit" component={AssetLinksEditScreen} /> */}
    </Routes>
  );
};
