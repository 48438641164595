import { AuthorData } from './../catalog-types';
import { CatalogEntityKind } from '../catalog-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { AuthorV3Data } from '../bogota/bogota-types';
import { pick } from 'lodash';
import { AuthorManager } from '../db/author-manager';

export const emptyAuthorData = {
  kind: CatalogEntityKind.AUTHOR,
  id: '',
  name: '',
  slug: '',
  title: '',
  bio: '',
  avatarUrl: '',
  priority: 100,
};

export class Author
  extends BaseCatalogEntity<AuthorData>
  implements CatalogEntity<AuthorData>
{
  constructor(data?: { id: string }) {
    super(data);
    this.data = { ...emptyAuthorData, ...data };
  }

  theManager(): EntityManager<CatalogEntity<AuthorData>, AuthorData> {
    return AuthorManager.getInstance() as unknown as EntityManager<
      CatalogEntity<AuthorData>,
      AuthorData
    >;
  }

  get navPath(): string {
    return `/authors/${this.id}`;
  }

  destroy = async () => {
    // await this.resetAllData();
    await AuthorManager.getInstance().delete(this);
  };

  get catalogV3Data(): AuthorV3Data {
    return pick(this.data, [
      'slug',
      'name',
      'title',
      'bio',
      'avatarUrl',
      'priority',
    ]) as AuthorV3Data;
  }
}
