import { VideoGuideData } from '../catalog-types';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { VideoGuide } from '../models/video-guide';
import { isEmpty } from 'lodash';

export class VideoGuideManager extends EntityManager<
  VideoGuide,
  VideoGuideData
> {
  get collectionName(): string {
    return CatalogCollections.VIDEO_GUIDE_METADATA;
  }

  createModel(data: { id: string }): VideoGuide {
    return new VideoGuide(data);
  }

  async active(): Promise<VideoGuide[]> {
    if (isEmpty(this.list)) {
      await this.loadAll();
    }
    return this.list
      .filter(item => {
        return item.data.active;
      })
      .sort((a, b) => a.data.position - b.data.position);
  }

  // // the instance of the model to be currently included in published catalogs
  // // todo: scope to channel
  // async currentFeaturedRelease(): Promise<VideoGuide> {
  //   if (isEmpty(this.list)) {
  //     await this.loadAll();
  //   }
  //   return this.list.find(item => {
  //     return item.data.active;
  //   });
  // }

  private static instance: VideoGuideManager;

  public static getInstance(): VideoGuideManager {
    if (!VideoGuideManager.instance) {
      VideoGuideManager.instance = new VideoGuideManager();
      VideoGuideManager.instance.listenAll();
    }
    return VideoGuideManager.instance;
  }
}
