import React, { useEffect } from 'react';
import { useState } from 'react';
import { PlayerCaliDataBuilder } from '@masala-lib/catalog/cali/player-cali-data-builder';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { useParams } from 'react-router-dom';

export const ExcerptDataScreen = () => {
  const { excerptId } = useParams<{ excerptId: string }>();

  const [data, setData] = useState<object>({ status: 'loading...' });

  useEffect(() => {
    ExcerptManager.getInstance()
      .loadById(excerptId)
      .then(async (excerpt: Excerpt) => {
        if (excerpt) {
          const dataBuilder = await PlayerCaliDataBuilder.forExcerpt(excerpt);
          // setData(await dataBuilder.asyncBuildData());
          await dataBuilder.loadElements();
          setData(dataBuilder.buildExcerptData());
        } else {
          setData({ error: `Failed to load excerpt[${excerptId}]` });
        }
      });
  }, [excerptId]);

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
