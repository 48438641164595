import { Alert, AlertMessages } from '../../../misc/alert-messages';
import { WordIdRange, WordId } from '@tikka/basic-types';
import { Element, ElementId, ElementList } from '../../../editor-aliases';
import { EKind, WordGroupSubKind } from '../../../element-kinds';
import { MutationActions } from '../../db/mutation-actions';
import { WordGroupWriteDto } from '../../../editorial-types';

// [<AbstractClass>]
export abstract class WordGroupActions {
  getCurrentWordGroups() {
    return this.content.filterByKind(EKind.WORD_GROUP);
  }
  getCurrentSentences() {
    return this.content.filterByKind(EKind.SENTENCE);
  }

  focusedWordGroup() {
    if (
      this.currentFocusedElement &&
      this.currentFocusedElement.kind === EKind.WORD_GROUP
    ) {
      return this.currentFocusedElement;
    } else {
      return null;
    }
  }

  create(kind: WordGroupSubKind, wordRange: WordIdRange): void {
    if (
      this.getCurrentWordGroups().hasElementsIntersectWordIdRange(wordRange)
    ) {
      this.alertMessages.add({
        ...Alert,
        text: "can't create word group overlapping with existing word groups",
      });
    } else {
      const sentences = this.getCurrentSentences();
      const sentence1 = sentences.getElementContainingWordId(wordRange.begin);
      const sentence2 = sentences.getElementContainingWordId(wordRange.end);
      if (sentence1 !== sentence2) {
        this.alertMessages.add({
          ...Alert,
          text: "can't create word group spanning multiple sentences",
        });
      } else {
        const wordGroupData: WordGroupWriteDto = {
          subKind: kind,
          id: null,
          content: {},
          anchor: {
            wordId: wordRange.begin as WordId,
            endWordId: wordRange.end as WordId,
          },
        };
        this.mutationActions.addUpdateWordGroup(wordGroupData);
      }
    }
  }

  update(data: WordGroupWriteDto): void {
    // TODO use an id and DTO?
    const wordRange = { begin: data.anchor.wordId, end: data.anchor.endWordId };
    const intersecting0 =
      this.getCurrentWordGroups().getElementsIntersectWordIdRange(wordRange);
    if (intersecting0) {
      const intersecting = intersecting0.filter(
        (el: Element) => el.id !== data.id
      );
      if (intersecting.length > 0) {
        this.alertMessages.add({
          ...Alert,
          text: "can't update word group to overlap with other word groups",
        });
        return;
      }
    }
    this.mutationActions.addUpdateWordGroup(data);
  }

  remove(id: ElementId): void {
    this.mutationActions.removeWordGroup(id);
  }

  createFromCurrentWordSelection(kind: WordGroupSubKind) {
    const wordRange = this.currentWordSelection;
    if (wordRange) {
      this.create(kind, wordRange);
    }
  }

  removeFocused() {
    const focused = this.focusedWordGroup();
    if (focused) {
      this.remove(focused.id);
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList<Element>;
  abstract get currentWordSelection(): WordIdRange;
  abstract get currentFocusedElement(): Element;
  abstract get alertMessages(): AlertMessages;
}
