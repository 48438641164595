import styled from 'styled-components';
import { FormatText } from '../../components/format-text';

const Wrapper = styled.aside`
  blockquote {
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2em;

    em {
      font-size: 500;
      font-style: italic;
      color: var(--color-red800);
    }
  }
`;

export const ChapterNotes = ({ chapter }: { chapter: any }) => {
  return (
    <Wrapper>
      <h4>{chapter.notes.length > 2 ? 'Notes' : 'Note'}</h4>
      {chapter.notes.map((note: { body: string }, index: number) => {
        // TODO: Markdown
        return (
          <blockquote key={index}>
            <FormatText text={note.body} />
          </blockquote>
        );
      })}
    </Wrapper>
  );
};
