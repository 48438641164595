import { makeObservable, observable, when } from 'mobx';

export class BatchProcessor<ItemType, ResultType> {
  items: ItemType[];
  batchSize: number;
  processItemFunc: (item: ItemType) => Promise<ResultType>;
  runningSet: Set<Promise<ResultType>> = new Set();
  @observable runningSetSize: number = 0;
  errorCount: number = 0;
  results: ResultType[] = [];

  constructor(
    items: ItemType[],
    batchSize: number,
    processItemFunc: (item: ItemType) => Promise<ResultType>
  ) {
    this.items = items;
    this.batchSize = batchSize;
    this.processItemFunc = processItemFunc;
    makeObservable(this);
  }

  async evalPromise(promise: Promise<ResultType>): Promise<void> {
    try {
      const result = await promise;
      this.results.push(result);
    } catch (error) {
      this.errorCount++;
    }
    this.runningSet.delete(promise);
    this.runningSetSize = this.runningSet.size;
  }

  processItem(item: ItemType) {
    const promise = this.processItemFunc(item);
    this.runningSet.add(promise);
    this.runningSetSize = this.runningSet.size;
    this.evalPromise(promise);
  }

  async run() {
    const startTime = Date.now();
    const items = this.items;
    const batchSize = this.batchSize;
    while (items.length > 0) {
      const item = items.pop();
      this.processItem(item);
      if (this.runningSetSize >= batchSize) {
        await when(() => this.runningSetSize < batchSize);
      }
    }
    await when(() => this.runningSetSize === 0);
    const endTime = Date.now();
    console.log(
      `BatchProcessor finished in ${(endTime - startTime) / 1000} seconds`
    );
    return;
  }
}
