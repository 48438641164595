// interface needed by audio transport controls in shared tikka module
interface WakeLock {
  activate: () => void;
  deactivate: () => void;
}

export class AppFactory {
  public static get wakeLock(): WakeLock {
    return null;
  }
}
