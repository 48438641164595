import {
  ScriptOptionsKey,
  scriptOptionsKeys,
} from '@masala-lib/llm/project/llm-project-types';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ListWrapper } from '../../components/list';

import { FilterCheckbox, OptionListItem } from './components';

export const InjectFlagSelection = observer(() => {
  const model = useSamosaModel();
  const { scriptInjectParams } = model;

  const toggleOption = (key: ScriptOptionsKey) => {
    scriptInjectParams.toggleScriptOption(key);
  };

  return (
    <ListWrapper>
      {scriptOptionsKeys.map(key => {
        const selected = scriptInjectParams.isScriptOptionEnabled(key);
        return (
          <OptionListItem onClick={() => toggleOption(key)}>
            <FilterCheckbox status={selected ? 'checked' : 'unchecked'} />
            <span className="label">{key}</span>
          </OptionListItem>
        );
      })}
    </ListWrapper>
  );
});
