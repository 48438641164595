import * as React from 'react';
import { observer } from 'mobx-react';
import { NormalBody, TextAreaBody } from './reference-script-bodies';
import { styled } from '@naan/stitches.config';
import { ManageSectionsBody } from '../editing-action-bar/manage-sections-dialog';

const Tabs = styled('div', {
  display: 'flex',
  padding: '24px 0',
  // background: '$gray-50',
  overflow: 'auto',
  width: '100vw',
  margin: '0 -18px',
  scrollSnapType: 'x mandatory',
  position: 'relative',

  '@medium': {
    margin: 0,
    width: 'auto',
  },

  '& a': {
    all: 'unset',
    textStyle: 'body-bold',
    backgroundColor: '$transparent',
    color: '$textSecondary',
    px: '$space$4',
    py: '$space$2',
    borderRadius: '$space$2',
    cursor: 'pointer',
    transition: 'color 0.15s ease, opacity 0.2s ease',
    whiteSpace: 'nowrap',

    '&:hover': {
      color: '$teal-500',
    },
    '&.selected': {
      color: '$teal-600',
      backgroundColor: '$teal-50',
    },
  },

  '&:hover': {
    '& a:not(:hover)': {
      opacity: 0.5,
    },
  },
});

// keep it simple for now until we flush out needs
export const ReferenceScriptDialog = observer(() => {
  return <NormalBody />;
});

type TabName = 'normal' | 'manage' | 'textarea';

const stateBodyMap: {
  [K in TabName]: () => JSX.Element;
} = {
  normal: NormalBody,
  textarea: TextAreaBody, // not sure if needed or not
  manage: ManageSectionsBody, // moved to action bar
};

export const TabbedReferenceScriptDialog = observer(() => {
  const [currentTab, setCurrentTab] = React.useState<TabName>('normal');

  const Body = stateBodyMap[currentTab];

  const names = ['Normal', 'Text area', 'Manage sections'];
  return (
    <>
      <Tabs>
        {['normal', 'textarea', 'manage'].map((tab, i) => (
          <a
            onClick={() => setCurrentTab(tab as any)}
            className={currentTab === tab ? 'selected' : ''}
          >
            {names[i]}
          </a>
        ))}
      </Tabs>

      <Body />
    </>
  );
});
