import { computed, makeObservable, reaction, runInAction } from 'mobx';
import { ChaatToolModel } from './chaat-tool-model';
import { ChaatEpisodeData } from './episode-data';
import { AlertMessages } from '@masala-lib/misc/alert-messages';
import { createBus } from '@masala-lib/misc/bus';
import { EpisodeDataBase } from '@masala-lib/editorial/episode-data/episode-data';
import { Navigation } from '@tikka/navigation/timeline-navigator';
import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import {
  CreateChaatPlayer,
  NavigationState,
} from '@masala-lib/editorial/chaat/chaat-player';
import { CreateTracking, Tracking } from '@tikka/tracking/tracking';

import { Auth } from '@masala-lib/editorial/db/auth';
import { runTimestampingWithEpisodeData } from '@masala-lib/editorial/chaat/timestamper/chaat-timestamper';

// TODO move to some module in masala lib??
import notie from 'notie';
import { CreateTracker } from '@tikka/tracking/tracker';
import { FirestoreLoader } from '@masala-lib/firestore-db/firestore-loader';
import { ChaatDocSet } from '@masala-lib/editorial/db/chaat-doc-set';
import {
  COMPLETE,
  ERROR_NOT_LOADABLE,
  IN_PROGRESS,
} from '@masala-lib/firestore-db/firestore-doc-set';
import { ChaatMutationActions } from './mutation-actions';
import { createAudioSource } from './chaat-audio-sources';
import { ChaatCueActions } from './actions/cue-actions';
import { ChaatAudioRegionActions } from './actions/audio-region-actions';
import { ChaatAudioMarkerActions } from './actions/audio-marker-actions';
import { Unit } from '@masala-lib/catalog/models/unit';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { ElementTracker, Word, Element } from '@masala-lib/chaat-aliases';
import { ChaatForceLinearAudioRegionActions } from './actions/force-linear-region-actions';
import { ChaatTrickyActions } from './actions/tricky-actions';

export const auth = new Auth();
export const alertMessages = new AlertMessages();
export const mutationActions = new ChaatMutationActions();
export const navigation = new Navigation();
export const transportState = new TransportState();
export const wordTracker = CreateTracker({
  positionFunction: () => transportState.audioPosition,
  triggerFunction: () => transportState.audioPosition,
  intervals: (list: any) => list.timeIntervals,
}) as ElementTracker<Word>;

export const tracking = CreateTracking<Element>({
  positionFunction: () => transportState.audioPosition,
  getIntervals: (list: any) => list.timeIntervals,
});

export const audioSource = createAudioSource();
export const audioTransport = new AudioTransport(transportState, {
  maintainAudioRestart: true,
});

audioTransport.setAudioSource(audioSource);

export const navigationState = new NavigationState();

export const player = CreateChaatPlayer(
  audioTransport,
  transportState,
  navigationState,
  navigation
);

export const cueActions = new ChaatCueActions();
export const audioRegionActions = new ChaatAudioRegionActions();
export const forceLinearAudioRegionActions =
  new ChaatForceLinearAudioRegionActions();
export const audioMarkerActions = new ChaatAudioMarkerActions();
export const trickyActions = new ChaatTrickyActions();
export const chaatToolModel = new ChaatToolModel();
export const appBus = createBus({});
export const episodeData = new ChaatEpisodeData();

chaatToolModel.init();

// rename AppRoot/appRoot to episodeLoader, reflects actual purpose?
export class AppRoot {
  episodeKey = '';
  mode: 'chaat' | 'tricky' = 'chaat';
  loader = new FirestoreLoader(ChaatDocSet);
  unit: Unit = null;
  disposers: (() => void)[] = [];

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.loader.getStateVersion(),
        () => this.contentUpdated()
      )
    );
  }

  @computed
  get status() {
    const newEpisode = this.episodeKey !== this.loader.key;
    const loaderStatus = this.loader.getStatus();
    switch (loaderStatus) {
      case ERROR_NOT_LOADABLE:
      case COMPLETE:
        return loaderStatus;
      default:
        return newEpisode ? IN_PROGRESS : COMPLETE;
    }
  }

  async loadEpisodePromise(episodeKey: string) {
    this.unit = await new UnitManager().loadSoloById(episodeKey);
    this.loader.loadEpisode(episodeKey, true);
  }

  runTimestamping() {
    const tmpEpisodeData = new EpisodeDataBase();
    tmpEpisodeData.episodeKey = this.loader.key;
    this.loader.docSet.copyTo(tmpEpisodeData);
    runTimestampingWithEpisodeData(tmpEpisodeData);
  }

  contentUpdated() {
    const status = this.loader.getStatus();
    if (status === 'COMPLETE') {
      console.log('LOADED');
      const newEpisode = this.episodeKey !== this.loader.key;
      this.episodeKey = this.loader.key;
      runInAction(() => {
        episodeData.episodeKey = this.episodeKey;
        chaatToolModel.unit = this.unit;
        this.loader.docSet.copyTo(episodeData);
        navigation.addNavigator(episodeData.segmentNavigator);
        navigation.addNavigator(episodeData.notchNavigator);
        // TODO rename tracking singleton to elementTracking
        // TODO reconfigure word tracker singleton with new words if words changed?
        // TODO build trigger function from wordTracker if words changed?
        wordTracker.configure({
          elements: episodeData.wordsWithTimes,
        });
        // TODO use trigger function with setElements
        tracking.setElements({
          elements: episodeData.playableElements,
          triggerFunction: () => wordTracker.anyIsChangedSignal.watch(),
        });

        player.setElements(episodeData.playableElements as any);
        chaatToolModel.updateFromEpisodeData(episodeData);
        // configure audioSource if episodeKey changed? TODO do another way?
        if (newEpisode) {
          audioSource.setAudioSourceDefinitions(
            this.episodeKey,
            episodeData.audioUrls
          );
        }
      });
      mutationActions.setEpisodeKey(this.episodeKey);
    } else if (status === 'OBSOLETE_TIMESTAMP_DATA') {
      if (mutationActions.chaatInputRecentLocalModification) {
        this.runTimestamping();
      }
    } else if (status === 'VERY_OBSOLETE_TIMESTAMP_DATA') {
      this.runTimestamping();
    } else if (status === 'TRANSCRIPTION_NOT_FINISHED') {
      notie.alert({ text: 'Transcription not finished' });
    } else if (status === 'CHAAT_NOT_INITIALIZED') {
      notie.alert({ text: 'Chaat not initialized' });
    }
  }
}

export const appRoot = new AppRoot(); // TODO could put appRoot creation in singletons too??
