import { L12String, LString } from '@utils/util-types';
import { ElementId, StringToString } from '@tikka/basic-types';
import { LocaleCode } from '@utils/util-types';
import { /*BogotaStoryData,*/ SpeakerV5Data } from './bogota/bogota-types';
import { KerningConfigData } from '../editorial/chaat/chaat-types';
import { VolumeCaliData } from './cali/cali-catalog-types';
import { JobData } from '@masala-lib/jobs/job-types';

// not sure if will be useful
export enum CatalogEntityKind {
  UNIT = 'UNIT',
  VOLUME = 'VOLUME',
  UNIT_L1 = 'UNIT_L1',
  UNIT_L1_VERSION = 'UNIT_L1_VERSION',
  SHOW = 'SHOW',
  CHANNEL = 'CHANNEL',
  AUTHOR = 'AUTHOR',
  TAG = 'TAG',
  ACTIVITY_GUIDE = 'ACTIVITY_GUIDE',
  FEATURED_RELEASE = 'FEATURED_RELEASE',
  VIDEO_GUIDE = 'VIDEO_GUIDE',
  EXCERPT = 'EXCERPT',
  SOUNDBITE_EDITION = 'SOUNDBITE_EDITION',
}

// export enum MetadataFlavor {
//   LUPA = 'LUPA',
//   BOLERO = 'BOLERO',
// }

// base interface for both episode and volume owned metadata
// (ownership reflected in base collection doc props)
// export interface MetadataElement extends Element<MetaElementKind> {}
export interface BaseMetadata /*extends BaseEntityData*/ {
  id: string; // random uid used for db level relations - WIP
  kind?: CatalogEntityKind; // entity type, not sure if needed but could help with code factoring
  slug?: string; // friendly id used in generated catalogs and routes
  name?: string; // display name used within masala system (distinct from end-user content title)
  pendingOperation?: string; // status indicator for medium duration operation
  archived?: boolean;

  // epoch seconds float
  updatedAt?: number;
  createdAt?: number;
}

//
// the parsed metadata URL is probably best handled as just a property on the main metadata doc, not an element
// eventually it can be just transiently used during script import

// // google doc url to import the rest of the metadata from.
// // persisted into 'Unit__metadata' collection
// export interface MetadataUrl extends MetadataElement {
//   // kind = METADATA_URL
//   url: string;
// }

export const AUDIO_ASSET_LINK_PROPS = [
  'audioFinalUrl',
  'audioNoMusicUrl',
  'audioTranscribeUrl',
];

// unit level data needed by the chaat and script editor functionality
// persisted into 'Unit__metadata' collection
export interface UnitData extends BaseMetadata {
  // todo: figure out why this is causing grief, kind: CatalogEntityKind.UNIT;
  name: string; // workflow title
  pendingOperation?: string; // status indicator for medium duration operation
  schemaVersion?: string; // i.e. "2.0"
  // editEnabled?: boolean; // todo: rethink locking, probably won't use for 2.0

  // timestamp of script import or migration
  scriptInitTime?: number;
  // jason, seemed like the server code was storing the job id during the init, but only attempting to write
  // the wider job data obj until later (and barfing before it could)
  // also, i'm not so sure about denormalizing the job data like this. with the current code,
  // it's failing and that status is written to the job doc, but not here. feels ugly for any
  // updates that we care about within the job handling needs to know to write here to be seen
  chaatInitJobId?: string;
  chaatInitJobData?: JobData;
  // timestamp of most recent chaat init start. epoch seconds (6 decimals)
  chaatInitTime?: number; // indicates that the chaat init process was started
  chaatReady?: boolean; // temp need to populate

  // flavor?: MetadataFlavor; // LUPA, BOLERO
  metadataUrl?: string; // metadata google doc url for bolero flavored units
  metadataImportSourceUrl?: string; // preserve the import source once unlinked

  // temp home unit UnitL1 data broken out
  linearUrl?: string; // link to linear workflow issue
  soundbiteEditorialDocUrl?: string; // link to generated google doc for soundbite editorial work
  workflowStatus?: WorkflowStatus;
  // when true, stricter lint rules are checked
  structuralContentReady?: boolean;
  // if true, then need to flip canonical/translation versions of chapter notes and passage titles during ingestion
  structuralContentInL1?: boolean;

  // volumeSlug: string;
  volumeId?: string; // parent (ownership) ref
  unitNumber?: number;
  // partSuffix moved to infoV5
  // partSuffix?: string; // used in lupa catalog. drives two-tone "Part X" display in app catalog
  // audioLanguageCode?: string;
  l2Code?: LocaleCode; // (content language being studied)
  l1Code?: LocaleCode; // primary l1 (learner's language)
  // workflowTitle?: string;

  // db managed publish data (not metadata import)
  trial?: boolean; // lupa catalog
  recommended?: boolean; // part of lupa catalog schema, but not used
  releaseDate?: string | number; // currently either an iso8601 string or epoch seconds number
  releaseIsoDate?: string; // this is a transient field used only by the unit edit form
  activityGuideId?: string;

  // TODO chaatStatus?: string; // AUDIO_PROCESSING, TRANSCRIBING, COMPLETE
  audioM16000StoragePath?: string; // gcs path
  audioM16000StorageUrl?: string; // url used for transcribing

  // audioProcessingJob?: AudioProcessingJobData;
  transcriptionJob?: TranscriptionJobData; // only necessary to detect chaat init state for legacy units (pre Sep 2023)

  // unitTitle?: string; // L2
  // unitDescription?: string; // translated
  // weblink?: string;
  // originalBroadcastDate?: string; // iso

  migrationSourceKey?: string; // populated when 'migrate from v1' performed
  scriptImportSourceUrl?: string; // populated when 'import from jw script' performed
  cloneSourceUnitId?: string; // when set, points to the master for this clone unit
  pendingCloneUnitId?: string; // when set, points to the clone forked off from this master unit
  mergedCloneUnitId?: string; // points to the last merged clone. allows the external review to easily access the discussion threads when confirming the fixes

  // used to determine the reference element version to show diffs against
  defaultBaselineTimestamp?: number;
  // must be true to allow external reviewer access to script editor
  // also sets tracking 'true' by default for all users when enabled
  trackingEnabled?: boolean;

  lockedByUserId?: string; // when assigned, only the referenced user id may edit
  lockedForAll?: boolean; // when false, maybe edited by the locking user. when true read-only for all users

  // asset links
  audioFinalUrl?: string;
  audioNoMusicUrl?: string;
  audioTranscribeUrl?: string;

  // gcs paths
  audioFinalStoragePath?: string;
  audioFinalStorageUrl?: string;
  audioTranscribeStoragePath?: string;
  audioTranscribeStorageUrl?: string;

  // deprecated - migrating to volume data
  imageThumbUrl?: string;
  imageThumbStoragePath?: string;
  imageThumbStorageUrl?: string;

  // unit level title, description, etc
  infoV5?: InfoV5Data;

  // unitL1s?: { [index in LocaleCode]: UnitL1 };

  // temp home for ingestion data
  dataUrl?: string;
  version?: number;
  ingestedAt?: number;
  ingestedAtIso?: string; // todo: consolidate on iso string

  chapterDataUrls?: string[]; // a lot of fields in this interface should probably be moved to UnitL1Version
  audioSlicings?: AudioSlice[];

  // // only temporarily holding the data after ingestion before moving to l1version data
  // // todo: refactor this
  // bogotaCatalogData?: BogotaStoryData;

  downloadSize?: number; // bytes
  durationMinutes?: number; // calculated and updated during ingest
  volumeDurationMinutes?: number; // summed and updated during ingest

  exportedUrl?: string; // url of last full data export
  exportedAt?: number; // timestamp of last export

  contentUpdatedAt?: number; // timestamp of last update in script editor

  warningCount?: number;
  openThreadCount?: number;

  vocabReferenceCatalogUrl?: string; // legacy catalog to attempt to match up vocab ids from
  bogotaVocabMigrationMap?: StringToString; // map of bogota to cali vocab ids

  truncateChapterPosition?: number; // when assigned truncate ingestion after given number of chapters

  // TODO: revisit naming speakerLabels vs speakerData
  // speakerLabels?: SpeakerV4Data[]; // as parsed from metadata - unit level 'voices'
  // speakers?: SpeakerV5Data[]; // as parsed from metadata 'speakers' block (new lupa content)
  // not yet used, intended for new UI
  // currently parsed metadata is used during ingest
  //voices?: { [index: string]: Voice }; // index = voice uid (for first pass, just use label)

  // parsed lupa metadata
  // lupaCast?: CastV3Data[];
  // lupaCredits?: CreditsV3Data;

  // TODO: remove once all data migrated to volume
  tagTypeSlugs?: { [index: string /*TagType*/]: string[] };

  kerningConfig?: KerningConfigData;
}

export interface AudioSlice {
  position: number;
  startMillis: number;
  finishMillis: number;
  finalAudioPath: string;
  finalAudioUrl?: string;
}

// export type UnitL1s = { [index in LocaleCode]: UnitL1 };

// place holder info schema to fascilitate deprecation of google doc metadata docs
export interface InfoV5Data {
  titleL2: string;
  titleL1: string;
  partSuffix: string;
  descriptionL2: string;
  descriptionL1: string;
  taglineL2: string;
  taglineL1: string;
  weblink: string;
  originalBroadcastDate: string;
  seasonNumber?: string;
}

export const LUPA_CREDITS_KEYS = [
  'producedBy',
  'producedIn',
  'editedBy',
  'soundDesignBy',
  'artworkBy',
];

// persisted at volume level, shared between lupa units
// super set of credit labels used by various content
export interface CreditsData {
  // standard lupa v3 catalog credits
  producedBy?: string;
  producedIn?: string;
  editedBy?: string;
  soundDesignBy?: string;
  artworkBy?: string;

  writtenBy?: string;
  directedBy?: string;
  createdBy?: string;
  storyBy?: string;
  researchBy?: string;
  executiveProducer?: string;
  originalMusicBy?: string;
  photoBy?: string;
  factCheckingBy?: string;
  productionAssistant?: string;
  storyDescriptionBy?: string;
  associateProducedBy?: string;
  coproducedWith?: string;
  sponsoredBy?: string;

  // tentatively included
  host?: string;
  guest?: string;

  // audioMixedBy?: string;
  // host?: string;
  // seniorProducer?: string;
  // seniorEditor?: string;
  // reportedBy?: string;
  // narrationBy?: string;
}

export interface AudioProcessingJobData {
  // episodeKey: string | null;
  m16000AudioId: string;
  transcribeUrl: string;
  inputAudioUpdateTimestamp: number; // epoch seconds float (microsecond precision)
  resampledAudioUpdateTimestamp: number; // epoch seconds float (microsecond precision)
  // was a flag intended to conditionally bypass the firestore triggers, but not currently relevant (always bypassed)
  // localJobFlow?: boolean;
}

export interface TranscriptionJobData {
  // episodeKey: string | null; // not currently populating
  jobId: string;
  inputAudioTimestamp: number;
  jobStartTimestamp: number;
  jobFinishTimestamp: number;
}

export enum WorkflowStatus {
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  EXTERNAL_REVIEW = 'EXTERNAL_REVIEW',
  PUBLISHED = 'PUBLISHED',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
}

export enum CatalogStage {
  REVIEW = 'REVIEW',
  STAGED = 'STAGED',
  LIVE = 'LIVE',
}

// represents the workflow and publish status for the learner's language translated content.
// for each translated locale we'll have separate records to track that progress and status.
// persisted to 'UnitL1' collection
// export interface UnitL1 extends BaseMetadata {
//   // id = [unitId].[locale]
//   kind: CatalogEntityKind.UNIT_L1;

//   unitId: string; // parent (ownership) ref
//   locale: string; // make typed

//   dirty: boolean; // indicates that edits have been made which are not yet 'pushed'
//   lastVersion: number; // counter incremented with each 'pushed' version

//   // this is the definitive tracking of which version is visible to which audience
//   internalVersion: number | null;
//   externalVersion: number | null;
//   publicVersion: number | null;
//   // UnitL1 level publish summary state is derived from above version map

//   // UnitL1 level workflow status is primarily manually controlled with possibly some automated state changes
//   workflowStatus: WorkflowStatus;

//   // versions: { [index: string]: UnitL1Version }; // index = locale
// }

// bogota (v3) catalog only
// export interface UnitL1 {
//   locale: LocaleCode;

//   dirty: boolean; // indicates that edits have been made which are not yet ingested
//   lastVersionNumber: number; // counter incremented with each ingestion version

//   // this is the definitive tracking of which version is visible to which audience
//   // aka 'review'
//   internalVersion?: UnitL1Version; // the most recently ingested data
//   // aka 'staged'
//   publicVersion?: UnitL1Version; // promoted to from review

//   // cloned from 'publicVersion' when staged catalog flipped live on the prod server
//   liveVersion?: UnitL1Version;

//   // not currently used
//   // externalVersion?: UnitL1Version; // promoted to from 'internal'
//   // UnitL1 level publish summary state is derived from above version map

//   // UnitL1 level workflow status is primarily manually controlled with possibly some automated state changes
//   workflowStatus: WorkflowStatus;
// }

// bogota (v3) catalog only
// export interface UnitL1Version {
//   versionNumber: number;

//   bogotaCatalogData?: BogotaStoryData; // not populated for 'live' version

//   // url to the GCS hosted ingested json data
//   dataV3Url: string;

//   // true when a new version has been ingested or promoted, but not yet
//   // published to a new catalog
//   pendingPublish: boolean;

//   //  publishStatus: PublishStatus; - derived from L1 version pointers

//   timestamp: number; // ingestion/promotion time (epoch seconds float)
//   author: string; // editorial user id who initiated the ingestion/promotion
// }

export enum PublishStatus {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL', // not currently used
  PUBLIC = 'PUBLIC',
}

export enum AppDataFormat {
  LUPA = 'LUPA',
  BOLERO = 'BOLERO',
  CALI = 'CALI',
}

// hold the actual ingested data for a l2/l1 language pair
// ingested/'pushed' version which is accessible via app
// persisted to 'UnitL1Version' collection
// export interface UnitL1Version extends BaseMetadata {
//   // id = [unitId].[locale].[version]
//   kind: CatalogEntityKind.UNIT_L1_VERSION;

//   unitL1Id: string; // parent (ownership) ref
//   locale: string; // redundant

//   version: number;

//   // url to the GCS hosted ingested json data
//   dataV3Url: string;

//   //  publishStatus: PublishStatus; - derived from L1 version pointers
//   timestamp: number; // 'push' time (epoch seconds float)
//   author: string; // editorial user id who initiated the 'push'
//   // the different version of data for different app versions
//   // formats: { [index: string]: object }; // AppDataFormat -> to element data, TODO:
// }

//
//
// Volume__metadata
//
// export interface VolumeCollection {
//   id: string; // volume slug
//   // volumeSlug: string; // also used as document id
//   items: { [index: string]: VolumeMetadata }; // map from element id to element data
// }

// persisted to faunadb 'Volume' collection

// todo: figure out if we want an interface here, or should always just use the class
// export interface Volume extends BaseMetadata {
//   kind: CatalogEntityKind.VOLUME;
//   slug: string;

//   channelId?: string; // parent (ownership) ref - todo: fauna ref modeling
//   showId?: string; // optional

//   title: string; // translated
//   tagline?: string; // translated
//   description?: string; // translated
//   // topicSlugs: string[];
//   // countrySlugs: string[];
//   // weblink: string;
//   originalBroadcastDate?: string; // iso
//   seasonNumber?: number;
//   imageThumbUrl?: string;
//   hideVoiceCredits?: boolean; // true for wondery content, false for lupa
//   supportedFormats?: { [index: string]: boolean }; // AppDataFormat -> flag indicated which formats should be published

//   credits?: StringToString; // index = credit keys (i.e. HOST, etc) - to be managed in a global collection

//   // all defined voices will sharable across volume units and must share the same bios and translations
//   voices?: { [index: string]: Voice }; // index = voice uid (for first pass, just use label)

//   // units: { [index: string]: UnitMetadata };
//   unitCount?: number;
// }

// // all defined voices will sharable across volume units and musts share the same bios and translations
// // persisted into 'Volume__metadata' collection
// export interface VolumeVoices extends MetadataElement {
//   // kind = VOLUME_VOICES
//   voices: { [index: string]: Voice }; // index = voice uid (for first pass, just use label)
// }

// currently used for both the storage and user input form interfaces

export interface VolumeData extends BaseMetadata {
  slug: string;
  channelId?: string; // parent (ownership) ref
  forkParentId?: string; // when set, points to the master for this fork
  showId?: string; // optional
  singleUnit?: boolean;
  // flavor?: MetadataFlavor; // LUPA, BOLERO

  name: string; // display name inside masala tools
  exportedUrl?: string; // url of last full data export
  exportedAt?: number; // timestamp of last export

  // volume level title, description, etc
  infoV5?: InfoV5Data;

  // originalBroadcastDate?: string; // iso
  // seasonNumber?: number;
  // imageThumbUrl?: string;
  themeColor?: string; // color used in story detail header and status buttons

  imageThumbUrl?: string;
  imageThumbStoragePath?: string;
  imageThumbStorageUrl?: string;

  hideVoiceCredits?: boolean; // true for wondery content, false for lupa
  supportedFormats?: { [index: string]: boolean }; // AppDataFormat -> flag indicated which formats should be published

  // map of tagtype to slug list
  tagTypeSlugs?: { [index: string /*TagType*/]: string[] };

  creditsData?: CreditsData; // ad hoc superset of support credit properties

  // moving forward, this will be the definitive representation and persisted storage of the speaker label data
  // will be imported into from the metadata google doc if present there, but expected to be removed from the
  // google doc metadata once imported, and the volume screen UI will be used to manage the data after import
  // we should eventually be able to completely skip the SPEAKERS google doc metadata block and only manage via
  // the volume screen UI
  speakers?: SpeakerV5Data[];

  unitSlugs?: string[];

  trial?: boolean; // bolero catalog
  releaseDate?: string;

  // // todo: remove these
  // jwnextIngestVersion?: number;
  // jwnextIngestedAt?: string; // iso string
  // jwnextDataUrl?: string; // story detail screen data payload - todo: staged versions
  // bogotaCatalogData?: BogotaCatalogVolumeData; // volume level ingested data to be directly included in published catalog

  // not expected to be used at volume level (only unit level)
  linearUrl?: string; // link to linear workflow issue

  // cali ingestion
  locale: LocaleCode;

  dirty: boolean; // indicates that edits have been made which are not yet ingested

  pendingVersion: number; // counter incremented with each ingestion version
  pendingDataUrl?: string; // stuff the volume level data URL tha will be written here so it can be referenced by the chapter level ingestion
  // ingestedAt?: string; // iso string - denormalized from reviewVersion.timestamp

  // this is the definitive tracking of which version is visible to which audience
  reviewVersion?: VolumeIngestionVersion; // the most recently ingested data
  stagedVersion?: VolumeIngestionVersion; // promoted to from review

  // cloned from 'publicVersion' when staged catalog flipped live on the prod server
  liveVersion?: VolumeIngestionVersion;

  // Volume level workflow status is primarily manually controlled with possibly some automated state changes
  workflowStatus: WorkflowStatus;
}

// v5 flavor volume ingestion result
export interface VolumeIngestionVersion {
  versionNumber: number; // ingestion version number

  // catalogType: string; // 'v5', might be useful
  catalogData?: VolumeCaliData; // todo - replaces BogotaStoryData, the data to include in the top level catalog

  // url to the GCS hosted ingested json data, data needed for the vocab panel, and possibly story detail view
  dataUrl: string;

  // we always publish immediate after ingest, so i think think this is needed now
  // // true when a new version has been ingested or promoted, but not yet
  // // published to a new catalog
  // pendingPublish: boolean; // todo: does this belong here or directly on the volume

  // todo: think about these more
  ingestedAt: string; // iso
  // ingestedAt: number; // epochSecondsFloat(),
  // ingestedAtIso: string; //new Date().toISOString(),

  // timestamp: number; // ingestion/promotion time (epoch seconds float)
  // not worth passing into the server context
  // author: string; // editorial user id who initiated the ingestion/promotion
}

// export interface Voice {
//   id: string; // uid - for first pass, just use label
//   label: string; // name or l2 generic label. translated when generic label
//   bio: string; // l2 bio if name, not needed if generic label. translated
//   accent: string; // translated and/or key into standardized set

//   // suppress missing bio warning for the narrow case that neither a bio or label translations is needed
//   // when label translation is provided, the bio is implicitly not needed
//   // (not sure if this flag is really needed)
//   noBio: boolean;
//   noCredit: boolean; // true if this specific bio should be excluded from the volume level credits when other are included. (anticipated future need)
// }

// desired future persistence interface
// currently a bridge format for jw.next ingestion
export interface SpeakerData {
  id?: string; // needed for DataCard interface
  label: LString;
  bio: LString;
  accent: LString;
  noBio?: boolean; // not yet used, currently magic 'n/a'bio used
  noCredit?: boolean; // true if this specific bio should be excluded from the volume level credits when other are included. (anticipated future need)
}

export interface ClientSpeakerData {
  labelL12: L12String;
  bioL12: L12String;
  accentL12: L12String;
  noCredit?: boolean; // true if this specific bio should be excluded from the volume level credits when other are included. (anticipated future need)
}

//
//
// Show__metadata
//
// export interface ShowCollection {
//   id: string; // show slug
//   // showSlug: string; // also used as document id
//   items: { [index: string]: ShowMetadata }; // map from element id to metadata
// }

// shows are collections of volume, but probably not a neccessary hierarchical ownership level.
// not a normal script editor element, but can hopefully leverage generic handling of audit/history handling
// persisted to faunadb 'Show' collection
export interface Show extends BaseMetadata {
  kind: CatalogEntityKind.SHOW;
  // slug: string; // publish id

  channelId: string; // parent (ownership) ref - todo: fauna ref modeling

  title: string; // translated
  tagline: string; // translated
  description: string; // translated
  weblink: string;
  imageLogoUrl: string;
}

//
//
// Channel__metadata
//
export interface ChannelData extends BaseMetadata {
  name: string;
  slug: string;
  // flavor?: MetadataFlavor; // LUPA, BOLERO - // should be able to remove now (in separate PR)

  // end-user displayed props (flattened "Show" props)
  clientChannelSlug: string; // slug used to bind client managed assets to channel
  title: string;
  tagline: string;
  description: string; // markdown
  weblink: string;
  themeColor: string; // hex

  // most recently published catalog
  l1?: LocaleCode; // primary l1
  l2?: LocaleCode;
  // auxL1s: LocaleCode[]; // ordered list of planned locales
  auxL1s?: string; // for now csv, todo: persist as structured

  // tags for dashboard singleton widgets
  featuredFilterSets?: string; // semi-colon separated list of csv list of tag slugs
  featuredTagSlugsCsv?: string; // deprecated
  featuredCollectionSlug?: string; // deprecated

  // bogota catalog
  catalogVersion?: number;
  catalogGeneratedAt?: string;
  catalogUrl?: string; // staged
  internalCatalogUrl?: string; // in-progress
  externalCatalogUrl?: string; // review
  liveCatalogUrl?: string;

  reviewCaliVersion?: CatalogVersion;
  stagedCaliVersion?: CatalogVersion;
  liveCaliVersion?: CatalogVersion;

  catalogSlug?: string; // i.e. 'devtest-mini'
  autoPublishEnvCsv?: string; // list of target firebase db's to automatically sync to; i.e. devtest
  livePublishEnvCsv?: string; // list of target to push to with the 'make live' action

  // railsEndpointKey?: string; // 'devtest', etc, if assigned, then deploy to rails server upon publish
  // // catalogMode?: string; // v3, v4 - always 'v3' for bogota, 'v4' for cali
  // // where the 'make live' action should publish to
  // liveRailsEndpointKey?: string;
  // liveCatalogSlug?: string;

  // // base path for gcs storage paths. defaults to 'dev' (so assets can be shared accross channels by default)
  // storageSilo?: string;

  mergeChannelSlugs?: string; // csv list of channels to aggregate

  // // true during bulk ingestions - avoids pointless churn and time
  // suppressAutopublish?: boolean;
}

// not sure if warranted
export interface CatalogVersion {
  versionNumber: number; // publish version number
  flavor: CatalogStage;
  dataUrl: string;
  generatedAt: string; // iso
}

// export const railsEndpointUrls = {
//   '': '',
//   local: 'http://localhost:3000',
//   jfedev: 'https://jfedev.ngrok.io',
//   devtest: 'https://service.devtest.jiveworld.com',
//   beta: 'https://service.beta.jiveworld.com',
//   preprod: 'https://service.preprod.jiveworld.com',
//   prod: 'https://service.lupa.app',
//   // obsolete
//   // staging: 'https://service.staging.jiveworld.com',
//   // eslbeta: 'https://service-esl-beta.jiveworld.com',
//   // bolero: 'https://service.bolero.jiveworld.com',
// } as StringToString;

export const nodeEndpointUrls = {
  '': '',
  local: 'http://localhost:3030',
  // jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://cali-app-server-devtest.jiveworld.app',
  editorial: 'https://cali-app-server-staging.jiveworld.app',
  beta: 'https://cali-app-server-beta.jiveworld.app',
  // live: 'https://node.jiveworld.com',
  // until custom domain
  live: 'https://cali-app-server-live-uontdmid5q-uc.a.run.app',
} as StringToString;

export const spaBaseUrls = {
  '': '',
  local: 'http://localhost:3100',
  // jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://test.jiveworld.app',
  editorial: 'https://editorial.jiveworld.app', // staging db
  beta: 'https://beta.jiveworld.app',
  live: 'https://app.jiveworld.com',
} as StringToString;

// the in-memory object which spans fauna and firestore fetched data
// export interface Unit extends CatalogModel {
//   kind: CatalogEntityKind.UNIT;
//   // id = episodeKey

//   episodeMetadata: EpisodeMetadataDto;
//   audioProcessingJob: AudioProcessingJobDto;
//   transcriptionJob: TranscriptionJobDto;
// }

// rename to UnitStorage?
// export interface UnitData extends BaseMetadata {
//   slug: string;
//   title: string;
//   volumeId?: string;
//   // episodeKey?: string;

//   unitTitle?: string;
//   unitTagline?: string;
//   unitDescription?: string;
//   audioFinalUrl?: string;

//   // temp home for ingestion data
//   dataUrl?: string;
//   version?: number;
// }

export interface CatalogModel {
  kind: CatalogEntityKind;
  id: string;

  selectLabel: string;
  filterMatch: (text: string) => boolean;
}

export interface CatalogModelManager<Model extends CatalogModel> {
  setFilterText: (text: string) => void;
  list: Model[];
  fetchById: (id: string) => Model;
}

/// As spec’d on ENG-1339
export interface AuthorData extends BaseMetadata {
  title: string;
  bio: string;
  avatarUrl: string; // to be hosted on jiveworld.com website. See ENG-1340
  priority: number; // exported in feed used for ordering on the marketing site (sort descending, then alphabetically)
}

export interface TagData extends BaseMetadata {
  tagType: string; //TagType; // todo: figure out if we can type this
  // optional custom heading of dashboard promotion widget
  // if blank then "Stories about <lowercase tag name>" used
  widgetHeading?: string;
}

export interface ActivityGuideData extends BaseMetadata {
  unitId?: string;
  authorId: string;
  authorNote: string;
  resourceUrl: string;
}

// now known as "Collections"
export interface FeaturedReleaseData extends BaseMetadata {
  channelId: string;
  // used as client-side id for viewed tracking data
  slug: string;
  title: string;
  illustrationUrl: string;
  article: string;
  preview: string; // the preview article text shown on the dashboard
  storySlugs: string; // csv of part-1 units
  // iso8601 - used to drive displayed month text in UI
  releaseDate: string;
  // iso8601 - will inherit from 'releaseDate' if not assigned
  // drives access control logic
  accessibleDate: string;
  active: boolean; // flag to control which instances are included in catalog
}

// the google cloud storage folder that video guide images are expected to be manually placed
export const VIDEO_GUIDE_GCS_FOLDER =
  'https://console.cloud.google.com/storage/browser/jw-editorial-pub/video-guides/en/images';

export interface VideoGuideData extends BaseMetadata {
  slug: string;
  title: string;
  videoIdLandscape: string; // Wistia id
  imageUrlLandscape: string; // raw GCS hosted path for all the  image assets
  videoIdPortrait: string;
  imageUrlPortrait: string;
  durationSeconds: number;
  position: number; // tentative sorting solution
  active: boolean; // flag to control which instances are included in catalog
  // category: string; // future
  // promote: boolean; // future
  // locale: string; // future
}

export enum ExcerptKind {
  SOUNDBITE = 'SOUNDBITE',
  SNIPPET = 'SNIPPET',
  OTHER = 'OTHER',
}

export enum ExcerptStatus {
  SUGGESTED = 'SUGGESTED',
  ON_DECK = 'ON_DECK',
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  QA = 'QA',
  DONE = 'DONE',
  PUBLISHED = 'PUBLISHED',
}

export interface ExcerptData extends BaseMetadata {
  slug: string;
  name: string; // workflow title
  unitId: string;
  elementId?: ElementId; // excerpt element id
  excerptKind?: ExcerptKind;
  status?: ExcerptStatus;
  sourceDocUrl?: string; // google doc, for now just for reference, in the future will be import source
  linearUrl?: string; // for reference if useful
  internalNotes?: string;
  categories?: string; // free form text for now, will likely migrate to a tag list UI someday
  difficulty?: number; // for internal editorial tracking

  // story/unit/chapter specific data
  storySlug?: string;
  unitSlug?: string;
  unitNumber?: number;
  position?: number; // chapter number
  roughSortIndex?: number; // word index gather on creation at ExceptData level

  //
  // for now use a fat schema for soundbite data, will probably move to a subclass
  //

  // todo: support multi select
  // categories?: string[]; // idiom, vernacular, etc
  title: string;
  promptEn?: string;
  prompt?: string;
  vocabulary?: string;
  explanationEn?: string;
  explanation?: string;
  releaseDate?: string; // iso8601, date that soundbite is live
  // placeholder ingestion data until we support a review workflow
  ingestVersion?: number;
  ingestedAt?: string;
  dataUrl?: string;
  slicedAudioUrl?: string;
}

export interface SoundbiteEditionData extends BaseMetadata {
  name: string; // workflow title
  channelId: string;
  volumeId?: string;
  calendarSummary: string; // markdown text
  calendarSoundbiteIds?: string[]; // ordered list of daily soundbites seen on calendar
  auxilarySoundbiteIds?: string[]; // set of additional soundbites released at same time
  // drives the visibility access control. ordinal of the week count to make content visible relative
  // to a per server global config date
  visibilityWeekPostEpoch: number;
  linearUrl?: string;
}

export interface SoundbiteEditionCatalogData {
  slug: string;
  volumeSlug?: string;
  calendarSummary: string;
  calendarSoundbiteSlugs: string[];
  auxilarySoundbiteSlugs: string[];
  visibilityWeekPostEpoch: number;
}
