import React from 'react';
import { observer } from 'mobx-react';
import { ActionLink } from './action-link';
import { uiTimeString } from '../../misc/timestamp-formats';
import { CommentDTO } from '../models/conversation-manager';

type TimelineItemFooterType = {
  item: CommentDTO;
  children?: React.ReactNode;
};

const TimelineItemFooter = ({ item, children }: TimelineItemFooterType) => {
  return (
    <div className="thread-item-attribution">
      {item.author ? (
        <>
          <span>{item.author}</span> &nbsp;
        </>
      ) : (
        <span>&nbsp;</span>
      )}
      <span style={{ float: 'right' }}>
        {children}
        {uiTimeString(item.timestamp)}
      </span>
    </div>
  );
};

type CommentType = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  comment: CommentDTO;
  onDelete: (e: CommentDTO) => any;
  onEdit: (e: CommentDTO) => any;
};

const Comment = ({ onClick, comment, onDelete, onEdit }: CommentType) => {
  const message = comment.content.replace(/\n/g, '<br>');
  return (
    <div className="thread-item" onClick={onClick}>
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
      <TimelineItemFooter item={comment}>
        {comment.edited ? <>(edited) &nbsp;</> : null}
        <ActionLink onPress={() => onEdit(comment)}>[edit]</ActionLink>{' '}
        <ActionLink onPress={() => onDelete(comment)}>[delete]</ActionLink>{' '}
      </TimelineItemFooter>
    </div>
  );
};

function workThreadItemKey(item: CommentDTO) {
  return item.id + item.timestamp + (item.message ?? '');
}

type ConversationType = {
  items: Array<CommentDTO>;
  onDelete?: (e: CommentDTO) => any;
  onEdit?: (e: CommentDTO) => any;
};

export const Conversation = observer(
  ({ items, onDelete = null, onEdit = null }: ConversationType) => {
    return (
      <div className="comment-thread">
        {items.map(item => (
          <WorkThreadItem
            key={workThreadItemKey(item)}
            item={item}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}
      </div>
    );
  }
);

type WorkThreadItemType = {
  item: CommentDTO;
  onDelete: (e: CommentDTO) => any;
  onEdit: (e: CommentDTO) => any;
};

export const WorkThreadItem = ({
  item,
  onDelete,
  onEdit,
}: WorkThreadItemType) => {
  // TODO hack for sniff change message
  if (item.message) {
    return <HistoryItem item={item} className="change" />;
  } else {
    return <Comment comment={item} onDelete={onDelete} onEdit={onEdit} />;
  }
};

type VersionItemType = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className: string;
  item: CommentDTO;
};

export const VersionItem = ({ onClick, className, item }: VersionItemType) => {
  className = className || '';
  className = className + ' thread-item';
  return (
    <div className={className} onClick={onClick}>
      <div>{item.content}</div>
      <TimelineItemFooter item={item} />
    </div>
  );
};

type HistoryItemType = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className: string;
  item: CommentDTO;
};

export const HistoryItem = ({ onClick, className, item }: HistoryItemType) => {
  className = className || '';
  className = className + ' thread-item';
  return (
    <div className={className} onClick={onClick}>
      <div dangerouslySetInnerHTML={{ __html: item.message }}></div>
      <TimelineItemFooter item={item} />
    </div>
  );
};

// export const VersionTimeline = ({items}) => {
//   return (
//     <div className="comment-thread">
//       {items.map( (item) =>
//         <VersionItem key={item.timestamp} item={item} onClick={}/>
//       )}
//     </div>
//   );
// };
