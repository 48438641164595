import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from './spinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid var(--color-blue100);
  border-radius: 12px;
  padding: 0 12px;
  margin: 24px 0;
  box-shadow: var(--color-gray50) 0px 3px 12px;

  & > .spinner {
    padding: 12px;
  }
  & > .legend {
    padding: 12px;
    margin-left: -6px;
    color: var(--color-gray400);
    font-weight: 500;
  }
`;

export const SpinnerWithLegend = ({ legend = 'Loading…' }) => {
  return (
    <Wrapper>
      <span className="spinner">
        <Spinner />
      </span>
      <span className="legend">{legend}</span>
    </Wrapper>
  );
};
