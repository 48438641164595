import { TrackGadget } from './track-gadget';
import { makeObservable, observable, reaction } from 'mobx';
import { Context2D } from './canvas';

export class WaveformTrackGadget extends TrackGadget {
  @observable notifyTimeSelect = 0;
  lastTimeSelect = -1;
  waveformData: number[] = null;
  fillStyle = '#000000';

  constructor() {
    super();
    makeObservable(this);
  }

  onTimeSelect(f: () => void) {
    this.disposers.push(reaction(() => this.notifyTimeSelect, f));
  }

  handleMouseClickAtTime(x: number, y: number, time: number) {
    this.lastTimeSelect = time;
    this.notifyTimeSelect++;
    return true;
  }

  setWaveformData(waveformData: number[]) {
    this.waveformData = waveformData;
    this.requestFullRedraw();
  }

  drawForegroundLayer(ctx: Context2D) {}

  drawBackgroundLayer(ctx: Context2D) {
    if (!this.waveformData) {
      return;
    }
    ctx.save();
    ctx.fillStyle = this.fillStyle;
    const sampleDurationMS = 10;
    const startTime = this.canvasStartTime;
    const startSample = Math.round(startTime / sampleDurationMS);
    const numSamples = Math.round(this.canvasTimeExtent / sampleDurationMS);
    for (let i = 0; i < numSamples; i++) {
      const sampleValue = this.waveformData[startSample + i];
      const sampleTime = startTime + sampleDurationMS * i;
      // TODO unhardcode amplitude scaling
      this.drawTimerangeRect(
        ctx,
        { begin: sampleTime, end: sampleTime + sampleDurationMS },
        (this.height * sampleValue) / 80000
      );
    }
    ctx.restore();
  }
}
