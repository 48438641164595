import {
  computeCueFrameSliceByTime,
  computeTranscriptSliceByTime,
  spanGetWords,
} from './spans';
import { sliceFrame } from './frame_ts';
import { compare, sliceIndexes, sliceWith } from './util_ts';
import {
  AudioRegionsSpan,
  ContentSpan,
  CuesSpan,
  TranscriptSpan,
} from './spans';
import { Slice, TimeInterval } from './timestamper-types';
import {
  GapPair,
  MatchResult,
  PositionPair,
  SliceIntervalsPair,
  SlicePair,
} from './match-result';

export type Section = {
  tag: 'SECTION';
  content: ContentSpan;
  transcript: TranscriptSpan;
  contentCues: CuesSpan;
  audioRegions: AudioRegionsSpan;
  startTime: number;
  endTime: number;
};

export type ZipRun = {
  tag: 'ZIP_RUN';
  subTag: string;
  globalPosition: number;
  intervals: TimeInterval[];
};

export type SkipRun = {
  tag: 'SKIP_RUN';
  globalPosition: number;
  skipCount: number;
  interval: TimeInterval;
};

export function makeSubSection(
  section: Section,
  startTime: number,
  endTime: number,
  contentSlice: Slice
): Section {
  // if (startTime > endTime) {
  //   debugger;
  // }
  const transcriptSlice = computeTranscriptSliceByTime(
    section.transcript.data,
    startTime,
    endTime
  );
  const cuesSlice = computeCueFrameSliceByTime(
    section.contentCues.data,
    startTime,
    endTime
  );
  const content: ContentSpan = {
    data: sliceFrame(section.content.data, contentSlice),
    startTime,
    endTime,
  };
  const transcript: TranscriptSpan = {
    data: sliceFrame(section.transcript.data, transcriptSlice),
    startTime,
    endTime,
  };
  const contentCues: CuesSpan = {
    data: sliceFrame(section.contentCues.data, cuesSlice),
    startTime,
    endTime,
  };
  const audioRegions = {
    data: section.audioRegions.data,
    startTime,
    endTime,
  };
  return {
    tag: 'SECTION',
    content,
    transcript,
    contentCues,
    audioRegions,
    startTime,
    endTime,
  };
}

export function makeZipRun(
  section: Section,
  tag: string,
  globalPosition: number,
  intervals: TimeInterval[]
): ZipRun {
  return {
    tag: 'ZIP_RUN',
    subTag: tag,
    globalPosition,
    intervals,
  };
}

export function splitSectionWithSliceIntervalsPairs(
  section: Section,
  tag: string,
  pairs0: SliceIntervalsPair[]
): (Section | ZipRun)[] {
  let sectionStartTime = section.startTime;
  let sectionStartIdx = 0;
  let sectionEndTime = 0;
  // TODO
  const pairs = pairs0.slice().sort(compare);
  const sectionWordCount = spanGetWords(section.content).length | 0;
  const result: (Section | ZipRun)[] = [];
  for (const { terminal, contentSlice: s, intervals } of pairs) {
    // const forLoopVar = pairs[idx];
    // const terminal = forLoopVar.terminal;
    // const s = forLoopVar.contentSlice;
    // const intervals = forLoopVar.intervals;
    const [sliceStart, sliceLast] = s;
    // const sliceStart = s[0] | 0;
    // const sliceLast = s[1] | 0;
    const sectionEndIdx = sliceStart - 1;
    sectionEndTime = intervals[0].startTime;
    if (sectionEndIdx >= sectionStartIdx) {
      // System_Array__$005B$005D$1_append_1505(
      result.push(
        makeSubSection(section, sectionStartTime, sectionEndTime, [
          sectionStartIdx,
          sectionEndIdx,
        ])
      );
    }
    sectionStartIdx = sliceLast + 1;
    sectionStartTime = intervals[intervals.length - 1].endTime;
    const sliceLen = sliceLast - sliceStart + 1;
    // TODO if sliceLen = intervals.Length && terminal then
    if (sliceLen === intervals.length ? terminal : false) {
      const startGPos = sliceWith(section.content.data.globalPosition, s)[0];
      // System_Array__$005B$005D$1_append_1505(
      result.push(makeZipRun(section, tag, startGPos, intervals));
    } else {
      // System_Array__$005B$005D$1_append_1505(
      result.push(
        makeSubSection(section, intervals[0].startTime, intervals[0].endTime, s)
      );
    }
  }
  if (sectionStartIdx < sectionWordCount) {
    sectionEndTime = section.endTime;
    // System_Array__$005B$005D$1_append_1505(
    result.push(
      makeSubSection(section, sectionStartTime, sectionEndTime, [
        sectionStartIdx,
        null,
      ])
    );
  }
  return result;
}

export function sectionGetTranscriptWordInterval(
  section: Section,
  i: number
): TimeInterval {
  const startTime = section.transcript.data.startTime[i];
  const endTime = section.transcript.data.endTime[i];
  return {
    startTime: Math.max(startTime, section.startTime),
    endTime: Math.min(endTime, section.endTime),
  };
}

export function sectionGetTranscriptWordIntervalsSlice(
  section: Section,
  slice: Slice
): TimeInterval[] {
  // return Array.from(
  //   delay(() =>
  //     map(
  //       i => sectionGetTranscriptWordInterval(section, i),
  //       sliceIndexes(slice[0], slice[1])
  //     )
  //   )
  // );
  // [| for i in sliceIndexes(slice) -> sectionGetTranscriptWordInterval(section, i) |]
  return sliceIndexes(slice).map(i =>
    sectionGetTranscriptWordInterval(section, i)
  );
}

export function splitSectionWithSlicePairs(
  section: Section,
  tag: string,
  pairs: SlicePair[]
): (Section | ZipRun)[] {
  const mapped: SliceIntervalsPair[] = [];
  for (const r of pairs) {
    const intervals = sectionGetTranscriptWordIntervalsSlice(
      section,
      r.transcriptSlice
    );
    // System_Array__$005B$005D$1_append_1505(
    mapped.push({
      contentSlice: r.contentSlice,
      intervals,
      terminal: true,
    });
  }
  return splitSectionWithSliceIntervalsPairs(section, tag, mapped);
}

export function splitSectionWithPositionPairs(
  section: Section,
  tag: string,
  pairs: PositionPair[]
): (Section | ZipRun)[] {
  const mapped: SliceIntervalsPair[] = [];
  for (const r of pairs) {
    // const r = pairs[idx];
    const mappedPair: SliceIntervalsPair = {
      contentSlice: [r.contentPos, r.contentPos],
      intervals: [sectionGetTranscriptWordInterval(section, r.transcriptPos)],
      terminal: true,
    };
    // System_Array__$005B$005D$1_append_1505(mapped, mappedPair);
    mapped.push(mappedPair);
  }
  return splitSectionWithSliceIntervalsPairs(section, tag, mapped);
}

export function splitSectionWithGapPairs(
  section: Section,
  tag: string,
  pairs: GapPair[]
): (Section | ZipRun)[] {
  let startContentPos = 0;
  let endContentPos = 0;
  let startTime = section.startTime;
  let endTime = 0;
  if (spanGetWords(section.content).length === 0) {
    return [];
  }
  const mapped: SliceIntervalsPair[] = [];
  for (const pair of pairs) {
    // const pair = pairs[idx];
    const contentPos = pair.contentPos;
    const gapInterval = pair.gapInterval;
    endContentPos = contentPos - 1;
    endTime = gapInterval.startTime;
    if (endContentPos >= startContentPos) {
      const mappedPair: SliceIntervalsPair = {
        contentSlice: [startContentPos, endContentPos],
        intervals: [{ startTime, endTime }],
        terminal: false,
      };
      // System_Array__$005B$005D$1_append_1505(mapped, mappedPair);
      mapped.push(mappedPair);
    }
    startTime = gapInterval.endTime;
    startContentPos = endContentPos + 1;
  }
  endContentPos = spanGetWords(section.content).length - 1;
  endTime = section.endTime;
  if (endContentPos >= startContentPos) {
    const mappedPair: SliceIntervalsPair = {
      contentSlice: [startContentPos, endContentPos],
      intervals: [{ startTime, endTime }],
      terminal: false,
    };
    // System_Array__$005B$005D$1_append_1505(mapped, mappedPair);
    mapped.push(mappedPair);
  }
  return splitSectionWithSliceIntervalsPairs(section, tag, mapped);
}

export function splitSectionWithMatchResult(section: Section, m: MatchResult) {
  const matchInfo = m.matchInfo;
  const tag = m.matcherKind;
  switch (matchInfo.kind) {
    case 'SLICE_PAIRS': {
      return splitSectionWithSlicePairs(section, tag, matchInfo.pairs);
    }
    case 'POSITION_PAIRS': {
      return splitSectionWithPositionPairs(section, tag, matchInfo.pairs);
    }
    case 'GAP_PAIRS': {
      return splitSectionWithGapPairs(section, tag, matchInfo.pairs);
    }
    case 'SLICE_INTERVALS_PAIRS': {
      return splitSectionWithSliceIntervalsPairs(section, tag, matchInfo.pairs);
    }
    default:
      throw new Error();
  }
}
