import { chaatToolModel, mutationActions, transportState } from '../app-root';
import { AudioMarkerActions } from '@masala-lib/editorial/models/actions/audio-marker-actions';

export class ChaatAudioMarkerActions extends AudioMarkerActions {
  get mutationActions() {
    return mutationActions;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get currentAudioMarkers() {
    return chaatToolModel.audioMarkers;
  }
  get currentAudioMarkerHitIntervals() {
    return chaatToolModel.audioMarkerHitIntervals;
  }
}
