import * as React from 'react';
import styled from 'styled-components';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';
import { useKeyboardBinding } from '../../lib/hooks/use-keyboard-binding';
import createListener from 'keyboardist';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid var(--color-gray100);
  border-radius: 12px;
  padding-left: 8px;
  background: white;
  & > label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray200);
    height: 100%;
    cursor: pointer;
  }
  & > input {
    border: none;
    display: block;
    width: 100%;
    display: block;
    background: transparent;
    padding: 4px 12px 4px 4px;
    outline: none;
    color: var(--color-gray900);
    ::placeholder {
      color: var(--color-gray300);
    }
  }

  & > .clear-search {
    border: none;
    background: none;
    position: absolute;
    top: 4px;
    right: 4px;
    color: var(--color-gray100);
    outline: none;
    &:hover {
      color: var(--color-gray200);
    }
  }
`;

const searchIcon = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9ZM13.1922 14.6064C12.0236 15.4816 10.5723 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.5723 15.4816 12.0236 14.6064 13.1922L20.5356 19.1213L19.1213 20.5355L13.1922 14.6064Z"
      fill="currentColor"
    />
  </svg>
);

const clearIcon = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7 8.11111L8.11111 7L12 10.8889L15.8889 7L17 8.11111L13.1111 12L17 15.8889L15.8889 17L12 13.1111L8.11111 17L7 15.8889L10.8889 12L7 8.11111Z"
      fill="currentColor"
    />
  </svg>
);

export const SearchBar = ({ manager }: { manager: FilterViewManager<any> }) => {
  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    manager.setFilterText(ev.target.value);
  };

  const ref = React.useRef<HTMLInputElement>();
  useKeyboardBinding('slash', () => {
    ref.current.focus();
  });

  const inputListener = React.useRef(null);
  React.useEffect(() => {
    if (!inputListener.current) {
      inputListener.current = createListener('keydown', ref.current);
    }

    if (inputListener.current) {
      return inputListener.current.subscribe('escape', () => {
        ref.current.blur();
        handleClear();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClear = () => {
    if (ref.current) {
      ref.current.value = '';
      manager.setFilterText('');
    }
  };

  return (
    <Wrapper>
      <label htmlFor="search">{searchIcon}</label>
      <input
        ref={ref}
        id="search"
        placeholder={'search (/)'}
        type="text"
        size={30}
        onChange={handleChange}
        value={manager.filterText}
      />
      {ref.current && ref.current.value ? (
        <button className="clear-search" onClick={handleClear}>
          {clearIcon}
        </button>
      ) : null}
    </Wrapper>
  );
};
