import { AlertMessages } from '../../misc/alert-messages';
import { ElementId } from '@tikka/basic-types';
import { Auth } from './auth';
import { MutationActions } from './mutation-actions';
import { Attributed, AttributedElement } from '../../editorial-types';

export class MutationActionsServer extends MutationActions {
  get auth(): Auth {
    throw Error('not implemented server side');
  }

  get alerts(): AlertMessages {
    throw Error('not implemented server side');
  }

  checkPermissions(action?: any) {}

  elementWillUpdate(id: ElementId) {}

  addAttribution(element: AttributedElement) {
    element.author = 'SERVICE'; // TODO attribution for server side ops
    this.addTimestamp(element);
    return element;
  }
}
