import { TagData } from './../catalog-types';
import { CatalogEntityKind } from '../catalog-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { isEmpty, lowerCase } from 'lodash';
import { randomSlug } from '../../utils';
import { runInAction } from 'mobx';
import { TagManager } from '../db/tag-manager';
import { FilterWidgetData } from '@tikka/client/catalog-types';

export const tagTypeOptions = [
  {
    label: 'Country',
    value: 'country',
  },
  {
    label: 'Topic',
    value: 'topic',
  },
  {
    label: 'AP Tag',
    value: 'ap',
  },
  {
    label: 'IB Tag',
    value: 'ib',
  },
] as const;

export type TagType = typeof tagTypeOptions[number]['value'];

export const emptyTagData = {
  kind: CatalogEntityKind.TAG,
  id: '',
  name: '',
  slug: '',
  tagType: '',
};

export class Tag
  extends BaseCatalogEntity<TagData>
  implements CatalogEntity<TagData>
{
  constructor(data?: {
    id: string;
    name: string;
    slug: string;
    tagType: string;
  }) {
    super(data);
    this.data = { ...emptyTagData, ...data };
  }

  theManager(): EntityManager<CatalogEntity<TagData>, TagData> {
    return TagManager.getInstance() as unknown as EntityManager<
      CatalogEntity<TagData>,
      TagData
    >;
  }

  get navPath(): string {
    return `/tags/${this.id}`;
  }

  fromFormData(d: TagData): void {
    let name = d.name?.trim();
    if (isEmpty(name)) {
      // guarantee we always have a name so UI isn't broken if client doesn't properly validate
      name = randomSlug(5);
    }
    const slug = this.sanitizedTypeSlug(d.tagType as TagType, d.slug, name);

    runInAction(() => {
      this.data = { ...this.data, ...d, name, slug };
    });
  }

  sanitizedTypeSlug(tagType: TagType, slug: string, name: string): string {
    console.log(`tag.santizeSlug - slug: ${slug}, name: ${name}`);
    const candidate = super.sanitizedSlug(slug, name);

    const existing = TagManager.getInstance().getByTypeSlug(tagType, candidate);
    if (existing && existing.id !== this.id) {
      const message = `error - slug collision: ${candidate} - this.id: ${this.id}, exising.id: ${existing.id}`;
      console.log(message);
      global.alert(message); // TODO: @Armando, please clean up the error flow here
      throw Error(message);
    }
    return candidate;
  }

  get widgetHeading(): string {
    const { tagType, name, widgetHeading } = this.data;
    // will need to be revisited when supporting localization
    if (widgetHeading) {
      return widgetHeading;
    }
    switch (tagType) {
      case 'topic':
        return `Stories about ${lowerCase(name)}`;
      case 'country':
        return `Stories from ${name}`;
      default:
        console.error(`unexpected tagType for widget - ${this.slug}`);
        return name;
    }
  }

  get clientFilterKey(): string {
    switch (this.data.tagType) {
      case 'topic':
        return 'topics';
      case 'country':
        return 'countries';
      default:
        return this.data.tagType;
    }
  }

  get filterWidgetData(): FilterWidgetData {
    return {
      slug: this.data.slug,
      key: this.clientFilterKey,
      value: this.data.name,
      heading: this.widgetHeading,
    };
  }

  destroy = async () => {
    console.log(`tag.destroy`);
    await TagManager.getInstance().delete(this);
  };
}
