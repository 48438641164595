import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { idIsOfKind, WordId, WordIdRange } from '@tikka/basic-types';
import {
  ElementList,
  WordGroup,
  Word,
  CreateElementList,
} from '../../editor-aliases';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';
import { EKind } from '../../element-kinds';
import { randomString } from '../../utils';
import { renderWordRange } from './word-range-render';
import {
  domIdToElementId,
  getKindFromId,
} from '@tikka/elements/element-id-utils';
import { renderStylesForDomId } from './style-painting/dom-styles';
import {
  MembershipList,
  CreateMembershipList,
  CreateMembershipReconciler,
} from '@tikka/membership-reconciliation/membership-reconciler';

// import { renderView as renderWordRangeSelectView } from './word-range-select-view.js';

@observer
export class WordRangeSelect extends React.Component<any> {
  setWordRangeSelection: (id: WordIdRange) => void;
  getWordRangeSelection: () => WordIdRange;
  domScope: string = randomString(8);

  constructor(props: any) {
    super(props);
    this.setWordRangeSelection = props.setWordRangeSelection;
    this.getWordRangeSelection = props.getWordRangeSelection;
  }

  get disabled(): boolean {
    return this.props.disabled;
  }

  // TODO change these to getters
  get givenMembershipLists(): Map<string, MembershipList> {
    return this.props.givenMembershipLists;
  }

  get wordGroups(): ElementList<WordGroup> {
    return this.props.wordGroups;
  }

  get domainWords(): ElementList<Word> {
    return this.props.domainWords;
  }

  get displayWordRange(): WordIdRange {
    return this.props.displayWordRange;
  }

  get displayWordIndexRange() {
    return this.domainWords.idRangeToIndexRange(this.displayWordRange);
  }

  get wordSelectionMembershipList() {
    const currentSelection = this.getWordRangeSelection();
    let els = currentSelection
      ? [makeAdhocRangeElement(currentSelection, this.domainWords)]
      : [];
    const elements = CreateElementList({
      elements: els,
      words: this.domainWords,
    });
    return CreateMembershipList({
      memberships: ['word-range-selection'],
      presentationIdPrefix: this.domScope,
      useRanges: true,
      elements: elements,
    });
    // return {
    //   memberships: ['word-range-selection'],
    //   domScope: this.domScope,
    //   useRanges: true,
    //   elements: elements,
    //   membershipsString: null as string,
    // };
  }

  get membershipLists() {
    const result: Map<string, MembershipList> = new Map(
      this.givenMembershipLists.entries()
    );
    result.set('wordSelection', this.wordSelectionMembershipList);
    return result;
  }

  handleWordClick(event: MouseEvent, id: WordId) {
    if (this.disabled || !id) {
      return;
    }
    const currentSelection = this.getWordRangeSelection();
    if (event.getModifierState('Shift') && currentSelection) {
      if (id >= currentSelection.begin) {
        this.setWordRangeSelection({ begin: currentSelection.begin, end: id });
      } else {
        this.setWordRangeSelection({ begin: id, end: currentSelection.end });
      }
    } else {
      this.setWordRangeSelection({ begin: id, end: id });
    }
  }

  handleClick(event: MouseEvent) {
    // TODO figure out how to not strong coerce
    const domId = (event.target as any).id;
    const id = domIdToElementId(this.domScope, domId);
    // const kind = getKindFromId(id);
    if (idIsOfKind(id, EKind.WORD)) {
      this.handleWordClick(event, id);
    }
  }

  render() {
    const membershipListsRenderer =
      CreateMembershipReconciler(renderStylesForDomId);
    membershipListsRenderer.setMembershipLists(this.membershipLists);
    const html = renderWordRange(
      this.domScope,
      membershipListsRenderer,
      this.domainWords,
      this.displayWordIndexRange,
      this.wordGroups
    );
    return (
      <div
        className="word-range-select"
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={(e: any) => this.handleClick(e)}
      />
    );
  }
}
