import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';

export function useExcerpt() {
  const { id = null } = useParams<{ id: string }>();
  const manager = ExcerptManager.getInstance();
  const [model, setModel] = React.useState<Excerpt>(null);

  // console convenience
  (window as any).currentExcerpt = model;

  useEffect(() => {
    // setModel(manager.fetchById(id));
    manager.loadById(id).then(model => setModel(model));
  }, [manager, id]);

  return model;
}
