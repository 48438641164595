import * as React from 'react';
import { observer } from 'mobx-react';
import { createLogger } from '@app/logger';
import { SamosaModel } from 'samosa/models/samosa-model';
import { setSamosaModel } from 'samosa/models/samosa-model-handle';
import { Sidebar } from './sidebar';
import { MessageEntry } from './message-entry';
// import { EditingActionBar } from './editing-action-bar';
import { styled } from '@naan/stitches.config';
import { CurrentThreadArea } from './thread-area/current-thread-area';
import { Overview } from './overview';
import { scrollThreadsToBottom } from './utils/scrolling-fx';
import { GlobalStyles } from '@naan/global-styles';
import { RenderModal } from './utils/imperative-modal';
import { Portal } from '@console/components/modals/portal';
import { DialogPresenter } from './dialog-presenter';
import { PresentedDialog } from './presented-dialog';
import { FocusRestorer } from '@naan/primitives/modals/dialog/focus-restorer';
import { KeyboardService } from 'lib/services/keyboard-service';
import { HelpOverlay } from './help-modal';

const log = createLogger('samosa-view');

const Wrapper = styled('div', {
  '--sidebarHeight': '300px',
  '--maxChatWidth': '800px',

  height: '100vh', // this var gets inherited from the page layout
  display: 'grid',
  gridTemplateColumns: 'var(--sidebarHeight) 1fr',
  gridTemplateRows: '1fr',
  gridTemplateAreas: '"sidebar body"',
  '& > .sidebar': {
    gridArea: 'sidebar',
    backgroundColor: '$colors$blue-50',
  },
  '& > .body': {
    gridArea: 'body',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    overflow: 'auto',

    '& > .chat-area': {
      background: '$colors$gray-20',
      overflow: 'auto',
      paddingBottom: 88,
      paddingTop: 12,
    },

    '& > .entry-area': {
      position: 'relative',
      background: '$colors$gray-25',
    },
  },
});

// window.scrollToBottom = scrollThreadsToBottom;

export const SamosaView = observer(
  ({ model, goBack }: { model: SamosaModel; goBack: () => void }) => {
    setSamosaModel(model);

    log.debug('projectId', model.projectId);

    React.useEffect(() => {
      setTimeout(() => {
        scrollThreadsToBottom();
      }, 50);
    }, [model.currentStepId]);

    return (
      <>
        <GlobalStyles />
        <FocusRestorer />
        <Wrapper>
          <div className="sidebar">
            <Sidebar goBack={goBack} />
          </div>
          <div className="body">
            <div className="top">
              <Overview />
            </div>
            <div className="chat-area" id="SCROLLABLE-AREA">
              <CurrentThreadArea />
            </div>
            <div className="entry-area">
              <MessageEntry />
            </div>
          </div>
        </Wrapper>
        <RenderModal /> {/* jason's imperative modal */}
        <PresentedDialog />
        <HelpOverlay />
      </>
    );
  }
);
