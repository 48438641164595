import { computed, makeObservable, observable, reaction } from 'mobx';
import { NO_INDEX } from '@tikka/basic-types';
import { Element } from '../../editorial-types';

export type FilterTerm = {
  inputText: string;
  parsed: boolean;
  kind: string;
  props: any;
};

export type FilterDef = {
  kind: string;
  isFlag: boolean;
  canonicalText: (term: FilterTerm) => string;
  parse: (term: FilterTerm) => void;
  func: (term: FilterTerm) => (el: any) => boolean;
};

export function regexTermParser(kind: string, pattern: RegExp) {
  const re = new RegExp(pattern);
  const parse = (term: FilterTerm) => {
    const m = term.inputText.match(re);
    if (m) {
      term.kind = kind;
      term.parsed = true;
      term.props = m.groups;
    }
  };
  return parse;
}
export const functionListAND =
  (funcs: ((a: any) => boolean)[]) =>
  (v: any): boolean => {
    if (funcs.length === 0) {
      return false;
    } else {
      return funcs.findIndex(f => !f(v)) === NO_INDEX;
    }
  };

export class FilterModel {
  filterDefs: FilterDef[];
  filterDefMap: Map<string, FilterDef> = new Map();

  @observable.ref uiText = '';

  @observable.ref inputFilterTerms: FilterTerm[] = [];

  @observable.ref filterFunction = (e: Element) => false;

  disposers: (() => void)[] = [];

  allFlagKindsList: string[];

  allFlagKindsSet: Set<string>;

  termKindsCanonicalOrder: string[];

  termSortComparer: (a: FilterTerm, b: FilterTerm) => number;

  constructor(filterDefs0: FilterDef[]) {
    this.filterDefs = filterDefs0;
    this.allFlagKindsList = this.filterDefs
      .filter(def => def.isFlag)
      .map(def => def.kind);
    this.allFlagKindsSet = new Set(this.allFlagKindsList);
    this.termKindsCanonicalOrder = this.filterDefs.map(def => def.kind);

    this.termSortComparer = (t1: FilterTerm, t2: FilterTerm) => {
      const t1index = this.termKindsCanonicalOrder.findIndex(
        kind => t1.kind === kind
      );

      // TODO factor with above instead
      const t2index = this.termKindsCanonicalOrder.findIndex(
        kind => t2.kind === kind
      );
      return t1index - t2index;
    };
    for (const def of this.filterDefs) {
      this.filterDefMap.set(def.kind, def);
    }
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.uiText,
        () => this.computeInputFilterTerms()
      )
    );
    this.disposers.push(
      reaction(
        () => this.inputFilterTerms,
        () => this.computeUiText()
      )
    );
    this.disposers.push(
      reaction(
        () => this.filterKey,
        () => this.computeFilterFunction()
      )
    );
  }

  rawTermsFromText(text: string): FilterTerm[] {
    const paddedText = ' ' + text; // TODO for some reason cannot find an delimiter at start of string unless do this
    const delimiters = /@|#/g;
    const matches = [...paddedText.matchAll(delimiters)];
    const lookBehindAt = (offset: number) =>
      offset > 0 && paddedText[offset - 1] === '@';
    let offsets = matches
      .filter(m => !lookBehindAt(m.index))
      .map(m => m.index - 1);
    offsets = [0, ...offsets, text.length];
    const result: FilterTerm[] = [];
    for (let s0 = 0; s0 < offsets.length; s0++) {
      const e0 = s0 + 1;
      const s = offsets[s0];
      const e = offsets[e0];
      result.push({
        inputText: text.slice(s, e),
        parsed: false,
        kind: null,
        props: null,
      });
    }
    return result;
  }

  parseTerm(term: FilterTerm) {
    for (const def of this.filterDefs) {
      def.parse(term);
      if (term.parsed) {
        break;
      }
    }
  }

  termCanonicalText(term: FilterTerm): string {
    // TODO for flags express relationship between kinds and canonical rep with bidirectional mapping and also use for do matching?
    if (term.parsed) {
      const def = this.filterDefMap.get(term.kind);
      return def.canonicalText(term);
    } else {
      return null;
    }
  }

  termUiText(term: FilterTerm): string {
    return term.inputText || this.termCanonicalText(term);
  }

  termFilterFunction(term: FilterTerm): (el: Element) => boolean {
    // TODO for flags use some kind of mapping not if statements? (use if statments for others)
    if (term.parsed) {
      const def = this.filterDefMap.get(term.kind);
      return def.func(term);
    } else {
      throw Error('not parsed in termFilterFunction');
    }
  }

  computeInputFilterTerms() {
    const terms: FilterTerm[] = this.rawTermsFromText(this.uiText);
    for (const term of terms) {
      this.parseTerm(term);
    }
    this.inputFilterTerms = terms;
  }

  computeUiText() {
    const termStrings = this.inputFilterTerms.map(term =>
      this.termUiText(term)
    );
    this.uiText = termStrings.join('');
  }

  @computed
  get parsedFilterTerms(): FilterTerm[] {
    return this.inputFilterTerms.filter(term => term.parsed);
  }

  @computed
  get activeFlags(): Set<string> {
    const flagList = this.parsedFilterTerms
      .filter(term => this.allFlagKindsSet.has(term.kind))
      .map(term => term.kind);
    return new Set(flagList);
  }

  @computed
  get filterKey(): string {
    const terms = this.parsedFilterTerms.slice().sort(this.termSortComparer);
    return terms.map(term => this.termCanonicalText(term)).join('');
  }

  computeFilterFunction() {
    const filters = this.parsedFilterTerms.map(term =>
      this.termFilterFunction(term)
    );
    this.filterFunction = functionListAND(filters);
  }

  setAllActiveFlagsOff() {
    for (const key of this.activeFlags) {
      this.setFlag(key, false);
    }
  }

  setFlag(flag: string, value: boolean) {
    if (value) {
      this.inputFilterTerms = [
        ...this.inputFilterTerms,
        { inputText: null, kind: flag, parsed: true, props: null },
      ];
    } else {
      this.inputFilterTerms = this.inputFilterTerms.filter(
        t => t.kind !== flag
      );
    }
  }
}
