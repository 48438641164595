import { observer } from 'mobx-react';
import { Auth } from '@masala-lib/editorial/db/auth';
import { confirm } from '../../lib/confirm';

interface DeleteActionsProps {
  model: {
    archived: boolean;
    archive: () => void;
    unarchive: () => void;
  };
}

// Make this into a generic component that takes "model" instead of unit
export const DeleteActions = observer(({ model }: DeleteActionsProps) => {
  const auth = Auth.getInstance();

  const handleArchive = () => {
    model.archive();
  };

  const handleRestore = () => {
    model.unarchive();
  };

  if (!model.archived && auth.can('archive_items')) {
    return (
      <button onClick={confirm(handleArchive)} className="danger-action">
        Archive
      </button>
    );
  }

  return (
    <>
      {auth.can('restore_items') ? (
        <button onClick={confirm(handleRestore)} className="danger-action">
          Restore
        </button>
      ) : null}
      {/* // only allow purge now from 'archived' view */}
      {/* {auth.can('permadelete_items') ? (
          <button onClick={confirm(handleDelete)} className="danger-action">
            Delete
          </button>
        ) : null} */}
    </>
  );
});
