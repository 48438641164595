import React from 'react';
import { scriptEditorModel } from '../models/app-root';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';

export const LintWarningItem = ({ warning }: { warning: LintWarning }) => {
  const suppressed = warning.suppressed;
  const infoOnly = warning.infoOnly;
  const handleToggle = () => {
    scriptEditorModel.setWarningSuppression(warning, !suppressed);
  };

  let className = 'focused-element-warning ';
  if (suppressed) {
    className += 'suppressed';
  }
  if (infoOnly) {
    className += 'info';
  }

  return (
    <div className={className}>
      {warning.message}
      <ActionLink onPress={() => handleToggle()}>
        {' '}
        {infoOnly ? null : `[${suppressed ? '+' : 'X'}]`}
      </ActionLink>
    </div>
  );
};
