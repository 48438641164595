import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { AuthorData } from '@masala-lib/catalog/catalog-types';
import { useAuthor } from './use-author';
import { AuthorForm } from './author-form';
import { AuthorManager } from '@masala-lib/catalog/db/author-manager';

interface AuthorEditParams {
  stopEditing: () => void;
}

export const AuthorEditScreen = observer(
  ({ stopEditing }: AuthorEditParams) => {
    const manager = AuthorManager.getInstance();
    const author = useAuthor();

    if (!author) {
      return null;
    }

    const onSubmit = (dto: AuthorData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      author.fromFormData(dto);
      manager.update(author).then(stopEditing);
    };

    return (
      <Page title={`Editing ${author.name}`}>
        <AuthorForm
          dto={author.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
        />
      </Page>
    );
  }
);
