import * as React from 'react';
import { observer } from 'mobx-react';
import { UnitGeneralInfo } from './unit-general-info';
import { Page } from '../shared/page';
import { useUnit } from './use-unit';
import { UnitDetailActions } from './unit-detail-actions';
import { UnitWorkflow } from './unit-workflow';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Lightbox } from '../../components/modals';
import { useSwitch } from '../../lib/hooks/use-switch';
import { Auth } from '@masala-lib/editorial/db/auth';
import { useNavigate } from 'react-router-dom';

interface UnitDetailParams {
  closeDetail: () => void;
  startEditing: () => void;
}

function isAuthorized(unit: Unit) {
  if (unit?.archived) {
    if (!Auth.getInstance().can('see_archived_items')) {
      return false;
    }
  }

  return true;
}

export const UnitDetailScreen = observer(
  ({ closeDetail, startEditing }: UnitDetailParams) => {
    const unit = useUnit();
    const navigate = useNavigate();
    const showWorkflowDialog = useSwitch(false);

    React.useEffect(() => {
      if (!isAuthorized(unit)) {
        // history.goBack();
        navigate(-1);
      }
    }, [navigate, unit]);

    /// this is very helpful for debugging.
    /// you'll have access to the currentUnit in the browser console
    (window as any).currentUnit = unit;

    if (!unit) {
      return null;
    }

    if (!isAuthorized(unit)) {
      return null;
    }

    const goBack = () => {
      navigate(unit.parentNavPath);
    };

    return (
      <Page
        title={`${unit.name} ${unit.archived ? '(Archived)' : ''}`}
        onClose={goBack}
        controls={<UnitDetailActions unit={unit} startEditing={startEditing} />}
      >
        <UnitGeneralInfo unit={unit} showWorkflowDialog={showWorkflowDialog} />
        <Lightbox
          showing={showWorkflowDialog.value}
          title={`${unit.name} : Create Linear Workflow`}
        >
          <UnitWorkflow unit={unit} showSwitch={showWorkflowDialog} />
        </Lightbox>
      </Page>
    );
  }
);
