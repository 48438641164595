import { GenericFirestoreDocSet } from '../../firestore-db/firestore-doc-set';
import { DocKeys } from './db';
import { isEmpty, isNil } from 'lodash';
import { epochSecondsFloat } from '../../utils';
import { DocumentSnapshot } from '@platform/firebase-types';

class ChaatDocSet0 extends GenericFirestoreDocSet {
  constructor(key: string, listenMode = true) {
    super(key, null, listenMode);
  }

  getDocKeys(): DocKeys[] {
    return [
      DocKeys.UNIT_METADATA_DOC,
      // DocKeys.EPISODE_METADATA_DOC,
      DocKeys.VERBATIM_DOC,
      DocKeys.STRUCTURAL_DOC,
      DocKeys.CHAAT_CUES_DOC,
      DocKeys.CHAAT_TIMESTAMPS_DOC,
      DocKeys.CHAAT_AUDIO_ANALYSIS_DOC,
      DocKeys.CHAAT_AUDIO_REGIONS_DOC,
      DocKeys.CHAAT_AUDIO_MARKERS_DOC,
      DocKeys.CHAAT_SPEECH_TRANSCRIPT_DOC,
      // DocKeys.CHAAT_AUDIO_PROCESSING_JDB_DOC,
      // DocKeys.CHAAT_TRANSCRIPTION_JOB_DOC,
      DocKeys.CHAAT_SIGNOFFS_DOC,
      DocKeys.CHAAT_TRICKY_EDITS,
      DocKeys.TRANSLATIONS_DOC,
      DocKeys.WORD_GROUP_DOC, // currently used by BogotaDataLoader which should be updated to use a different docset
    ];
  }

  handleSnapshot(doc: DocumentSnapshot<unknown>, docKey: string): void {
    if (docKey === DocKeys.CHAAT_TRICKY_EDITS) {
      // TODO factor this in more reasonable way, maybe with defaults per key if doc is empty
      const data = doc.data();
      if (isNil(data)) {
        this.docs[docKey] = { items: {} };
        return;
      }
    }
    super.handleSnapshot(doc, docKey);
  }

  verifyDocSet() {
    if (this.hasAllDocs) {
      // TODO still needs work overall to detect intermediate conditions like audio updated but transcription job not started yet
      const docs = this.docs;

      if (!docs) {
        console.log(`unexpectly missing docs`);
        this.status = 'DOCS NOT YET LOADEC';
        return;
      }

      const unitMetadata = docs.unitMetadataDoc;
      if (isEmpty(unitMetadata)) {
        this.status = 'UNIT_NOT_INITIALIZED';
        return;
      }
      if (
        !(
          unitMetadata.chaatReady ||
          unitMetadata.transcriptionJob?.jobFinishTimestamp
        )
      ) {
        this.status = 'CHAAT_NOT_INITIALIZED';
        return;
      }
      // if (isEmpty(this.chaatMetadataDoc)) {
      //   this.status = 'CHAAT_NOT_INITIALIZED';
      //   return;
      // }
      //} && isEmpty(docs.unitMetadataDoc?.transcriptionJob)) {
      // if (!docs.unitMetadataDoc.chaatInitTime) {
      //   this.status = 'CHAAT_NOT_INITIALIZED';
      //   return;
      // }
      // // if (isEmpty(docs.transcriptionJobDoc) || isNil(docs.transcriptionJobDoc.jobFinishTimestamp)) {
      // if (isNil(docs.unitMetadataDoc?.transcriptionJob?.jobFinishTimestamp)) {
      //   this.status = 'TRANSCRIPTION_NOT_FINISHED'; // TODO use constants for all of these values
      //   return;
      // }
      // if (
      //   docs.unitMetadataDoc.transcriptionJob.jobStartTimestamp >
      //   docs.unitMetadataDoc.transcriptionJob.jobFinishTimestamp
      // ) {
      //   this.status = 'TRANSCRIPTION_NOT_FINISHED';
      //   return;
      // }
      if (
        docs.verbatimDoc.timestamp === docs.timestampsDoc.scriptTimestamp &&
        docs.cuesDoc.timestamp === docs.timestampsDoc.cuesTimestamp &&
        docs.audioRegionsDoc.timestamp === docs.timestampsDoc.regionsTimestamp
      ) {
        this.status = 'COMPLETE';
      } else {
        const latestMutationTimestamp = Math.max(
          docs.verbatimDoc.timestamp,
          docs.cuesDoc.timestamp,
          docs.audioRegionsDoc.timestamp
        );
        if (latestMutationTimestamp < epochSecondsFloat() - 60) {
          this.status = 'VERY_OBSOLETE_TIMESTAMP_DATA';
          return;
        }
        this.status = 'OBSOLETE_TIMESTAMP_DATA';
      }
    }
  }
}

export function ChaatDocSet(key: string, listenMode?: boolean) {
  return new ChaatDocSet0(key, listenMode);
}
