import { observer } from 'mobx-react';
import * as React from 'react';
import { Button } from 'rebass';
import { OnePaneLayout } from '../shared/layouts';
import { PageHeading } from '../shared/page-heading';
import { Stack } from '../shared/stack';
import { DevErrorTesting } from './dev-error-testing';
import { createLogger } from '@app/logger';
import { createHelloSingleJob } from '@masala-lib/jobs/hello-single-stage-job';
import {
  createHelloMultiJob,
  restartHelloMultiJobPoller,
} from '@masala-lib/jobs/hello-multi-stage-job';
import { NavLink } from 'react-router-dom';

const log = createLogger('DevScreen');

export const DevScreen = observer(() => {
  const spawnHelloSingleJob = async () => {
    const params = {
      name: 'blah',
      sleepSeconds: 10,
      eventualResult: 'worked!',
    };
    log.info(`createChaatInitJob: ${JSON.stringify(params)} `);
    const jobId = await createHelloSingleJob(params);
    log.info(`jobId: ${JSON.stringify(jobId)}`);
    return jobId;
  };

  const spawnHelloSingleJobFail = async () => {
    const params = {
      name: 'going to die',
      sleepSeconds: 5,
      eventualResult: 'death',
    };
    log.info(`createChaatInitJob: ${JSON.stringify(params)} `);
    const jobId = await createHelloSingleJob(params);
    log.info(`jobId: ${JSON.stringify(jobId)}`);
    return jobId;
  };

  const spawnStillborn = async () => {
    const params = {
      name: 'stillborn',
      sleepSeconds: 5,
      eventualResult: 'humpty dumpty',
    };
    log.info(`createChaatInitJob: ${JSON.stringify(params)} `);
    const jobId = await createHelloSingleJob(params);
    log.info(`jobId: ${JSON.stringify(jobId)}`);
    return jobId;
  };

  const spawnHelloMultiJob = async () => {
    const params = {
      name: 'multi',
      sleepSeconds: 5,
      eventualResult: {
        initStatus: 'good',
        jobUri: 'http://localhost:3000/jobs/blah',
      },
    };
    const jobId = await createHelloMultiJob(params);
    log.info(`jobId: ${JSON.stringify(jobId)}`);
    return jobId;
  };

  return (
    <OnePaneLayout>
      <PageHeading>
        <h4>Dev utilites</h4>
      </PageHeading>
      <Stack>
        Spawn hello single-stage job:
        <button onClick={spawnHelloSingleJob}>succeed</button>
        {' - '}
        <button onClick={spawnHelloSingleJobFail}>fail</button>
        {' - '}
        <button onClick={spawnStillborn}>stillborn</button>
        <br />
        <button onClick={spawnHelloMultiJob}>spawn multi-stage job</button>
        {' - '}
        <button onClick={restartHelloMultiJobPoller}>restart poller</button>
        <DevErrorTesting />
        <br />
        <NavLink to="/tags/dump">dump tag localization code</NavLink>
      </Stack>
      {/* <Stack>
        <CatalogHarness />
      </Stack> */}
    </OnePaneLayout>
  );
});
