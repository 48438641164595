import * as React from 'react';

import styled from 'styled-components';

const FilterControlStyleWrapper = styled.div`
  padding: 16px;
  & + & {
    padding-top: 0;
  }

  & > .label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--color-gray600);

    & > .hint {
      color: var(--color-gray200);
    }
  }
`;

interface FilterControlWrapperProps {
  label: string;
  hint?: string;
  children: React.ReactNode;
}

export const FilterControlWrapper = ({
  label,
  children,
  hint = '',
}: FilterControlWrapperProps) => {
  return (
    <FilterControlStyleWrapper>
      <div className="label">
        {label} {hint && <span className="hint">({hint})</span>}
      </div>
      <div className="inner">{children}</div>
    </FilterControlStyleWrapper>
  );
};
