import { WordElementList, WordId } from '@tikka/basic-types';
import { Intervals, fromIntervals, size } from '@tikka/intervals/intervals';
import { TrickyEdit } from '../db/firestore-doc-types';

export function createQuantasFromIntervals(
  intervals0: Intervals,
  quantaMS: number,
  endTime: number,
  values: number[] = null
) {
  const numQuantas = Math.floor(endTime / quantaMS);
  const quantas: number[] = new Array(numQuantas).fill(0);
  const intervals = intervals0.asIntervals();
  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    let value = 1;
    if (values) {
      value = values[i];
    }
    const beginTime = interval.begin;
    const endTime = interval.end;
    const beginQuantaIndex = Math.floor(beginTime / quantaMS);
    const endQuantaIndex = Math.floor(endTime / quantaMS);
    // TODO handle case where beginQuantaIndex === endQuantaIndex

    const beginQuantaTime = beginQuantaIndex * quantaMS;
    const beginQuantaEndTime = beginQuantaTime + quantaMS;
    const beginQuantaOverlap = (beginQuantaEndTime - beginTime) / quantaMS;
    const beginQuantaAdjust = beginQuantaOverlap * value;
    quantas[beginQuantaIndex] += beginQuantaAdjust;
    const endQuantaTime = endQuantaIndex * quantaMS;
    const endQuantaOverlap = (endTime - endQuantaTime) / quantaMS;
    const endQuantaAdjust = endQuantaOverlap * value;
    quantas[endQuantaIndex] += endQuantaAdjust;
    for (let j = beginQuantaIndex + 1; j < endQuantaIndex; j++) {
      quantas[j] = value;
    }
  }
  return quantas;
}

export function createQuantasAverageCutoff(
  quantas: number[][],
  cutoff: number
) {
  cutoff = cutoff * quantas.length;
  const ilen = quantas[0].length;
  const result = new Array(ilen).fill(0);
  const jlen = quantas.length;
  for (let i = 0; i < ilen; i++) {
    let sum = 0;
    for (let j = 0; j < jlen; j++) {
      sum += quantas[j][i];
    }
    if (sum > cutoff) {
      result[i] = 1;
    }
  }
  return result;
}

export function createQuantasAnyCutoff(quantas: number[][], cutoff: number) {
  const ilen = quantas[0].length;
  const result = new Array(ilen).fill(0);
  const jlen = quantas.length;
  for (let i = 0; i < ilen; i++) {
    for (let j = 0; j < jlen; j++) {
      if (quantas[j][i] > cutoff) {
        result[i] = 1;
        break;
      }
    }
  }
  return result;
}

function evaluatePaceQuanta(
  words: WordElementList,
  quantaMS: number,
  epsilonMS: number,
  quantaIndex: number,
  mode: 'chars' | 'words'
) {
  const quantaTime = quantaIndex * quantaMS;
  const quantaTimeMidpoint = quantaTime + quantaMS / 2;
  const quantaEndTime = quantaTime + quantaMS;
  let beginTime = quantaTimeMidpoint - epsilonMS;
  let endTime = quantaTimeMidpoint + epsilonMS;
  const timeIntervals = words.timeIntervals;
  const wordIndexRange = timeIntervals.rangeIntersecting(beginTime, endTime);
  if (!wordIndexRange) {
    return 0;
  }
  const firstWordIndex = wordIndexRange.begin;
  const lastWordIndex = wordIndexRange.end;
  const firstWordInterval = timeIntervals.intervalAt(firstWordIndex);
  const firstWordBeginTime = firstWordInterval.begin;
  if (firstWordBeginTime > quantaEndTime) {
    return 0;
  }

  const lastWordInterval = timeIntervals.intervalAt(lastWordIndex);
  const lastWordEndTime = lastWordInterval.end;
  if (lastWordEndTime < quantaTime) {
    return 0;
  }
  beginTime = Math.max(firstWordBeginTime, beginTime);
  endTime = Math.min(lastWordEndTime, endTime);
  const duration = endTime - beginTime; // TODO decide if use this or sum of word durations as below
  const firstWordDuration = size(firstWordInterval);
  const firstWordSkippedDuration = beginTime - firstWordBeginTime;
  const firstWordOverlapDuration = firstWordDuration - firstWordSkippedDuration;
  const firstWordOverlapRatio = firstWordOverlapDuration / firstWordDuration;
  const lastWordDuration = size(lastWordInterval);
  const lastWordSkippedDuration = lastWordEndTime - endTime;
  const lastWordOverlapDuration = lastWordDuration - lastWordSkippedDuration;
  const lastWordOverlapRatio = lastWordOverlapDuration / lastWordDuration;

  let numeratorCount = 0;
  let totalTimeMS = 0;
  const firstContainedWordIndex = firstWordIndex + 1;
  const lastContainedWordIndex = lastWordIndex - 1;

  if (mode === 'chars') {
    const firstWordCharCount = words.values[firstWordIndex].text.length;
    numeratorCount += firstWordCharCount * firstWordOverlapRatio;
    totalTimeMS += firstWordOverlapDuration;
    const lastWordCharCount = words.values[lastWordIndex].text.length;
    numeratorCount += lastWordCharCount * lastWordOverlapRatio;
    totalTimeMS += lastWordOverlapDuration;
    for (let i = firstContainedWordIndex; i <= lastContainedWordIndex; i++) {
      const word = words.values[i];
      numeratorCount += word.text.length;
      totalTimeMS += size(timeIntervals.intervalAt(i));
    }
    const charsPerSecond = (numeratorCount * 1000) / totalTimeMS;
    return charsPerSecond;
  } else if (mode === 'words') {
    numeratorCount += firstWordOverlapRatio;
    numeratorCount += lastWordOverlapRatio;
    numeratorCount += lastContainedWordIndex - firstContainedWordIndex + 1;
    const wordsPerSecond = (numeratorCount * 1000) / duration;
    return wordsPerSecond;
  }
}

function createQuantasPace(
  words: WordElementList,
  quantaMS: number,
  epsilonMS: number,
  endTime: number,
  mode: 'chars' | 'words'
) {
  const numQuantas = Math.floor(endTime / quantaMS);
  const quantas: number[] = new Array(numQuantas).fill(0);
  for (let i = 0; i < numQuantas; i++) {
    quantas[i] = evaluatePaceQuanta(words, quantaMS, epsilonMS, i, mode);
  }
  return quantas;
}

export function createQuantasCharsPace(
  words: WordElementList,
  quantaMS: number,
  epsilonMS: number,
  endTime: number
) {
  return createQuantasPace(words, quantaMS, epsilonMS, endTime, 'chars');
}

export function createQuantasWordsPace(
  words: WordElementList,
  quantaMS: number,
  epsilonMS: number,
  endTime: number
) {
  return createQuantasPace(words, quantaMS, epsilonMS, endTime, 'words');
}

export function createScaledQuantasMax1(quantas: number[], scale: number) {
  const result = new Array(quantas.length).fill(0);
  for (let i = 0; i < quantas.length; i++) {
    result[i] = Math.min(quantas[i] * scale, 1.0);
  }
  return result;
}

export function createScaledQuantas(quantas: number[], scale: number) {
  const result = new Array(quantas.length).fill(0);
  for (let i = 0; i < quantas.length; i++) {
    result[i] = quantas[i] * scale;
  }
  return result;
}

function intervalPercentOverlapQuantas(
  intervalBegin: number,
  intervalEnd: number,
  quantas: number[],
  quantaMS: number
) {
  let weightedTime = 0;
  const beginQuantaIndex = Math.floor(intervalBegin / quantaMS);
  const endQuantaIndex = Math.floor(intervalEnd / quantaMS);
  if (beginQuantaIndex === endQuantaIndex) {
    return quantas[beginQuantaIndex];
  } else {
    if (quantas[beginQuantaIndex] !== 0.0) {
      const beginQuantaEndTime = (beginQuantaIndex + 1) * quantaMS;
      const beginQuantaOverlap = beginQuantaEndTime - intervalBegin;
      weightedTime += beginQuantaOverlap * quantas[beginQuantaIndex];
    }
    if (quantas[endQuantaIndex] !== 0.0) {
      const endQuantaBeginTime = endQuantaIndex * quantaMS;
      const endQuantaOverlap = intervalEnd - endQuantaBeginTime;
      weightedTime += endQuantaOverlap * quantas[endQuantaIndex];
    }

    for (let j = beginQuantaIndex + 1; j < endQuantaIndex; j++) {
      weightedTime += quantas[j] * quantaMS;
    }
  }
  return weightedTime / (intervalEnd - intervalBegin);
}

export function getWordIndexesOverlapQuantas(
  words: WordElementList,
  quantas: number[],
  quantaMS: number,
  cutoffRatio: number
) {
  const result: number[] = [];
  const timeIntervals = words.timeIntervals;
  const len = timeIntervals.length;
  for (let i = 0; i < len; i++) {
    const interval = timeIntervals.intervalAt(i);
    const intervalBegin = interval.begin;
    const intervalEnd = interval.end;
    const overlap = intervalPercentOverlapQuantas(
      intervalBegin,
      intervalEnd,
      quantas,
      quantaMS
    );
    if (overlap > cutoffRatio) {
      result.push(i);
    }
  }
  return result;
}

export function getWordsOverlapQuantas(
  words: WordElementList,
  quantas: number[],
  quantaMS: number,
  cutoffRatio: number
) {
  const wordElements = words.values;
  const wordIndexes = getWordIndexesOverlapQuantas(
    words,
    quantas,
    quantaMS,
    cutoffRatio
  );

  return wordIndexes.map(i => wordElements[i]);
}

export function getWordIntervalsOverlapQuantas(
  words: WordElementList,
  quantas: number[],
  quantaMS: number,
  cutoffRatio: number
) {
  const timeIntervals = words.timeIntervals;
  const wordIndexes = getWordIndexesOverlapQuantas(
    words,
    quantas,
    quantaMS,
    cutoffRatio
  );
  return fromIntervals(wordIndexes.map(i => timeIntervals.intervalAt(i)));
}

export function createQuantasOverride(
  quantas: number[],
  overrides: TrickyEdit[]
): number[] {
  const result = new Array(quantas.length).fill(0);
  for (let i = 0; i < quantas.length; i++) {
    const override = overrides[i];
    if (override === 'IN') {
      result[i] = 1.0;
    } else if (override === 'OUT') {
      result[i] = 0.0;
    } else {
      result[i] = quantas[i];
    }
  }
  return result;
}

export function createQuantasMasked(
  quantas: number[],
  mask: boolean[]
): number[] {
  const result = new Array(quantas.length).fill(0);
  for (let i = 0; i < quantas.length; i++) {
    result[i] = mask[i] ? quantas[i] : 0.0;
  }
  return result;
}

export type ControlPoint = [number, number];
export type ControlPoints = ControlPoint[];

export type CompressionSettings = {
  targetLevel: number;
  boostDamper: number;
  windowSize: number;
  enabled: boolean;
};

export type CompressionSettingsKey = keyof CompressionSettings;

export const compressionSettingDefaults: CompressionSettings = {
  targetLevel: 0.5,
  boostDamper: 0.8,
  windowSize: Math.floor(7000 / 25),
  enabled: false,
};

type ControlPointFunc = (x: number) => number;
export function createFunctionFromControlPoints(
  controlPoints: ControlPoints
): ControlPointFunc {
  controlPoints = [...controlPoints].reverse();
  const first = controlPoints[0];
  const last = controlPoints.at(-1);
  const stages = controlPoints.slice(1);
  const f = (x: number) => {
    if (x <= first[0]) {
      return first[1];
    }
    if (x >= last[0]) {
      return last[1];
    }
    let prevControlPoint = first;
    for (const stage of stages) {
      if (x <= stage[0]) {
        const [x0, y0] = prevControlPoint;
        const [x1, y1] = stage;
        const slope = (y1 - y0) / (x1 - x0);
        const y = slope * (x - x0) + y0;
        return y;
      }
      prevControlPoint = stage;
    }
  };
  return f;
}

export function createQuantasWithFunction(
  quantas: number[],
  f: ControlPointFunc
) {
  return quantas.map(q => f(q));
}

export function compressQuantasDynamicRange(
  samples: number[],
  boostDamper: number,
  targetLevel: number,
  windowSize: number
) {
  const len = samples.length;
  // let sum = 0;
  // for (const q of quantas) {
  //   sum += q;
  // }
  const threshold = targetLevel * 0.2;
  const fillLevel = targetLevel * 0.5;
  const result = new Array(len).fill(0);
  const ringSize = windowSize;
  const ringBuffer = new Array(ringSize).fill(fillLevel);
  let ringBufferSum = fillLevel * windowSize;
  const halfWindowSize = Math.floor(windowSize / 2);
  let ringBufferIndex = 0;
  let windowEdgeIndex = 0;
  // operating index is in the middle of the window
  let operatingIndex = ringBufferIndex - halfWindowSize;
  while (true) {
    let edgeWindowSample =
      windowEdgeIndex < len ? samples[windowEdgeIndex] : targetLevel;
    if (edgeWindowSample < threshold) {
      // make very weak signals neutral
      edgeWindowSample = fillLevel;
    }
    ringBufferSum -= ringBuffer[ringBufferIndex];
    ringBuffer[ringBufferIndex] = edgeWindowSample;
    ringBufferSum += edgeWindowSample;
    windowEdgeIndex++;
    ringBufferIndex++;
    operatingIndex++;
    if (ringBufferIndex >= ringSize) {
      ringBufferIndex = 0;
    }
    if (operatingIndex < 0) {
      // can't operate on samples before the beginning, only filling in the ring buffer
      continue;
    }
    if (operatingIndex >= len) {
      // done
      break;
    }
    const windowLevel = ringBufferSum / windowSize;
    const signalBoost = (targetLevel - windowLevel) * boostDamper;
    // const debug = signalBoost > 0.0 ? 'BOOST' : 'CUT';
    // console.log(debug);
    const operatingSample = samples[operatingIndex];
    let outputSample = operatingSample + signalBoost;
    outputSample = Math.max(0, outputSample);
    outputSample = Math.min(1, outputSample);
    result[operatingIndex] = outputSample;
  }
  return result;
}

export function filterSolitaryIndexes(indexes: number[]) {
  const result: number[] = [];
  let run: number[] = [];
  for (const index of indexes) {
    if (run.length === 0) {
      run.push(index);
    } else {
      if (index === run.at(-1) + 1) {
        run.push(index);
      } else {
        if (run.length > 1) {
          result.push(...run);
        }
        run = [index];
      }
    }
  }
  return result;
}

export type TrickyBitsSignalTransforms = {
  uncertaintyTransform: ControlPoints;
  charsPaceTransform: ControlPoints;
  wordsPaceTransform: ControlPoints;
  linearInterpolationTransform: ControlPoints;
  cutoffValue: number;
  compositionMode: 'AVG' | 'ANY';
};

export function adjustWithTenLevels(
  current: number,
  direction: 1 | -1,
  min: number,
  max: number
) {
  const range = max - min;
  const adjust = (direction * range) / 10;
  let result = current + adjust;
  result = Math.max(min, result);
  result = Math.min(max, result);
  return result;
}

export function overlappingValues(as: number[], bs: number[]): number[] {
  const result: number[] = [];
  // assuming each array sorted and unique
  let i = 0;
  let j = 0;
  while (i < as.length && j < bs.length) {
    const a = as[i];
    const b = bs[j];
    if (a === b) {
      result.push(a);
      i++;
      j++;
    } else if (a < b) {
      i++;
    } else {
      j++;
    }
  }
  return result;
}
