import {
  computed,
  configure as mobxConfigure,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { db } from '../../platform/firebase-init';
import { ScriptEditorEpisodeData } from './episode-data';
import { AlertMessages } from '@masala-lib/misc/alert-messages';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ConversationManager } from '@masala-lib/editorial/models/conversation-manager';
import { Linter } from '@masala-lib/editorial/linter/linter';
import { EKind } from '@masala-lib/element-kinds';
import { KeyboardModes } from '@masala-lib/misc/keyboard-mode';
import { ScriptEditorModel } from './script-editor-model';

import { FirestoreLoader } from '@masala-lib/firestore-db/firestore-loader';
import { ScriptDocSet } from '@masala-lib/editorial/db/script-doc-set';
import { notEmpty, notNull } from '@utils/conditionals';
import { Unit } from '@masala-lib/catalog/models/unit';
import { ScriptEditorMutationActions } from './mutation-actions';
import { ScriptEditorStructuralActions } from './actions/structural-actions';
import { ScriptEditorMetadataBlockActions } from './actions/metadata-block-actions';
import { ScriptEditorWordGroupActions } from './actions/word-group-actions';
import { ScriptEditorVerbatimActions } from './actions/verbatim-actions';
import { ScriptEditorTranslationActions } from './actions/translation-actions';
import { ScriptEditorVersions } from './versions';
import { Volume } from '@masala-lib/catalog/models/volume';
import { DbPaths } from '@masala-lib/editorial/db/db';
import { CatalogCollections } from '@masala-lib/catalog/db/catalog-db-paths';
import { isEmpty } from 'lodash';
import { UserManager } from '@masala-lib/editorial/models/user-manager';
import { ElementId } from '@masala-lib/editor-aliases';
import { createLogger } from '@app/logger';

const log = createLogger('script-editor:app-root');

mobxConfigure({ enforceActions: 'never' }); // TODO consider if should enforce actions

export const auth = new Auth();
export const alertMessages = new AlertMessages();
export const conversationManager = ConversationManager.getInstance(); // new ConversationManager();
export const userManager = UserManager.getInstance();
export const mutationActions = new ScriptEditorMutationActions();
export const scriptEditorModel = new ScriptEditorModel();
export const keyboardModes = new KeyboardModes();
export const structuralActions = new ScriptEditorStructuralActions();
export const metadataBlockActions = new ScriptEditorMetadataBlockActions();
export const wordGroupActions = new ScriptEditorWordGroupActions();
export const verbatimActions = new ScriptEditorVerbatimActions();
export const translationActions = new ScriptEditorTranslationActions();
export const versions = new ScriptEditorVersions();

export const episodeData = new ScriptEditorEpisodeData();
export const linter = new Linter(episodeData);

export const unit = new Unit();
export const volume = new Volume();

export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR';

export class AppRoot {
  // @observable.ref model: ScriptEditorModel;
  @observable.ref episodeKey = '';

  loader = new FirestoreLoader(ScriptDocSet);
  // unit: Unit = null;
  disposers: (() => void)[] = [];
  jumpId: ElementId = null;

  constructor() {
    log.debug('constructor');
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.loader.getStateVersion(),
        () => this.contentUpdated()
      )
    );
    this.disposers.push(
      reaction(
        () => conversationManager.getStateVersion(),
        () => this.contentUpdated()
      )
    );
  }

  @computed
  get status() {
    if (
      notEmpty(unit.data) &&
      !(unit.hasWriteAccess(auth.appUser) || unit.hasReadAccess(auth.appUser))
    ) {
      return PERMISSIONS_ERROR;
    }
    return this.loader.getStatus();
  }

  jumpToId(jump0: string, ms: number) {
    if (jump0) {
      const jump = jump0 as ElementId;
      setTimeout(() => scriptEditorModel.setFocusedElementId(jump), ms);
    }
  }

  // BEWARE! this appears to be no longer used
  async loadEpisodePromise(
    episodeKey: string,
    volumeId: string,
    locale: string = null,
    jumpId0: string = null,
    baselineTimestamp: number = null
  ): Promise<void> {
    // this.unit = await new UnitManager().loadSoloById(episodeKey);
    const paths = new DbPaths(db, episodeKey);
    log.info(`loadEpisodePromise - volumeId: ${volumeId}`);
    // hack to make sure the unit data gets loaded and the speaker bio lints works
    if (!volumeId) {
      let unitDoc = await paths.unitMetadataDocRef.get({ source: 'cache' });
      let unitData = unitDoc.data();
      if (isEmpty(unitData)) {
        unitDoc = await paths.unitMetadataDocRef.get();
        unitData = unitDoc.data();
      }
      volumeId = unitData.volumeId;
      unit.data = { ...unit.data, ...unitData };
      // this is a !@#$ mess!
      // scriptEditorModel.unit = unit;
    }
    unit.id = episodeKey;
    volume.id = volumeId;
    unit.fetchedVolume = volume;

    const volumeDocRef = db
      .collection(CatalogCollections.VOLUME_METADATA)
      .doc(volumeId);

    // episodeData.locale = locale || this.unit.defaultLocale;
    if (!locale) {
      throw Error(`locale required`);
    }
    episodeData.locale = locale;

    // this.loader.loadEpisode(episodeKey, true);
    if (this.loader.docSet) {
      this.loader.closeEpisode();
    }
    // TODO rework this
    this.loader.key = episodeKey;
    this.loader.docSet = this.loader.factory(episodeKey, true);
    this.loader.docSet.addDocRef('volumeDoc', volumeDocRef);
    this.loader.docSet.load();

    conversationManager.loadEpisode(episodeKey, true);
    conversationManager.setUser(auth.appUser);
    if (jumpId0) {
      log.info(`jumpToId: ${jumpId0}`);
      // note, needed to increase this from 20 to 500
      // 100 wasn't enough. probably a better way to handle
      this.jumpToId(<string>jumpId0, 500);
    }
  }

  closeEpisode() {
    // this.model = null;
    this.episodeKey = '';
    // todo: also reset the scriptEditorModal with a blank ElementList
    this.loader.closeEpisode();
    conversationManager.close();
  }

  contentUpdated() {
    log.debug('appRoot.contentUpdated, status: ' + this.loader.getStatus());
    if (this.loader.getStatus() === 'COMPLETE') {
      log.debug('COMPLETE: ' + this.loader.key);
      const newEpisode = this.episodeKey !== this.loader.key;
      this.episodeKey = this.loader.key;
      runInAction(() => {
        const t1 = Date.now();
        unit.data = {
          ...unit.data,
          ...this.loader.docSet.docs.unitMetadataDoc,
        };
        conversationManager.setBaselineTimestamp(
          unit.data.defaultBaselineTimestamp
        );
        scriptEditorModel.setUnitTracking(!!unit.data.trackingEnabled);
        // @jason, any idea why i never see any logging for this
        log.info(`contentUpdated - new unitData: ${JSON.stringify(unit.data)}`);
        volume.data = { ...volume.data, ...this.loader.docSet.docs.volumeDoc };
        episodeData.episodeKey = this.episodeKey;
        linter.setUnit(unit);
        scriptEditorModel.unit = unit;
        this.loader.docSet.copyTo(episodeData);
        const content = episodeData.content;
        // TODO: rename to "conversation", or can we somehow add as a getter to the element interface?
        // const content = content0.joinWithIdMap<ConversationDTO>('thread', conversationManager.obj, { messages: [] });
        // JE: looks like this was bogus and not actually proving the conversation map
        // const conversationMap: ElementIdMap<ConversationDTO> = conversationManager.obj;
        // const content = content0.joinWithIdMap<ConversationDTO>('thread', conversationMap, {
        //   messages: [],
        // });

        const words = episodeData.words;
        const sentences = content.filterByKind(EKind.SENTENCE);
        const wordGroups = content.filterByKind(EKind.WORD_GROUP);
        log.debug('new compute content time: f' + (Date.now() - t1) + ' ' + t1);
        scriptEditorModel.editEnabled = unit.hasWriteAccess(auth.appUser);
        scriptEditorModel.standardAccessEnabled = unit.hasStandardAccess(
          auth.appUser
        );
        scriptEditorModel.writeAccessEnabled = unit.hasWriteAccess(
          auth.appUser
        );
        scriptEditorModel.translationsLookup = episodeData.translations;
        scriptEditorModel.setEpisodeKey(episodeData.episodeKey);
        scriptEditorModel.setElementList(content); // TODO would set state here in runInAction
        scriptEditorModel.setL1Locale(episodeData.locale);
        scriptEditorModel.setL2Locale(episodeData.contentLanguage);
        mutationActions.setEpisodeKey(this.episodeKey);
        if (newEpisode) {
          versions.lazyLoadVersions(this.episodeKey);
        }
        if (notNull(this.jumpId)) {
          this.jumpToId(<string>this.jumpId, 500);
          this.jumpId = null;
        }
      });
    }
  }
}
