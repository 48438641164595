import { reaction } from 'mobx';
import { scrollIfNotVisibleDomId } from '@masala-lib/editorial/ui/dom-scroll';
import { TouchUpEditorModal } from '../../models/touchup-editor-modal';
import { setTouchupEditorModal } from './use-editor-modal';

export const useScrollIfNotVisible = (modal: TouchUpEditorModal) => {
  setTouchupEditorModal(modal);

  const editorModal = modal;
  // setTouchupEditorModal(editorModal);
  const disposers: (() => void)[] = [];
  disposers.push(
    reaction(
      () => editorModal.currentElementId,
      () => {
        scrollIfNotVisibleDomId(
          editorModal.currentElementId,
          'nearest',
          'bodyId'
        );
        // ensure both selected element and potential candidate are visible
        const candidateId = editorModal.shouldBeVisibleCandidateId;
        if (candidateId) {
          scrollIfNotVisibleDomId(candidateId, 'nearest', 'bodyId');
        }
      }
    )
  );

  return {};
};
