import * as React from 'react';
import {
  UseFormReturn,
  Path,
  FieldErrors,
  get,
  ValidationValue,
  ValidationRule,
} from 'react-hook-form';
import {
  InputStyleWrapperCss,
  InputStyleWrapper as Wrapper,
} from './input-style-wrapper';
import cx from 'classnames';
import { useUniqueId } from '../../lib/hooks/use-unique-id';
import { ErrorMessage } from './error-message';

type BasicInputTypes =
  | 'text'
  | 'color'
  | 'date'
  | 'email'
  | 'tel'
  | 'url'
  | 'number';

export type InputProps<T> = {
  name: Path<T>;
  label: string;
  hint?: React.ReactNode;
  required?: boolean; // will figure more sophisticated validation later because TS sucks.
  validPattern?: ValidationRule<RegExp>;
  validateFunc?: any;
  formContext: UseFormReturn<T>;
};

export type BasicInputProps<T> = InputProps<T> & {
  type?: BasicInputTypes;
};

export function Input<T>({
  type = 'text',
  name,
  label,
  hint,
  formContext,
  required = false,
  validateFunc,
  validPattern,
  css,
  className,
  ...inputProps
}: BasicInputProps<T> & InputStyleWrapperCss) {
  const {
    register,
    formState: { errors },
  } = formContext;

  const id = useUniqueId();
  const validationRules = {
    pattern: validPattern,
    validate: validateFunc,
    required,
  };

  const error = get(errors, name, null);

  return (
    <Wrapper className={cx({ className, 'with-error': !!error })} css={css}>
      <label htmlFor={id}>{label}</label>
      <input
        type={type}
        id={id}
        {...register(name, validationRules)}
        {...inputProps}
      />
      <ErrorMessage name={name} error={error} />
      {hint ? <div className="hint">{hint}</div> : null}
    </Wrapper>
  );
}
