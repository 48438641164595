import React, { useEffect } from 'react';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';

export const TagsDumpScreen = () => {
  const manager = TagManager.getInstance();
  const tags = manager.list;
  return (
    <pre>
      {tags.map(
        tag => `__s("${tag.name}", "tags:${tag.data.tagType}:${tag.slug}");\n`
      )}
    </pre>
  );
};
