import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { useAuthor } from './use-author';
import { DataItem } from '../../components/data-display/data-item';
import { Card } from '../../components/data-display/card';
import { Avatar } from '../../components/avatar';
import ReactMarkdown from 'react-markdown';
import { Auth } from '@masala-lib/editorial/db/auth';

interface AuthorDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const AuthorDetailScreen = observer(
  ({ closeDetail, startEditing }: AuthorDetailsParams) => {
    const author = useAuthor();
    const auth = Auth.getInstance();

    if (!author) {
      return null;
    }

    return (
      <Page
        title={`${author.name} ${author.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.can('manage_activity_guides') ? (
                <button onClick={startEditing} className="primary-action">
                  Edit Author
                </button>
              ) : null}
              <DeleteActions model={author} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <Card>
              <DataItem label={'Avatar'}>
                <Avatar src={author.data.avatarUrl} size={'90px'} />
              </DataItem>
              <DataItem label="Name" value={author.data.name} />
              <DataItem label="Title" value={author.data.title} />
              <DataItem label="Bio">
                <ReactMarkdown>{author.data.bio}</ReactMarkdown>
              </DataItem>
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
