import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { formatShortcutText } from 'lib/services/keyboard-service/format-shortcut-text';

const ButtonShortcut = styled('span', {
  display: 'inline-block',
  // marginLeft: 8,
  fontSize: 10,
  color: '$black-alpha-50',
  // background: '$colors$gray-10',
  // shadow: '0 0 0 1px $colors$black-alpha-06',
});

export const QuickButton = ({
  onClick,
  label,
  leftIcon,
  shortcut,
}: {
  onClick: () => void;
  label: React.ReactNode;
  leftIcon?: React.ReactNode;
  shortcut?: string;
}) => {
  return (
    <div>
      <Button
        label={label as any}
        leftIcon={leftIcon}
        // size={'extraSmall'}
        size={'small'}
        radius={'squared'}
        presentation={'whiteFloating'}
        type="button"
        onClick={onClick}
        rightIcon={
          shortcut ? (
            <ButtonShortcut>{formatShortcutText(shortcut)}</ButtonShortcut>
          ) : undefined
        }
        css={{
          textStyle: 'small-text',
          fontSize: 14,
        }}
      />
    </div>
  );
};

export const SlimButton = ({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) => {
  return (
    <div>
      <Button
        label={label}
        size={'extraSmall'}
        radius={'squared'}
        presentation={'whiteFloating'}
        type="button"
        onClick={onClick}
      />
    </div>
  );
};
