import { observer } from 'mobx-react';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { Link, useLocation } from 'react-router-dom';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { SoundbiteEditionsTable } from './soundbite-editions-table';
import { PageHeading } from '../shared/page-heading';
import { SortControls } from '../units/sort-controls';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
import { useEffect } from 'react';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { FilterControls } from '../shared/filters/filter-controls';
import { FiltersBar } from '../shared/filters-bar';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';

export const SoundbiteEditionListScreen = observer(() => {
  const location = useLocation();
  const {
    channel,
    sort = 'updatedAt',
    order = 'desc',
  }: {
    channel?: string;
    sort?: string;
    order?: string;
  } = getQueryParamsFromLocation(location);

  const auth = Auth.getInstance();
  const manager = SoundbiteEditionManager.getInstance();

  useEffect(() => {
    console.log(`useEffect - setChannelFilter: ${channel}`);
    manager.setFilter('channelId', channel, 'channel');
    manager.setSorting(sort, order);
  }, [manager, channel, sort, order]);

  const channelOptions = ChannelManager.getInstance().selectOptionsWithAll;

  const filterValueDecorator = (filterName: string, value: string) => {
    if (filterName === 'channelId') {
      return channelOptions.find(ch => ch.value === value)?.label;
    }
    return value;
  };

  const list = manager.filteredList;

  return (
    <Page
      title="Manage Soundbite Editions"
      controls={
        <PageActions>
          {auth.can('manage_excerpts') ? (
            <Link to={'/soundbite-editions/new'} className="primary-action">
              Create new Soundbite Edition
            </Link>
          ) : null}
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <PageHeading>
          <SortControls
            fields={[
              { fieldName: 'visibilityWeekPostEpoch', label: 'Week' },
              { fieldName: 'name', label: 'Name' },
              { fieldName: 'slug', label: 'Slug' },
              { fieldName: 'volumeName', label: 'Volume' },
              { fieldName: 'createdAt', label: 'Created at' },
              { fieldName: 'updatedAt', label: 'Updated at' },
            ]}
            currentSortingFieldName={manager.sorting.fieldName}
            currentSortingOrder={manager.sorting.order}
          />
          <FilterControls path="/soundbite-editions" filters={['channel']} />
          <FiltersBar
            filters={manager.filters}
            nameMap={{ channelId: 'Channel' }}
            valueDecorator={filterValueDecorator}
          />
        </PageHeading>
        <SoundbiteEditionsTable list={list} />
      </OnePaneLayout>
    </Page>
  );
});
