import { MDBModal, MDBModalBody } from 'mdbreact';
import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import ReactSelect from 'react-select';
import { scriptEditorModel, alertMessages } from '../models/app-root';
import styled from 'styled-components';
import { Button } from '../../console/components/button';
import StateManager from 'react-select';
import { EKind } from '@masala-lib/element-kinds';
import { Alert } from '@masala-lib/misc/alert-messages';

const Wrapper = styled.div`
  .tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .tag {
      display: flex;
      align-items: center;
      background: var(--color-gray700);
      color: var(--color-gray200);
      font-size: 14px;
      font-weight: 500;
      padding: 6px 10px;
      margin: 4px;
      border-radius: 4px;
      cursor: default;

      .label {
        margin-top: -3px;
      }

      .remove {
        display: flex;
        color: var(--color-gray200);
        background: var(--color-transparent);
        padding: 4px;
        margin-left: 4px;
        margin-right: -4px;
        border-radius: 4px;
        &:hover {
          color: var(--color-white);
          background: var(--color-gray800);
        }
      }
    }

    .plus-icon {
      border: none;
      display: flex;
      align-items: center;
      background: var(--color-gray100);
      color: var(--color-gray400);
      font-size: 14px;
      font-weight: 500;
      padding: 6px 10px;
      margin: 4px;
      border-radius: 4px;
      cursor: pointer;
      .label {
        margin-left: 4px;
      }
    }
  }
  .selector {
    margin-top: 16px;
    max-width: 400px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--color-gray100);

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
    }

    .label {
      display: block;
      font-size: 17px;
      line-height: 22px;
      font-weight: 400;
      color: var(--color-gray500);
      margin-bottom: 8px;
    }
  }
`;

class ReplaceSpeakerLabelsDialogState {
  @observable.ref open = false;
  oneChoice = false;
  constructor() {
    makeObservable(this);
  }
}

const dialogState = new ReplaceSpeakerLabelsDialogState();

export function openReplaceSpeakerLabelsDialog(oneChoice: boolean) {
  const focusedLineElement = scriptEditorModel.focusedLineElement;
  if (focusedLineElement?.kind !== EKind.PARAGRAPH) {
    alertMessages.add({
      ...Alert,
      text: 'must be on a speaker label to use speaker label replace',
    });
    return;
  }
  dialogState.open = true;
  dialogState.oneChoice = oneChoice;
  scriptEditorModel.setKbDeactivated(true);
}

export const ReplaceSpeakerLabelsDialog = observer(() => {
  const [selectedSpeaker, setSelectedSpeaker] = React.useState<string>('');

  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  // const focusedLineElement = scriptEditorModel.focusedLineElement;
  // const focusedSpeakerLabel =
  //   focusedLineElement?.kind === EKind.PARAGRAPH_HEAD ? focusedLineElement.content.text : null;
  const focusedSpeakerLabel = scriptEditorModel.speakerLabelForFocus;

  const ref = React.useRef<StateManager>();

  const handleSubmit = () => {
    scriptEditorModel.globallyReplaceSpeakerLabel(
      focusedSpeakerLabel,
      selectedSpeaker
    );
    toggleModal();
  };

  const handleSpeakerSelection = (selectedOption: any) => {
    setSelectedSpeaker(selectedOption?.value ?? '');
  };

  const validSpeakerOptions = scriptEditorModel.unit.speakerLabelStrings.map(
    l => {
      return { value: l, label: l };
    }
  );

  return (
    <MDBModal size="sm" centered isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
        {focusedSpeakerLabel ? (
          <Wrapper>
            <div className="selector">
              <div className="label">
                Choose replacement speaker for {focusedSpeakerLabel}
              </div>
              <ReactSelect
                options={validSpeakerOptions}
                ref={ref}
                placeholder={'Select speaker'}
                value={validSpeakerOptions.find(
                  option => option.value === selectedSpeaker
                )}
                onChange={handleSpeakerSelection}
                isClearable
              />
              <div className="buttons">
                <Button
                  onClick={handleSubmit}
                  disabled={!selectedSpeaker}
                  label={'Replace'}
                />
              </div>
            </div>
          </Wrapper>
        ) : (
          'No speaker label selected'
        )}
      </MDBModalBody>
    </MDBModal>
  );
});
