// no-ops needed to satisfy the ExternalLink behavior of the Markdown shared component
import { createLogger } from './logger';
import { LocaleCode } from '@utils/util-types';

const log = createLogger('app-util');

export const embeddedMode = () => false;

export const isEmbeddedPreV8 = () => false;

export const postNativeMessage = ({
  type,
  payload,
}: {
  type: string;
  payload: any;
}) => {
  log.info(`postNativeMessage: ${type}, ${payload}`);
};

// needed to satisfy utils/format-date app specific dependency
export const currentLocale = () => undefined as LocaleCode;
