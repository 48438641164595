import { TouchUpEditorModal } from '../../models/touchup-editor-modal';

// @jason, the singleton needs to live in a a separate file so it doesn't
// break hot realoading when the main view file is touched
let theEditorModal: TouchUpEditorModal = null;

export function setTouchupEditorModal(editor: TouchUpEditorModal) {
  theEditorModal = editor;
}

export function useTouchupEditorModal() {
  return theEditorModal;
}
