import { observable, when } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useEffect, useRef } from 'react';

export interface Modal {
  open: boolean;
}

export type ModalComponent = React.ForwardRefExoticComponent<
  {
    modal: Modal;
  } & React.RefAttributes<unknown>
>;

export type ModalDef = {
  component: ModalComponent;
  modal: Modal;
};

const modalState = observable({ current: null as ModalDef }, null, {
  deep: false,
});

export function setModal(modal: ModalDef) {
  modalState.current = modal;
}

const isClickInsideRectangle = (e: MouseEvent, element: HTMLElement) => {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
};

export const RenderModal = observer(() => {
  const currentModalRecord = modalState.current;
  const modal = currentModalRecord?.modal;
  const isOpened = modal?.open;
  const ref = useRef<HTMLDialogElement>(null);
  useEffect(() => {
    if (ref.current) {
      const current = ref.current;
      current.onclick = e => {
        if (ref.current && !isClickInsideRectangle(e, ref.current)) {
          modal.open = false;
        }
      };
      current.onclose = () => {
        modal.open = false;
      };
    }
    if (isOpened) {
      ref.current?.showModal();
      document.body.classList.add('modal-open'); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove('modal-open');
      setModal(null);
    }
  }, [isOpened, modal]);

  if (!currentModalRecord) {
    return <></>;
  }
  const Component = currentModalRecord.component;
  return <Component modal={modal} ref={ref} />;
});

export async function openModal(component: ModalComponent, modal: Modal) {
  modal.open = true;
  setModal({ modal, component });
  await when(() => !modal.open);
  setModal(null);
}
