import * as React from 'react';
import * as Sentry from '@sentry/react';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { EmptyErrorBoundary } from 'empty-error-boundary';

// TODO
const errorReportingEnabled = deploymentConfig.errorReportingEnabled;

export const BugReportingBoundary = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    if (errorReportingEnabled) {
      /// we will use Sentry's ErrorBoundary if errorReportingEnabled is true
      return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>;
    } else {
      return <EmptyErrorBoundary>{children}</EmptyErrorBoundary>;
    }
  }
);
