import React from 'react';
import { observer } from 'mobx-react';
import { SimpleStatusBar } from './simple-status-bar';
import { scriptEditorModel } from '../models/app-root';

export const StatusBar = observer(() => {
  const model = scriptEditorModel;
  const message = `${model.unit?.slug}/${model.currentChapterTitle}`;
  // const warningCount = model.lintWarnings?.elements?.length;
  // const message = warningCount
  //   ? `${model.unit?.slug} / ${warningCount} active warnings(s)`
  //   : elementText;

  return <SimpleStatusBar message={message} />;
});
