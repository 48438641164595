import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthorManager } from '@masala-lib/catalog/db/author-manager';
import { Author } from '@masala-lib/catalog/models/author';

export function useAuthor() {
  const { id = null } = useParams<{ id: string }>();
  console.log('ID', id);
  const manager = AuthorManager.getInstance();
  const [currentAuthor, setCurrentAuthor] = React.useState<Author>(null);
  useEffect(() => {
    setCurrentAuthor(manager.fetchById(id));
  }, [manager, id]);

  return currentAuthor;
}
