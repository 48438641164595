import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { PlayerCaliDataBuilder } from '@masala-lib/catalog/cali/player-cali-data-builder';

const loadData = async (unitId: string) => {
  let unit = await UnitManager.getInstance().loadById(unitId);
  if (!unit) {
    return [`Failed to load unit[${unitId}]`];
  }

  await unit.ensureParents();
  const volume = unit.volume;
  await volume.fetchUnits();
  unit = volume.fetchedUnits.find(u => u.id === unitId);
  const unitDataBuilder = await PlayerCaliDataBuilder.create(unit);

  const output: string[] = unitDataBuilder.buildStructuralExampleText();
  return output;
};

export const StructuralExampleScreen = () => {
  const { unitId } = useParams<{ unitId: string }>();

  const [data, setData] = useState<string>('loading...');

  useEffect(() => {
    loadData(unitId)
      .then(data => {
        setData(data.join('\n'));
      })
      .catch(err => {
        setData(String(err));
      });
  }, [unitId]);

  return <pre>{data}</pre>;
};
