import { styled } from '@naan/stitches.config';
import React from 'react';

const BubbleWrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  whiteSpace: 'pre-wrap',
  width: '100%',
  height: 'auto',
  borderRadius: 16,
  borderTopLeftRadius: 0,
  padding: 16,
  position: 'relative',
  backgroundColor: '$$backgroundColor',
  // border: '1px solid rgba(0,0,0,0.025)',
  boxShadow: '0 0 0 1px rgba(0,0,0,0.05), 0 6px 12px  rgba(0,0,0,0.015)',
  // textStyle: 'body',
  // fontSize: 14,
  // lineHeight: '21px ',
  '& .bubble-content': {
    color: '$colors$black-alpha-70',
    mixBlendMode: 'multiply',
  },
  variants: {
    truncate: {
      true: {
        $$truncateLines: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '$$truncateLines',
        WebkitBoxOrient: 'vertical',
        // paddingBottom: 0,
        // -webkit-mask-image: -webkit-gradient(linear, left 90%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))
        '& .bubble-content': {
          $$fadeStart: '50%',
          // mask with gradient to fade out bottom of text
          maskImage:
            'linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) $$fadeStart)',
        },

        '&:hover': {
          $$fadeStart: '10%',
        },
        // '&::after': {
        //   content: '""',
        //   position: 'absolute',
        //   height: 84,
        //   bottom: 0,
        //   right: 0,
        //   left: 0,
        //   background:
        //     'linear-gradient(to top, rgba(255,255,255,  100), rgba(255,255,255,0))',
        //   mixBlendMode: 'lighten',
        //   opacity: 0.75,
        // },
      },
    },
    suppressed: {
      true: {
        $$truncateLines: 2,
        textDecoration: 'line-through',
        color: 'LightGray',
      },
    },
    presentation: {
      script: {
        $$backgroundColor: '$colors$yellow-50',
        '&:hover': {
          $$backgroundColor: '$colors$yellow-100',
        },
      },
      request: {
        $$backgroundColor: '$colors$green-50',
        '&:hover': {
          $$backgroundColor: '$colors$green-100',
        },
      },
      response: {
        // $$truncateLines: 10,
        $$backgroundColor: '$colors$blue-50',
        '&:hover': {
          $$backgroundColor: '$colors$blue-100',
        },
      },
    },
  },
});

type BubbleWrapperProps = React.ComponentProps<typeof BubbleWrapper>;

export const Bubble = React.forwardRef<
  HTMLDivElement,
  BubbleWrapperProps & {
    truncateLines?: number;
  }
>(({ children, ...props }, ref) => {
  return (
    <BubbleWrapper
      ref={ref}
      {...props}
      css={{
        $$truncateLines: props.truncateLines ?? 3,
      }}
    >
      <div className="bubble-content">{children}</div>
    </BubbleWrapper>
  );
});

// //
// // a single requeset/response pair as shown within main body panel
// //
// export const Bubble = observer(({ content }: { content: React.node }) => {
//   return <pre className="bubble">{content}</pre>;
// });
