import * as React from 'react';
import { TagData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Textarea } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { tagTypeOptions } from '@masala-lib/catalog/models/tag';

interface Props {
  dto: TagData;
  onSubmit: (dto: TagData) => void;
  onCancel: () => void;
}

export const TagForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'id',
    'name',
    'slug',
    'tagType',
    'widgetHeading',
  ]);
  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);

  React.useEffect(() => {
    return () => {
      //otherwise we stay in form mode, even when going to another item
      onCancel();
    };
  }, [onCancel]);

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          {...getFieldProps('tagType')}
          options={tagTypeOptions as any}
          required
        />
        <Input {...getFieldProps('name')} required />
        <Input
          {...getFieldProps('slug')}
          hint={
            'if left blank, a slug will be generated automatically from the name'
          }
        />
        <Input
          {...getFieldProps('widgetHeading')}
          hint={
            'optional custom heading of dashboard promotion widget; if blank then "Stories about <lowercase tag name>" used'
          }
        />
        <FormButtons
          submitLabel={isNew ? 'Create tag' : 'Save changes'}
          onCancel={onCancel}
        />
      </form>
    </OnePaneLayout>
  );
};
