import { isEmpty, orderBy, sortBy } from 'lodash';
import { computed } from 'mobx';
import {
  CatalogEntityKind,
  CatalogStage,
  ChannelData,
  ExcerptStatus,
  nodeEndpointUrls,
  SoundbiteEditionCatalogData,
  spaBaseUrls,
  WorkflowStatus,
} from '../catalog-types';
import { Volume } from './volume';
import {
  blankOption,
  CatalogEntity,
  EntityManager,
} from '../db/catalog-entity-manager';
import { BaseCatalogEntity, NEW_MODEL_PATH_ID } from './base-catalog-entity';
import { Unit } from './unit';
import { deploymentConfig } from '../../deployment-config';
import { FeaturedReleaseManager } from '../db/featured-release-manager';
import { ChannelManager } from '../db/channel-manager';
import { VolumeManager } from '../db/volume-manager';
import { notEmpty } from '@utils/conditionals';
import { CatalogCaliData, ChannelCaliData } from '../cali/cali-catalog-types';
import { createLogger } from '@app/logger';
import { agePretty } from '@masala-lib/utils';
import { notEmptyOrNA, parseCsv } from '@utils/string-utils';
import { ExcerptCatalogDataBuilder } from '../cali/excerpt-catalog-data-builder';
import { FilterWidgetData } from '@tikka/client/catalog-types';
import { Tag } from './tag';
import { TagManager } from '../db/tag-manager';
import { Excerpt } from './excerpt';
import { VideoGuideManager } from '../db/video-guide-manager';
import { LocaleCode } from '@utils/util-types';
import { SoundbiteEdition } from './soundbite-edition';
import { SoundbiteEditionManager } from '../db/soundbite-edition-manager';
import { VolumeCrud } from '../db/volume-crud';
import { SoundbiteEditionCrud } from '../db/soundbite-edition-crud';
import { ChannelCrud } from '../db/channel-crud';
import { EntityCrud } from '../db/entity-crud';
import { ExcerptManager } from '../db/excerpt-manager';

const log = createLogger('channel');

export const emptyChannelData: ChannelData = {
  kind: CatalogEntityKind.CHANNEL,
  id: '',
  name: '',
  slug: '',
  clientChannelSlug: '',
  title: '',
  tagline: '',
  description: '',
  weblink: '',
  themeColor: '',
};

export const BASE_CALI_PATH = 'cali';
// export const DEFAULT_STORAGE_SILO = 'dev';

// // repurposing the bolero mode rails server support
export const CALI_CATALOG_MODE = 'v5';
// export const BOGOTA_CATALOG_MODE = 'v3';

export class Channel
  extends BaseCatalogEntity<ChannelData>
  implements CatalogEntity<ChannelData>
{
  fetchedVolumes: Volume[]; // cache of on-demand crud fetched data

  constructor(d?: { id: string }) {
    super(d);
    this.data = { ...emptyChannelData, ...d };
  }

  get navPath(): string {
    return `/channels/${this.id}`;
  }

  get parentNavPath(): string {
    return '/channels';
  }

  get volumesNavPath(): string {
    return `/volumes?channel=${this.id}`;
  }

  get unitsNavPath(): string {
    return `/units?channel=${this.id}`;
  }

  get excerptsNavPath(): string {
    return `/excerpts?channel=${this.id}`;
  }

  get newVolumeNavPath(): string {
    return `/volumes/${this.id}/${NEW_MODEL_PATH_ID}`;
  }

  fromFormData(d: ChannelData) {
    console.log(`Channel.fromFormData: ${JSON.stringify(d)}`);
    // if (isEmpty(d.storageSilo.trim())) {
    //   console.log(`defaulting storageSilo to 'dev'`);
    //   d.storageSilo = DEFAULT_STORAGE_SILO;
    // }
    super.fromFormData(d);
  }

  // sanitizedSlug(d: ChannelData): string {
  //   let candidate = isEmpty(d.slug) ? d.name : d.slug;
  //   candidate = slugify(candidate);
  //   // todo check uniqueness
  //   return candidate;
  // }

  // get baseStoragePath(): string {
  //   let result = this.data.storageSilo?.trim();
  //   if (isEmpty(result)) {
  //     result = DEFAULT_STORAGE_SILO;
  //   }
  //   return result;
  // }

  get baseMediaStoragePath(): string {
    return deploymentConfig.isDevtest
      ? `${BASE_CALI_PATH}/dev-media` // share media path for dev/testing for better caching of audio assets during ingestion
      : this.baseDataStoragePath;
  }

  get baseDataStoragePath(): string {
    return `${BASE_CALI_PATH}/${this.slug}`;
  }

  catalogStoragePath(stage: CatalogStage, version: number): string {
    const catalogSlug = this.catalogSlugForStage(stage);
    return `${this.baseDataStoragePath}/catalog/catalog-${this.l1}-${catalogSlug}-${version}.json`;
  }

  get l1(): LocaleCode {
    return this.data.l1;
  }

  async destroy(): Promise<void> {
    console.log(`Channel[${this.id}].destroy`);
    await this.destroyVolumes();
    await ChannelManager.getInstance().delete(this);
  }

  async destroyVolumes(): Promise<void> {
    const ids = this.volumes.map(volume => volume.id);
    for (let i = 0; i < ids.length; i++) {
      const volume = VolumeManager.getInstance().getById(ids[i]);
      await volume.destroy();
    }
  }

  async archiveChildren(): Promise<void> {
    const ids = this.volumes.map(volume => volume.id);
    for (let i = 0; i < ids.length; i++) {
      const volume = VolumeManager.getInstance().getById(ids[i]);
      await volume.archive();
    }
  }

  get crud(): EntityCrud<Channel, ChannelData> {
    return ChannelCrud;
  }

  theManager(): EntityManager<CatalogEntity<ChannelData>, ChannelData> {
    return ChannelManager.getInstance() as unknown as EntityManager<
      CatalogEntity<ChannelData>,
      ChannelData
    >;
  }
  get channelId(): string {
    return this.id;
  }

  @computed
  get volumes(): Volume[] {
    if (this.fetchedVolumes) {
      return this.fetchedVolumes;
    }
    if (isEmpty(this.data.mergeChannelSlugs)) {
      return VolumeManager.getInstance().byChannel(this.id);
    } else {
      return this.mergedVolumes;
    }
  }

  async fetchVolumes(): Promise<Volume[]> {
    if (!this.fetchedVolumes) {
      if (isEmpty(this.data.mergeChannelSlugs)) {
        this.fetchedVolumes = await VolumeCrud.loadAllForChannel(this);
      } else {
        await VolumeManager.getInstance().ensureLoaded();
        await ChannelManager.getInstance().ensureLoaded();
        this.fetchedVolumes = this.mergedVolumes;
      }
    }
    return this.fetchedVolumes;
  }

  get mergedVolumes(): Volume[] {
    const result: Volume[] = [];
    for (const subChannel of this.mergeChannels) {
      // todo: better cycle check
      if (subChannel.slug === this.slug) {
        throw Error(`mergeChannel cycle detected`);
      }
      result.push(...subChannel.volumes);
    }
    return result;
  }

  get excerpts(): Excerpt[] {
    const result: Excerpt[] = [];
    for (const volume of this.volumes) {
      result.push(...volume.excerpts);
    }
    return result;
  }

  async fetchExcerpts(): Promise<Excerpt[]> {
    const result: Excerpt[] = [];
    const volumes = await this.fetchVolumes();
    for (const volume of volumes) {
      const volumeExcerpts = await volume.fetchExcerpts();
      result.push(...volumeExcerpts);
    }
    return result;
  }

  // get soundbiteEditions(): SoundbiteEdition[] {
  //   const result: SoundbiteEdition[] =
  //     SoundbiteEditionManager.getInstance().byChannel(this.id);
  //   return result;
  // }

  async buildSoundbiteEditionsCatalogData(): Promise<
    SoundbiteEditionCatalogData[]
  > {
    // const items: SoundbiteEdition[] = SoundbiteEditionManager.getInstance().byChannel(this.id);
    const items: SoundbiteEdition[] =
      await SoundbiteEditionCrud.loadAllForChannel(this);
    const result: SoundbiteEditionCatalogData[] = [];
    for (const item of items) {
      result.push(await item.buildCatalogData());
    }
    return result;
  }

  // slugs for all soundbites assigned to a soundbite edition
  async editionedSoundbiteSlugs(): Promise<string[]> {
    const items: SoundbiteEdition[] =
      await SoundbiteEditionCrud.loadAllForChannel(this);
    const result: string[] = [];
    for (const item of items) {
      const slugs = await item.soundbiteSlugs();
      result.push(...slugs);
    }
    return result;
  }

  // one-time migration away from soundbite calendar view
  async markAllEditionedSoundbitesAsPublished(): Promise<void> {
    const excerptManager = ExcerptManager.getInstance();
    const slugs = await this.editionedSoundbiteSlugs();
    let count = 0;
    for (const slug of slugs) {
      const excerpt = await excerptManager.loadSoloBySlug(slug);
      if (excerpt.data.status !== ExcerptStatus.PUBLISHED) {
        log.info(`marking excerpt published: ${slug}`);
        await excerpt.updatePartial({ status: WorkflowStatus.PUBLISHED });
        count++;
      } else {
        log.info(`excerpt already published: ${slug}`);
      }
    }
    window.alert(`marked ${count} excerpts as published`);
  }

  get mergeChannels(): Channel[] {
    const slugs = parseCsv(this.data.mergeChannelSlugs);
    const result: Channel[] = [];
    for (const slug of slugs) {
      if (!isEmpty(slug)) {
        const channel = ChannelManager.getInstance().getBySlug(slug);
        if (!channel) {
          console.log(`mergeChannels - channel not found for slug: ${slug}`);
        } else {
          result.push(channel);
        }
      }
    }
    return result;
  }

  @computed
  get units(): Unit[] {
    const result: Unit[] = [];
    this.volumes.forEach(volume => result.push(...volume.units));
    return result;
  }

  visibleUnits(status: WorkflowStatus): Unit[] {
    const list = this.units.filter(unit => unit.visible(status));
    // sorting is for the convenience of manual perusing the catalog
    const sorted = orderBy(
      list,
      ['data.releaseDate', 'normalizedName'],
      ['desc', 'asc']
    );
    return sorted;
  }

  visibleVolumes(status: WorkflowStatus): Volume[] {
    return this.volumes.filter(volume => volume.visible(status));
  }

  // @computed
  get volumeCount(): number {
    return this.volumes?.length || 0;
  }

  @computed
  get unitCount(): number {
    return this.units.length;
  }

  @computed
  get excerptCount(): number {
    return this.excerpts.length;
  }

  async incrementCatalogVersion(): Promise<number> {
    const nextVersion = (this.data.catalogVersion || 0) + 1;
    this.updatePartial({ catalogVersion: nextVersion });
    return nextVersion;
  }

  get reviewPublishAgePretty(): string {
    return agePretty(this.data.reviewCaliVersion?.generatedAt);
  }

  get stagedPublishAgePretty(): string {
    return agePretty(this.data.stagedCaliVersion?.generatedAt);
  }

  get livePublishAgePretty(): string {
    return agePretty(this.data.liveCaliVersion?.generatedAt);
  }

  async publishCaliReviewCatalog(): Promise<void> {
    await this.publishCaliCatalogForStage(CatalogStage.REVIEW);
  }

  async invokePublishCaliStagedCatalog(): Promise<void> {
    await this.publishCaliCatalogForStage(CatalogStage.STAGED);
  }

  async publishCaliCatalogForStage(stage: CatalogStage): Promise<void> {
    if (isEmpty(this.data.catalogSlug))
      throw Error('catalog slug not assigned');
    await this.setPendingOperation('publishing cali catalog...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/publishCaliCatalog?channelId=${this.id}&stage=${stage}`;
    log.info(`publish - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    const data = await response.json();
    log.info(`response: ${JSON.stringify(data)}`);
    await this.clearPendingOperation();
    if (response.status !== 200) {
      throw Error(`publish failed: ${JSON.stringify(data)}`);
    }
    return data.dataUrl;
  }

  // by default only ingest volume not already ingested within last 24 hours
  async ingestAllVolumes(ageMin: number = 1440): Promise<void> {
    await this.setPendingOperation('cali reingest all volumes...');
    const apiUrl = `${deploymentConfig.masalaServerUrl}/ingestAllVolumes?channelId=${this.id}&ageMin=${ageMin}`;
    log.info(`publish - apiUrl: ${apiUrl}`);
    let response = await fetch(apiUrl);
    const data = await response.json();
    log.info(`response: ${JSON.stringify(data)}`);
    await this.clearPendingOperation();
    if (response.status !== 200) {
      throw Error(`publish failed: ${JSON.stringify(data)}`);
    }
    return data.dataUrl;
  }

  async ingestVolumesWithDirtyVocabIds(ageMin: number = 0): Promise<number> {
    await this.setPendingOperation('cali reingest volumes with dirty ids...');
    let count = 0;
    for (const volume of this.volumes) {
      if (!volume.canIngest) {
        continue;
      }
      const dirty = await volume.hasDirtyVocabIds();
      if (dirty) {
        log.warn(`${volume.slug} - found dirty vocab ids`);
        count++;
        await volume.invokeCaliIngest({ autoPublish: false });
      }
    }
    await this.clearPendingOperation();
    return count;
  }

  async clearPendingOperationForAll(): Promise<void> {
    for (const volume of this.volumes) {
      await volume.clearPendingOperation();
    }
  }

  async caliPromoteAllVolumes(): Promise<void> {
    await this.setPendingOperation('promoting all volumes...');
    for (const volume of this.volumes) {
      await volume.promoteCaliReviewToStaged({ autoPublish: false });
    }
    await this.clearPendingOperation();
  }

  // async ensureBogotaVocabMigrationMapAllVolumes(): Promise<void> {
  //   await this.setPendingOperation(
  //     'ensure bogota vocab migration map - all volumes...'
  //   );
  //   for (const unit of this.units) {
  //     if (unit.needsBogotaVocabMigrationMap) {
  //       await this.setPendingOperation(
  //         `ensure bogota vocab migration map - ${unit.name}`
  //       );
  //       await unit.generateBogotaVocabMigrationMap();
  //     }
  //   }
  //   await this.clearPendingOperation();
  // }

  // todo: move this to a data builder
  async buildCaliCatalogData(
    stage: CatalogStage,
    version: number,
    catalogUrl: string
  ): Promise<CatalogCaliData> {
    await this.fetchVolumes();
    await this.fetchExcerpts();
    // needed to resolve the FilterWidgetData
    await TagManager.getInstance().ensureLoaded();
    // await SoundbiteEditionManager.getInstance().ensureLoaded();

    const mode = CALI_CATALOG_MODE;
    // const l1 = this.data.l1;
    // const l2 = this.data.l2;
    const slug = this.catalogSlugForStage(stage);

    const { l1, l2, featuredCollectionSlug } = this.data;
    const featuredFilterSets = this.featuredFilterSetsData;
    const featuredFilters = this.featuredFiltersData; // deprecated

    // include in-progress or above for 'review', only 'published for staged
    const status =
      stage === CatalogStage.REVIEW
        ? WorkflowStatus.IN_PROGRESS
        : WorkflowStatus.PUBLISHED;

    // assumes all unit and volume data already loaded into entity managers
    // by ChannelCatalogPublisher
    const stories = this.visibleVolumes(status)
      .sort((a, b) => b.ingestedAt.localeCompare(a.ingestedAt)) // make manual inspection of catalog handier
      .map(volume => volume.ingestionVersionForStage(stage)?.catalogData)
      .filter(Boolean);

    const collections = (
      await new FeaturedReleaseManager().activeForChannel(this.id)
    ).map(_ => _.catalogV3Data);

    const videoGuides = (await new VideoGuideManager().active()).map(
      _ => _.catalogData
    );

    const soundbites = await ExcerptCatalogDataBuilder.buildList(this, stage);
    const soundbiteEditions = await this.buildSoundbiteEditionsCatalogData();

    // const channelDatas: ChannelCaliData[] = [];
    // if (this.mergeChannels.length > 0) {
    // }

    const channelDatas = this.mergeChannels.map(
      channel => channel.caliChannelData
    );

    const result: CatalogCaliData = {
      catalogUrl,
      version,
      generatedAt: new Date().toISOString(),
      slug,
      mode,
      l1,
      l2,
      channels: channelDatas,
      videoGuides,
      featuredFilterSets,
      // featuredFilters, // deprecated
      // featuredCollectionSlug, // deprecated
      soundbiteEditions,
      soundbites,
      collections,
      stories,
    };
    return result;
  }

  get caliChannelData(): ChannelCaliData {
    const {
      clientChannelSlug,
      title,
      tagline,
      description,
      weblink,
      themeColor,
    } = this.data;
    const result: ChannelCaliData = {
      slug: clientChannelSlug,
      title,
      tagline,
      description,
      weblink,
      themeColor,
    };
    return result;
  }

  get featuredTagSlugs(): string[] {
    return parseCsv(this.data.featuredTagSlugsCsv);
  }

  get featuredTags(): Tag[] {
    const tagManager = TagManager.getInstance();
    const result = this.featuredTagSlugs.map(slug =>
      tagManager.fetchById(slug)
    );
    return result;
  }

  // list of list of filters to cycle through on dashboard
  get featuredFilterSetsData(): FilterWidgetData[][] {
    const slugCsvs = this.data.featuredFilterSets.split(';');
    const result = slugCsvs.map(slugCsv =>
      this.featuredFiltersDataForSlugs(slugCsv)
    );
    return result;
  }

  // deprecated
  get featuredFiltersData(): FilterWidgetData[] {
    return this.featuredFiltersDataForSlugs(this.data.featuredTagSlugsCsv);
  }

  featuredFiltersDataForSlugs(slugCsv: string): FilterWidgetData[] {
    const tagManager = TagManager.getInstance();
    const slugs = parseCsv(slugCsv);
    const datas = slugs.map(slug => {
      const tag = tagManager.getBySlug(slug);
      if (tag) {
        return tag.filterWidgetData;
      } else {
        // should perhaps be hard error, or a way to capture and show warnings
        log.error(`filter data unmatched for slug: ${slug}`);
        return null;
      }
    });
    return datas.filter(_ => !!_); // filter out nulls
  }

  // cali
  catalogSlugForStage(stage: CatalogStage): string {
    const base = this.data.catalogSlug;
    switch (stage) {
      case CatalogStage.REVIEW:
        return `${base}-review`;
      case CatalogStage.STAGED:
        return base;
      // LIVE version never published, only promoted from STAGED
      // case CatalogStage.LIVE:
    }
    throw Error(`unexpected catalog stage: ${stage}`);
  }

  get reviewCatalogSlug(): string {
    return this.catalogSlugForStage(CatalogStage.REVIEW);
  }

  get reviewCatalogUrl(): string {
    return this.data.reviewCaliVersion?.dataUrl;
  }

  get hasAutoPublishEnv(): boolean {
    return notEmptyOrNA(this.data.autoPublishEnvCsv);
  }

  get hasLivePublishEnv(): boolean {
    return notEmptyOrNA(this.data.livePublishEnvCsv);
  }

  get autoPublishEnvs(): string[] {
    return parseCsv(this.data.autoPublishEnvCsv);
  }

  get firstAutoPublishEnv(): string {
    return this.autoPublishEnvs[0];
  }

  get livePublishEnvs(): string[] {
    return parseCsv(this.data.livePublishEnvCsv);
  }

  get firstLivePublishEnv(): string {
    return this.livePublishEnvs[0];
  }

  get stagedSpaBaseUrl(): string {
    return spaBaseUrls[this.firstAutoPublishEnv];
  }

  get liveSpaBaseUrl(): string {
    return spaBaseUrls[this.firstLivePublishEnv];
  }

  get reviewCatalogMagicLink(): string {
    const baseUrl = this.stagedSpaBaseUrl;
    if (!baseUrl) return null;
    return `${baseUrl}/admin/select-catalog/${this.reviewCatalogSlug}`;
  }

  get stagedCatalogMagicLink(): string {
    const baseUrl = this.stagedSpaBaseUrl;
    if (!baseUrl) return null;
    return `${baseUrl}/admin/select-catalog/${this.stagedCatalogSlug}`;
  }

  get liveCatalogMagicLink(): string {
    const baseUrl = this.liveSpaBaseUrl;
    if (!baseUrl) return null;
    return `${baseUrl}/admin/select-catalog/${this.liveCatalogSlug}`;
  }

  get stagedCatalogSlug(): string {
    return this.catalogSlugForStage(CatalogStage.STAGED);
  }

  get stagedCatalogUrl(): string {
    return this.data.stagedCaliVersion?.dataUrl;
  }

  // return list of slug, url tuples
  // (both review and staged)
  get catalogUrls(): [string, string][] {
    const result: [string, string][] = [];
    if (this.data.catalogSlug) {
      if (this.reviewCatalogUrl) {
        result.push([this.reviewCatalogSlug, this.reviewCatalogUrl]);
      }
      if (this.stagedCatalogUrl) {
        result.push([this.stagedCatalogSlug, this.stagedCatalogUrl]);
      }
    }
    return result;
  }

  // async buildV3CatalogData(): Promise<CatalogV3Data> {
  //   this.data.catalogVersion = (this.data.catalogVersion || 0) + 1;
  //   // await this.updatePartial({ catalogVersion: this.data.catalogVersion });
  //   const version = this.data.catalogVersion;
  //   const mode = 'v3';
  //   const l1 = 'en';
  //   const l2 = 'es';
  //   const slug = this.slug;

  //   const storagePath = `catalog/${mode}/${l1}/${l2}/${slug}/catalog-${version}.json`;
  //   const catalogUrl = publicUrlForPath(storagePath);
  //   // todo: make sure loaded
  //   const stories = this.units.map(unit => unit.data?.bogotaCatalogData);

  //   const result = {
  //     catalogUrl,
  //     storagePath, // for publisher convenience
  //     version,
  //     generatedAt: new Date().toISOString(),
  //     slug,
  //     mode,
  //     l1,
  //     l2,
  //     stories,
  //   };
  //   return result;
  // }

  // // bogota catalog
  // async buildCatalogData(status: WorkflowStatus): Promise<CatalogV4Data> {
  //   // TODO remove this direct assignment to data?
  //   this.data.catalogVersion = (this.data.catalogVersion || 0) + 1;
  //   // await this.updatePartial({ catalogVersion: this.data.catalogVersion });
  //   const version = this.data.catalogVersion;
  //   const mode = BOGOTA_CATALOG_MODE;
  //   const l1 = this.data.l1;
  //   const l2 = this.data.l2;
  //   const slug = this.catalogSlugForStatus(status);

  //   const storagePath = `catalog/${mode}/${l1}/${l2}/${slug}/catalog-${version}.json`;
  //   const catalogUrl = publicUrlForPath(storagePath);

  //   // assumes all unit and volume data already loaded into entity managers
  //   // by ChannelCatalogPublisher
  //   const stories = this.visibleUnits(status)
  //     .map(
  //       unit => unit.defaultL1VersionDataForStatus(status)?.bogotaCatalogData
  //     )
  //     .filter(Boolean);

  //   // hack back the previous lupa web support until the dust settles
  //   const volumeList = this.visibleVolumes(status)
  //     .map(volume => (volume.data as any).bogotaCatalogData)
  //     .filter(Boolean);

  //   const featuredReleases = (
  //     await new FeaturedReleaseManager().activeForChannel(this.id)
  //   ).map(_ => _.catalogV3Data);

  //   const result = {
  //     catalogUrl,
  //     storagePath, // for publisher convenience
  //     version,
  //     generatedAt: new Date().toISOString(),
  //     slug,
  //     mode,
  //     l1,
  //     l2,
  //     stories,
  //     volumeList,
  //     featuredReleases,
  //   };
  //   return result;
  // }

  // // bogota
  // catalogSlugForStatus(status: WorkflowStatus): string {
  //   const base = this.data.catalogSlug;
  //   switch (status) {
  //     case WorkflowStatus.PUBLISHED:
  //       return base;
  //     case WorkflowStatus.EXTERNAL_REVIEW:
  //       return `${base}-review`;
  //     case WorkflowStatus.IN_PROGRESS:
  //       return `${base}-in-progress`;
  //   }
  //   throw Error(`unexpected catalog status: ${status}`);
  // }

  // get isLupa(): boolean {
  //   return this.data.catalogMode === 'v3'; // todo: clean up lupa/bolero vs v3/v4
  // }

  // // bogota catalogs
  // async publishStagedCatalog(): Promise<void> {
  //   // return await this.publishCatalogFlavor('public');
  //   await this.publishCatalogForStatus(WorkflowStatus.PUBLISHED);
  // }

  // async publishReviewCatalogs(): Promise<void> {
  //   await this.publishCatalogForStatus(WorkflowStatus.IN_PROGRESS);
  //   await this.publishCatalogForStatus(WorkflowStatus.EXTERNAL_REVIEW);
  // }

  // // async publishInternalCatalog(): Promise<void> {
  // //   await this.publishCatalogForStatus(WorkflowStatus.IN_PROGRESS);
  // // }

  // // flavor: 'public', 'internal', or 'external'
  // async publishCatalogForStatus(status: WorkflowStatus): Promise<void> {
  //   this.setPendingOperation('publishing catalog...');
  //   await this.ensureL1VersionData();
  //   const apiUrl = `${deploymentConfig.masalaServerUrl}/publishCatalog?channelId=${this.id}&status=${status}`;
  //   console.log(`publish - apiUrl: ${apiUrl}`);
  //   let response = await fetch(apiUrl);
  //   const data = await response.json();
  //   console.log(`response: ${JSON.stringify(data)}`);
  //   this.clearPendingOperation();
  //   if (response.status !== 200) {
  //     throw Error(`publish failed: ${JSON.stringify(data)}`);
  //   }
  //   return data.dataUrl;
  // }

  get canMakeLive(): boolean {
    return (
      this.hasLivePublishEnv && notEmpty(this.data.stagedCaliVersion.dataUrl)
    );
  }

  get makeLivePending(): boolean {
    return (
      this.canMakeLive && this.data.liveCatalogUrl !== this.data.catalogUrl
    );
  }

  get liveCatalogSlug(): string {
    return this.data.catalogSlug; //`${this.data.catalogSlug}-cali`;
  }

  // todo: better factor this
  async caliAutoPublishReview(): Promise<void> {
    const url = this.data.reviewCaliVersion.dataUrl;
    const catalogSlug = this.reviewCatalogSlug;
    log.info(
      `caliAutoPublishReview - endpoints: ${this.data.autoPublishEnvCsv}, slug: ${catalogSlug}, url: ${url}`
    );
    for (const deployEnv of this.autoPublishEnvs) {
      await publishCatalogUrl(deployEnv, catalogSlug, url);
    }
    // await this.updatePartial({
    //   stagedCaliVersion: this.data.stagedCaliVersion,
    // });
  }

  async caliAutoPublishStaged(): Promise<void> {
    const url = this.data.stagedCaliVersion.dataUrl;
    const catalogSlug = this.stagedCatalogSlug;
    log.info(
      `caliAutoPublishStaged - endpoints: ${this.data.autoPublishEnvCsv}, slug: ${catalogSlug}, url: ${url}`
    );
    for (const deployEnv of this.autoPublishEnvs) {
      await publishCatalogUrl(deployEnv, catalogSlug, url);
    }
    // await this.updatePartial({
    //   stagedCaliVersion: this.data.stagedCaliVersion,
    // });
  }

  async caliMakeLive(): Promise<void> {
    const url = this.data.stagedCaliVersion.dataUrl;
    const catalogSlug = this.liveCatalogSlug;
    log.info(
      `caliMakeLive - endpoints: ${this.data.livePublishEnvCsv}, slug: ${catalogSlug}, url: ${url}`
    );
    for (const deployEnv of this.livePublishEnvs) {
      await publishCatalogUrl(deployEnv, catalogSlug, url);
    }
    await this.updatePartial({
      liveCaliVersion: this.data.stagedCaliVersion,
    });
    // todo: cali equivalent of "mark staged as live"
    // await this.markAllStagedAsLive();
  }

  // async makeLive(): Promise<void> {
  //   await deployToRails(
  //     this.data.liveRailsEndpointKey,
  //     this.data.liveCatalogSlug,
  //     BOGOTA_CATALOG_MODE,
  //     this.data.catalogUrl
  //   );
  //   await this.updatePartial({ liveCatalogUrl: this.data.catalogUrl });
  //   await this.markAllStagedAsLive();
  // }

  get volumeSelectOptions() {
    const volumeOptions = this.volumes.map(model => ({
      value: model.id,
      label: model.selectLabel,
    }));
    // TODO: property sort accents. i.e. Éxodo
    const sortedOptions = sortBy(volumeOptions, _ =>
      _.label?.toLocaleLowerCase()?.normalize()
    );
    return [blankOption, ...sortedOptions];
  }

  // async promoteStagedToLive() {
  //   await this.markAllStagedAsLive();
  //   // todo: poke live server
  // }

  // async markAllStagedAsLive() {
  //   for (const unit of this.units) {
  //     await unit.markStagedAsLive();
  //   }
  // }

  // async clearDefaultL1PendingPublishForStatus(status: WorkflowStatus) {
  //   for (const unit of this.units) {
  //     await unit.clearDefaultL1PendingPublishForStatus(status);
  //   }
  // }

  // async ensureL1VersionData() {
  //   for (const unit of this.units) {
  //     await unit.ensureL1VersionData();
  //   }
  // }
}

// export const deployToRails = async (
//   endpointKey: string,
//   slug: string,
//   mode: string,
//   url: string
// ): Promise<void> => {
//   const endpointUrl = railsEndpointUrls[endpointKey];
//   if (isEmpty(endpointUrl)) {
//     throw Error(`invalid rails endpoint key: ${endpointKey}`);
//   }
//   const apiUrl = `${railsEndpointUrls[endpointKey]}/api/v3/channels/${slug}/deploy?mode=${mode}&url=${url}`;
//   console.log(`invoking: ${apiUrl}`);
//   const response = await fetch(apiUrl, { method: 'post' });
//   const json = await response.json();
//   console.log(`deployToRails resp: ${JSON.stringify(json)}`);
//   if (!isEmpty(json.error)) {
//     throw Error(JSON.stringify(json));
//   }
// };

export const publishCatalogUrl = async (
  deployEnv: string,
  slug: string,
  url: string
): Promise<void> => {
  const endpointUrl = nodeEndpointUrls[deployEnv];
  if (isEmpty(endpointUrl)) {
    throw Error(`invalid rails endpoint key: ${deployEnv}`);
  }
  const apiUrl = `${endpointUrl}/catalogs/${slug}`;
  console.log(`invoking: ${apiUrl}`);
  const response = await fetch(apiUrl, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify({ url }),
  });
  const json = await response.json();
  console.log(`publishCatalogUrl resp: ${JSON.stringify(json)}`);
  if (!isEmpty(json.error)) {
    throw Error(JSON.stringify(json));
  }
};

// export const loadAllPublishingData = async () => {
//   await ChannelManager.getInstance().loadAll();
//   await VolumeManager.getInstance().loadAll();
//   await UnitManager.getInstance().loadAll();
//   // await TagManager.getInstance().loadAll();
//   // await ActivityGuideManager.getInstance().loadAll();
//   // await AuthorManager.getInstance().loadAll();
// };
