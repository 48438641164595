import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { QuickButton, SlimButton } from './quick-button';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { showLlmPickerDialog } from './llm-picker-dialog';
import { RequestLogo } from '../components/request-logo';
import { MinusExtraSmallIcon, MinusIcon } from '@naan/icons/minus-icon';
import { IconButton } from '@naan/primitives/button';
import { PlusExtraSmallIcon, PlusIcon } from '@naan/icons/plus-icon';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa6';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
});

const hoverSelector = '&:hover:not(.disabled)';
const activeSelector = '&:active:not(.disabled), &[data-state=open]';

const TemperatureControl = styled('div', {
  display: 'flex',
  alignItems: 'center',
  $$buttonTextColor: '$colors$gray-600',
  $$buttonBackgroundColor: '$colors$white',

  textStyle: 'small-text-bold',

  flexDirection: 'row',
  justifyContent: 'center',
  borderRadius: 6,
  cursor: 'pointer',
  transitionProperty: 'background',
  transitionDuration: '0.15s',
  color: '$$buttonTextColor',
  backgroundColor: '$$buttonBackgroundColor',
  whiteSpace: 'nowrap',
  maxWidth: '100%',

  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  border: '1px solid $colors$black-opacity-50',

  '& button': {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$$buttonTextColor',
    backgroundColor: '$$buttonBackgroundColor',
    padding: '10px 6px',
    borderRadius: 6,

    '& svg': {
      width: 12,
      height: 12,
    },

    [hoverSelector]: {
      $$buttonBackgroundColor: '$colors$gray-20',
      $$buttonTextColor: '$colors$gray-700',
    },
    [activeSelector]: {
      $$buttonBackgroundColor: '$colors$gray-50',
      $$buttonTextColor: '$colors$gray-600',
    },
  },

  '& span': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    background: '$colors$gray-50',
    padding: '3px 4px',
    borderRadius: 4,
  },

  // [hoverSelector]: {
  //   $$buttonBackgroundColor: '$colors$gray-20',
  //   $$buttonTextColor: '$colors$gray-700',
  // },
  // [activeSelector]: {
  //   $$buttonBackgroundColor: '$colors$gray-50',
  //   $$buttonTextColor: '$colors$gray-600',
  // },
  '&.disabled': {
    opacity: '0.2',
  },
});

export const SettingsBar = observer(() => {
  const model = useSamosaModel();

  const reduceTemperature = (): void => model.decreaseTemperature();
  const increaseTemperature = (): void => model.increaseTemperature();

  return (
    <Wrapper>
      <QuickButton
        leftIcon={
          <RequestLogo modelName={model.llmOptions?.model} size={'tiny'} />
        }
        label={model.llmOptions?.model}
        onClick={showLlmPickerDialog}
      />
      <TemperatureControl>
        <button onClick={reduceTemperature}>
          <MinusExtraSmallIcon />
        </button>
        <span>{model.llmOptions?.temperature ?? ''}</span>
        <button onClick={increaseTemperature}>
          <PlusExtraSmallIcon />
        </button>
      </TemperatureControl>
      <div className="streaming-controls">
        <QuickButton
          // label={model.llmOptions.streamingDisabled ? 'disabled' : 'enabled'}
          label="Streaming"
          leftIcon={
            model.llmOptions.streamingDisabled ? (
              <FaToggleOff />
            ) : (
              <FaToggleOn />
            )
          }
          onClick={() => model.toggleStreaming()}
        />
      </div>
    </Wrapper>
  );
});
