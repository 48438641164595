import * as React from 'react';
import {
  KeyboardService,
  slashHelpBinding,
} from 'lib/services/keyboard-service';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';

export function useTranslationKeyboardBindings(
  editor: TouchUpEditorModal,
  onResolve: (value: boolean) => void
) {
  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('editor', {
      down: [() => editor.moveToNextLine(), null], // 'Next line'],
      up: [() => editor.moveToPrevLine(), null], //'Previous line'],
      left: [() => editor.moveToPrevAlert(), 'Previous alert'],
      j: [() => editor.moveToPrevAlert(), null],
      right: [() => editor.moveToNextAlert(), 'Next alert'],
      k: [() => editor.moveToNextAlert(), null],

      w: [() => editor.toggleWinner(), 'Toggle winning translation'],
      enter: [() => editor.pickAndAdvance(), 'Pick and advance'],
      a: [() => editor.handleAddTranslation(), 'Add free-form translation'],
      'shift+enter': [
        () => editor.handleEditIntent(),
        'Edit / clone translation',
      ],
      'ctrl+d': [
        () => editor.toggleArchive(),
        'Archive (soft delete) translation',
      ],
      'shift+ctrl+d': [
        () => editor.toggleShowArchived(),
        'Show / hide archived',
      ],

      f: [
        () => editor.markPending(),
        'Flag (to be addressed before sending back to Script Editor)',
      ],
      g: [
        () => {
          editor.injectTranslateLinePrompt();
          onResolve(true);
        },
        'Generate translate line prompt',
      ],
      l: [
        () => editor.markDeferred(),
        'Defer (note will be sent back to Script Editor as comment)',
      ],
      r: [
        () => editor.markResolved(),
        'Resolve (clears flagged / deferred state)',
      ],
      s: [() => editor.saveToMasala(), 'Save to Masala'],
      n: [() => editor.editNote(), 'Add/edit note (automatically flags)'],
      'shift+n': [() => editor.toggleShowNotes(), 'Show / hide notes'],
      o: [() => editor.toggleShowLosers(), 'Show / hide unpicked'],
      space: [() => editor.handlePlayAction(), 'Play/stop playing'],
      b: [() => editor.handleRewindAction(), 'Rewind 5 seconds'],
      escape: [
        () => {
          onResolve(undefined);
        },
        null,
      ],
      'shift+slash, slash': [() => KeyboardService.instance.toggleHelp(), null],
    });

    // KeyboardService.instance.setCurrentShortcutSet('editor');
    // return () => {
    //   KeyboardService.instance.removeShortcutSet('editor');
    // };
  }, [editor, onResolve]);

  // for (const [key, value] of Object.entries(bindings)) {
  //   useKeyboardBinding(key, value);
  // }
}
