import React from 'react';
import { computed, observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import './word-group-inspector.css';

import { scriptEditorModel, wordGroupActions } from '../models/app-root';
import { wordIndexRangeToWordStrings } from '@masala-lib/content-utils';
import { ScrollingRows } from '@masala-lib/editorial/ui/scrolling-rows/scrolling-rows.jsx';
import { ResilientRowCursor } from '@masala-lib/editorial/ui/scrolling-rows/resilient-row-cursor.js';
import { WordGroupEditor } from '../ui/word-group-editor';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';

class WordGroupInspectorImpl extends React.Component {
  constructor(props) {
    super(props);
    this.disposers = [];
    this.cursor = null;
    this.keyDivEl = null;
    this.wordGroupEditorEl = null;
    this.lastKeyPress = 1;
    this.cursor = new ResilientRowCursor(row => row.id);
    makeObservable(this, {
      lastKeyPress: observable,
      allWordGroups: computed({ keepAlive: true }),
      currentWordGroupId: computed({ keepAlive: true }),
      currentWordGroup: computed({ keepAlive: true }),
    });
    this.cursor.setRows(this.allWordGroups);
    this.disposers.push(
      reaction(
        () => this.allWordGroups,
        filtered => this.cursor.setRows(filtered)
      )
    );
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  getKeyDiv() {
    return this.keyDivEl;
  }

  getCursor() {
    return this.cursor.getCursor();
  }

  get allWordGroups() {
    return scriptEditorModel.wordGroups.values;
  }

  get translations() {
    return scriptEditorModel.translationsLookup;
  }

  get locale() {
    return scriptEditorModel.l1locale;
  }

  get currentWordGroupId() {
    if (this.getCursor()) {
      return this.getCursor().key;
    }
    return null;
  }

  get currentWordGroup() {
    if (!this.currentWordGroupId) {
      return null;
    }
    return scriptEditorModel.wordGroups.getElement(this.currentWordGroupId);
  }

  get currentSentence() {
    const wordGroup = this.currentWordGroup;
    if (wordGroup) {
      return scriptEditorModel.sentences.getElementContainingAddress(
        wordGroup.address
      );
    } else {
      return null;
    }
  }

  sentenceIndex(wordGroup) {
    if (wordGroup) {
      const sentences = scriptEditorModel.sentences;
      const sentence = sentences.getElementContainingAddress(wordGroup.address);
      if (sentence) {
        return sentences.getIndex(sentence.id);
      }
    }
    return -1;
  }

  get currentSentenceIndex() {
    if (!this.currentWordGroup) {
      return -1;
    }
    return this.sentenceIndex(this.currentWordGroup);
  }

  getWordGroupTranscriptText(wordGroup) {
    const indexRange = { begin: wordGroup.address, end: wordGroup.endAddress };
    const text = wordIndexRangeToWordStrings(
      indexRange,
      scriptEditorModel.words
    ).join(' ');
    return stripTrailingPunctuation(text);
  }

  setKeyDiv(element) {
    this.keyDivEl = element;
    if (element) {
      element.tabindex = -1;
      element.addEventListener('keydown', this.handleKeyPress);
      if (!document.activeElement) {
        this.setFocus();
      }
    }
  }

  setFocus() {
    if (this.keyDivEl) {
      this.keyDivEl.focus();
      setTimeout(() => {
        this.keyDivEl.focus();
      }, 1000);
    }
  }

  deleteAction() {
    if (!this.currentWordGroupId) {
      return;
    }
    wordGroupActions.remove(this.currentWordGroupId);
  }

  // TODO use keyboardist instead?
  handleKeyPress(e) {
    const focused = document.activeElement;
    if (!focused || focused !== this.keyDivEl) {
      return;
    }
    this.lastKeyPress = e.code;
    if (e.code === 'KeyD' && e.ctrlKey) {
      e.preventDefault();
      this.deleteAction();
    }
  }

  kindSymbols = {
    VOCAB: '',
    TRICKY: '~',
    SIC: '$',
  };

  WordGroupRow = item => {
    const translation = this.translations[item.id];
    let note = translation?.content?.note ?? '???';
    const canonical = item.content?.canonical;
    if (canonical) {
      note = `${canonical} = ${note}`;
    }
    const delimiter = note.includes('=') ? '|' : '=';
    const kindIndicator = this.kindSymbols[item.subKind];
    return (
      <div>
        {'S-' + (this.sentenceIndex(item) + 1) + ':'} &nbsp;
        <span style={{}}>{kindIndicator}</span>
        {/*<span style={{}}>&lt;{typeIndicator}</span>*/}
        <span>{this.getWordGroupTranscriptText(item)} </span>{' '}
        {note ? <span> {delimiter + ' ' + note}</span> : ''}
        {/*<span style={{}}>&gt;</span>*/}
      </div>
    );
  };

  render() {
    return (
      <div className="wg-sheet">
        <div className="wg-sheet-right-sidepanel">
          {this.currentWordGroup ? (
            <WordGroupEditor
              element={this.currentWordGroup}
              content={scriptEditorModel.elements}
              translations={this.translations}
              local={this.locale}
              ref={element => (this.wordGroupEditorEl = element)}
            />
          ) : (
            ''
          )}
        </div>
        <div
          className="wg-sheet-left-sidepanel"
          tabIndex={-1}
          id="wg-sheet-sheet-left-sidepanel"
          ref={element => this.setKeyDiv(element)}
        >
          <div className="wg-sheet-left-bottom">
            <div className="wg-sheet-word-groups">
              <ScrollingRows
                getCursor={this.cursor.getCursor}
                setCursor={this.cursor.setCursor}
                rowComponent={this.WordGroupRow}
                allRows={this.allWordGroups}
                getRowKey={row => row.id}
                rowHeight={30}
                keyEventElement={() => this.getKeyDiv()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export const WordGroupInspector = observer(WordGroupInspectorImpl);
