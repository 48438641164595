import { observer } from 'mobx-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { OnePaneLayout } from '../shared/layouts';
import { Page } from '../shared/page';
import { SpeakersTable } from '../volumes/speakers-table';
import { useVolume } from '../volumes/use-volume';

export const VolumeSpeakersScreen = observer(() => {
  const volume = useVolume();
  const navigate = useNavigate();

  if (!volume) {
    return null;
  }

  const goBack = () => {
    navigate(volume.navPath);
  };

  const resetAllSpeakerData = async () => {
    if (window.confirm('Are you sure?')) {
      await volume.resetAllSpeakerData();
    }
  };

  return (
    <Page title={`Volume Speakers`} onClose={goBack}>
      <OnePaneLayout>
        <h4>{volume.name} speakers</h4>
        <p>
          <NavLink to={volume.navPath}>[Back to volume detail]</NavLink>
        </p>
        <SpeakersTable volume={volume} />
        <hr />
        <p>
          <ActionLink onPress={resetAllSpeakerData}>
            [reset all speaker data]
          </ActionLink>
        </p>
      </OnePaneLayout>
    </Page>
  );
});
