import { Button } from '@naan/primitives/button';

export const ActionButton = ({
  onClick,
  label,
}: {
  onClick: () => void;
  label: string;
}) => {
  return (
    <Button
      label={label}
      presentation={'grayLight'}
      fullWidth
      size="large"
      onClick={onClick}
    />
  );
};
