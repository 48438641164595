import * as React from 'react';
import { OnePaneLayout } from './one-pane';

// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import 'mdbreact/dist/css/mdb.css';

import styled from 'styled-components';

const Wrapper = styled.div`
  --color-rightpane: var(--color-gray20);
  --color-rightpane-border: var(--color-gray50);

  display: grid;
  grid-template-columns: 1fr 320px;
  grid-template-areas: 'main sidebar';
  grid-column-gap: 0;
  min-height: 100vh;
  & > .dual-sidebar {
    grid-area: sidebar;
    background: var(--color-rightpane);
    border-left: 1px solid var(--color-rightpane-border);
    /* margin-top: -28px;
    margin-right: -16px; */
    /* margin-left: 16px; */
  }

  & > .dual-main {
    grid-area: main;
    .inner {
      padding: 16px;
      padding-left: 24px;
    }
  }
`;

interface DualPaneLayoutProps {
  children: React.ReactNode;
  rightPanel?: React.ReactNode;
}

export const DualPaneLayout = ({
  children,
  rightPanel,
}: DualPaneLayoutProps) => {
  // useTitle(title);
  return (
    <Wrapper>
      <div className="dual-sidebar">{rightPanel}</div>
      <div className="dual-main">
        <OnePaneLayout>{children}</OnePaneLayout>
      </div>
    </Wrapper>
  );
};
