import React from 'react';
import { observer } from 'mobx-react';
import { signInWithGoogle } from '../../platform/firebase-auth';

export const LoginScreen = observer(() => {
  return (
    <div style={{ textAlign: 'center', padding: 12 }}>
      <span
        style={{
          textDecoration: 'underline',
          marginLeft: 6,
          marginRight: 6,
          fontSize: '1.5em',
        }}
        onClick={signInWithGoogle}
      >
        (Sign-in)
      </span>
    </div>
  );
});
