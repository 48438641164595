import { observer } from 'mobx-react';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { FilterSelector } from './filter-selector';

export const VolumeFilterSelector = observer(({ path }: { path: string }) => {
  const volumeOptions = VolumeManager.getInstance().selectOptionsWithAll;
  return (
    <FilterSelector
      path={path}
      fieldName="volume"
      options={volumeOptions}
      placeholder="Select a volume…"
      label={'Filter by volume'}
      shortcutKey={'keyV'}
    />
  );
});
