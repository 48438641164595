import * as React from 'react';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { stepTypeMap } from './step-type-map';
import { styled } from '@naan/stitches.config';
import { BsCaretRightFill } from 'react-icons/bs';
import { SectionInfo } from '@masala-lib/llm/project/llm-project-types';
import { SamosaModel } from 'samosa/models/samosa-model';
import { Button } from '@naan/primitives/button';
import { MergeIcon } from '../components/icons';

const SectionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  '& > .section': {
    // borderBottom: '2px solid $colors$black-alpha-06',
  },
});

const SectionTitleWrapper = styled('div', {
  position: 'sticky',
  top: 54,
  zIndex: 1,
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 8,

  textTransform: 'uppercase',
  color: '$black-alpha-50',
  backgroundColor: '$blue-100',
  // mixBlendMode: 'multiply',
  padding: '8px 12px',
  // borderBottom: '1px solid $colors$black-alpha-06',
  borderRadius: '0 12px 12px 0',
  margin: '0 8px 0 0',
  marginBottom: 4,
  cursor: 'default',

  '& .children': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },

  '& .icon': {
    opacity: 0,
    transition: 'opacity 0.2s',
    color: '$black-alpha-20',
  },

  '&:hover': {
    color: '$black-alpha-80',
    // backgroundColor: '$blue-200',
    '& .icon': {
      opacity: 1,
    },
  },
  // marginBottom: 8,
});

const SectionTitle: React.FC<
  React.ComponentProps<typeof SectionTitleWrapper> & { right?: React.ReactNode }
> = ({ children, right = <BsCaretRightFill />, ...props }) => {
  return (
    <SectionTitleWrapper {...props}>
      <div className="children">{children}</div>
      {right ? <span className="icon">{right}</span> : null}
    </SectionTitleWrapper>
  );
};

const StatusWrapper = styled('div', {
  display: 'flex',
  gap: 4,
  fontSize: 10,
  lineHeight: '14px',
  fontWeight: 500,
  textTransform: 'uppercase',
  // background: '$black-alpha-10',
  '& .value': {
    color: '$$valueColor',
  },

  // '& .pending': {
  //   $$valueColor: '$colors$red-300',
  // },
  // '& .flagged': {
  //   $$valueColor: '$colors$teal-500',
  // },
});

// const StepListStatus = observer(
//   ({ section, model }: { section: SectionInfo; model: SamosaModel }) => {
//     return (
//       <StatusWrapper className="status">
//         {/* mergeStatus */}
//         {model.mergeCanHaveConflicts ? (
//           <>
//             <div className="pending">
//               <span className="label">M</span>
//               <span className="value">
//                 {section.missingCount +
//                   section.conflictCount +
//                   section.completeButNotSignedOffCount}
//               </span>
//             </div>
//             <div className="flagged">
//               <span className="label">F</span>
//               <span className="value">{section.samosaFlaggedCount}</span>
//             </div>
//           </>
//         ) : null}
//       </StatusWrapper>
//     );
//   }
// );

export const StepListGrouped = observer(() => {
  const model = useSamosaModel();
  const sections = model.sectionsInfo.sections;
  const nonFittingSteps = model.sectionsInfo.nonFitting;
  console.log('sections', sections);

  // console.log('steps by section', model.stepsBySection);
  console.log('steps by section', model.sectionsInfo);

  return (
    <SectionsWrapper>
      {nonFittingSteps.length > 0 ? (
        <div className="section">
          <SectionTitle>Non-fitting steps</SectionTitle>
          {nonFittingSteps.map(step => {
            const ItemComponent = stepTypeMap[step.kind];
            return <ItemComponent key={step.id} step={step} />;
          })}
        </div>
      ) : null}
      {sections.map((section, index) => {
        // TODO: put index in section structure
        /// temporal, remove ugly dashes --- from section title
        const sectionTitle = section.title; //.replace(/---/g, '');
        // const mergeStatus = model.mergeCanHaveConflicts
        //   ? // ? ` (M:${section.missingCount}, C:${section.conflictCount}, F:${section.samosaFlaggedCount})`
        //     ` (P:${section.missingCount + section.conflictCount}, F:${
        //       section.samosaFlaggedCount
        //     })`
        //   : '';
        return (
          <div className="section">
            <SectionTitle
              right={
                <Button
                  onClick={() => model.touchupMergeScript(index + 1)}
                  label="Merge…"
                  size="micro"
                  presentation="blue"
                  leftIcon={<MergeIcon />}
                />
              }
            >
              {sectionTitle}
              {/* <StepListStatus section={section} model={model} /> */}
            </SectionTitle>
            {section.steps.map(step => {
              const ItemComponent = stepTypeMap[step.kind];
              return <ItemComponent key={step.id} step={step} />;
            })}
          </div>
        );
      })}
    </SectionsWrapper>
  );
});
