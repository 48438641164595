import { epochSecondsFloat } from '@masala-lib/utils';

export const JOBS_COLLECTION_NAME = 'Jobs';

// not sure where this best lives
export class InputsNotReady extends Error {
  retryAfter: number = null;
  constructor(message: string, retryDelay: number = null) {
    super(message);
    if (retryDelay) {
      this.retryAfter = epochSecondsFloat() + retryDelay;
    }
  }
}

export type JobId = string;
export type JobStatus = 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED' | 'WAITING';
export type StageStatus = JobStatus;
export type JobRequestParams = { [index in string]: any };
export type StagesDataMap = { [index: string]: StageData };
export type JobData = {
  shallowCopy?: boolean;
  kind: string;
  id: JobId;
  params: JobRequestParams;
  lastRunStage: string;
  lastRunSubStage: string;
  startTimestamp: number;
  exitTimestamp: number;
  status: JobStatus;
  stages: StagesDataMap;
  archived: boolean;
  errorMessage?: string; // capture unexpected error outside the scope of a stage
  errorStack?: string;
};

export type StageData = {
  stageName: string; // map index
  startTimestamp: number;
  exitTimestamp: number;
  status: StageStatus;
  logMessageMap: LogMessageMap;
  errorMessage: string;
  errorStack: string;
  startPollAsyncOutputsTimestamp: number;
  endPollAsyncOutputsTimestamp: number; // todo: confirm what this drives
  intervalPollAsyncOutputs: number;
  localTmpFileNames: string[];
  stageOutputData: any;
};

// interface to invoke a stage (not persisted)
export type StageParams = {
  jobData: JobData;
  stageData: StageData;
  stageOutputData: any;
  name: string;
  logger: JobLogger;
};

export type JobStatusResult = {
  status:
    | JobStatus
    | 'JOB_ALREADY_SUCCEEDED'
    | 'STAGE_ALREADY_RUNNING'
    | 'CANNOT_RERUN_JOB_WITHOUT_RETRY_OPTION';
  lastRunStage: string;
};

export type StageHandler = (p: StageParams) => Promise<void>;

export type StageDefinition = {
  name: string;
  index: number;
  handler: StageHandler;
};

export interface JobLogger {
  debug(...args: any[]): void;
  info(...args: any[]): void;
  warn(...args: any[]): void;
  error(...args: any[]): void;
}

export type LogMessage = {
  level: string;
  message: string;
  timestamp: number;
  counter: number;
  text?: string[]; // obsolete interface
};

export type LogMessageMap = { [index: number]: LogMessage };

export const LogLevels = ['DEBUG', 'INFO', 'WARN', 'ERROR'];
export type LogLevel = typeof LogLevels[number];
