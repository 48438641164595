import * as React from 'react';
import { observer } from 'mobx-react';
import { Exchange, Step } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { styled } from '@naan/stitches.config';
import { Bubble } from '../components/bubble';
import { RequestLogo } from '../components/request-logo';
import { SimpleDialog } from '../components/simple-dialog';
import { ExchangeDetailStatus } from './exchange-step';
import { hashCode } from '@utils/string-utils';
import { shouldTruncate } from '@masala-lib/llm/project/llm-project-funcs';
import { ResponseToolbar } from './response-toolbar';
import { EditableExchangeLabel } from './editable-exchange-label';
import { DialogPresenter } from '../dialog-presenter';
import { ToolbarBaseOuterContainer } from '../components/toolbar';

const ChatRowWrapper = styled(ToolbarBaseOuterContainer, {
  padding: '0 12px',
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  gridTemplateRows: '1fr',
  gap: 8,
});

//
// the response side of an exchange pair as shown within main body panel
//
export const ExchangeResponse = observer(({ step }: { step: Step }) => {
  const model = useSamosaModel();
  const exchange = step as Exchange;
  const { request } = exchange;
  const { llmOptions } = request;

  const suppressed = model.isExchangeSuppressed(exchange as Exchange);
  const current = model.isCurrentStep(exchange);
  const pending = exchange.pendingResponse;
  const truncateLines = current && !suppressed ? 25 : 3;
  const long = shouldTruncate(exchange.rawResponse.text, truncateLines);
  const truncate = (!pending && long) || suppressed;

  const onBubbleClick = () => {
    DialogPresenter.present(onDismiss => (
      <DetailDialog exchange={exchange} onDismiss={onDismiss} />
    ));
  };

  return (
    <>
      <ChatRowWrapper>
        <RequestLogo modelName={llmOptions?.model} />
        <div
          className="exchange-body"
          onClick={onBubbleClick}
          onMouseEnter={() => {
            // console.log('enter');
            model.setHoveredExchange(exchange);
          }}
          onMouseLeave={() => {
            // console.log('leave');
            model.setHoveredExchange(null);
          }}
        >
          <Bubble
            presentation={'response'}
            truncate={truncate}
            suppressed={suppressed}
            truncateLines={truncateLines}
          >
            {exchange.rawResponse.error ? (
              <p>ERROR: {exchange.rawResponse.error}</p>
            ) : null}
            {exchange.rawResponse.text}
          </Bubble>
        </div>
        <div className="floating-toolbar">
          <ResponseToolbar exchange={exchange} />
        </div>
      </ChatRowWrapper>
    </>
  );
});

const TextWrapper = styled('div', {
  whiteSpace: 'pre-wrap',
});

const DetailDialog = ({
  exchange,
  onDismiss,
}: {
  exchange: Exchange;
  onDismiss: () => void;
}) => (
  <SimpleDialog
    title={
      <>
        Response - <EditableExchangeLabel exchange={exchange} />
      </>
    }
    onDismiss={onDismiss}
    disableContainerDismissal
  >
    <DetailBody exchange={exchange} />
  </SimpleDialog>
);

const DetailBody = ({ exchange }: { exchange: Exchange }) => {
  return (
    <>
      <ExchangeDetailStatus exchange={exchange} />
      <hr />
      <TextWrapper>{exchange.rawResponse.text}</TextWrapper>

      {!!exchange.parsedResponse?.alerts?.length ? (
        <div>
          <br />
          {/* // todo: styling */}
          ALERTS:
          <br />
          {exchange.parsedResponse.alerts.map(alert => (
            <p key={hashCode(alert.message)}>{alert.message}</p>
          ))}
        </div>
      ) : null}
    </>
  );
};
