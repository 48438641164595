import React from 'react';
import { alertMessages, AppRoot, PERMISSIONS_ERROR } from './models/app-root';

import './ui/script-editor.css';
import { useParams } from 'react-router-dom';
import { StatusBar } from './ui/status-bar';
import { SearchBar } from './ui/search-bar';
import { ScriptEditor } from './script-editor';
import { Alerts } from '@masala-lib/editorial/ui/alerts';

import { ChoiceModeDialog } from './ui/choice-mode-dialog';
import { WordGroupInspectorDialog } from './word-group-inspector/word-group-inspector-dialog.jsx';
import { FiltersDialog } from './ui/filters-dialog';
import { VersionsDialog } from './ui/versions-dialog';
import { ContextualSidepanel } from './ui/contextual-sidepanel';
import { observer, useObserver } from 'mobx-react';
import {
  ERROR_NOT_LOADABLE,
  IN_PROGRESS,
} from '@masala-lib/firestore-db/firestore-doc-set';
import { HistoryDialog } from './ui/history-dialog';
import { SidePopup } from '../console/views/shared/side-popup';
import { ScriptEditorCheetSheet } from './ui/script-editor-cheat-sheet';
import { ReplaceSpeakerLabelsDialog } from './ui/replace-speaker-labels-dialog';
import { CreateSentenceDialog } from './ui/create-sentence-dialog';
import { isChrome } from '@masala-lib/utils';

export const ScriptEditorApp = () => {
  const { unitId, locale, jumpId, volumeId } = useParams<{
    unitId: string;
    volumeId: string;
    locale: string;
    jumpId: string;
  }>();

  const [appRoot, setAppRoot] = React.useState<AppRoot>();

  const baselineTimestamp: number = null;
  const notChrome = !isChrome();

  if (notChrome) {
    return <h2>{'Script Editor only works in Google Chrome'}</h2>;
  }

  // eslint-disable-next-line
  React.useEffect(() => {
    const appRoot = new AppRoot();
    appRoot
      .loadEpisodePromise(unitId, volumeId, locale, jumpId, baselineTimestamp)
      .then(() => {
        setAppRoot(appRoot);
      });
    return () => appRoot.closeEpisode();
  }, [unitId, locale, jumpId, volumeId]);

  // eslint-disable-next-line
  return useObserver(() => {
    if (!appRoot || appRoot.status === IN_PROGRESS) {
      return <h2>{'...loading'}</h2>;
    } else if (appRoot.status === ERROR_NOT_LOADABLE) {
      return <div>{'LOADING ERROR'}</div>;
    } else if (appRoot.status === PERMISSIONS_ERROR) {
      return <div>{'NO ACCESS'}</div>;
    } else {
      return <AppRootView />;
    }
  });
};

const AppRootView = observer(() => (
  <div style={{ height: '100%' }}>
    <div id={'main-container'}>
      <div className={'left-sidepanel'} />
      <div className={'editor-main'}>
        <StatusBar />
        <SearchBar />
        <ScriptEditor />
        <div className={'alerts'}>
          <Alerts alerts={alertMessages} />
        </div>
      </div>
      <SideBar />
      <ReplaceSpeakerLabelsDialog />
      <CreateSentenceDialog />
      <ChoiceModeDialog />
      <FiltersDialog />
      <VersionsDialog />
      <HistoryDialog />
      <WordGroupInspectorDialog />
      <SidePopup>
        <ScriptEditorCheetSheet />
      </SidePopup>
    </div>
  </div>
));

const SideBar = observer(() => {
  return (
    <div className={'right-sidepanel'}>
      <ContextualSidepanel />
    </div>
  );
});
