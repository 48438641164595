import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Auth } from '@masala-lib/editorial/db/auth';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { Volume } from '@masala-lib/catalog/models/volume';
import { createLogger } from '@app/logger';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';

const log = createLogger('excerpt-import');

async function importFromUrl(excerpt: Excerpt, dataUrl: string) {
  try {
    consoleAddAuditLogMessage(
      excerpt.volume.id,
      'Import excerpt from dataUrl: ' + dataUrl
    );
    // todo: what should we archive before import?
    // await volume.invokeExport();
    const response = await excerpt.importFromUrl(dataUrl);
    if (response.error) {
      alert(`Error: ${response.error}`);
    } else {
      alert(response.result);
    }
    // todo: need volume level audit logs
  } catch (error) {
    excerpt.clearPendingOperation();
    window.alert(error);
    throw error;
  }
}

async function importFromScript(excerpt: Excerpt, script: string) {
  try {
    consoleAddAuditLogMessage(
      excerpt.volume.id,
      'Import excerpt from raw script'
    );
    // todo: what should we archive before import?
    // await volume.invokeExport();
    const response = await excerpt.importFromScript(script);
    if (response.error) {
      alert(`Error: ${response.error}`);
    } else {
      alert(response.result);
    }
    // todo: need volume level audit logs
  } catch (error) {
    excerpt.clearPendingOperation();
    window.alert(error);
    throw error;
  }
}

interface FormData {
  id: string;
  importSource: string;
}

export const ExcerptImport = observer(({ model }: { model: Excerpt }) => {
  const auth = Auth.getInstance();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FormData) => {
    log.info(`ExcerptImport.onSubmit(${JSON.stringify(data)})`);
    const source = data.importSource.trim();
    if (isEmpty(source)) {
      alert('source required');
    } else {
      if (source.startsWith('http')) {
        importFromUrl(model, source);
      } else {
        // alert('url required');
        importFromScript(model, source);
      }
    }
  };

  if (!auth.can('manage_volumes')) {
    return null;
  }

  return (
    <>
      <h4>Import</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          defaultValue={model.id}
          {...register('id', { required: false })}
        />
        Exported volume data URL or pasted raw script text
        <br />
        {/* <input {...register('importSource')} size={20} /> */}
        <textarea {...register('importSource')} cols={50} rows={2} />
        <br />
        <input type="submit" /> &nbsp;
      </form>
    </>
  );
});
