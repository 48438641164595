import {
  alertMessages,
  chaatToolModel,
  mutationActions,
  transportState,
} from '../app-root';
import { WordId } from '@tikka/basic-types';
import { ElementList, Word } from '@masala-lib/chaat-aliases';
import { CueActions } from '@masala-lib/editorial/models/actions/cue-actions';
import { Cue } from '@masala-lib/editorial/chaat/chaat-types';

export class ChaatCueActions extends CueActions {
  get mutationActions() {
    return mutationActions;
  }
  get alertMessages() {
    return alertMessages;
  }
  get cueInsertWordId() {
    return chaatToolModel.currentCuePointWordId as WordId;
  }
  set cueInsertWordId(id: WordId) {
    chaatToolModel.currentCuePointWordId = id;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get existingCues(): Cue[] {
    // TODO work on typing
    return <Cue[]>chaatToolModel.cuesData;
  }
  get activeNavStops() {
    return chaatToolModel.segmentStopWords;
  }
  get words() {
    return chaatToolModel.words;
  }
}
