import * as React from 'react';
import { styled } from 'naan/stitches.config';

const BodyWrapper = styled('div', {
  padding: '0 $space$4 0 $space$4',
  flex: 1,
  overflow: 'auto',
  transition: 'box-shadow 0.4s',
  '&:first-child': {
    paddingTop: '$space$4',
  },
  '&:last-child': {
    paddingBottom: '$space$4',
  },
  '&.overflowing': {
    boxShadow: 'inset 0 -12px 12px -12px rgba(0,0,0,.1)',
  },
  '& .--body-beacon': {
    display: 'block',
    height: '1px',
  },
});

type BodyWrapperProps = React.ComponentProps<typeof BodyWrapper>;

// function checkElementOverflow(element: HTMLDivElement) {
//   if (element) {
//     const { scrollHeight, clientHeight } = element;
//     // check if the element is overflowing
//     return scrollHeight > clientHeight;
//   }
// }

export const Body: React.FC<BodyWrapperProps> = ({ children, ...props }) => {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const beaconRef = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    // const bodyIsOverflowing = checkElementOverflow(bodyRef.current);

    // if (bodyIsOverflowing) {
    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          bodyRef.current?.classList.remove('overflowing');
        } else {
          bodyRef.current?.classList.add('overflowing');
        }
      },
      {
        root: bodyRef.current,
      }
    );
    observer.observe(beaconRef.current);

    return () => {
      observer.disconnect();
    };
    // }
  }, []);

  return (
    <BodyWrapper ref={bodyRef} {...props}>
      {children}
      <span className="--body-beacon" ref={beaconRef} />
    </BodyWrapper>
  );
};
