import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { getUnitExportData } from '@masala-lib/editorial/db/mutation-actions';
// beware the ReactJson library looked pretty, but it choked on our size of data
// import ReactJson from 'react-json-view';

export const UnitExportDataScreen = () => {
  const { unitId } = useParams<{ unitId: string }>();

  const [data, setData] = useState<object>({ status: 'loading...' });

  useEffect(() => {
    console.log('UnitExportDataScreen-useEffect');
    async function getData() {
      const jsonData = await getUnitExportData(unitId);
      setData(jsonData);
    }
    getData();
  }, [unitId]);

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
