import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { VideoGuideCreateScreen } from './video-guide-create-screen';
import { VideoGuideDetailScreen } from './video-guide-detail-screen';
import { VideoGuideEditScreen } from './video-guide-edit-screen';
import { VideoGuidesListScreen } from './video-guides-list-screen';

export const VideoGuidesScreen = () => {
  return (
    <ModelScreen
      path={'/video-guides'}
      renderList={() => <VideoGuidesListScreen />}
      renderDetail={controller => (
        <VideoGuideDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <VideoGuideEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <VideoGuideCreateScreen />}
    />
  );
};
