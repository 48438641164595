import * as React from 'react';
import { useForm } from 'react-hook-form';
import { MDBModal, MDBModalBody } from 'mdbreact';
import { chaatToolModel } from '../models/app-root';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { KerningConfigData } from '@masala-lib/editorial/chaat/chaat-types';

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

class KerningConfigDialogState {
  @observable.ref open = false;
  constructor() {
    makeObservable(this);
  }
}

const dialogState = new KerningConfigDialogState();

export function openKerningConfigDialog() {
  dialogState.open = true;
}

export const KerningConfigDialog = observer(() => {
  const { register, handleSubmit } = useForm();
  const unit = chaatToolModel.unit;

  const onSubmit = (data: KerningConfigData) => {
    unit.setKerningConfigData(data);
    closeModal();
  };

  const closeModal = () => {
    dialogState.open = false;
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={closeModal}>
      <MDBModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputRow>
            <label>Auto Segment Threshold</label>
            <div>
              <input
                type="number"
                defaultValue={unit.kerningConfigData.minGapSize}
                {...register('minGapSize')}
              />
              <span> ms</span>
            </div>
          </InputRow>
          <InputRow>
            <label>Minimum Segment Length</label>
            <div>
              <input
                type="number"
                step="any"
                defaultValue={unit.kerningConfigData.minSegmentLength}
                {...register('minSegmentLength')}
              />
              <span> s</span>
            </div>
          </InputRow>
          <InputRow>
            <label>Forced Gap Size</label>
            <div>
              <input
                type="number"
                defaultValue={unit.kerningConfigData.forcedGapSize}
                {...register('forcedGapSize')}
              />
              <span> ms</span>
            </div>
          </InputRow>
          <br />
          <input type="submit" value="Save" />
        </form>
      </MDBModalBody>
    </MDBModal>
  );
});
