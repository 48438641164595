import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DataItem } from '../../components/data-display/data-item';
import { Card } from '../../components/data-display/card';
import { useJob } from './use-job';
import { agePrettyTimestamp } from '@masala-lib/utils';
import { JobManager } from '@masala-lib/jobs/job-manager';
import { alertError } from 'ui/misc-utils';
import { createLogger } from '@app/logger';
import { NavLink } from 'react-router-dom';

const log = createLogger('job-detail-screen');

interface DetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const JobDetailScreen = observer(
  ({ closeDetail, startEditing }: DetailsParams) => {
    const { model: job, failure } = useJob();
    log.debug(`render ${job?.id} - arch: ${String(job?.archived)}`);
    // const auth = Auth.getInstance();

    if (!job) {
      return null;
    }

    const toggleArchive = async () => {
      JobManager.toggleArchive(job).catch(alertError);
    };

    if (failure) {
      return <div>{failure}</div>;
    }

    const unitId = job.params?.UNIT_ID;

    return (
      <Page
        title={`${job.id} ${job.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {/* {auth.can('manage_activity_guides') ? (
                <button onClick={startEditing} className="primary-action">
                  Edit Author
                </button>
              ) : null} */}
              <button
                onClick={toggleArchive}
                className={job.archived ? '' : `danger-action`}
              >
                {job.archived ? 'Unarchive' : 'Archive'}
              </button>
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <Card>
              <DataItem label="Id" value={job.id} />
              <DataItem label="Kind" value={job.kind} />
              <DataItem label="Status" value={job.status} />
              <DataItem label="Last run stage" value={job.lastRunStage} />
              <DataItem
                label="Started"
                value={agePrettyTimestamp(job.startTimestamp)}
              />
              <DataItem
                label="Exited"
                value={agePrettyTimestamp(job.exitTimestamp)}
              />
              <DataItem label="Archived" value={String(job.archived)} />
              {!!job.errorMessage ? (
                <>
                  <DataItem label="Error" value={job.errorMessage} />
                  <DataItem label="Stack" value={<pre>{job.errorStack}</pre>} />
                </>
              ) : null}
              {unitId ? (
                <DataItem
                  label="Unit"
                  value={<NavLink to={`/units/${unitId}`}>{unitId}</NavLink>}
                />
              ) : null}
              {/* <DataItem label="Bio">
                <ReactMarkdown>{author.data.bio}</ReactMarkdown>
              </DataItem> */}
              {job.stageList.map(stage => (
                <DataItem
                  key={stage.stageName}
                  label={`Stage: ${stage.stageName}`}
                  value={
                    <Card>
                      <DataItem label="Status" value={stage.status} />
                      <DataItem
                        label="Started"
                        value={agePrettyTimestamp(stage.startTimestamp)}
                      />
                      <DataItem
                        label="Finished"
                        value={agePrettyTimestamp(stage.exitTimestamp)}
                      />
                      <DataItem label="Error" value={stage.errorMessage} />
                      <DataItem
                        label="Logs"
                        value={<pre>{job.stageLogs(stage.stageName)}</pre>}
                      />
                      <DataItem
                        label="Output data"
                        value={JSON.stringify(stage.stageOutputData)?.substring(
                          0,
                          100
                        )}
                      />
                    </Card>
                  }
                />
              ))}
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
