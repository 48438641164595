import { observer } from 'mobx-react';
import { useSamosaModel } from '../../models/samosa-model-handle';
import {
  ReferenceScript,
  Step,
} from '@masala-lib/llm/project/llm-project-types';
import { Bubble } from '../components/bubble';
import { styled } from '@naan/stitches.config';
import { SimpleDialog } from '../components/simple-dialog';
import { ReferenceScriptDialog } from './reference-script-dialog';
import { shouldTruncate } from '@masala-lib/llm/project/llm-project-funcs';
import { DialogPresenter } from '../dialog-presenter';
import { ToolbarBaseOuterContainer } from '../components/toolbar';
import { ScriptToolbar } from '../thread-area/script-toolbar';

const Wrapper = styled(ToolbarBaseOuterContainer, {
  padding: '20px 12px',
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  gridTemplateRows: '1fr',
  gap: 8,
  '.floating-toolbar': {
    // need to compensate here because of the padding defined above
    bottom: 4,
  },
  // '& > .exchange-body': {},
});

//
// main body panel item, when current selection is the reference script
//
export const ReferenceScriptStep = observer(({ step }: { step: Step }) => {
  const model = useSamosaModel();
  const script = step as ReferenceScript;
  const truncateLines = model.isCurrentStep(script) ? 25 : 3;
  const truncate = shouldTruncate(model.referenceText, truncateLines);

  const onBubbleClick = () => {
    DialogPresenter.present(onDismiss => (
      <SimpleDialog title="Reference script" onDismiss={onDismiss}>
        <ReferenceScriptDialog />
      </SimpleDialog>
    ));
  };

  return (
    <Wrapper>
      <div />
      <div onClick={onBubbleClick}>
        <Bubble
          presentation="script"
          truncate={truncate}
          truncateLines={truncateLines}
        >
          {model.referenceText}
        </Bubble>
      </div>
      {/* // todo */}
      {/* <div className="floating-toolbar">
        <ScriptToolbar />
      </div> */}
    </Wrapper>
  );
});
