import { Interval } from '@tikka/intervals/intervals';
import { makeObservable, observable, reaction } from 'mobx';
import { Context2D } from './canvas';

const BOTH = 'BOTH';
const TOP = 'TOP';
const BOTTOM = 'BOTTOM';
const DOWN = 'DOWN';
const UP = 'UP';
const CENTER = 'CENTER';

export class TrackGadget {
  static BOTH = BOTH;
  static TOP = TOP;
  static BOTTOM = BOTTOM;
  static DOWN = DOWN;
  static UP = UP;
  static CENTER = CENTER;
  @observable notifyForegroundShouldRedraw = 0;
  @observable notifyBackgroundShouldRedraw = 0;
  originY = 0;
  height = 0;
  midpointY = 0;
  bottomY = 0;
  doesRollover = false;
  detectPixelForRollover = false;

  timePixelScale = 1;
  canvasPixelWidth = 0;
  canvasStartTime = 0;
  canvasTimeExtent = 0;
  canvasEndTime = 0;
  disposers: (() => void)[] = [];

  constructor() {
    makeObservable(this);
  }

  yInside(y: number) {
    return y > this.originY && y < this.bottomY;
  }

  setLayout(originY: number, height: number) {
    this.originY = originY;
    this.height = height;
    this.midpointY = this.originY + this.height / 2;
    this.bottomY = this.originY + height;
  }

  setCanvasMapping(
    canvasStartTime: number,
    canvasTimeExtent: number,
    canvasPixelWidth: number,
    timePixelScale: number
  ) {
    this.canvasStartTime = canvasStartTime;
    this.canvasTimeExtent = canvasTimeExtent;
    this.canvasEndTime = canvasStartTime + canvasTimeExtent;
    this.canvasPixelWidth = canvasPixelWidth;
    this.timePixelScale = timePixelScale;
    this.requestFullRedraw();
  }

  drawBackgroundLayer(ctx: Context2D) {
    throw new Error('non implemented abstract');
  }

  drawForegroundLayer(ctx: Context2D) {
    throw new Error('non implemented abstract');
  }

  draw(ctx: Context2D) {
    this.drawBackgroundLayer(ctx);
    this.drawForegroundLayer(ctx);
  }

  onBackgroundShouldRedraw(f: () => void) {
    this.disposers.push(reaction(() => this.notifyBackgroundShouldRedraw, f));
  }

  onForegroundShouldRedraw(f: () => void) {
    this.disposers.push(reaction(() => this.notifyForegroundShouldRedraw, f));
  }

  requestBackgroundRedraw() {
    this.notifyBackgroundShouldRedraw++;
  }

  requestForegroundRedraw() {
    this.notifyForegroundShouldRedraw++;
  }

  requestFullRedraw() {
    this.requestForegroundRedraw();
    this.requestBackgroundRedraw();
  }

  handleMouseClickAtTime(
    x: number,
    y: number,
    time: number,
    event: MouseEvent
  ): boolean {
    return false;
  }

  timeExtentToPixelCount(extent: number) {
    return extent * this.timePixelScale;
  }

  timeToCanvasPixelX(time: number) {
    return (time - this.canvasStartTime) * this.timePixelScale;
  }

  timeIsOnCanvas(time: number) {
    return time > this.canvasStartTime && time < this.canvasEndTime;
  }

  timeIntervalIsOnCanvas(begin: number, end: number) {
    return !(end < this.canvasStartTime || begin > this.canvasEndTime);
  }

  drawTimerangeEdges(
    ctx: Context2D,
    timeInterval: Interval,
    pixelHeight: number
  ) {
    ctx.beginPath();
    const x1 = this.timeToCanvasPixelX(timeInterval.begin);
    const x2 = this.timeToCanvasPixelX(timeInterval.end);
    ctx.moveTo(x1, this.bottomY);
    ctx.lineTo(x1, this.bottomY - pixelHeight);
    ctx.moveTo(x2, this.bottomY);
    ctx.lineTo(x2, this.bottomY - pixelHeight);
    ctx.stroke();
  }

  drawTimerangeRect(
    ctx: Context2D,
    timeInterval: Interval,
    pixelHeight: number
  ) {
    ctx.beginPath();
    ctx.rect(
      this.timeToCanvasPixelX(timeInterval.begin),
      this.bottomY - pixelHeight,
      this.timeExtentToPixelCount(timeInterval.end - timeInterval.begin),
      pixelHeight
    );
    ctx.fill();
  }

  // TODO factor out with above
  drawTimerangeRectOutline(
    ctx: Context2D,
    timeInterval: Interval,
    pixelHeight: number
  ) {
    ctx.beginPath();
    ctx.rect(
      this.timeToCanvasPixelX(timeInterval.begin),
      this.bottomY - pixelHeight,
      this.timeExtentToPixelCount(timeInterval.end - timeInterval.begin),
      pixelHeight
    );
    ctx.stroke();
  }

  drawTimerangeDividingLine(
    ctx: Context2D,
    timeInterval: Interval,
    ratio: number
  ) {
    const y = this.originY + this.height * ratio;
    ctx.beginPath();
    ctx.moveTo(this.timeToCanvasPixelX(timeInterval.begin), y);
    ctx.lineTo(this.timeToCanvasPixelX(timeInterval.end), y);
    ctx.stroke();
  }

  drawTextAtTime(
    ctx: Context2D,
    time: number,
    text: string,
    leadingSpace: number,
    width = -1
  ) {
    const x = this.timeToCanvasPixelX(time) + leadingSpace;
    if (width >= 0) {
      ctx.fillText(text, x, this.midpointY, width - leadingSpace);
    } else {
      ctx.fillText(text, x, this.midpointY);
    }
  }

  drawTextInTimeInterval(
    ctx: Context2D,
    interval: Interval,
    text: string,
    alignment = CENTER
  ) {
    const width = this.timeExtentToPixelCount(interval.end - interval.begin);
    let x;
    ctx.save();
    if (alignment === CENTER) {
      ctx.textAlign = 'center';
      x = this.timeToCanvasPixelX((interval.begin + interval.end) / 2);
    } else {
      x = this.timeToCanvasPixelX(interval.begin) + 5; //TODO calc spacer value from DPI?
    }
    ctx.fillText(text, x, this.midpointY, width);
    ctx.restore();
  }

  drawLineAtTime(
    ctx: Context2D,
    time: number,
    pixelHeight: number,
    direction = 'UP'
  ) {
    const pixelX = this.timeToCanvasPixelX(time);
    if (direction === 'UP') {
      ctx.moveTo(pixelX, this.bottomY);
      ctx.lineTo(pixelX, this.bottomY - pixelHeight);
    } else {
      ctx.moveTo(pixelX, this.originY);
      ctx.lineTo(pixelX, this.originY + pixelHeight);
    }
    ctx.stroke();
  }

  drawTriangleAtTime(
    ctx: Context2D,
    time: number,
    pixelWidth: number,
    direction = DOWN
  ) {
    const halfWidth = pixelWidth >> 1;
    const pixelCenter = this.timeToCanvasPixelX(time);
    if (direction === DOWN) {
      ctx.beginPath();
      ctx.moveTo(pixelCenter - halfWidth, this.originY);
      ctx.lineTo(pixelCenter, this.bottomY);
      ctx.lineTo(pixelCenter + halfWidth, this.originY);
      ctx.closePath();
      ctx.fill();
    } else {
      // TODO
    }
  }

  drawGadgetSliceBounds(ctx: Context2D, pick = BOTH) {
    ctx.beginPath();
    if (pick === BOTH || pick === TOP) {
      ctx.moveTo(0, this.originY);
      ctx.lineTo(this.canvasPixelWidth, this.originY);
    }
    if (pick === BOTH || pick === BOTTOM) {
      ctx.moveTo(0, this.bottomY);
      ctx.lineTo(this.canvasPixelWidth, this.bottomY);
    }
    ctx.stroke();
  }

  dispose() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }
}
