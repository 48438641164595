import { ChannelData } from '../catalog-types';
import { Channel } from '../models/channel';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';

export class ChannelManager extends EntityManager<Channel, ChannelData> {
  get collectionName(): string {
    return CatalogCollections.CHANNEL_METADATA;
  }

  createModel(data: { id: string }): Channel {
    return new Channel(data);
  }

  private static instance: ChannelManager;

  public static getInstance(): ChannelManager {
    if (!ChannelManager.instance) {
      ChannelManager.instance = new ChannelManager();
      // todo: remove the listenAll from here. shouldn't be needed expect for the console
      ChannelManager.instance.listenAll();
    }
    return ChannelManager.instance;
  }

  // return list of slug, url tuples
  // (both review and staged)
  get allCatalogUrls(): [string, string][] {
    const result: [string, string][] = [];
    for (const channel of this.list) {
      result.push(...channel.catalogUrls);
    }
    return result;
  }
}
