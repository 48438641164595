import * as React from 'react';
import { observer } from 'mobx-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SearchBar } from '../shared/search-bar';
import { UnitRow } from './unit-row';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Volume } from '@masala-lib/catalog/models/volume';
import { ListOfLinks } from '../shared/list-of-links';
import { OnePaneLayout } from '../shared/layouts';
import {
  getQueryParamsFromLocation,
  makeQueryPath,
} from '../../lib/query-stuff';
import { Page } from '../shared/page';
import { PageActions } from '../shared/page-actions';
import { UnitsTable } from './units-table';
import { PageHeading } from '../shared/page-heading';
import { SortControls } from './sort-controls';
import { FilterControls } from '../shared/filters/filter-controls';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

export const NEW_MODEL_PATH_ID = '_new_';

// erases the /_/ path fragment for an unscoped list view
export const normalizePathId = (path: string): string => {
  return path === '_' ? null : path;
};

interface RouteSearchParams {
  volume?: string;
  channel?: string;
  workflowStatus?: string;
  warningsStatus?: string;
  sort?: string;
  order?: string;
}

export const UnitListScreen = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    volume: volumeId,
    channel: channelId,
    workflowStatus,
    warningsStatus,
    sort = 'updatedAt',
    order = 'desc',
  }: RouteSearchParams = getQueryParamsFromLocation(location);
  const auth = Auth.getInstance();
  const manager = UnitManager.getInstance();

  React.useEffect(() => {
    manager.setFilter('channelId', channelId, 'channel');
    manager.setFilter('volumeId', volumeId, 'volume');
    manager.setFilter('data.workflowStatus', workflowStatus, 'workflow_status');
    manager.setFilter('warningsStatus', warningsStatus, 'warnings_status');
    manager.setSorting(sort, order);
  }, [
    manager,
    volumeId,
    channelId,
    workflowStatus,
    warningsStatus,
    sort,
    order,
  ]);

  // const filterValueDecorator = (filterName: string, value: string) => {
  //   if (filterName === 'volumeId') {
  //     return VolumeManager.getInstance().getById(value).name;
  //   }
  //   return value;
  // };

  const list: Unit[] = manager.filteredList;

  return (
    <Page
      title={`Units ${manager.showArchived ? '- Archive' : ''}`}
      controls={
        <PageActions>
          <SearchBar manager={manager} />
          {auth.can('manage_units') ? (
            <>
              <button
                onClick={() =>
                  navigate(makeQueryPath(`/units/new`, { volume: volumeId }))
                }
                className="primary-action"
              >
                New Unit
              </button>
            </>
          ) : null}
        </PageActions>
      }
    >
      <OnePaneLayout>
        {/* {volume ? (
          <PageHeading>
            <NavLink to={volume?.navPath}>
              Volume <strong>{volume?.name}</strong>
            </NavLink>
            {volume?.channel ? (
              <NavLink to={volume.channel.volumesNavPath}>{volume.channel.name} volumes</NavLink>
            ) : null}
          </PageHeading>
        ) : null} */}
        {/* <ReactSelect
        value={volumeOptions.find(c => c.value === volumeIdFilter)}
        options={volumeOptions}
        onChange={(selectedOption: SelectOption) => {
          setVolumeIdFilter(selectedOption.value);
        }}
      /> */}
        <PageHeading>
          <SortControls
            fields={[
              { fieldName: 'unitName', label: 'Name' },
              { fieldName: 'volumeName', label: 'Volume' },
              { fieldName: 'releaseDate', label: 'Release date' },
              { fieldName: 'createdAt', label: 'Created at' },
              { fieldName: 'updatedAt', label: 'Updated at' },
            ]}
            currentSortingFieldName={manager.sorting.fieldName}
            currentSortingOrder={manager.sorting.order}
          />
          <FilterControls
            path="/units"
            filters={['workflowStatus', 'volume', 'channel', 'warningsStatus']}
          />
          {/* <ArchivedToggle
            active={manager.showArchived}
            onToggle={manager.toggleShowArchived}
            destroyAll={() => {}}
          /> */}
          {/* <FiltersBar

            filters={manager.filters}
            nameMap={{ 'data.workflowStatus': 'workflow status', volumeId: 'volume ID' }}
            valueDecorator={filterValueDecorator}
          /> */}
        </PageHeading>
        <UnitsTable
          units={list}
          currentSortingFieldName={manager.sorting.fieldName}
          currentSortingOrder={manager.sorting.order}
        />

        {/* <ActionLink onPress={handleRefresh}>[refresh]</ActionLink> */}
      </OnePaneLayout>
    </Page>
  );
});

// TODO: remove this declaration
export const UnitList = observer(
  ({
    list,
    selected,
    volume,
  }: {
    list: Unit[];
    selected: Unit;
    volume: Volume;
  }) => (
    <>
      <ListOfLinks>
        <ul>
          {list.map((model: Unit) => (
            <li key={model.id}>
              <UnitRow model={model} isSelected={model.id === selected?.id} />
            </li>
          ))}
        </ul>
      </ListOfLinks>
      {volume ? (
        <>
          {/* <NavLink to={`/volumes/${volumeId}/units/${NEW_MODEL_PATH_ID}`}>[new unit]</NavLink> */}
          <NavLink to={volume.newUnitNavPath}>[new unit]</NavLink>
        </>
      ) : (
        <NavLink to={`/units/${NEW_MODEL_PATH_ID}`}>[new]</NavLink>
      )}
      <br />
    </>
  )
);
