import * as React from 'react';
import { UnitData, WorkflowStatus } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { Input, Select, FormButtons, Checkbox } from '../../components/forms';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { OnePaneLayout } from '../shared/layouts';
import { localeCodes } from '@utils/util-types';
// import { flavorOptions } from '../volumes/volume-form';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';

interface Props {
  dto: UnitData;
  onSubmit: (dto: UnitData) => void;
  onCancel: () => void;
}

export const UnitForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'id',
    'name',
    'slug',
    'l2Code',
    'l1Code',
    // 'audioLanguageCode',
    // 'flavor',
    'linearUrl',
    'soundbiteEditorialDocUrl',
    'workflowStatus',
    'structuralContentReady',
    'structuralContentInL1',
    'imageThumbUrl',
    // 'imageBannerUrl',
    'audioFinalUrl',
    'audioTranscribeUrl',
    'audioNoMusicUrl',
    'releaseDate',
    'releaseIsoDate',
    'trial',
    'downloadSize',
    'volumeId',
    'vocabReferenceCatalogUrl',
    'truncateChapterPosition',
    // 'jwnextEnabled',
  ]);

  const { handleSubmit, reset, getFieldProps, register } = useMasalaForm<
    typeof defaultValues
  >({
    defaultValues,
  });
  const isNew = isEmpty(dto.id);
  const volume = VolumeManager.getInstance().getById(dto.volumeId);
  const volumeOptions =
    volume?.channel?.volumeSelectOptions ||
    VolumeManager.getInstance().selectOptionsWithBlank;
  const blankOption = { value: '', label: '[none]' };
  const statusOptions = [
    blankOption,
    ...Object.values(WorkflowStatus).map(key => ({ value: key, label: key })),
  ];

  const blankDefaultOption = { value: '', label: '[channel default]' };
  const localeOptions = [
    blankDefaultOption,
    ...localeCodes.map(key => ({ value: key, label: key })),
  ];

  React.useEffect(() => {
    return () => {
      //otherwise we stay in form mode, even when going to another item
      onCancel();
    };
  }, [onCancel]);

  // const handleCancel = React.useCallback(() => {
  //   if (isNew) {
  //     history.replace('volumes'); // could also be goBack
  //   } else {
  //     onCancel();
  //   }
  // }, [isNew, history, onCancel]);

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          {...getFieldProps('volumeId')}
          options={volumeOptions}
          required
        />
        <hr />
        <Input
          {...getFieldProps('name')}
          required
          label="Workflow (internal) display name"
          hint="externally displayed 'Title' fields can be updated via 'Info' forms"
        />
        {/* <Input {...getFieldProps('partSuffix')} /> */}
        <Input
          {...getFieldProps('slug')}
          hint={"(will default to '[channel slug]-[volume slug]-#')"}
        />
        {/* <Input {...getFieldProps('l2Code')} label="L2 (studied language)" required />
        <Input {...getFieldProps('l1Code')} label="Primary L1 (learners language)" required /> */}
        <Select
          {...getFieldProps('l2Code')}
          options={localeOptions}
          label="L2 (studied language)"
          hint="(will default to channel prop)"
        />
        {/* // @Armando, should 'hint's work on select fields? */}
        <Select
          {...getFieldProps('l1Code')}
          options={localeOptions}
          label="Primary L1 (learner's language)"
          hint="(will default to channel prop)"
        />
        {/* <Input
          {...getFieldProps('audioLanguageCode')}
          label="Chaat audio language code"
          hint="'xx-XX' format, i.e. es-US, pt-BR, de-DE, en-US"
        /> */}
        {/* <Select
          {...getFieldProps('flavor')}
          options={flavorOptions as any}
          required
        /> */}

        <Input {...getFieldProps('linearUrl')} />
        <Input {...getFieldProps('soundbiteEditorialDocUrl')} />
        <Select {...getFieldProps('workflowStatus')} options={statusOptions} />
        <Checkbox
          {...getFieldProps('structuralContentReady')}
          label={'Structural content ready'}
          hint={'(when true, stricter lint rules are checked)'}
        />
        <Checkbox
          {...getFieldProps('structuralContentInL1')}
          label={'Structural content in L1'}
          hint={
            '(needed for new non-english where samosa is used to generation stuctural content in order to flip L1/L2 during ingestion)'
          }
        />
        {/* <Input {...getFieldProps('audioFinalUrl')} label={'Final audio url'} />
        <Input {...getFieldProps('audioTranscribeUrl')} label={'Transcribe audio url'} /> */}
        {/*todo: make these condition on lupa flavor unit*/}

        {/* <DateInput {...getFieldProps('releaseDate')} /> */}
        <Input
          {...getFieldProps('releaseIsoDate')}
          validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
          hint={'YYYY-MM-DD - will inherit from volume if blank'}
        />
        <Input {...getFieldProps('downloadSize')} hint="bytes" />

        <Checkbox {...getFieldProps('trial')} label={'Free trial'} />
        <FormButtons submitLabel={isNew ? 'Create unit' : 'Save changes'} />

        <hr />
        <h4>Asset Links</h4>
        <Input
          {...getFieldProps('audioFinalUrl')}
          hint="fully mixed audio including music and other sound effects - used by current lupa app, and future 'lean back' player"
        />
        <Input
          {...getFieldProps('audioTranscribeUrl')}
          hint="audio version which includes just the spoken audio stems - used for timestamping"
        />
        <Input
          {...getFieldProps('audioNoMusicUrl')}
          hint="audio version which includes spoken audio and sound effects - not used by current lupa app, expected to be used in future study mode when available. optional - transcribe url will be used when blank"
        />
        {/* <Input {...getFieldProps('imageBannerUrl')} /> */}
        <Input
          {...getFieldProps('imageThumbUrl')}
          hint="DEPRECATED - should be provided at the volume level now (will be automatically migrated upon ingestion)"
        />

        <FormButtons
          submitLabel={isNew ? 'Create unit' : 'Save changes'}
          onCancel={onCancel}
        />

        <hr />
        <Input
          {...getFieldProps('metadataUrl')}
          hint="may be used to reimport metadata from a google doc. will be automatically reset after next ingestion or 'import metadata' action"
        />
        <Input
          {...getFieldProps('vocabReferenceCatalogUrl')}
          hint="legacy catalog to attempt to match up vocab ids from"
        />
        <Input
          {...getFieldProps('truncateChapterPosition')}
          hint="if provided, then only ingest up to given number of chapters - used for german demo ingestion"
        />
        {/* <Checkbox
          {...getFieldProps('jwnextEnabled')}
          label={'Enable jw.next ingestion'}
        /> */}
      </form>
    </OnePaneLayout>
  );
};
