import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Tag, tagTypeOptions } from '@masala-lib/catalog/models/tag';
import { Badge } from '../../components/badge';
import { Table } from '../shared/table';

interface TagsTableProps {
  tags: Tag[];
}

export const TagsTable = observer(({ tags }: TagsTableProps) => {
  return (
    <Table
      data={tags}
      cells={[
        {
          header: 'Type',
          render: tag => {
            const type = tagTypeOptions.find(t => t.value === tag.data.tagType);
            return <Badge>{type?.label}</Badge>;
          },
        },
        {
          header: 'Name',
          render: tag => <Link to={tag.navPath}>{tag.name}</Link>,
        },
        {
          header: 'slug',
          render: tag => tag.data.slug,
        },
      ]}
    />
  );
});
