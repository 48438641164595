import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { useActivityGuide } from './use-activity-guide';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import ReactMarkdown from 'react-markdown';
import { Box, Flex } from 'rebass';
import { A } from '@masala-lib/editorial/ui/action-link';
import { Avatar } from '../../components/avatar';
import { Auth } from '@masala-lib/editorial/db/auth';

interface ActivityGuideDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const ActivityGuideDetailScreen = observer(
  ({ closeDetail, startEditing }: ActivityGuideDetailsParams) => {
    const activityGuide = useActivityGuide();
    const auth = Auth.getInstance();

    if (!activityGuide) {
      return null;
    }

    return (
      <Page
        title={`${activityGuide.name} ${
          activityGuide.archived ? '(Archived)' : ''
        }`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.can('manage_activity_guides') ? (
                <button onClick={startEditing} className="primary-action">
                  Edit ActivityGuide
                </button>
              ) : null}
              <DeleteActions model={activityGuide} />
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <Card>
              <DataItem label={'Id'} value={activityGuide.id} />
              <DataItem
                label={'Unit '}
                value={
                  activityGuide?.unit ? (
                    <Link to={activityGuide.unit.navPath}>
                      {activityGuide.unit.data.name}
                    </Link>
                  ) : (
                    'This activity guide has not been assigned to a unit'
                  )
                }
              />
              {/* <DataItem label={'Name'} value={activityGuide.data.name} /> */}
              <DataItem
                label={'Author'}
                value={
                  <Flex alignItems="center">
                    <Box mr={2}>
                      <Avatar
                        src={activityGuide.author?.data?.avatarUrl}
                        size={'24px'}
                      ></Avatar>
                    </Box>
                    <Link to={activityGuide.author?.navPath}>
                      {activityGuide.author?.data?.name || 'N/A'}
                    </Link>
                  </Flex>
                }
              />
              <DataItem
                label={'Resource'}
                value={
                  <A href={activityGuide.data.resourceUrl}> Resource URL </A>
                }
              />
              <DataItem
                label={'Author’s Note'}
                value={
                  <Box py={3}>
                    <ReactMarkdown>
                      {activityGuide.data.authorNote}
                    </ReactMarkdown>
                  </Box>
                }
              />
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
