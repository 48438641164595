import { observer } from 'mobx-react';
import * as React from 'react';
import { Unit } from '@masala-lib/catalog/models/unit';
import { OnePaneLayout } from '../shared/layouts';

import useStateMachine, { t } from '@cassiozen/usestatemachine';
import { SpinnerWithLegend } from '../../components/spinner';
import { UnitWorkflowCreator } from './unit-workflow-creator';
import { useLinear } from '@masala-lib/misc/linear';
import { Template } from '@linear/sdk';
import { StateSwitch } from '../../lib/hooks/use-switch';
// import { AttachmentUpdateDocument } from '@linear/sdk/dist/_generated_documents';

interface UnitWorkflowProps {
  unit: Unit;
  showSwitch: StateSwitch;
}

export const UnitWorkflow = observer(
  ({ unit, showSwitch }: UnitWorkflowProps) => {
    const {
      getEditorialTemplates,
      createIssue,
      attachAttachment,
      createIssueTree,
    } = useLinear();

    /// I wasn't able to get the "context" feature of useStateMachine working.
    /// So I'm faking it with just state objects.
    const [templates, setTemplates] = React.useState<Template[]>(null);
    const [selectedTemplates, setSelectedTemplates] =
      React.useState<{ master: Template; subs: Template[] }>(null);

    const [state, send] = useStateMachine({
      schema: {
        events: {
          CREATE_WORKFLOW: t<{ value: { selectedTemplates: Template[] } }>(),
        },
      },
      initial: 'initial',
      states: {
        initial: {
          on: {
            LOAD_TEMPLATES: 'loadingTemplates',
          },
        },
        loadingTemplates: {
          on: {
            TEMPLATES_LOADED: 'showWorkflowCreator',
          },
          effect() {
            getEditorialTemplates().then(templates => {
              setTemplates(templates);
              send('TEMPLATES_LOADED');
            });
          },
        },
        showWorkflowCreator: {
          on: {
            GENERATE_WORKFLOW: 'creatingWorkflow',
          },
        },
        creatingWorkflow: {
          effect() {
            async function runAsyncEffect() {
              const { master, subs } = selectedTemplates;
              const parentIssue = await createIssueTree({ unit, master, subs });
              console.log('PARENTISSUE', parentIssue);
              unit.setLinearUrl(parentIssue.url);
              showSwitch.off();
              // alert('Now you can reload');
            }
            runAsyncEffect();
          },
        },
      },
    });

    React.useEffect(() => {
      const url = unit.data.linearUrl;
      /// this effect works only when you first land on the page
      /// When issueId changes (after setting the parent issue) the effect will run
      /// but the `send` commands down there won't do anything. ¯\_(ツ)_/¯
      if (!url) {
        send('LOAD_TEMPLATES');
        // send('CREATE_WORKFLOW');
      }
    }, [send, unit.data.linearUrl]);

    const handleCreateWorkflow = (
      masterTemplate: Template,
      selectedTemplates: Template[]
    ) => {
      setSelectedTemplates({ master: masterTemplate, subs: selectedTemplates });
      send('GENERATE_WORKFLOW');
    };

    return (
      <>
        {/* state.value */}
        {state.value === 'loadingTemplates' ? (
          <OnePaneLayout>
            <SpinnerWithLegend legend="Loading templates from Linear. Please wait." />
          </OnePaneLayout>
        ) : null}

        {state.value === 'creatingWorkflow' ? (
          <OnePaneLayout>
            <SpinnerWithLegend legend="Creating issues in Linear. DO NOT close this tab." />
          </OnePaneLayout>
        ) : null}

        {state.value === 'showWorkflowCreator' && templates ? (
          <UnitWorkflowCreator
            unit={unit}
            templates={templates}
            onCreateWorkflow={handleCreateWorkflow}
            onCancel={showSwitch.off}
          />
        ) : null}
      </>
    );
  }
);
