import { IElement, IndexedElement } from '@tikka/basic-types';
import { fastSortOnIntKey } from './misc/fast-sort-on-int-key';
import { getChapterNoteOrderAnnotation } from './misc/editorial-string-utils';
import { EKind } from './element-kinds';
import { CreatePrecedence, Precedence } from '@tikka/elements/precedence';
import { StorageStructural } from './editorial-types';

// controls the script editor ordering of different element types anchored to the same word index.
// does not lend itself to ordering of multiple notes at the same script position
let elementSecondarySortOrder = CreatePrecedence([
  EKind.CHAPTER_COMPLETE,
  EKind.CHAPTER,
  // EKind.CHAPTER_SUMMARY,
  EKind.CHAPTER_NOTE,
  EKind.PASSAGE,
  // EKind.PASSAGE_QUESTION,
  EKind.PARAGRAPH,
  EKind.EXCERPT,
  EKind.SENTENCE,
  EKind.WORD,
  EKind.WORD_GROUP,
] as const);

export const elementKindToSortPrecedence =
  elementSecondarySortOrder.keyToPrecedenceLevel;

// allows sorting primarily by word index and secondarily by element kind
// export function elementSortProjection(element: IndexedElement) {
//   const wordIndex: number = element.address;
//   const kindPrecedence: number = elementKindToSortPrecedence[element.kind];
//   // multiply the word index by 16 then add in the element kind ordering
//   // assumes the kind list size is less than 16
//   return (wordIndex << 4) | kindPrecedence;
// }

export function sortElements(elements: IElement[]) {
  const sortPrecedence = elementSecondarySortOrder;
  const precedenceMap = sortPrecedence.keyToPrecedenceLevel;
  // allows sorting primarily by word index and secondarily by element kind
  const sortProjection = (element: IndexedElement) => {
    const wordIndex: number = element.address;
    const kindPrecedence: number = precedenceMap[element.kind];
    // multiply the word index by 16 then add in the element kind ordering
    // assumes the kind list size is less than 16
    return (wordIndex << 4) | kindPrecedence;
  };

  fastSortOnIntKey(elements, sortProjection);
  sortChapterNotes(elements);
}

export function sortArrayRangeInPlace<T>(
  arr: T[],
  rangeBegin: number,
  rangeEnd: number,
  comparer: (a: T, b: T) => number
) {
  for (let i = rangeBegin; i < rangeEnd; i++) {
    for (let j = i + 1; j <= rangeEnd; j++) {
      if (comparer(arr[i], arr[j]) > 0) {
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
    }
  }
}

export function subSortKind(
  elements: IElement[],
  kind: EKind,
  comparer: (a: any, b: any) => number
) {
  let rangeBegin = 0;
  let rangeEnd = 0;
  while (rangeBegin < elements.length) {
    if (elements[rangeBegin].kind === kind) {
      rangeEnd = rangeBegin + 1;
      while (rangeEnd < elements.length && elements[rangeEnd].kind === kind) {
        rangeEnd++;
      }
      rangeEnd--;
      sortArrayRangeInPlace(elements, rangeBegin, rangeEnd, comparer);
      rangeBegin = rangeEnd;
    }
    rangeBegin++;
  }
}

function sortChapterNotes(elements: IElement[]) {
  const comparer = (aElement: any, bElement: any) => {
    const a: StorageStructural = aElement;
    const b: StorageStructural = bElement;
    const aOrder = getChapterNoteOrderAnnotation(a.content.text);
    const bOrder = getChapterNoteOrderAnnotation(b.content.text);
    if (!aOrder && !bOrder) {
      return a.timestamp - b.timestamp;
    }
    if (aOrder && bOrder) {
      return aOrder < bOrder ? -1 : 1;
    }
    return aOrder ? -1 : 1; // place annotated notes first
  };

  subSortKind(elements, EKind.CHAPTER_NOTE, comparer);
}
