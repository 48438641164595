import * as React from 'react';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { styled } from '@naan/stitches.config';

const TextWrapper = styled('div', {
  whiteSpace: 'pre-wrap',
});

export const NormalBody = () => {
  const model = useSamosaModel();

  return <TextWrapper>{model.referenceText}</TextWrapper>;
};

export const TextAreaBody = () => {
  const model = useSamosaModel();
  const contentRows = Math.floor(model.referenceScript.elements.length * 2);
  const rows = Math.min(20, contentRows);
  return (
    // @armando, is there easy way to make this fill dialog panel?
    // @joseph - I can't find where this is used
    <textarea value={model.referenceText} readOnly rows={rows} cols={90} />
  );
};
