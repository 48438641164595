import { Alert } from '@masala-lib/misc/alert-messages';
import { ElementId, IDTOfET } from '@tikka/basic-types';
import { EditorElement } from '@masala-lib/editorial-types';
import { MutationActions } from '@masala-lib/editorial/db/mutation-actions';
import { Perms } from '@masala-lib/editorial/db/permissions';
import { scriptEditorModel, auth, alertMessages } from './app-root';

export class ScriptEditorMutationActions extends MutationActions {
  // constructor() {
  //   super();
  // }

  get auth() {
    return auth;
  }

  get alerts() {
    return alertMessages;
  }

  elementWillUpdate(id: ElementId) {
    scriptEditorModel.elementWillUpdate(id as IDTOfET<EditorElement>);
  }

  checkPermissions(action?: any) {
    super.checkPermissions(action);
    // TODO
    if (!this.auth.appUser) {
      console.log('ALERT: action requires login');
      this.alerts.add({ ...Alert, text: 'action requires login' }); //, level: 3 });
      throw Error('checkPermission: action requires login');
    }

    if (!scriptEditorModel.writeAccessEnabled) {
      console.log('ALERT: action requires write access');
      this.alerts.add({ ...Alert, text: 'episode is read only' }); //, level: 3 });
      throw Error('checkPermission: action requires write access');
    }

    if (action === Perms.STANDARD) {
      if (!scriptEditorModel.standardAccessEnabled) {
        console.log('ALERT: action requires standard access');
        this.alerts.add({ ...Alert, text: 'insufficient access' }); //, level: 3 });
        throw Error('checkPermission: action requires standard access');
      }
    }
  }
}
