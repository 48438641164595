// this file gets sync'ed between:
//   [peer-proj]/src/masala-lib/platform/
//  browser:
//   [masala-lib]/src/masala-lib/platform/
//  node:
//   [masala-lib]/node-src/masala-lib/platform/

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { deploymentConfig } from '@masala-lib/deployment-config';
import { Firestore, FirebaseStorage } from './firebase-types';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL?: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

// for now just stuff the various firestore project credentials here.
// easier to splitting out to separate ENV configs
const projectConfigs: { [index: string]: FirebaseConfig } = {
  // currently mingled with v2 testing
  masalaV1: {
    apiKey: 'AIzaSyCk7qf6Qbsq2oRwCel1g08st-su4pYDB_0',
    authDomain: 'jiveworld.firebaseapp.com',
    databaseURL: 'https://jiveworld.firebaseio.com',
    projectId: 'jiveworld',
    storageBucket: 'jiveworld.appspot.com',
    messagingSenderId: '903123262392',
    appId: '1:903123262392:web:3daaccb9ba95b3e4cf0d4d',
  },
  // joseph's personal dev sandbox
  jfedev: {
    apiKey: 'AIzaSyBzqHG3yn82Xj9aMoNyMo6f0LdODIfX02U',
    authDomain: 'jw-jfedev.firebaseapp.com',
    projectId: 'jw-jfedev',
    storageBucket: 'jw-jfedev.appspot.com',
    messagingSenderId: '692282509209',
    appId: '1:692282509209:web:0c6f2e3286f8f008a93508',
  },
  devtest: {
    apiKey: 'AIzaSyDcOnje0Oye_XJHshCwSS7XbSUfw6BMW38',
    authDomain: 'jw-devtest-v2.firebaseapp.com',
    projectId: 'jw-devtest-v2',
    storageBucket: 'jw-devtest-v2.appspot.com',
    messagingSenderId: '835803293570',
    appId: '1:835803293570:web:eded428d4cd627d11b519b',
  },
  editorial: {
    apiKey: 'AIzaSyAWgStAeTi2xpuWK6GkDZGMavT36RC0V9Y',
    authDomain: 'jw-editorial.firebaseapp.com',
    projectId: 'jw-editorial',
    storageBucket: 'jw-editorial.appspot.com',
    messagingSenderId: '167705078002',
    appId: '1:167705078002:web:365dd792864dcb661c486b',
  },
};

const config: FirebaseConfig =
  projectConfigs[deploymentConfig.firebaseConfigKey];

function initFirebase(): void {
  firebase.initializeApp(config);
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
    experimentalForceLongPolling: true,
  }); // todo: ideally shouldn't be necessary
  firebase
    .firestore()
    .enablePersistence()
    .catch(function (err) {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}

initFirebase();

export const db: Firestore = firebase.firestore();

// web-side gcs is probably more hassle than value
// export const storage: FirebaseStorage = firebase.storage();

export const fieldValueDelete = () => firebase.firestore.FieldValue.delete();
export const fieldValueArrayUnion = (...elements: any[]) =>
  firebase.firestore.FieldValue.arrayUnion(...elements);
// not sure how to import/reference this, but just '{merge: true}' seemed to work
// export const setOptionsMerge = () => firebase.firestore.SetOptions.merge();

// // google cloud storage interface
// // https://firebase.google.com/docs/storage/web/start
// export const storage = getStorage(firebaseApp);

export { firebase };
