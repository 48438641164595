import {
  IElement,
  IntervalIndexed,
  IntervalTimed,
  WordElement,
} from '@tikka/basic-types';
import { BreakWordMap } from '../editorial-types-aside';

export type FatElement = IElement & IntervalTimed & IntervalIndexed;

export function adjustTimesAndAddresses(
  elements: FatElement[],
  words: WordElement[],
  {
    expandIntoGaps = false,
    breakWordMap = null,
    audioEndTime = 0, // full track duration
  }: {
    expandIntoGaps?: boolean;
    breakWordMap?: BreakWordMap;
    audioEndTime?: number;
  }
): void {
  for (let i = 0; i < elements.length; i++) {
    const isLast = i + 1 === elements.length;
    const element = elements[i];
    const nextElement = isLast ? null : elements[i + 1];
    const firstWordIndex = element.address;
    const lastWordIndex = isLast ? words.length - 1 : nextElement.address - 1;
    var time: number;
    var endTime: number;
    if (expandIntoGaps) {
      time = priorBreakpointTime(words, firstWordIndex, breakWordMap);
      endTime = followingBreakpointTime(
        words,
        lastWordIndex,
        breakWordMap,
        audioEndTime
      );
    } else {
      time = words[firstWordIndex]?.time;
      endTime = words[lastWordIndex]?.time;
      if (!time || !endTime) {
        console.error(
          `adjustTimesAndAddresses - failed to find word data for indexes[${firstWordIndex},${lastWordIndex}] - head: ${JSON.stringify(
            element
          )}}]`
        );
      }
    }

    // collect the properties which need changing
    const spanData = {
      endAddress: lastWordIndex,
      time,
      endTime,
    };
    // transform the 'head' into a 'span', but retain id, so that the translation lookup still works
    Object.assign(element, spanData);
  }
}

function priorBreakpointTime(
  words: WordElement[],
  wordIndex: number,
  breakWordMap: BreakWordMap
) {
  if (wordIndex === 0) {
    return 0;
  } else {
    const matchedBreakTime = breakWordMap.get(wordIndex);
    if (matchedBreakTime) {
      // console.log(
      //   `matched prior breaktime: ${matchedBreakTime} for wordIndex: ${wordIndex}`
      // );
      return matchedBreakTime;
    } else {
      return Math.floor(
        (words[wordIndex].time + words[wordIndex - 1].endTime) / 2
      );
    }
  }
}

function followingBreakpointTime(
  words: WordElement[],
  wordIndex: number,
  breakWordMap: BreakWordMap,
  endAudioTime: number
) {
  if (wordIndex >= words.length - 1) {
    // return wordElements[wordIndex].endTime; // TODO: don't cut end of audio
    return endAudioTime;
  } else {
    const matchedBreakTime = breakWordMap.get(wordIndex + 1);
    if (matchedBreakTime) {
      // console.log(
      //   `matched following breaktime: ${matchedBreakTime} for wordIndex: ${wordIndex}`
      // );
      return matchedBreakTime;
    } else {
      return Math.floor(
        (words[wordIndex].endTime + words[wordIndex + 1].time) / 2
      );
    }
  }
}
