import { searchSorted } from '@tikka/intervals/search-sorted';
import levenshtein from 'js-levenshtein';
import { isEmpty, isNull, zip } from 'lodash';
import {
  GapPair,
  InfoTag,
  MatchInfo,
  MatchResult,
  PositionPair,
} from './match-result';
import { Section } from './sections';
import {
  audioRegionSpanIsContinuousAudio,
  AudioRegionsSpan,
  audioRegionsSpanFindAudioIntervalContains,
  audioRegionsSpanIntervalIsSilence,
  spanGetWords,
} from './spans';
import { Slice } from './timestamper-types';
import {
  compare,
  comparePrimitives,
  mapSetDefault,
  sliceWith,
} from './util_ts';

export function levenshteinRatio(a: string, b: string) {
  const distance = levenshtein(a, b);
  const length = Math.max(a.length, b.length);
  return (length - distance) / length;
}

export type SeqMatcherOptions = {
  seqMinLength: number;
  seqMinRatio: number;
  seqMatchEnds: boolean;
  seqMatchLongest: boolean;
  seqLevenshteinCutoff: number;
};

export const seqMatcherOpts: SeqMatcherOptions = {
  seqMinLength: 4,
  seqMinRatio: 0.3,
  seqMatchEnds: true,
  seqMatchLongest: false,
  seqLevenshteinCutoff: 0.0,
};

export type LongWordMatcherOptions = {
  minWordLength: number;
  prefixTest: number;
  maxMatch: number;
};

export const longWordMatcherOpts: LongWordMatcherOptions = {
  minWordLength: 8,
  prefixTest: 6,
  maxMatch: 0,
};

export const spanishCommonList = [
  'un',
  'y',
  'el',
  'de',
  'es',
  'la',
  'a',
  'se',
  'los',
  'las',
  'una',
  'uno',
  'unos',
  'que',
  'yo',
  'muy',
  'mi',
  'hay',
  'no',
  'por',
  'en',
  'al',
  'lo',
  'le',
  'esto',
  'esta',
  'con',
  'para',
  'su',
  'te',
  'si',
  'ser',
  'bien',
  'yo',
  'del',
  'todo',
  'soy',
  'pero',
  'eso',
  'vamos',
  'estoy',
  'tengo',
  'ahora',
  'algo',
  'tenda',
  'nada',
  'nos',
  'quando',
  'porque',
  'como',
  'entonces',
  'estaban',
  'estaba',
];

export const spanishCommon = new Set(spanishCommonList);

export const englishCommonList = [
  'i',
  'you',
  'we',
  'us',
  'our',
  'they',
  'them',
  'and',
  'the',
  'or',
  'if',
  'in',
  'of',
  'that',
  'was',
  'his',
  'her',
  'she',
  'he',
  'that',
  'it',
  'was',
  'for',
  'that',
  'had',
  'not',
  'be',
  'on',
  'this',
  'which',
  'have',
  'from',
  'but',
  'all',
  'were',
  'my',
  'are',
  'me',
  'one',
  'their',
  'so',
  'an',
  'said',
  'would',
  'been',
  'will',
  'by',
  'has',
  'any',
  'do',
  'your',
  'what',
  'has',
  'man',
  'could',
  'other',
  'than',
  'then',
  'some',
  'very',
  'about',
];

export const englishCommon = new Set(englishCommonList);

export const germanCommonList = [
  'ich',
  'ist',
  'nicht',
  'sie',
  'du',
  'das',
  'die',
  'es',
  'und',
  'der',
  'zu',
  'ein',
  'in',
  'wir',
  'mir',
  'mit',
  'was',
  'den',
  'mich',
  'auf',
  'dass',
  'er',
  'eine',
  'des',
  'wurde',
  'als',
  'fur',
  'dem',
  'sich',
  'an',
  'war',
  'nach',
  'am',
  'auch',
  'aus',
  'bei',
  'bis',
  'zum',
  'einer',
  'durch',
  'einem',
  'werden',
  'sind',
  'zur',
  'wird',
  'uber',
  'einen',
  'um',
  'oder',
  'wurden',
  'unter',
  'wie',
  'jahr',
  'seine',
  'vor',
  'vom',
  'nur',
  'ab',
  'seit',
  'seiner',
  'sowie',
  'noch',
  'sein',
  'hatte',
  'zwei',
  'jahre',
  'diese',
  'dieser',
  'aber',
  'zwischen',
  'hat',
  'man',
  'haben',
];

export const germanCommon = new Set(germanCommonList);

export const portugueseCommonList = [
  'que',
  'nao',
  'de',
  'um',
  'para',
  'eu',
  'se',
  'me',
  'uma',
  'esta',
  'com',
  'do',
  'por',
  'te',
  'os',
  'bem',
  'em',
  'ele',
  'isso',
  'mas',
  'da',
  'como',
  'no',
  'sim',
  'as',
  'mais',
  'na',
  'meu',
  'voce',
  'aqui',
  'muito',
  'foi',
  'estou',
  'vamos',
  'ela',
  'fazer',
  'vai',
  'isto',
  'ja',
  'tem',
  'so',
  'minha',
  'nos',
  'ser',
  'tudo',
  'ao',
  'tenho',
  'vou',
  'sei',
  'agora',
  'ha',
  'la',
  'tu',
  'quando',
  'porque',
  'estas',
  'quem',
  'onde',
  'nada',
  'ter',
];

export const portugueseCommon = new Set(portugueseCommonList);

export const danishCommonList = [
  'er',
  'jeg',
  'det',
  'du',
  'ikke',
  'at',
  'en',
  'og',
  'har',
  'vi',
  'til',
  'pa',
  'hvad',
  'mig',
  'med',
  'de',
  'den',
  'for',
  'der',
  'sa',
  'dig',
  'han',
  'kan',
  'af',
  'vil',
  'var',
  'her',
  'et',
  'skal',
  'ved',
  'men',
  'om',
  'nu',
  'som',
  'ja',
  'min',
  'nej',
  'noget',
  'hun',
  'vaere',
  'bare',
  'kom',
  'din',
  'hvor',
  'os',
  'dem',
  'hvis',
  'ud',
  'ma',
  'fra',
  'se',
  'godt',
  'have',
  'ville',
  'gor',
  'lige',
  'okay',
  'alle',
  'op',
  'lad',
  'hvorfor',
  'tror',
  'sig',
  'tak',
  'hvordan',
  'kommer',
  'fa',
  'kunne',
  'gore',
  'meget',
];

export const danishCommon = new Set(danishCommonList);

export const common: { [index: string]: Set<string> } = {
  danish: danishCommon,
  english: englishCommon,
  german: germanCommon,
  portuguese: portugueseCommon,
  spanish: spanishCommon,
};

export function matchSequences(
  a: any[],
  b: any[],
  compare: ([a, b]: [any, any]) => boolean
) {
  let i = 0;
  // TODO while i < a.Length && i < b.Length do
  while (i < a.length ? i < b.length : false) {
    const equal = compare([a[i], b[i]]);
    if (!equal) {
      break;
    } else {
      i = i + 1;
    }
  }
  return i;
}

export function cueTimeToInterval(
  timestamp: number,
  audioRegions: AudioRegionsSpan
): [number, number] {
  const [startTime, endTime, regionIdx] =
    audioRegionsSpanFindAudioIntervalContains(audioRegions, timestamp);
  // const startTime = patternInput[0] | 0;
  // const regionIdx = patternInput[2] | 0;
  // const endTime = patternInput[1] | 0;
  if (
    regionIdx == null ||
    !audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx)
  ) {
    return [timestamp, timestamp];
  } else {
    return [startTime, endTime];
  }
}

export function matchHeadTail(
  a: any[],
  b: any[],
  compare: ([a, b]: [any, any]) => boolean
): [Slice, Slice, number] {
  const shortestLen = Math.min(a.length, b.length);
  const forwardMatchLen = matchSequences(a, b, compare);
  if (forwardMatchLen === shortestLen) {
    const slice: Slice = [0, forwardMatchLen - 1];
    return [slice, slice, forwardMatchLen];
  }
  const reverseA = a.slice().reverse();
  const reverseB = b.slice().reverse();
  const reverseMatchLen = matchSequences(reverseA, reverseB, compare);
  const forward = forwardMatchLen > reverseMatchLen;
  const matchLen = forward ? forwardMatchLen : reverseMatchLen;
  if (forward) {
    return [[0, matchLen - 1], [0, matchLen - 1], matchLen];
  } else {
    return [
      [a.length - matchLen, a.length - 1],
      [b.length - matchLen, b.length - 1],
      matchLen,
    ];
  }
}

export function wordsIndex(words: string[]): Map<string, number[]> {
  const result = new Map<string, number[]>([]);
  // const arr = indexed(words);
  for (const [i, word] of words.entries()) {
    // const forLoopVar = arr[idx];
    // const word = forLoopVar[1];
    // const i = forLoopVar[0] | 0;
    // System_Array__$005B$005D$1_append_1505(
    mapSetDefault(result, word, []).push(i);
  }
  return result;
}

export function wordSpans(words: string[]): [number, number][] {
  let current = 0;
  const spans: [number, number][] = [];
  for (const word of words) {
    const length = word.length;
    // System_Array__$005B$005D$1_append_1505(spans, [
    spans.push([current, current + length - 1]);
    current = current + length;
  }
  return spans;
}

export function substringCount(needle: string, haystack: string): number {
  const loop = (count_mut: number, index_mut: number) => {
    // eslint-disable-next-line no-label-var
    loop: while (true) {
      const count = count_mut,
        index = index_mut;
      if (index >= haystack.length) {
        return count | 0;
      } else {
        const matchValue = haystack.indexOf(needle, index) | 0;
        if (matchValue === -1) {
          return count | 0;
        } else {
          const idx = matchValue | 0;
          count_mut = count + 1;
          index_mut = idx + 1;
          // eslint-disable-next-line no-extra-label
          continue loop;
        }
      }
    }
  };
  if (needle.length === 0) {
    return 0;
  } else {
    return loop(0, 0) | 0;
  }
}

export function underscoreJoin(a: string[]) {
  return a.join('_');
}

export function patternCount(a: string[], patternSlice: Slice): number {
  const matchStr = underscoreJoin(sliceWith(a, patternSlice));
  const contentStr = underscoreJoin(a);
  return substringCount(matchStr, contentStr);
}

export type LCSResult = {
  aPos: number;
  bPos: number;
  matchLen: number;
};

export function findLCS(a: any[], b: any[]): LCSResult {
  let matchLen = 0;
  const storage: [number, number, number][] = [];
  const aLen = a.length;
  const bLen = b.length;
  for (let i = 0; i < a.length; i++) {
    const ind = b.indexOf(a[i]);
    if (ind === -1) {
      continue;
    }
    let j = i;
    let k = ind;
    // TODO while j < aLen && k < bLen do
    while (j < aLen ? k < bLen : false) {
      if (b[k] === a[j]) {
        matchLen = matchLen + 1;
      } else {
        // System_Array__$005B$005D$1_append_1505(
        storage.push([matchLen, j - matchLen, k - matchLen]);
        matchLen = 0;
      }
      j = j + 1;
      k = k + 1;
    }
    // System_Array__$005B$005D$1_append_1505(
    storage.push([matchLen, j - matchLen, k - matchLen]);
    matchLen = 0;
  }
  if (!isEmpty(storage)) {
    // Array.sortInPlaceBy (fun (m:int []) -> -m.[0]) storage
    storage.sort((a, b) => comparePrimitives(-a[0], -b[0]));
    const result = storage[0];
    // return new LCSResult(result[1], result[2], result[0]);
    return {
      aPos: result[1],
      bPos: result[2],
      matchLen: result[0],
    };
  } else {
    return null;
  }
}

export function findLongestMatch(a: any[], b: any[]): [Slice, Slice, number] {
  const lcs = findLCS(a, b);
  if (lcs) {
    return [
      [lcs.aPos, lcs.aPos + lcs.matchLen - 1],
      [lcs.bPos, lcs.bPos + lcs.matchLen - 1],
      lcs.matchLen,
    ];
  } else {
    return [null, null, 0];
  }
}

export function checkMatchLength(
  a: any,
  matchLen: number,
  options: SeqMatcherOptions
): boolean {
  if (a.length === 0) {
    return false;
  } else {
    const matchRatio = matchLen / a.length;
    if (matchLen > options.seqMinLength) {
      return true;
    } else {
      return matchRatio > options.seqMinRatio;
    }
  }
}

export function tryMatch(
  a: any[],
  b: any[],
  options: SeqMatcherOptions
): [Slice, Slice] {
  const levenshteinMatch = ([str1, str2]: [string, string]) => {
    // const str1 = tupledArg[0];
    // const str2 = tupledArg[1];
    const score = levenshteinRatio(str1, str2);
    return score > options.seqLevenshteinCutoff;
  };
  const eqCompare = ([a, b]: [any, any]) => {
    // const a_1 = tupledArg_1[0];
    // const b_1 = tupledArg_1[1];
    return a === b;
  };
  const matchFunc =
    options.seqLevenshteinCutoff > 0.1 ? levenshteinMatch : eqCompare;
  if (options.seqMatchEnds) {
    const [aSlice, bSlice, matchLen] = matchHeadTail(a, b, matchFunc);
    // const matchLen = patternInput[2] | 0;
    // const bSlice = patternInput[1];
    // const aSlice = patternInput[0];
    if (checkMatchLength(a, matchLen, options)) {
      return [aSlice, bSlice];
    } else {
      return [null, null];
    }
  } else if (options.seqMatchLongest) {
    const [aSlice, bSlice, matchLen] = findLongestMatch(a, b);
    // const matchLen_1 = patternInput_1[2] | 0;
    // const bSlice_1 = patternInput_1[1];
    // const aSlice_1 = patternInput_1[0];
    if (checkMatchLength(a, matchLen, options)) {
      return [aSlice, bSlice];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}

export function wholeSlice(a: any[]): Slice {
  return [0, a.length - 1];
}

export function matchSectionWordSequences(
  options: SeqMatcherOptions,
  section: Section
): MatchResult {
  const transcriptWords = spanGetWords(section.transcript);
  const contentWords = spanGetWords(section.content);
  if (contentWords.length > 100) {
    return null;
  }
  const [contentSlice, transcriptSlice] = tryMatch(
    contentWords,
    transcriptWords,
    options
  );
  // const transcriptSlice = patternInput[1];
  // const contentSlice = patternInput[0];
  if (isNull(contentSlice)) {
    return null;
  }
  // return new MatchResult(
  //   'wordsequence',
  //   new MatchInfo(0, [new SlicePair(contentSlice, transcriptSlice)]),
  //   []
  // );

  return {
    matcherKind: 'wordsequence',
    matchInfo: {
      kind: 'SLICE_PAIRS',
      pairs: [{ contentSlice, transcriptSlice }],
    },
    infoTags: [],
  };
}

export function matchSectionCommonWordSequences(
  options: SeqMatcherOptions,
  language: string,
  section: Section
): MatchResult {
  const transcriptWords = spanGetWords(section.transcript);
  const contentWords = spanGetWords(section.content);
  const commonSet = common[language];
  if (contentWords.length > 25) {
    return null;
  }
  // let contentIdxs = [| for i, w in Array.indexed contentWords do if commonSet.has(w) then i |]
  const contentIdxs = [...contentWords.entries()]
    .filter(([i, w]) => commonSet.has(w))
    .map(([i, w]) => i);

  // const contentIdxs = Int32Array.from(
  //   delay(() =>
  //     collect(matchValue => {
  //       const w = matchValue[1];
  //       const i = matchValue[0] | 0;
  //       return commonSet.has(w) ? singleton(i) : empty();
  //     }, indexed(contentWords))
  //   )
  // );
  // let transcriptIdxs = [| for i, w in Array.indexed transcriptWords do if commonSet.has(w) then i |]
  const transcriptIdxs = [...transcriptWords.entries()]
    .filter(([i, w]) => commonSet.has(w))
    .map(([i, w]) => i);

  if (contentIdxs.length < 2 || transcriptIdxs.length < 2) {
    return null;
  }

  // const transcriptIdxs = Int32Array.from(
  //   delay(() =>
  //     collect(matchValue_1 => {
  //       const w_1 = matchValue_1[1];
  //       const i_1 = matchValue_1[0] | 0;
  //       return commonSet.has(w_1) ? singleton(i_1) : empty();
  //     }, indexed(transcriptWords))
  //   )
  // );
  // let seq1 = [| for i in contentIdxs -> contentWords.[i]|]
  const seq1 = contentIdxs.map(i => contentWords[i]);
  // const seq1 = Array.from(
  //   delay(() => map(i_2 => contentWords[i_2], contentIdxs))
  // );
  // let seq2 = [| for i in transcriptIdxs -> transcriptWords.[i]|]
  const seq2 = transcriptIdxs.map(i => transcriptWords[i]);
  // const seq2 = Array.from(
  //   delay(() => map(i_3 => transcriptWords[i_3], transcriptIdxs))
  // );
  const [contentSlice, transcriptSlice] = tryMatch(seq1, seq2, options);
  // const transcriptSlice = patternInput[1];
  // const contentSlice = patternInput[0];
  if (isNull(contentSlice) || contentSlice[1] - contentSlice[0] < 1) {
    return null;
  }
  // partial... [| for a, b in Array.zip (contentIdxs.slice(contentSlice)) (transcriptIdxs.slice(transcriptSlice)) -> {contentPos=a; transcriptPos=b} |]
  const positionPairs = zip(
    sliceWith(contentIdxs, contentSlice),
    sliceWith(transcriptIdxs, transcriptSlice)
  ).map(([a, b]) => {
    return {
      contentPos: a,
      transcriptPos: b,
    };
  });
  const matchInfo: MatchInfo = {
    kind: 'POSITION_PAIRS',
    pairs: positionPairs,
  };
  return {
    matcherKind: 'commonwords',
    matchInfo,
    infoTags: [],
  };
}

export function testWordSetMatchingOrder(
  section: Section,
  contentIndex: Map<string, number[]>,
  transcriptIndex: Map<string, number[]>,
  testWords: string[]
): [boolean, PositionPair[]] {
  const transcriptWords = spanGetWords(section.transcript);
  const contentWords = spanGetWords(section.content);
  let failedTest = false;
  const contentPositions: number[] = [];
  const transcriptPositions: number[] = [];
  const workingPairs: PositionPair[] = [];
  for (const word of testWords) {
    // System_Array__$005B$005D$1_extend_5975E3(
    contentPositions.push(...contentIndex.get(word));
    // System_Array__$005B$005D$1_extend_5975E3(
    transcriptPositions.push(...transcriptIndex.get(word));
  }
  contentPositions.sort(compare);
  transcriptPositions.sort(compare);
  for (const [contentPos, transcriptPos] of zip(
    contentPositions,
    transcriptPositions
  )) {
    // const forLoopVar = arr[idx_1];
    // const transcriptPos = forLoopVar[1] | 0;
    // const contentPos = forLoopVar[0] | 0;
    if (contentWords[contentPos] !== transcriptWords[transcriptPos]) {
      failedTest = true;
      break;
    }
    // System_Array__$005B$005D$1_append_1505(
    workingPairs.push({
      contentPos,
      transcriptPos,
    });
  }
  return [failedTest, workingPairs];
}

export function matchSectionLongWords(
  options: LongWordMatcherOptions,
  section: Section
): MatchResult {
  const transcript = section.transcript;
  const content = section.content;
  const contentWordsStr = underscoreJoin(spanGetWords(content));
  const contentIndex = wordsIndex(spanGetWords(content));
  const transcriptIndex = wordsIndex(spanGetWords(transcript));
  const minLen = options.minWordLength;
  let maxMatch = options.maxMatch;
  if (maxMatch === 0) {
    // TODO
    maxMatch = ~~(spanGetWords(content).length / 2);
  }
  if (maxMatch === 0) {
    return null;
  }
  // let sortedContentWords = [| for word in contentIndex.keys() do if word.Length >= minLen then word|]
  const sortedContentWords = [...contentIndex.keys()].filter(
    word => word.length >= minLen
  );
  // const sortedContentWords = Array.from(
  //   delay(() =>
  //     collect(
  //       word => (word.length >= minLen ? singleton(word) : empty()),
  //       contentIndex.keys()
  //     )
  //   )
  // );
  if (isEmpty(sortedContentWords)) {
    return null;
  }
  // Array.sortInPlaceBy (fun (w:string) -> -w.Length ) sortedContentWords
  sortedContentWords.sort((a, b) => comparePrimitives(-a.length, -b.length));
  let currentWordLen = sortedContentWords[0].length;
  const words: string[] = [];
  let pairs: PositionPair[] = [];
  let workingWords: string[] = [];
  let completedSome = false;
  let matched = 0;
  for (const word of sortedContentWords) {
    // const word_1 = sortedContentWords[idx];
    // TODO if word.Length <> currentWordLen || matched >= maxMatch then
    if (word.length !== currentWordLen ? true : matched >= maxMatch) {
      currentWordLen = word.length;
      const [failedTest, newPairs] = testWordSetMatchingOrder(
        section,
        contentIndex,
        transcriptIndex,
        [...words, ...workingWords]
      );
      // const newPairs = patternInput[1];
      // const failedTest = patternInput[0];
      if (!failedTest) {
        // System_Array__$005B$005D$1_extend_5975E3(words, workingWords);
        words.push(...workingWords);
        pairs = newPairs;
        completedSome = true;
      }
      workingWords = [];
      // TODO if failedTest && completedSome then
      if (failedTest ? completedSome : false) {
        break;
      }
      // TODO if completedSome && matched >= maxMatch then
      if (completedSome ? matched >= maxMatch : false) {
        break;
      }
    }
    const contentWordPositions = contentIndex.get(word);
    const transcriptWordPositions = transcriptIndex.get(word);
    if (isEmpty(transcriptWordPositions)) {
      continue;
    }
    if (contentWordPositions.length !== transcriptWordPositions.length) {
      continue;
    }
    const wordPrefix = word.slice(0, options.prefixTest + 1);
    if (
      contentWordPositions.length !==
      substringCount(wordPrefix, contentWordsStr)
    ) {
      continue;
    }
    // System_Array__$005B$005D$1_append_1505(workingWords, word);
    workingWords.push(word);
    matched = matched + 1;
  }
  if (!isEmpty(workingWords)) {
    const [failedTest, newPairs] = testWordSetMatchingOrder(
      section,
      contentIndex,
      transcriptIndex,
      [...words, ...workingWords]
    );
    // const newPairs_1 = patternInput_1[1];
    // const failedTest_1 = patternInput_1[0];
    if (!failedTest) {
      pairs = newPairs;
    }
  }
  if (isEmpty(pairs)) {
    return null;
  }
  return {
    matcherKind: 'longwords',
    matchInfo: {
      kind: 'POSITION_PAIRS',
      pairs,
    },
    infoTags: [],
  };
}

export function matchSectionInterpolate(
  allowSilences: boolean,
  section: Section
): MatchResult {
  const infoTags: InfoTag[] = [];
  const silences = !audioRegionSpanIsContinuousAudio(section.audioRegions);
  // TODO if silences && not allowSilences then
  if (silences ? !allowSilences : false) {
    return null;
  }
  const contentWords = spanGetWords(section.content);
  const spans = wordSpans(contentWords);
  const startTime = section.startTime;
  const endTime = section.endTime;
  const duration = endTime - startTime;
  const pos = section.content.data.globalPosition[0];
  const infoTag: InfoTag = {
    tag: 'INFO_TAG',
    globalPosition: pos,
    interval: { startTime, endTime },
    kind: '',
    name: '',
    message: null,
  };
  // TODO if duration > 1000 && contentWords.Length > 1 && not silences then
  if ((duration > 1000 ? contentWords.length > 1 : false) ? !silences : false) {
    // infoTags.append({ infoTag with kind="warn"; name="long"})
    infoTags.push({ ...infoTag, kind: 'warn', name: 'long' });
    // System_Array__$005B$005D$1_append_1505(
    //   infoTags,
    //   new InfoTag(
    //     infoTag.globalPosition,
    //     infoTag.interval,
    //     'warn',
    //     'long',
    //     infoTag.message
    //   )
    // );
  }
  const [_, lastCharPos] = spans[spans.length - 1];
  // ... spans[System_Array__$005B$005D$1_get_lastIndex(spans)][1] | 0;
  const charCount = lastCharPos + 1;
  const quanta = charCount > 0 ? duration / charCount : 0;
  // TODO if quanta < 15.0 && not silences then
  if (quanta < 15 ? !silences : false) {
    // infoTags.append({ infoTag with kind="warn"; name="short"})
    infoTags.push({ ...infoTag, kind: 'warn', name: 'short' });
    // System_Array__$005B$005D$1_append_1505(
    //   infoTags,
    //   new InfoTag(
    //     infoTag.globalPosition,
    //     infoTag.interval,
    //     'warn',
    //     'short',
    //     infoTag.message
    //   )
    // );
  }
  if (silences) {
    // infoTags.append({ infoTag with kind="warn"; name="silences"})
    infoTags.push({ ...infoTag, kind: 'warn', name: 'silences' });
    // System_Array__$005B$005D$1_append_1505(
    //   infoTags,
    //   new InfoTag(
    //     infoTag.globalPosition,
    //     infoTag.interval,
    //     'warn',
    //     'silences',
    //     infoTag.message
    //   )
    // );
  }
  // infoTags.append({ infoTag with kind="interpolate"; name="interpolate"})
  infoTags.push({ ...infoTag, kind: 'interpolate', name: 'interpolate' });
  // System_Array__$005B$005D$1_append_1505(
  //   infoTags,
  //   new InfoTag(
  //     infoTag.globalPosition,
  //     infoTag.interval,
  //     'interpolate',
  //     'interpolate',
  //     infoTag.message
  //   )
  // );
  const b = startTime;
  // let intervals = [|for starts, ends in spans -> {startTime=int(float(b) + quanta * float(starts)); endTime=int(float(b) + quanta * float(ends + 1))}|]
  const intervals = spans.map(([starts, ends]) => {
    // TODO
    return {
      startTime: ~~(b + quanta * starts),
      endTime: ~~(b + quanta * (ends + 1)),
    };
  });
  // const intervals = Array.from(
  //   delay(() =>
  //     collect(matchValue => {
  //       const starts = matchValue[0] | 0;
  //       const ends = matchValue[1] | 0;
  //       return singleton(
  //         new TimeInterval(~~(b + quanta * starts), ~~(b + quanta * (ends + 1)))
  //       );
  //     }, spans)
  //   )
  // );
  return {
    matcherKind: 'interpolate',
    matchInfo: {
      kind: 'SLICE_INTERVALS_PAIRS',
      pairs: [
        { contentSlice: wholeSlice(contentWords), intervals, terminal: true },
      ],
    },
    infoTags,
  };
}

export function matchSectionContentCues(section: Section): MatchResult {
  const contentCues = section.contentCues;
  const audioRegions = section.audioRegions;
  const cueTimestamps = contentCues.data.timestamp;
  const cueContentGlobalPositions = contentCues.data.globalPosition;
  const gapPairs: GapPair[] = [];
  // const arr = indexed(cueTimestamps);
  for (const [cueIdx, ts] of cueTimestamps.entries()) {
    // const forLoopVar = arr[idx];
    // const ts = forLoopVar[1] | 0;
    // const cueIdx = forLoopVar[0] | 0;
    const [startTime, endTime] = cueTimeToInterval(ts, audioRegions);
    // const startTime = patternInput[0] | 0;
    // const endTime = patternInput[1] | 0;
    const contentIdx = searchSorted(
      section.content.data.globalPosition,
      cueContentGlobalPositions[cueIdx],
      false
    );
    // System_Array__$005B$005D$1_append_1505(
    gapPairs.push({
      contentPos: contentIdx,
      gapInterval: { startTime, endTime },
    });
  }
  if (isEmpty(gapPairs)) {
    return null;
  }
  return {
    matcherKind: 'cue',
    matchInfo: {
      kind: 'GAP_PAIRS',
      pairs: gapPairs,
    },
    infoTags: [],
  };
}

export function matchSectionTrimSilence(section: Section): MatchResult {
  const audioRegions = section.audioRegions;
  const contentWords = spanGetWords(section.content);
  const epsilon = 25;
  let gapPairs: GapPair[] = [];
  const testStartTime = section.startTime + epsilon;
  const testEndTime = section.endTime - epsilon;

  let [startTime, endTime, regionIdx] =
    audioRegionsSpanFindAudioIntervalContains(audioRegions, testStartTime);
  // const startTime = patternInput[0] | 0;
  // const regionIdx = patternInput[2] | 0;
  // const endTime = patternInput[1] | 0;
  if (
    regionIdx != null &&
    audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx)
  ) {
    if (section.startTime >= endTime) {
      return null;
    }
    // System_Array__$005B$005D$1_append_1505(
    gapPairs.push({
      contentPos: 0,
      gapInterval: { startTime: section.startTime, endTime },
    });
  }
  [startTime, endTime, regionIdx] = audioRegionsSpanFindAudioIntervalContains(
    audioRegions,
    testEndTime
  );
  // const startTime_1 = patternInput_1[0] | 0;
  // const regionIdx_1 = patternInput_1[2] | 0;
  // const endTime_1 = patternInput_1[1] | 0;
  if (
    regionIdx != null &&
    audioRegionsSpanIntervalIsSilence(audioRegions, regionIdx)
  ) {
    if (startTime >= section.endTime) {
      return null;
    }
    // System_Array__$005B$005D$1_append_1505(
    gapPairs.push({
      contentPos: contentWords.length,
      gapInterval: { startTime, endTime: section.endTime },
    });
  }
  if (isEmpty(gapPairs)) {
    return null;
  }
  if (gapPairs.length === 2) {
    if (gapPairs[1].gapInterval.startTime <= gapPairs[0].gapInterval.endTime) {
      gapPairs = [gapPairs[0]];
    }
  }
  const infoTags: InfoTag[] = [];
  const pos = section.content.data.globalPosition[0];
  for (const pair of gapPairs) {
    // System_Array__$005B$005D$1_append_1505(
    infoTags.push({
      tag: 'INFO_TAG',
      globalPosition: pos + pair.contentPos,
      interval: pair.gapInterval,
      kind: 'trim',
      name: 'trim',
      message: null,
    });
  }
  return {
    matcherKind: 'trim',
    matchInfo: { kind: 'GAP_PAIRS', pairs: gapPairs },
    infoTags,
  };
}
