/* .stack > * + * {
  margin-top: 1.5rem;
} */

import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  & > * + * {
    margin-top: 24px;
  }
`;

interface Props {
  children: React.ReactNode;
}

export const Stack = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
