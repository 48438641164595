import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { Unit } from '@masala-lib/catalog/models/unit';
// interface RouteParams {
//   id?: string;
// }

export function useUnit() {
  // const { id = null } = useParams<RouteParams>();
  const { id } = useParams<{ id: string }>();

  const manager = UnitManager.getInstance();
  const [currentUnit, setCurrentUnit] = React.useState<Unit>(null);

  (window as any).currentUnit = currentUnit;

  useEffect(() => {
    setCurrentUnit(manager.fetchById(id));
  }, [manager, id]);

  return currentUnit;
}
