import { isString } from 'lodash';
import moment from 'moment';

interface FormatDateOptions {
  placeholder: string;
  format: string;
}

export function formatDate(date: number | string, options?: FormatDateOptions) {
  const { placeholder = 'N/A', format = 'll' } = options ?? {};
  if (!date) {
    return placeholder;
  }

  return moment
    .unix(isString(date) ? parseInt(date, 10) : date)
    .utc()
    .format(format);
}
