import {
  IDTOfET,
  KTOfET,
  universalETOf,
  universalIDTOf,
  ElementList as BElementList,
  SimpleElementList as _SimpleElementList,
  ExtractTimedET,
} from '@tikka/basic-types';
import {
  ChaatElement,
  ChaatElementList,
  ChaatSentence,
  ChaatStructural,
  ChaatWordElement,
  CreateChaatElementList,
} from './editorial-types';
import { ElementNodeType } from '@tikka/elements/element-tree';
import { Tracker } from '@tikka/tracking/tracker';

export type Element = ChaatElement;
export type Sentence = ChaatSentence;
export type Structural = ChaatStructural;
export type Word = ChaatWordElement;

export type ElementId = IDTOfET<Element>;
export type ElementKind = KTOfET<Element>;

export type IDTOf<T extends ElementKind | Element> = universalIDTOf<Element, T>;
export type ETOf<T extends ElementId | ElementKind> = universalETOf<Element, T>;

export type ElementList<T extends Element> = ChaatElementList<T>;

export type ElementNode = ElementNodeType<Element, Element>;
export type ElementTracker<ET extends ExtractTimedET<Element>> = Tracker<
  IDTOfET<ET>,
  ET
>;

export const CreateElementList = CreateChaatElementList;
export const SimpleElementList: <ET extends Element>(
  elements: ET[]
) => BElementList<ET> = _SimpleElementList as any;
