import React, { useEffect } from 'react';
import { useState } from 'react';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';
import { SoundbiteEdition } from '@masala-lib/catalog/models/soundbite-edition';
import { useParams } from 'react-router-dom';

export const SoundbiteEditionDataScreen = () => {
  const { id } = useParams<{ id: string }>();

  const [data, setData] = useState<object>({ status: 'loading...' });

  useEffect(() => {
    SoundbiteEditionManager.getInstance()
      .loadById(id)
      .then(async (item: SoundbiteEdition) => {
        if (item) {
          setData(await item.buildCatalogData());
        } else {
          setData({ error: `Failed to load excerpt[${id}]` });
        }
      });
  }, [id]);

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
