import classNames from 'classnames';
import { isFunction } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.table`
  --link-color: var(--color-gray800);
  --cell-width: auto;

  width: 100%;
  border-collapse: collapse;
  color: var(--color-gray700);

  & > .empty-view {
    background: var(--color-blue50);
    color: var(--color-gray500);
    padding: 24px;
    border-radius: 12px;
    text-align: center;
  }

  tbody > tr {
    border-bottom: 1px solid var(--color-gray100);
    &.has-click-handler:hover {
      cursor: pointer;
      background-color: var(--color-yellow50);
      --link-color: var(--color-teal600);
    }
    &:nth-child(odd) {
      background-color: var(--color-gray20);
    }
  }
  th {
    text-align: left;
    padding: 8px 16px;
    font-family: Roboto;
    color: var(--color-blue300);
    font-weight: 500;
    border-bottom: 2px solid var(--color-gray50);

    /* border: 1px solid var(--color-gray100); */
  }
  td {
    padding: 8px;
    font-size: 13px;
    width: var(--cell-width);
  }

  a {
    &.primary-action {
      display: block;
      font-weight: 500;
      color: var(--link-color);
      padding: 8px;
      &:hover {
        --link-color: var(--color-teal900);
      }
    }
    &.secondary-action {
      display: block;
      font-weight: 400;
      color: var(--color-gray600);
      /* background-color: var(--color-transparent); */
      padding: 6px 8px;
      /* border: 1px solid var(--color-transparent); */
      border-radius: 6px;
      /* font-size: 12px; */

      &:hover,
      &:active {
        color: var(--color-teal800);
        /* background-color: var(--color-white); */
        /* border: 1px solid var(--color-gray100); */
      }
    }
  }

  .icon-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray300);
  }
  /* a {
    color: var(--link-color);
    font-weight: 400;
    &:hover {
      --link-color: var(--color-teal600);
    }
  } */
`;

interface TableCellConfiguration<TableItem> {
  header: React.ReactNode;
  show?: boolean;
  render?: (item: TableItem) => React.ReactNode;
  style?: { [key: string]: string };
}

interface TableProps<TableItem> {
  uniqueKey?: string;
  data: Array<TableItem>;
  cells: Array<TableCellConfiguration<TableItem>>;
  emptyView?: React.ReactNode;
}

export function Table<TableItem extends { id?: string }>({
  data,
  cells,
  emptyView = "There's no data here",
}: TableProps<TableItem>) {
  const activeCells = React.useMemo(() => {
    return cells.filter(cell => cell.show !== false);
  }, [cells]);

  if (!data.length) {
    return (
      <Wrapper className="empty-table">
        <div className="empty-view">{emptyView}</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <thead>
        <tr>
          {activeCells.map((cell, index) => (
            <th key={index}>{cell.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item: TableItem) => (
          <tr key={item.id}>
            {activeCells.map((cell, index) => {
              return (
                <td key={index} style={cell.style}>
                  {cell.render(item)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Wrapper>
  );
}
