import { chaatToolModel, mutationActions, transportState } from '../app-root';
import { AudioRegionActions } from '@masala-lib/editorial/models/actions/audio-region-actions';
import { AudioRegionWriteDto } from '@masala-lib/editorial/chaat/chaat-types';

export class ChaatForceLinearAudioRegionActions extends AudioRegionActions {
  addUpdate(data: AudioRegionWriteDto): void {
    this.mutationActions.addUpdateChaatAudioRegion(data, 'FORCE_LINEAR');
  }
  get mutationActions() {
    return mutationActions;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get audioSelectionRegion() {
    return chaatToolModel.audioRegionSelection;
  }
  get currentAudioRegions() {
    return chaatToolModel.forceLinearAudioRegions;
  }
  get currentAudioRegionIntervals() {
    return chaatToolModel.forceLinearAudioRegionIntervals;
  }
}
