import {
  computed,
  makeObservable,
  observable,
  ObservableMap,
  runInAction,
} from 'mobx';
import { ExcerptData, ExcerptKind, ExcerptStatus } from '../catalog-types';
import { get, isEmpty, isEqual, isNil, sortBy } from 'lodash';
import { EMPTY_FILTER, EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { emptyExcerptData, Excerpt } from '../models/excerpt';
import { UnitManager } from './unit-manager';
import { hasIdSepartor, idParts } from '@tikka/elements/element-id-utils';
import { randomSlug } from '../../utils';
import { loadScriptEpisodeData } from '@masala-lib/editorial/db/loader-funcs';
import { Channel } from '../models/channel';

export class ExcerptManager extends EntityManager<Excerpt, ExcerptData> {
  // modelMap: ObservableMap<string, Excerpt>;

  // @observable.ref
  // channelIdFilter: string = null;

  @observable.ref
  unitIdFilter: string = null;

  @observable.ref
  channelFilter: Channel = null;

  disposers: (() => void)[] = [];

  constructor() {
    super();
    this.modelMap = observable(new Map(), { deep: false });
    makeObservable(this);

    this.disposers.push(() => this.close());
  }

  setFilterText(text: string) {
    this.filterText = text;
  }

  baseList(): Excerpt[] {
    // return this.byChannel(this.channelIdFilter);
    let list = this.rawList;
    // should provide a better way to sort within the volume detail view
    return list.sort((a, b) => b.data.updatedAt - a.data.updatedAt);
  }

  // // todo: utilize db query level filter for this
  // byChannel(channelId: string): Excerpt[] {
  //   const count = this.rawList.filter(
  //     model => model.channelId === channelId
  //   ).length;
  //   // console.log(`byChannel(${channelId}), count: ${count}`);
  //   if (isEmpty(channelId)) {
  //     console.log(`empty channelId, returning raw list`);
  //     return this.rawList;
  //   } else {
  //     // note, to mass delete orphan volumes, create a channel with an id of '_' via firebase console
  //     return this.rawList.filter(
  //       model =>
  //         model.channelId === channelId ||
  //         (isEmpty(model.channelId) && channelId === '_')
  //     );
  //   }
  // }

  @computed
  get filteredList() {
    let list = this.rawList;

    if (this.channelFilter) {
      list = this.channelFilter.excerpts;
    }

    if (!isEmpty(this.filterText)) {
      list = list.filter(model => model.filterMatch(this.filterText));
    }

    /// the filters get composed. I'm not sure the order is important here.
    // maybe we should take the order from `filterableFields`
    this.filters.forEach((filter, filterName) => {
      if (filter.value) {
        list = list.filter(item => {
          const value = get(item, filterName);
          // console.log('FILTER', { filter, filterName, value, expected: filter.value });
          if (filter.value === EMPTY_FILTER) {
            return isEmpty(value);
          }

          return isEqual(value, filter.value);
        });
      }
    });

    switch (this.sorting.fieldName) {
      case 'name':
        list = sortBy(list, ['normalizedName']);
        break;
      case 'releaseDate':
        list = sortBy(list, ['releaseDateSort', 'normalizedName']);
        break;
      case 'volumeName':
        list = sortBy(list, ['volumeNormalizedName', 'normalizedName']);
        break;
      case 'createdAt':
        list = sortBy(list, ['data.createdAt', 'normalizedName']);
        break;

      case 'updatedAt':
      default:
        list = sortBy(list, ['data.updatedAt', 'normalizedName']);
        break;

      // default:
      //   break;
    }

    if (this.sorting.order === 'desc') {
      list = list.reverse();
    }

    return list;
  }

  get collectionName(): string {
    return CatalogCollections.EXCERPT_METADATA;
  }

  // better name here would probably be `buildModel`
  createModel(data: { id: string }): Excerpt {
    return new Excerpt(data);
  }

  async createWithDto(dto: ExcerptData): Promise<Excerpt> {
    const data = { ...emptyExcerptData, ...dto };
    const result = await super.createWithDto(data);
    return result;
  }

  close() {
    // no longer needed
  }

  // just returns cached instance if available, otherwise return placeholder
  // and do an async load
  fetchById(id: string): Excerpt {
    console.log(`ExcerptManager.fetchById(${id})`);
    if (isEmpty(id)) {
      return null;
    }
    let candidate = this.modelMap.get(id);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
      this.loadById(id); // will run async and update the already returned instance
    }
    return candidate;
  }

  async loadById(id: string): Promise<Excerpt> {
    if (hasIdSepartor(id)) {
      return await this.ensuredEntityForPath(id);
    }

    const model = await super.loadById(id);
    if (model) {
      await model.ensureParents();
      if (!model.data.name) {
        model.data.name = model.data.title; // hack to transiently populate name for legacy data
      }
      if (!model.data.excerptKind) {
        model.data.excerptKind = ExcerptKind.SOUNDBITE;
      }
    }
    return model;
  }

  // returns existing excerpt or creates a new one if needed for referenced unit and excerpt element id
  async ensuredEntityForPath(id: string): Promise<Excerpt> {
    const parts = idParts(id);
    if (!parts || parts.length !== 2) {
      throw Error(`unexpected excerpt path: ${id}`);
    }
    const [unitId, elementId] = parts;
    const data = await this.loadDataByUnitElement(unitId, elementId);
    let model: Excerpt;
    if (data) {
      // model = this.createModel(data);
      model = await this.loadById(data.id);
      if (!model.data.name) {
        model.data.name = model.data.title; // hack to transiently populate name for legacy data
      }
    } else {
      console.log(`creating new excerpt for path: ${id}`);
      const unit = await UnitManager.getInstance().loadById(unitId);
      if (!unit) {
        throw Error(`unit not found for id: ${unitId}`);
      }
      const episodeData = await loadScriptEpisodeData(unitId);
      const elements = episodeData.structuralElementList;
      const excerptElement = elements.getElement(elementId);
      if (!excerptElement) {
        return null;
      }
      const roughSortIndex = excerptElement.address;

      // todo: improve default slug and title
      const tail = randomSlug(3);
      const slug = `${unit.slug}-${tail}`;
      const name = `${unit.name} - ${tail}`;
      const dto = {
        unitId,
        elementId,
        slug,
        name,
        title: name,
        id: '',
        roughSortIndex,
      };
      model = await this.createWithDto(dto);
    }
    await model.ensureParents();
    return model;
  }

  async loadDataByUnitElement(
    unitId: string,
    elementId: string
  ): Promise<ExcerptData> {
    const querySnapshot = await this.collectionRef()
      .where('unitId', '==', unitId)
      .where('elementId', '==', elementId)
      .get();
    const doc = querySnapshot.docs[0];
    return doc?.data();
  }

  async loadSoloById(id: string): Promise<Excerpt> {
    const model = await super.loadSoloById(id);
    if (model) {
      model.fetchedUnit = await new UnitManager().loadSoloById(model.unitId);
    }
    return model;
  }

  getOrCreateById(id: string): Excerpt {
    let candidate = this.modelMap.get(id);
    if (isNil(candidate)) {
      candidate = this.createModel({ id });
      runInAction(() => {
        this.modelMap.set(id, candidate);
      });
    }
    return candidate;
  }

  // async loadSoloById(id: string): Promise<Excerpt> {
  //   const model = await super.loadSoloById(id);
  //   // if (model) {
  //   //   model.fetchedUnit = await new UnitManager().loadSoloById(
  //   //     model.unitId
  //   //   );
  //   // }
  //   return model;
  // }

  // async loadSoloBySlug(slug: string): Promise<Unit> {
  //   const model = await super.loadSoloBySlug(slug);
  //   if (model) {
  //     model.fetchedVolume = await new VolumeManager().loadSoloById(
  //       model.volumeId
  //     );
  //   }
  //   return model;
  // }

  private static instance: ExcerptManager;

  public static getInstance(): ExcerptManager {
    if (!ExcerptManager.instance) {
      ExcerptManager.instance = new ExcerptManager();
      // should probably only listen for the console usage
      ExcerptManager.instance.listenAll();
    }
    return ExcerptManager.instance;
  }
}
