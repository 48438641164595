import { JobId, StageParams } from '@masala-lib/jobs/job-types';
import { JobKind } from '@masala-lib/jobs/job-kind';
import { sleep } from '@utils/util';

import { createLogger } from '@app/logger';
const log = createLogger('hello-single-stage-job');

const INITIALIZE_AND_START = 'INITIALIZE_AND_START';

interface HelloParams {
  name: string;
  sleepSeconds: number;
  eventualResult: string;
}

interface StartStageOutput {
  result: string;
  actualDurationMillis: number;
}

export async function createHelloSingleJob(
  params: HelloParams
): Promise<JobId> {
  log.info(`createHelloSingleJob: ${JSON.stringify(params)} `);
  const jobId = await jobKind.createJob(params);
  log.info(`jobId: ${JSON.stringify(jobId)}`);
  return jobId;
}

export class HelloSingleStageJobKind extends JobKind {
  get kind() {
    return 'HELLO-SINGLE';
  }

  protected get stageMethods() {
    return [[INITIALIZE_AND_START, this.initializeAndStart]] as const;
  }

  private async initializeAndStart({
    jobData,
    stageData,
    stageOutputData,
    logger,
  }: StageParams): Promise<void> {
    const output = stageOutputData as StartStageOutput;
    const params = jobData.params as HelloParams;

    logger.info(`Hello ${params.name}!`);
    logger.debug(`all params`, params);

    this.setRelativePollTimesForStageAsyncOutputs(
      {
        start: 0,
        end: 5 * 60, // 5 minutes
      },
      stageData
    );

    logger.info('before first nap');
    // await new Promise(resolve => setTimeout(resolve, params.sleepSeconds * 1000) );
    await sleep(params.sleepSeconds * 1000);
    logger.info('first nap completed');

    if (params.eventualResult === 'death') {
      logger.error('I am dying!');
      throw Error('simulated death');
    }

    await sleep(params.sleepSeconds * 1000);
    logger.info('second nap completed');

    output.result = params.eventualResult;
  }
}

const jobKind = new HelloSingleStageJobKind();
