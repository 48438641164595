import { observer } from 'mobx-react';
import { DataCard } from '../../components/data-display/data-card';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { useTag } from './use-tag';
import { Auth } from '@masala-lib/editorial/db/auth';

interface TagDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const TagDetailScreen = observer(
  ({ closeDetail, startEditing }: TagDetailsParams) => {
    const tag = useTag();
    const auth = Auth.getInstance();

    if (!tag) {
      return null;
    }

    const handleDelete = () => {
      tag.destroy();
    };

    return (
      <Page
        title={`${tag.name} ${tag.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.isAdmin ? (
                <>
                  <button onClick={startEditing} className="primary-action">
                    Edit Tag
                  </button>
                  <button onClick={handleDelete} className="danger-action">
                    Hard Delete Tag
                  </button>
                </>
              ) : null}
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            <DataCard
              data={{
                ...tag.data,
              }}
            />
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);
