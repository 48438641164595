import { VolumeData } from '../catalog-types';
import { Volume } from '../models/volume';
import { computed, makeObservable, observable } from 'mobx';
import { get, isEmpty, isEqual, pick, sortBy } from 'lodash';
import { EMPTY_FILTER, EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { ChannelManager } from './channel-manager';
import { UnitManager } from './unit-manager';

import { createLogger } from '@app/logger';
const log = createLogger('volume-manager');

export class VolumeManager extends EntityManager<Volume, VolumeData> {
  @observable.ref
  channelIdFilter: string = null;

  constructor() {
    super();
    makeObservable(this); // todo: confirm if needed/okay to call both here and in super class
  }

  setChannelIdFilter(channelId: string): void {
    this.channelIdFilter = channelId;
  }

  @computed
  get filteredList() {
    let list = this.rawList;

    if (!isEmpty(this.filterText)) {
      list = list.filter(model => model.filterMatch(this.filterText));
    }

    /// the filters get composed. I'm not sure the order is important here.
    // maybe we should take the order from `filterableFields`
    this.filters.forEach((filter, filterName) => {
      if (filter.value) {
        list = list.filter(item => {
          const value = get(item, filterName);
          // console.log('FILTER', { filter, filterName, value, expected: filter.value });
          if (filter.value === EMPTY_FILTER) {
            return isEmpty(value);
          }

          return isEqual(value, filter.value);
        });
      }
    });

    switch (this.sorting.fieldName) {
      case 'name':
        list = sortBy(list, ['name']);
        break;
      case 'createdAt':
        list = sortBy(list, ['data.createdAt', 'name']);
        break;
      case 'updatedAt':
        list = sortBy(list, ['data.updatedAt', 'name']);
        break;
      case 'slug':
        list = sortBy(list, ['slug']);
        break;
      // case 'volumeName':
      //   list = sortBy(list, ['volumeNormalizedName', 'normalizedName']);
      //   break;

      default:
        break;
    }

    if (this.sorting.order === 'desc') {
      list = list.reverse();
    }

    return list;
  }

  baseList(): Volume[] {
    return this.byChannel(this.channelIdFilter);
  }

  // todo: utilize db query level filter for this
  byChannel(channelId: string): Volume[] {
    const count = this.rawList.filter(
      model => model.channelId === channelId
    ).length;
    // console.log(`byChannel(${channelId}), count: ${count}`);
    if (isEmpty(channelId)) {
      console.log(`empty channelId, returning raw list`);
      return this.rawList;
    } else {
      // note, to mass delete orphan volumes, create a channel with an id of '_' via firebase console
      return this.rawList.filter(
        model =>
          model.channelId === channelId ||
          (isEmpty(model.channelId) && channelId === '_')
      );
    }
  }

  byForkParentId(forkParentId: string): Volume[] {
    return this.rawList.filter(
      model => model.data.forkParentId === forkParentId
    );
  }

  async destroyOrphans(): Promise<void> {
    const orphanIds: string[] = [];
    this.rawList.forEach(volume => {
      if (isEmpty(volume.channelId)) {
        orphanIds.push(volume.id);
      }
    });
    console.log(`orphans to destroy: ${JSON.stringify(orphanIds)}`);
    orphanIds.forEach(id => this.delete({ id: id }));
  }

  get collectionName(): string {
    return CatalogCollections.VOLUME_METADATA;
  }

  createModel(data: { id: string }): Volume {
    return new Volume(data);
  }

  async create(model: Volume): Promise<Volume> {
    const result = await super.createWithDto(model.toStorageData());
    if (model.data.singleUnit) {
      const unitData = {
        ...pick(model.data, ['name', 'slug' /*, 'flavor'*/]),
        l2Code: result.l2,
        l1Code: result.l1,
        volumeId: result.id,
        id: result.id, // force same id for cheap nav substitution
      };
      const unit = await UnitManager.getInstance().createWithDto(unitData);
    }
    return result;
  }

  async loadSoloById(id: string): Promise<Volume> {
    const model = await super.loadSoloById(id);
    if (model) {
      model.fetchedChannel = await new ChannelManager().loadSoloById(
        model.channelId
      );
      model.fetchedMasterVolume = await model.fetchMasterVolume();
    }
    return model;
  }

  // async restoreAllCaliArchived() {
  //   this.setShowArchived(true);
  //   UnitManager.getInstance().setShowArchived(true);
  //   for (const volume of this.archivedList) {
  //     if (volume.archived && volume.data.reviewVersion) {
  //       log.info(`restoring volume ${volume.slug}`);
  //       await volume.unarchive();
  //       for (const unit of volume.units) {
  //         if (unit.archived) {
  //           log.info(`restoring unit ${unit.slug}`);
  //           await unit.unarchive();
  //         }
  //       }
  //     }
  //   }
  //   this.setShowArchived(false);
  //   UnitManager.getInstance().setShowArchived(false);
  // }

  private static instance: VolumeManager;

  public static getInstance(): VolumeManager {
    if (!VolumeManager.instance) {
      VolumeManager.instance = new VolumeManager();
      // todo: remove the listenAll from here. shouldn't be needed except for the console
      VolumeManager.instance.listenAll();
    }
    return VolumeManager.instance;
  }
}
