import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Channel } from '@masala-lib/catalog/models/channel';
import { Table } from '../shared/table';
import { Shelf } from '../shared/shelf';

interface ChannelsTableProps {
  channels: Channel[];
}

export const ChannelsTable = observer(({ channels }: ChannelsTableProps) => {
  return (
    <Table
      data={channels}
      cells={[
        {
          header: 'Channel name',
          render: item => {
            return (
              <Link className="primary-action" to={`/channels/${item.id}`}>
                {item.name} ({item.slug})
              </Link>
            );
          },
        },
        {
          header: 'Volumes',
          render: item => {
            return (
              <Shelf>
                <Link className="secondary-action" to={item.volumesNavPath}>
                  {item.volumeCount} volumes
                </Link>
                <Link className="secondary-action" to={item.unitsNavPath}>
                  {item.unitCount} units
                </Link>
                <Link className="secondary-action" to={item.excerptsNavPath}>
                  {item.excerptCount} excerpts
                </Link>
              </Shelf>
            );
          },
        },
      ]}
    />
  );
});
