import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HomeScreen } from './console/views/home-screen';
import { UserListScreen } from './console/views/users/user-list-screen';
import { NotFoundScreen } from './console/views/not-found-screen';
import { InitFirestoreScreen } from './console/views/init-firestore-screen';
import { ScriptEditorApp } from './script-editor/script-editor-app';
import { Root as ChaatApp } from './chaat/root';
import {
  VolumeDetailBySlug,
  VolumesScreen,
} from './console/views/volumes/volumes-screen';
import { ChannelsScreen } from './console/views/channels/channels-screen';
import { UnitsScreen } from './console/views/units/units-screen';
import { DevScreen } from './console/views/dev';
import { CaliCatalogDataScreen } from './console/views/channels/catalog-data-screen';
import { MasterLayout } from './console/views/shared/layouts';
import { ArchiveScreen } from './console/views/archive/archive-screen';
import { AuthorsScreen } from './console/views/authors/authors-screen';
import { PrintUnitScreen } from './console/views/print-tool';
import { TagsScreen } from './console/views/tags/tags-screen';
import { ActivityGuidesScreen } from './console/views/activity-guides/activity-guides-screen';
import { UnitExportDataScreen } from './review-tool/unit-json-data-screen';
import { MiscToolsScreen } from './console/views/misc-tools-screen';
import { FeaturedReleasesScreen } from './console/views/featured-releases/featured-releases-screen';
import { VideoGuidesScreen } from './console/views/video-guides/video-guides-screen';
import { ExcerptsScreen } from './console/views/excerpts/excerpts-screen';
import { ExcerptDataScreen } from './console/views/excerpts/excerpt-data-screen';
import { ExcerptDumpScreen } from './console/views/excerpts/excerpt-dump-screen';
import { VolumeCatalogDataScreen } from './review-tool/volume-catalog-data-screen';
import { SoundbiteEditionsScreen } from './console/views/soundbite-editions/soundbite-editions-screen';
import { SoundbiteEditionDataScreen } from './console/views/soundbite-editions/soundbite-edition-data-screen';
import { SamosaScreen } from './samosa/ui/samosa-screen';
import { JobsScreen } from '@console/views/jobs/jobs-screen';
import { ProjectListScreen } from '@console/views/assist-projects/project-list-screen';
import { TagsDumpScreen } from '@console/views/tags/tags-dump-screen';
import { StructuralExampleScreen } from '@console/views/units/structural-example-screen';

export const MasalaRoutes = () => (
  <Routes>
    {/* "apps" */}
    <Route
      path="/units/:volumeId/:unitId/editor/:locale"
      element={<ScriptEditorApp />}
    />
    <Route
      path="/units/:volumeId/:unitId/editor/:locale/:jumpId"
      element={<ScriptEditorApp />}
    />
    <Route path="/episodes/:key/chaat" element={<ChaatApp />} />
    <Route path="/episodes/:key/chaat/:mode" element={<ChaatApp />} />
    <Route path="/samosa/:projectId" element={<SamosaScreen />} />

    {/* console flows */}
    <Route path="/" element={<MasterLayout />}>
      <Route index element={<Navigate to="home" />} />
      <Route path="/home" element={<HomeScreen />} />
      <Route path="/misc" element={<MiscToolsScreen />} />
      <Route path="/channels/*" element={<ChannelsScreen />} />
      <Route path="/volumes/*" element={<VolumesScreen />} />
      <Route path="/units/*" element={<UnitsScreen />} />
      <Route path="/excerpts/*" element={<ExcerptsScreen />} />
      <Route
        path="/soundbite-editions/*"
        element={<SoundbiteEditionsScreen />}
      />
      <Route path="/users/*" element={<UserListScreen />} />
      <Route path="/authors/*" element={<AuthorsScreen />} />
      <Route path="/tags/*" element={<TagsScreen />} />
      <Route path="/activity-guides/*" element={<ActivityGuidesScreen />} />
      <Route path="/featured-releases/*" element={<FeaturedReleasesScreen />} />
      <Route path="/video-guides/*" element={<VideoGuidesScreen />} />
      <Route path="/archive/*" element={<ArchiveScreen />} />
      <Route path="/jobs/*" element={<JobsScreen />} />
      <Route path="/assist-projects" element={<ProjectListScreen />} />
      <Route path="/dev" element={<DevScreen />} />
    </Route>

    {/* print/raw screens */}
    <Route path="/print/:id" element={<PrintUnitScreen />} />
    <Route
      path="/units/:unitId/structuralExample"
      element={<StructuralExampleScreen />}
    />
    <Route path="/excerpts/:excerptId/dump" element={<ExcerptDumpScreen />} />
    <Route path="/tags/dump" element={<TagsDumpScreen />} />

    {/* json data screens */}
    <Route
      path="/channels/:id/cali-catalog"
      element={<CaliCatalogDataScreen />}
    />
    <Route
      path="/volumes/:volumeId/catalog-data/:locale"
      element={<VolumeCatalogDataScreen />}
    />
    <Route
      path="/units/:volumeId/:unitId/unit-export-data"
      element={<UnitExportDataScreen />}
    />
    <Route path="/excerpts/:excerptId/data" element={<ExcerptDataScreen />} />
    <Route
      path="/soundbite-editions/:id/data"
      element={<SoundbiteEditionDataScreen />}
    />
    {/* <Route
        path="/units/:volumeId/:unitId/script-player-data/:locale"
        component={CaliPlayerDataScreen}
      /> */}

    {/* for one-time init of new firestore db */}
    <Route path="/init-firestore" element={<InitFirestoreScreen />} />

    <Route path="*" element={<NotFoundScreen />} />
    {/* <Route component={NotFoundScreen} /> */}
  </Routes>
);
