import React from 'react';
import { observer } from 'mobx-react';
import { DiscussionSidepanel } from './discussion-sidepanel';
import { WordGroupEditor } from './word-group-editor';
import { scriptEditorModel } from '../models/app-root';
import { SearchResultsNavPanel } from './search-results-nav-panel';
import { EKind } from '@masala-lib/element-kinds';
// import { PassageQuestionEditor } from './passage-question-editor';
import { ExcerptEditor } from './excerpt-editor';
import { SpeakerEditor, SpeakerSidepanel } from './speaker-editor';

const SidepanelComponents: { [index in EKind]?: any } = {
  [EKind.WORD_GROUP]: WordGroupEditor,
  // [EKind.PASSAGE_QUESTION_HEAD]: PassageQuestionEditor,
  [EKind.EXCERPT]: ExcerptEditor,
  [EKind.PARAGRAPH]: SpeakerSidepanel,
};

const defaultSidepanelComponent = DiscussionSidepanel;

export const ContextualSidepanel = observer(() => {
  let SidepanelComponent = null;
  let focusedElement = scriptEditorModel.focusedElement;
  let warnings = scriptEditorModel.focusedElementLintWarnings;
  let translations = scriptEditorModel.translationsLookup;
  let l1locale = scriptEditorModel.l1locale; // todo: fix caseing to l1Locale
  let l2locale = scriptEditorModel.l2locale;
  if (focusedElement) {
    SidepanelComponent =
      SidepanelComponents[focusedElement.kind] ?? defaultSidepanelComponent;
  }

  const content = scriptEditorModel.elements; // TODO remove this? don't need pass stuff to app level components can get from app level models?

  return (
    <>
      <SearchResultsNavPanel />
      {SidepanelComponent ? (
        <SidepanelComponent
          element={focusedElement as any}
          content={content}
          translations={translations}
          l1locale={l1locale}
          l2locale={l2locale}
          warnings={warnings}
          onMasterLanguageFork={scriptEditorModel.onMasterLanguageFork}
        />
      ) : null}
    </>
  );
});
