// import { getRandomValues, _btoa } from './platform/platform-utils';
// import cryptoRandomString from 'crypto-random-string';
import { includes, isEmpty, isNumber } from 'lodash';
import randomstring from 'randomstring';
import { isEmptyStringOrNumber, notEmpty } from '@utils/conditionals';
import { deploymentConfig } from './deployment-config';

// const forwardSlashRegex = /\//g;

export function randomString(length: number = 8): string {
  // const a = new Uint8Array(length);
  // getRandomValues(a);

  // const str = _btoa(String.fromCharCode.apply(null, [...a])).replace(forwardSlashRegex, '_');
  // return str.slice(0, length);

  // return cryptoRandomString({ length, type: 'alphanumeric' });
  return randomstring.generate({
    length,
    charset: 'alphanumeric',
  });
}

// some old element ids seems to have `+`s and `_`s,
// so flatten to `0`s during ingestion
export function sanitizeDirtyId(str: string) {
  return str?.replace(/[+_]/g, '0');
}

export function hasDirtyIdChars(str: string): boolean {
  return str?.includes('+') || str?.includes('_');
}

export function randomSlug(length: number = 5): string {
  return randomstring.generate({
    length,
    charset: 'alphabetic',
    capitalization: 'lowercase',
  });
}

// used by linear integration
export function removeLeadingNumber(templateName: string) {
  return templateName.replace(/^\d{3}\s/, '').replace(/^\w{3}:\d{3}\s/, '');
}

export function epochSecondsFloat() {
  return Date.now() / 1000.0;
}

export const stringToEpochSeconds = (s: string): number => {
  let millis: number;
  if (isEmpty(s)) {
    millis = 0;
  } else {
    if (s.includes('-')) {
      millis = Date.parse(s);
    } else {
      millis = Number(s);
    }
  }
  return millis / 1000.0;
};

// handle our currently inconsistent releaseData value type
export const looseDateToIso8601 = (looseDate: number | string): string => {
  // beware isEmpty(number) apparently returns true!!
  // if (isEmpty(looseDate)) {

  if (isNumber(looseDate)) {
    if (!looseDate) {
      return null;
    }

    // not sure why the `as number` is needed here, but was getting this error when attempting to
    // deploy to gcs (even though lint was passing locally):
    //   src/masala-lib/utils.ts(54,34): error TS2345: Argument of type 'string | number' is not assignable to parameter of type 'number'.
    return epochSecondsToIso8601(looseDate as number);
  } else {
    if (isEmpty(looseDate)) {
      return null;
    }

    const date = new Date(looseDate); // assume it's a string formatted date which can be correctly parsed
    return date.toISOString().split('T')[0]; // better way??
  }
};

export const epochSecondsToIso8601 = (seconds: number): string => {
  if (!seconds) {
    return null;
  } else {
    return new Date(seconds * 1000).toISOString().split('T')[0]; // better way??
  }
};

export const iso8601ToEpochSeconds = (s: string): number => {
  if (s) {
    return new Date(s).getTime() / 1000;
  } else {
    return null;
  }
};

export function* xrange(begin: number, end: number) {
  for (let i = begin; i <= end; i++) yield i;
}

export function numberProjectionSort<T>(
  arr: T[],
  projection: (o: T) => number
) {
  arr.sort((a, b) => projection(a) - projection(b));
}

export function setDifference<T>(a: Set<T>, b: Set<T>) {
  return new Set([...a].filter(x => !b.has(x)));
}

export function setIntersection<T>(a: Set<T>, b: Set<T>) {
  return new Set([...a].filter(x => b.has(x)));
}

export function setUnion<T>(a: Set<T>, b: Set<T>) {
  return new Set([...a, ...b]);
}

// strips empty properties
// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
export function removeEmpty(obj: Object) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => notEmpty(v))
  );
}

export function isChrome() {
  return navigator.userAgent.indexOf('Chrome') !== -1;
}

//
// derived from spa/src/core/lib/pretty-duration.ts
//

export const agePrettyTimestamp = (epochSeconds: number) => {
  return agePretty(epochSeconds * 1000);
};

export const agePretty = (dateTimeParam: string | number) => {
  if (isEmptyStringOrNumber(dateTimeParam)) return null;
  return `${minutesToPrettyDuration(ageMinutes(dateTimeParam))} ago`;
};

export const ageMinutes = (dateTimeParam: string | number) => {
  if (isEmptyStringOrNumber(dateTimeParam)) return null;
  const time: number =
    typeof dateTimeParam == 'string'
      ? Date.parse(dateTimeParam)
      : dateTimeParam;
  const result = (Date.now() - time) / (60 * 1000);
  return result;
};

export const minutesToPrettyDuration = (durationInMinutes: number): string => {
  const durationInMilliseconds = durationInMinutes * 60 * 1000;
  return millisToPrettyDuration(durationInMilliseconds);
};

export const millisToPrettyDuration = (durationInMillis: number): string => {
  // defend against unexpectedly missing arg data
  if (durationInMillis === undefined || durationInMillis === null) {
    return '';
  }
  const durationInSeconds = Math.floor(durationInMillis / 1000);
  const durationInMinutes = Math.floor(durationInSeconds / 60);

  const days = Math.floor(durationInMinutes / 60 / 24);
  const hours = Math.floor(durationInMinutes / 60) % 24;
  const minutes = Math.floor(durationInMillis / (1000 * 60)) % 60;
  // const seconds = Math.floor((durationInMillis / 1000) % 60);

  const daysDurationString = days > 0 ? `${days}d` : null;
  const hoursDurationString = hours > 0 ? `${hours}hr` : null;
  // const minutesDurationString = minutes > 0 ? `${minutes}m` : null;
  const minutesDurationString = `${minutes}m`;

  //  never show seconds if we have hours
  // const secondsDurationString = seconds > 0 && hours === 0 ? `${seconds}s` : null;

  return join([
    daysDurationString,
    hoursDurationString,
    minutesDurationString,
    // secondsDurationString,
  ]);
};

const join = (parts: (string | null)[]) =>
  parts.filter(part => part !== null).join(' ');

export const millisToMinutes = (millis: number) =>
  Math.ceil(millis / (60 * 1000));

export const invariant = (condition: boolean, message: string) => {
  if (condition) {
    return;
  }

  if (!message) {
    message = 'Failed invariant';
  }

  // if (deploymentConfig.isDevtest)
  // for now always fail regardless of deployment environment
  // todo: consider an 'assert' style pattern for production

  const error = Error(message);
  // eslint-disable-next-line no-console
  console.error(error.stack);
  throw error;
};
