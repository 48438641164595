import * as React from 'react';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { Input } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { NavLink } from 'react-router-dom';
import { Button } from 'rebass';
import { CreditsData } from '@masala-lib/catalog/catalog-types';

interface Props {
  dto: CreditsData;
  onSubmit: (dto: CreditsData) => void;
  cancelNavPath: string;
}

export const CreditsForm = ({ dto, onSubmit, cancelNavPath }: Props) => {
  const { handleSubmit, getFieldProps } = useMasalaForm<CreditsData>({
    defaultValues: dto,
  });

  // React.useEffect(() => {
  //   return () => {
  //     //otherwise we stay in form mode, even when going to another item
  //     onCancel();
  //   };
  // }, [onCancel]);

  const formButtons = (
    <>
      <NavLink to={cancelNavPath}>[Cancel]</NavLink> &nbsp;{' '}
      <Button type="submit" backgroundColor={'teal'} px={32}>
        Save changes
      </Button>
    </>
  );

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <h4>Standard Lupa Credits</h4> */}
        {/* <Input {...getFieldProps('producedBy')} hint="RA" />
        <Input {...getFieldProps('producedIn')} hint="RA" />
        <Input {...getFieldProps('editedBy')} hint="RA" />
        <Input {...getFieldProps('soundDesignBy')} hint="RA" />
        <Input {...getFieldProps('artworkBy')} hint="RA" /> */}
        {/* {formButtons} */}
        <hr />
        <h4>Credits</h4>
        <Input {...getFieldProps('writtenBy')} hint="The Truth" />
        <Input {...getFieldProps('producedBy')} hint="RA" />
        <Input {...getFieldProps('coproducedWith')} />
        <Input {...getFieldProps('executiveProducer')} />
        <Input {...getFieldProps('directedBy')} />
        <Input {...getFieldProps('createdBy')} />
        <Input {...getFieldProps('host')} />
        <Input {...getFieldProps('guest')} />
        <Input {...getFieldProps('storyBy')} />
        <Input {...getFieldProps('researchBy')} />
        <Input {...getFieldProps('editedBy')} hint="RA" />
        <Input {...getFieldProps('originalMusicBy')} />
        <Input {...getFieldProps('soundDesignBy')} hint="RA" />
        <Input {...getFieldProps('artworkBy')} hint="RA" />
        <Input {...getFieldProps('photoBy')} />
        <Input {...getFieldProps('factCheckingBy')} />
        <Input {...getFieldProps('productionAssistant')} />
        <Input {...getFieldProps('associateProducedBy')} />
        <Input {...getFieldProps('sponsoredBy')} />
        <Input {...getFieldProps('producedIn')} hint="RA" />
        {/* <Input {...getFieldProps('audioMixedBy')} /> */}
        {formButtons}
        {/* <NavLink to={cancelNavPath}>[Cancel]</NavLink> &nbsp;{' '}
        <Button type="submit" backgroundColor={'teal'} px={32}>
          Save changes
        </Button> */}
        {/* <FormButtons submitLabel={'Save changes'} onCancel={onCancel} /> */}
      </form>
    </OnePaneLayout>
  );
};
