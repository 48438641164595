import * as React from 'react';
import type { AppUser } from '@masala-lib/editorial/models/user-manager';
import { isEmpty, pick } from 'lodash';
import { Input, Checkbox, FormButtons, Select } from '../../components/forms';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import styled from 'styled-components';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';

const Wrapper = styled.div`
  /* display: flex;
  justify-content: center; */
  width: 50ch;
  min-width: 600px;
  max-width: 900px;
`;

interface Props {
  dto: AppUser;
  onSubmit: (data: AppUser) => void;
  onCancel: () => void;
}
export const UserForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'id',
    'alias',
    'email',
    'name',
    'slackMemberId',
    'isAdmin',
    'isSuperAdmin',
    'isReviewer',
    'mayMakeLive',
    'scopedChannelId',
  ]);
  const { handleSubmit, getFieldProps, register } = useMasalaForm<
    typeof defaultValues
  >({
    defaultValues,
  });
  const isNew = isEmpty(dto.id);

  const channelOptions = ChannelManager.getInstance().selectOptionsWithBlank;

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        {Auth.getInstance().isSuperAdmin ? (
          <Input {...getFieldProps('alias')} required />
        ) : null}
        <Input {...getFieldProps('email')} required />
        <Input {...getFieldProps('name')} required />
        <Input {...getFieldProps('slackMemberId')} />
        <Select
          {...getFieldProps('scopedChannelId')}
          label="Partner access restriction channel"
          options={channelOptions}
          hint="Should be assigned for partner users. Will restrict the users access to only content with the specified channel."
        />
        <Checkbox {...getFieldProps('isReviewer')} />
        <Checkbox {...getFieldProps('isAdmin')} />
        {Auth.getInstance().isSuperAdmin ? (
          <>
            <Checkbox {...getFieldProps('isSuperAdmin')} />
            <Checkbox {...getFieldProps('mayMakeLive')} />
          </>
        ) : null}

        <FormButtons
          submitLabel={isNew ? 'Create user' : 'Save changes'}
          onCancel={onCancel}
        />
      </form>
    </Wrapper>
  );
};
