import { LocaleCode } from '@utils/util-types';
import { VocabDefinition, VocabSelection } from './batch-vocab';

export type BatchVocabExampleExchange = {
  selection: VocabSelection;
  definition: VocabDefinition;
};

export type BatchVocabContextData = {
  l1: LocaleCode;
  l2: LocaleCode;
  exchanges: BatchVocabExampleExchange[];
};

export const batchVocabContextData: BatchVocabContextData[] = [
  {
    l1: 'es',
    l2: 'en',
    exchanges: [
      {
        selection: {
          sentence:
            'And they thought I was pretending to be humble, but I was entirely sincere.',
          translation:
            'Y pensaban que estaba haciéndome el humilde, pero estaba siendo completamente sincero.',
          headwords: 'entirely sincere',
        },
        definition: {
          definition: 'completamente sincero',
        },
      },
      {
        selection: {
          sentence:
            'And the second reason is I would really like Barack Obama and I to become best friends.',
          translation:
            'Y la segunda razón es que realmente me gustaría que Barack Obama y yo nos convirtiéramos en mejores amigos.',
          headwords: 'to become',
        },
        definition: {
          definition: 'nos convirtiéramos',
        },
      },
      {
        selection: {
          sentence:
            'And while this was going on, my friends and family were equally sure they now had direct access to the president of the United States.',
          translation:
            'Y mientras esto sucedía, mis amigos y familiares estaban igual de seguros de que ahora tenían acceso directo al presidente de los Estados Unidos.',
          headwords: 'equally sure',
        },
        definition: {
          definition: 'igual de seguros',
        },
      },
      {
        selection: {
          sentence:
            'And while this was going on, my friends and family were equally sure they now had direct access to the president of the United States.',
          translation:
            'Y mientras esto sucedía, mis amigos y familiares estaban igual de seguros de que ahora tenían acceso directo al presidente de los Estados Unidos.',
          headwords: 'while this was going on',
        },
        definition: {
          definition: 'mientras esto sucedía',
        },
      },
      {
        selection: {
          sentence:
            'I mean, suddenly everyone has a law that only I can get through Congress.',
          translation:
            'O sea, de repente todos tienen una ley que solo yo puedo hacer pasar por el Congreso.',
          headwords: 'suddenly',
        },
        definition: {
          definition: 'de repente',
        },
      },
      {
        selection: {
          sentence:
            'Now, even in the best of circumstances, this is a disturbing question to get from a family member.',
          translation:
            'Ahora, incluso en las mejores circunstancias, esta es una pregunta inquietante viniendo de un familiar.',
          headwords: 'even in the best of circumstances',
        },
        definition: {
          definition: 'incluso en las mejores circunstancias',
        },
      },
      {
        selection: {
          sentence: 'And, I have to say, I totally get it.',
          translation: 'Y debo decir que lo entiendo totalmente.',
          headwords: 'totally get it',
        },
        definition: {
          definition: 'lo entiendo totalmente',
        },
      },
      {
        selection: {
          sentence:
            "...and just because Darth Vader is the public face of the organization, it doesn't mean that every Stormtrooper gets personal one-on-one time.",
          translation:
            '...y solo porque Darth Vader sea la cara pública de la organización, no significa que cada Stormtrooper tenga tiempo personal uno a uno.',
          headwords: 'gets personal one-on-one time',
        },
        definition: {
          definition: 'tenga tiempo personal uno a uno.',
        },
      },
      {
        selection: {
          sentence: "The first i-- is kind of corny, but it's true.",
          translation: 'La primera es un poco cursi, pero es verdad.',
          headwords: 'kind of corny',
        },
        definition: {
          definition: 'un poco cursi',
        },
      },

      /***** NEW */
      {
        selection: {
          sentence:
            "So, my senior year in high school, I am your average all-American teenage boy, interested in average all-American teenage boy things, like having a cool car and taking a hot date to the prom, and, um, designing my date's prom dress.",
          translation:
            'Entonces, en mi último año de secundaria, yo era el típico chico adolescente estadounidense, interesado en cosas típicas de chicos adolescentes estadounidenses, como tener un auto genial y llevar a una cita atractiva al baile de graduación, y, eh, diseñar el vestido de baile de mi cita.',
          headwords: 'average all-American teenage boy',
        },
        definition: {
          definition: 'típico chico adolescente estadounidense',
        },
      },
      {
        selection: {
          sentence:
            "So, my senior year in high school, I am your average all-American teenage boy, interested in average all-American teenage boy things, like having a cool car and taking a hot date to the prom, and, um, designing my date's prom dress.",
          translation:
            'Entonces, en mi último año de secundaria, yo era el típico chico adolescente estadounidense, interesado en cosas típicas de chicos adolescentes estadounidenses, como tener un auto genial y llevar a una cita atractiva al baile de graduación, y, eh, diseñar el vestido de baile de mi cita.',
          headwords: 'hot date',
        },
        definition: {
          definition: 'cita atractiva',
        },
      },
      {
        selection: {
          sentence: 'Um, so, the fact that I had a date at all was a miracle.',
          translation:
            'Eh, así que, el hecho de tener una cita ya era un milagro.',
          headwords: 'at all',
        },
        definition: {
          definition: 'en absoluto, en sí mismo',
        },
      },
      {
        selection: {
          sentence:
            "My girlfriend's name was Dana, and she was the most beautiful girl that I had ever seen.",
          translation:
            'Mi novia se llamaba Dana y era la chica más hermosa que había visto jamás.',
          headwords: 'the most beautiful',
        },
        definition: {
          definition: 'la más hermosa',
        },
      },
      {
        selection: {
          sentence:
            'And we were together most of senior year, and as we got closer to senior prom, we began planning.',
          translation:
            'Y estuvimos juntos la mayor parte del último año, y a medida que se acercaba el baile de graduación, empezamos a planear.',
          headwords: 'as we got closer',
        },
        definition: {
          definition: 'a medida que se acercaba',
        },
      },
      {
        selection: {
          sentence:
            'And when I say we began planning, what I really mean is that I began to sketch what we were going to wear to the prom.',
          translation:
            'Y cuando digo que empezamos a planear, lo que realmente quiero decir es que yo comencé a bocetar lo que íbamos a ponernos para el baile.',
          headwords: 'what I really mean',
        },
        definition: {
          definition: 'realmente quiero decir',
        },
      },
      {
        selection: {
          sentence: 'And I knew it was my turn, and nothing could go wrong.',
          translation: 'Y yo sabía que era mi turno, y nada podía salir mal.',
          headwords: 'nothing could go wrong',
        },
        definition: {
          definition: 'nada podía salir mal',
        },
      },
      {
        selection: {
          sentence:
            "And one day, I was at home watching music videos, and there's this artist named Christopher Williams who was really popular at the time.",
          translation:
            'Y un día, estaba en casa viendo videos musicales, y había un artista llamado Christopher Williams que era muy popular en ese entonces.',
          headwords: 'at the time',
        },
        definition: {
          definition: 'en ese entonces, en esos tiempos',
        },
      },
      {
        selection: {
          sentence:
            "Now, I'm not going to completely rip off Christopher's style, because you can't steal another artist's work.",
          translation:
            'Bueno, no voy a copiar por completo el estilo de Christopher, porque no puedes robar el trabajo de otro artista.',
          headwords: 'rip off',
        },
        definition: {
          definition: 'copiar, robar',
        },
      },
    ],
  },
  {
    l1: 'pt',
    l2: 'en',
    exchanges: [
      {
        selection: {
          sentence: 'So Bratz are dolls, uh, kind of like Barbies, but better.',
          translation:
            'Então, Bratz são bonecas, uh, meio que como Barbies, mas melhores.',
          headwords: 'dolls',
        },
        definition: {
          definition: 'bonecas',
        },
      },
      {
        selection: {
          sentence: 'So Bratz are dolls, uh, kind of like Barbies, but better.',
          translation:
            'Então, Bratz são bonecas, uh, meio que como Barbies, mas melhores.',
          headwords: 'kind of like',
        },
        definition: {
          definition: 'meio que como',
        },
      },
      {
        selection: {
          sentence:
            'Instead, they stood about 10 inches tall with these huge heads, full lips, curvy physique, and they had the coolest makeup.',
          translation:
            'Em vez disso, tinham cerca de 10 polegadas de altura com aquelas cabeças enormes, lábios cheios, físico curvilíneo, e tinham a maquiagem mais legal.',
          headwords: 'to stand (measurement) tall',
        },
        definition: {
          definition: 'tinham cerca de 10 polegadas de altura',
        },
      },
      {
        selection: {
          sentence:
            'I knew I was hooked, and I wanted more, but I could not ask my mom for more, because we were homeless.',
          translation:
            'Eu sabia que estava viciada, e queria mais, mas não podia pedir para a minha mãe, porque nós éramos moradoras de rua.',
          headwords: 'hooked',
        },
        definition: {
          definition: 'viciada',
        },
      },
      {
        selection: {
          sentence:
            'We had been homeless for over a year, and she had bigger worries, like if she had enough money for train fare or food, what borough we would end up sleeping in, and if I had a clean uniform for school.',
          translation:
            'Estávamos morando na rua há mais de um ano, e ela tinha preocupações maiores, como se tinha dinheiro suficiente para a passagem de trem ou comida, em que região acabaríamos dormindo, e se eu tinha um uniforme limpo para a escola.',
          headwords: 'we would end up',
        },
        definition: {
          definition: 'acabaríamos',
        },
      },
    ],
  },
]; // TODO
