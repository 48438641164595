import { ExcerptManager } from '../db/excerpt-manager';
import { Excerpt } from '../models/excerpt';
import { ExcerptData } from '@tikka/client/catalog-types';
import { Channel } from '../models/channel';
import { invariant } from '@masala-lib/utils';
import { PlayerCaliDataBuilder } from './player-cali-data-builder';
import { EKind } from '@masala-lib/element-kinds';
import { CatalogStage } from '../catalog-types';

export class ExcerptCatalogDataBuilder {
  manager: ExcerptManager;
  channel: Channel;
  stage: CatalogStage;

  static async create(channel: Channel, stage: CatalogStage) {
    invariant(!!channel, 'ExcerptCatalogDataBuilder: channel required');
    const result = new ExcerptCatalogDataBuilder(channel, stage);
    await result.init();
    return result;
  }

  static async buildList(channel: Channel, stage: CatalogStage) {
    const builder = await this.create(channel, stage);
    return await builder.buildList();
  }

  constructor(channel: Channel, stage: CatalogStage) {
    this.channel = channel;
    this.stage = stage;
  }

  async init(): Promise<void> {
    // this.manager = ExcerptManager.getInstance();
    // await this.manager.ensureLoaded();
    await this.channel.fetchExcerpts();
  }

  async buildList(): Promise<Partial<ExcerptData>[]> {
    // const list = this.manager.list;

    // // ensure we can resolve channel matches (should probably denormalize the data)
    // for (const excerpt of list) {
    //   if (!excerpt.channelSlug) {
    //     await excerpt.ensureParents();
    //   }
    // }
    // .filter(excerpt => excerpt.channelSlug === this.channel.slug)

    // const excerpts = this.channel.excerpts.sort((a, b) =>
    const allExcerpts = await this.channel.fetchExcerpts();
    const publishedExcerpts = allExcerpts.filter(excerpt =>
      excerpt.includedForStage(this.stage)
    );
    const excerpts = publishedExcerpts.sort(
      (a, b) => b.ingestedAt.localeCompare(a.ingestedAt) // todo: revisit sort order
    );

    const list: Partial<ExcerptData>[] = [];

    for (const excerpt of excerpts) {
      const catalogEntry = await this.buildCatalogEntry(excerpt);
      if (catalogEntry) {
        list.push(catalogEntry);
      }
    }
    return list;
  }

  // beware, largely duplicated w/ player-cali-data-builder
  async buildCatalogEntry(excerpt: Excerpt): Promise<Partial<ExcerptData>> {
    const dataBuilder = await PlayerCaliDataBuilder.forExcerpt(excerpt);
    await dataBuilder.loadElements();
    let beginSentence = null;
    try {
      const [beginSentence0, _] = dataBuilder.resolveExcerptSentences();
      beginSentence = beginSentence0;
    } catch (error) {
      return null;
    }
    const startMillis = beginSentence.time;
    const chapters = dataBuilder.elements.filterByKind(EKind.CHAPTER);
    const chapter = chapters.getElementContainingWordAddress(
      beginSentence.address
    );
    const chapterPos = chapters.getIndex(chapter.id) + 1;
    return {
      slug: excerpt.clientSlug,
      volumeSlug: excerpt.volume?.clientSlug,
      unitSlug: excerpt.unit?.clientSlug,
      unitNumber: excerpt.unit?.unitNumber,
      chapterPos,
      startMillis,
      title: excerpt.data.title,
      prompt: excerpt.data.prompt || excerpt.data.promptEn,
      category: excerpt.categorySlug,

      // will not be relevant with new editions driven data structure
      releaseDate: excerpt.data.releaseDate,

      // // denormalized story data
      // storyTitle: excerpt.volume?.data?.infoV5?.titleL2,
      // country: excerpt.volume?.countryTagNames?.join(', '),
      // imageUrl: excerpt.volume?.data?.imageThumbUrl,

      ingestedAt: excerpt.ingestedAt,
      ingestVersion: excerpt.data.ingestVersion,
      dataUrl: excerpt.data.dataUrl,

      // probably not needed
      audioUrl: excerpt.data.slicedAudioUrl,
      // unitSlug: unit.slug,
      // l2: unit.data.l2Code,
      // l1: this.locale,
    };
  }
}
