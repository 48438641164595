import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Auth } from '@masala-lib/editorial/db/auth';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { Volume } from '@masala-lib/catalog/models/volume';
import { createLogger } from '@app/logger';

const log = createLogger('volume-import');

async function importFromUrl(volume: Volume, dataUrl: string) {
  try {
    consoleAddAuditLogMessage(volume.id, 'Import from dataUrl: ' + dataUrl);
    if (volume.unitCount > 0) {
      await volume.invokeExport();
    }
    await volume.importFromUrl(dataUrl);
    // todo: need volume level audit logs
  } catch (error) {
    volume.clearPendingOperation();
    window.alert(error);
    throw error;
  }
}

interface FormData {
  id: string;
  importSource: string;
}

export const VolumeImport = observer(({ model }: { model: Volume }) => {
  const auth = Auth.getInstance();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FormData) => {
    log.info(`VolumeImport.onSubmit(${JSON.stringify(data)})`);
    const source = data.importSource.trim();
    if (isEmpty(source)) {
      alert('source required');
    } else {
      if (source.startsWith('http')) {
        importFromUrl(model, source);
      } else {
        alert('url required');
      }
    }
  };

  if (!auth.can('manage_volumes')) {
    return null;
  }

  return (
    <>
      <h4>Import</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          defaultValue={model.id}
          {...register('id', { required: false })}
        />
        Exported data source URL
        <br />
        <input {...register('importSource')} size={20} />
        <input type="submit" /> &nbsp;
      </form>
    </>
  );
});
