import * as React from 'react';
import { observer } from 'mobx-react';
import { ActionDialog } from '../components/action-dialog';
import {
  getSamosaModel,
  useSamosaModel,
} from 'samosa/models/samosa-model-handle';
import { Prompt } from '@masala-lib/llm/project/llm-project-types';
import { PromptFormModal } from './prompt-form';
import { toLower } from 'lodash';
import { randomString } from '@masala-lib/utils';
import { ListWrapper } from '../components/list';
import { IconButton } from '@naan/primitives/button';
import { EditIcon } from '@naan/icons/edit-icon';
import { DialogPresenter } from '../dialog-presenter';
import { KeyboardService } from 'lib/services/keyboard-service';
import { ItemsPickerController } from 'lib/services/ui-controllers/picker-controller';
import { PromptEntryModel } from '../message-entry/prompt-entry-model';
import { VSpacer } from '@naan/primitives/spacer';
import { createLogger } from '@app/logger';
import { PromptListItem } from './prompt-picker-modal';
import autoAnimate from '@formkit/auto-animate';
import { filterPromptsOnLanguagesKey } from '@masala-lib/llm/project/prompt-funcs';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { Button as NaanButton } from '@naan/primitives/button';
import { ChevronDownIcon } from '@naan/icons/chevron-down-icon';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';

const log = createLogger('prompts-modal');

const newPromptIndex = 0;

const editPrompt = (prompt: Prompt) => {
  if (prompt) {
    DialogPresenter.present(onDismiss => (
      <PromptFormModal prompt={prompt} onDismiss={onDismiss} />
    ));
  }
};

const clonePrompt = (prompt: Prompt) => {
  const model = getSamosaModel();
  const newPrompt = { ...prompt, id: randomString(8) };
  newPrompt.title = `Copy of ${newPrompt.title}`;
  newPrompt.description ??= `from @${model.appUser.alias}`;
  editPrompt(newPrompt);
};

const deletePrompt = (prompt: Prompt) => {
  const model = getSamosaModel();
  prompt.archived = true;
  model.updatePrompt(prompt);
};

const createPrompt = () => {
  const model = getSamosaModel();
  const promptModel = PromptEntryModel.instance;
  const task = model.task;
  const subTask = model.subTask;
  let languagesKey = '';
  if (
    task === 'vocab' ||
    (task === 'translation' && subTask === 'transcript')
  ) {
    languagesKey = model.languagesKey;
  }

  const prompt = {
    slug: randomString(8),
    title: 'New prompt',
    description: `from @${model.appUser.alias}`,
    languagesKey,
    text: promptModel.promptText,
    task: model.task,
    parserKey: 'standard',
  };
  editPrompt(prompt);
};

export const PromptManager = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    log.debug('PromptManager - render');
    const model = useSamosaModel();
    const modelPrompts = model.prompts;
    const [languagesKeyValue, setLanguagesKey] = React.useState<
      string | undefined
    >();

    const languagesKey =
      languagesKeyValue === undefined ? model.languagesKey : languagesKeyValue;

    const languageValue = languagesKeyOptions.find(
      option => option.value === languagesKey
    )?.label;

    const prompts = React.useMemo<Prompt[]>(() => {
      const prompts = modelPrompts;
      return filterPromptsOnLanguagesKey(prompts, languagesKey);
    }, [modelPrompts, languagesKey]);

    const picker = React.useMemo<ItemsPickerController>(() => {
      log.debug('useMemo - picker');
      const picker = new ItemsPickerController({
        rows: prompts.length + 1,
        initialIndex: newPromptIndex,
      });
      return picker;
    }, [prompts]);

    const onEnter = React.useCallback(() => {
      const index = picker.index;

      if (index === newPromptIndex) {
        createPrompt();
        return; // avoid running onDismiss
      } else {
        const prompt = prompts[index - 1];
        if (prompt) {
          editPrompt(prompt);
        }
      }
    }, [prompts, picker]);

    const handleSwap = React.useCallback(
      (direction: 1 | -1) => {
        // TODO reason about position swap within subset of prompts
        // const promptIndex = picker.index - 1;
        // const prompt = prompts[promptIndex];
        // if (prompt) {
        //   model.swapPromptPosition(prompt, direction);
        //   const length = prompts.length;
        //   const newPromptIndex = (promptIndex + direction + length) % length;
        //   picker.index = newPromptIndex + 1;
        // } else {
        //   log.warn(
        //     `handleSwapUp - ignoring invalid prompt index: ${promptIndex}`
        //   );
        // }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [picker, prompts, model]
    );

    const handleSwapUp = React.useCallback(() => handleSwap(-1), [handleSwap]);
    const handleSwapDown = React.useCallback(() => handleSwap(1), [handleSwap]);

    React.useEffect(() => {
      log.debug('useEffect');
      return KeyboardService.instance.addShortcutSet('prompts-modal', {
        ...picker.getKeybindings(),
        escape: [onDismiss, null],
        enter: [onEnter, 'Edit selected prompt'],
        e: [onEnter, null], // undocumented alias to match parent modal behavior
        n: [createPrompt, 'Create new prompt'],
        'alt+up': [handleSwapUp, 'Swap order up'],
        'alt+down': [handleSwapDown, 'Swap order down'],
      });
    }, [model, onDismiss, onEnter, handleSwapUp, handleSwapDown, picker]);

    const title = `Manage ${toLower(model.task)} prompts`;
    return (
      <ActionDialog
        onDismiss={onDismiss}
        // onDismiss={() => undefined}
        // need to disable default defocus dismiss handler so child edit modal can be opened
        disableContainerDismissal
        // dismissOnDefocus={false}
        title={title}
      >
        <Menu
          trigger={
            <NaanButton
              label={languageValue}
              rightIcon={<ChevronDownIcon />}
              size="small"
              presentation="grayLight"
            />
          }
        >
          {languagesKeyOptions.map(({ value, label }) => (
            <MenuItem
              key={value}
              action={() => setLanguagesKey(value)}
              rightIcon={value === languagesKey ? <CheckmarkIcon /> : undefined}
              label={label}
            />
          ))}
        </Menu>

        <PromptList prompts={prompts} picker={picker} />
      </ActionDialog>
    );
  }
);

// isolate what gets rerendered with the picker state
const PromptList = observer(
  ({
    prompts,
    picker,
  }: {
    prompts: Prompt[];
    picker: ItemsPickerController;
  }) => {
    const model = useSamosaModel();

    const parent = React.useRef<HTMLUListElement>(null);

    // const pickerIndex = picker.index; // important to reference to trigger rerenders after first reorder
    log.debug('picker.index', picker.index);

    // React.useEffect(() => {
    //   parent.current && autoAnimate(parent.current);
    // }, [parent]);

    return (
      <ListWrapper ref={parent}>
        <PromptListItem
          keyboardSelected={picker.is(newPromptIndex)}
          onClick={() => {
            createPrompt();
          }}
        >
          <span>
            Create <u>n</u>ew prompt…
          </span>
        </PromptListItem>
        {/* <VSpacer size={1} /> */}
        {prompts.map((prompt, index) => (
          <PromptListItem
            key={prompt.id}
            keyboardSelected={picker.is(index + 1)}
            onClick={() => editPrompt(prompt)}
          >
            <span>{prompt.title}</span>
            <div className="controls" onClick={e => e.stopPropagation()}>
              {!prompt.scope && <span>G</span>}
              <PromptOverflowMenu prompt={prompt} />
              {/* <ActionLink
              onPress={() => model.swapPromptPosition(prompt, -1)}
              >
                [↑]
              </ActionLink>
              <ActionLink onPress={() => model.swapPromptPosition(prompt, 1)}>
                [↓]
              </ActionLink> */}
            </div>
          </PromptListItem>
        ))}
        <VSpacer size={1} />
      </ListWrapper>
    );
  }
);

const PromptOverflowMenu = ({ prompt }: { prompt: Prompt }) => {
  return (
    <Menu>
      <MenuItem action={() => editPrompt(prompt)} label="Edit" />
      <MenuItem action={() => clonePrompt(prompt)} label="Clone" />
      <MenuItem action={() => deletePrompt(prompt)} label="Delete" />
    </Menu>
  );
};

export const languagesKeyOptions = [
  { value: 'en:pt', label: 'EN:PT' },
  { value: 'en:es', label: 'EN:ES' },
  { value: 'es:en', label: 'ES:EN' },
  { value: '', label: 'Universal' },
];

export const showPromptManager = DialogPresenter.makePresenter(PromptManager);
