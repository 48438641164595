import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Page } from '../shared/page';
import { listAllProjectMetadatas } from '@masala-lib/llm/project/llm-project-funcs';
import { createLogger } from '@app/logger';
import { observer } from 'mobx-react';
import { Table } from '../shared/table';
import { Shelf } from '../shared/shelf';
import { alertError } from 'ui/misc-utils';
import {
  ProjectMeta,
  enhanceProjectMetadata,
} from '@masala-lib/llm/project/project-meta';
import { Auth } from '@masala-lib/editorial/db/auth';

const log = createLogger('project-list-screen');

interface LoaderState {
  list?: ProjectMeta[];
  error?: Error;
}

const loadState = async (): Promise<LoaderState> => {
  try {
    const rawList = await listAllProjectMetadatas();
    const list = rawList.map(metadata => enhanceProjectMetadata(metadata));
    log.debug('loadState complete');
    return { list };
  } catch (e) {
    log.error(`loadState error: ${e}`);
    const error = e instanceof Error ? e : Error(String(e));
    return { error };
  }
};

export const ProjectListScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = Auth.getInstance();

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { list, error } = loaderState;

  React.useEffect(() => {
    loadState().then(state => setLoaderState(state));
  }, []);

  if (error) {
    return <>error: {String(error)}</>;
  }

  if (!list) {
    return <>...</>;
  }

  return (
    <Page title={`All Machine Assisted Editorial projects`}>
      <ProjectTable list={list} />
    </Page>
  );
};

const ProjectTable = observer(({ list }: { list: ProjectMeta[] }) => {
  return (
    <Table
      data={list}
      cells={[
        {
          header: 'Project',
          render: meta => <NavLink to={meta.navPath}>{meta.name}</NavLink>,
        },
        {
          header: 'Unit',
          render: meta => (
            <NavLink to={`/units/${meta.unitId}`}>{meta.unitName}</NavLink>
          ),
        },
        {
          header: 'Task',
          render: meta => meta.task,
        },
        {
          header: 'State',
          render: meta => meta.state,
        },
        {
          header: 'Started',
          render: meta => meta.agePretty,
        },
        {
          header: 'Actions',
          render: meta => <ProjectActions meta={meta} />,
        },
      ]}
    />
  );
});

const ProjectActions = observer(({ meta }: { meta: ProjectMeta }) => {
  const toggleArchived = () => {
    meta.toggleArchived().catch(alertError);
  };

  return (
    <Shelf>
      <button
        onClick={toggleArchived}
        className={meta.archived ? '' : `danger-action`}
      >
        {meta.archived ? 'Unarchive' : 'Archive'}
      </button>
    </Shelf>
  );
});
