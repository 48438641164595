import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ActivityGuide } from '@masala-lib/catalog/models/activity-guide';
import { Table } from '../shared/table';

interface ActivityGuidesTableProps {
  activityGuides: ActivityGuide[];
}

export const ActivityGuidesTable = observer(
  ({ activityGuides }: ActivityGuidesTableProps) => {
    return (
      <Table
        data={activityGuides}
        cells={[
          {
            header: 'Unit',
            render: activityGuide => (
              <Link to={activityGuide.navPath}>
                {activityGuide.unit?.data?.name || 'N/A'}
              </Link>
            ),
          },
          {
            header: 'Author',
            render: activityGuide => activityGuide.author?.data?.name,
          },
        ]}
      />
    );
  }
);
