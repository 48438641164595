import React from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'rebass/styled-components';

const prevent =
  (action: () => {}) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    action();
  };

export const ActionLink = ({
  onPress = () => {},
  className = '',
  title = '',
  children,
}: {
  onPress: () => any;
  className?: string;
  title?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Link href="#" className={className} onClick={prevent(onPress)}>
      {/* fontSize={1} mr={2}> */}
      {title}
      {children}
    </Link>
  );
};

export const A = ({
  href,
  className = '',
  children = null,
}: {
  href: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  if (isEmpty(href)) {
    return null; //children;
  }

  const body = isEmpty(children) ? href : children;
  return (
    <a href={href} className={className} target="_blank" rel="noreferrer">
      {body}
    </a>
  );
};
