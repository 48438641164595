import {
  ObservableMap,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {
  EmptyElementList,
  SentenceId,
  WordElement,
  WordId,
  WordIdRange,
} from '@tikka/basic-types';
import {
  Element,
  Sentence,
  Word,
  ElementList,
  ElementTracker,
} from '@masala-lib/chaat-aliases';
import { Tracking } from '@tikka/tracking/tracking';
import * as AppRoot from './app-root';
import { EKind } from '@masala-lib/element-kinds';
import {
  EmptyIntervals,
  Interval,
  Intervals,
  fromIntervals,
  size,
} from '@tikka/intervals/intervals';
import {
  redactWord,
  wordIdRangeToWordStrings,
} from '@masala-lib/content-utils';
import {
  EpisodeDataBase,
  getSentenceTimestampingSignature,
} from '@masala-lib/editorial/episode-data/episode-data';
import { ChaatEpisodeData } from './episode-data';
import { Unit } from '@masala-lib/catalog/models/unit';
import {
  AudioMarker,
  ChaatInputCue,
  Cue,
  WarningKeys,
  warningUiDescriptionLookup,
} from '@masala-lib/editorial/chaat/chaat-types';
import {
  AdhocRangeElement,
  EditorWordGroup,
} from '@masala-lib/editorial-types';
import { runTimestampingWithEpisodeData } from '@masala-lib/editorial/chaat/timestamper/chaat-timestamper';
import { debugTimestampStability } from '@masala-lib/editorial/chaat/timestamper/timestamper-debug';
import {
  ControlPoints,
  TrickyBitsSignalTransforms,
  createFunctionFromControlPoints,
  createQuantasAnyCutoff,
  createQuantasAverageCutoff,
  createQuantasCharsPace,
  createQuantasFromIntervals,
  createQuantasMasked,
  createQuantasOverride,
  createQuantasWithFunction,
  createQuantasWordsPace,
  createScaledQuantasMax1,
  getWordsOverlapQuantas,
  getWordIntervalsOverlapQuantas,
  getWordIndexesOverlapQuantas,
  filterSolitaryIndexes,
  overlappingValues,
  createScaledQuantas,
  compressionSettingDefaults,
  CompressionSettingsKey,
  compressQuantasDynamicRange,
  adjustWithTenLevels,
} from '@masala-lib/editorial/chaat/quanta-funcs';
import { TrickyEdit } from '@masala-lib/editorial/db/firestore-doc-types';
import {
  CreateChaatEditorialWordElementList,
  getWordRecordsData,
} from '@masala-lib/editorial-word-element-list';
import _ from 'lodash';

export type QuantaTrackKeys = 'UNCERTAINTY' | 'CHARS_PACE' | 'WORDS_PACE';
export class ChaatToolModel {
  // debugging
  episodeData: EpisodeDataBase = null;
  timestampMode: 'OLD' | 'NEW' | 'UNKNOWN' = 'UNKNOWN';

  @observable.ref unit: Unit;

  @observable.ref episodeKey = '';

  @observable.ref elements: ElementList<Element> = EmptyElementList;

  unredactedWords: ElementList<Word> = EmptyElementList;

  redactedWords: ElementList<Word> = EmptyElementList;

  words: ElementList<Word> = EmptyElementList;

  redactTrickyBits = false;

  sentences: ElementList<Sentence> = EmptyElementList;

  disposers: (() => void)[] = [];

  @observable.ref editEnabled = false; // TODO??

  @observable.ref currentCuePointWordId: WordId = null;

  tracking: Tracking<Element> = null;

  @observable.ref wordTracker = null as ElementTracker<Word>;

  @observable.ref sentenceTracker = null as ElementTracker<Sentence>;

  @observable.ref warnings: ElementList<AdhocRangeElement> = EmptyElementList;

  @observable.ref warningSentences = EmptyElementList;

  @observable.ref notchTimeIntervals: Intervals = EmptyIntervals;

  @observable.ref segmentTimeIntervals: Intervals = EmptyIntervals; // segmentElements and segmentIntervals also???
  // or the controller code initing the track area extracts intervals from elements?

  @observable.ref segmentStopWords: ElementList<Word> = EmptyElementList;

  @observable.ref candidateSegmentStopWords: ElementList<Word> =
    EmptyElementList;

  @observable.ref transcriptWords: string[] = [];

  @observable.ref transcriptWordTimeIntervals: Intervals = EmptyIntervals;

  @observable.ref cuesData: Cue[] = [];

  @observable.ref cuedWords: ElementList<Word> = EmptyElementList;

  @observable.ref cueDisplayTimeIntervals = EmptyIntervals;

  @observable.ref interpolatedTimeIntervals = EmptyIntervals;
  @observable.ref interpolatedData: string[] = [];

  @observable.ref majorWarnings: ElementList<AdhocRangeElement> =
    EmptyElementList;

  @observable.ref minorWarnings: ElementList<AdhocRangeElement> =
    EmptyElementList;

  @observable.ref warningTimeIntervals: Intervals = EmptyIntervals;

  @observable.ref warningData: WarningKeys[] = [];

  @observable.ref interpolationData = null as object[]; // TODO or interpolations?

  @observable.ref nonVoiceAudioRegions = [] as Element[];

  @observable.ref nonVoiceAudioRegionIntervals = EmptyIntervals;

  @observable.ref forceLinearAudioRegions = [] as Element[];

  @observable.ref forceLinearAudioRegionIntervals = EmptyIntervals;

  @observable.ref audioMarkers = [] as AudioMarker[];

  @observable.ref audioMarkerHitIntervals = EmptyIntervals;

  @observable.ref unsignedoffSentences: ElementList<Sentence> =
    EmptyElementList;

  @observable.ref audioUrls: any = {};

  @observable.ref stateVersion = 0;

  audioRegionSelection: Interval = null;

  // TRICKY

  @observable.ref trickyAsrWordTimeIntervals: Intervals = null;
  @observable.ref trickyAsrWordUncertainties: number[] = null;

  @observable.ref uncertaintyScores: number[] = null;
  quantaMS = 25; // TODO
  @observable quantaTrackSelection: string = null;
  @observable.ref wordRangeSelection: WordIdRange = null;
  quantaTracksDisabledState = new ObservableMap<string, boolean>({});
  quantaTracksScaleFactors = new ObservableMap<string, number>({});
  compressionSettings = new ObservableMap<string, number | boolean>({});
  @observable quantaTransforms: TrickyBitsSignalTransforms = {
    uncertaintyTransform: [
      [0.05, 1.0],
      [0.025, 0.6],
      [0.001, 0],
    ],
    charsPaceTransform: [
      [23.0, 1.0],
      [12.0, 0.3],
      [1.0, 0],
    ],
    wordsPaceTransform: [
      [5.5, 1.0],
      [2.0, 0.3],
      [0.5, 0],
    ],
    linearInterpolationTransform: [
      [0.9, 0.9],
      [0.1, 0],
      [0.1, 0],
    ],
    cutoffValue: 0.8,
    compositionMode: 'ANY',
  };

  @observable.ref trickyEdits: Map<number, TrickyEdit> = new Map();

  @observable.ref wordGroups: EditorWordGroup[] = [];

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.segmentStopWords,
        () => this.reinitKerning()
      )
    );
    this.disposers.push(
      reaction(
        () => this.currentWordId,
        () => this.cancelSelections()
      )
    );
  }

  // async toggleTimestampingMethodAndDebug() {
  //   const oldStarts =
  //     this.episodeData.timestampsDoc.wordTimeIntervals.startTimes.slice();
  //   let timestampingResult: TimestamperResult = {} as any;
  //   if (
  //     true ||
  //     this.timestampMode === 'OLD' ||
  //     this.timestampMode === 'UNKNOWN'
  //   ) {
  //     console.log('****RUNNING NEW TIMESTAMPING');
  //     timestampingResult = (await runTimestampingWithEpisodeData(
  //       this.episodeData
  //     )) as any;
  //     if (this.timestampMode === 'UNKNOWN') {
  //       this.timestampMode = 'NEW';
  //       return;
  //     }
  //     this.timestampMode = 'NEW';
  //   } else {
  //     console.log('****RUNNING OLD TIMESTAMPING');
  //     timestampingResult = await runTimestampingWithEpisodeDataOld(
  //       this.episodeData
  //     );
  //     this.timestampMode = 'OLD';
  //   }
  //   // debug logging
  //   const sentenceIntervals = this.sentences.wordIntervals;
  //   debugTimestampStability(
  //     this.episodeData.words.values.map(w => w.text),
  //     timestampingResult.wordStartTimes,
  //     oldStarts,
  //     sentenceIntervals
  //   );
  // }

  init() {
    this.tracking = AppRoot.tracking;
    this.wordTracker = AppRoot.wordTracker;
    this.sentenceTracker = this.tracking.getTrackerWithKind(EKind.SENTENCE);
    this.disposers.push(
      AppRoot.appBus.subscribe(
        'setCuePoint',
        cuePoint => (this.currentCuePointWordId = cuePoint)
      )
    );
  }

  @computed
  get trickyEditsValues(): TrickyEdit[] {
    // TODO
    const endTime = this.wordTimeIntervals.endPoints.at(-1);
    const quantaMS = this.quantaMS;
    const numQuanta = Math.ceil(endTime / quantaMS);
    const result: TrickyEdit[] = new Array(numQuanta).fill('NOP');
    for (const [index, value] of this.trickyEdits.entries()) {
      result[index] = value;
    }
    return result;
  }

  toggleRedactTrickyBits() {
    this.redactTrickyBits = !this.redactTrickyBits;
    if (this.redactTrickyBits) {
      this.words = this.redactedWords;
    } else {
      this.words = this.unredactedWords;
    }
    this.stateVersion++;
  }

  getCompressionSetting(key: CompressionSettingsKey): number | boolean {
    const value = this.compressionSettings.get(key);
    if (typeof value !== 'undefined') {
      return value;
    } else {
      return compressionSettingDefaults[key];
    }
  }

  adjustTrickyCompressionSettingsValue(
    key: CompressionSettingsKey,
    direction?: 1 | -1
  ) {
    // TODO factor
    switch (key) {
      case 'targetLevel':
        AppRoot.trickyActions.updateTrickyCompressionSetting(
          key,
          adjustWithTenLevels(
            this.getCompressionSetting(key) as number,
            direction,
            0.2,
            0.5
          )
        );
        break;
      case 'boostDamper':
        AppRoot.trickyActions.updateTrickyCompressionSetting(
          key,
          adjustWithTenLevels(
            this.getCompressionSetting(key) as number,
            direction,
            0.4,
            0.9
          )
        );
        break;
      case 'windowSize':
        AppRoot.trickyActions.updateTrickyCompressionSetting(
          key,
          adjustWithTenLevels(
            this.getCompressionSetting(key) as number,
            direction,
            Math.floor(4000 / this.quantaMS),
            Math.floor(12000 / this.quantaMS)
          )
        );
        break;
      case 'enabled':
        AppRoot.trickyActions.updateTrickyCompressionSetting(
          key,
          !this.getCompressionSetting(key) as boolean
        );
        break;
    }
  }

  @computed
  get uncertaintiesTransform(): ControlPoints {
    return this.quantaTransforms.uncertaintyTransform;
  }

  @computed
  get charsPaceTransform(): ControlPoints {
    return this.quantaTransforms.charsPaceTransform;
  }

  @computed
  get wordsPaceTransform(): ControlPoints {
    return this.quantaTransforms.wordsPaceTransform;
  }

  @computed
  get linearInterpolationTransform(): ControlPoints {
    return this.quantaTransforms.linearInterpolationTransform;
  }

  @computed
  get trickyCutoffValue(): number {
    return this.quantaTransforms.cutoffValue;
  }

  toggleExcludeCurrentTrack() {
    const trackKey = this.quantaTrackSelection;
    if (!trackKey) {
      return;
    }
    const newValue = !this.quantaTracksDisabledState.get(trackKey);
    AppRoot.trickyActions.updateTrickyTrackDisable(trackKey, newValue);
    // this.quantaTracksDisabledState.set(trackKey, newValue);
    // this.stateVersion++;
  }

  adjustCurrentTrackScale(direction: 'UP' | 'DOWN') {
    const trackKey = this.quantaTrackSelection;
    if (!trackKey) {
      return;
    }
    let scale = this.quantaTracksScaleFactors.get(trackKey);
    if (typeof scale !== 'number') {
      scale = 1.0;
    }
    scale = direction === 'UP' ? scale * 1.05 : scale / 1.05;
    scale = Math.max(0.1, scale);
    scale = Math.min(10.0, scale);
    AppRoot.trickyActions.updateTrickyTrackScaleFactors(trackKey, scale);
    // this.quantaTracksScaleFactors.set(trackKey, scale);
    // this.stateVersion++;
  }

  @computed({ keepAlive: true })
  get uncertaintiesTransformFunction() {
    return createFunctionFromControlPoints(this.uncertaintiesTransform);
  }

  @computed({ keepAlive: true })
  get charsPaceTransformFunction() {
    return createFunctionFromControlPoints(this.charsPaceTransform);
  }

  @computed({ keepAlive: true })
  get wordsPaceTransformFunction() {
    return createFunctionFromControlPoints(this.wordsPaceTransform);
  }

  @computed({ keepAlive: true })
  get linearInterpolationTransformFunction() {
    return createFunctionFromControlPoints(this.linearInterpolationTransform);
  }

  @computed({ keepAlive: true })
  get wordsQuantaMask(): boolean[] {
    const wordsMask = createQuantasFromIntervals(
      this.wordTimeIntervals,
      this.quantaMS,
      this.endTime
    ).map(q => q > 0.05);
    return wordsMask;
  }

  get endTime(): number {
    const endTime = this.wordTimeIntervals.endPoints.at(-1);
    return endTime;
  }

  @computed({ keepAlive: true })
  get numQuanta(): number {
    return Math.ceil(this.endTime / this.quantaMS);
  }

  @computed({ keepAlive: true })
  get emptyQuantas(): number[] {
    return new Array(this.numQuanta).fill(0.0);
  }

  @computed({ keepAlive: true })
  get modStepsQuantas(): number[] {
    return new Array(this.numQuanta).fill(0.0).map((_, i) => (i % 11) / 10);
  }

  @computed({ keepAlive: true })
  get testControlPointsQuantas(): number[] {
    const inputQuantas = this.modStepsQuantas;
    const controlPoints: ControlPoints = [
      [1.0, 1.0],
      [0.7, 0.1],
      [0.7, 0],
    ];
    const f = createFunctionFromControlPoints(controlPoints);
    const result = createQuantasWithFunction(inputQuantas, f);
    return result;
  }

  @computed({ keepAlive: true })
  get _uncertaintiesQuantas(): number[] {
    const quantaMS = this.quantaMS;
    const endTime = this.endTime;
    let asrIntervals: Intervals = null;
    let asrUncertaintyScores: number[] = null;
    if (this.trickyAsrWordTimeIntervals) {
      asrIntervals = this.trickyAsrWordTimeIntervals;
      asrUncertaintyScores = this.trickyAsrWordUncertainties;
    } else {
      asrIntervals = this.transcriptWordTimeIntervals;
      asrUncertaintyScores = this.uncertaintyScores;
    }
    if (this.uncertaintyScores) {
      let uncertaintiesQuantas = createQuantasFromIntervals(
        asrIntervals,
        quantaMS,
        endTime,
        asrUncertaintyScores
      );
      // uncertaintiesQuantas = createScaledQuantasMax1(
      //   uncertaintiesQuantas,
      //   80.0
      // );
      const scale = this.quantaTracksScaleFactors.get('UNCERTAINTY');
      if (typeof scale === 'number') {
        uncertaintiesQuantas = createScaledQuantas(uncertaintiesQuantas, scale);
      }
      uncertaintiesQuantas = createQuantasWithFunction(
        uncertaintiesQuantas,
        this.uncertaintiesTransformFunction
      );
      return uncertaintiesQuantas;
    } else {
      return this.emptyQuantas;
    }
  }

  @computed({ keepAlive: true })
  get uncertaintiesQuantas(): number[] {
    // TODO factor
    const _uncertaintiesQuantas = this._uncertaintiesQuantas;
    return _uncertaintiesQuantas;
    // const windowSize = this.getCompressionSetting('windowSize') as number;
    // const targetLevel = this.getCompressionSetting('targetLevel') as number;
    // const boostDamper = this.getCompressionSetting('boostDamper') as number;
    // const result = compressQuantasDynamicRange(
    //   _uncertaintiesQuantas,
    //   boostDamper,
    //   targetLevel,
    //   windowSize
    // );
    // return result;
  }

  @computed({ keepAlive: true })
  get linearInterpolationQuantas(): number[] {
    const quantaMS = this.quantaMS;
    const endTime = this.endTime;

    const rawInterpolatedTimeIntervals = this.interpolatedTimeIntervals;
    const interpolatedData = this.interpolatedData;
    const interpolatedTimeIntervals: Interval[] = [];
    for (const [i, data] of interpolatedData.entries()) {
      if (data === 'interpolate') {
        interpolatedTimeIntervals.push(
          rawInterpolatedTimeIntervals.intervalAt(i)
        );
      }
    }
    const interpolatedIntervals = fromIntervals(interpolatedTimeIntervals);
    let linearInterpolationQuantas = createQuantasFromIntervals(
      interpolatedIntervals,
      quantaMS,
      endTime
    );
    const scale = 'TODO';
    // const scale = this.quantaTracksScaleFactors.get('');
    if (typeof scale === 'number') {
      linearInterpolationQuantas = createScaledQuantas(
        linearInterpolationQuantas,
        scale
      );
    }
    linearInterpolationQuantas = createQuantasWithFunction(
      linearInterpolationQuantas,
      this.linearInterpolationTransformFunction
    );

    return linearInterpolationQuantas;
  }

  @computed({ keepAlive: true })
  get _charsPaceQuantas(): number[] {
    let charsPaceQuantas = createQuantasCharsPace(
      this.words,
      this.quantaMS,
      200,
      this.endTime
    );
    // charsPaceQuantas = createScaledQuantasMax1(charsPaceQuantas, 1.0 / 18.0);

    const scale = this.quantaTracksScaleFactors.get('CHARS_PACE');
    if (typeof scale === 'number') {
      charsPaceQuantas = createScaledQuantas(charsPaceQuantas, scale);
    }
    charsPaceQuantas = createQuantasWithFunction(
      charsPaceQuantas,
      this.charsPaceTransformFunction
    );
    charsPaceQuantas = createQuantasMasked(
      charsPaceQuantas,
      this.wordsQuantaMask
    );
    return charsPaceQuantas;
  }

  @computed({ keepAlive: true })
  get charsPaceQuantas(): number[] {
    // TODO factor
    const _charsPaceQuantas = this._charsPaceQuantas;
    if (!this.getCompressionSetting('enabled')) {
      return _charsPaceQuantas;
    }
    const windowSize = this.getCompressionSetting('windowSize') as number;
    const targetLevel = this.getCompressionSetting('targetLevel') as number;
    const boostDamper = this.getCompressionSetting('boostDamper') as number;
    const result = compressQuantasDynamicRange(
      _charsPaceQuantas,
      boostDamper,
      targetLevel,
      windowSize
    );
    return result;
  }

  @computed({ keepAlive: true })
  get _wordsPaceQuantas(): number[] {
    let wordsPaceQuantas = createQuantasWordsPace(
      this.words,
      this.quantaMS,
      350,
      this.endTime
    );
    // wordsPaceQuantas = createScaledQuantasMax1(wordsPaceQuantas, 1.0 / 4.0);
    const scale = this.quantaTracksScaleFactors.get('WORDS_PACE');
    if (typeof scale === 'number') {
      wordsPaceQuantas = createScaledQuantas(wordsPaceQuantas, scale);
    }
    wordsPaceQuantas = createQuantasWithFunction(
      wordsPaceQuantas,
      this.wordsPaceTransformFunction
    );
    wordsPaceQuantas = createQuantasMasked(
      wordsPaceQuantas,
      this.wordsQuantaMask
    );
    return wordsPaceQuantas;
  }

  @computed({ keepAlive: true })
  get wordsPaceQuantas(): number[] {
    // TODO factor
    const _wordsPaceQuantas = this._wordsPaceQuantas;
    if (!this.getCompressionSetting('enabled')) {
      return _wordsPaceQuantas;
    }
    const windowSize = this.getCompressionSetting('windowSize') as number;
    const targetLevel = this.getCompressionSetting('targetLevel') as number;
    const boostDamper = this.getCompressionSetting('boostDamper') as number;
    const result = compressQuantasDynamicRange(
      _wordsPaceQuantas,
      boostDamper,
      targetLevel,
      windowSize
    );
    return result;
  }

  @computed({ keepAlive: true })
  get computedQuantas(): number[] {
    const include: number[][] = [];
    if (!this.quantaTracksDisabledState.get('UNCERTAINTY')) {
      include.push(this.uncertaintiesQuantas);
    }
    if (!this.quantaTracksDisabledState.get('CHARS_PACE')) {
      include.push(this.charsPaceQuantas);
    }
    if (!this.quantaTracksDisabledState.get('WORDS_PACE')) {
      include.push(this.wordsPaceQuantas);
    }
    if (include.length === 0) {
      return this.emptyQuantas;
    }
    return createQuantasAnyCutoff(include, this.quantaTransforms.cutoffValue);
  }

  get userQuantas(): TrickyEdit[] {
    return this.trickyEditsValues;
    // const quantaMS = this.quantaMS;
    // const endTime = this.endTime;
    // if (this.wordUncertainties) {
    //   let uncertaintiesQuantas = createQuantasFromIntervals(
    //     this.wordTimeIntervals,
    //     quantaMS,
    //     endTime,
    //     this.wordUncertainties
    //   );
    //   uncertaintiesQuantas = createScaledQuantasMax1(
    //     uncertaintiesQuantas,
    //     80.0
    //   );
    //   return uncertaintiesQuantas;
    // } else {
    //   return this.dummyQuantas;
    // }
  }

  @computed({ keepAlive: true })
  get semiFinalQuantas(): number[] {
    let result = createQuantasOverride(
      this.computedQuantas,
      this.trickyEditsValues
    );
    return result;
  }

  wordsFromIndexes(indexes: number[]): Word[] {
    return indexes.map(i => this.words.values[i]);
  }

  wordsTimeIntervalsFromIndexes(indexes: number[]): Intervals {
    const timeIntervals = this.words.timeIntervals;
    const intervalObjects = indexes.map(i => timeIntervals.intervalAt(i));
    return fromIntervals(intervalObjects);
  }

  @computed({ keepAlive: true })
  get finalTrickyWordIndexes(): number[] {
    let indexes = getWordIndexesOverlapQuantas(
      this.words,
      this.semiFinalQuantas,
      this.quantaMS,
      0.6
    );
    indexes = filterSolitaryIndexes(indexes);
    return indexes;
  }

  @computed({ keepAlive: true })
  get finalTrickyWordsRatio(): number {
    const indexes = this.finalTrickyWordIndexes;
    const result = indexes.length / this.words.values.length;
    return result;
  }

  @computed({ keepAlive: true })
  get selectedTrackQuantas(): number[] {
    switch (this.quantaTrackSelection) {
      case 'UNCERTAINTY':
        return this.uncertaintiesQuantas;
      case 'CHARS_PACE':
        return this.charsPaceQuantas;
      case 'WORDS_PACE':
        return this.wordsPaceQuantas;
      default:
        return null;
    }
  }

  @computed({ keepAlive: true })
  get selectedTrackTrickyWordsRatio(): number {
    const quantas = this.selectedTrackQuantas;
    if (!quantas) {
      return 0;
    }
    const passed = createQuantasAnyCutoff([quantas], this.trickyCutoffValue);
    const indexes = getWordIndexesOverlapQuantas(
      this.words,
      passed,
      this.quantaMS,
      0.6
    );
    const result = indexes.length / this.words.values.length;
    return result;
  }

  @computed({ keepAlive: true })
  get finalQuantas(): number[] {
    const indexes = this.finalTrickyWordIndexes;
    const intervals = this.wordsTimeIntervalsFromIndexes(indexes);
    const result = createQuantasFromIntervals(
      intervals,
      this.quantaMS,
      this.endTime
    );
    return result;
  }

  @computed({ keepAlive: true })
  get existingTrickyQuantas(): number[] {
    const indexes = this.existingTrickyWordIndexes;
    const intervals = this.wordsTimeIntervalsFromIndexes(indexes);
    const result = createQuantasFromIntervals(
      intervals,
      this.quantaMS,
      this.endTime
    );
    return result;
  }

  @computed({ keepAlive: true })
  get trickyWords(): WordElement[] {
    const indexes = this.finalTrickyWordIndexes;
    const words = this.wordsFromIndexes(indexes);
    return words;
  }

  @computed({ keepAlive: true })
  get existingTrickyWords(): WordElement[] {
    const indexes = this.existingTrickyWordIndexes;
    const words = this.wordsFromIndexes(indexes);
    return words;
  }

  setQuantaTrackSelection(key: string) {
    this.quantaTrackSelection = key;
  }

  cancelSelections() {
    if (!this.wordRangeSelection) {
      return;
    }
    this.wordRangeSelection = null;
  }

  normalizeWordRange(range: WordIdRange) {
    if (range) {
      const startIndex = this.words.getIndex(range.begin);
      const endIndex = this.words.getIndex(range.end);
      if (startIndex > endIndex) {
        return { begin: range.end, end: range.begin };
      } else {
        return range;
      }
    } else {
      return range;
    }
  }

  setWordRangeSelection(range0: WordIdRange) {
    const range = this.normalizeWordRange(range0);
    this.wordRangeSelection = range;
  }

  wordRangeSelectTo(id: WordId) {
    if (this.wordRangeSelection) {
      this.setWordRangeSelection({
        begin: this.wordRangeSelection.begin,
        end: id,
      });
    } else if (this.currentWordId) {
      this.setWordRangeSelection({ begin: this.currentWordId, end: id });
    }
  }

  getTrickySelectionAudioInterval(): Interval {
    if (this.audioRegionSelection) {
      return this.audioRegionSelection;
    }
    if (this.wordRangeSelection) {
      const wordRange = this.wordRangeSelection;
      const wordIndexRange = this.words.idRangeToIndexRange(wordRange);
      const wordRangeInterval = this.words.timeIntervals.valueBounds(
        wordIndexRange.begin,
        wordIndexRange.end
      );
      return wordRangeInterval;
    }
    if (this.currentWordId) {
      return this.words.getTimeInterval(this.currentWordId);
    }
    return null;
  }

  getQuantaIndexRangeFromAudioInterval(interval: Interval): Interval {
    const begin = Math.floor(interval.begin / this.quantaMS);
    const end = Math.floor(interval.end / this.quantaMS);
    return { begin, end };
  }

  setTrickyEditOnQuantaRange(quantaIndexRange: Interval, edit: TrickyEdit) {
    const { begin, end } = quantaIndexRange;
    const edits = new Map<number, TrickyEdit>();
    for (let i = begin; i <= end; i++) {
      edits.set(i, edit);
    }
    AppRoot.trickyActions.updateTrickyEdits(edits);
  }

  setTrickyEditOnSelection(edit: TrickyEdit) {
    const interval = this.getTrickySelectionAudioInterval();
    if (interval) {
      const quantaIndexRange =
        this.getQuantaIndexRangeFromAudioInterval(interval);
      this.setTrickyEditOnQuantaRange(quantaIndexRange, edit);
    }
  }

  forceTrickyForSelection() {
    this.setTrickyEditOnSelection('IN');
  }

  forceNoTrickyForSelection() {
    this.setTrickyEditOnSelection('OUT');
  }

  clearTrickyOverrideForSelection() {
    this.setTrickyEditOnSelection('NOP');
  }

  @computed({ keepAlive: true })
  get existingTrickys(): EditorWordGroup[] {
    const unsorted = this.wordGroups.filter(
      group => group.subKind === 'TRICKY'
    );
    const sorted = unsorted.sort((a, b) => a.address - b.address);
    return sorted;
  }

  @computed({ keepAlive: true })
  get hasExistingTrickys(): boolean {
    return this.existingTrickys.length > 0;
  }

  @computed({ keepAlive: true })
  get existingTrickyWordIndexes(): number[] {
    const result: number[] = [];
    for (const group of this.existingTrickys) {
      const begin = group.address;
      const end = group.endAddress;
      for (let i = begin; i <= end; i++) {
        result.push(i);
      }
    }
    return result;
  }

  @computed({ keepAlive: true })
  get globalOutputTrickyRatioInExisting(): number {
    const existingIndexes = this.existingTrickyWordIndexes;
    const outputIndexes = this.finalTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, outputIndexes);
    const result = overlapIndexes.length / outputIndexes.length;
    return result;
  }

  @computed({ keepAlive: true })
  get existingTrickyRatioInGlobalOutput(): number {
    // TODO factor
    const existingIndexes = this.existingTrickyWordIndexes;
    const outputIndexes = this.finalTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, outputIndexes);
    const result = overlapIndexes.length / existingIndexes.length;
    return result;
  }

  @computed({ keepAlive: true })
  get existingAndGlobalOutputRatioOverlap() {
    // TODO factor
    const existingIndexes = this.existingTrickyWordIndexes;
    const outputIndexes = this.finalTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, outputIndexes);
    const result =
      overlapIndexes.length /
      (outputIndexes.length + existingIndexes.length - overlapIndexes.length);
    return result;
  }

  @computed({ keepAlive: true })
  get existingAndGlobalOutputOddsRatio() {
    // TODO factor
    return (
      this.globalOutputTrickyRatioInExisting / this.existingTrickyWordsRatio
    );
  }

  @computed({ keepAlive: true })
  get existingTrickyRatioInSelected(): number {
    const quantas = this.selectedTrackQuantas;
    if (!quantas) {
      return 0;
    }
    const passed = createQuantasAnyCutoff([quantas], this.trickyCutoffValue);
    const trackIndexes = getWordIndexesOverlapQuantas(
      this.words,
      passed,
      this.quantaMS,
      0.6
    );
    const existingIndexes = this.existingTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, trackIndexes);
    const result = overlapIndexes.length / existingIndexes.length;
    return result;
  }

  @computed({ keepAlive: true })
  get selectedRatioInExistingTricky(): number {
    const quantas = this.selectedTrackQuantas;
    if (!quantas) {
      return 0;
    }
    const passed = createQuantasAnyCutoff([quantas], this.trickyCutoffValue);
    const trackIndexes = getWordIndexesOverlapQuantas(
      this.words,
      passed,
      this.quantaMS,
      0.6
    );
    const existingIndexes = this.existingTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, trackIndexes);
    const result = overlapIndexes.length / trackIndexes.length;
    return result;
  }

  @computed({ keepAlive: true })
  get selectedAndExistingRatioOverlap() {
    // TODO factor
    const quantas = this.selectedTrackQuantas;
    if (!quantas) {
      return 0;
    }
    const passed = createQuantasAnyCutoff([quantas], this.trickyCutoffValue);
    const trackIndexes = getWordIndexesOverlapQuantas(
      this.words,
      passed,
      this.quantaMS,
      0.6
    );
    const existingIndexes = this.existingTrickyWordIndexes;
    const overlapIndexes = overlappingValues(existingIndexes, trackIndexes);
    const result =
      overlapIndexes.length /
      (trackIndexes.length + existingIndexes.length - overlapIndexes.length);
    return result;
  }

  @computed({ keepAlive: true })
  get selectedAndExistingOddsRatio() {
    return this.selectedRatioInExistingTricky / this.existingTrickyWordsRatio;
  }

  @computed({ keepAlive: true })
  get selectedAndExistingYulesY() {
    const OR = this.selectedAndExistingOddsRatio;
    return Math.sqrt(OR - 1) / Math.sqrt(OR + 1);
  }

  @computed({ keepAlive: true })
  get existingTrickyWordsRatio(): number {
    const existingIndexes = this.existingTrickyWordIndexes;
    const result = existingIndexes.length / this.words.values.length;
    return result;
  }

  get wordStrings(): string[] {
    return this.words.values.map(word => (<any>word).text);
  }

  get wordTimeIntervals(): Intervals {
    return this.words.timeIntervals;
  }

  @computed
  get currentWordId(): WordId {
    return this.wordTracker.observableIsUnder();
  }

  @computed
  get currentSentenceId(): SentenceId {
    return this.sentenceTracker.observableIsUnder();
  }

  getWarningLevel(warningIndex: number) {
    return this.warningData[warningIndex] === 'silences' ? 1 : 0;
  }

  getWarningUiText(warningIndex: number) {
    const warningRange: WordIdRange = (
      this.warnings.values[warningIndex] as AdhocRangeElement
    )['range'];
    const warningWords = wordIdRangeToWordStrings(
      warningRange,
      this.elements.words
    );
    return (
      warningUiDescriptionLookup[this.warningData[warningIndex]] +
      warningWords.join(' ')
    );
  }

  setAudioRegionSelection(selection: Interval) {
    this.audioRegionSelection = selection;
  }

  addCue() {
    AppRoot.cueActions.addChaatInputCue();
  }

  toggleNavStop() {
    AppRoot.cueActions.toggleNavStop();
  }

  addShiftCue() {
    AppRoot.cueActions.addShiftCue();
  }

  addShiftEndCue() {
    AppRoot.cueActions.addShiftEndCue();
  }

  removeCueAtCurrent() {
    AppRoot.cueActions.removeCueAtCurrent();
  }

  createAudioRegion() {
    AppRoot.audioRegionActions.addUpdateFromAudioSelection();
  }

  removeAudioRegion() {
    AppRoot.audioRegionActions.removeFromAudioSelection();
  }

  createForceLinearAudioRegion() {
    AppRoot.forceLinearAudioRegionActions.addUpdateFromAudioSelection();
  }

  removeForceLinearAudioRegion() {
    AppRoot.forceLinearAudioRegionActions.removeFromAudioSelection();
  }

  createAudioMarker() {
    AppRoot.audioMarkerActions.addUpdateFromAudioPosition();
  }

  removeAudioMarker() {
    AppRoot.audioMarkerActions.removeFromAudioPosition();
  }

  setCurrentSentenceSignoff(signoff: boolean) {
    const sentenceId = this.currentSentenceId; // TODO this is calling function not getter
    if (sentenceId) {
      const sentence = this.sentences.getElement(sentenceId);
      const key = getSentenceTimestampingSignature(sentence, this.words);
      AppRoot.mutationActions.setChaatSignoff(key, signoff);
    }
  }

  @computed({ keepAlive: true })
  get segmentStopWordGapIntervals() {
    const wordTimeIntervals = this.wordTimeIntervals;
    const segmentStopWords = this.segmentStopWords;
    const words = this.words;
    const result: Interval[] = [];
    for (const stopWord of segmentStopWords.values) {
      const stopWordIndex = words.getIndex(stopWord.id);
      result.push(wordTimeIntervals.getPriorGapInterval(stopWordIndex));
    }
    return result;
  }

  initKerning() {
    // const biggerGaps = this.words.timeIntervals.fromGapIntervals(200);
    // const kerningPoints = new Intervals(biggerGaps.midPoints);
    const kerningMinDuration = 1000;
    const kerningPause = (i: Interval) => {
      return kerningMinDuration - size(i);
    };
    let kerningGaps = this.segmentStopWordGapIntervals.filter(
      i => kerningPause(i) > 50
    );
    const kerningPoints = kerningGaps.map(i =>
      Math.floor(i.begin + (i.end - i.begin) * 0.3)
    );
    const kerningPauses = kerningGaps.map(i => kerningPause(i));
    const player = AppRoot.player;
    player.setKerningUseVolumeRamp(false);
    player.setKerningData(kerningPoints, kerningPauses);
    player.kerningEnable(true);
  }

  reinitKerning() {
    if (AppRoot.transportState.kerningEnabled) {
      this.initKerning();
    }
  }

  deselect() {
    AppRoot.appBus.emit('deselect', null);
    this.cancelSelections(); // TODO factor better
  }

  updateFromEpisodeData(episodeData: ChaatEpisodeData) {
    runInAction(() => {
      this.episodeData = episodeData;
      this.episodeKey = episodeData.episodeKey;
      this.elements = episodeData.content;
      this.words = episodeData.wordsWithTimes;
      this.unredactedWords = this.words;
      this.sentences = this.elements.filterByKind(EKind.SENTENCE);
      this.nonVoiceAudioRegions = episodeData.nonVoiceAudioRegions;
      this.nonVoiceAudioRegionIntervals =
        episodeData.nonVoiceAudioRegionIntervals;
      this.forceLinearAudioRegions = episodeData.forceLinearAudioRegions;
      this.forceLinearAudioRegionIntervals =
        episodeData.forceLinearAudioRegionIntervals;
      this.audioMarkers = episodeData.audioMarkers;
      this.audioMarkerHitIntervals = episodeData.audioMarkerHitIntervals;
      this.segmentTimeIntervals = episodeData.segmentTimeIntervals;
      this.segmentStopWords = episodeData.segmentStopWords;
      this.candidateSegmentStopWords = episodeData.candidateSegmentStopWords;
      this.warnings = episodeData.warnings;
      this.majorWarnings = episodeData.majorWarnings;
      this.minorWarnings = episodeData.minorWarnings;
      this.warningSentences = episodeData.warningSentences;
      this.warningTimeIntervals = episodeData.warningTimeIntervals;
      this.warningData = episodeData.warningData;
      this.interpolatedTimeIntervals = episodeData.interpolatedTimeIntervals;
      this.interpolatedData = episodeData.interpolatedData;
      this.cuesData = episodeData.wordMarkers;
      this.cuedWords = episodeData.cuedWords;
      this.cueDisplayTimeIntervals = episodeData.cueDisplayTimeIntervals;
      this.transcriptWords = episodeData.transcriptWords;
      this.transcriptWordTimeIntervals =
        episodeData.transcriptWordTimeIntervals;
      this.notchTimeIntervals = episodeData.notchTimeIntervals;
      this.unsignedoffSentences = episodeData.chaatUnsignedoffSentences;
      this.trickyEdits = episodeData.trickyEdits;
      const trickyTrackDisable = episodeData.trickyBitsTrackDisable;
      if (trickyTrackDisable) {
        this.quantaTracksDisabledState.merge(trickyTrackDisable);
      }
      const trickyTrackScaleFactor = episodeData.trickyBitsTrackScaleFactor;
      if (trickyTrackScaleFactor) {
        this.quantaTracksScaleFactors.merge(trickyTrackScaleFactor);
      }
      const trickyCompressionSettings = episodeData.trickyCompressionSettings;
      if (trickyCompressionSettings) {
        this.compressionSettings.merge(trickyCompressionSettings);
      }
      // this.quantaTransforms =
      //   episodeData.trickyBitsSignalTransforms ?? this.quantaTransforms;
      this.audioUrls = episodeData.audioUrls;
      this.wordTracker = this.tracking.getTrackerWithKind(EKind.WORD);
      this.sentenceTracker = this.tracking.getTrackerWithKind(EKind.SENTENCE);
      // TODO hacking
      this.uncertaintyScores = episodeData.wordUncertainties;
      // if (!this.uncertaintiesQuantas && this.wordUncertainties) {
      //   this.initTestQuantas();
      // }
      if (AppRoot.appRoot.mode === 'tricky') {
        // TODO this is not efficient, make computed on episodeData
        this.wordGroups = episodeData.wordGroups;
        const wordValues = this.words.values.map(w => redactWord({ ...w }));
        const wordList = CreateChaatEditorialWordElementList({
          elements: wordValues,
          episodeKey: this.episodeKey,
          wordRecordsData: getWordRecordsData(this.words),
        });
        this.redactedWords = wordList;
        this.trickyAsrWordTimeIntervals = episodeData.trickyAsrIntervals;
        this.trickyAsrWordUncertainties =
          episodeData.trickyAsrWordUncertainties;
      }

      // TODO something more context sensitive to set the nav point here?
      AppRoot.navigationState.navigationPoint = AppRoot.navigation
        .getNavigatorForKey('SEGMENT')
        .navigationPoint(0);
      this.stateVersion++;
    });
  }
}
