const isThisWindows = navigator?.platform?.indexOf('Win') > -1;

export function formatShortcutText(shortcut: string) {
  const metaSymbol = isThisWindows ? '^' : '⌘';
  const shiftSymbol = '⇧';
  const altSymbol = isThisWindows ? '⎇' : '⌥';
  const ctrlSymbol = '⌃';

  return (
    shortcut
      .replace('meta', metaSymbol)
      .replace('shift', shiftSymbol)
      .replace('alt', altSymbol)
      .replace('ctrl', ctrlSymbol)
      .replace('up', '↑')
      .replace('down', '↓')
      .replace('left', '←')
      .replace('right', '→')
      .replace('enter', '↵')
      // .replace(/\+/g, '-')
      .replace(/\+/g, ' ')
      .toUpperCase()
  );
}
