import { useRef, useEffect } from 'react';

export const useTitle = (title = '') => {
  const prefix = 'Masala'; // I don't know the conventions for making things configurable
  if (prefix) {
    title = `${title} | ${prefix}`;
  }
  const prevTitleRef = useRef(document.title);

  document.title = title || '';

  useEffect(() => {
    const prevTitle = prevTitleRef.current;
    return () => {
      document.title = prevTitle;
    };
  }, []);
};
