import { chaatToolModel, mutationActions, transportState } from '../app-root';
import { AudioRegionActions } from '@masala-lib/editorial/models/actions/audio-region-actions';
import { AudioRegionWriteDto } from '@masala-lib/editorial/chaat/chaat-types';

export class ChaatAudioRegionActions extends AudioRegionActions {
  addUpdate(data: AudioRegionWriteDto): void {
    this.mutationActions.addUpdateChaatAudioRegion(data, 'NON_VOICE');
  }
  get mutationActions() {
    return mutationActions;
  }
  get currentAudioPosition() {
    return transportState.audioPosition;
  }
  get audioSelectionRegion() {
    return chaatToolModel.audioRegionSelection;
  }
  get currentAudioRegions() {
    return chaatToolModel.nonVoiceAudioRegions;
  }
  get currentAudioRegionIntervals() {
    return chaatToolModel.nonVoiceAudioRegionIntervals;
  }
}
