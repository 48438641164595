import { ActivityGuideData } from '../catalog-types';
import { ActivityGuide } from '../models/activity-guide';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';

export class ActivityGuideManager extends EntityManager<
  ActivityGuide,
  ActivityGuideData
> {
  get collectionName(): string {
    return CatalogCollections.ACTIVITY_GUIDE_METADATA;
  }

  createModel(data: { id: string; authorId: string }): ActivityGuide {
    return new ActivityGuide(data);
  }

  // todo: do a db query instead of assuming all data already loaded
  assignedToUnit(unitId: string) {
    return this.list.find(item => {
      return item.data.unitId === unitId;
    });
  }

  private static instance: ActivityGuideManager;

  public static getInstance(): ActivityGuideManager {
    if (!ActivityGuideManager.instance) {
      ActivityGuideManager.instance = new ActivityGuideManager();
      ActivityGuideManager.instance.listenAll();
    }
    return ActivityGuideManager.instance;
  }
}
