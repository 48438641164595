import { epochSecondsFloat, randomString } from '@masala-lib/utils';
import {
  CollectionReference,
  DocumentReference,
  Firestore,
} from '@platform/firebase-types';
import nodefs from 'fs/promises';
import { db } from '@platform/firebase-init';
import { RT } from '@tikka/type-utils';
import { createLogger } from '@app/logger';
import {
  InputsNotReady,
  JOBS_COLLECTION_NAME,
  JobData,
  JobId,
  JobLogger,
  JobRequestParams,
  JobStatusResult,
  LogMessage,
  StageData,
  StageDefinition,
  StageHandler,
  StageStatus,
  StagesDataMap,
} from './job-types';
import { JobLoggerImpl } from './job-logger';

const consoleLogger = createLogger('job-kind');

export function getFirestoreDb(): Firestore {
  return db;
}

export function shallowCopyJobData(data: JobData): JobData {
  return { ...data, shallowCopy: true, stages: null };
}

export abstract class JobKind {
  private _stageDefinitionsList: StageDefinition[] = null;
  private _stageDefinitionLookup: Map<string, StageDefinition> = null;
  private _stageNamesList: string[] = null;
  private _stageNamesSet: Set<string> = null;
  private pollingTimer: RT<typeof setInterval> = null;
  private lastPollTimestamp: number;

  abstract get kind(): string;

  protected abstract get stageMethods(): readonly (readonly [
    string,
    StageHandler
  ])[];

  private get firestoreCollection() {
    const db = getFirestoreDb();
    return db.collection(JOBS_COLLECTION_NAME) as CollectionReference<JobData>;
  }

  private firestoreDocRef(id: JobId): DocumentReference<JobData> {
    const collection = this.firestoreCollection;
    return collection.doc(id);
  }

  private createId(params: JobRequestParams): JobId {
    return randomString(12);
  }

  protected async afterCreateJobInFirestore(data: JobData): Promise<void> {
    // subclasses can override to create references within masala firestore data for example
    return;
  }

  protected async afterJobStatusChange(id: string): Promise<void> {
    // subclasses can override to denormalize data for example
    return;
  }

  async createJob(params: JobRequestParams): Promise<JobId> {
    // create long job document in firestore with associated params
    const id = this.createId(params);
    const docRef = this.firestoreDocRef(id);
    const data = {} as JobData;
    data.kind = this.kind;
    data.id = id;
    data.archived = false;
    // apparently needs to be assigned for the record to be even included at all in ordered firestore query
    data.startTimestamp = this.currentTimestamp;
    data.status = 'IN_PROGRESS';
    data.params = { ...params };
    try {
      if (params.name === 'stillborn') {
        throw Error('stillborn');
      }
      data.stages = this.generateEmptyStageDataFromIndex(0);
      await docRef.set(data);
      await this.afterCreateJobInFirestore(data);
    } catch (e) {
      const error = e as Error;
      data.status = 'FAILED';
      // todo: figure out why this was fine in vscode, but failed tsc check
      // consoleLogger.error(error.stack);
      consoleLogger.error(error.stack);
      assignErrorToData(error, data);

      await docRef.set(data);
    }
    // letting async call run free
    this.continue(data);
    // TODO startup polling to push forward waiting jobs, think about where
    this.setupPollingTimerIfNeeded();
    return id;
  }

  get currentTimestamp() {
    return epochSecondsFloat();
  }

  private get stageDefinitionList(): StageDefinition[] {
    // return stage definitions if already available
    if (this._stageDefinitionsList) {
      return this._stageDefinitionsList;
    }

    // map stage methods array to new stage definitions array
    this._stageDefinitionsList = this.stageMethods.map(
      ([name, handler], index: number) => {
        // build name of stage from name of method snake capatilized
        return {
          name,
          index,
          handler: handler.bind(this),
        };
      }
    );
    return this._stageDefinitionsList;
  }

  private get stageDefinitionLookup() {
    if (this._stageDefinitionLookup) {
      return this._stageDefinitionLookup;
    }
    this._stageDefinitionLookup = new Map(
      this._stageDefinitionsList.map(def => [def.name, def])
    );
    return this._stageDefinitionLookup;
  }

  get stageNamesList(): string[] {
    if (this._stageNamesList) {
      return this._stageNamesList;
    }
    this._stageNamesList = this.stageDefinitionList.map(d => d.name);
    return this._stageNamesList;
  }

  private get stageNamesSet(): Set<string> {
    if (this._stageNamesSet) {
      return this._stageNamesSet;
    }
    this._stageNamesSet = new Set(this.stageNamesList);
    return this._stageNamesSet;
  }

  computeJobOverallState(data: JobData): JobData {
    // TODO think about default state of exitTimestamp so it blows away old state when saved?
    const state: JobData = {} as any;
    // capture start timestamp from first stage data
    state.startTimestamp = this.retrieveStageData(
      this.stageNamesList[0],
      data
    ).startTimestamp;
    // iterate stage data searching for last exited stage
    let lastExitedStageName: string = null;
    let lastRunStageName: string = null;
    for (const stageName of this.stageNamesList) {
      const stageData = this.retrieveStageData(stageName, data);
      if (stageData.startTimestamp) {
        lastRunStageName = stageName;
      }
      if (stageData.exitTimestamp) {
        lastExitedStageName = stageName;
      } else {
        break;
      }
    }
    state.lastRunStage = lastRunStageName;

    if (lastRunStageName) {
      const stageDefinition = this.stageDefinitionLookup.get(lastRunStageName);
      const stageData = this.retrieveStageData(stageDefinition.name, data);
      if (
        stageData.status === 'FAILED' ||
        stageData.status === 'WAITING' ||
        stageDefinition.index === this.stageDefinitionList.length - 1
      ) {
        state.exitTimestamp = stageData.exitTimestamp;
        state.status = stageData.status as any;
        return state;
      }
    }
    // otherwise in progress
    state.status = 'IN_PROGRESS';
    return state;
  }

  private generateEmptyStageDataFromIndex(index: number): StagesDataMap {
    const result = {} as StagesDataMap;
    const stages = this.stageDefinitionList.slice(index);
    for (const stage of stages) {
      result[stage.name] = {
        stageName: stage.name,
        stageOutputData: {},
      } as StageData;
    }
    return result;
  }

  async fetchJobData(id: JobId): Promise<JobData> {
    // fetch all current job data from firestore using collection and id
    const docRef = this.firestoreDocRef(id);
    return (await docRef.get()).data();
  }

  private async mergeStageData(
    id: JobId,
    stageName: string,
    data: StageData
  ): Promise<void> {
    // write all data into stage property in firestore
    if (!this.stageNamesSet.has(stageName)) {
      throw new Error('cannot find stage named: ' + stageName);
    }
    const update = {
      stages: {
        [stageName]: data,
      },
    } as any;
    const docRef = this.firestoreDocRef(id);
    await docRef.set(update, { merge: true });
  }

  protected registerLocalTmpFilenameForStage(
    stageData: StageData,
    filename: string
  ) {
    const tmpFilenames: string[] = stageData.localTmpFileNames ?? [];
    tmpFilenames.push(filename);
    stageData.localTmpFileNames = tmpFilenames;
  }

  private async removeLocalTmpFilesForStage(
    stageData: StageData
  ): Promise<boolean> {
    const tmpFilenames = stageData.localTmpFileNames;
    if (!tmpFilenames || tmpFilenames.length === 0) {
      return false;
    }
    const uniqFilenames = new Set(tmpFilenames).values();
    for (const fname of uniqFilenames) {
      await nodefs.unlink(fname);
    }
    stageData.localTmpFileNames = null;
    return true;
  }

  retrieveStageData(stageName: string, data: JobData): StageData {
    // retrieve the value of stage name from job data verifying exists
    if (!(stageName in data.stages)) {
      throw new Error('field does not exist in job data:' + stageName);
    }
    return data.stages[stageName];
  }

  retrieveStageOutputData(stageName: string, data: JobData): any {
    return this.retrieveStageData(stageName, data).stageOutputData;
  }

  private async executeAtStage(
    id: JobId,
    stage: string
  ): Promise<JobStatusResult> {
    await this.resetStageDataFromStage(id, stage);
    const stageDefinition = this.stageDefinitionLookup.get(stage);
    consoleLogger.info(
      `executeAtStage - stageDef: ${String(stageDefinition?.name)}`
    );

    const jobData = await this.fetchJobData(id);
    const docRef = this.firestoreDocRef(id);
    const stageData = this.retrieveStageData(stage, jobData);
    stageData.startTimestamp = this.currentTimestamp;
    stageData.status = 'IN_PROGRESS';
    stageData.logMessageMap = {};
    // reset data from previous attempt (relevant when polling)
    await docRef.update({ [`stages.${stage}`]: stageData } as any);

    await this.mergeStageData(id, stage, stageData);
    let error: Error = null;
    const flushFn = (logMessage: LogMessage) => {
      const path = `stages.${stage}.logMessageMap.${logMessage.counter}`;
      docRef.update(
        {
          [path]: logMessage,
        } as any /*@jason can you figure out the proper typing here?*/,
        { merge: true }
      );
    };
    const logger = new JobLoggerImpl({ jobId: id, stageName: stage, flushFn });
    logger.info(`STARTING STAGE: ${stage}`);
    try {
      await stageDefinition.handler({
        jobData,
        stageData: stageData,
        stageOutputData: stageData.stageOutputData,
        name: stageDefinition.name,
        logger,
      });
    } catch (e) {
      error = e as Error;
    }
    let stageStatus: StageStatus = 'SUCCEEDED';
    let exitTimestamp = this.currentTimestamp;
    if (error) {
      if (error instanceof InputsNotReady) {
        stageStatus = 'WAITING';
        logger.info('inputs not ready - waiting');
        exitTimestamp = null;
      } else {
        stageStatus = 'FAILED';
        assignErrorToData(error, stageData, logger);
      }
      logger.info(`EXITED STAGE ${stage} with status ${stageStatus}`);
    }
    await this.removeLocalTmpFilesForStage(stageData);
    stageData.status = stageStatus;
    stageData.exitTimestamp = exitTimestamp;
    // stageData.logMessages = log.logArray;
    stageData.logMessageMap = logger.messageMap;

    const update = this.computeJobOverallState(jobData);
    update.stages = {
      [stage]: stageData,
    };
    await docRef.set(update, { merge: true });
    this.afterJobStatusChange(jobData.id);
    let result: JobStatusResult = {
      status: update.status,
      lastRunStage: stage,
    };
    if (stageStatus === 'SUCCEEDED' && result.status === 'IN_PROGRESS') {
      result = await this.executeAtStage(
        id,
        this.stageNamesList[stageDefinition.index + 1]
      );
    }
    return result;
  }

  private async resetStageDataFromStage(
    id: JobId,
    stage: string,
    resetStageData: StageData = null as any
  ): Promise<void> {
    const stageDefinition = this.stageDefinitionLookup.get(stage);

    const empty = {} as any;
    const stagesData = this.generateEmptyStageDataFromIndex(
      stageDefinition.index
    );

    empty.stages = stagesData;
    const docRef = this.firestoreDocRef(id);
    await docRef.set(empty, { merge: true });
    if (resetStageData) {
      await docRef.set(
        { stages: { [stage]: resetStageData } },
        { merge: true }
      );
    }
  }

  private findContinueStage(data: JobData): StageDefinition {
    // find stage not run or with status failed or inputs not ready
    for (const stageDefinition of this.stageDefinitionList) {
      const stageData = this.retrieveStageData(stageDefinition.name, data);
      if (
        !stageData.startTimestamp ||
        stageData.status === 'FAILED' ||
        stageData.status === 'WAITING'
      ) {
        return stageDefinition;
      }
      if (stageData.status === 'IN_PROGRESS') {
        return null;
      }
    }
    // should be impossible?
    throw new Error('error finding continue job stage');
  }

  private async continue(
    data: JobData,
    allowRetry = false
  ): Promise<JobStatusResult> {
    if (!allowRetry && data.status === 'FAILED') {
      return {
        status: 'CANNOT_RERUN_JOB_WITHOUT_RETRY_OPTION',
        lastRunStage: 'UNKNOWN',
      };
    }

    if (data.status === 'SUCCEEDED') {
      return {
        status: 'JOB_ALREADY_SUCCEEDED',
        lastRunStage: 'UNKNOWN',
      };
    }

    // resume on the continue stage if exists
    const continueStage = this.findContinueStage(data);
    consoleLogger.info(`continueStage: ${continueStage}`);
    if (continueStage) {
      return await this.executeAtStage(data.id, continueStage.name);
    }
    return {
      status: 'STAGE_ALREADY_RUNNING',
      lastRunStage: 'UKNOWN',
    };
  }

  async continueWithJobId(
    id: JobId,
    allowRetry = false
  ): Promise<JobStatusResult> {
    consoleLogger.info(`continueWithJobId - id: ${id}`);
    // lookup job data and continue
    const jobData = await this.fetchJobData(id);
    consoleLogger.info(`jobData: ${JSON.stringify(jobData)}`);
    return await this.continue(jobData, allowRetry);
  }

  async continueWithWaitingJobs(): Promise<void> {
    const collection = this.firestoreCollection;
    consoleLogger.info('ATTEMPT CONTINUE WITH WAITING');
    const result = await collection
      .where('status', '==', 'WAITING')
      .where('kind', '==', this.kind)
      .limit(3)
      .get();
    result.forEach(jobSnapshot => {
      const jobData = jobSnapshot.data();
      consoleLogger.info(`CONTINUE WITH JOB ID: ${jobData.id}`);
      this.continue(jobData, false);
    });
  }

  private continueWithWaitingCallback() {
    this.lastPollTimestamp = epochSecondsFloat();
    this.continueWithWaitingJobs();
  }

  setupPollingTimerIfNeeded() {
    if (!this.pollingTimer) {
      this.pollingTimer = setInterval(() => {
        this.continueWithWaitingCallback();
      }, 90 * 1000);
    }
  }

  protected setRelativePollTimesForStageAsyncOutputs(
    {
      start,
      end,
    }: {
      start: number; // seconds
      end: number; // seconds
    },
    stageData: StageData
  ) {
    const currentTime = this.currentTimestamp;
    stageData.startPollAsyncOutputsTimestamp = start ? start + currentTime : 0;
    stageData.endPollAsyncOutputsTimestamp = end ? end + currentTime : 0;
  }

  protected checkCanPollAsyncOutForStage(stageName: string, jobData: JobData) {
    const stageData = this.retrieveStageData(stageName, jobData);
    const currentTime = this.currentTimestamp;
    const startPollTime = stageData.startPollAsyncOutputsTimestamp;
    const endPollTime = stageData.endPollAsyncOutputsTimestamp;
    if (startPollTime && currentTime < startPollTime) {
      throw new InputsNotReady(
        `output of stage ${stageName} not reached valid poll time`,
        startPollTime - currentTime
      );
    }
    if (endPollTime && currentTime > endPollTime) {
      throw new Error(`exceeded valid output time for stage ${stageName}`);
    }
  }
}

export function assignErrorToData(
  error: Error,
  data: { errorMessage?: string; errorStack?: string },
  logger?: JobLogger
) {
  const errorMessage = `${error.name}: ${error.message}`;
  const errorStack = error.stack;
  data.errorMessage = errorMessage;
  data.errorStack = errorStack;
  logger?.error(errorMessage);
  logger?.debug(errorStack);
}
