import { Alert, AlertMessages } from '../../../misc/alert-messages';
import { WordId } from '@tikka/basic-types';
import {
  ElementList,
  Element,
  Sentence,
  CreateElementList,
  ElementId,
  IDTOf,
} from '../../../editor-aliases';
import {
  getSentenceWordIdRange,
  getSentenceWordStrings,
} from '../../../content-utils';
import { EKind } from '../../../element-kinds';
import { MutationActions } from '../../db/mutation-actions';
import { doDiffAndPatch } from '../../ids/positional-diff-patch';
import {
  copyWordRecords,
  copyWordRecordsRangeWithOuterBounds,
  createWordRecordsData,
  getActiveWordRecords,
  getMutationFuncs,
  insertWordsBetweenIds,
  replaceWordRecordsRangeWithOuterBounds,
} from '../../ids/word-ids';
import { isEmpty } from 'lodash';
import { notNil } from '@utils/conditionals';
import {
  getTranscriptWordsFromString,
  verbatimDisallowedChars,
  hasVerbatimDisallowedChars,
  stringArraysAreEqual,
  strongNormalizeWordArray,
  normalizeSpecialChars,
} from '../../../misc/editorial-string-utils';
import { Perms } from '../../db/permissions';
import {
  EditorElementList,
  EditorSentence,
  SentenceWriteDto,
  SpanAnchor,
} from '../../../editorial-types';
import { WordRecord, WordRecordsData } from '../../../editorial-types-aside';
import { getWordRecordsData } from '../../../editorial-word-element-list';

function sanityCheckVerbatimUpdate(
  oldWordsRecordsData: WordRecordsData,
  newWordsRecordsData: WordRecordsData,
  oldSentences: Sentence[]
) {
  let mismatchRegionsCount = 0;
  let mismatchCount = 0;
  let maxMismatchCount = 0;
  const getOldIndex = oldWordsRecordsData.getIndexActive;
  const getNewIndex = newWordsRecordsData.getIndexActive;
  const oldWords = oldWordsRecordsData.activeWordRecords.map(
    record => record.text
  );
  const newWords = newWordsRecordsData.activeWordRecords.map(
    record => record.text
  );

  // all word text is not empty string or undefined
  const allAreWords = (words: string[]) => {
    for (const word of words) {
      if (isEmpty(word.trim())) {
        // TODO check implementation
        return false;
      }
    }
    return true;
  };

  if (!allAreWords(oldWords) || !allAreWords(newWords)) {
    return false;
  }

  for (const [index, sentence] of oldSentences.entries()) {
    let anchor = sentence.anchor;
    const sentenceStartWordId = anchor.wordId;
    let sentenceEndWordId;
    if (index < oldSentences.length - 1) {
      sentenceEndWordId = oldSentences[index + 1].anchor.wordId;
    } else {
      const allRecords = oldWordsRecordsData.allWordRecords;
      sentenceEndWordId = allRecords[allRecords.length - 1].id;
    }
    let oldSentenceStartIndex = getOldIndex(sentenceStartWordId);
    if (oldSentenceStartIndex < 0) {
      oldSentenceStartIndex = 0;
    }
    let newSentenceStartIndex = getNewIndex(sentenceStartWordId);
    if (newSentenceStartIndex < 0) {
      newSentenceStartIndex = 0;
    }
    const oldSentenceEndIndex = getOldIndex(sentenceEndWordId) - 1;
    const newSentenceEndIndex = getNewIndex(sentenceEndWordId) - 1;
    const oldSentenceWords = [];
    for (let i = oldSentenceStartIndex; i <= oldSentenceEndIndex; i++) {
      oldSentenceWords.push(oldWords[i]);
    }
    const newSentenceWords = [];
    for (let i = newSentenceStartIndex; i <= newSentenceEndIndex; i++) {
      newSentenceWords.push(newWords[i]);
    }
    if (!allAreWords(oldSentenceWords) || !allAreWords(newSentenceWords)) {
      return false;
    }
    let mismatch = false;
    if (oldSentenceWords.length !== newSentenceWords.length) {
      mismatch = true;
    }
    if (!mismatch) {
      mismatch =
        JSON.stringify(oldSentenceWords) !== JSON.stringify(newSentenceWords);
    }
    if (mismatch) {
      if (mismatchCount === 0) {
        mismatchRegionsCount = mismatchRegionsCount + 1;
      }
      mismatchCount++;
      if (mismatchCount > maxMismatchCount) {
        maxMismatchCount = mismatchCount;
      }
    } else {
      mismatchCount = 0;
    }
  }
  return mismatchRegionsCount <= 1 && maxMismatchCount <= 2;
}

function computeVerbatimUpdate(
  sentenceId: IDTOf<Sentence>,
  newSentenceVerbatim: string,
  currentSentences: ElementList<Sentence>,
  wordRecordsData: WordRecordsData
) {
  const sentence = currentSentences.getElement(sentenceId);
  const wordElements = currentSentences.words;
  const sentenceWordIdRange = getSentenceWordIdRange(sentence, wordElements);
  const currentDomainWords = currentSentences.words;
  const newSentenceWords = getTranscriptWordsFromString(newSentenceVerbatim);
  const currentSentenceWords = getSentenceWordStrings(
    sentence,
    currentDomainWords
  );
  if (stringArraysAreEqual(newSentenceWords, currentSentenceWords)) {
    return null;
  }
  const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
  const normalizedCurrentWords = strongNormalizeWordArray(currentSentenceWords);
  const sentenceStartWordIndex = wordRecordsData.getIndexActive(
    sentenceWordIdRange.begin
  );
  const sentenceStartId =
    wordRecordsData.activeWordRecords[sentenceStartWordIndex].id;
  const sentenceEndIdInclusive = sentenceWordIdRange.end;
  const editingWordRecords: WordRecord[] = copyWordRecordsRangeWithOuterBounds(
    wordRecordsData,
    sentenceStartId,
    sentenceEndIdInclusive
  );
  const currentStartIndex = currentSentences.address(sentenceId);
  const copyOfCurrentWordRecords = copyWordRecords(
    wordRecordsData.allWordRecords
  );
  if (editingWordRecords.length > 2) {
    const mutationFuncs = getMutationFuncs(editingWordRecords);
    const patchResult = doDiffAndPatch(
      normalizedCurrentWords,
      normalizedNewWords,
      mutationFuncs
    );
    const activeEditedWords = getActiveWordRecords(editingWordRecords);
    // zip in actual text for words
    for (const [index, word] of newSentenceWords.entries()) {
      activeEditedWords[index].text = word;
    }
    replaceWordRecordsRangeWithOuterBounds(
      createWordRecordsData(copyOfCurrentWordRecords),
      editingWordRecords
    );
  } else {
    insertWordsBetweenIds(
      newSentenceWords,
      createWordRecordsData(copyOfCurrentWordRecords),
      editingWordRecords[0].id,
      editingWordRecords[1].id
    );
  }
  const newWordRecordsData = createWordRecordsData(copyOfCurrentWordRecords);
  const newStartIndex = newWordRecordsData.getIndexActive(
    sentenceWordIdRange.begin
  );
  // const newWords: string[] = [];
  // for (const s of currentSentences.elements) {
  //   if (s.id === sentenceId) {
  //     newWords.push(...newSentenceWords);
  //   } else {
  //     newWords.push(...getSentenceWordStrings(s, currentDomainWords)); // TODO refactor functions?
  //   }
  // }
  let adjustedStartWordId = null;
  if (
    currentStartIndex !== newStartIndex ||
    newStartIndex === newWordRecordsData.activeWordRecords.length - 1
  ) {
    if (currentStartIndex < newWordRecordsData.activeWordRecords.length) {
      adjustedStartWordId =
        newWordRecordsData.activeWordRecords[currentStartIndex].id;
    }
  }

  return {
    newWordRecordsData,
    adjustedStartWordId,
  };
}

// [<AbstractClass>]
export abstract class VerbatimActions {
  getCurrentSentences() {
    return this.content.filterByKind(EKind.SENTENCE);
  }
  getCurrentWordGroups() {
    return this.content.filterByKind(EKind.WORD_GROUP);
  }

  computeAndSanityCheckVerbatimUpdate(
    sentenceId: IDTOf<Sentence>,
    content: string,
    currentSentences: ElementList<Sentence>
  ) {
    // const currentWordRecordsData = this.content.words.__wordRecordsData;
    const currentWordRecordsData = getWordRecordsData(this.content.words);
    const verbatimUpdate = computeVerbatimUpdate(
      sentenceId,
      content,
      currentSentences,
      currentWordRecordsData
    );
    if (!verbatimUpdate) {
      return null;
    }

    const newWordRecordsData = verbatimUpdate.newWordRecordsData;

    if (
      sanityCheckVerbatimUpdate(
        currentWordRecordsData,
        newWordRecordsData,
        currentSentences.values
      )
    ) {
      return verbatimUpdate;
    } else {
      return null;
    }
  }

  computeSentenceShiftData(
    id: ElementId,
    newStartWordId: WordId,
    currentSentences: EditorElementList<EditorSentence> = null
  ) {
    currentSentences = currentSentences ?? this.getCurrentSentences();
    const sentence = currentSentences.getElement(id);
    // const sentenceAnchor = sentence.anchor as SpanExclusiveAnchor;
    const sentenceMutationData = {
      id: sentence.id,
      anchor: {
        wordId: newStartWordId,
      },
    };
    // const prevSentence = currentSentences.getElement(currentSentences.prevId(id));
    // if (prevSentence) {
    //   const prevSentenceMutationData = {
    //     id: prevSentence.id,
    //     anchor: {
    //       // 'sentence' elements are assumed to have the SpanAnchor flavor
    //       wordId: prevSentence.anchor.wordId,
    //       endWordIdExclusive: newStartWordId,
    //     },
    //   };
    //   return { sentence: sentenceMutationData, prevSentence: prevSentenceMutationData };
    // } else {
    return { sentence: sentenceMutationData };
    // }
  }

  updateSentence(
    id: IDTOf<Sentence>,
    content: string,
    allowZeroLength: boolean = false
  ): void {
    content = normalizeSpecialChars(content);
    const newSentenceWords = getTranscriptWordsFromString(content);
    if (newSentenceWords.length === 0 && !allowZeroLength) {
      this.alertMessages.add({
        ...Alert,
        text: 'cannot edit sentence to contain no words, delete instead',
      });
      return;
    }
    if (hasVerbatimDisallowedChars(content)) {
      this.alertMessages.add({
        ...Alert,
        text: `edit contained disallowed characters: ${verbatimDisallowedChars}`,
      });
      return;
    }

    const normalizedNewWords = strongNormalizeWordArray(newSentenceWords);
    const emptyWord = normalizedNewWords.find(
      (text: string) => text.length === 0
    );
    if (notNil(emptyWord)) {
      this.alertMessages.add({
        ...Alert,
        text: 'edit contains disallowed standalone punctuation',
      });
      return;
    }

    const sentence = this.content.getElement(id) as Sentence;
    const sentenceWordRange = getSentenceWordIdRange(
      sentence,
      this.content.words
    );
    const currentWordGroups =
      this.getCurrentWordGroups().getElementsIntersectWordIdRange(
        sentenceWordRange
      );
    let testCurrentWordGroups = true;
    if (currentWordGroups) {
      for (const group of currentWordGroups) {
        if (group.address > group.endAddress) {
          testCurrentWordGroups = false;
        }
      }
    } else {
      testCurrentWordGroups = false;
    }

    const verbatimUpdate = this.computeAndSanityCheckVerbatimUpdate(
      id,
      content,
      this.getCurrentSentences()
    );

    if (!verbatimUpdate) {
      this.alertMessages.add({ ...Alert, text: 'no changes saved' });
      return;
    }

    const newWordRecordsData = verbatimUpdate.newWordRecordsData;
    if (testCurrentWordGroups) {
      const getNewIndex = newWordRecordsData.getIndexActive;
      const isDeletedId = newWordRecordsData.isRemapped;
      for (const group of currentWordGroups) {
        const groupAnchor = group.anchor as SpanAnchor;
        const groupStart = getNewIndex(groupAnchor.wordId);
        const groupEnd = getNewIndex(groupAnchor.endWordId);
        if (groupStart >= groupEnd && isDeletedId(groupAnchor.endWordId)) {
          this.alertMessages.add({
            ...Alert,
            text: 'edit not allowed because completely destroys word region of existing word group',
          });
          return;
        }
      }
    }

    // TODO need to pass sentence updates not just Null
    let sentenceUpdates = null;
    if (verbatimUpdate.adjustedStartWordId) {
      const shiftAdjustments = this.computeSentenceShiftData(
        id,
        verbatimUpdate.adjustedStartWordId
      );
      sentenceUpdates = [...Object.values(shiftAdjustments)];
    } else {
      sentenceUpdates = [{ id: id, anchor: sentence.anchor }];
    }
    this.mutationActions.updateVerbatim(
      newWordRecordsData.allWordRecords,
      sentenceUpdates
    );
  }

  createSentence(text: string, adjacent: ElementId, above = true): void {
    // TODO
    // need to compute and sanity check verbatim update
    let startWordId = null;
    let address = null;
    let adjacentSentence = null;
    const currentSentences = this.getCurrentSentences();
    if (!above) {
      adjacent = currentSentences.nextId(adjacent);
    }
    if (adjacent) {
      adjacentSentence = currentSentences.getElement(adjacent);
      const adjacentSentenceAnchor = adjacentSentence.anchor;
      startWordId = adjacentSentenceAnchor.wordId;
      address = adjacentSentence.address;
    } else {
      const currentWords = currentSentences.words;
      // const wordRecords = currentWords.__wordRecordsData.allWordRecords;
      const wordRecords = getWordRecordsData(currentWords).allWordRecords;
      startWordId = wordRecords[wordRecords.length - 1].id;

      address = currentSentences.words.values.length;
      const currentWordElements = currentWords.values;
      text =
        currentWordElements[currentWordElements.length - 1].text + ' ' + text;
    }
    const endAddress = address - 1;
    const dummyAnchor = { wordId: startWordId };
    const dummySentenceId = 'SENTENCE:DUMMY' as IDTOf<Sentence>;
    const dummySentence: Sentence = <any>{
      kind: EKind.SENTENCE,
      id: dummySentenceId,
      anchor: dummyAnchor,
      address,
      endAddress,
    };

    const newSentences: Sentence[] = [];
    if (adjacentSentence) {
      for (const sentence of currentSentences.values) {
        if (sentence === adjacentSentence) {
          if (above) {
            newSentences.push(dummySentence);
            newSentences.push(sentence);
          } else {
            newSentences.push(sentence);
            newSentences.push(dummySentence);
          }
        } else {
          newSentences.push(sentence);
        }
      }
    } else {
      newSentences.push(...currentSentences.values);
      newSentences.push(dummySentence);
    }

    const existingWords = currentSentences.words;
    const newSentencesList = CreateElementList({
      elements: newSentences,
      words: existingWords,
      // wordRecordsDataParam: existingWords.__wordRecordsData,
    });
    const verbatimUpdate = this.computeAndSanityCheckVerbatimUpdate(
      dummySentenceId,
      text,
      newSentencesList
    );
    if (verbatimUpdate) {
      let sentenceUpdates = null;
      if (verbatimUpdate.adjustedStartWordId) {
        const shiftAdjustments = this.computeSentenceShiftData(
          dummySentenceId,
          verbatimUpdate.adjustedStartWordId,
          newSentencesList
        );
        const newSentenceData: SentenceWriteDto = shiftAdjustments.sentence;
        newSentenceData.id = null;
        sentenceUpdates = [...Object.values(shiftAdjustments)];
        this.mutationActions.updateVerbatim(
          verbatimUpdate.newWordRecordsData.allWordRecords,
          sentenceUpdates
        );
      } else {
        // TODO appears this code is never actually reached
        // because no adjacent case (append end) always has adjustedWordId, verify and remove?
        if (!adjacentSentence) {
          const shiftAdjustments = this.computeSentenceShiftData(
            dummySentenceId,
            verbatimUpdate.newWordRecordsData.activeWordRecords[endAddress].id,
            newSentencesList
          );
          const newSentenceData: SentenceWriteDto = shiftAdjustments.sentence;
          newSentenceData.id = null;
          sentenceUpdates = [...Object.values(shiftAdjustments)];
          this.mutationActions.updateVerbatim(
            verbatimUpdate.newWordRecordsData.allWordRecords,
            sentenceUpdates
          );
          return;
        }
        this.alertMessages.add({
          ...Alert,
          text: "can't add sentence with no content",
        });
      }
    } else {
      this.alertMessages.add({
        ...Alert,
        text: 'error with verbatim sanity check',
      });
    }
  }

  // @jason, should 'wordId' be a WordId or ElementId?
  // TODO enum for direction instead of above bool?
  splitSentence(id: ElementId, wordId: WordId, above: boolean): void {
    const wordGroups = this.getCurrentWordGroups();
    const address = this.content.words.getIndex(wordId);
    const boundaryWordGroup =
      wordGroups.getElementContainingWordAddress(address);
    if (boundaryWordGroup) {
      if (boundaryWordGroup.address !== address) {
        this.alertMessages.add({
          ...Alert,
          text: 'cannot split sentence in middle of word group',
        });
        return;
      }
    }
    const sentence = this.getCurrentSentences().getElement(id);
    if (sentence.anchor.wordId === wordId) {
      this.alertMessages.add({
        ...Alert,
        text: 'cannot split sentence in way that creates empty sentence',
      });
      return;
    }
    this.mutationActions.splitSentence(
      id,
      wordId,
      above,
      this.content.filterByKind(EKind.SENTENCE) as ElementList<Sentence>
    );
  }

  removeSentence(id: IDTOf<Sentence>): void {
    const sentence = this.getCurrentSentences().getElement(id);
    const sentenceWordRange = getSentenceWordIdRange(
      sentence,
      this.content.words
    );
    const wordGroups = this.getCurrentWordGroups();
    if (wordGroups.hasElementsIntersectWordIdRange(sentenceWordRange)) {
      this.alertMessages.add({
        ...Alert,
        text: "error can't delete sentence that contains word groups, delete word groups first",
      });
    } else {
      // TODO remove all words in sentence by passing empty string to update sentence with zero length override??
      this.mutationActions.checkPermissions(Perms.STANDARD);
      this.updateSentence(id, '', true);
      this.mutationActions.removeSentence(id); // TODO race condition with above async op, make internal JS.Promise returning implementation of updateSentence?
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get content(): ElementList<Element>;
  abstract get alertMessages(): AlertMessages;
}
