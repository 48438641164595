import React from 'react';
import Layout from './ui/layout';
import { appRoot } from './models/app-root';
import './ui/chaat.css';
import { useObserver } from 'mobx-react';
import {
  ERROR_NOT_LOADABLE,
  IN_PROGRESS,
  NOT_INITIATED,
} from '@masala-lib/firestore-db/firestore-doc-set';
import { isChrome } from '@masala-lib/utils';
import { useParams } from 'react-router-dom';

export const Root = () => {
  const { key = null, mode = null } =
    useParams<{ key: string; mode: string }>();

  const notChrome = !isChrome();
  if (notChrome) {
    return <h2>{'Chaat only works in Google Chrome'}</h2>;
  }

  if (mode === 'tricky') {
    appRoot.mode = 'tricky';
  }

  appRoot.loadEpisodePromise(key);

  // eslint-disable-next-line
  return useObserver(() => {
    if (
      appRoot.status !== IN_PROGRESS &&
      // is there a logic error here ?
      // this condition will always return 'true' since the types '"ERROR_NOT_LOADABLE" | "COMPLETE"' and '"NOT_INITIATED"' have no overlap
      //appRoot.status !== NOT_INITIATED &&
      appRoot.status !== ERROR_NOT_LOADABLE
    ) {
      return (
        <div>
          <Layout />
        </div>
      );
    } else {
      const statusString =
        appRoot.status === ERROR_NOT_LOADABLE ? 'LOADING ERROR' : 'loading...';
      return <div>{statusString}</div>;
    }
  });
};
