import { Step } from '@masala-lib/llm/project/llm-project-types';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { SidebarItem } from './sidebar-item';
import { PlusSquareIcon } from '../components/icons';
import { Button } from '@naan/primitives/button';
import { PlusSmallIcon } from '@naan/icons/plus-icon';

export const ReferenceScriptItem = observer(({ step }: { step: Step }) => {
  const model = useSamosaModel();
  const selected = model.isCurrentStep(step);

  return (
    <Button
      presentation={'grayDark'}
      size="small"
      radius={'squared'}
      disabled={selected}
      onClick={() => model.setNewThread()}
      leftIcon={<PlusSmallIcon />}
      label={'New'}
      fullWidth
    />
  );
});
