import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';
import { Tag } from '@masala-lib/catalog/models/tag';

export function useTag() {
  const { id = null } = useParams<{ id: string }>();
  const manager = TagManager.getInstance();
  const [currentTag, setCurrentTag] = React.useState<Tag>(null);
  useEffect(() => {
    setCurrentTag(manager.fetchById(id));
  }, [manager, id]);

  return currentTag;
}
