import { TrackGadget } from './track-gadget';
import { makeObservable, observable, reaction } from 'mobx';
import { Context2D } from './canvas';
import { TrickyEdit } from '@masala-lib/editorial/db/firestore-doc-types';

export class QuantaTrackGadget extends TrackGadget {
  @observable notifyTrackSelect = 0;
  trackKey: string = null;
  trackSelectionKeySource: () => string = null;
  quantaMS: number;
  values: (number | TrickyEdit)[] = null;
  dimmed = false;
  color = '#000000';
  color2 = '#000000';
  highlightColor = '#000000';
  highlightCutoff: number = 0;
  dimmedColor = '#bbbbbb';
  doesRollover = true;
  rolloverState: any = null;

  constructor() {
    super();
    makeObservable(this);
  }

  setQuantaValues(values: (number | TrickyEdit)[]) {
    this.values = values;
    this.requestFullRedraw();
  }

  setTrackSelectionSource(source: () => string) {
    if (this.trackSelectionKeySource) {
      return;
    }
    this.trackSelectionKeySource = source;
    this.disposers.push(
      reaction(
        () => source(),
        () => this.handleTrackSelectionChange()
      )
    );
  }

  handleTrackSelectionChange() {
    this.requestForegroundRedraw();
  }

  drawBounds(ctx: Context2D) {
    this.drawGadgetSliceBounds(ctx, 'BOTTOM');
    this.drawGadgetSliceBounds(ctx, 'TOP');
  }

  isSelectedTrack() {
    if (!this.trackSelectionKeySource) {
      return;
    }
    const key = this.trackSelectionKeySource();
    if (key !== this.trackKey) {
      return false;
    }
    return true;
  }

  drawForegroundLayer(ctx: Context2D) {
    ctx.save();
    if (this.isSelectedTrack()) {
      ctx.strokeStyle = '#000000';
      ctx.lineWidth = 3;
      this.drawBounds(ctx);
    } else {
      ctx.strokeStyle = '#888888';
      this.drawBounds(ctx);
    }
    ctx.restore();
  }

  drawBackgroundLayer(ctx: Context2D) {
    if (!this.values || !this.values.length) {
      return;
    }
    ctx.save();
    ctx.fillStyle = this.dimmed ? this.dimmedColor : this.color;
    const quantaMS = this.quantaMS;
    const startTime = this.canvasStartTime;
    const startQuanta = Math.round(startTime / quantaMS);
    const numQuantas = Math.round(this.canvasTimeExtent / quantaMS);
    for (let i = 0; i < numQuantas; i++) {
      const quantaValue = this.values[startQuanta + i];
      if (typeof quantaValue === 'number') {
        ctx.globalAlpha = quantaValue;
      } else {
        switch (quantaValue) {
          case 'IN':
            ctx.fillStyle = this.color;
            break;
          case 'OUT':
            ctx.fillStyle = this.color2;
            break;
          case 'NOP':
            continue;
        }
      }
      const quantaTime = startTime + quantaMS * i;
      this.drawTimerangeRect(
        ctx,
        { begin: quantaTime, end: quantaTime + quantaMS },
        this.height
      );
      if (
        !this.dimmed &&
        this.highlightCutoff &&
        typeof quantaValue === 'number' &&
        quantaValue > this.highlightCutoff
      ) {
        ctx.globalAlpha = 1;
        const savedFillStyle: any = ctx.fillStyle;
        ctx.fillStyle = this.highlightColor;
        this.drawTimerangeRect(
          ctx,
          {
            begin: quantaTime,
            end: quantaTime + quantaMS * this.highlightCutoff + 5,
          },
          this.height * 0.5
        );
        ctx.fillStyle = savedFillStyle;
      }
    }
    ctx.restore();
  }

  onTrackSelect(f: (...a: any[]) => void) {
    this.disposers.push(
      reaction(
        () => this.notifyTrackSelect,
        () => f(this, this.trackKey)
      )
    );
  }

  checkRollover(x: number, y: number, time: number, state: any) {
    if (state) {
      return state;
    }

    return {
      gadget: this,
      index: 0,
      interval: { begin: this.canvasStartTime, end: this.canvasEndTime },
    };
  }

  setRolloverState(state: any) {
    if (state !== this.rolloverState) {
      this.requestForegroundRedraw();
    }
    this.rolloverState = state;
  }

  deactivateRollover() {
    if (this.rolloverState) {
      this.requestForegroundRedraw();
    }
    this.rolloverState = null;
  }

  handleMouseClickAtTime(
    x: number,
    y: number,
    time: number,
    event: MouseEvent
  ): boolean {
    if (!this.trackKey) {
      return false;
    }
    this.notifyTrackSelect++;
  }
}
