import * as Dialog from '@console/components/samosa-dialogs';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import { TranslationMergeView } from './translation-merge-view';
import { runConfirmableDialog } from '../utils/dialog-runner';
import { Button } from '@naan/primitives/button';
// import { TranslationMergeKeyboardHelp } from './translation-merge-keyboard-help';
import { runSimpleConfirmation } from '../components/simple-confirm';
import { setTouchupEditorModal } from './use-editor-modal';
import { TopBarComponent } from './translation-topbar';
import { useScrollIfNotVisible } from './use-scroll-if-not-visible';
import { KeyboardService } from 'lib/services/keyboard-service';
import { MdOutlineKeyboardAlt } from 'react-icons/md';
import { HelpListItem } from '../help-modal';

const DialogContainer = styled(Dialog.Container, {
  $$chromeColor: 'color-mix(in srgb, $colors$gray-800 80%, $colors$blue-800)',

  width: 'min( 100% - 32px, 920px )',
  // border: '1px solid #888',
  margin: 'auto',
  maxHeight: 'calc(100vh - 64px)',
  // ::backdrop {
  //   background: rgba(0, 0, 0, 0.3);
  // }
});

const Body = styled('div', {
  // width: 'min( 100% - 10px, 920px )',
  maxHeight: '90vh',
  overflowY: 'scroll',
});

const BottomBar = styled('div', {
  backgroundColor: '$colors$gray-100',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 8,
  gap: 8,
  // width: 'min( 100% - 32px, 920px )',
  borderTop: '1px solid $colors$gray-100',
  '& .left': {},
  '& .right': {
    display: 'flex',
    gap: 8,
  },
});

// const mergeScriptViewMap: {
//   [K in ProjectTask]: () => JSX.Element;
// } = {
//   translation: MergeScriptView,
//   structural: StructuralMergeScriptView,
// };

export const TranslationMergeModal = observer(
  ({
    modal,
    onResolve,
  }: {
    modal: TouchUpEditorModal;
    onResolve: (value: boolean) => void;
  }) => {
    console.log('rendering modal');
    const editorModal = modal;
    setTouchupEditorModal(editorModal);
    useScrollIfNotVisible(editorModal);
    // const disposers: (() => void)[] = [];
    // disposers.push(
    //   reaction(
    //     () => editorModal.currentElementId,
    //     () => {
    //       scrollIfNotVisibleDomId(
    //         editorModal.currentElementId,
    //         'nearest',
    //         'bodyId'
    //       );
    //       // ensure both selected element and potential candidate are visible
    //       const candidateId = editorModal.shouldBeVisibleCandidateId;
    //       if (candidateId) {
    //         scrollIfNotVisibleDomId(candidateId, 'nearest', 'bodyId');
    //       }
    //     }
    //   )
    // );
    const onCancel = () => onResolve(undefined);
    // const onSave = () => onResolve(true);
    const onDismiss = async () => {
      onCancel();
    };

    const onEscape = async () => {
      onCancel();
    };

    return (
      <DialogContainer
        open
        // onEscapeKeyDown={onEscape}
        // need to disable the onDismiss handler to to avoid getting prematurely closed when child dialog presented
        // onDismiss={/*onDismiss*/ () => undefined}
      >
        <TopBarComponent />
        <Body id={'bodyId'}>
          <TranslationMergeView onResolve={onResolve} />
        </Body>
        <BottomBar>
          <div className="left">
            {/* <button
              className="icon"
              onClick={() => KeyboardService.instance.toggleHelp()}
            >
              <MdOutlineKeyboardAlt />
            </button> */}
            <HelpListItem>
              <div className="key">^ ?</div>
              <div className="help">Show help</div>
            </HelpListItem>
          </div>
          <div className="right">
            {/* <Button
              size="small"
              presentation="grayLight"
              radius="squared"
              onClick={onDismiss}
              label="Cancel"
            />
            <Button
              size="small"
              presentation="blue"
              radius="squared"
              onClick={onSave}
              label="Save"
            /> */}
            <Button
              size="small"
              presentation="grayLight"
              radius="squared"
              onClick={onDismiss}
              label="Close"
            />
          </div>
        </BottomBar>
      </DialogContainer>
    );
  }
);

export const runTranslationMergeModal = async (
  modal: TouchUpEditorModal
): Promise<boolean> => {
  return runConfirmableDialog(onResolve => (
    <TranslationMergeModal modal={modal} onResolve={onResolve} />
  ));
};
