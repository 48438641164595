import * as React from 'react';
import { AuthorData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Textarea } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';

interface Props {
  dto: AuthorData;
  onSubmit: (dto: AuthorData) => void;
  onCancel: () => void;
}

export const AuthorForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'id',
    'name',
    'slug',
    'title',
    'bio',
    'avatarUrl',
    'priority',
  ]);
  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);

  React.useEffect(() => {
    return () => {
      //otherwise we stay in form mode, even when going to another item
      onCancel();
    };
  }, [onCancel]);

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input {...getFieldProps('name')} required />
        <Input {...getFieldProps('title')} />
        <Input {...getFieldProps('slug')} />
        <Textarea
          {...getFieldProps('bio')}
          hint={'You can use **bold** _italics_'}
          rows={6}
        />
        <Input {...getFieldProps('avatarUrl')} />
        <Input
          {...getFieldProps('priority')}
          hint={
            'exported in feed used for ordering on the marketing site (sort descending, then alphabetically)'
          }
        />
        <FormButtons
          submitLabel={isNew ? 'Create author' : 'Save changes'}
          onCancel={onCancel}
        />
      </form>
    </OnePaneLayout>
  );
};
