import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';
import { observer } from 'mobx-react';
import { LoginScreen } from './console/views/login-screen';
import { initFirestoreCollections } from '@masala-lib/firestore-db/init-collections';
import { deploymentConfig } from '@masala-lib/deployment-config';

import { signOut } from './platform/firebase-auth';
import { FeaturedReleaseManager } from '@masala-lib/catalog/db/featured-release-manager';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { ConversationManager } from '@masala-lib/editorial/models/conversation-manager';
import { Auth } from '@masala-lib/editorial/db/auth';
import { startUpdateChecks } from './console/lib/update-checker';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { VideoGuideManager } from '@masala-lib/catalog/db/video-guide-manager';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';
import { MasalaRoutes } from './routes';

// todo: figure out best way to enable listening just when needed for
// console list views
// TagManager.getInstance().listenAll();
// ActivityGuideManager.getInstance().listenAll();
// FeaturedReleaseManager.getInstance().listenAll();

const g = global as any;

g.deploymentConfig = deploymentConfig;

// g.jwBuildTimestamp = '2021.08.02-10:39pm pdt';

g.auth = Auth.getInstance();
g.conversationManager = ConversationManager.getInstance();
g.channelManager = ChannelManager.getInstance();
g.volumeManager = VolumeManager.getInstance();
g.unitManager = UnitManager.getInstance();
g.excerptManager = ExcerptManager.getInstance();
g.soundbiteEditionManager = SoundbiteEditionManager.getInstance();

g.tagManager = TagManager.getInstance();
g.activityGuideManager = ActivityGuideManager.getInstance();
g.featuredReleaseManager = FeaturedReleaseManager.getInstance();
g.videoGuideManager = VideoGuideManager.getInstance();

g.initFirestoreCollections = initFirestoreCollections;

// not sure the best home for this
configure({
  enforceActions: 'never',
});

export const App = observer(() => {
  React.useEffect(() => {
    startUpdateChecks();
  }, []);

  // useEffect(() => {
  //   console.log(`appview - authUser: ${JSON.stringify(auth.authUser?.email)}, appUser: ${JSON.stringify(auth.appUser)}`);
  // }, [auth.authUser, auth.appUser]);

  const auth = Auth.getInstance();
  if (!auth) {
    return null;
  }

  if (!auth.authUser) {
    return <LoginScreen />;
  }

  if (auth.userManager.status !== 'COMPLETE') {
    return <h2>...loading</h2>;
  }

  if (!auth.appUser) {
    return (
      <>
        <h2>Unauthorized</h2>
        <p>{auth.authUser?.email}</p>
        <a onClick={signOut} href="#">
          Sign out
        </a>
      </>
    );
  }

  return (
    <BrowserRouter>
      <MasalaRoutes />
    </BrowserRouter>
    // <LegacySpaToastTarget service={NotificationService} />
  );

  // return <InitFirestoreScreen />; // todo, sort out a better solution to bootstrap a new db, than uncommenting this line
});
