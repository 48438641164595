import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActivityGuide } from '@masala-lib/catalog/models/activity-guide';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';

export function useActivityGuide() {
  const { id = null } = useParams<{ id: string }>();
  const manager = ActivityGuideManager.getInstance();
  const [currentActivityGuide, setCurrentActivityGuide] =
    React.useState<ActivityGuide>(null);

  // this pattern allows us to have currentActivityGuide available in the console
  (window as any).currentActivityGuide = currentActivityGuide;

  useEffect(() => {
    setCurrentActivityGuide(manager.fetchById(id));
  }, [manager, id]);

  return currentActivityGuide;
}
