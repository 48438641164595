import { ElementId, idIsOfKind } from '@tikka/basic-types';
import {
  CreateElementList,
  Element,
  ElementList,
  MetadataBlock,
} from '@masala-lib/editor-aliases';
import { computed, makeObservable } from 'mobx';
import {
  EpisodeDataBase,
  interleave,
  metadataOrder,
} from '@masala-lib/editorial/episode-data/episode-data';
import { sortElements } from '@masala-lib/element-sort';
import { EKind } from '@masala-lib/element-kinds';
import { getKindFromId } from '@tikka/elements/element-id-utils';

export class ScriptEditorEpisodeData extends EpisodeDataBase {
  //
  stateVersion0 = 1;

  constructor() {
    super();
    makeObservable(this);
  }

  @computed({ keepAlive: true })
  get content(): ElementList<Element> {
    let result: Element[] = [];
    result.push(...this.sentences);
    result.push(...this.wordGroups);
    result.push(...this.structural);
    sortElements(result);
    const translations = this.translations;
    const nonWordGroupTranslations: any = {};
    for (const [key, value] of Object.entries(translations)) {
      // if (getKindFromId(value.elementId) !== EKind.WORD_GROUP) {
      if (!idIsOfKind(value.elementId, EKind.WORD_GROUP)) {
        nonWordGroupTranslations[key] = value;
      }
    }
    const translationsLookup = (id: ElementId) => nonWordGroupTranslations[id];
    result = interleave(result, translationsLookup, 1);
    const metadataToOrderFunc = (m: MetadataBlock) =>
      metadataOrder.keyToPrecedenceLevel[m.subKind];
    const metadata = [...this.metadataBlocks].sort(metadataToOrderFunc);
    result = [...metadata, ...result];

    return CreateElementList({
      elements: result,
      words: this.words,
    });
  }

  @computed({ keepAlive: true })
  get stateVersion() {
    const touch = [this.words, this.content, this.translations];
    this.stateVersion0++;
    return this.stateVersion0;
  }
}
