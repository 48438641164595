import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { observer } from 'mobx-react';
import { Input, Textarea } from '@console/components/forms';
import { useMasalaForm } from '@console/lib/hooks/use-masala-form';
import { runConfirmableDialog } from '../utils/dialog-runner';
import {
  KeyboardService,
  keynameForEvent,
} from 'lib/services/keyboard-service';
import { styled } from '@naan/stitches.config';

// import { createLogger } from '@app/logger';
// const log = createLogger('text-form-modal');

//
// dialog to provide custom text to display in sidebar for an exchange
//

interface FormData {
  text: string;
}

type TextFormModalProps = {
  label: string;
  text: string;
  confirmLabel?: string;
  onResolve: (text: string) => void;
  type?: 'text' | 'textarea';
  size?: 'narrow' | 'medium' | 'wide';
};

const DialogContainer = styled(Dialog.Container, {
  variants: {
    size: {
      narrow: {
        width: '360px',
      },
      medium: {
        width: 'min(420px, 100vw-32px)',
      },
      wide: {
        width: 'min(720px, 100vw - 32px)',
      },
    },
  },
});

export const TextFormModal = observer(
  ({
    label,
    text,
    confirmLabel = 'Save',
    onResolve,
    type = 'text',
    size = 'narrow',
  }: TextFormModalProps) => {
    const { handleSubmit, getFieldProps } = useMasalaForm<FormData>({
      defaultValues: {
        text,
      },
    });

    //@joseph, @jason fyi: this pauses the current shortcut set when the modal is opened
    // otherwise one can still, for example, move the cursor below the modal
    React.useEffect(() => {
      // returning this automatically resumes the shortcut set when the modal is closed
      return KeyboardService.instance.pauseCurrentShortcutSet();
    }, []);

    const onSubmit = async (
      data: FormData
      // event: any /*React.BaseSyntheticEvent*/
    ) => {
      // log.info(`onSubmit - text: ${data.text}`);
      onResolve(data.text);
    };
    const onCancel = () => onResolve(undefined);
    return (
      // need to disable the onDismiss handler, otherwise an outside click will close the
      // entire stack of modals
      <DialogContainer open onEscapeKeyDown={onCancel} size={size}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Dialog.Body css={{ width: '100%' }}>
            {type === 'text' ? (
              <Input
                {...getFieldProps('text')}
                label={label}
                css={{
                  maxWidth: '100%',
                }}
              />
            ) : (
              <Textarea
                {...getFieldProps('text')}
                label={label}
                css={{
                  maxWidth: '100%',
                }}
                onKeyDown={event => {
                  const eventName = keynameForEvent(event as any);
                  if (
                    eventName === 'enter' ||
                    eventName === 'meta+enter' ||
                    eventName === 'ctrl+enter'
                  ) {
                    event.preventDefault();
                    handleSubmit(onSubmit)();
                    return;
                  }
                }}
              />
            )}
          </Dialog.Body>
          <Dialog.ButtonsContainer
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 8,
            }}
          >
            <div>
              <Dialog.SmallButton
                type="button"
                onClick={onCancel}
                label="Cancel"
                presentation="grayLight"
              />
            </div>
            <div>
              <Dialog.SmallButton
                label={confirmLabel}
                presentation="blue"
                type="submit"
              />
            </div>
          </Dialog.ButtonsContainer>
        </form>
      </DialogContainer>
    );
  }
);

export const runTextFormModal = async (
  props: Omit<TextFormModalProps, 'onResolve'>
): Promise<string> => {
  return runConfirmableDialog(onResolve => (
    <TextFormModal {...props} onResolve={onResolve} />
  ));
};
