import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FeaturedReleaseManager } from '@masala-lib/catalog/db/featured-release-manager';
import { FeaturedRelease } from '@masala-lib/catalog/models/featured-release';

export function useFeaturedRelease() {
  const { id = null } = useParams<{ id: string }>();
  const manager = FeaturedReleaseManager.getInstance();
  const [model, setModel] = React.useState<FeaturedRelease>(null);

  // console convenience
  (window as any).currentFeaturedRelease = model;

  useEffect(() => {
    setModel(manager.fetchById(id));
  }, [manager, id]);

  return model;
}
