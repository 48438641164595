import styled from 'styled-components';

const Wrapper = styled.header`
  text-align: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  h2 {
    .position {
      display: block;
      font-size: 1.5em;
    }
    font-weight: 700;
  }
`;

export const ChapterHeader = ({ chapter }: { chapter: any }) => {
  return (
    <Wrapper>
      <h2>
        <span className="position">{chapter.position}</span>
        <span className="title">{chapter.title}</span>
      </h2>
      <h3>{chapter.titleEnglish}</h3>
    </Wrapper>
  );
};
