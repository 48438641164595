import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Unit } from '@masala-lib/catalog/models/unit';

export const UnitRow = observer(
  ({ model, isSelected }: { model: Unit; isSelected: boolean }) => {
    const style = isSelected ? 'console-selected-row' : '';
    return (
      <div className={style}>
        <NavLink to={model.navPath}>{model.name}</NavLink>
        {' -- '}
        {model.scriptInitted ? (
          model.chaatReady ? (
            <NavLink to={`/episodes/${model.episodeKey}/chaat`}>
              [chaat]
            </NavLink>
          ) : null
        ) : (
          <>
            <NavLink to={model.navPath}>[import script]</NavLink>
          </>
        )}
        {model.operationStatus ? ` -- ${model.operationStatus}` : null}
      </div>
    );
  }
);
