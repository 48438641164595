import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'rebass';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import { Shelf } from '../shared/shelf';

export const DevErrorTesting = () => {
  const handleSyncError = () => {
    throw new Error('Test error');
  };

  const handleAsyncError = () => {
    async function asyncError() {
      throw new Error('Async test error');
    }
    asyncError();
  };

  return (
    <Card header="error-handling">
      <DataItem
        label="Trigger Sync Error"
        value={
          <Shelf>
            <Button
              backgroundColor={`var(--color-red500)`}
              onClick={handleSyncError}
            >
              Sync error
            </Button>
          </Shelf>
        }
      />
      <DataItem
        label="Trigger Async Error"
        value={
          <Button
            backgroundColor={`var(--color-red600)`}
            onClick={handleAsyncError}
          >
            Async error
          </Button>
        }
      />
      {/* <DataItem
        label="Init firestore"
        value={<NavLink to="/init-firestore">[link]</NavLink>}
      /> */}
    </Card>
  );
};
