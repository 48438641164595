// this will only work for simple queries, but it's enough for our needs

import { camelCase, isEmpty } from 'lodash';

// todo: remove usage and instead use react-route-dom's useSearchParams
// (hmmm. need to upgrade react-router version first)
export function getQueryParamsFromLocation(
  location: any,
  autoCamelCase = true,
  autoEscape = true
) {
  const query = location.search.substr(1);
  const obj: any = {};

  if (query) {
    const pairs = query.split('&');
    pairs.forEach((pair: string) => {
      const [key, value] = pair.split('=');
      const normalizedKey = autoCamelCase ? camelCase(key) : key;
      obj[normalizedKey] = decodeURIComponent(value) || null;
    });
  }

  return obj;
}

export function serializeQueryString(object: { [key: string]: string }) {
  var str = [];
  for (var property in object)
    if (object.hasOwnProperty(property)) {
      if (!isEmpty(object[property])) {
        str.push(
          encodeURIComponent(property) +
            '=' +
            encodeURIComponent(object[property])
        );
      }
    }
  return str.join('&');
}

export function makeQueryPath(
  pathname: string,
  object: { [key: string]: string }
) {
  const currentQueryString = getQueryParamsFromLocation(window.location, false);
  return `${pathname}?${serializeQueryString({
    ...currentQueryString,
    ...object,
  })}`;
}
