import React from 'react';
import { observer } from 'mobx-react';
import { ConversationView } from './conversation-view';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import { LintWarningItem } from './lint-warning';

export const DiscussionSidepanel = observer(
  ({ element, warnings }: { element: any; warnings: LintWarning[] }) => (
    <div className="discussion-sidepanel">
      <div className="right-sidepanel-header">Element Comments</div>
      {warnings
        ? warnings.map(warning => <LintWarningItem warning={warning} />)
        : null}
      <ConversationView element={element} showChanges />
    </div>
  )
);
