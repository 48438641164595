import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { KeyboardService } from 'lib/services/keyboard-service';
import { observer } from 'mobx-react';
import { ListItem, ListWrapper } from '../components/list';
import { formatShortcutText } from 'lib/services/keyboard-service/format-shortcut-text';
import { Portal } from '@naan/primitives/portal';
import { useOnClickOutside } from '../merge/translation-merge-keyboard-help';

const Overlay = styled('div', {
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  background: '$colors$black-alpha-10',
  zIndex: 2000,
});

const Wrapper = styled('div', {
  $$columns: 1,
  position: 'fixed',
  left: 0,
  top: 0,
  background: '$colors$yellow-50',
  zIndex: 2005,
  boxShadow: '0 0 12px 0 $colors$black-alpha-10',
  columns: '$$columns',
  '&.wrap-2': {
    $$columns: 2,
  },
});

export const HelpListItem = styled(ListItem, {
  gap: 8,
  '& .key': {
    fontSize: 11,
    color: '$black-alpha-90',
    background: '$gray-100',
    padding: '2px 4px',
    boxShadow:
      '0 0 0 1px $colors$black-alpha-20, 0 1px 0 0 $colors$white-alpha-50 inset, 0 1px 4px 0 $colors$black-alpha-20',
    textShadow: '0 1px 0 $colors$white-alpha-50',
    borderRadius: 4,
  },
});

export const HelpOverlay = observer(() => {
  const docs = KeyboardService.instance.getCurrentDocumentation();
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    KeyboardService.instance.toggleHelp();
  });

  if (KeyboardService.instance.showHelp) {
    const className = docs.length > 8 ? 'wrap-2' : '';
    return (
      <Portal id="help-overlay">
        <Overlay>
          <Wrapper ref={ref} className={className}>
            <ListWrapper>
              {docs.map(doc => {
                return (
                  <HelpListItem>
                    <div className="key">{formatShortcutText(doc.keyname)}</div>
                    <div className="help">
                      {doc.documentation ?? '[documentation pending]'}
                    </div>
                  </HelpListItem>
                );
              })}
              <HelpListItem>
                {/* <div className="key">⌘-K</div> */}
                <div className="key">^ ?</div>
                <div className="help">Show / hide this help</div>
              </HelpListItem>
            </ListWrapper>
          </Wrapper>
        </Overlay>
      </Portal>
    );
  }

  return null;
});
