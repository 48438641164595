//
// Provides handy references to all the collection docs for a given episode key.
//
// should perhaps be renamed to something like EpisodeDocPaths
//

import { UnitData } from '../../catalog/catalog-types';
import { DocumentReference, Firestore } from '../../../platform/firebase-types';
import {
  ChaatAudioAnalysisDoc,
  ChaatAudioMarkersDoc,
  ChaatAudioRegionsDoc,
  ChaatCuesDoc,
  ChaatSpeechTranscriptsDoc,
  ChaatTimestampsDoc,
  // ChaatAudioProcessingJobDoc,
  // EpisodeChaatMetadataDoc,
  EpisodeMetadataBlockDoc,
  // EpisodeMetadataDoc,
  EpisodeStructuralDoc,
  EpisodeTranslationDoc,
  EpisodeVerbatimDoc,
  EpisodeWordGroupDoc,
  SentenceVersionsDoc,
  StructuralVersionsDoc,
  TranslationVersionsDoc,
  WarningSuppressionsDoc,
  WordGroupVersionsDoc,
  ChaatSignoffsDoc,
  AuditLogDoc,
  ChaatTrickyEditsDoc,
  // ChaatTranscriptionJobDoc,
} from './firestore-doc-types';
import { ID_LENGTH } from '@masala-lib/catalog/db/catalog-entity-manager';

export const DocKeys = {
  UNIT_METADATA_DOC: 'unitMetadataDoc',
  // EPISODE_METADATA_DOC = 'episodeMetadataDoc',
  VERBATIM_DOC: 'verbatimDoc',
  STRUCTURAL_DOC: 'structuralDoc',
  WORD_GROUP_DOC: 'wordGroupsDoc',
  TRANSLATIONS_DOC: 'translationsDoc',
  METADATA_BLOCKS_DOC: 'metadataBlocksDoc',
  WARNING_SUPPRESSIONS_DOC: 'warningSuppressionsDoc',
  // CHAAT_METADATA_DOC = 'chaatMetadataDoc',
  CHAAT_CUES_DOC: 'cuesDoc',
  CHAAT_TIMESTAMPS_DOC: 'timestampsDoc',
  CHAAT_AUDIO_ANALYSIS_DOC: 'audioAnalysisDoc',
  CHAAT_AUDIO_REGIONS_DOC: 'audioRegionsDoc',
  CHAAT_AUDIO_MARKERS_DOC: 'audioMarkersDoc',
  CHAAT_SPEECH_TRANSCRIPT_DOC: 'speechTranscriptDoc',
  CHAAT_TRICKY_EDITS: 'trickyEditsDoc',
  // CHAAT_AUDIO_PROCESSING_JDB_DOC = 'audioProcessingJobDoc',
  // CHAAT_TRANSCRIPTION_JOB_DOC = 'transcriptionJobDoc',
  CHAAT_SIGNOFFS_DOC: 'chaatSignoffsDoc',
  SENTENCE_VERSIONS_DOC: 'sentenceVersionsDoc',
  WORD_GROUP_VERSIONS_DOC: 'wordGroupVersionsDoc',
  STRUCTURAL_VERSIONS_DOC: 'structuralVersionsDoc',
  TRANSLATION_VERSIONS_DOC: 'translationVersionsDoc',
  AUDIT_LOG_DOC: 'auditLogDoc',
} as const;

type ValuesOf<T extends object> = T[keyof T];
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DocKeys = ValuesOf<typeof DocKeys>;

export const sharedDocKeys: DocKeys[] = [
  'verbatimDoc',
  'sentenceVersionsDoc',
  'audioAnalysisDoc',
  'speechTranscriptDoc',
  'timestampsDoc',
  'cuesDoc',
  'audioRegionsDoc',
  'audioMarkersDoc',
  'chaatSignoffsDoc',
];

export const collectionNameMap: Record<DocKeys, string> = {
  unitMetadataDoc: 'Unit__metadata',
  verbatimDoc: 'Unit__verbatim',
  structuralDoc: 'Unit__structural',
  wordGroupsDoc: 'Unit__wordGroups',
  translationsDoc: 'Unit__translations',
  metadataBlocksDoc: 'Unit__metadataBlocks',
  warningSuppressionsDoc: 'Unit__warningSuppressions',
  cuesDoc: 'Unit__chaatCues',
  timestampsDoc: 'Unit__chaatTimestamps',
  audioAnalysisDoc: 'Unit__chaatAudioAnalysis',
  audioRegionsDoc: 'Unit__chaatAudioRegions',
  audioMarkersDoc: 'Unit__chaatAudioMarkers',
  speechTranscriptDoc: 'Unit__chaatSpeechTranscripts',
  chaatSignoffsDoc: 'Unit__chaatSignoffs',
  trickyEditsDoc: 'Unit__chaatTrickyEdits',
  sentenceVersionsDoc: 'Unit__sentenceVersions',
  wordGroupVersionsDoc: 'Unit__wordGroupVersions',
  structuralVersionsDoc: 'Unit__structuralVersions',
  translationVersionsDoc: 'Unit__translationVersions',
  auditLogDoc: 'Unit__auditLog',
} as const;

export function collectionNameForDocKey(key: DocKeys) {
  return collectionNameMap[key];
}

export const FORKED_UNIT_SEPARATOR = '-';

export function isForkedUnitId(unitId: string): boolean {
  if (!unitId) {
    return false;
  }
  if (!(unitId.length > ID_LENGTH)) {
    return false;
  }
  if (!unitId.includes(FORKED_UNIT_SEPARATOR)) {
    return false;
  }
  return true;
}

export function splitUnitId(unitId: string): [string, string] {
  if (!isForkedUnitId(unitId)) {
    return null;
  }
  return unitId.split(FORKED_UNIT_SEPARATOR) as [string, string];
}

// todo: rename to EpisodeDbPaths
export class DbPaths {
  db: Firestore;
  // TODO rename to docId because is confusing
  key: string;

  constructor(db: Firestore, key: string) {
    this.db = db;
    this.key = key;
  }

  get rootRef() {
    return this.db;
  }

  docRef<DocData>(docKey: DocKeys) {
    const path = collectionNameMap[docKey];
    const collection = this.rootRef.collection(path);
    const splitId = splitUnitId(this.key);
    if (splitId && sharedDocKeys.includes(docKey)) {
      const [baseId, _] = splitId;
      return collection.doc(baseId) as DocumentReference<DocData>;
    }
    return collection.doc(this.key) as DocumentReference<DocData>;
  }

  get unitMetadataDocRef(): DocumentReference<UnitData> {
    // return this.collectionDocRef<UnitData>('Unit__metadata');
    return this.docRef<UnitData>('unitMetadataDoc');
  }

  // // todo: rename to episodeDocRef?
  // get metadataDocRef(): DocumentReference<EpisodeMetadataDoc> {
  //   return this.collectionDocRef<EpisodeMetadataDoc>('Unit__metadata');
  // }

  // get chaatMetadataDocRef() {
  //   return this.collectionDocRef<EpisodeChaatMetadataDoc>('Unit__chaatMetadata');
  // }

  get verbatimDocRef() {
    return this.docRef<EpisodeVerbatimDoc>('verbatimDoc');
  }

  get structuralDocRef() {
    return this.docRef<EpisodeStructuralDoc>('structuralDoc');
  }

  get wordGroupsDocRef() {
    return this.docRef<EpisodeWordGroupDoc>('wordGroupsDoc');
  }

  get translationsDocRef() {
    return this.docRef<EpisodeTranslationDoc>('translationsDoc');
  }

  get metadataBlocksDocRef() {
    return this.docRef<EpisodeMetadataBlockDoc>('metadataBlocksDoc');
  }

  get chaatCuesDocRef() {
    return this.docRef<ChaatCuesDoc>('cuesDoc');
  }

  get chaatTimestampsDocRef() {
    return this.docRef<ChaatTimestampsDoc>('timestampsDoc');
  }

  get chaatAudioAnalysisDocRef() {
    return this.docRef<ChaatAudioAnalysisDoc>('audioAnalysisDoc');
  }

  get chaatAudioRegionsDocRef() {
    return this.docRef<ChaatAudioRegionsDoc>('audioRegionsDoc');
  }

  get chaatAudioMarkersDocRef() {
    return this.docRef<ChaatAudioMarkersDoc>('audioMarkersDoc');
  }

  get chaatSpeechTranscriptsDocRef() {
    return this.docRef<ChaatSpeechTranscriptsDoc>('speechTranscriptDoc');
  }

  get chaatTrickyEditsDocRef() {
    return this.docRef<ChaatTrickyEditsDoc>('trickyEditsDoc');
  }

  get sentenceVersionsDocRef() {
    return this.docRef<SentenceVersionsDoc>('sentenceVersionsDoc');
  }

  get wordGroupVersionsDocRef() {
    return this.docRef<WordGroupVersionsDoc>('wordGroupVersionsDoc');
  }

  get structuralVersionsDocRef() {
    return this.docRef<StructuralVersionsDoc>('structuralVersionsDoc');
  }

  get translationVersionsDocRef() {
    return this.docRef<TranslationVersionsDoc>('translationVersionsDoc');
  }

  get warningSuppressionsDocRef() {
    return this.docRef<WarningSuppressionsDoc>('warningSuppressionsDoc');
  }

  // get chaatAudioProcessingJobDocRef() {
  //   return this.collectionDocRef<ChaatAudioProcessingJobDoc>('Unit__chaatAudioProcessingJob');
  // }

  // get chaatTranscriptionJobDocRef() {
  //   return this.collectionDocRef<ChaatTranscriptionJobDoc>('Unit__chaatTranscriptionJob');
  // }

  get chaatSignoffsDocRef() {
    return this.docRef<ChaatSignoffsDoc>('chaatSignoffsDoc');
  }

  get auditLogDocRef() {
    return this.docRef<AuditLogDoc>('auditLogDoc');
  }

  getDocRef(docKey: DocKeys) {
    // TODO now this seems redundant with this.docRef(...) except for the return type which is probably not important
    if (docKey === DocKeys.UNIT_METADATA_DOC) {
      return this.unitMetadataDocRef;
    }
    // if (docKey === DocKeys.EPISODE_METADATA_DOC) {
    //   return this.metadataDocRef;
    // }
    if (docKey === DocKeys.VERBATIM_DOC) {
      return this.verbatimDocRef;
    }
    if (docKey === DocKeys.STRUCTURAL_DOC) {
      return this.structuralDocRef;
    }
    if (docKey === DocKeys.WORD_GROUP_DOC) {
      return this.wordGroupsDocRef;
    }
    if (docKey === DocKeys.TRANSLATIONS_DOC) {
      return this.translationsDocRef;
    }
    if (docKey === DocKeys.METADATA_BLOCKS_DOC) {
      return this.metadataBlocksDocRef;
    }
    if (docKey === DocKeys.WARNING_SUPPRESSIONS_DOC) {
      return this.warningSuppressionsDocRef;
    }
    if (docKey === DocKeys.CHAAT_CUES_DOC) {
      return this.chaatCuesDocRef;
    }
    if (docKey === DocKeys.CHAAT_TIMESTAMPS_DOC) {
      return this.chaatTimestampsDocRef;
    }
    if (docKey === DocKeys.CHAAT_AUDIO_ANALYSIS_DOC) {
      return this.chaatAudioAnalysisDocRef;
    }
    if (docKey === DocKeys.CHAAT_AUDIO_REGIONS_DOC) {
      return this.chaatAudioRegionsDocRef;
    }
    if (docKey === DocKeys.CHAAT_AUDIO_MARKERS_DOC) {
      return this.chaatAudioMarkersDocRef;
    }
    if (docKey === DocKeys.CHAAT_SPEECH_TRANSCRIPT_DOC) {
      return this.chaatSpeechTranscriptsDocRef;
    }
    // if (docKey === DocKeys.CHAAT_AUDIO_PROCESSING_JDB_DOC) {
    //   return this.chaatAudioProcessingJobDocRef;
    // }
    // if (docKey === DocKeys.CHAAT_TRANSCRIPTION_JOB_DOC) {
    //   return this.chaatTranscriptionJobDocRef;
    // }
    if (docKey === DocKeys.CHAAT_SIGNOFFS_DOC) {
      return this.chaatSignoffsDocRef;
    }
    if (docKey === DocKeys.CHAAT_TRICKY_EDITS) {
      return this.chaatTrickyEditsDocRef;
    }
    if (docKey === DocKeys.SENTENCE_VERSIONS_DOC) {
      return this.sentenceVersionsDocRef;
    }
    if (docKey === DocKeys.WORD_GROUP_VERSIONS_DOC) {
      return this.wordGroupVersionsDocRef;
    }
    if (docKey === DocKeys.STRUCTURAL_VERSIONS_DOC) {
      return this.structuralVersionsDocRef;
    }
    if (docKey === DocKeys.TRANSLATION_VERSIONS_DOC) {
      return this.translationVersionsDocRef;
    }
    if (docKey === DocKeys.AUDIT_LOG_DOC) {
      return this.auditLogDocRef;
    }
  }
}

// "Unit__metadata"
// "Unit__chaatMetadata"
// "Unit__verbatim"
// "Unit__structural"
// "Unit__wordGroups"
// "Unit__translations"
// "Unit__metadataBlocks"
// "Unit__threads"
// "Unit__chaatCues"
// "Unit__chaatTimestamps"
// "Unit__chaatAudioAnalysis"
// "Unit__chaatAudioRegions"
// "Unit__chaatAudioMarkers"
// "Unit__chaatSpeechTranscripts"
// "Unit__sentenceVersions"
// "Unit__wordGroupVersions"
// "Unit__structuralVersions"
// "Unit__warningSuppressions"
