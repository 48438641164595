import { action, observable } from 'mobx';
import { SamosaModel } from './samosa-model';

// not entirely sure if this needs to be observable, but gives us some extra flexibility
const samosaModelStore = observable({ model: null as SamosaModel });

export const useSamosaModel = (): SamosaModel => samosaModelStore.model;

export const setSamosaModel = action((model: SamosaModel) => {
  samosaModelStore.model = model;
  (window as any).samosaModel = model; // console convenience
});

export const getSamosaModel = (): SamosaModel => samosaModelStore.model;
