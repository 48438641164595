import { deploymentConfig } from '../../deployment-config';
import {
  consoleAddAuditLogMessage,
  // initChaatForUnit,
} from '../db/mutation-actions';
import { createLogger } from '@app/logger';

const log = createLogger('chaat-crud');

export async function newInitChaat(
  unitId: string,
  audioUrl: string,
  chaatAudioGcsBasePath: string,
  l2Code: string
) {
  consoleAddAuditLogMessage(unitId, 'initChaat');
  const url = `${
    deploymentConfig.masalaServerUrl
  }/new_chaat_init?unitId=${unitId}&audioUrl=${encodeURI(
    audioUrl
  )}&l2Code=${l2Code}&chaatAudioGcsBasePath=${chaatAudioGcsBasePath}`;
  log.info(`newInitChat - fetching: ${url}`);
  const response = await fetch(url);
  const result = await response.json();
  log.info(`result: ${JSON.stringify(response)}`);
  return result;
}

export const continueNewInitChaatUrl = (jobId: string) => {
  return `${deploymentConfig.masalaServerUrl}/continue_chaat_init?jobId=${jobId}`;
};

// export async function oldInitChaat(episodeKey: string) {
//   consoleAddAuditLogMessage(episodeKey, 'initChaat');
//   // const metadata = await extractInitChaatDataFromScriptData(episodeKey);
//   // console.log(`initChaat - extracted metadata: ${JSON.stringify(metadata)}`);
//   const localJobFlowFn = async () => runAudioProcessing(episodeKey);
//   return initChaatForUnit(episodeKey, localJobFlowFn);
// }

// async function runAudioProcessing(episodeKey: string): Promise<string> {
//   const result = await invokeAudioProcessingNext(episodeKey, true);
//   return result;
//   // const result2 = await invokeAwsTranscribeNext(episodeKey);
//   // return [result1, result2].join('; ');
// }

// export async function invokeAudioProcessingNext(
//   episodeKey: string,
//   triggerNext: boolean
// ): Promise<string> {
//   // note, python logic will handle the triggering of the aws transcription task
//   const url = `${
//     deploymentConfig.falconServerUrl
//   }/audio_processing_next?key=${episodeKey}&trigger_next=${String(
//     triggerNext
//   )}`;
//   console.log(`invokeAudioProcessing - invoking: ${url}`);
//   const result = await fetch(url);
//   const text = await result.text();
//   console.log(`response: ${text}`);
//   return text;
// }

// // no longer expected to be called from js level logic
// // invoked by aws lambda
// export async function invokeAwsTranscribeNext(
//   episodeKey: string
// ): Promise<string> {
//   const url = `${deploymentConfig.falconServerUrl}/aws_transcribe_next?key=${episodeKey}`;
//   console.log(`invokeAudioProcessing - invoking: ${url}`);
//   const result = await fetch(url);
//   const text = await result.text();
//   console.log(`response: ${text}`);
//   return text;
// }
