import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { AuthorsTable } from './authors-table';
import { AuthorManager } from '@masala-lib/catalog/db/author-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

export const AuthorsListScreen = observer(() => {
  const auth = Auth.getInstance();
  const manager = AuthorManager.getInstance();
  const list = manager.list;

  return (
    <Page
      title="Manage Authors"
      controls={
        <PageActions>
          {auth.can('manage_activity_guides') ? (
            <Link to={'/authors/new'} className="primary-action">
              Create new author
            </Link>
          ) : null}
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <AuthorsTable authors={list} />
      </OnePaneLayout>
    </Page>
  );
});
