import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { SidePopup } from '../../console/views/shared/side-popup';
import { ChaatTool } from '../chaat-tool';
import { ChaatCheetSheet } from './chaat-cheat-sheet';
import { KerningConfigDialog } from './kerning-config-dialog';
import { Sidebar } from './sidebar';
import { TracksComponent } from './tracks.jsx';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro');
  body {
    margin:0;
    padding:0;
  }
`;

const PanesContainer = styled.div`
  padding-left: 200px;
`;

const SideBarPane = styled.div`
  left: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  position: fixed;
  overflow: auto;
`;

const ScriptPane = styled.div`
  padding-top: 250px;
`;

const TracksContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
`;

const Layout = () => {
  return (
    <>
      <GlobalStyle />
      <PanesContainer>
        <SideBarPane>
          <Sidebar />
        </SideBarPane>
        <ScriptPane>
          <ChaatTool />
        </ScriptPane>
        <TracksContainer>
          <TracksComponent />
        </TracksContainer>
      </PanesContainer>
      <KerningConfigDialog />
      <SidePopup>
        <ChaatCheetSheet />
      </SidePopup>
    </>
  );
};

export default Layout;
