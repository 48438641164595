import React from 'react';
import { observer } from 'mobx-react';
import { StructuralMergeView } from './structural-merge-view';
import { runConfirmableDialog } from '../utils/dialog-runner';
import { SimpleDialog } from '../components/simple-dialog';

export const StructuralMergeModal = observer(
  ({ onResolve }: { onResolve: (text: string) => void }) => {
    const onCancel = () => onResolve(undefined);
    return (
      <SimpleDialog title="Structural merge" onDismiss={onCancel}>
        <StructuralMergeView />
      </SimpleDialog>
    );
  }
);

export const runStructuralMergeModal = async (): Promise<string> => {
  return runConfirmableDialog(onResolve => (
    <StructuralMergeModal onResolve={onResolve} />
  ));
};
