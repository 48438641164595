import { makeObservable } from 'mobx';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';

import {
  CatalogEntityKind,
  ExcerptData,
  SoundbiteEditionCatalogData,
  SoundbiteEditionData,
} from '../catalog-types';
import { Volume } from './volume';
import { createLogger } from '@app/logger';
import { VolumeManager } from '../db/volume-manager';
import { SoundbiteEditionManager } from '../db/soundbite-edition-manager';
import { Excerpt } from './excerpt';
import { VolumeCrud } from '../db/volume-crud';
import { EntityCrud } from '../db/entity-crud';
import { SoundbiteEditionCrud } from '../db/soundbite-edition-crud';

const log = createLogger('soundbite-edition');

export const emptySoundbiteEditionData: SoundbiteEditionData = {
  kind: CatalogEntityKind.SOUNDBITE_EDITION,
  id: '',
  name: '',
  slug: '',
  calendarSummary: '',
  channelId: '',
  volumeId: '',
  visibilityWeekPostEpoch: 0,
};

export class SoundbiteEdition
  extends BaseCatalogEntity<SoundbiteEditionData>
  implements CatalogEntity<SoundbiteEditionData>
{
  fetchedVolume: Volume;
  fetchedExcerpts: Excerpt[];

  constructor(d?: Partial<SoundbiteEditionData>) {
    super(d as ExcerptData);
    this.data = { ...emptySoundbiteEditionData, ...d };
    makeObservable(this);
  }

  get slug() {
    return this.data.slug;
  }

  get name() {
    return this.data.name; // || this.data.title;
  }

  get channelId() {
    return this.data.channelId;
  }

  get navPath(): string {
    return `/soundbite-editions/${this.id}`;
  }

  // get soundbitePreviewUrl(): string {
  //   if (this.data.dataUrl) {
  //     return soundbitePreviewUrl(this.data.dataUrl);
  //   } else {
  //     return null;
  //   }
  // }

  async ensureParents(): Promise<Volume> {
    // this.fetchedVolume = await VolumeManager.getInstance().loadById(
    //   this.data.volumeId
    // );
    // this.fetchedVolume?.ensureParents();
    // return this.fetchedVolume;
    return await this.fetchVolume();
  }

  get volume(): Volume {
    return (
      this.fetchedVolume ||
      VolumeManager.getInstance().getById(this.data.volumeId)
    );
  }

  async fetchVolume(): Promise<Volume> {
    if (!this.fetchedVolume) {
      this.fetchedVolume = await VolumeCrud.loadById(this.data.volumeId, {
        fetchParents: true,
      });
    }
    return this.fetchedVolume;
  }

  async fetchExcerpts(): Promise<Excerpt[]> {
    if (!this.fetchedExcerpts) {
      const volume = await this.fetchVolume();
      this.fetchedExcerpts = await volume.fetchExcerpts();
    }
    return this.fetchedExcerpts;
  }

  excerptById(id: string) {
    if (!this.fetchedExcerpts) {
      throw Error('SoundbiteEdition.excerptById: no fetchedExcerpts');
    }
    return this.fetchedExcerpts.find(excerpt => excerpt.id === id);
  }

  get calendarSoundbiteSlugs(): string[] {
    return this.calendarSoundbites.map(excerpt => excerpt.slug);
  }

  get auxilarySoundbiteSlugs(): string[] {
    return this.auxilarySoundbites.map(excerpt => excerpt.slug);
  }

  get soundbiteCount(): number {
    return (
      (this.data.calendarSoundbiteIds?.length || 0) +
      (this.data.auxilarySoundbiteIds?.length || 0)
    );
  }

  get calendarSoundbiteIds(): string[] {
    return this.data.calendarSoundbiteIds ?? [];
  }

  get calendarSoundbites(): Excerpt[] {
    // assumes manager has prefetched all data
    return this.calendarSoundbiteIds.map(id =>
      // ExcerptManager.getInstance().getById(id)
      this.excerptById(id)
    );
  }

  get auxilarySoundbiteIds(): string[] {
    return this.data.auxilarySoundbiteIds ?? [];
  }

  get auxilarySoundbites(): Excerpt[] {
    return this.auxilarySoundbiteIds.map(id =>
      // ExcerptManager.getInstance().getById(id)
      this.excerptById(id)
    );
  }

  async buildCatalogData(): Promise<SoundbiteEditionCatalogData> {
    // await this.ensureParents();
    // await ExcerptManager.getInstance().loadAll();
    await this.fetchVolume();
    await this.fetchExcerpts();
    return {
      slug: this.slug,
      volumeSlug: this.volume?.slug,
      calendarSummary: this.data.calendarSummary,
      calendarSoundbiteSlugs: this.calendarSoundbiteSlugs,
      auxilarySoundbiteSlugs: this.auxilarySoundbiteSlugs,
      visibilityWeekPostEpoch: this.data.visibilityWeekPostEpoch,
    };
  }

  async soundbiteSlugs(): Promise<string[]> {
    await this.fetchVolume();
    await this.fetchExcerpts();
    const result = [
      ...this.calendarSoundbiteSlugs,
      ...this.auxilarySoundbiteSlugs,
    ];
    return result;
  }

  // needed for sort mode
  get visibilityWeekPostEpoch(): number {
    return this.data.visibilityWeekPostEpoch;
  }

  // get channel(): Channel {
  //   return this.volume?.channel;
  // }

  // get channelSlug(): string {
  //   const result = this.channel?.slug;
  //   invariant(!!result, 'Excerpt: unresolved channelSlug');
  //   return result;
  // }

  // get volumeNormalizedName(): string {
  //   return this.volume?.normalizedName;
  // }

  // get releaseDateSort(): string {
  //   return notEmptyOrNA(this.data.releaseDate) ? this.data.releaseDate : null;
  // }

  // get ingestedAt(): string {
  //   return this.data.ingestedAt || new Date(0).toISOString(); // ensure that sorts won't barf
  // }

  // get ingestionAgePretty(): string {
  //   return agePretty(this.data.ingestedAt);
  // }

  // get isCatalogStale(): boolean {
  //   const ingestedAt = this.data.ingestedAt;
  //   if (!ingestedAt) return false;
  //   const publishedAt = this.channel.data?.reviewCaliVersion?.generatedAt;
  //   return !publishedAt || ingestedAt > publishedAt;
  // }

  // async ingest(): Promise<string> {
  //   this.setPendingOperation('ingesting excerpt...');
  //   const apiUrl = `${deploymentConfig.masalaServerUrl}/ingestExcerpt?excerptId=${this.id}`;
  //   console.log(`excerpt.ingest - apiUrl: ${apiUrl}`);
  //   let response = await fetch(apiUrl);
  //   if (response.status !== 200) {
  //     const responseText: string = await response.text();
  //     throw Error(`invoke failed: ${responseText}`);
  //   }
  //   const data = await response.json();
  //   console.log(`response: ${JSON.stringify(data)}`);
  //   this.clearPendingOperation();
  //   return data.dataUrl;
  // }

  get crud(): EntityCrud<SoundbiteEdition, SoundbiteEditionData> {
    return SoundbiteEditionCrud;
  }

  theManager(): EntityManager<
    CatalogEntity<SoundbiteEditionData>,
    SoundbiteEditionData
  > {
    return SoundbiteEditionManager.getInstance() as unknown as EntityManager<
      CatalogEntity<SoundbiteEditionData>,
      SoundbiteEditionData
    >;
  }
}
