import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { SamosaModel, loadSamosaModel } from '../models/samosa-model';
import { createLogger } from '@app/logger';
import { SamosaView } from './samosa-view';
const log = createLogger('samosa-screen');

interface LoaderState {
  model?: SamosaModel;
  failure?: Error;
}

export const SamosaScreen = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { model, failure } = loaderState;

  log.debug('projectId', projectId);
  React.useEffect(() => {
    loadSamosaModel(projectId)
      .then(model => {
        log.debug('model init complete');
        setLoaderState({ model });
      })
      .catch(error => {
        log.error(`model init error: ${error}`);
        const failure = error instanceof Error ? error : Error(String(error));
        setLoaderState({ failure });
      });
    // disposer?
  }, [projectId]);

  if (failure) {
    return <>error: {String(failure)}</>;
  }

  if (!model) {
    return <>...</>;
  }

  const goBack = () => {
    // duplicated logic from Unit.assistPath since do don't have a Unit instance handy here
    const unitAssistPath = `/units/${model.projectMetadata.unitId}/assist`;
    navigate(unitAssistPath);
  };

  return <SamosaView model={model} goBack={goBack} />;
};
