import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { ResizeIcon } from './icons';

const Wrapper = styled('div', {
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$colors$black-alpha-20',
  borderRadius: 8,
  color: '$colors$white-alpha-80',
  transition: 'all 0.2s ease',
  '&:hover': {
    background: '$colors$black-alpha-30',
    color: 'white',
  },
});

export const ResizeControl = observer(
  ({ onClick }: { onClick: () => void }) => {
    return (
      <Wrapper onClick={onClick}>
        <ResizeIcon />
      </Wrapper>
    );
  }
);
