import * as React from 'react';
import { observer } from 'mobx-react';
import { ActionDialog } from '../components/action-dialog';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ListItem, ListWrapper } from '../components/list';
import { styled } from '@naan/stitches.config';
import { DialogPresenter } from '../dialog-presenter';
import { KeyboardService } from 'lib/services/keyboard-service';
import { ItemsPickerController } from 'lib/services/ui-controllers/picker-controller';
import { PromptEntryModel } from '../message-entry/prompt-entry-model';
import { Prompt } from '@masala-lib/llm/project/llm-project-types';
import { showPromptManager } from './prompt-manager-modal';
import { PromptFormModal } from './prompt-form';
import { Button } from '@naan/primitives/modals';
import { filterPromptsOnLanguagesKey } from '@masala-lib/llm/project/prompt-funcs';

// const StyledListItem = styled(ListItem, {
//   gap: 8,
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   padding: '12px 0px',
//   cursor: 'default',
//   '&:hover': {
//     fontWeight: '500',
//   },
//   '& .controls': {
//     display: 'flex',
//   },
//   '& u': {
//     flex: 0,
//     textDecoration: 'underline',
//   },
// });

export const PromptListItem = styled(ListItem, {
  gap: 8,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 0px',
  cursor: 'default',
  '&:hover': {
    fontWeight: '500',
  },
  '& .controls': {
    display: 'flex',
  },
  '& u': {
    flex: 0,
    textDecoration: 'underline',
  },
});

export const PromptPicker = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const model = useSamosaModel();
    const promptModel = PromptEntryModel.instance;

    const reuseIndex = 0;

    // @armando, wanted confirm the usage of useMemo vs useRef for the picker
    // const picker = React.useRef<ItemsPickerController>(
    //   new ItemsPickerController({
    //     rows: prompts.length + 1,
    //     initialIndex: reuseIndex,
    //   })
    // ).current;
    const modelPrompts = model.prompts;
    const languagesKey = model.languagesKey;

    const prompts = React.useMemo<Prompt[]>(() => {
      const prompts = modelPrompts;
      return filterPromptsOnLanguagesKey(prompts, languagesKey);
    }, [modelPrompts, languagesKey]);
    const lastIndex = prompts.length + 1;

    const picker = React.useMemo<ItemsPickerController>(() => {
      const picker = new ItemsPickerController({
        rows: prompts.length + 1,
        initialIndex: reuseIndex,
      });
      return picker;
    }, [prompts]);

    const injectPrompt = React.useCallback(
      (prompt: Prompt) => {
        promptModel.setPromptText(prompt.text);
        onDismiss();
        promptModel.focusPromptArea();
      },
      [promptModel, onDismiss]
    );

    const injectLastPrompt = React.useCallback(() => {
      const text = model.lastPromptText;
      promptModel.setPromptText(text);
      onDismiss();
      promptModel.focusPromptArea();
    }, [model.lastPromptText, onDismiss, promptModel]);

    const onEnter = React.useCallback(() => {
      const index = picker.index;

      if (index === reuseIndex) {
        injectLastPrompt();
      } else if (index === lastIndex) {
        showPromptManager();
      } else {
        const prompt = prompts[index - 1];
        if (prompt) {
          promptModel.setPromptText(prompt.text);
        }
      }
      onDismiss();
      promptModel.focusPromptArea();
    }, [
      injectLastPrompt,
      prompts,
      onDismiss,
      picker.index,
      promptModel,
      reuseIndex,
      lastIndex,
    ]);

    const editPrompt = React.useCallback((prompt: Prompt) => {
      if (prompt) {
        DialogPresenter.present(onDismiss => (
          <PromptFormModal prompt={prompt} onDismiss={onDismiss} />
        ));
      }
    }, []);

    React.useEffect(() => {
      return KeyboardService.instance.addShortcutSet('prompt-picker', {
        ...picker.getKeybindings(),
        escape: [onDismiss, null],
        enter: [onEnter, 'Inject selected prompt'],
        r: [injectLastPrompt, 'Reuse last prompt'],
        e: [
          () => editPrompt(prompts[picker.index - 1]),
          'Edit selected prompt',
        ],
        m: [showPromptManager, 'Manage prompts…'],
      });
    }, [prompts, picker, onDismiss, onEnter, injectLastPrompt, editPrompt]);

    return (
      <ActionDialog
        onDismiss={onDismiss}
        disableContainerDismissal
        title={'Select prompt'}
      >
        <PromptList {...{ prompts, picker, injectPrompt, injectLastPrompt }} />
        <Button
          presentation={'grayLight'}
          radius="squared"
          label={
            (
              <>
                <u>M</u>anage prompts…
              </>
            ) as any
          }
          onClick={showPromptManager}
          // shortcut="ctrl+m"
        />
      </ActionDialog>
    );
  }
);

// isolate what gets rerendered with the picker state
const PromptList = observer(
  ({
    prompts,
    picker,
    injectPrompt,
    injectLastPrompt,
  }: {
    prompts: Prompt[];
    picker: ItemsPickerController;
    injectPrompt: (prompt: Prompt) => void;
    injectLastPrompt: () => void;
  }) => {
    const model = useSamosaModel();

    return (
      <ListWrapper>
        <PromptListItem
          keyboardSelected={picker.is(0)}
          onClick={() => {
            injectLastPrompt();
          }}
        >
          <span>
            <u>R</u>euse last prompt
          </span>
        </PromptListItem>
        {prompts.map((prompt, index) => (
          <PromptListItem
            key={index}
            keyboardSelected={picker.is(index + 1)}
            onClick={() => injectPrompt(prompt)}
          >
            <span>{prompt.title}</span>
          </PromptListItem>
        ))}
      </ListWrapper>
    );
  }
);

export const showPromptPicker = DialogPresenter.makePresenter(PromptPicker);
