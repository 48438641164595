import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { SoundbiteEditionCreateScreen } from './soundbite-edition-create-screen';
import { SoundbiteEditionDetailScreen } from './soundbite-edition-detail-screen';
import { SoundbiteEditionEditScreen } from './soundbite-edition-edit-screen';
import { SoundbiteEditionListScreen } from './soundbite-edition-list-screen';

export const SoundbiteEditionsScreen = () => {
  return (
    <ModelScreen
      path={'/soundbite-editions'}
      renderList={() => <SoundbiteEditionListScreen />}
      renderDetail={controller => (
        <SoundbiteEditionDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <SoundbiteEditionEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <SoundbiteEditionCreateScreen />}
    />
  );
};
