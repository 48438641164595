import { createLogger } from '@app/logger';
import { KeyboardShortcutsSet } from './types';

const log = createLogger('keyboard-service');

// converts objects like {'a,b':c} to {'a':c, 'b':c}
export function denormalizeKeys(object: KeyboardShortcutsSet) {
  const newObject: KeyboardShortcutsSet = {};

  Object.keys(object).forEach(key => {
    const values = key.split(',');
    values.forEach(value => {
      newObject[value.trim()] = object[key];
    });
  });

  return newObject;
}
