import { styled } from '@naan/stitches.config';

export const ListWrapper = styled('ul', {
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'column',
});

export const ListItem = styled('li', {
  $$selectionColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 12px',
  position: 'relative',
  '& + &': {
    borderTop: '1px solid $colors$gray-100',
  },
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 2,
    bottom: 2,
    left: -8,
    right: -8,
    backgroundColor: '$$selectionColor',
    zIndex: -1,
    borderRadius: 4,
  },
  variants: {
    keyboardSelected: {
      true: {
        // backgroundColor: 'rgba(16,92,127,0.3)',
        $$selectionColor: '$colors$blue-50',
      },
    },
  },
});
