import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { ExcerptCreateScreen } from './excerpt-create-screen';
import { ExcerptDetailScreen } from './excerpt-detail-screen';
import { ExcerptEditScreen } from './excerpt-edit-screen';
import { ExcerptListScreen } from './excerpt-list-screen';

export const ExcerptsScreen = () => {
  return (
    <ModelScreen
      path={'/excerpts'}
      renderList={() => <ExcerptListScreen />}
      renderDetail={controller => (
        <ExcerptDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <ExcerptEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <ExcerptCreateScreen />}
    />
  );
};
