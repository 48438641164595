import { computed, isBoxedObservable, untracked } from 'mobx';
import { makeObservable, observable } from 'mobx';
import { ElementId, ElementIdToString, idIsOfKind } from '@tikka/basic-types';
import {
  EKind,
  isStructuralElement,
  isStructuralKind,
} from '../../element-kinds';
import { StringToElement } from '@tikka/basic-types';
import {
  ChangeRecord,
  generateChangeMessageDataForChangeRecord,
  getBaselineVersions,
  getChangeRecordFromBaseline,
  MutationActions,
  versionsAreEquivalent,
} from '../db/mutation-actions';
import { numberProjectionSort } from '../../utils';
import {
  getKindFromId,
  getTranslationId,
} from '@tikka/elements/element-id-utils';
import {
  EditorElement,
  EditorElementList,
  ElementVersionData,
  SentenceVersionData,
  SentenceWriteDto,
  StructuralVersionData,
  TranslationVersionData,
  VersionItem,
  WordGroupVersionData,
} from '../../editorial-types';
import { getWordRecordsData } from '../../editorial-word-element-list';

export abstract class VersionsBase {
  @observable.ref baselineTimestamp: number = null;

  @observable.ref sentenceVersions: StringToElement<SentenceVersionData> = {};
  @observable.ref sentenceActiveVersionIdMap: ElementIdToString = {};

  @observable.ref structuralVersions: StringToElement<StructuralVersionData> =
    {};
  @observable.ref structuralActiveVersionIdMap: ElementIdToString = {};

  @observable.ref wordGroupVersions: StringToElement<WordGroupVersionData> = {};
  @observable.ref wordGroupActiveVersionIdMap: ElementIdToString = {};

  @observable.ref translationVersions: StringToElement<TranslationVersionData> =
    {};
  @observable.ref translationActiveVersionIdMap: ElementIdToString = {};

  constructor() {
    makeObservable(this);
  }

  @computed
  get sentenceVersionsData() {
    return Object.values(this.sentenceVersions);
  }

  @computed
  get structuralVersionsData() {
    return Object.values(this.structuralVersions);
  }

  @computed
  get wordGroupVersionsData() {
    return Object.values(this.wordGroupVersions);
  }

  @computed
  get translationVersionsData() {
    return Object.values(this.translationVersions);
  }

  @computed
  get sentenceActiveVersionsMap() {
    const activeVersions = Object.values(this.sentenceActiveVersionIdMap)
      .filter(id => id)
      .map(id => this.sentenceVersions[id]);
    return new Map(
      activeVersions.map(v => [v.id, v] as [ElementId, SentenceVersionData])
    );
  }

  @computed
  get structuralActiveVersionsMap() {
    const activeVersions = Object.values(this.structuralActiveVersionIdMap)
      .filter(id => id)
      .map(id => this.structuralVersions[id]);
    return new Map(
      activeVersions.map(v => [v.id, v] as [ElementId, StructuralVersionData])
    );
  }

  @computed
  get wordGroupActiveVersionsMap() {
    const activeVersions = Object.values(this.wordGroupActiveVersionIdMap)
      .filter(id => id)
      .map(id => this.wordGroupVersions[id]);
    return new Map(
      activeVersions.map(v => [v.id, v] as [ElementId, WordGroupVersionData])
    );
  }

  @computed
  get translationActiveVersionsMap() {
    const activeVersions = Object.values(this.translationActiveVersionIdMap)
      .filter(id => id)
      .map(id => this.translationVersions[id]);
    return new Map(
      activeVersions.map(v => [v.id, v] as [ElementId, TranslationVersionData])
    );
  }

  @computed
  get allVersions() {
    return {
      ...this.sentenceVersions,
      ...this.structuralVersions,
      ...this.wordGroupVersions,
      ...this.translationVersions,
    };
  }

  @computed
  get baselineVersions() {
    const timestamp = this.baselineTimestamp;
    const result = untracked(() => {
      const sentenceBaselines = getBaselineVersions(
        this.sentenceVersionsData,
        timestamp
      );
      const structuralBaselines = getBaselineVersions(
        this.structuralVersionsData,
        timestamp
      );
      const wordGroupBaselines = getBaselineVersions(
        this.wordGroupVersionsData,
        timestamp
      );
      const translationBaselines = getBaselineVersions(
        this.translationVersionsData,
        timestamp
      );
      return {
        ...sentenceBaselines,
        ...structuralBaselines,
        ...wordGroupBaselines,
        ...translationBaselines,
      };
    });
    return result;
  }

  getChangeRecord(version: ElementVersionData): ChangeRecord {
    const baseVersionId = version.baseVersionId;
    let baseVersion = baseVersionId
      ? this.allVersions[version.baseVersionId]
      : null;
    baseVersion = baseVersion === version ? null : baseVersion;
    if (versionsAreEquivalent(version, baseVersion)) {
      return null;
    }
    return {
      version,
      baseVersion,
    };
  }

  getLastUpdated(count: number = null) {
    let versions: ElementVersionData[] = [
      ...this.sentenceVersionsData,
      ...this.structuralVersionsData,
      ...this.wordGroupVersionsData,
      ...this.translationVersionsData,
    ] as any;
    numberProjectionSort(versions, el => -el.timestamp);
    if (count) {
      versions = versions.slice(0, count);
    }
    return versions;
  }

  getLastChangeRecords(count: number = null) {
    return this.getLastUpdated(count * 5)
      .map(v => this.getChangeRecord(v))
      .filter(record => record)
      .slice(0, count);
  }

  getLastChangeMessages(count: number = null) {
    return this.getLastChangeRecords(count)
      .map(change => generateChangeMessageDataForChangeRecord(change, true))
      .filter(item => item);
  }

  getSortedVersionsData(id: ElementId): ElementVersionData[] {
    let vdata: ElementVersionData[] = null;
    const kind = getKindFromId(id);
    // if (kind === EKind.WORD_GROUP) {
    if (idIsOfKind(id, EKind.WORD_GROUP)) {
      vdata = this.wordGroupVersionsData;
    } else if (isStructuralKind(kind)) {
      vdata = this.structuralVersionsData;
    }
    // TODO verbatim and translations

    vdata = vdata.filter((el: ElementVersionData) => el.id === id);
    numberProjectionSort(vdata, el => -el.timestamp);
    return vdata;
  }

  getVersionBeforeOrAtTimestamp(id: ElementId, timestamp: number) {
    const vdata = this.getSortedVersionsData(id);
    for (const version of vdata) {
      if (version.timestamp <= timestamp) {
        return version;
      }
    }
    return null;
  }

  doReverts(versionsData0: ElementVersionData | ElementVersionData[]): void {
    versionsData0 = Array.isArray(versionsData0)
      ? versionsData0
      : [versionsData0];
    const versionsData = versionsData0 as ElementVersionData[];
    for (const vdata of versionsData) {
      if (vdata.kind === EKind.WORD_GROUP) {
        this.mutationActions.revertWordGroup(vdata);
      } else if (vdata.kind === EKind.TRANSLATION) {
        this.mutationActions.revertTranslation(vdata);
      } else if (vdata.kind === EKind.SENTENCE) {
        this.revertToSentenceVersion(vdata);
      } else if (isStructuralElement(vdata)) {
        this.mutationActions.revertStructural(vdata);
      }
    }
  }

  revertTo(versionItem: VersionItem) {
    this.doReverts(versionItem.versionData);
  }

  revertToSentenceVersion(version: SentenceVersionData): void {
    const sentenceWordMap = new Map(version.words.map(w => [w.id, w]));
    // const wordRecordsData = this.activeContent.words.__wordRecordsData;
    const wordRecordsData = getWordRecordsData(this.activeContent.words);
    const wordRecords = wordRecordsData.allWordRecords.slice();
    const startWordId = version.anchor.wordId;
    const startWordRecordIndex =
      wordRecordsData.getIndexAllRecords(startWordId);
    const sentences = this.activeContent.filterByKind(EKind.SENTENCE);
    const lastWordId = version.words[version.words.length - 1].id;
    const followingSentenceId = sentences.nextId(lastWordId, null, true);
    const followingSentence = sentences.getElement(followingSentenceId);
    const terminalWordId = followingSentence.anchor.wordId;
    for (
      let i = startWordRecordIndex;
      wordRecords[i].id < terminalWordId;
      i++
    ) {
      const wordRecord = wordRecords[i];
      const wordId = wordRecord.id;
      const revertWord = sentenceWordMap.get(wordId);
      if (revertWord) {
        wordRecord.active = true;
        wordRecord.text = revertWord.text;
      } else {
        wordRecord.active = false;
      }
    }
    const sentenceDTOs: SentenceWriteDto[] = [];
    sentenceDTOs.push({
      id: version.id,
      anchor: {
        wordId: version.anchor.wordId,
      },
    });
    let containedSentenceId = sentences.nextId(
      sentences.prevId(startWordId, null, true)
    );
    while (containedSentenceId && containedSentenceId !== followingSentenceId) {
      if (containedSentenceId !== version.id) {
        const deleteSentence = sentences.getElement(containedSentenceId);
        sentenceDTOs.push({
          id: deleteSentence.id,
          anchor: deleteSentence.anchor,
          deleted: true,
        });
      }
      containedSentenceId = sentences.nextId(containedSentenceId);
    }
    this.mutationActions.updateVerbatim(wordRecords, sentenceDTOs);
  }

  abstract get mutationActions(): MutationActions;
  abstract get activeContent(): EditorElementList<EditorElement>;
}
