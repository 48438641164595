import { isFunction } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;

  & > header {
    font-size: 14px;
    display: block;
    font-weight: 700;
    color: #a0a0a0;
  }

  & > .data-value {
    color: #151515;
  }

  &:last-child {
    border: none;
    margin-bottom: 0;
  }
`;

interface DataItemProps {
  label?: string;
  value?: React.ReactNode;
  children?: React.ReactNode;
}

export const DataItem = ({ label, value, children }: DataItemProps) => {
  return (
    <Wrapper>
      {label ? (
        <header>
          <span className="data-term">{label}</span>
        </header>
      ) : null}
      <div className="data-value">
        {isFunction(value) ? value() : value}
        {children}
      </div>
    </Wrapper>
  );
};
