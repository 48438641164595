import React from 'react';
import { MainTrackWidget } from './main-track-widget';
import { StatusBar } from './status-bar';
import { CueInsertTarget } from './cue-insert-target';
import { TrickyMainTrackWidget } from './tricky-bits-main-track-widget';
import { TrackWidget } from '@masala-lib/editorial/chaat/ui/track-area/track-widget';
import { appRoot } from 'chaat/models/app-root';

export class TracksComponent extends React.Component {
  mainTrackWidget: TrackWidget = null;

  componentDidMount() {
    const canvas = this.refs.canvas as HTMLCanvasElement;
    if (appRoot.mode === 'tricky') {
      this.mainTrackWidget = new TrickyMainTrackWidget();
    } else {
      this.mainTrackWidget = new MainTrackWidget();
    }
    // setMainTrackWidget(this.mainTrackWidget); TODO used by status bar in old code
    this.mainTrackWidget.setCanvas(canvas);
    this.mainTrackWidget.setElementId('trackContainer');
  }

  render() {
    return (
      <div
        style={{
          paddingLeft: 200,
          paddingTop: 15,
          paddingBottom: 15,
          backgroundColor: '#f4f4f4',
          borderBottom: '2px solid lightgrey',
        }}
      >
        <div
          style={{
            border: '1px solid black',
            width: 'max-content',
            backgroundColor: '#ffffff',
          }}
        >
          <StatusBar />
          <CueInsertTarget />
          <div id={'trackContainer'}>
            <canvas
              id={'theCanvas'}
              ref="canvas"
              width={1000}
              height={148}
              style={{ borderTop: '1px solid black' }}
            />
          </div>
        </div>
      </div>
    );
  }
}

// TODO ISSUE working audio time range start and end point
