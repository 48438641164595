import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { VolumeForm } from './volume-form';
import { VolumeData } from '@masala-lib/catalog/catalog-types';
import { useVolume } from './use-volume';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';

interface VolumeDetailsParams {
  stopEditing: () => void;
}

export const VolumeEditScreen = observer(
  ({ stopEditing }: VolumeDetailsParams) => {
    const manager = VolumeManager.getInstance();
    const currentVolume = useVolume();

    if (!currentVolume) {
      return null;
    }

    const onSubmit = (dto: VolumeData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      currentVolume.fromFormData(dto);
      manager.update(currentVolume).then(stopEditing);
      consoleAddAuditLogMessage(
        currentVolume.firstUnitKey,
        `updateVolumeDetails`
      );
    };

    return (
      <Page title={currentVolume.name}>
        <VolumeForm
          dto={currentVolume.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
        />
      </Page>
    );
  }
);
