import { get, isEmpty } from 'lodash';
import { ObservableMap } from 'mobx';
import styled from 'styled-components';
import {
  getQueryParamsFromLocation,
  serializeQueryString,
} from '../../lib/query-stuff';
import { XIcon } from '../../components/icons/x-icon';
import { useLocation, useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  & > .filters {
    display: flex;
    font-family: roboto;
    background-color: var(--color-blue20);
    border-radius: 8px;
    padding: 6px;
    flex-wrap: wrap;

    & > * + * {
      margin-left: -4px;
    }

    & > .filter {
      margin: 4px;
      /* margin-right: 12px; */
      display: flex;
      font-size: 14px;
      line-height: 21px;
      background: var(--color-blue50);
      padding: 4px 12px;
      border-radius: 4px;

      span.label {
        font-weight: 400;
        margin-right: 6px;
        color: var(--color-blue400);
      }

      span.value {
        font-weight: 700;
        color: var(--color-blue500);
      }

      button {
        background: none;
        border: none;
        width: 21px;
        height: 21px;
        margin-left: 4px;
        margin-right: -6px;
        color: var(--color-blue700);
        border-radius: 3px;
        &:hover {
          background: var(--color-blue100);
          color: var(--color-blue900);
        }
      }
    }
  }
`;

interface FilterBarProps {
  filters: ObservableMap<string, any>;
  nameMap?: { [key: string]: string };
  valueDecorator?: (filterName: string, value: string) => string;
}

const defaultDecorator = (filterName: string, filterValue: string): string =>
  filterValue;

export function FiltersBar({
  filters,
  nameMap = {},
  valueDecorator = defaultDecorator,
}: FilterBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const filterArray = Array.from(filters).filter(([, filter]) => {
    return !isEmpty(filter.value);
  });

  const removeFilter = (queryName: string) => () => {
    const { pathname } = location;
    const curQuery = getQueryParamsFromLocation(location, false);
    curQuery[queryName] = null;
    const newSearchString = serializeQueryString(curQuery);
    navigate(`${pathname}?${newSearchString}`);
  };

  if (filterArray.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <div className="filters">
        {filterArray.map(([filterName, filter]) => {
          const label = get(nameMap, filterName, filterName);
          const queryName = get(filter, 'queryName', filterName);

          return (
            <div className="filter">
              <span className="label">{label}</span>
              <span className="value">
                {valueDecorator(filterName, filter.value)}
              </span>
              <button
                onClick={removeFilter(queryName)}
                title={`remove ${label} filter`}
              >
                <XIcon size={12} />
              </button>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}
