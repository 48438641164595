import { Step } from '@masala-lib/llm/project/llm-project-types';
import { ExchangeItem } from './exchange-item';
import { ReferenceScriptItem } from './reference-script-item';

export const stepTypeMap: {
  [index: string]: ({ step }: { step: Step }) => JSX.Element;
} = {
  REFERENCE_SCRIPT: ReferenceScriptItem,
  EXCHANGE: ExchangeItem,
};
