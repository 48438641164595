import React from 'react';
import { observer } from 'mobx-react';
import type { AppUser } from '@masala-lib/editorial/models/user-manager';
import { UserForm } from './user-form';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';
import { useNavigate } from 'react-router-dom';

// handles both edit and new
// if no 'id' route param, then presents the new user form
interface Props {
  manager: FilterViewManager<AppUser>; //UserManager;
  onCancel: () => void;
}
export const UserCreate = observer(({ manager, onCancel }: Props) => {
  const model = { id: '' };
  const navigate = useNavigate();

  const onSubmit = (dto: AppUser) => {
    manager.create(dto).then(() => navigate(-1));
  };

  return <UserForm dto={model} onSubmit={onSubmit} onCancel={onCancel} />;
});
