import styled, { StyledProps } from 'styled-components';

const BadgeWrapper = styled.span`
  --badge-background-color: var(--color-blue50);
  --badge-color: var(--color-blue700);

  display: inline-block;
  color: var(--badge-color);
  background-color: var(--badge-background-color);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 9px;
  text-shadow: 1px 1px 0 var(--color-opacity10-black);
  letter-spacing: 0.0125em;

  &.type-published {
    --badge-background-color: var(--color-green50);
    --badge-color: var(--color-green700);
  }
`;

interface BadgeProps {
  backgroundColor?: string;
  color?: string;
  children: React.ReactNode;
}

export function Badge({
  backgroundColor,
  color,
  children,
  ...badgeProps
}: BadgeProps) {
  const styles = {} as any;

  if (backgroundColor) {
    styles['--badge-background-color'] = backgroundColor;
  }
  if (color) {
    styles['--badge-background-color'] = backgroundColor;
  }
  return (
    <BadgeWrapper style={styles} {...badgeProps}>
      {children}
    </BadgeWrapper>
  );
}
