import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { TagData } from '@masala-lib/catalog/catalog-types';
import { TagForm } from './tag-form';
import { Tag } from '@masala-lib/catalog/models/tag';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';
import { useNavigate } from 'react-router-dom';

export const TagCreateScreen = observer(() => {
  const tagManager = TagManager.getInstance();
  const navigate = useNavigate();
  const tag = new Tag();

  const goBack = () => {
    navigate(`/tags`);
  };

  const onSubmit = (dto: TagData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    tag.fromFormData(dto);
    tagManager.create(tag).then(goBack);
  };

  return (
    <Page title={'Creating new tag'}>
      <TagForm dto={tag.toFormData()} onSubmit={onSubmit} onCancel={goBack} />
    </Page>
  );
});
