import { Slice } from './timestamper-types';
import { range } from 'lodash';
import { searchSorted } from '@tikka/intervals/search-sorted';

export function mapSetDefault<T1, T2>(x: Map<T1, T2>, key: T1, def: T2): T2 {
  const value = x.get(key);
  if (!value) {
    x.set(key, def);
    return def;
  } else {
    return value;
  }
}

export function sliceIndexes(slice: Slice): number[] {
  // let (starts, ends) = slice
  // seq {starts .. ends}
  const [starts, ends] = slice;
  return range(starts, ends + 1, 1);
}

export function sliceWith<T>(a: T[], slice: Slice): T[] {
  if (!slice) {
    return a.slice(); // TODO maybe just return original array, not problem for timestamper because are immutable in practice
  }
  if (typeof slice[1] === 'number') {
    return a.slice(slice[0], slice[1] + 1);
  }
  return a.slice(slice[0]);
}
export function isArrayLike(x: any) {
  return Array.isArray(x) || ArrayBuffer.isView(x);
}

export function compareArraysWith(
  x: any[],
  y: any[],
  comp: (a: any, b: any) => number
) {
  if (x == null) {
    return y == null ? 0 : 1;
  }
  if (y == null) {
    return -1;
  }
  if (x.length !== y.length) {
    return x.length < y.length ? -1 : 1;
  }
  for (let i = 0, j = 0; i < x.length; i++) {
    j = comp(x[i], y[i]);
    if (j !== 0) {
      return j;
    }
  }
  return 0;
}

export function compareArrays(x: any, y: any) {
  return compareArraysWith(x, y, compare);
}

export function dateOffset(date: any) {
  const date1 = date;
  return typeof date1.offset === 'number'
    ? date1.offset
    : date.kind === 1 /* UTC */
    ? 0
    : date.getTimezoneOffset() * -60000;
}

export function compareDates(x: any, y: any): number {
  let xtime;
  let ytime;
  // DateTimeOffset and DateTime deals with equality differently.
  if ('offset' in x && 'offset' in y) {
    xtime = x.getTime();
    ytime = y.getTime();
  } else {
    xtime = x.getTime() + dateOffset(x);
    ytime = y.getTime() + dateOffset(y);
  }
  return xtime === ytime ? 0 : xtime < ytime ? -1 : 1;
}

function compareObjects(x: any, y: any): number {
  const xKeys = Object.keys(x);
  const yKeys = Object.keys(y);
  if (xKeys.length !== yKeys.length) {
    return xKeys.length < yKeys.length ? -1 : 1;
  }
  xKeys.sort();
  yKeys.sort();
  for (let i = 0, j = 0; i < xKeys.length; i++) {
    const key = xKeys[i];
    if (key !== yKeys[i]) {
      return key < yKeys[i] ? -1 : 1;
    } else {
      j = compare(x[key], y[key]);
      if (j !== 0) {
        return j;
      }
    }
  }
  return 0;
}

export function comparePrimitives(x: any, y: any) {
  return x === y ? 0 : x < y ? -1 : 1;
}

export function compare(x: any, y: any): number {
  if (x === y) {
    return 0;
  } else if (x == null) {
    return y == null ? 0 : -1;
  } else if (y == null) {
    return 1;
  } else if (typeof x !== 'object') {
    return x < y ? -1 : 1;
  } else if (isArrayLike(x)) {
    return isArrayLike(y) ? compareArrays(x, y) : -1;
  } else if (x instanceof Date) {
    return y instanceof Date ? compareDates(x, y) : -1;
  } else {
    return Object.getPrototypeOf(x).constructor === Object
      ? compareObjects(x, y)
      : -1;
  }
}

export function unionIntervals(
  aStarts: number[],
  aEnds: number[],
  bStarts: number[],
  bEnds: number[]
): [number[], number[]] {
  let indexACopyFrom = 0;
  let indexACopyTo = 0;
  let unionStart = 0;
  let unionEnd = 0;
  const startsOut: number[] = [];
  const endsOut: number[] = [];
  const maxValidIndexA = (aStarts.length - 1) | 0;
  if (maxValidIndexA < 0) {
    return [bStarts, bEnds];
  }
  // const arr = indexed(bStarts);
  for (const [indexB, startVal] of bStarts.entries()) {
    // const forLoopVar = arr[idx];
    // const startVal = forLoopVar[1] | 0;
    // const indexB = forLoopVar[0] | 0;
    const startIndexA = searchSorted(aStarts, startVal, false) - 1;
    // TODO if startIndexA <= maxValidIndexA && aEnds.[startIndexA] > startVal then
    if (startIndexA <= maxValidIndexA ? aEnds[startIndexA] > startVal : false) {
      unionStart = aStarts[startIndexA];
      indexACopyTo = startIndexA - 1;
    } else {
      indexACopyTo = startIndexA;
      unionStart = startVal;
    }

    // TODO if indexACopyFrom <= maxValidIndexA && indexACopyFrom <= indexACopyTo then
    if (
      indexACopyFrom <= maxValidIndexA ? indexACopyFrom <= indexACopyTo : false
    ) {
      // System_Array__$005B$005D$1_extend_5975E3(
      startsOut.push(...aStarts.slice(indexACopyFrom, indexACopyTo + 1));
      // System_Array__$005B$005D$1_extend_5975E3(
      endsOut.push(...aEnds.slice(indexACopyFrom, indexACopyTo + 1));
    }
    const endIndexA = searchSorted(aEnds, bEnds[indexB], false) | 0;
    // TODO if endIndexA <= maxValidIndexA && aStarts.[endIndexA] < bEnds.[indexB] then
    if (
      endIndexA <= maxValidIndexA ? aStarts[endIndexA] < bEnds[indexB] : false
    ) {
      unionEnd = aEnds[endIndexA];
      indexACopyFrom = endIndexA + 1;
    } else {
      indexACopyFrom = endIndexA;
      unionEnd = bEnds[indexB];
    }
    // System_Array__$005B$005D$1_append_1505(startsOut, unionStart);
    startsOut.push(unionStart);
    // System_Array__$005B$005D$1_append_1505(endsOut, unionEnd);
    endsOut.push(unionEnd);
  }
  if (indexACopyFrom <= maxValidIndexA) {
    // System_Array__$005B$005D$1_extend_5975E3(
    startsOut.push(...aStarts.slice(indexACopyFrom, aStarts.length));
    // System_Array__$005B$005D$1_extend_5975E3(
    endsOut.push(...aEnds.slice(indexACopyFrom, aStarts.length));
  }
  return [startsOut, endsOut];
}
