import { observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Table } from '../shared/table';
import { isEmpty, isFunction, kebabCase } from 'lodash';
import { phraseize } from '../../lib/phraseize';
import { A, ActionLink } from '@masala-lib/editorial/ui/action-link';
import { makeQueryPath } from '../../lib/query-stuff';
import { Shelf } from '../shared/shelf';
import { formatDate } from '../../lib/formatters';

import styled from 'styled-components';
import classNames from 'classnames';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import * as colors from '../../../chaat/ui/colors';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';

const Badge = styled.span`
  --badge-background-color: var(--color-blue50);
  --badge-color: var(--color-blue700);

  display: inline-block;
  color: var(--badge-color);
  background-color: var(--badge-background-color);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 6px;
  border-radius: 9px;
  text-shadow: 1px 1px 0 var(--color-opacity10-black);
  letter-spacing: 0.0125em;

  &.type-published {
    --badge-background-color: var(--color-green50);
    --badge-color: var(--color-green700);
  }

  &.type-pending {
    --badge-background-color: var(--color-yellow50);
    --badge-color: var(--color-yellow700);
  }
`;

const UnitCell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 500px);
  min-width: 500px;

  > * + * {
    margin-top: 6px;
  }

  .volume-unit {
    font-size: 14px;
    line-height: 21px;
    display: flex;
    .volume-link {
      color: var(--color-gray600);
      font-weight: 400;
      max-width: 30%;
      text-overflow: ellipsis;

      /* Required for text-overflow to do anything */
      white-space: nowrap;
      overflow: hidden;

      &:hover {
        color: var(--color-gray800);
        text-decoration: underline;
        text-decoration-color: var(--color-gray300);
      }
    }
    .unit-link {
      color: var(--color-gray700);
      font-weight: 500;

      &.archived {
        color: var(--color-gray400);
      }

      &:hover {
        color: var(--color-blue800);
        text-decoration: underline;
        text-decoration-color: var(--color-gray300);
      }
    }
    .unit-link + .volume-link {
      max-width: 65%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &::before {
        content: '/';
        padding-left: 1ch;
        padding-right: 1ch;
        color: var(--color-gray200);
      }
    }
  }

  .unit-meta {
    > * + * {
      &::before {
        content: '|';
        margin-left: 8px;
        margin-right: 8px;
        color: var(--color-gray200);
      }
    }
    .release-date {
    }
  }

  .operation-status {
    padding: 6px 12px;
    background-color: var(--color-yellow100);
    color: var(--color-gray900);
    align-self: flex-start;
    /* border-radius: 20px; */
  }
`;

const Warnings = styled.span`
  margin-left: 5px;
  background-color: ${colors.lightRed};
`;

const OpenThreads = styled.span`
  margin-left: 5px;
  background-color: ${colors.lightYellow};
`;

// erases the /_/ path fragment for an unscoped list view
export const normalizePathId = (path: string): string => {
  return path === '_' ? null : path;
};

interface UnitsTableProps {
  units: Unit[];
  currentSortingFieldName?: string;
  currentSortingOrder?: string;
  swapUnitNumber?: (unit: Unit, moveUp: boolean) => void;
}

export const UnitsTable = observer(({ units, ...props }: UnitsTableProps) => {
  const showReorderButtons = isFunction(props.swapUnitNumber);
  return (
    <Table
      data={units}
      cells={[
        {
          header: 'Volume / Unit',
          render: unit => (
            <UnitCell>
              <div className="volume-unit">
                <Link
                  to={unit.navPath}
                  className={classNames('unit-link', {
                    archived: unit.archived,
                  })}
                >
                  {unit.name} ({unit.slug})
                </Link>
                {unit.volumeName /*  && unit.volumeName !== unit.name */ ? (
                  <Link
                    className={'volume-link'}
                    to={makeQueryPath(`/units`, { volume: unit.volumeId })}
                  >
                    {unit.volumeName}
                  </Link>
                ) : null}
                {unit.data.warningCount ? (
                  <Warnings>&nbsp;W: {unit.data.warningCount}&nbsp;</Warnings>
                ) : null}
                {unit.data.openThreadCount ? (
                  <OpenThreads>
                    &nbsp;U: {unit.data.openThreadCount}&nbsp;
                  </OpenThreads>
                ) : null}
                {/*unit.ingestionNeeded ? (
                  <span>
                    <Badge className="type-pending">Stale Ingestion</Badge>
                  </span>*/
                /* TODO: cali version of these
                ) : unit.pendingPromotion ? (
                  <span>
                    <Badge className="type-pending">Stage Pending</Badge>
                  </span>
                ) : unit.pendingPublish ? (
                  <span>
                    <Badge className="type-pending">Publish Pending</Badge>
                  </span>
                ) : unit.pendingMarkLive ? (
                  <span>
                    <Badge className="type-pending">Live Pending</Badge>
                  </span>
                ) */
                /*) : null*/}
              </div>
              <div className="unit-meta">
                <span className="release-date">
                  Release date <b>{formatDate(unit.data.releaseDate)}</b>
                </span>
                <span className="release-date">
                  Ingested at <b>{uiTimeString(unit.data.ingestedAt)}</b>
                </span>
                <span className="release-date">
                  Updated at{' '}
                  <b>
                    {uiTimeString(
                      unit.data.contentUpdatedAt || unit.data.updatedAt
                    )}
                  </b>
                </span>
                {/* <span className="release-date">
                  Updated at <b>{uiTimeString(unit.data.updatedAt)}</b>
                </span> */}
                {unit.data.workflowStatus ? (
                  <span>
                    <Badge
                      as={Link}
                      className={`type-${kebabCase(unit.data.workflowStatus)}`}
                      to={makeQueryPath(`/units`, {
                        workflow_status: unit.data.workflowStatus,
                      })}
                    >
                      {phraseize(unit.data.workflowStatus)}
                    </Badge>
                  </span>
                ) : null}
              </div>
              {unit.operationStatus ? (
                <div className="operation-status">{unit.operationStatus}</div>
              ) : null}
            </UnitCell>
          ),
        },
        {
          show: showReorderButtons,
          header: 'Re-Order',
          render: unit => (
            <>
              {unit.data.unitNumber}.{' '}
              {!unit.isFirstUnit ? (
                <ActionLink onPress={() => props.swapUnitNumber(unit, true)}>
                  [↑]
                </ActionLink>
              ) : null}{' '}
              {!unit.isLastUnit ? (
                <ActionLink onPress={() => props.swapUnitNumber(unit, false)}>
                  [↓]
                </ActionLink>
              ) : null}
            </>
          ),
        },
        {
          show: !UnitManager.getInstance().showArchived,
          header: 'Actions',
          render: unit => <UnitActions unit={unit} />,
        },
      ]}
    />
  );
});
const UnitActions = observer(({ unit }: { unit: Unit }) => (
  <Shelf>
    {unit.isLocked ? <strong>[Locked]</strong> : null}
    {unit.scriptInitted ? (
      <>
        {/* beware, largely duplicated in unit-detail.tsx */}
        {/* <Link to={unit.editorNavPath()} className="secondary-action">
        script editor
      </Link> */}
        <NavLink to={unit.assistPath} className="secondary-action">
          Assist
        </NavLink>
        <A href={unit.resolveEditorUrl()} className="secondary-action">
          Script
        </A>
        {unit.chaatInitted && !unit.isLocked ? (
          unit.chaatReady ? (
            <A href={unit.chaatUrl} className="secondary-action">
              Chaat
            </A>
          ) : null // this state now covered in Util.operationStatus
        ) : null}
        {isEmpty(unit.data.linearUrl) ? null : (
          <A href={unit.data.linearUrl} className="secondary-action">
            Linear
          </A>
        )}
      </>
    ) : (
      <>
        <Link to={unit.navPath} className="secondary-action">
          Import
        </Link>
      </>
    )}
  </Shelf>
));
