import { observer } from 'mobx-react';
import * as React from 'react';
import { DialogPresenter } from './dialog-presenter';

export const PresentedDialog = observer(() => {
  // const element = DialogPresenter.topElement();
  // return element ? element : null;

  // beware, for stacked dialogs to be usable, the parent dialogs most not have any dialog level dismiss hook
  // because it's will get undesireably dismissed when sub-dialog opened
  return (
    <React.Fragment>
      {DialogPresenter.elements.map((element, index) => (
        <React.Fragment key={index}>{element}</React.Fragment>
      ))}
    </React.Fragment>
  );
});
