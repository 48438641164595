import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { isEmpty, set } from 'lodash';
import { Auth } from '@masala-lib/editorial/db/auth';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { Volume } from '@masala-lib/catalog/models/volume';
import { createLogger } from '@app/logger';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { Select } from '@console/components/forms';
import { useNavigate } from 'react-router-dom';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';

const log = createLogger('volume-import');

async function forkIntoChannel(volume: Volume, channelId: string) {
  try {
    consoleAddAuditLogMessage(volume.id, 'Fork into channel: ' + channelId);
    // todo: need volume level audit logs
  } catch (error) {
    volume.clearPendingOperation();
    window.alert(error);
    throw error;
  }
}

interface FormData {
  id: string;
  channelId: string;
}

export const VolumeFork = observer(({ model }: { model: Volume }) => {
  const auth = Auth.getInstance();
  const formContext = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formContext;
  const navigate = useNavigate();

  const onSubmit = async (data: FormData) => {
    const channel = ChannelManager.getInstance().getById(data.channelId);
    const forkId = await model.createForkInChannel(channel);
    if (forkId) {
      // setTimeout(() => scroll to top of form somehow, 500);
      if (window.confirm('Fork created. Do you want to view it?')) {
        navigate(`/volumes/${forkId}`);
      }
    }
  };

  const cloneNewExcerpts = async () => {
    const cloned = await model.cloneNewExcerptsToAllForkVolumes();
    if (cloned.length > 0) {
      window.alert(
        'Cloned new excerpts to all fork volumes: \n' + cloned.join('\n')
      );
    } else {
      window.alert('No new excerpts to clone');
    }
  };

  if (!auth.can('manage_volumes')) {
    return null;
  }

  const channelOptions = ChannelManager.getInstance().selectOptionsWithBlank;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          defaultValue={model.id}
          {...register('id', { required: false })}
        />
        <Select
          {...register('channelId', { required: false })}
          label="Fork to Channel"
          formContext={formContext}
          options={channelOptions}
          // value={channelOptions.find(
          //   option => option.value === selectedChannel
          // )}
          // onChange={handleChannelSelection}
          required
        />
        <input type="submit" /> &nbsp;
      </form>
      <br />
      <ActionLink onPress={cloneNewExcerpts}>
        [Clone new excerpts to all fork volumes]
      </ActionLink>
    </>
  );
});
