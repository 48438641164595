export const ForwardArrowIcon = ({ size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 100 100"
    width={size}
    height={size}
  >
    <path d="M65.391,67.891l32.892-16.25V48.36L65.391,32.11L37.5,17.734V37.5H0v25h37.5v19.766L65.391,67.891"></path>
  </svg>
);
