import * as React from 'react';
import { observer } from 'mobx-react';
import { ActionDialog } from '../components/action-dialog';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ListItem, ListWrapper } from '../components/list';
import { styled } from '@naan/stitches.config';
import { DialogPresenter } from '../dialog-presenter';
import { KeyboardService } from 'lib/services/keyboard-service';
import { ItemsPickerController } from 'lib/services/ui-controllers/picker-controller';
import { llmModelKeys } from '@masala-lib/llm/project/llm-project-funcs';

const LlmListItem = styled(ListItem, {
  gap: 8,
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 0px',
  cursor: 'default',
  '&:hover': {
    fontWeight: '500',
  },
  '& .controls': {
    display: 'flex',
  },
  '& u': {
    flex: 0,
    textDecoration: 'underline',
  },
});

export const LlmPickerDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const model = useSamosaModel();

    const modelKeys = llmModelKeys;
    const setLlm = (modelKey: string) => {
      model.setLlm(modelKey);
      onDismiss();
    };

    const picker = React.useRef<ItemsPickerController>(
      new ItemsPickerController({
        rows: modelKeys.length,
        initialIndex: modelKeys.indexOf(model.llmOptions.model),
      })
    ).current;

    const onEnter = React.useCallback(() => {
      const modelKey = modelKeys[picker.index];
      if (modelKey) {
        model.setLlm(modelKey);
        onDismiss();
      }
    }, [model, modelKeys, onDismiss, picker]);

    React.useEffect(() => {
      return KeyboardService.instance.addShortcutSet('llm-picker', {
        ...picker.getKeybindings(),
        escape: [onDismiss, null],
        enter: [onEnter, 'Apply selected model'],
      });
    }, [model, modelKeys, onDismiss, onEnter, picker]);

    return (
      <ActionDialog
        onDismiss={onDismiss}
        disableContainerDismissal
        title={'LLM Model'}
      >
        <ListWrapper>
          {modelKeys.map((value, index) => (
            <LlmListItem
              key={value}
              keyboardSelected={picker.is(index)}
              onClick={() => setLlm(value)}
            >
              <span>{value}</span>
            </LlmListItem>
          ))}
        </ListWrapper>
      </ActionDialog>
    );
  }
);

export const showLlmPickerDialog =
  DialogPresenter.makePresenter(LlmPickerDialog);
