import { styled } from '@naan/stitches.config';

export const InputStyleWrapper = styled('div', {
  $$borderColor: '$colors$black-alpha-20',
  $$labelColor: '$colors$textPrimary',
  $$maxWidth: '50vw',

  maxWidth: '$$maxWidth',
  padding: '8px 0',

  '&.with-error': {
    $$borderColor: 'pink',
    $$labelColor: 'indianred',
  },

  '& label': {
    display: 'block',
    cursor: 'pointer',
    textStyle: 'masala-body',
    marginBottom: '4px',
    color: '$$labelColor',
  },

  '& > input, & > textarea, & .make-input': {
    display: 'block',
    width: '100%',

    background: 'white',
    border: '1px solid $$borderColor',
    borderRadius: '4px',
    padding: '8px',
  },

  '& > select': {
    display: 'block',
    width: '100%',
    background: 'white',
    border: '1px solid $$borderColor',
    borderRadius: '4px',
    padding: '8px 12px',
  },

  '& > .checkbox-container': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& > input': {
      position: 'relative',
      top: '-4px',
      marginRight: '6px',
    },
  },

  '& > .error': {
    padding: '8px 0',
    color: 'indianred',
    marginBottom: '-18px',
  },

  '& > .hint': {
    fontSize: '12px',
    color: '$colors$textSecondary',
    // marginTop: -4,
  },

  '& .checkbox-container + .hint': {
    marginTop: '-4px',
  },
});

export type InputStyleWrapperProps = React.ComponentProps<
  typeof InputStyleWrapper
>;

export type InputStyleWrapperCss = Pick<
  InputStyleWrapperProps,
  'css' | 'className'
>;
