import React from 'react';
import { reaction } from 'mobx';
import { textToHtml } from '@masala-lib/editorial/ui/content-editable-utils';
import { DomTextEditor } from '@masala-lib/editorial/ui/dom-text-editor';
import { getElementEditableContentString } from '@masala-lib/content-utils';
import { normalizeSpecialChars } from '@masala-lib/misc/editorial-string-utils';

export class ScriptLineEditor extends React.Component {
  constructor(props) {
    super(props);
    // TODO make conditional on kind supporting editable content
    this.element = props.element;
    this.scriptEditorModel = props.model;
    this.baseClassName = this.props.baseClassName;
    let DomEditorClass =
      this.props.configurations[this.element.kind]['domEditorClass'];
    DomEditorClass = DomEditorClass || DomTextEditor;
    this.domEditor = new DomEditorClass();
    this.getIsEditingId = props.getIsEditingId;
    this.disposers = [];
    this.disposers.push(
      reaction(
        () => this.isEditing,
        () => this.configureEditor()
      )
    );
    this.configureEditor();
  }

  get isEditing() {
    return this.getIsEditingId(this.element.id);
  }

  get modified() {
    return this.elementContent !== this.getContentFromEditor();
  }

  get elementContent() {
    return normalizeSpecialChars(
      getElementEditableContentString(
        this.element,
        this.scriptEditorModel.words
      )
    );
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.prefix !== this.props.prefix;
  }

  getContentFromEditor() {
    return this.domEditor.getCurrentContent();
  }

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  setEditableDomElement(domElement) {
    this.domEditor.setEditableElement(domElement);
    this.configureEditor();
  }

  configureEditor() {
    this.domEditor.fromContent(this.elementContent);
    this.domEditor.setEditing(this.isEditing);
    // TODO invert set unset in setActiveEntityEditor?
    this.props.setActive(this, this.isEditing);
  }

  revert() {
    this.domEditor.fromContent(this.elementContent, true);
  }

  render() {
    const { prefix } = this.props;
    const text = this.domEditor.lastSyncedContent; // TODO improve interface for this
    const html = text ? textToHtml(text) : '';
    // const subKind = this.element.subKind; // TODO rethink the hackiness
    // const className = subKind ? config.style + ' ' + subKind.toLowerCase() : config.style;

    return (
      // TODO move prefix styling to App.css??
      <div className={this.baseClassName} style={{ whiteSpace: 'nowrap' }}>
        {prefix ? (
          <span
            className={'markdown-prefix'}
            style={{ verticalAlign: 'top', userSelect: 'none' }}
          >
            {prefix}
          </span>
        ) : null}
        <div
          className={'dom-line-editor not-in-edit'}
          ref={domElement => this.setEditableDomElement(domElement)}
          contentEditable={false}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    );
  }
}
