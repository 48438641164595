import { GenericFirestoreDocSet } from '../../firestore-db/firestore-doc-set';
import { DocKeys } from './db';

export class ScriptDocSet0 extends GenericFirestoreDocSet {
  constructor(key: string, listenMode = true) {
    super(key, null, listenMode);
  }

  getDocKeys(): DocKeys[] {
    return [
      DocKeys.UNIT_METADATA_DOC,
      // DocKeys.EPISODE_METADATA_DOC,
      DocKeys.VERBATIM_DOC,
      DocKeys.STRUCTURAL_DOC,
      DocKeys.WORD_GROUP_DOC,
      DocKeys.TRANSLATIONS_DOC,
      DocKeys.METADATA_BLOCKS_DOC,
      DocKeys.WARNING_SUPPRESSIONS_DOC,
    ];
  }
}

export function ScriptDocSet(key: string, listenMode?: boolean) {
  return new ScriptDocSet0(key, listenMode);
}
