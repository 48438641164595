import { observer } from 'mobx-react';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { JobsTable } from './jobs-table';
import { Auth } from '@masala-lib/editorial/db/auth';
import { JobListModel } from '@masala-lib/jobs/job-manager';
import React from 'react';

export const JobsListScreen = observer(() => {
  // const auth = Auth.getInstance();

  const [model, setModel] = React.useState<JobListModel>();

  React.useEffect(() => {
    const listModel = new JobListModel();
    listModel.listenActive();
    setModel(listModel);

    return () => listModel.stopListening();
  }, []);

  if (!model) {
    return null;
  }

  return (
    <Page
      title="Manage Jobs"
      controls={
        <PageActions>
          <SearchBar manager={model} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <JobsTable list={model.list} />
      </OnePaneLayout>
    </Page>
  );
});
