import * as React from 'react';
import { observer } from 'mobx-react';
import { Exchange, Step } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { styled } from '@naan/stitches.config';
import { Bubble } from '../components/bubble';
import { RequestLogo } from '../components/request-logo';
import { SimpleDialog } from '../components/simple-dialog';
import { ExchangeDetailStatus, ExchangeInlineStatus } from './exchange-step';
import { scrollThreadsToBottom } from '../utils/scrolling-fx';
import { shouldTruncate } from '@masala-lib/llm/project/llm-project-funcs';
import { EditableExchangeLabel } from './editable-exchange-label';
import { DialogPresenter } from '../dialog-presenter';
import { ToolbarBaseOuterContainer } from '../components/toolbar';
import { RequestToolbar } from './request-toolbar';
import { usePauseKeyboardService } from 'lib/services/keyboard-service';

const ChatRowWrapper = styled(ToolbarBaseOuterContainer, {
  padding: '0 12px',
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  gridTemplateRows: '1fr',
  gap: 8,
  '& > .exchange-body': {
    userSelect: 'none',
  },
});

function useAutoScrollWhileLoading(exchange: Exchange) {
  React.useEffect(() => {
    let scrollTimeout: number | null = null;
    function scrollWhileLoading() {
      if (exchange.pendingResponse === true) {
        console.log('SCROLL');
        scrollThreadsToBottom();
        scrollTimeout = window.setTimeout(scrollWhileLoading, 1000);
      } else {
        scrollTimeout = null;
      }
    }
    scrollWhileLoading();
    return () => {
      if (scrollTimeout !== null) {
        window.clearTimeout(scrollTimeout);
      }
    };
  }, [exchange]);
}

export const ExchangeRequest = observer(({ step }: { step: Step }) => {
  const model = useSamosaModel();
  const exchange = step as Exchange;

  const suppressed = model.isExchangeSuppressed(exchange as Exchange);

  const onBubbleClick = () => {
    DialogPresenter.present(onDismiss => (
      <RequestDialog exchange={exchange} onDismiss={onDismiss} />
    ));
  };

  const current = model.isCurrentStep(exchange);
  const long = shouldTruncate(exchange.request.text, 20);
  let truncate0 = long || suppressed;
  // console.log('truncate0: ' + truncate0);
  const truncate = truncate0 ? (current ? 'medium' : true) : false;
  const truncateLines = current && !suppressed ? 25 : 3;

  // console.log('truncate: ' + truncate);

  useAutoScrollWhileLoading(exchange);

  return (
    <>
      <ChatRowWrapper>
        <RequestLogo />
        <div
          className="exchange-body"
          onClick={onBubbleClick}
          onMouseEnter={() => {
            // console.log('enter');
            model.setHoveredExchange(exchange);
          }}
          onMouseLeave={() => {
            // console.log('leave');
            model.setHoveredExchange(null);
          }}
        >
          <Bubble
            presentation="request"
            truncate
            suppressed={suppressed}
            truncateLines={truncateLines}
          >
            <ExchangeInlineStatus exchange={exchange} />
            {exchange.request.text}
          </Bubble>
        </div>
        {/* // todo */}
        {/* <div className="floating-toolbar">
          <RequestToolbar exchange={exchange} />
        </div> */}
      </ChatRowWrapper>
    </>
  );
});

const TextWrapper = styled('div', {
  whiteSpace: 'pre-wrap',
});

const RequestDialog = ({
  exchange,
  onDismiss,
}: {
  exchange: Exchange;
  onDismiss: () => void;
}) => {
  return (
    <SimpleDialog
      title={
        <>
          Request - <EditableExchangeLabel exchange={exchange} />
        </>
      }
      onDismiss={onDismiss}
      disableContainerDismissal
    >
      <ExchangeDetailStatus exchange={exchange} />
      <hr />
      <TextWrapper>{exchange.request.text}</TextWrapper>
    </SimpleDialog>
  );
};
