import { ExcerptStatus } from '@masala-lib/catalog/catalog-types';
import { capitalize } from 'lodash';
import { observer } from 'mobx-react';
import { FilterSelector } from './filter-selector';

const statusValues = Object.values(ExcerptStatus);
const statusOptions = statusValues.map(value => {
  return {
    label: capitalize(value.replace('_', ' ')),
    value,
  };
});

export const SoundbiteStatusFilterSelector = observer(
  ({ path }: { path: string }) => {
    return (
      <FilterSelector
        path={path}
        fieldName="soundbite_status"
        options={statusOptions}
        label={'Filter by status'}
        shortcutKey={'keyS'}
      />
    );
  }
);
