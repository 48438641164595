import { Template } from '@linear/sdk';
import * as React from 'react';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Shelf } from '../shared/shelf';
import styled from 'styled-components';
import { Card } from '../../components/data-display/card';
import { Stack } from '../shared/stack';
import { DualPaneLayout } from '../shared/layouts';
import { PageHeading } from '../shared/page-heading';
import { Box, Button, Flex } from 'rebass';

const Wrapper = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  label {
    display: flex;
    color: var(--color-gray600);
    font-size: 14px;
    line-height: 21px;
    padding: 18px 12px;
    font-weight: 400;

    &:hover {
      color: var(--color-blue600);
      cursor: pointer;
    }
    & > input {
      top: 4px;
      position: relative;
      margin-right: 6px;
    }
  }

  li {
    border-bottom: 1px solid #eee;
    &:last-child {
      border: none;
    }
  }

  .buttons {
    button {
      --button-background-color: var(--color-transparent);
      --button-color: var(--color-gray500);
      --button-border-color: var(--color-gray100);
      display: flex;
      background: none;
      background: var(--button-background-color);
      border: 1px solid var(--button-border-color);
      color: var(--button-color);
      padding: 4px 12px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      border-radius: 4px;
      outline: none;

      &:hover {
        --button-background-color: var(--color-gray50);
        --button-color: var(--color-gray600);
      }
    }
  }
`;

interface Props {
  unit: Unit;
  templates: Template[];
  onCreateWorkflow: (
    masterTemplate: Template,
    selectedTemplates: Template[]
  ) => void;
  onCancel: () => void;
}

type SelectMap = { [key: string]: boolean };

export const UnitWorkflowCreator = ({
  unit,
  templates,
  onCreateWorkflow,
  onCancel,
}: Props) => {
  const [masterTemplate, ...subTemplates] = templates;
  const [selected, setSelected] = React.useState<SelectMap>({});
  const toggleItem = (id: string) => () => {
    const isSelected = !!selected[id]; // works because undefined is falsey
    setSelected({ ...selected, [id]: !isSelected });
  };

  // const selectAll = () => {
  //   const selected: SelectMap = {};
  //   subTemplates.forEach(({ id }) => {
  //     selected[id] = true;
  //   });
  //   setSelected(selected);
  // };

  const selectNewSet = () => {
    selectSet('New:');
  };

  const selectMigSet = () => {
    selectSet('Mig:');
  };

  const selectJpSet = () => {
    selectSet('JP:');
  };

  const selectSet = (prefix: string) => {
    const selected: SelectMap = {};
    subTemplates.forEach(subTemplate => {
      const { id } = subTemplate;
      if (subTemplate.name.startsWith(prefix)) {
        selected[id] = true;
      }
    });
    setSelected(selected);
  };

  const unselectAll = () => {
    setSelected({});
  };

  function createIssues() {
    const selectedTemplates = subTemplates.filter(tpl => !!selected[tpl.id]);
    onCreateWorkflow(masterTemplate, selectedTemplates);
    // console.log(selectedTemplates);
  }

  return (
    <Wrapper>
      <DualPaneLayout
        rightPanel={
          <Stack>
            <Box p={2} mt={4}>
              <Flex justifyContent={'center'}>
                <Button onClick={createIssues} backgroundColor={'teal'} px={32}>
                  Generate Workflow
                </Button>
              </Flex>
            </Box>
            <Box p={2} mt={4}>
              <Flex justifyContent={'center'}>
                <Button
                  onClick={onCancel}
                  backgroundColor={'transparent'}
                  color={'var(--color-gray400)'}
                  px={32}
                >
                  Cancel
                </Button>
              </Flex>
            </Box>
          </Stack>
        }
      >
        <PageHeading>Create a linear workflow for this unit</PageHeading>
        <Card
          header={
            <div className="buttons">
              <>
                <Shelf>
                  <span>Templates to use</span>
                  {/* <button onClick={selectAll}>Select all</button> */}
                  <button onClick={selectNewSet}>Select 'New'</button>
                  <button onClick={selectJpSet}>Select 'JP'</button>
                  <button onClick={selectMigSet}>Select 'Mig'</button>
                  <button onClick={unselectAll}>Unselect all</button>
                </Shelf>
              </>
            </div>
          }
        >
          <ul>
            {subTemplates.map(template => (
              <li key={template.id}>
                <label>
                  <input
                    type="checkbox"
                    checked={!!selected[template.id]}
                    onChange={toggleItem(template.id)}
                  />{' '}
                  {template.templateData?.title}
                </label>
              </li>
            ))}
          </ul>
        </Card>
      </DualPaneLayout>
    </Wrapper>
  );
};
