import * as React from 'react';

export function EditLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6571 3.75315C21.7704 3.63987 21.869 3.53606 21.9637 3.43639C22.0962 3.29692 22.221 3.16557 22.3674 3.02779C22.6007 2.80823 22.8116 2.65198 23.0199 2.56864C23.2128 2.49151 23.417 2.47087 23.668 2.55118C23.9336 2.63619 24.2796 2.84394 24.7178 3.28217C25.1561 3.7204 25.3638 4.06637 25.4488 4.33205C25.5292 4.58301 25.5085 4.78727 25.4314 4.98009C25.348 5.18846 25.1918 5.39932 24.9722 5.63261C24.8345 5.77899 24.7031 5.90377 24.5637 6.03627L24.5636 6.03632L24.5636 6.03634C24.4639 6.13103 24.3601 6.22966 24.2469 6.34293L14.4454 16.1444L10.7457 17.2543L11.8556 13.5546L21.6571 3.75315ZM25.425 2.57506C24.9212 2.07133 24.443 1.74923 23.9727 1.59875C23.4878 1.44357 23.0445 1.4818 22.6485 1.64016C22.2682 1.79231 21.9492 2.04817 21.6821 2.29959C21.5258 2.4467 21.3561 2.62436 21.2022 2.78552L21.202 2.78572C21.1103 2.88165 21.0243 2.97174 20.95 3.04605L11.0594 12.9366C11.0009 12.9952 10.9578 13.0673 10.934 13.1465L9.5211 17.8563C9.46824 18.0325 9.51639 18.2235 9.64646 18.3536C9.77652 18.4836 9.9675 18.5318 10.1437 18.4789L14.8535 17.066C14.9328 17.0422 15.0049 16.9991 15.0634 16.9406L24.954 7.05003C25.0283 6.97567 25.1185 6.88957 25.2145 6.79787L25.2146 6.79777C25.3757 6.64388 25.5534 6.47424 25.7004 6.31797C25.9519 6.05083 26.2077 5.73184 26.3599 5.35148C26.5182 4.95557 26.5565 4.51223 26.4013 4.02728C26.2508 3.55704 25.9287 3.0788 25.425 2.57506ZM6 4C4.89543 4 4 4.89543 4 6V22C4 23.1046 4.89543 24 6 24H22C23.1046 24 24 23.1046 24 22V14H22V22H6V6H14V4H6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EditSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.88309 10.0564L7 13L9.94363 12.1169L16.1253 5.93529C16.714 5.34657 17.5971 4.75783 16.4196 3.58038C15.2422 2.40293 14.6534 3.28602 14.0647 3.87474L7.88309 10.0564Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        d="M16 16V10.5L17 10.5V16C17 16.5523 16.5523 17 16 17H4C3.44772 17 3 16.5523 3 16V5C3 4.44772 3.44771 4 4 4L10 4V5L4 5V16H16Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EditIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.148 11.1733L9 15L12.8267 13.852L20.8628 5.81588C21.6282 5.05054 22.7762 4.28518 21.2455 2.7545C19.7148 1.22381 18.9495 2.37182 18.1841 3.13717L10.148 11.1733Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        d="M18 18V12H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H12V6L6 6V18H18Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
