import * as React from 'react';
import { observer } from 'mobx-react';
import { TabName, SidebarTabs } from './sidebar-tabs';
import { StepListChronological } from './step-list-chronological';
import { StepListGrouped } from './step-list-grouped';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';

export const StepList = observer(() => {
  const model = useSamosaModel();
  // const showTabs = model.sectionsInfo.hasSectionedResponses;
  const showTabs = model.sectionsInfo.hasSections;
  const [currentTab, setCurrentTab] = React.useState<TabName>(
    showTabs ? 'sections' : 'chron'
  );

  return (
    <>
      {showTabs ? (
        <SidebarTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      ) : null}
      {currentTab === 'chron' ? <StepListChronological /> : null}
      {currentTab === 'sections' ? <StepListGrouped /> : null}
    </>
  );
});
