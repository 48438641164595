import React, { useEffect } from 'react';
import { Volume } from '@masala-lib/catalog/models/volume';
import { useParams } from 'react-router-dom';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';

export function useVolume() {
  const { id } = useParams<{ id: string }>();
  const manager = VolumeManager.getInstance();
  const [currentVolume, setCurrentVolume] = React.useState<Volume>(null);
  useEffect(() => {
    const volume = manager.fetchById(id);
    // useful for debugging. currentUnit would be available in the console
    (window as any).currentVolume = volume;
    setCurrentVolume(volume);
  }, [manager, id]);

  return currentVolume;
}
