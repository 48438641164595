// *********** CHAAT LOW-LEVEL TYPES
import { ElementId, WordId } from '@tikka/basic-types';

export type ChaatInputCue = {
  wordId: WordId;
  timestamp: number;
};
export type Cue = {
  wordId: WordId; //ElementId;
  timestamp: number;
  input: boolean;
  navStop: boolean;
};
export type AudioRegionWriteDto = {
  id: string; //ElementId;
  startTime: number;
  endTime: number;
};

export type AudioRegionKind = 'NON_VOICE' | 'FORCE_LINEAR'; // i.e. 60.1

export interface AudioRegion extends AudioRegionWriteDto {
  kind: AudioRegionKind; // i.e. 60.1
}

export interface AudioMarker {
  id: ElementId;
  time: number; // element start time in millis, relative to start of master audio file
}

export type AudioMarkerWriteDto = {
  id: ElementId;
  time: number;
};
export type WarningKeys = keyof typeof warningUiDescriptionLookup;
export const warningUiDescriptionLookup = {
  short: 'seems like a short audio period for: ',
  long: 'seems like a long audio period for: ',
  silences: 'audio silences inside interpolation for: ',
  // |}
};

export interface KerningConfigData {
  minGapSize: number;
  minSegmentLength: number;
  forcedGapSize: number;
}
