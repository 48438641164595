import { IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
// import { presentEditLabelModal } from './editable-exchange-label';

export const ToolbarButton = (
  props: React.ComponentProps<typeof IconButton>
) => {
  return (
    <IconButton
      {...props}
      size={'small'}
      css={{
        $$iconColor: '$$toolbarIconColor',
        // mixBlendMode: 'multiply',
      }}
      testId="toolbar-button"
    />
  );
};

export const ToolbarContainer = styled('div', {
  $$backgroundColor: '$colors$gray-100',
  $$toolbarIconColor: '$colors$gray-400',
  display: 'flex',
  background: '$$backgroundColor',
  overflow: 'hidden',
  borderRadius: '24px',
  boxShadow: '0 0 3px 1px $colors$black-opacity-10',
  // $$iconColor: '$colors$red-600',
  variants: {
    presentation: {
      blue: {
        $$backgroundColor: '$colors$blue-100',
        $$toolbarIconColor: '$colors$blue-400',
      },
      green: {
        $$backgroundColor: '$colors$green-100',
        $$toolbarIconColor: '$colors$green-600',
      },
      yellow: {
        $$backgroundColor: '$colors$yellow-100',
        $$toolbarIconColor: '$colors$yellow-800',
      },
    },
  },
});

export const ToolbarBaseOuterContainer = styled('div', {
  $$toolbarOpacity: 0,
  position: 'relative',
  '&  > .floating-toolbar': {
    position: 'absolute',
    right: 12,
    bottom: -12,
    opacity: '$$toolbarOpacity',
    transition: 'opacity 0.12s ease-in-out, transform 0.08s ease-in-out',
  },
  '&:hover': {
    $$toolbarOpacity: 1,
  },
});
