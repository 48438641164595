import { map } from 'lodash';
import * as React from 'react';
import { Unit } from '@masala-lib/catalog/models/unit';
import { phraseize } from '../../lib/phraseize';
import styled from 'styled-components';

const Wrapper = styled.div`
  header {
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    h1 {
      font-weight: 700;
    }
    h2 {
      font-weight: 500;
      font-style: italic;
    }
  }
`;

export const Header = ({ data, unit }: { data: any; unit: Unit }) => {
  const { cast, topics, countries, credits } = data;

  return (
    <Wrapper>
      <div className="meta">
        <dl>
          <dt>Version</dt>
          <dd>{data.version}</dd>
        </dl>
      </div>
      <div className="info">
        <header>
          <h1>{unit.name}</h1>
          <h2>{data.titleL1}</h2>
        </header>
        <dl>
          <dt>Topics</dt>
          <dd>{topics.join(',')}</dd>
          <dt>Countries</dt>
          <dd>{countries.join(',')}</dd>
          {map(credits, (value, key) => {
            return (
              <React.Fragment key={key}>
                <dt> {phraseize(key)}</dt>
                <dd>{value}</dd>
              </React.Fragment>
            );
          })}
          <dt>Description</dt>
          <dd>{data.description}</dd>
          <dt>Tagline</dt>
          <dd>{data.tagline}</dd>
          <dt>Voices</dt>
          <dd>
            <dl>
              {cast.map((voice: any, id: number) => {
                return (
                  <React.Fragment key={id}>
                    <dt>{voice.fullName}</dt>
                    <dd>
                      {voice.description}{' '}
                      {voice.accent ? <em>({voice.accent})</em> : null}
                    </dd>
                  </React.Fragment>
                );
              })}
            </dl>
          </dd>
        </dl>
      </div>
    </Wrapper>
  );
};
