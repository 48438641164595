import { values } from 'lodash';
import useSWR from 'swr';
import { useUnit } from '../units/use-unit';
import { VocabList } from './vocab-list';
import { Header } from './unit-header';
import { Chapter } from './chapter';

import styled from 'styled-components';

const Wrapper = styled.main`
  display: grid;
  grid-template-columns:
    12vw
    78vw
    10vw;

  & > * {
    grid-column: 2;
  }

  & > section + section {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid #000;
    padding-bottom: 4rem;
  }

  /*
  CSS grid dl
  copied from here:
  https://codepen.io/graup/pen/xNXMJM
  */

  & dl {
    margin: 0;
    display: grid;
    grid-template: auto / 12em 1fr;
    dt {
      padding: 0.5rem 0;
      grid-column: 1;
      vertical-align: text-top;
    }

    dd {
      padding: 0.5rem 0;
      grid-column: 2;
      padding: 1rem;
    }

    dt,
    dd {
      padding: 1em;
      line-height: 1.5;
    }

    dl {
      /* margin-top: -1rem; */
      background: #f9f9f9;
      padding: 0 1rem;
    }
  }
`;

const fetcher = (url: string): any => fetch(url).then(res => res.json());

const DebugJson = ({ obj }: { obj: any }) => {
  return (
    <div>
      <pre>{JSON.stringify(obj, null, 2)}</pre>
    </div>
  );
};

export const PrintUnitScreen = () => {
  const unit = useUnit();
  const url = unit?.data?.dataUrl;
  const { data, error } = useSWR(url, fetcher);

  if (!unit) {
    return null;
  }

  console.log('ERR', error);

  if (error) return <h1>'An error has occurred.';</h1>;
  if (!data) return <h1>Loading...</h1>;

  const { chapters, vocabs, cast, topics, countries, credits, ...rest } = data;

  const vocabsList = values(vocabs);

  return (
    <Wrapper>
      <section>
        <Header unit={unit} data={data} />
      </section>
      {chapters.map((chapter: any) => {
        const chapterVocabs = vocabsList.filter(
          (v: any) => v.chapterPosition === chapter.position
        );
        return (
          <section>
            <Chapter chapter={chapter} />
            <VocabList vocabs={chapterVocabs}></VocabList>
          </section>
        );
      })}
    </Wrapper>
  );
};
