export const chatScrollableId = 'SCROLLABLE-AREA';

export function getThreadsScrollableArea(): HTMLDivElement {
  return document.getElementById('SCROLLABLE-AREA') as HTMLDivElement;
}

export function scrollThreadsToBottom() {
  const scrollableArea = getThreadsScrollableArea();
  if (scrollableArea) {
    const top = scrollableArea.scrollHeight - scrollableArea.clientHeight;
    scrollableArea.scrollTo({ top: top, behavior: 'smooth' });
  }
}
