import {
  ElementId,
  ELTOf,
  ExtractETForIDT,
  ExtractIndexed,
  ExtractTimed,
  IDTOfET as _IDTOfET,
  IDTOfKT,
  IElement,
  IndexedElement,
  KTOfIDT,
  TimedElement,
} from '../basic-types';
import { Intervals } from '../intervals/intervals';
import { Signal } from './signal';
import { Tracker } from './tracker';
import { TrackingImpl } from './tracking-impl';

export interface Tracking<
  ET extends TimedElement | IndexedElement,
  IDTOfET extends ElementId = _IDTOfET<ET>
> {
  setElements({
    elements,
    triggerFunction,
  }: {
    elements: ELTOf<ET>;
    triggerFunction: () => any;
  }): void;

  getTrackerWithKind<KT extends KTOfIDT<IDTOfET>>(
    kind: KT
  ): Tracker<IDTOfKT<KT>, ET>;

  getTracker<IDT extends IDTOfET>(
    elementId: IDT
  ): Tracker<IDT, ExtractETForIDT<ET, IDT>>;

  currentIsUnder<KT extends KTOfIDT<IDTOfET>>(kind: KT): IDTOfKT<KT>;

  isUnder<IDT extends IDTOfET>(elementId: IDT): boolean;

  isBefore<IDT extends IDTOfET>(elementId: IDT): boolean;

  isVisited<IDT extends IDTOfET>(elementId: IDT): boolean;

  isUnderSignal<IDT extends IDTOfET>(elementId: IDT): Signal;

  isBeforeSignal<IDT extends IDTOfET>(elementId: IDT): Signal;

  isVisitedSignal<IDT extends IDTOfET>(elementId: IDT): Signal;

  watchIsUnder<IDT extends IDTOfET>(elementId: IDT): boolean;

  watchIsBefore<IDT extends IDTOfET>(elementId: IDT): boolean;

  watchIsVisited<IDT extends IDTOfET>(elementId: IDT): boolean;

  changedSignal<IDT extends IDTOfET>(elementId: IDT): Signal;

  anyIsChangedSignal<KT extends KTOfIDT<IDTOfET>>(kind: KT): Signal;
}

export function CreateTracking<
  ET extends IElement,
  IET extends IndexedElement | TimedElement =
    | ExtractIndexed<ET>
    | ExtractTimed<ET>
>({
  positionFunction,
  getIntervals,
}: {
  positionFunction: () => number;
  getIntervals: (arg: any) => Intervals;
}): Tracking<IET> {
  return new TrackingImpl<IET>(positionFunction, getIntervals) as any;
}
