import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ChannelForm } from './channel-form';
import { ChannelData } from '@masala-lib/catalog/catalog-types';
import { useChannel } from './use-channel';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';

interface ChannelEditParams {
  stopEditing: () => void;
}

export const ChannelEditScreen = observer(
  ({ stopEditing }: ChannelEditParams) => {
    const manager = ChannelManager.getInstance();
    const channel = useChannel();

    if (!channel) {
      return null;
    }

    const onSubmit = (dto: ChannelData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      channel.fromFormData(dto);
      manager.update(channel).then(stopEditing);
    };

    return (
      <Page title={`Editing ${channel.name}`}>
        <ChannelForm
          dto={channel.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
        />
      </Page>
    );
  }
);
