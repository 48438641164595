import {
  ElementId,
  ElementIdToString,
  StringToElement,
  WordElement,
  WordId,
} from '@tikka/basic-types';
import { Sentence } from '../../editor-aliases';
import { Word, CreateElementList, ElementList } from '../../editor-aliases';
import { EKind } from '../../element-kinds';
import { setDifference } from '../../utils';
import { wordElementsFromWordRecords } from '../episode-data/episode-data';
import { createWordRecordsData } from '../ids/word-ids';
import * as VersionsDb from './versions-db';
import {
  ElementVersionData,
  SentenceVersionData,
  StorageSentence,
  StorageStructural,
  StorageTranslation,
  StorageWordGroup,
  StructuralVersionData,
  TranslationVersionData,
  VersionableElement,
  WordGroupVersionData,
} from '../../editorial-types';
import { WordRecord } from '../../editorial-types-aside';
import { CreateEditorEditorialWordElementList } from '../../editorial-word-element-list';

export function versionKey(element: VersionableElement) {
  return element.id + ':' + element.timestamp;
}

export function versionKeyToElementId(vkey: string): ElementId {
  const parts = vkey.split(':');
  parts.pop();
  return parts.join(':') as ElementId;
}

export function computeVersionsUpdateFromCurrentsArray<
  T extends ElementVersionData
>(
  elements: VersionableElement[],
  savedVersions: StringToElement<T>,
  activeVersionIdMap: ElementIdToString
) {
  // active is both present and not inactivated
  const newVersions: StringToElement<T> = {};
  const updatedActiveVersionIdMap = { ...activeVersionIdMap };
  const activeVersionIdMapDelta: ElementIdToString = {};
  for (const element of elements) {
    const key = versionKey(element);
    if (!savedVersions[key]) {
      let baseVersionId = activeVersionIdMap[element.id] ?? null;
      if (baseVersionId === key) {
        baseVersionId = null;
        console.log('base version was same as version for:' + key);
      }
      newVersions[key] = { ...element, baseVersionId } as T;
    }
    if (!element.deleted) {
      if (updatedActiveVersionIdMap[element.id] !== key) {
        updatedActiveVersionIdMap[element.id] = key;
        activeVersionIdMapDelta[element.id] = key;
      }
    } else {
      if (
        updatedActiveVersionIdMap[element.id] &&
        updatedActiveVersionIdMap[element.id] === key
      ) {
        updatedActiveVersionIdMap[element.id] = null;
        activeVersionIdMapDelta[element.id] = null;
      }
    }
  }
  const present = new Set(elements.map(el => versionKey(el)));

  for (const active of Object.values(updatedActiveVersionIdMap)) {
    if (active && !present.has(active)) {
      activeVersionIdMapDelta[versionKeyToElementId(active)] = null;
    }
  }
  return { newVersions, activeVersionIdMapDelta };
}

export function computeVersionsUpdates<T extends ElementVersionData>(
  current: any,
  savedVersions: StringToElement<T>,
  activeVersionIdMap: ElementIdToString
) {
  const elements: VersionableElement[] = Object.values(current);
  return computeVersionsUpdateFromCurrentsArray(
    elements,
    savedVersions,
    activeVersionIdMap
  );
}

// @jason: should probably make this generic
export function computeStructuralVersionUpdates(
  structural: any,
  savedStructuralVersions: StringToElement<StructuralVersionData>,
  activeVersionIdMap: ElementIdToString
) {
  return computeVersionsUpdates(
    structural,
    savedStructuralVersions,
    activeVersionIdMap
  );
}

export function computeWordGroupVersionUpdates(
  wordGroups: any,
  savedWordGroupVersions: StringToElement<WordGroupVersionData>,
  activeVersionIdMap: ElementIdToString
) {
  return computeVersionsUpdates(
    wordGroups,
    savedWordGroupVersions,
    activeVersionIdMap
  );
}

export function computeSentenceVersionUpdates(
  sentences0: any,
  words: ElementList<Word>,
  savedSentenceVersions: StringToElement<SentenceVersionData>,
  activeVersionIdMap: ElementIdToString
) {
  const updates = computeVersionsUpdates(
    sentences0,
    savedSentenceVersions,
    activeVersionIdMap
  );
  const newVersions = updates.newVersions;
  const sentences = [...Object.values(newVersions)];

  // TODO move these
  const stringComparer = (a: string, b: string) => {
    if (a < b) {
      return -1;
    } else if (b > a) {
      return 1;
    }
    return 0;
  };

  const sentenceSortComparer = (
    a: StorageSentence,
    b: StorageSentence
  ): number => {
    return stringComparer(a.anchor.wordId, b.anchor.wordId);
  };
  const allSentences = Object.values(sentences0) as StorageSentence[];

  allSentences.sort(sentenceSortComparer);
  const sentenceTransitions = allSentences.map(s => s.anchor.wordId);

  sentences.sort(sentenceSortComparer);

  for (const sentence of sentences) {
    // TODO use the word records or keep as only active word elements?
    const startWordId = sentence.anchor.wordId;
    let endWordId: WordId;
    let index = sentenceTransitions.findIndex(wordId => wordId > startWordId);
    if (index !== -1) {
      const endWordIdExclusive = sentenceTransitions[index];
      endWordId = words.prevId(endWordIdExclusive);
    } else {
      endWordId = words.values[words.values.length - 1].id;
    }
    const wordRange = { begin: startWordId, end: endWordId };
    const wordElements = words.idRangeAsElements(wordRange);
    sentence.words = wordElements; // TODO Hacking
  }

  return {
    newVersions,
    activeVersionIdMapDelta: updates.activeVersionIdMapDelta,
  };
}

export function computeFilteredVersionsDoc<T extends ElementVersionData>(
  doc: {
    items: StringToElement<T>;
    activeVersionIdMap: ElementIdToString;
  },
  filter: (version: T) => boolean
): {
  items: StringToElement<T>;
  activeVersionIdMap: ElementIdToString;
} {
  const items: StringToElement<T> = {};
  const activeVersionIdMap: ElementIdToString = {};
  for (const [key, version] of Object.entries(doc.items)) {
    if (filter(version)) {
      items[key] = version;
    }
  }
  for (const [id, key] of Object.entries(doc.activeVersionIdMap)) {
    if (items[key]) {
      activeVersionIdMap[id as any] = key;
    }
  }
  return { items, activeVersionIdMap };
}

// TODO get the Promise return types right for these funcs
export async function updateStructuralVersions(episodeKey: string) {
  const structuralDoc = await VersionsDb.loadStructuralDoc(episodeKey);
  const structuralVersionsDoc = await VersionsDb.loadStructuralVersionsDoc(
    episodeKey
  );
  const updates = computeStructuralVersionUpdates(
    structuralDoc.items,
    structuralVersionsDoc.items,
    structuralVersionsDoc.activeVersionIdMap
  );
  return VersionsDb.updateStructuralVersions(
    episodeKey,
    updates.newVersions,
    updates.activeVersionIdMapDelta
  );
}

export async function updateWordGroupVersions(episodeKey: string) {
  const wordGroupsDoc = await VersionsDb.loadWordGroupsDoc(episodeKey);
  const wordGroupVersionsDoc = await VersionsDb.loadWordGroupVersionsDoc(
    episodeKey
  );
  const updates = computeWordGroupVersionUpdates(
    wordGroupsDoc.items,
    wordGroupVersionsDoc.items,
    wordGroupVersionsDoc.activeVersionIdMap
  );
  return VersionsDb.updateWordGroupVersions(
    episodeKey,
    updates.newVersions as StringToElement<WordGroupVersionData>,
    updates.activeVersionIdMapDelta
  );
}

export async function updateTranslationVersions(episodeKey: string) {
  const translationsDoc = await VersionsDb.loadTranslationsDoc(episodeKey);
  // TODO fix translations versions
  const translationVersionsDoc = await VersionsDb.loadTranslationsVersionsDoc(
    episodeKey
  );
  const translations = [];
  for (const language of Object.values(translationsDoc.items)) {
    for (const translation of Object.values(language.translations)) {
      translations.push(translation);
    }
  }
  const updates = computeVersionsUpdateFromCurrentsArray(
    translations,
    translationVersionsDoc.items,
    translationVersionsDoc.activeVersionIdMap
  );
  return VersionsDb.updateTranslationVersions(
    episodeKey,
    updates.newVersions,
    updates.activeVersionIdMapDelta
  );
}

export async function updateSentenceVersions(episodeKey: string) {
  const verbatimDoc = await VersionsDb.loadVerbatimDoc(episodeKey);
  const wordRecords: WordRecord[] = verbatimDoc.wordRecords;
  const wordRecordsData = createWordRecordsData(wordRecords);
  const wordElements = wordElementsFromWordRecords(
    wordRecordsData.activeWordRecords
  );
  const sentenceVersionsDoc = await VersionsDb.loadSentenceVersionsDoc(
    episodeKey
  );
  // const words = CreateElementList({
  //   elements: wordElements,
  //   episodeKey: episodeKey,
  //   wordRecordsDataParam: wordRecordsData,
  //   idToIndexF: wordRecordsData.getIndexActive,
  // });
  const words = CreateEditorEditorialWordElementList({
    elements: wordElements as WordElement[],
    episodeKey: episodeKey,
    wordRecordsData: wordRecordsData,
  });

  const updates = computeSentenceVersionUpdates(
    verbatimDoc.sentences,
    words,
    sentenceVersionsDoc.items,
    sentenceVersionsDoc.activeVersionIdMap
  );
  return VersionsDb.updateSentenceVersions(
    episodeKey,
    updates.newVersions,
    updates.activeVersionIdMapDelta
  );
}
