import React from 'react';
import './bug-reporting-init';
import ReactDOM from 'react-dom';
import { App } from './app';
import './console/views/shared/colors.css';
// import { BugsnagErrorBoundary } from './bugsnag';
import { BugReportingBoundary } from 'bug-reporting-boundary';

// note, bootstrap was messing with review tool margins
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";
// import './index.css';

// TODO turn off mobx strict mode

ReactDOM.render(
  <BugReportingBoundary>
    <App />
  </BugReportingBoundary>,
  document.getElementById('root')
);
