import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { SoundbiteEditionData } from '@masala-lib/catalog/catalog-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnePaneLayout } from '../shared/layouts';
import { SoundbiteEditionForm } from './soundbite-edition-form';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';
import { SoundbiteEdition } from '@masala-lib/catalog/models/soundbite-edition';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';

export const SoundbiteEditionCreateScreen = observer(() => {
  const location = useLocation();
  const manager = SoundbiteEditionManager.getInstance();
  const navigate = useNavigate();
  const { volume: volumeId, channel: channelId } =
    getQueryParamsFromLocation(location);
  const init = {} as Partial<SoundbiteEditionData>;
  if (volumeId) {
    init.volumeId = volumeId;
  }
  if (channelId) {
    init.channelId = channelId;
  }
  const model = new SoundbiteEdition(init);

  const goBack = () => {
    navigate(`/soundbite-editions`);
  };

  const onSubmit = (dto: SoundbiteEditionData) => {
    console.group('Creating excerpt');
    console.log('From data', dto);
    model.fromFormData(dto);
    manager.create(model).then(goBack);
    console.groupEnd();
  };

  return (
    <Page title={'Creating new excerpt'}>
      <OnePaneLayout>
        <SoundbiteEditionForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
        />
      </OnePaneLayout>
    </Page>
  );
});
