import { observer } from 'mobx-react';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { alertError } from 'ui/misc-utils';
import { filterOnKinds, kindMarkdownPrefix } from '@masala-lib/llm/llm-funcs';
import { ElementExchangeCodeAnnotation } from './exchange-code-annotation';
import { getSuppressedFlag } from '@masala-lib/llm/project/llm-project-funcs';

// completey forking this from the merge-script-view code until the dust settles on the touchup editor

export const StructuralMergeView = observer(() => {
  const model = useSamosaModel();

  const handleResetElementSuppressions = () => {
    // model.resetAllElementSuppressions().catch(alertError);
  };

  const referenceElements = model.referenceScript.elements;
  const mergeElements = model.baseMergeScript.filter(el => !el.anchor);
  const outputElements = filterOnKinds(mergeElements, ['CHAPTER', 'PASSAGE']);

  // const suppressions = model.suppressedElements;
  const elementFlags = model.elementFlags;

  return (
    <div>
      {model.archived ? <h3>(ARCHIVED)</h3> : null}
      {referenceElements.map(refEl => {
        const matchedElements = outputElements.filter(
          outEl => refEl.reference === outEl.reference
        );
        const activeMatched = matchedElements.filter(
          el => !getSuppressedFlag(elementFlags[el.id])
          // el => !suppressions[el.id]
        );
        let alert = '';
        if (activeMatched.length > 1) {
          alert = '(CONFLICT)';
        }
        return (
          <p>
            {matchedElements.map(outEl => {
              const suppressed = getSuppressedFlag(elementFlags[outEl.id]);
              // const suppressed = !!suppressions[outEl.id];
              return (
                <>
                  &nbsp; &nbsp;
                  <ActionLink
                    onPress={() => null}
                    // onPress={() => model.toggleSuppressElement(null, outEl)}
                  >
                    {suppressed ? '[+]' : '[-]'}{' '}
                  </ActionLink>
                  {/* // todo: apply strike through styling */}
                  {suppressed ? '(SUPPRESSED) ' : null}
                  {/* // hardwire to chapters for now */}
                  {kindMarkdownPrefix[outEl.kind]}
                  {outEl.text} <ElementExchangeCodeAnnotation element={outEl} />
                  <br />
                </>
              );
            })}
            {kindMarkdownPrefix[refEl.kind]}
            {refEl.kind !== 'SPEAKER_LABEL' ? (
              <>[{refEl.reference}]</>
            ) : null}{' '}
            {refEl.text} {alert}
          </p>
        );
      })}
      <hr />
      <ActionLink onPress={handleResetElementSuppressions}>
        [reset all element suppressions]
      </ActionLink>{' '}
      {/* ({model.suppressedElementCount}) */}
    </div>
  );
});
