import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ClaudeLogo, GptLogo, SamosaLogo, SimulationLogo } from './icons';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$blue-50',
  $$color: '$colors$blue-200',
  $$size: '40px',
  $$iconSize: '20px',
  $$borderRadius: '12px',
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$$size',
  height: '$$size',
  borderRadius: '$$borderRadius',
  backgroundColor: '$$backgroundColor',
  color: '$$color',
  '& svg': {
    maxWidth: '$$iconSize',
  },
  variants: {
    size: {
      large: {
        $$size: '60px',
        $$iconSize: '32px',
      },
      small: {
        $$size: '24px',
        $$iconSize: '16px',
        $$borderRadius: '6px',
      },
      tiny: {
        $$size: '18px',
        $$iconSize: '14px',
        $$borderRadius: '4px',
      },
    },
  },
});

const models = [
  'openai/gpt-4-1106-preview',
  'openai/gpt-4',
  'openai/gpt-4-turbo',
  'openai/gpt-3.5-turbo',
  'openai/gpt-4-32k',
  'openai/gpt-4o',
  'anthropic/claude-2',
  'anthropic/claude-instant-v1',
  'anthropic/claude-3-opus',
  'anthropic/claude-3-sonnet',
  'anthropic/claude-3.5-sonnet',
  'echo',
] as const;

type ModelsKeys = typeof models[number];

type WrapperProps = React.ComponentProps<typeof Wrapper>;

export const RequestLogo = ({
  modelName,
  ...props
}: { modelName?: string } & WrapperProps) => {
  switch (modelName as ModelsKeys & string) {
    case 'openai/gpt-3.5-turbo': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$green-300',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <GptLogo />
        </Wrapper>
      );
    }

    case 'openai/gpt-4-1106-preview':
    case 'openai/gpt-4-turbo':
    case 'openai/gpt-4o':
    case 'openai/gpt-4': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$purple-300',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <GptLogo />
        </Wrapper>
      );
    }

    case 'openai/gpt-4-32k': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$pink-300',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <GptLogo />
        </Wrapper>
      );
    }

    case 'anthropic/claude-3-opus':
    case 'anthropic/claude-3-sonnet':
    case 'anthropic/claude-3.5-sonnet':
    case 'anthropic/claude-2': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$yellow-800',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <ClaudeLogo />
        </Wrapper>
      );
    }

    case 'anthropic/claude-instant-v1': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$yellow-600',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <ClaudeLogo />
        </Wrapper>
      );
    }

    case 'echo': {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$blue-300',
            $$color: '$colors$white',
          }}
          {...props}
        >
          <SimulationLogo />
        </Wrapper>
      );
    }

    default: {
      return (
        <Wrapper
          css={{
            $$backgroundColor: '$colors$green-100',
            $$color: '$colors$green-600',
          }}
          {...props}
        >
          <SamosaLogo />
        </Wrapper>
      );
    }
  }
};
