import * as React from 'react';
import { observer } from 'mobx-react';
import { Exchange, Step } from '@masala-lib/llm/project/llm-project-types';
import { getSamosaModel } from 'samosa/models/samosa-model-handle';
import { exchangeLabel } from '@masala-lib/llm/project/llm-project-funcs';
import { IconButton } from '@naan/primitives/button';
import { HiPencil } from 'react-icons/hi';

export const EditableExchangeLabel = observer(
  ({ exchange }: { exchange: Exchange }) => {
    const model = getSamosaModel();
    return (
      <>
        {exchangeLabel(exchange)}
        <IconButton
          size="small"
          icon={<HiPencil />}
          onClick={() => model.handleEditExchangeLabel(exchange)}
          testId="edit-exchange-label-button"
        />
      </>
    );
  }
);
