import { StrategyProps } from '@masala-lib/editorial/ui/completion/core';

const commands = ['resolve', 'accept', 'slack', 'unslack'];

export const commandStartsWith = (term: string, limit = 10): string[] => {
  const results: string[] = [];
  // Whether previous key started with the term
  let prevMatch = false;
  for (const command of commands) {
    if (command.startsWith(term)) {
      results.push(command);
      if (results.length === limit) break;
      prevMatch = true;
    } else if (prevMatch) {
      break;
    }
  }
  return results;
};

export const THREAD_COMMAND_STRATEGY: StrategyProps = {
  id: 'emoji',
  match: /\B\/([-+\w]*)$/,
  search: (term, callback) => {
    callback(commandStartsWith(term));
  },
  replace: command =>
    `<span contenteditable=false class="command">/${command}</span>&nbsp;`,
  template: command => `<small>/${command}</small>`,
};
