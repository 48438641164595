// import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UnitData } from '../catalog-types';
import { isEmpty } from 'lodash';
import { Unit } from '../models/unit';
import {
  CatalogCollections,
  collectionReference,
  docReference,
} from './catalog-db-paths';
import { VolumeCrud } from './volume-crud';
import { createLogger } from '@app/logger';
import {
  CollectionReference,
  DocumentReference,
} from '@platform/firebase-types';
import { epochSecondsFloat } from '@masala-lib/utils';
import { Volume } from '../models/volume';
import { EntityCrud } from './entity-crud';

const log = createLogger('unit-manager');

const collectionName = CatalogCollections.UNIT_METADATA;

function docRef(id: string): DocumentReference<UnitData> {
  return docReference<UnitData>(collectionName, id);
}

function collectionRef(): CollectionReference<UnitData> {
  return collectionReference<UnitData>(collectionName);
}

async function loadById(
  id: string,
  { fetchParents = false }: { fetchParents?: boolean }
): Promise<Unit> {
  console.log(`${collectionName}.loadById(${id})`);
  if (isEmpty(id)) {
    return null;
  }
  const docSnapshot = await docRef(id).get();
  if (docSnapshot.exists === false) {
    log.warn(`loadById - missing doc: ${id}`);
    return null;
  }
  const data = docSnapshot.data();
  const model = new Unit(data);
  if (fetchParents) {
    await model.fetchVolume();
    // model.fetchedVolume = await VolumeCrud.loadById(model.data.volumeId, {
    //   fetchParents: true,
    //   fetchChildren: false,
    // });
  }
  return model;
}

async function loadAllForVolume(volume: Volume): Promise<Unit[]> {
  const querySnapshot = await collectionRef()
    .where('volumeId', '==', volume.id)
    // .where('archived', '==', false) // not trusting that an explicit values exists for all data, so filtering in client-side code
    .orderBy('unitNumber', 'asc')
    .get();
  const queriedUnits = querySnapshot.docs.map(doc => {
    const data = doc.data();
    const model = new Unit(data);
    model.fetchedVolume = volume;
    return model;
  });
  const units = queriedUnits.filter(unit => !unit.archived);
  // volume.fetchedUnits = units;
  return units;
}

async function updatePartial(
  id: string,
  data: Partial<UnitData>
): Promise<void> {
  console.log(`${collectionName}.updatePartial(${JSON.stringify(data)})`);
  data.updatedAt = epochSecondsFloat();

  // todo: factor out a docSetMerge() function with this guard
  if (isEmpty(data)) {
    // an empty object object will nuke the entire doc!
    return;
  }
  await docRef(id).set(data, { merge: true });
}

async function toggleArchive(data: UnitData): Promise<void> {
  await updatePartial(data.id, { archived: !data.archived });
}

async function destroyArchived() {
  // runInAction(async () => {
  //   const list = [...this.archivedList]; // make a shallow copy to isolate the loop data from mutation
  //   for (const item of list) {
  //     // console.log(item.destroy);
  //     await item.destroy();
  //   }
  // });
  // alertError('not yet implemented');
  // todo: promote dialogs to base console layout
  alert('not yet implemented');
}

export const UnitCrud: EntityCrud<Unit, UnitData> = {
  docRef,
  collectionRef,
  loadById,
  loadAllForVolume,
  updatePartial,
  toggleArchive,
  destroyArchived,
};
