import { TrickyEdit } from '@masala-lib/editorial/db/firestore-doc-types';
import { MutationActions } from '../../db/mutation-actions';
import { TrickyBitsSignalTransforms } from '@masala-lib/editorial/chaat/quanta-funcs';

export abstract class TrickyActions {
  updateTrickyEdits(edits: Map<number, TrickyEdit>) {
    this.mutationActions.updateTrickyEdits(edits);
  }

  updateTrickySignalTransforms(transforms: TrickyBitsSignalTransforms) {
    this.mutationActions.updateTrickySignalTransforms(transforms);
  }

  updateTrickyTrackDisable(key: string, disable: boolean) {
    this.mutationActions.updateTrickyTrackDisableMap({ [key]: disable });
  }

  updateTrickyTrackScaleFactors(key: string, factor: number) {
    this.mutationActions.updateTrickyTrackScaleFactors({ [key]: factor });
  }

  updateTrickyCompressionSetting(key: string, setting: any) {
    this.mutationActions.updateTrickyCompressionSetting({ [key]: setting });
  }

  abstract get mutationActions(): MutationActions;
}
