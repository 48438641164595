import { observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import { BackArrowIcon } from '../../console/components/icons/back-arrow-icon';
import { ForwardArrowIcon } from '../../console/components/icons/forward-arrow-icon';
import { BaseFilterKinds } from '@masala-lib/editorial/filters/base-filter-defs';
import { scriptEditorModel } from '../models/app-root';
import * as colors from '../../chaat/ui/colors';

interface IToggleButton {
  isActive: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  background-color: lightgray;
  padding: 5px;
`;

const Title = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
`;

const Warnings = styled.button<IToggleButton>`
  width: 40px;
  height: 100%;
  background-color: ${props =>
    props.isActive ? colors.darkRed : colors.lightRed};
`;

const OpenThreads = styled.button<IToggleButton>`
  width: 40px;
  height: 100%;
  background-color: ${props =>
    props.isActive ? colors.darkYellow : colors.lightYellow};
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 90px;
  justify-content: space-around;
`;

export const SearchResultsNavPanel = observer(() => {
  const warningCount = scriptEditorModel.lintWarnings?.values?.length ?? 0;
  const openThreadCount =
    scriptEditorModel.elementsWithActiveThreads?.values?.length ?? 0;

  const isOpenThreadsFilterActive = scriptEditorModel.filters.activeFlags.has(
    BaseFilterKinds.OPEN
  );
  const isWarningsFilterActive = scriptEditorModel.filters.activeFlags.has(
    BaseFilterKinds.WARNING
  );

  const size = scriptEditorModel.searchResult.values.length;
  const cursorPointId = scriptEditorModel.searchResultCursorPointId();
  const currentIndex = scriptEditorModel.searchResult.getIndex(cursorPointId);
  const onPrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    scriptEditorModel.prevSearchResult();
    e.currentTarget.blur();
  };
  const onNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    scriptEditorModel.nextSearchResult();
    e.currentTarget.blur();
  };

  const onClickWarnings = (e: React.MouseEvent<HTMLButtonElement>) => {
    scriptEditorModel.filters.setAllActiveFlagsOff();
    if (!isWarningsFilterActive) {
      scriptEditorModel.filters.setFlag(BaseFilterKinds.WARNING, true);
      setTimeout(() => scriptEditorModel.nextSearchResult(), 50);
    }

    e.currentTarget.blur();
  };

  const onClickOpenThreads = (e: React.MouseEvent<HTMLButtonElement>) => {
    scriptEditorModel.filters.setAllActiveFlagsOff();
    if (!isOpenThreadsFilterActive) {
      scriptEditorModel.filters.setFlag(BaseFilterKinds.OPEN, true);
      setTimeout(() => scriptEditorModel.nextSearchResult(), 50);
    }
    e.currentTarget.blur();
  };

  const current = currentIndex === -1 ? '-' : currentIndex + 1;
  const showNav = size > 0;
  return (
    <Container>
      <Status>
        <Warnings isActive={isWarningsFilterActive} onClick={onClickWarnings}>
          {warningCount}
        </Warnings>
        <OpenThreads
          isActive={isOpenThreadsFilterActive}
          onClick={onClickOpenThreads}
        >
          {openThreadCount}
        </OpenThreads>
      </Status>
      {showNav ? (
        <>
          <button onClick={onPrev}>
            <BackArrowIcon />
          </button>
          <Title>{`${current} of ${size}`}</Title>
          <button onClick={onNext}>
            <ForwardArrowIcon />
          </button>
        </>
      ) : null}
    </Container>
  );
});
