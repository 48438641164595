import { db } from '@platform/firebase-init';
import { ProjectTask, Prompt } from './llm-project-types';
import { QuerySnapshot } from '@platform/firebase-types';
import { randomString } from '@masala-lib/utils';
import { epochSeconds } from '../llm-funcs';

export function projectsCollection() {
  return db.collection('LlmPrompts');
}

export async function fetchTaskPrompts(task: ProjectTask): Promise<Prompt[]> {
  const query = projectsCollection().where('task', '==', task);
  // .where('archived', '!=', false); // todo: proper query
  const querySnapshot = (await query.get()) as QuerySnapshot<Prompt>;
  const list = querySnapshot.docs.map(doc => doc.data());
  return list.filter(p => p.archived !== true);
}

export async function fetchScopedPrompts(
  task: ProjectTask,
  scopes: string[]
): Promise<Prompt[]> {
  let result = await fetchTaskPrompts(task);
  if (scopes.length) {
    result = result.filter(p => !p.scope || scopes.includes(p.scope));
  }
  return result;
}

export function filterPromptsOnLanguagesKey(
  prompts: Prompt[],
  languagesKey: string
): Prompt[] {
  if (!languagesKey) return prompts;
  return prompts.filter(
    p => !p.languagesKey || p.languagesKey === languagesKey
  );
}

export function allocateDocId(task: ProjectTask) {
  return `${task}-${randomString(8)}`;
}

export async function savePrompt(data: Prompt): Promise<Prompt> {
  data.id ??= allocateDocId(data.task);
  data.timestamp ??= epochSeconds();
  data.parserKey ??= 'standard'; // will be part of the form data once we support multiple parsers
  const docRef = projectsCollection().doc(data.id);
  await docRef.set(data);
  return data;
}

export function sortPrompts(prompts: Prompt[]): Prompt[] {
  return prompts.slice().sort((a, b) => {
    const aPosition = a.position ?? 1000;
    const bPosition = b.position ?? 1000;
    if (aPosition !== bPosition) {
      return aPosition - bPosition;
    }

    const aTimestamp = a.timestamp ?? 0;
    const bTimestamp = b.timestamp ?? 0;
    return bTimestamp - aTimestamp;
  });
}
