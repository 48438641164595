import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';

import styled from 'styled-components';
import { signOut } from '../../../platform/firebase-auth';
import { auth } from '../../../script-editor/models/app-root';
import { Favorites } from './favorites';
import { Auth } from '@masala-lib/editorial/db/auth';

const Wrapper = styled.aside`
  --color-sidebar-tl-navitem: var(--color-blue500);
  --color-sidebar-tl-navitem-hover: var(--color-blue700);
  --color-sidebar-tl-background-hover: var(--color-gray100);

  --color-sidebar-sl-navitem: var(--color-gray700);
  --color-sidebar-sl-navitem-hover: var(--color-blue700);
  position: relative;
  overflow: auto;
  height: 100vh;

  /* position: fixed;
  width: 220px; */

  & > section {
    /* background: yellow; */
    padding: 4px 8px;
    /* margin-bottom: 8px; */
    /* border-bottom: 1px solid var(--color-gray100); */

    nav,
    ul,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .tl-link {
      display: block;
      font-size: 15px;
      line-height: 20px;
      color: var(--color-sidebar-tl-navitem);
      font-weight: 600;
      padding: 8px;
      padding-left: 16px;
      background: var(--color-sidebar-tl-background);
      border-radius: 4px;
      &.active {
        background: var(--color-sidebar-tl-background-hover);
      }
      &:hover {
        color: var(--color-sidebar-tl-navitem-hover);
        background: var(--color-sidebar-tl-background-hover);
      }
    }
    .sl-link {
      display: flex;
      font-size: 13px;
      line-height: 18px;
      color: var(--color-sidebar-sl-navitem);
      font-weight: 500;
      padding: 8px;
      padding-left: 16px;
      background: var(--color-sidebar-sl-background);
      border-radius: 4px;
      align-items: center;
      justify-content: flex-start;

      .label {
        max-width: 9.5em;
        text-overflow: ellipsis;

        white-space: nowrap;
        overflow: hidden;
      }
      .count {
        font-size: 12px;
        /* background: var(--color-gray300); */
        /* padding: 0 8px; */
        border-radius: 12px;
        /* color: var(--color-white); */
        color: var(--color-gray400);
        margin-left: auto;
        text-align: right;
      }
      .icon {
        color: var(--color-gray200);
        margin-left: -8px;
        margin-right: 4px;
      }

      &:hover {
        color: var(--color-sidebar-sl-navitem-hover);
      }
    }

    .sl-button {
      display: flex;
      font-size: 13px;
      line-height: 18px;
      color: var(--color-sidebar-sl-navitem);
      font-weight: 500;
      padding: 8px;
      padding-left: 16px;
      background: var(--color-sidebar-sl-background);
      border-radius: 4px;
      align-items: center;
      justify-content: flex-start;
      button {
        border: none;
        color: var(--color-blue500);
        background: none;
        padding: 4px 6px;
      }

      &:hover {
        color: var(--color-sidebar-sl-navitem-hover);
      }
    }
  }

  .user-section {
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--color-gray100);
    margin-bottom: 8px;
    height: 48px;
    margin-top: -4px;

    .user-avatar {
      margin-right: 4px;
    }

    .signout-button {
      margin-left: auto;
      background: none;
      border: none;
      color: var(--color-gray300);
      &:hover {
        color: var(--color-red400);
      }
    }
  }
`;

const ExitIcon = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9569 4H11V20H12V7.00986C12 6.57785 12.2774 6.19468 12.6878 6.05983L18.9569 4ZM22 4.38129V19.9904V20C22 20.4908 21.6464 20.8991 21.18 20.9839L13.3122 23.569C12.6654 23.7815 12 23.2997 12 22.619V21H11C10.4477 21 10 20.5523 10 20V4C10 3.44772 10.4477 3 11 3H21C21.5523 3 22 3.44772 22 4V4.38129ZM3.5 9H1.5C1.22386 9 1 9.22386 1 9.5V13.5C1 13.7761 1.22386 14 1.5 14H3.5C3.77614 14 4 14.2239 4 14.5V16.0196C4 16.4623 4.53273 16.6866 4.84937 16.3773L9.56483 11.7715C9.76567 11.5753 9.76567 11.2523 9.56483 11.0561L4.84937 6.45034C4.53273 6.14107 4 6.36541 4 6.80803V8.5C4 8.77614 3.77614 9 3.5 9Z"
      fill="currentColor"
    />
  </svg>
);

// const ChannelIcon = (
//   <svg width={16} height={16} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     {/*     <path
//       fill="currentColor"
//       d="M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M7,18H9L9.35,16H13.35L13,18H15L15.35,16H17.35L17.71,14H15.71L16.41,10H18.41L18.76,8H16.76L17.12,6H15.12L14.76,8H10.76L11.12,6H9.12L8.76,8H6.76L6.41,10H8.41L7.71,14H5.71L5.35,16H7.35L7,18M10.41,10H14.41L13.71,14H9.71L10.41,10Z"
//     /> */}
//     <path
//       fill="currentColor"
//       d="M5.41,21L6.12,17H2.12L2.47,15H6.47L7.53,9H3.53L3.88,7H7.88L8.59,3H10.59L9.88,7H15.88L16.59,3H18.59L17.88,7H21.88L21.53,9H17.53L16.47,15H20.47L20.12,17H16.12L15.41,21H13.41L14.12,17H8.12L7.41,21H5.41M9.53,9L8.47,15H14.47L15.53,9H9.53Z"
//     />
//   </svg>
// );

const UserSection = observer(() => {
  if (!auth.authUser) {
    return null;
  }

  return (
    <div className="user-section">
      <div className="user-avatar">
        <img
          style={{
            width: 35,
            height: 35,
            objectFit: 'cover',
            borderRadius: '50%',
            padding: 3,
          }}
          src={auth.authUser.photoURL}
          alt=""
        />
      </div>
      <div className="user-name">{auth.appUser?.alias}</div>
      <button className="signout-button" onClick={signOut}>
        {ExitIcon}
      </button>
    </div>
  );
});

// interface Bookmark {
//   id: string;
//   location: string;
//   name: string;
// }

export const MasterSidebar = observer(() => {
  const auth = Auth.getInstance();

  if (!auth.userManager.ready) {
    return null;
  }

  return (
    <Wrapper>
      <section>
        <UserSection />
      </section>
      <section>
        <Favorites />
      </section>
      <section>
        <NavLink to="/channels" className="tl-link">
          Channels
        </NavLink>
      </section>
      <section>
        <NavLink to="/volumes" className="tl-link">
          Volumes
        </NavLink>
      </section>
      <section>
        <NavLink to="/units" className="tl-link">
          Units
        </NavLink>
      </section>
      {auth.isPartner ? null : (
        <>
          <section>
            <NavLink to="/excerpts" className="tl-link">
              Soundbites
            </NavLink>
            {/* <NavLink to="/soundbite-editions" className="tl-link">
              Soundbite editions
            </NavLink> */}
          </section>
          <hr />
          <section>
            <NavLink to="/users" className="tl-link">
              Users
            </NavLink>
          </section>
          <hr />
          <section>
            <NavLink to="/activity-guides" className="tl-link">
              Activity guides
            </NavLink>
          </section>
          <section>
            <NavLink to="/authors" className="tl-link">
              Authors
            </NavLink>
          </section>
          <section>
            <NavLink to="/featured-releases" className="tl-link">
              Collections
            </NavLink>
          </section>
          <section>
            <NavLink to="/video-guides" className="tl-link">
              Video guides
            </NavLink>
          </section>
          <section>
            <NavLink to="/tags" className="tl-link">
              Tags
            </NavLink>
          </section>
          <section>
            <NavLink to="/misc" className="tl-link">
              Misc tools
            </NavLink>
          </section>
          <hr />
          <section>
            <NavLink to="/archive" className="tl-link">
              Archive
            </NavLink>
            <NavLink to="/assist-projects" className="tl-link">
              Assist projects
            </NavLink>
            <NavLink to="/jobs" className="tl-link">
              Jobs
            </NavLink>
          </section>
        </>
      )}
      {auth.isAdmin ? (
        <>
          <hr />
          <section>
            <NavLink to="/dev" className="tl-link">
              Developers
            </NavLink>
          </section>
        </>
      ) : null}
    </Wrapper>
  );
});
