import { isEmpty } from 'lodash';
import {
  Section,
  ZipRun,
  SkipRun,
  splitSectionWithMatchResult,
} from './sections';
import { InfoTag, MatchResult } from './match-result';
import { TimeInterval } from './timestamper-types';
import { comparePrimitives } from './util_ts';

type Input = Section;
export type Output = ZipRun | SkipRun | InfoTag;
type Processable = Input | Output;

export type Matcher = (section: Section) => MatchResult;

export function dumpMatchResult(section: Section, m: MatchResult) {
  console.log('**** ' + m.matcherKind.toUpperCase());
  const data = m.matchInfo;
  if (data.kind === 'POSITION_PAIRS') {
    const pairs = data.pairs;
    for (const pair of pairs) {
      const { contentPos, transcriptPos } = pair;
      const contentWord = section.content.data.word[contentPos];
      const transcriptWord = section.transcript.data.word[transcriptPos];
      console.log(`${contentWord} = ${transcriptWord}`);
    }
  }
  if (data.kind === 'SLICE_PAIRS') {
    const pairs = data.pairs;
    for (const pair of pairs) {
      const { contentSlice, transcriptSlice } = pair;
      console.log(
        'content slice len: ' + (contentSlice[1] - contentSlice[0] + 1)
      );
      console.log(
        'transcript slice len: ' + (transcriptSlice[1] - transcriptSlice[0] + 1)
      );
      const len = contentSlice[1] - contentSlice[0] + 1;
      for (let i = 0; i < len; i++) {
        const contentPos = contentSlice[0] + i;
        const transcriptPos = transcriptSlice[0] + i;
        const contentWord = section.content.data.word[contentPos];
        const transcriptWord = section.transcript.data.word[transcriptPos];
        if (typeof transcriptWord === 'undefined') {
          console.log('TWORD UNDEFINED');
        }
        console.log(`${contentWord} = ${transcriptWord}`);
      }
    }
  }
}

export function makeSkipRun(section: Section): SkipRun {
  const positions = section.content.data.globalPosition;
  console.log('MAKING SKIP RUN');
  return {
    tag: 'SKIP_RUN',
    globalPosition: positions[0],
    skipCount: positions.length,
    interval: { startTime: section.startTime, endTime: section.endTime },
  };
}

export function processWithMatchers(
  inputs: Processable[],
  matchers: ((section: Section) => MatchResult)[]
): Processable[] {
  const output: Processable[] = [];
  for (const input of inputs) {
    const kind = input.tag;
    if (kind === 'SECTION') {
      const section = input;
      let matchResult: MatchResult = null;
      for (const matcher of matchers) {
        matchResult = matcher(section);
        if (matchResult) {
          break;
        }
      }
      if (matchResult) {
        // System_Array__$005B$005D$1_extend_5975E3(
        // dumpMatchResult(section, matchResult);
        output.push(
          ...[
            ...splitSectionWithMatchResult(section, matchResult),
            ...matchResult.infoTags,
          ]
        );
      } else {
        // System_Array__$005B$005D$1_append_1505(output, section);
        output.push(section);
      }
    } else {
      // System_Array__$005B$005D$1_append_1505(output, input);
      output.push(input);
    }
  }
  return output;
}

export class TimestamperEngine {
  processingQueue: Section[] = [];
  outputQueue: Output[] = [];
  matchers: Matcher[] = [];
  sectionOverrideMatchers: (section: Section) => Matcher[] = null;

  setDefaultMatchers(matchers: ((section: Section) => MatchResult)[]) {
    this.matchers = matchers.slice();
  }

  setSectionOverrideMatchers(
    sectionOverrideMatchers: (section: Section) => Matcher[]
  ): void {
    this.sectionOverrideMatchers = sectionOverrideMatchers;
  }

  setSections(sections: Section[]) {
    this.processingQueue = sections.slice();
  }

  routeOutputs(outputs: Processable[]) {
    for (const output of outputs) {
      if (output.tag === 'SECTION') {
        // System_Array__$005B$005D$1_append_1505(this$.processingQueue, output);
        this.processingQueue.push(output);
      } else {
        this.processOutput(output);
      }
    }
  }

  run() {
    let numberIterations = 0;
    const maxIterations = 500000;
    // TODO while notEmpty(processingQueue) && numberIterations < maxIterations do
    while (
      !isEmpty(this.processingQueue) ? numberIterations < maxIterations : false
    ) {
      numberIterations = numberIterations + 1;
      // const section = System_Array__$005B$005D$1_popLeft(this$.processingQueue);
      const section = this.processingQueue.shift();
      let matchResult: MatchResult = null;
      let matchers: typeof this.matchers = null;
      if (this.sectionOverrideMatchers) {
        matchers = this.sectionOverrideMatchers(section);
      }
      if (!matchers) {
        matchers = this.matchers;
      }
      // const arr = this$.matchers;
      for (const matcher of matchers) {
        matchResult = matcher(section);
        if (matchResult) {
          break;
        }
      }
      if (matchResult) {
        // dumpMatchResult(section, matchResult);
        this.routeOutputs([
          ...splitSectionWithMatchResult(section, matchResult),
          ...matchResult.infoTags,
        ]);
      } else {
        this.routeOutputs([makeSkipRun(section)]);
      }
    }
    if (numberIterations >= maxIterations) {
      throw new Error('timestamper run over maximum iterations limit');
    }
  }

  processOutput(output: Output) {
    // System_Array__$005B$005D$1_append_1505(this$.outputQueue, output);
    this.outputQueue.push(output);
  }
}

export function extractDataFromOutput(
  output0: Output[],
  words: string[],
  endTime: number
) {
  const wordIntervals: TimeInterval[] = [];
  const outputWords: string[] = [];
  const warningIntervals: TimeInterval[] = [];
  const warningData = [];
  const interpolateIntervals: TimeInterval[] = [];
  const interpolateData: string[] = [];
  // let output = Array.sortBy (fun (o) -> int(o?globalPosition)) output0 // TODO see how inefficient int() is
  const output = output0
    .slice()
    .sort((a, b) => comparePrimitives(a.globalPosition, b.globalPosition));
  // const output = sortBy(o => o.globalPosition, output0, {
  //   Compare: comparePrimitives,
  // });
  for (const o of output) {
    // TODO
    const kind = o.tag;
    // TODO
    if (kind === 'ZIP_RUN') {
      const run = o;
      let pos = run.globalPosition;
      interpolateData.push(o.subTag);
      const intervals = run.intervals;
      interpolateIntervals.push({
        startTime: intervals[0].startTime,
        endTime: intervals[intervals.length - 1].endTime,
      });

      // const arr = run.intervals;
      for (const interval of intervals) {
        // const interval = arr[idx_1];
        // System_Array__$005B$005D$1_append_1505(wordIntervals, interval);
        if (Number.isNaN(interval.startTime)) {
          continue;
        }
        wordIntervals.push(interval);
        if (pos !== outputWords.length) {
        }
        // System_Array__$005B$005D$1_append_1505(outputWords, words[pos]);
        outputWords.push(words[pos]);
        pos = pos + 1;
      }
      // TODO
    } else if (kind === 'SKIP_RUN') {
      throw new Error('cannot extract data because output contains skip run');
    } else if (kind === 'INFO_TAG') {
      const tag = o;
      if (tag.kind === 'warn') {
        // System_Array__$005B$005D$1_append_1505(warningIntervals, tag.interval);
        warningIntervals.push(tag.interval);
        // System_Array__$005B$005D$1_append_1505(warningData, tag.name);
        warningData.push(tag.name);
      } else if (tag.kind === 'interpolate') {
        // System_Array__$005B$005D$1_append_1505(
        // interpolateIntervals.push(tag.interval);
        // System_Array__$005B$005D$1_append_1505(interpolateData, tag.name);
        // interpolateData.push(tag.name);
      }
    }
  }
  return {
    interpolateData,
    interpolateIntervals,
    warningData,
    warningIntervals,
    wordIntervals,
    words: outputWords,
  };
}
