import { Element, WordGroup } from '../../editor-aliases';
import {
  EKind,
  isStructuralKind,
  StructuralKind,
  WordGroupSubKind,
} from '../../element-kinds';
import { FilterTerm, regexTermParser } from './filters-model';
import { VersionableElement } from '../../editorial-types';

export enum BaseFilterKinds {
  WORD_GROUP = 'WORD_GROUP',
  VOCAB = 'VOCAB',
  TRICKY = 'TRICKY',
  SIC = 'SIC',
  SENTENCE = 'SENTENCE',
  UNFILLED = 'UNFILLED',
  STRUCTURAL = 'STRUCTURAL',
  OPEN = 'OPEN',
  WARNING = 'WARNING',
  ASSIGNED = 'ASSIGNED',
  AREPARTICIPANT = 'AREPARTICIPANT',
  CHANGED = 'CHANGED',
  MENTION = 'MENTION',
  BY = 'BY',
}

export const wordGroupFilter = {
  kind: BaseFilterKinds.WORD_GROUP,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#group ',
  parse: regexTermParser(BaseFilterKinds.WORD_GROUP, /#group/),
  func: (term: FilterTerm) => (el: Element) => el.kind === EKind.WORD_GROUP,
};

export const vocabFilter = {
  kind: BaseFilterKinds.VOCAB,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#vocab ',
  parse: regexTermParser(BaseFilterKinds.VOCAB, /#vocab/),
  func: (term: FilterTerm) => (el: WordGroup) =>
    el.subKind === WordGroupSubKind.VOCAB,
};

export const trickyFilter = {
  kind: BaseFilterKinds.TRICKY,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#tricky ',
  parse: regexTermParser(BaseFilterKinds.TRICKY, /#tricky/),
  func: (term: FilterTerm) => (el: WordGroup) =>
    el.subKind === WordGroupSubKind.TRICKY,
};

export const sicFilter = {
  kind: BaseFilterKinds.SIC,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#sic ',
  parse: regexTermParser(BaseFilterKinds.SIC, /#sic/),
  func: (term: FilterTerm) => (el: WordGroup) =>
    el.subKind === WordGroupSubKind.SIC,
};

export const sentenceFilter = {
  kind: BaseFilterKinds.SENTENCE,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#sentence ',
  parse: regexTermParser(BaseFilterKinds.SENTENCE, /#sentence/),
  func: (term: FilterTerm) => (el: Element) => el.kind === EKind.SENTENCE,
};

export const structuralFilter = {
  kind: BaseFilterKinds.STRUCTURAL,
  isFlag: true,
  canonicalText: (t: FilterTerm) => '#structural ',
  parse: regexTermParser(BaseFilterKinds.STRUCTURAL, /#structural/),
  // func: (term: FilterTerm) => (el: Element) => el.kind in StructuralKind,
  func: (term: FilterTerm) => (el: Element) => isStructuralKind(el.kind),
};

// TODO filter by comments/actions only "last" or "all"
const username = (term: FilterTerm) => term.props.username ?? null;

export const byFilter = {
  kind: BaseFilterKinds.BY,
  isFlag: false,
  canonicalText: (t: FilterTerm) => '@' + t.props.username + ' ',
  parse: regexTermParser(BaseFilterKinds.BY, /@(?<username>\w*)/),
  func: (term: FilterTerm) => (el: VersionableElement) => {
    const name: string = username(term);
    return name ? el.author.includes(name) : false;
  },
};
