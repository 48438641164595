import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ActivityGuideData } from '@masala-lib/catalog/catalog-types';
import { useActivityGuide } from './use-activity-guide';
import { ActivityGuideForm } from './activity-guide-form';
import { OnePaneLayout } from '../shared/layouts';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';

interface ActivityGuideEditParams {
  stopEditing: () => void;
}

export const ActivityGuideEditScreen = observer(
  ({ stopEditing }: ActivityGuideEditParams) => {
    const manager = ActivityGuideManager.getInstance();
    const activityGuide = useActivityGuide();

    if (!activityGuide) {
      return null;
    }

    const onSubmit = (dto: ActivityGuideData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      activityGuide.fromFormData(dto);
      manager.update(activityGuide).then(stopEditing);
    };

    return (
      <Page title={`Editing ${activityGuide.name}`}>
        <OnePaneLayout>
          <ActivityGuideForm
            dto={activityGuide.toFormData()}
            onSubmit={onSubmit}
            onCancel={stopEditing}
          />
        </OnePaneLayout>
      </Page>
    );
  }
);
