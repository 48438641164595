import { IElement } from '@tikka/basic-types';
import { EditorStructural, StorageStructural } from './editorial-types';

type ValuesOf<T extends object> = T[keyof T];

export const BasicElementKind = {
  //
  // persisted kinds
  //
  WORD: 'WORD',
  // currently persisted as span. with the word id refactor the spans will become ephemeral, and
  // SENTENCE_HEAD will the persisted kind which are immediately transformed into the
  // ephemeral spans by the content episodeData upon load
  SENTENCE: 'SENTENCE', // -> ephemeral `SENTENCE_SPAN = 'SENTENCE'` w/ word id refactor
  // SENTENCE_HEAD = 'SENTENCE', // after word id refactor

  // SEGMENT_HEAD = 'SEGMENT, // todo: support persited representation of segment heads
  WORD_GROUP: 'WORD_GROUP',
  ADHOC_RANGE: 'ADHOC_RANGE',
  TRANSLATION: 'TRANSLATION',
  METADATA_BLOCK: 'METADATA_BLOCK',

  //
  // ephemeral kinds
  //
  NOTATION: 'NOTATION',
  TRICKY: 'TRICKY',
  SIC: 'SIC',
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type BasicElementKind = ValuesOf<typeof BasicElementKind>;
//
// all persisted kinds
//
export const StructuralContentKind = {
  CHAPTER: 'CHAPTER', // title
  PASSAGE: 'PASSAGE', // hint
  PARAGRAPH: 'PARAGRAPH', // w/ optional speaker label (formerly SPEAKER_LABEL)
  CHAPTER_NOTE: 'CHAPTER_NOTE', // currently also represents what will become 'guides' (formerly CULTURAL_NOTE)
  // GUIDE: 'GUIDE',
  //v3 LISTENING_NOTE = 'LISTENING_NOTE',
  // obsolete
  // CHAPTER_SUMMARY: 'CHAPTER_SUMMARY',
  // PASSAGE_QUESTION: 'PASSAGE_QUESTION',
  EXCERPT: 'EXCERPT_HEAD',
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type StructuralContentKind = ValuesOf<typeof StructuralContentKind>;

export const StructuralMarkerKind = {
  CHAPTER_COMPLETE: 'CHAPTER_COMPLETE',
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type StructuralMarkerKind = ValuesOf<typeof StructuralMarkerKind>;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export const StructuralKind = {
  ...StructuralContentKind,
  ...StructuralMarkerKind,
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type StructuralKind = StructuralContentKind | StructuralMarkerKind;

export const StructuralKindSet = new Set<string>(Object.values(StructuralKind));

export const StructuralKindsList = Object.values(
  StructuralKind
) as StructuralKind[];

export const WordGroupSubKind = {
  VOCAB: 'VOCAB',
  TRICKY: 'TRICKY',
  SIC: 'SIC',
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type WordGroupSubKind = ValuesOf<typeof WordGroupSubKind>;

export const GuideSubKind = {
  CULTURE: 'CULTURE',
  LANGUAGE: 'LANGUAGE',
  DIALECT: 'DIALECT',

  // used for legacy episode "chapter notes" elements
  // expected to be assigned to and above guide sub-kind or converted to a listening note element kind
  UNSPECIFIED: 'UNSPECIFIED',
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type GuideSubKind = ValuesOf<typeof GuideSubKind>;

export const ImportMetaElementKind = {
  // google source source url for the various metadata blocks to parse
  // will eventually have structured editor views for each metadata block type
  METADATA_URL: 'METADATA_URL',

  // used now for both lupa and bolero
  SPEAKERS: 'SPEAKERS', // new speaker label data

  // bolero/new content
  UNIT_METADATA: 'UNIT_METADATA',
  VOLUME_METADATA: 'VOLUME_METADATA',
  VOLUME_CREDITS: 'VOLUME_CREDITS',
  SHOW_METADATA: 'SHOW_METADATA',
  CHANNEL_METADATA: 'CHANNEL_METADATA',

  // lupa content
  METADATA: 'METADATA',
  ASSET_LINKS: 'ASSET_LINKS',
  NOTES: 'NOTES', // obsolete, ignored
  MISC: 'MISC', // ignored scratch space

  // obsolete - will throw error if found
  UNIT_VOICES: 'UNIT_VOICES',
  VOLUME_VOICES: 'VOLUME_VOICES',
  CAST: 'CAST', // legacy speaker label data
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type ImportMetaElementKind = ValuesOf<typeof ImportMetaElementKind>;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export const EKind = {
  ...BasicElementKind,
  ...StructuralKind,
  ...ImportMetaElementKind,
} as const;

// eslint-disable-next-line  @typescript-eslint/no-redeclare
export type EKind = BasicElementKind | StructuralKind | ImportMetaElementKind;

export function isStructuralKind(kind: string): boolean {
  // return kind in StructuralKind;
  return StructuralKindSet.has(kind);
}

export function isStructuralElement(
  el: IElement
): el is StorageStructural | EditorStructural {
  return isStructuralKind(el.kind);
}
