//
// Provides consolidated references to all the collection docs related to the catalog functionality
//

import { db } from '../../../platform/firebase-init';
// import { EpisodeMetadataDoc } from '../../editorial/db/firestore-doc-types';
import {
  CollectionReference,
  DocumentReference,
} from '../../../platform/firebase-types';
import { ChannelData, UnitData, VolumeData } from '../catalog-types';

export const CatalogCollections = {
  EPISODE_METADATA: 'Unit__metadata',
  UNIT_METADATA: 'Unit__metadata',
  VOLUME_METADATA: 'Volume__metadata',
  CHANNEL_METADATA: 'Channel__metadata',
  AUTHOR_METADATA: 'Author__metadata',
  TAG_METADATA: 'Tag__metadata',
  ACTIVITY_GUIDE_METADATA: 'ActivityGuide__metadata',
  FEATURED_RELEASE_METADATA: 'FeaturedRelease__metadata',
  VIDEO_GUIDE_METADATA: 'VideoGuide__metadata',
  EXCERPT_METADATA: 'Excerpt__metadata',
  SOUNDBITE_EDITION_METADATA: 'SoundbiteEdition__metadata',
};

export const collectionReference = <DocData>(path: string) => {
  return db.collection(path) as CollectionReference<DocData>;
};

export const docReference = <DocData>(path: string, docId: string) => {
  return db.collection(path).doc(docId) as DocumentReference<DocData>;
};

// export const episodeCollectionRef = (): CollectionReference<EpisodeMetadataDoc> => {
//   return collectionReference<EpisodeMetadataDoc>(CatalogCollections.EPISODE_METADATA);
// };

// export const episodeDocRef = (docId: string): DocumentReference<EpisodeMetadataDoc> => {
//   return docReference<EpisodeMetadataDoc>(CatalogCollections.EPISODE_METADATA, docId);
// };

export const unitCollectionRef = (): CollectionReference<UnitData> => {
  return collectionReference<UnitData>(CatalogCollections.UNIT_METADATA);
};

export const unitDocRef = (docId: string): DocumentReference<UnitData> => {
  return docReference<UnitData>(CatalogCollections.UNIT_METADATA, docId);
};

export const volumeCollectionRef = (): CollectionReference<VolumeData> => {
  return collectionReference<VolumeData>(CatalogCollections.VOLUME_METADATA);
};

export const volumeDocRef = (docId: string): DocumentReference<VolumeData> => {
  return docReference<VolumeData>(CatalogCollections.VOLUME_METADATA, docId);
};

export const channelCollectionRef = (): CollectionReference<ChannelData> => {
  return collectionReference<ChannelData>(CatalogCollections.CHANNEL_METADATA);
};

export const channelDocRef = (
  docId: string
): DocumentReference<ChannelData> => {
  return docReference<ChannelData>(CatalogCollections.CHANNEL_METADATA, docId);
};
