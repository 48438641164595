import { ElementId } from '../../../editor-aliases';
import { EKind, ImportMetaElementKind } from '../../../element-kinds';
import { randomString } from '../../../utils';
import { MutationActions } from '../../db/mutation-actions';

// [<AbstractClass>]
export abstract class MetadataBlockActions {
  create(kind: ImportMetaElementKind): ElementId {
    const newId = `METADATA_BLOCK:${randomString(12)}` as ElementId; // TODO factor to mutation actions?
    this.mutationActions.addUpdateMetadataBlock({
      subKind: kind,
      id: newId,
      content: '',
    });

    return newId;
  }

  updateContent(id: ElementId, content: string): void {
    this.mutationActions.updateContentMetadataBlock(id, content);
  }

  remove(id: ElementId): void {
    this.mutationActions.removeMetadataBlock(id);
  }

  abstract get mutationActions(): MutationActions;
}
