import React, { useEffect } from 'react';
import {
  CatalogStage,
  WorkflowStatus,
} from '@masala-lib/catalog/catalog-types';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import useSWR from 'swr';
import { CatalogCaliData } from '@masala-lib/catalog/cali/cali-catalog-types';
import { useParams } from 'react-router-dom';

const buildCaliCatalogData = async (
  channelId: string
): Promise<CatalogCaliData> => {
  // const channel = ChannelManager.getInstance().fetchById(chanelId);
  const channel = await new ChannelManager().loadSoloById(channelId);
  const result = channel.buildCaliCatalogData(CatalogStage.REVIEW, 0, '');
  return result;
};

export const CaliCatalogDataScreen = () => {
  // const {
  //   params: { id },
  // } = match;
  const { id } = useParams<{ id: string }>();

  const { data, error } = useSWR(id, buildCaliCatalogData);

  if (data === undefined && !error) {
    return <div className="App">loading...</div>;
  }

  if (error) {
    return <div className="App">{String(error)}</div>;
  }

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
