import { styled } from 'naan/stitches.config';

export const Badge = styled('div', {
  $$backgroundColor: '$colors$gray-400',
  $$textColor: '$colors$white',
  display: 'inline-block',
  color: '$$textColor',
  backgroundColor: '$$backgroundColor',
  textStyle: 'badge-text',
  borderRadius: '4px',
  px: '4px',
  textTransform: 'uppercase',
  alignSelf: 'center',
  paddingTop: '1px',
  marginRight: '6px',
  variants: {
    type: {
      activityGuide: {
        $$backgroundColor: '$colors$yellow-200',
        $$textColor: '$colors$black-alpha-50',
      },
      bad: {
        $$backgroundColor: '$colors$red-500',
        $$textColor: '$colors$white',
      },
    },
  },
});
