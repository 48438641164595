import React from 'react';
import { observer } from 'mobx-react';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { importTranslationsFromRaGoogleDoc } from '@masala-lib/editorial/translations/translations-tsv';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';

const localExecution = true;

interface FormData {
  episodeKey: string;
  docUrl: string;
  locale: string;
}

async function importTranslationsFromDoc(params: FormData) {
  if (localExecution) {
    importTranslationsFromDocLocal(params);
  } else {
    importTranslationsFromDocRemote(params);
  }
}

async function importTranslationsFromDocRemote({
  episodeKey,
  docUrl,
  locale,
}: FormData) {
  try {
    const apiUrl = `${deploymentConfig.masalaServerUrl}/import_translations_from_doc?key=${episodeKey}&translations_doc_url=${docUrl}&locale=${locale}`;
    console.log(`importTranslationsFromDoc - apiUrl: ${apiUrl}`);
    let result = await fetch(apiUrl);
    const text = await result.text();
    console.log(`response: ${text}`);
    consoleAddAuditLogMessage(
      episodeKey,
      `Imported translations tsv for ${locale}`
    );
    alert(text);
    // return text;
  } catch (error) {
    console.error(error);
    window.alert(error);
  }
}

async function importTranslationsFromDocLocal({
  episodeKey,
  docUrl,
  locale,
}: FormData) {
  try {
    let result = await importTranslationsFromRaGoogleDoc(
      episodeKey,
      docUrl,
      locale
    );
    console.log(`importTranslationsFromRaGoogleDoc - complete: ${result}`);
    consoleAddAuditLogMessage(
      episodeKey,
      `Imported RA google doc translations for ${locale}`
    );
    alert(`import complete: ${result}`);
  } catch (error) {
    console.error(error);
    window.alert(error);
  }
}

interface Props {
  episodeKey: string;
  defaultLocale: string;
}
export const ImportTranslationsForm = observer(
  ({ episodeKey, defaultLocale }: Props) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = (data: FormData) => {
      console.log(`ImportTranslationsForm.onSubmit(${JSON.stringify(data)})`);
      if (!isEmpty(data.docUrl)) {
        importTranslationsFromDoc(data);
      } else {
        alert('Google doc URL required');
      }
    };

    console.log('defaultLocale ', defaultLocale);

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          defaultValue={episodeKey}
          {...register('episodeKey')}
        />
        URL of Google Doc with 1-sentence-per-line segmentation of translation
        text:
        <br />
        <input {...register('docUrl', { required: true })} />
        {errors.docUrl && <span>required</span>}
        <br />
        language code:{' '}
        <input
          {...register('locale', { required: true })}
          defaultValue={defaultLocale}
          size={2}
        />{' '}
        (i.e. 'en', 'ja')
        {errors.docUrl && <span>required</span>}
        <br />
        <input
          type="submit"
          value="DESTRUCTIVELY import translations from the google doc"
        />
      </form>
    );
  }
);
