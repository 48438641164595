import { formatDuration } from './format-duration';
import { WordGroup } from './word-group';

import styled from 'styled-components';
import React from 'react';

const Wrapper = styled.main`
  .passage {
    margin-top: 4rem;
    &:first-child {
      margin-top: 0;
    }
  }

  .sentence {
    user-select: auto;
  }

  .hint {
    font-family: serif;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 3rem;
    font-size: 2rem;
    margin-top: -0.5rem;
  }

  .timestamp {
    user-select: none;
    display: block;
    grid-column: 1;
    position: relative;
    left: -10vw;
    top: 1.2rem;
    line-height: 1rem;
    width: 8vw;
    text-align: right;
    font-size: 0.8rem;

    &.bold {
      font-weight: 500;
    }

    &.debug-label {
      font-size: 0.7rem;
      font-weight: 900;
      color: #777;
    }
  }

  .speaker {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9em;
    letter-spacing: 0.025em;
  }

  .translation-x {
    margin-top: -1rem;
    font-style: italic;
    color: var(--color-gray600);
  }

  .type-vocab {
    text-decoration: underline;
    text-decoration-color: var(--color-gray500);
    text-decoration-skip-ink: all;
  }

  .type-sic {
    text-decoration: line-through;
    box-decoration-break: clone;
    text-decoration-color: var(--color-gray500);
    text-decoration-skip-ink: all;
  }

  .type-tricky {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-decoration-color: var(--color-gray500);
    text-decoration-skip-ink: all;
  }

  .tricky-label {
    color: var(--color-gray500);
  }
`;

export const ChapterElements = ({ chapter }: { chapter: any }) => {
  return (
    <Wrapper>
      {chapter.elements.map((element: any) => {
        if (element.entityType === 'passage') {
          return (
            <div className="passage" key={element.address}>
              <span className="timestamp bold">
                {formatDuration(element.audioStart)}
              </span>
              <h4 className="hint">{element.hint}</h4>
              {element.elements?.map((element: any) => {
                if (element.entityType === 'paragraph') {
                  return (
                    <div className="paragraph" key={element.address}>
                      <span className="timestamp">
                        {formatDuration(element.audioStart)}
                      </span>
                      <header className="speaker">
                        <span>¶</span> {element.speaker}
                      </header>
                      {element.elements?.map((element: any) => {
                        if (element.entityType === 'sentence') {
                          return (
                            <React.Fragment key={element.address}>
                              <div className="sentence">
                                <div className="timestamp debug-label">
                                  {element.debugLabel}
                                </div>
                                <div className="word-groups">
                                  {element.elements?.map((element: any) => {
                                    if (element.entityType === 'word_group') {
                                      return (
                                        <WordGroup
                                          group={element}
                                          key={element.address}
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              </div>
                              <div className="sentence translation-x">
                                {element.translation}
                              </div>
                            </React.Fragment>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          );
        }
        return null;
      })}
    </Wrapper>
  );
};
