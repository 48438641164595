import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Unit } from '@masala-lib/catalog/models/unit';
import { A } from '@masala-lib/editorial/ui/action-link';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import { Auth } from '@masala-lib/editorial/db/auth';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';

async function importFromUrl(unit: Unit, scriptUrl: string) {
  try {
    await unit.importFromUrl(scriptUrl);
    consoleAddAuditLogMessage(
      unit.episodeKey,
      'Imported from url: ' + scriptUrl
    );
  } catch (error) {
    unit.clearPendingOperation();
    window.alert(error);
    throw error;
  }
}

interface FormData {
  id: string;
  importSource: string;
}

export const UnitImport = observer(({ model }: { model: Unit }) => {
  const auth = Auth.getInstance();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: FormData) => {
    console.log(`UnitImport.onSubmit(${JSON.stringify(data)})`);
    const source = data.importSource.trim();
    if (isEmpty(source)) {
      alert('source required');
    } else {
      if (source.startsWith('http')) {
        importFromUrl(model, source);
      } else {
        alert('url required');
      }
    }
  };

  if (!auth.can('manage_units')) {
    return <>Import / bootstrap needed</>;
  }

  return (
    <>
      <h4>Import</h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          defaultValue={model.id}
          {...register('id', { required: false })}
        />
        Transcript source URL
        <br />
        <input {...register('importSource')} size={20} />
        <input type="submit" /> &nbsp;
      </form>
      {/* // handy links for testing convience */}
      {model.data.exportedUrl ? (
        <>
          <hr />
          <A href={model.data.exportedUrl}>last exported data</A> (
          {uiTimeString(model.data.exportedAt)})
          <br />
        </>
      ) : null}
    </>
  );
});
