import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { ScriptElement } from '@masala-lib/llm/llm-types';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import { computed } from 'mobx';
import { ScriptElementControls } from './script-element-controls';
import { WinningIndicator } from './winning-indicator';
import { styleLookup } from './style-lookup';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { elide } from '@utils/string-utils';
import classnames from 'classnames';
import { msToMinSecString } from '@masala-lib/misc/timestamp-formats';
// import { ScriptElementOverflowMenu } from './script-element-overflow-menu';

const ScriptElementStyle = styled('div', {
  position: 'relative',
  $$dotColor: '$colors$transparent',
  $$focusIndicatorColor: '$colors$transparent',
  $$backgroundColor: '$colors$transparent',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr auto auto',
  gap: 12,

  paddingLeft: '18px',
  borderLeft: '6px solid $$focusIndicatorColor',
  lineHeight: '2em',
  backgroundColor: '$$backgroundColor',

  '&::before': {
    position: 'absolute',
    content: '""',
    width: 8,
    height: 8,
    left: 4,
    borderRadius: 10,
    backgroundColor: '$$dotColor',
  },

  '& > .text': {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& > .indicator': {
      transition: 'opacity 0.2s ease',
    },
    '& > .text-inner': {
      '&.bold': {
        fontWeight: 'bold',
      },
      '&.italic': {
        fontStyle: 'italic',
      },
      '&.truncate': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      },
    },
  },

  variants: {
    kind: {
      chapter: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginTop: '1em',
        marginBottom: '.5em',
      },
      chapterBreak: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginTop: '1em',
        marginBottom: '.5em',
      },
      passageBreak: {
        fontWeight: 'bold',
        fontSize: '1.2em',
        marginTop: '1em',
        marginBottom: '.5em',
      },
      sentence: {
        marginTop: '2em',
      },
      speaker: {
        // @armando, maybe a different font here to distinguish?
        fontSize: '1.1em',
        marginTop: '1em',
        marginBottom: '-1.5em',
      },
      translation: {
        // paddingLeft: '3em',
        paddingLeft: '12px',
      },
      vocab: {
        fontWeight: 'bold',
        paddingLeft: '4em',
        // paddingLeft:'12px',
      },
    },
    selected: {
      true: {
        // $$focusIndicatorColor: '$colors$blue-700',
        $$backgroundColor: '$colors$blue-50',
      },
    },
    highlighted: {
      true: {
        // $$focusIndicatorColor: '$colors$blue-700',
        backgroundColor: 'pink',
      },
    },
    alert: {
      true: {
        // backgroundColor: 'pink',
        $$dotColor: '$colors$red-300',
      },
    },
    suppressed: {
      true: {
        color: 'grey',
        '& .indicator': {
          opacity: 0,
        },
        '&:hover .indicator': {
          opacity: 1,
        },
      },
    },
    archived: {
      true: {
        color: '$black-alpha-20',
        textDecoration: 'line-through',
      },
    },
    task: {
      translation: {},
      vocab: {},
      structural: {
        marginTop: '0em',
        marginBottom: '0em',
      },
    },
    subTask: {},
  },
  compoundVariants: [
    {
      kind: 'passage',
      task: 'translation',
      css: {
        marginTop: '0em',
        marginBottom: '0em',
      },
    },
    {
      kind: 'passage',
      task: 'vocab', // TODO needed?
      css: {
        marginTop: '0em',
        marginBottom: '0em',
      },
    },
    // {
    //   kind: 'passage',
    //   task: 'structural',
    //   css: {
    //     marginTop: '0em',
    //     marginBottom: '0em',
    //   },
    // },
    {
      kind: 'sentence',
      task: 'structural',
      css: {
        marginLeft: '2em',
        marginTop: '1em',
        marginBottom: '0em',
      },
    },
    {
      kind: 'translation',
      task: 'vocab',
      css: {
        marginTop: '.25em',
        paddingLeft: '4em',
        fontStyle: 'italic',
      },
    },
    {
      kind: 'speaker',
      task: 'structural',
      css: {
        marginBottom: '0em',
      },
    },
    {
      kind: 'speaker',
      subTask: 'structural',
      css: {
        marginBottom: '0em',
      },
    },
  ],
});

export const ScriptElementItem = observer(
  ({
    element,
    modal,
  }: {
    element: ScriptElement;
    modal: TouchUpEditorModal;
  }) => {
    const id = element.id;
    const kind = element.kind;
    const selected = computed(() => modal.currentElementId === id).get();
    const referenceGroupSelected = computed(
      () => modal.currentElement?.reference === element.reference
    ).get();
    const collapsed = modal.collapsed;
    const suppressed = computed(() => modal.isSuppressed(id)).get();
    const archived = computed(() => modal.isArchived(id)).get();
    const picked = computed(() => modal.isPicked(id)).get();
    const added = element.origin === 'ADD';
    const alert = !!computed(() => modal.elementIdHasAlert(id)).get();
    const editing = computed(() => modal.editingElementId === id).get();
    let playing = false;
    if (element.kind === 'SENTENCE' && modal.player) {
      playing = computed(() => modal.currentPlayingSentenceId === id).get();
    }
    const highlighted = playing;

    const style = styleLookup[kind];
    const elementCategory = modal.elementCategory(element);
    const isOutput = elementCategory === 'OUTPUT';
    const isReference = elementCategory === 'REFERENCE';
    const isDecorator = elementCategory === 'DECORATOR';
    const isSummary = kind.endsWith('_SUMMARY');
    const isSlotClaiming = !!element.claimedSlot;
    const isRef = modal.elementIsReference(element);
    const shouldElide = isSummary && !referenceGroupSelected && !collapsed;
    // const elementText = shouldElide ? elide(element.text, 48) : element.text;
    const elementText = element.text;
    const timeDuration = modal.getOptionalElementTimeDuration(element);
    const samosaModel = useSamosaModel();
    const { task, subTask } = samosaModel;

    // for now always hide losers unless row selected
    if (task === 'structural' && suppressed && !referenceGroupSelected) {
      return null;
    }
    if (task === 'structural' && collapsed && !isOutput) {
      return null;
    }

    let prefix =
      typeof style.prefix === 'function' ? style.prefix(element) : style.prefix;
    if (isReference) {
      prefix += `[${element.reference}] `;
    }
    const styleName = style.styleName;
    let text = `${prefix}${elementText}`;
    if (timeDuration) {
      text += ` (duration: ${msToMinSecString(
        timeDuration[1]
      )} starts: ${msToMinSecString(timeDuration[0])})`;
    }

    // const handleDoubleClick = () => {
    //   if (isOutput) {
    //     if (element.origin === 'ADD') {
    //       modal.handleEditIntent(id);
    //     } else {
    //       // ignore double click on immutable candidates
    //     }
    //   } else {
    //     modal.editNote(id);
    //   }
    // };

    return (
      <ScriptElementStyle
        id={element.id}
        onClick={() => modal.setCurrentElementId(id)}
        // onDoubleClick={() => isTranslation && modal.editElement(id)}
        // onDoubleClick={handleDoubleClick}
        kind={styleName as any}
        task={task}
        subTask={subTask as any}
        selected={selected}
        suppressed={suppressed}
        archived={archived}
        alert={alert}
        highlighted={highlighted}
        // flagged={hasNote}
        // samosaFlagged={samosaFlagged}
      >
        <div className="text">
          {isSlotClaiming ? (
            <div className="indicator">
              <WinningIndicator element={element} modal={modal} />
            </div>
          ) : null}
          {editing ? 'form' : null}
          {/*
              let textClassName = 'text-inner';
    if (isOutput) {
      if (picked) {
        textClassName += ' bold';
      } else if (!added) {
        textClassName += ' italic';
      }
    }

          */}
          <span
            className={classnames('text-inner', {
              truncate: shouldElide,
              bold: picked && isOutput,
              italic: !added && isOutput,
            })}
          >
            {text}
          </span>
        </div>
        {!isDecorator ? (
          <>
            <div className="flags">
              <ScriptElementControls element={element} modal={modal} />
            </div>
            {/* {isRef ? (
              <div className="end">
                <ScriptElementOverflowMenu element={element} editor={modal} />
              </div>
            ) : null} */}
          </>
        ) : null}
      </ScriptElementStyle>
    );
  }
);
