import { reaction, makeObservable, observable } from 'mobx';
import { isNil } from 'lodash';
import { DbPaths, DocKeys } from '../editorial/db/db';
import { db } from '../../platform/firebase-init';
import {
  DocumentReference,
  DocumentSnapshot,
} from '../../platform/firebase-types';
import { DisposerFn, ReactionFn } from '../basic-types-aside';

export const NOT_INITIATED = 'NOT_INITIATED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETE = 'COMPLETE';
export const ERROR_NOT_LOADABLE = 'ERROR_NOT_LOADABLE';

export type FirestoreDocSetFactory = (
  key: string,
  listenMode?: boolean
) => FirestoreDocSet;

export abstract class FirestoreDocSet {
  key: string;
  listenMode: boolean;
  stateVersion: number;
  status: string;
  errorInfo: string; //?
  disposers: DisposerFn[] = [];

  constructor(key: string, listenMode = true) {
    this.key = key;
    this.listenMode = listenMode;
    this.stateVersion = 1;
    this.status = NOT_INITIATED;
    this.errorInfo = null; // TODO
    this.disposers = [];
    // TODO
    makeObservable(this, {
      stateVersion: observable.ref,
      status: observable.ref,
      errorInfo: observable.ref,
    });
  }

  load(): void {
    this.status = IN_PROGRESS;
    this.disposers.push(this.listenUpdates(() => this.handleUpdate()));
  }

  close(): void {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  watchDocument(f: ReactionFn, cb: Function): DisposerFn {
    return reaction(f, () => cb());
  }

  abstract clearAllDocs(): void;
  abstract get hasAllDocs(): boolean;

  abstract copyTo(dest: unknown): void;

  verifyDocSet(): void {
    if (this.hasAllDocs) {
      this.status = COMPLETE;
    }
  }

  abstract listenFirestoreUpdates(): DisposerFn;

  abstract watchAllDocs(cb: Function): DisposerFn;

  onSnapshot(
    docref: DocumentReference<unknown>,
    callback: Function
  ): DisposerFn {
    // TODO wrap callback in error handling that sets ERROR_NOT_LOADABLE when error (no doc for example)
    if (this.listenMode) {
      return docref.onSnapshot(callback as any);
    } else {
      docref.get().then(callback as any);
      return () => null;
    }
  }

  handleUpdate(): void {
    this.verifyDocSet();
    this.stateVersion++;
  }

  listenUpdates(cb: Function): DisposerFn {
    const disposers: DisposerFn[] = [];
    // TODO need to setup reaction to error info???
    disposers.push(this.watchAllDocs(cb));
    disposers.push(this.listenFirestoreUpdates());
    return () => {
      for (let d of disposers) d();
    };
  }
}

export class GenericFirestoreDocSet extends FirestoreDocSet {
  docs: any = {};
  docKeys: DocKeys[] = [];
  overrideDocRefs: { [index in string]: DocumentReference<any> } = {};

  constructor(key: string, docKeys: DocKeys[], listenMode = true) {
    super(key, listenMode);
    this.docKeys = docKeys ?? this.getDocKeys();

    for (const docKey of this.docKeys) {
      this.docs[docKey] = null;
    }
    this.docs = observable(this.docs, null, { deep: false });
  }

  addDocRef(key: string, ref: DocumentReference<any>) {
    this.overrideDocRefs[key] = ref;
    this.docs[key] = null;
    this.docKeys.push(key as DocKeys);
  }

  getDocKeys(): DocKeys[] {
    throw Error('Abstract method unimplemented');
  }

  clearAllDocs() {
    for (const docKey of this.docKeys) {
      this.docs[docKey] = null;
    }
  }

  get hasAllDocs() {
    for (const docKey of this.docKeys) {
      if (!this.docs[docKey]) {
        return false;
      }
    }
    return true;
  }

  copyTo(dest: any) {
    for (const docKey of this.docKeys) {
      dest[docKey] = this.docs[docKey];
    }
  }

  watchAllDocs(cb: () => void): () => void {
    const disposers: (() => void)[] = [];
    for (const docKey of this.docKeys) {
      disposers.push(this.watchDocument(() => this.docs[docKey], cb));
    }
    return () => {
      for (let d of disposers) d();
    };
  }

  handleSnapshot(doc: DocumentSnapshot<unknown>, docKey: string) {
    const data = doc.data();
    if (isNil(data)) {
      this.status = ERROR_NOT_LOADABLE;
    }
    this.docs[docKey] = doc.data();
  }

  getDocRef(
    key: DocKeys | string,
    paths: DbPaths = null
  ): DocumentReference<any> {
    if (this.overrideDocRefs[key]) {
      return this.overrideDocRefs[key];
    }
    return paths.getDocRef(key as DocKeys);
  }

  listenFirestoreUpdates() {
    const disposers: (() => void)[] = [];
    const paths = new DbPaths(db, this.key);
    for (const docKey of this.docKeys) {
      disposers.push(
        this.onSnapshot(
          this.getDocRef(docKey, paths),
          (doc: DocumentSnapshot<unknown>) => this.handleSnapshot(doc, docKey)
        )
      );
    }
    return () => {
      for (let d of disposers) d();
    };
  }
}
