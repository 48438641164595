import * as Sentry from '@sentry/react';
import { alertError as alertErrorUi } from '../ui/misc-utils';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { createLogger } from './logger';

const errorReportingEnabled = deploymentConfig.errorReportingEnabled;
const log = createLogger('notification-service');

export const bugsnagNotify = (errorOrString: Error | string) => {
  log.error(`***ERROR*** ${errorOrString}`);

  if (errorReportingEnabled === false) {
    const stack =
      (errorOrString as Error)?.stack || new Error().stack || '(no-stack)';
    log.error(stack);
    return;
  }

  if (errorOrString instanceof Error) {
    Sentry.captureException(errorOrString);
  } else {
    Sentry.captureMessage(errorOrString);
  }
};

// shared interface with masala-server
export const alertError = (error: any) => {
  alertErrorUi(error);
};
