import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { FeaturedReleaseData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { FeaturedRelease } from '@masala-lib/catalog/models/featured-release';
import { FeaturedReleaseForm } from './featured-release-form';
import { FeaturedReleaseManager } from '@masala-lib/catalog/db/featured-release-manager';
import { useNavigate } from 'react-router-dom';

export const FeaturedReleaseCreateScreen = observer(() => {
  const manager = FeaturedReleaseManager.getInstance();
  const navigate = useNavigate();
  const model = new FeaturedRelease();

  const goBack = () => {
    navigate(`/featured-releases`);
  };

  const onSubmit = (dto: FeaturedReleaseData) => {
    console.group('Creating collection');
    console.log('From data', dto);
    model.fromFormData(dto);
    manager.create(model).then(goBack);
    console.groupEnd();
  };

  return (
    <Page title={'Creating new collection'}>
      <OnePaneLayout>
        <FeaturedReleaseForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
        />
      </OnePaneLayout>
    </Page>
  );
});
