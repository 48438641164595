import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { useVolume } from '../volumes/use-volume';
import { SpeakerForm } from './speaker-form';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { pick } from 'lodash';
import {
  setSpeakerTranslation,
  speakerTranslation,
} from '@masala-lib/catalog/models/volume';

interface SpeakerEditParams {
  stopEditing: () => void;
}

export const SpeakerEditScreen = observer(() => {
  const volume = useVolume();
  const { label = null } = useParams<{ label?: string }>();
  const navigate = useNavigate();

  if (!volume) {
    return null;
  }

  const speaker = volume.speakerByLabel(label);
  if (!speaker) {
    // throw Error(`speaker data not found for label: ${label}`);
    return <NavLink to={volume.navPath}>[Back]</NavLink>;
  }
  const formData = pick(speaker, ['label', 'bio', 'accent']) as any;
  const l1 = volume.l1;
  formData['label-l1'] = speakerTranslation(speaker, 'label', l1);
  formData['bio-l1'] = speakerTranslation(speaker, 'bio', l1);
  formData['accent-l1'] = speakerTranslation(speaker, 'accent', l1);

  const goBack = () => {
    navigate(volume.speakersNavPath);
  };

  const onSubmit = (dto: any) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    // author.fromFormData(dto);
    // manager.update(author).then(stopEditing);
    const l2Props = pick(dto, ['label', 'bio', 'accent']);
    const newData = { ...speaker, ...l2Props };
    // todo: think about how to better factor this
    setSpeakerTranslation(speaker, 'label', l1, dto['label-l1']);
    setSpeakerTranslation(speaker, 'bio', l1, dto['bio-l1']);
    setSpeakerTranslation(speaker, 'accent', l1, dto['accent-l1']);
    volume.updateSpeaker(label, newData).then(goBack);
  };

  return (
    <Page title={`Editing ${speaker.label}`} onClose={goBack}>
      <SpeakerForm
        dto={formData}
        onSubmit={onSubmit}
        cancelNavPath={volume.speakersNavPath}
        l1Locale={volume.l1}
        l2Locale={volume.l2}
      />
    </Page>
  );
});
