import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { VolumeListScreen } from './volume-list-screen';
import { VolumeDetailScreen } from './volume-detail-screen';
import { VolumeEditScreen } from './volume-edit-screen';
import { VolumeCreateScreen } from './volume-create-screen';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Volume } from '@masala-lib/catalog/models/volume';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { SpeakerEditScreen } from '../speakers/speaker-edit-screen';
import { VolumeSpeakersScreen } from '../speakers/volume-speakers-screen';
import { VolumeCreditsEditScreen } from './volume-credits-edit-screen';
import { VolumeInfoEditScreen } from './volume-info-edit-screen';

export const VolumesScreen = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ModelScreen
            path={'/volumes'}
            renderList={() => <VolumeListScreen />}
            renderDetail={controller => (
              <VolumeDetailScreen
                closeDetail={controller.closeDetail}
                startEditing={controller.startEditing}
              />
            )}
            renderEditForm={controller => (
              <VolumeEditScreen stopEditing={controller.stopEditing} />
            )}
            renderCreateForm={controller => <VolumeCreateScreen />}
          />
        }
      />
      <Route path=":id/speakers/:label/edit" element={<SpeakerEditScreen />} />
      <Route path=":id/speakers" element={<VolumeSpeakersScreen />} />
      <Route path=":id/credits" element={<VolumeCreditsEditScreen />} />
      <Route path="slug/:slug" element={<VolumeDetailBySlug />} />
      <Route path=":id/info" element={<VolumeInfoEditScreen />} />
    </Routes>
  );
};

const loadVolumeBySlug = async (slug: string): Promise<Volume> => {
  const result = await new VolumeManager().loadSoloBySlug(slug);
  return result;
};

export const VolumeDetailBySlug = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data: volume, error } = useSWR(slug, loadVolumeBySlug);

  if (volume === undefined && !error) {
    return <div className="App">loading...</div>;
  }

  if (error) {
    return <div className="App">{String(error)}</div>;
  }

  if (volume === null) {
    return <div className="App">volume data not found for slug: {slug}</div>;
  }

  return <Navigate to={`/volumes/${volume.id}`} />;
};
