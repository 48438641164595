import * as React from 'react';
import { observer } from 'mobx-react';
import { Exchange, Step } from '@masala-lib/llm/project/llm-project-types';
import { styled } from '@naan/stitches.config';
import { ExchangeResponse } from './exchange-response';
import { ExchangeRequest } from './exchange-request';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { displayPropsForExchange } from '@masala-lib/llm/project/llm-project-funcs';

const ExchangeRowWrapper = styled('div', {
  $$leftBorderColor: 'transparent',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  marginBottom: 50,

  '&::before': {
    content: '""',
    position: 'absolute',
    left: -4,
    top: 0,
    bottom: 0,
    width: 4,
    backgroundColor: '$$leftBorderColor',
    borderRadius: 2,
  },

  variants: {
    selected: {
      true: {
        $$leftBorderColor: '$colors$blue-500',
      },
    },
  },
});

//
// a single request/response pair as shown within main body panel
//
export const ExchangeStep = observer(({ step }: { step: Step }) => {
  return (
    <ExchangeRowWrapper>
      <ExchangeRequest step={step} />
      <ExchangeResponse step={step} />
    </ExchangeRowWrapper>
  );
});

//
// exchange status/details to display within the modal for both the request and exchange
//
export const ExchangeDetailStatus = ({ exchange }: { exchange: Exchange }) => {
  const model = useSamosaModel();
  const { request } = exchange;
  const { llmOptions } = request;

  const suppressed = model.isExchangeSuppressed(exchange as Exchange);
  const { age } = displayPropsForExchange(exchange);
  // const status = model.threadStatus(exchange);

  return (
    <p>
      {/* // should this be shared or distinct from the exchange-step rendering? */}
      {exchange.rawResponse.error ? (
        // todo: styling
        <p>ERROR: {exchange.rawResponse.error}</p>
      ) : null}
      {suppressed ? (
        <>
          <strong>SUPPRESSED</strong>
          <br />
        </>
      ) : null}
      {age}
      {/* {exchange.label ? ` (${exchange.label})` : null} */}
      {/* , {status} */}
      {exchange.aborted ? ' (interrupted)' : null}
      <br />
      {/* {exchange.request.user ? <>{exchange.request.user} - </> : null} */}
      model: {llmOptions?.model}, temp: {llmOptions?.temperature}
      {llmOptions.parsingDisabled ? ' (parsing disabled)' : null}
    </p>
  );
};

const StatusWrapper = styled('div', {
  textStyle: 'tiny-text',
  color: '$black-alpha-40',
  display: 'flex',
  gap: 4,
  marginTop: -4,
  marginBottom: 4,
});

// version shown within thread body view
export const ExchangeInlineStatus = ({ exchange }: { exchange: Exchange }) => {
  // const model = useSamosaModel();
  // const { request } = exchange;
  // const { llmOptions } = request;

  const { age } = displayPropsForExchange(exchange);

  return (
    <StatusWrapper>
      <span>
        {exchange.code} {age}
      </span>
      {exchange.aborted ? <span>(interrupted)</span> : null}
      {exchange?.request?.llmOptions?.model}{' '}
      {exchange?.request?.llmOptions?.temperature}
    </StatusWrapper>
  );
};
