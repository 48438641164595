import * as React from 'react';
import { KeyboardService } from 'lib/services/keyboard-service';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';

export function useStructuralKeyboardBindings(
  editor: TouchUpEditorModal,
  onResolve: (value: boolean) => void
) {
  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('editor', {
      // todo: figure out how to best share overlaps with translation mode
      down: [() => editor.moveToNextLine(), null], // 'Next line'],
      up: [() => editor.moveToPrevLine(), null], //'Previous line'],
      // right: [() => editor.moveToNextAlert()],
      // left: [() => editor.moveToPrevAlert()],
      left: [() => editor.moveToPrevOutputSlotHead(), 'Previous structural'],
      right: [() => editor.moveToNextOutputSlotHead(), 'Next structural'],
      j: [() => editor.moveToPrevOutputSlotHead(), null],
      k: [() => editor.moveToNextOutputSlotHead(), null],

      w: [() => editor.pickWinner(), 'Pick winner'],
      enter: [() => editor.pickAndAdvance(), 'Pick and advance'],
      'shift+c': [() => editor.handleAddChapterBreak(), 'Add chapter marker'],
      g: [
        () => {
          editor.injectChapterInfoPrompt();
          onResolve(true);
        },
        'Generate chapter title / summary prompt',
      ],
      'shift+g': [
        () => {
          editor.injectPassageInfoPrompt();
          onResolve(true);
        },
        'Generate passage title / summary prompt',
      ],
      c: [() => editor.handleAddChapter(), 'Add free-form chapter title'],
      p: [() => editor.handleAddPassage(), 'Add free-form passage hint'],
      'shift+p': [() => editor.handleAddPassageMarker(), 'Add passage marker'],
      space: [() => editor.handlePlayAction(), 'Play/stop playing'],
      b: [() => editor.handleRewindAction(), 'Rewind 5 seconds'],
      m: [
        () => editor.handleMoveStructural(),
        'Move chapter/passage to another line',
      ],

      'shift+enter': [() => editor.handleEditIntent(), 'Edit / clone element'],
      'ctrl+d': [() => editor.toggleArchive(), 'Archive (soft delete) element'],
      'shift+ctrl+d': [
        () => editor.toggleShowArchived(),
        'Show / hide archived',
      ],

      // duplicated from translation mode
      f: [
        () => editor.markPending(),
        'Flag (to be addressed before sending back to Script Editor)',
      ],
      l: [
        () => editor.markDeferred(),
        'Defer (note will be sent back to Script Editor as comment)',
      ],
      r: [
        () => editor.markResolved(),
        'Resolve (clears flagged / deferred state)',
      ],
      s: [() => editor.saveToMasala(), 'Save to Masala'],
      n: [() => editor.editNote(), 'Add/edit note (automatically flags)'],
      'shift+n': [() => editor.toggleShowNotes(), 'Show / hide notes'],
      // o: [() => editor.toggleShowLosers(), 'Show / hide unpicked'],
      x: [() => editor.toggleCollapsed(), 'Toggle collapsed'],

      escape: [() => onResolve(true), 'Save and close'],
      // @armando, would there be an easy way to add this binding for all the modals w/o input fields?
      'shift+slash, slash': [() => KeyboardService.instance.toggleHelp(), null],
    });
  }, [editor, onResolve]);
}
