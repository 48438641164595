import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ConsoleLayout } from '../../console/views/console-layout';
import { ListOfLinks } from './shared/list-of-links';

export const HomeScreen = observer(() => {
  const auth = Auth.getInstance();
  return <View auth={auth} />;
});

const View = observer(({ auth }: { auth: Auth }) => {
  const user = auth.appUser;

  return (
    <ConsoleLayout title="Home">
      {auth.userManager.ready ? (
        user ? (
          <>
            <h4>Hi, {user.name}</h4>
            <ListOfLinks>
              <ul>
                <li>
                  <NavLink to="/channels">Manage Channels</NavLink>
                </li>
                <li>
                  <NavLink to="/volumes">All Volumes</NavLink>
                </li>
                <li>
                  <NavLink to="/units">All Units</NavLink>
                </li>
              </ul>
            </ListOfLinks>
          </>
        ) : (
          <h3>Unrecognized user: {auth.authUser?.email}</h3>
        )
      ) : (
        <>{/* <h3>authenticating...</h3> */}</>
      )}
    </ConsoleLayout>
  );
});
