/*
This is equivalent to window.confirm, intended for simple use.
They are not intended to be used for complex dialogs, or for dialogs that require
user input, custom controls, etc. For those, use the Dialog component directly.

Please don't overuse.

*/

import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { DialogPresenter } from '../dialog-presenter';
import { runConfirmableDialog } from '../utils/dialog-runner';
import { usePauseKeyboardService } from 'lib/services/keyboard-service';

const SimpleConfirm = ({
  body,
  onResolve,
}: {
  body: React.ReactNode;
  onResolve: (value: boolean) => void;
}) => {
  // @armando, please seek out other places that we need the keyboard service pause
  usePauseKeyboardService();
  const onCancel = () => onResolve(undefined);
  return (
    <Dialog.Container open onDismiss={onCancel}>
      <Dialog.Body>{body}</Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          label="Cancel"
          onClick={onCancel}
          presentation={'grayTransparent'}
        />
        <Dialog.Button
          label="OK"
          onClick={() => onResolve(true)}
          presentation={'teal'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

type SimpleConfirmProps = React.ComponentProps<typeof SimpleConfirm>;

export const presentSimpleConfirmation = (
  body: SimpleConfirmProps['body'],
  onResolve: SimpleConfirmProps['onResolve']
) => {
  DialogPresenter.present(onDismiss => (
    <SimpleConfirm
      body={body}
      onResolve={value => {
        onDismiss();
        onResolve(value);
      }}
    />
  ));
};

export const runSimpleConfirmation = async (
  body: SimpleConfirmProps['body']
): Promise<boolean> => {
  return runConfirmableDialog(onResolve => (
    <SimpleConfirm body={body} onResolve={onResolve} />
  ));
};
