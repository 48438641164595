import { observer } from 'mobx-react';
import { Exchange } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { alertError } from 'ui/misc-utils';
import { styled } from '@naan/stitches.config';
import { MinusSquareIcon, PlusSquareIcon } from '../components/icons';

const Button = styled('button', {
  $$hoverColor: '$colors$red-400',
  $$hoverBackgroundColor: '$colors$white-alpha-50',
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 4,
  color: '$$iconColor',

  // background: '$black-alpha-06',
  borderRadius: 16,
  '& svg': {
    width: 16,
    height: 16,
  },

  '&.suppresed': {
    opacity: 0.5,
    $$hoverColor: '$colors$green-600',
    // $$hoverBackgroundColor: '$colors$blue-100',
  },

  '&:hover': {
    opacity: 1,
    color: '$$hoverColor',
    background: '$$hoverBackgroundColor',
  },
});

export const SuppressToggle = observer(
  ({ exchange }: { exchange: Exchange }) => {
    const model = useSamosaModel();

    const toggleSuppress = (exchange: Exchange) => () => {
      if (model.sidebarMode === 'exchange') {
        model.toggleSuppressExchange(exchange).catch(alertError);
      } else {
        model.toggleSuppressThread(exchange).catch(alertError);
      }
    };

    const isSuppressed = model.isExchangeSuppressed(exchange);

    return (
      <Button
        onClick={toggleSuppress(exchange)}
        className={isSuppressed ? 'suppresed' : ''}
        title={isSuppressed ? 'unsuppress' : 'supress'}
      >
        {isSuppressed ? <PlusSquareIcon /> : <MinusSquareIcon />}
      </Button>
    );
  }
);
