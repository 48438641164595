import * as React from 'react';
import { ChannelData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Textarea } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { localeCodes } from '@utils/util-types';
// import { flavorOptions } from '../volumes/volume-form';
import { Auth } from '@masala-lib/editorial/db/auth';

// const catalogOptions = [
//   { label: '', value: '' },
//   { label: 'v3', value: 'v3' },
//   { label: 'v4', value: 'v4' },
// ];

// const endpointOptions = Object.keys(railsEndpointUrls).map(key => ({
//   value: key,
//   label: key,
// }));

const blankOption = { value: '', label: '[required]' };
const localeOptions = [
  blankOption,
  ...localeCodes.map(key => ({ value: key, label: key })),
];

interface Props {
  dto: Partial<ChannelData>;
  onSubmit: (dto: ChannelData) => void;
  onCancel: () => void;
}

// interface FormProps {
//   id: string;
//   name: string;
//   slug: string;
//   flavor?: string;
//   railsEndpointKey?: string;
//   catalogMode?: string;
//   catalogSlug?: string;
//   mergeChannelSlugs?: string;
// }

export const ChannelForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues: Partial<ChannelData> = pick(dto, [
    'id',
    'name',
    'slug',
    'clientChannelSlug',
    'title',
    'tagline',
    'description', // markdown
    'weblink',
    'themeColor', // hex
    // 'flavor',
    'l1',
    'l2',
    'auxL1s',
    'mergeChannelSlugs',
    // 'storageSilo',
    'catalogSlug',
    'autoPublishEnvCsv',
    'livePublishEnvCsv',
    // 'railsEndpointKey',
    // 'liveRailsEndpointKey',
    // 'liveCatalogSlug',
    'featuredFilterSets',
    'featuredTagSlugsCsv', // deprecated
    'featuredCollectionSlug', // deprecated
  ]);
  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);

  React.useEffect(() => {
    return () => {
      //otherwise we stay in form mode, even when going to another item
      onCancel();
    };
  }, [onCancel]);

  return (
    <OnePaneLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input {...getFieldProps('name')} required />
        <Input {...getFieldProps('slug')} />
        {/* <Select
          {...getFieldProps('flavor')}
          options={flavorOptions as any}
          required
        /> */}
        <Select
          {...getFieldProps('l2')}
          required
          options={localeOptions}
          label="Default L2 (studied language)"
        />
        <Select
          {...getFieldProps('l1')}
          required
          options={localeOptions}
          label="Default Primary L1 (learner's language)"
        />
        {/* <Input
          {...getFieldProps('auxL1s')}
          label="Aux L1s"
          hint="comma separated list of additional L1's for content to be translated into"
        /> */}

        <Input
          {...getFieldProps('catalogSlug')}
          hint={"(i.e. 'devtest-mini')"}
        />
        <Input
          {...getFieldProps('autoPublishEnvCsv')}
          label="Auto deploy envs (csv)"
          hint="comma separated list of: editorial, beta, live, devtest, local"
        />
        {Auth.getInstance().isSuperAdmin ? (
          <>
            <Input
              {...getFieldProps('livePublishEnvCsv')}
              label="'Live' deploy envs (csv)"
              // options={endpointOptions}
              hint={
                "(where the 'make live' action should publish the currently 'staged' catalog to)"
              }
            />
            {/* <Input {...getFieldProps('liveCatalogSlug')} /> */}
          </>
        ) : null}

        <Input
          {...getFieldProps('mergeChannelSlugs')}
          hint={'(cvs list of other channel slugs to merge into this catalog)'}
        />
        {/* <Input
          {...getFieldProps('storageSilo')}
          hint={
            "(allows better isolation of gcs stored assets for production channels, but defaults to 'dev' otherwise for wider caching)"
          }
        /> */}
        <h4>SPA end-user displayed props</h4>
        <Input
          {...getFieldProps('clientChannelSlug')}
          hint="(used to map SPA assets, assigned by joseph)"
        />
        <Input {...getFieldProps('title')} />
        <Input {...getFieldProps('tagline')} />
        <Textarea
          {...getFieldProps('description')}
          hint={'(markdown)'}
          rows={5}
        />
        <Input {...getFieldProps('weblink')} />
        <Input {...getFieldProps('themeColor')} hint="(provided by frank)" />

        <h4>SPA Dashboard singleton widget slugs</h4>
        <Input
          {...getFieldProps('featuredFilterSets')}
          hint={
            '(semi-colon separated list of csv lists of slug of topic and country tags to promote on dashboard view)'
          }
        />
        <Input
          {...getFieldProps('featuredTagSlugsCsv')}
          hint={'(deprecated)'}
        />
        <Input
          {...getFieldProps('featuredCollectionSlug')}
          hint={'(deprecated)'}
        />
        <FormButtons
          submitLabel={isNew ? 'Create channel' : 'Save changes'}
          onCancel={onCancel}
        />
      </form>
    </OnePaneLayout>
  );
};

//   // console.log(`ChannelForm.render, channel: ${JSON.stringify(channel)}, errors: ${errors?.length}`);
//   return (
//     /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
//     <form onSubmit={handleSubmit(onSubmit)}>
//       <ul>
//         <li key="id">
//           id: {dto.id}
//           <input type="hidden" defaultValue={dto.id} {...register('id', { required: false })} />
//         </li>
//         <li key="name">
//           name: <input defaultValue={dto.name} {...register('name', { required: true })} />
//         </li>
//         <li key="slug">
//           slug: <input defaultValue={dto.slug} {...register('slug', { required: false })} />
//         </li>
//       </ul>
//       <ErrorSummary errors={errors} />
//       <br />
//       {/* errors will return when field validation fails  */}
//       <input type="submit" /> &nbsp;
//       {/* todo: support imperative navigation so onCancel handler can conditionally navigate, then this can be simplified */}
//       {isNew ? (
//         <NavLink to={`/channels`}>[cancel]</NavLink>
//       ) : (
//         <ActionLink onPress={onCancel}>[cancel]</ActionLink>
//       )}
//     </form>
//   );
// };
