import React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { styled } from '@naan/stitches.config';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import { setTouchupEditorModal } from './use-editor-modal';
// import { ColumnTranslationMergeView } from './column-translation-merge-view';
import { ColumnStructuralMergeView } from './column-structural-merge-view';
import { runConfirmableDialog } from '../utils/dialog-runner';
import { useScrollIfNotVisible } from './use-scroll-if-not-visible';

const DialogContainer = styled(Dialog.Container, {
  width: 'min( 100% - 32px, 1000px )',
  border: '1px solid #888',
  margin: 'auto',
});

const DialogBody = styled(Dialog.Body, {
  width: 'min( 100% - 10px, 1000px )',
  maxHeight: '70vh',
  overflowY: 'scroll',
});

// export const MergeScriptModalDialog = React.forwardRef(
//   ({ modal }: { modal: Modal }, ref) => {
//     console.log('rendering modal');
//     const editorModal = modal as TouchUpEditorModal;
//     setTouchupEditorModal(editorModal);
//     return (
//       <Container ref={ref as any}>
//         <Body id={'bodyId'}>
//           <ColumnMergedView />
//         </Body>
//       </Container>
//     );
//   }
// );

export const ColumnMergeModal = ({
  modal,
  onResolve,
}: {
  modal: TouchUpEditorModal;
  onResolve: (value: boolean) => void;
}) => {
  // todo: factor shared code with translation-merge-modal.tsx
  const editorModal = modal;
  setTouchupEditorModal(editorModal);
  useScrollIfNotVisible(editorModal);
  // const disposers: (() => void)[] = [];
  // disposers.push(
  //   reaction(
  //     () => editorModal.currentElementId,
  //     () => {
  //       scrollIfNotVisibleDomId(
  //         editorModal.currentElementId,
  //         'nearest',
  //         'bodyId'
  //       );
  //       // ensure both selected element and potential candidate are visible
  //       const candidateId = editorModal.shouldBeVisibleCandidateId;
  //       if (candidateId) {
  //         scrollIfNotVisibleDomId(candidateId, 'nearest', 'bodyId');
  //       }
  //     }
  //   )
  // );

  // for now, any resolve will persist
  const handleClose = () => onResolve(true);
  return (
    <DialogContainer
      open
      // onEscapeKeyDownF={handleClose}
      // onDismiss={onCancel}
    >
      <Dialog.Heading>
        <Dialog.HtmlCloseButton onDismiss={handleClose} />
      </Dialog.Heading>
      <DialogBody id={'bodyId'}>
        <ColumnStructuralMergeView onResolve={onResolve} />
      </DialogBody>
    </DialogContainer>
  );
};

export const runColumnMergeDialog = async (
  modal: TouchUpEditorModal
): Promise<boolean> => {
  return runConfirmableDialog(onResolve => (
    <ColumnMergeModal modal={modal} onResolve={onResolve} />
  ));
};
