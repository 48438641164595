import { observer } from 'mobx-react';
import { OnePaneLayout } from './shared/layouts';
import { PageHeading } from './shared/page-heading';
import { Card } from '../components/data-display/card';
import { Button } from '../components/button';

export const MiscToolsScreen = observer(() => {
  return (
    <OnePaneLayout>
      <PageHeading>
        <h1>Misc tools</h1>
      </PageHeading>
      <Card header="Regenerate 'Guided listening worksheet' assets from latest 'lupa-external' catalog. (Will run automatically daily at midnight PST. May be used if needed for immediate verification)">
        <form
          action="https://api.netlify.com/build_hooks/615201df79d2cfff4cad8cbe"
          method="post"
          target="iframe"
        >
          <Button
            type="submit"
            label="Regenerate guided listening worksheets"
          ></Button>
        </form>
      </Card>
    </OnePaneLayout>
  );
});
