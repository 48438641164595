import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ActionDialog } from '../components/action-dialog';
import { VStack } from '@naan/primitives/stack';
import { ActionButton } from './action-button';
import { usePauseKeyboardService } from 'lib/services/keyboard-service';
import { PromptEntryModel } from '../message-entry/prompt-entry-model';
import { DialogPresenter } from '../dialog-presenter';
// import { openAiDirectModelNames } from '@masala-lib/llm/project/llm-project-funcs';

export const AutomationDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const model = useSamosaModel();
    const promptModel = PromptEntryModel.instance;
    usePauseKeyboardService();

    const onApplyAll = () => {
      model.parallelSend(promptModel.promptText);
      onDismiss();
    };
    const modelKey = model.llmOptions.model;

    const [provider, modelName] = modelKey.split('/');
    // const openAiDirect = openAiDirectModelNames.includes(modelName);
    // @jason, any reason that we can't simply this check now?
    const openAiDirect = provider === 'openai';

    return (
      <ActionDialog onDismiss={onDismiss} title={'Automations'}>
        <VStack gap={'small'}>
          {!openAiDirect ? (
            <p>
              <strong>
                Beware, only working reliably for models using openai directly
              </strong>
            </p>
          ) : null}
          <ActionButton label="Parallel send" onClick={onApplyAll} />
          <br />
          Substitutes {'`{script}`'} for each reference script section withiin
          current prompt and runs all requests in parallel
        </VStack>
      </ActionDialog>
    );
  }
);

export const showAutomationDialog =
  DialogPresenter.makePresenter(AutomationDialog);
