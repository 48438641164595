import { observer } from 'mobx-react';
import { Stack } from '../shared/stack';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { DeleteActions } from '../shared/delete-actions';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import ReactMarkdown from 'react-markdown';
import { Box } from 'rebass';
import { Auth } from '@masala-lib/editorial/db/auth';
import { useSoundbiteEdition } from './use-soundbite-edition';
import { A } from '@masala-lib/editorial/ui/action-link';
import { NavLink } from 'react-router-dom';
import {
  EditionSoundbitesData,
  SoundbiteEditionCalenderEdit,
} from './soundbite-edition-calendar-edit';
import { isEmpty, isEqual } from 'lodash';

interface SoundbiteEditionDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const SoundbiteEditionDetailScreen = observer(
  ({ closeDetail, startEditing }: SoundbiteEditionDetailsParams) => {
    const model = useSoundbiteEdition();

    if (!model) {
      return null;
    }

    const auth = Auth.getInstance();
    const { calendarSoundbiteIds, auxilarySoundbiteIds } = model.data;
    const allExcerpts = model.volume?.excerpts ?? [];

    // const handleIngest = async () => {
    //   try {
    //     await model.ingest();
    //   } catch (error) {
    //     model.clearPendingOperation();
    //     window.alert(error);
    //     throw error;
    //   }
    // };

    const handleCalendarSave = (changed: EditionSoundbitesData) => {
      const update = {} as any;
      if (!isEqual(changed.calendarSoundbiteIds, calendarSoundbiteIds)) {
        update['calendarSoundbiteIds'] = changed.calendarSoundbiteIds;
      }
      if (!isEqual(changed.auxilarySoundbiteIds, auxilarySoundbiteIds)) {
        update['auxilarySoundbiteIds'] = changed.auxilarySoundbiteIds;
      }
      if (!isEmpty(update)) {
        console.log(update);
        model.updatePartial(update);
      }
    };

    return (
      <Page
        title={`${model.name} ${model.archived ? '(Archived)' : ''}`}
        onClose={closeDetail}
        controls={
          <PageActions>
            <>
              {auth.can('manage_excerpts') ? (
                <>
                  <button onClick={startEditing} className="primary-action">
                    Edit Edition
                  </button>
                </>
              ) : null}
              {/* <DeleteActions model={model} /> */}
            </>
          </PageActions>
        }
      >
        <OnePaneLayout>
          <Stack>
            {/* <IngestionStatus model={model} /> */}
            <Card>
              <DataItem
                label={'Operation status'}
                value={model.operationStatus}
              />
              {model.volume ? (
                <DataItem
                  label="Volume"
                  value={
                    <>
                      {model.volume.name}
                      {' - '}
                      <NavLink to={model.volume.navPath}>Detail Screen</NavLink>
                    </>
                  }
                />
              ) : null}
              <DataItem label={'Workflow name'} value={model.data.name} />
              <DataItem label={'Slug'} value={model.data.slug} />
              {/* <DataItem label={'Id'} value={model.id} /> */}

              <DataItem
                label={'Summary'}
                value={
                  <Box py={3}>
                    <ReactMarkdown>{model.data.calendarSummary}</ReactMarkdown>
                  </Box>
                }
              />

              <DataItem
                label={'Calendar Soundbites'}
                value={
                  <SoundbiteEditionCalenderEdit
                    calendarSoundbiteIds={calendarSoundbiteIds}
                    auxilarySoundbiteIds={auxilarySoundbiteIds}
                    allExcerpts={allExcerpts}
                    onSave={handleCalendarSave}
                  />
                }
              />

              <DataItem
                label={'visibilityWeekPostEpoch'}
                value={model.data.visibilityWeekPostEpoch}
              />

              {/* <DataItem label={'Status'} value={model.data.status} /> */}

              {/* <DataItem
                // label={'Export raw script'}
                value={
                  <NavLink to={model.dumpScriptPath}>
                    [generate raw script]
                  </NavLink>
                }
              /> */}
              <DataItem
                label={'Linear URL'}
                value={
                  <A href={model.data.linearUrl}>{model.data.linearUrl}</A>
                }
              />
            </Card>
            <Card>
              {/* <DataItem
                label={'Data URL'}
                value={<A href={model.data.dataUrl}>{model.data.dataUrl}</A>}
              /> */}
              <DataItem
                label={'Debug Data Screen'}
                value={
                  <NavLink to={`/soundbite-editions/${model.id}/data`}>
                    [link]
                  </NavLink>
                }
              />
            </Card>
          </Stack>
        </OnePaneLayout>
      </Page>
    );
  }
);

// export const IngestionStatus = observer(({ model }: { model: Excerpt }) => {
//   // const user = Auth.getInstance().appUser;
//   // const lockedForMe = !excerpt.hasWriteAccess(user);

//   const channel = model.channel;

//   const handleIngestAndPreview = async () => {
//     try {
//       await model.ingest();
//       window.open(model.soundbitePreviewUrl);
//     } catch (error) {
//       model.clearPendingOperation();
//       window.alert(error);
//       throw error;
//     }
//   };

//   const handlePublishReviewCatalog = async () => {
//     try {
//       await channel.publishCaliReviewCatalog();
//     } catch (error) {
//       channel.clearPendingOperation();
//       window.alert(error);
//       throw error;
//     }
//   };

//   // const auth = Auth.getInstance();
//   // const writable = volume.hasWriteAccess(auth.appUser);

//   // const reviewVersion = volume.data.reviewVersion;
//   // const stagedVersion = volume.data.stagedVersion;

//   return (
//     <Card header="Ingestion status">
//       {/* <strong>Review version</strong> */}
//       {/* <br /> */}
//       {model.ingestionAgePretty || 'n/a'} (v
//       {model.data.ingestVersion}) &mdash;{' '}
//       <A href={model.data.dataUrl}>raw data url</A>
//       <br />
//       <A href={model.soundbitePreviewUrl}>[Soundbite preview]</A> &mdash;{' '}
//       <ActionLink onPress={handleIngestAndPreview}>
//         [Ingest and preview]
//       </ActionLink>{' '}
//       {/* &mdash;{' '} */}
//       {/* <ActionLink onPress={handleIngestAndPublish}>
//         [Ingest and update review catalog]
//       </ActionLink> */}
//       <br />
//       Review catalog: <i>{channel.reviewCatalogSlug}</i> &mdash;{' '}
//       {channel.reviewPublishAgePretty} (v
//       {channel.data.reviewCaliVersion?.versionNumber || ' n/a'})
//       {model.isCatalogStale ? (
//         <>
//           {' '}
//           &mdash;{' '}
//           <ActionLink onPress={handlePublishReviewCatalog}>
//             [Publish review catalog]
//           </ActionLink>
//         </>
//       ) : null}
//       <br />
//     </Card>
//   );
// });
