import { makeObservable, reaction, observable } from 'mobx';
import { chaatToolModel } from '../models/app-root';
import { TrackWidget } from '@masala-lib/editorial/chaat/ui/track-area/track-widget';
import { AudioPositionsTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/audio-positions-gadget';
import { WordsTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/words-gadget';
import { WaveformTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/waveform-gadget';
import { NavigationTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/navigation-gadget';
import { AudioRegionSelectionTrackGadget } from '@masala-lib/editorial/chaat/ui/track-area/audio-region-selection-gadget';
import * as colors from './colors';

import {
  player,
  navigationState,
  transportState,
  appBus,
} from '../models/app-root';
import { TransportState } from '@tikka/player/audio-transport';
// import { openAudioRegionLabelDialog } from "../audio-region-label-dialog";
import { intervalToMinSecMsDurationString } from '@masala-lib/misc/timestamp-formats';
import { getWaveformWithCache } from '@masala-lib/editorial/chaat/waveform-data';

export class MainTrackWidget extends TrackWidget {
  audioPositionGadget: AudioPositionsTrackGadget = null;
  waveformGadget: WaveformTrackGadget = null;
  wordsGadget: WordsTrackGadget = null;
  notchesGadget: NavigationTrackGadget = null;
  segmentsGadget: NavigationTrackGadget = null;
  audioRegionsGadget: NavigationTrackGadget = null;
  forceLinearRegionsGadget: NavigationTrackGadget = null;
  audioMarkersGadget: NavigationTrackGadget = null;
  cuesGadget: NavigationTrackGadget = null;
  infoGadget: NavigationTrackGadget = null;
  transcriptGadget: WordsTrackGadget = null;
  audioRegionSelectionGadget: AudioRegionSelectionTrackGadget = null;
  warningsGadget: NavigationTrackGadget = null;
  episodeKey = '';
  @observable.ref waveformData: number[] = [];

  constructor() {
    super(transportState, appBus);
    makeObservable(this);
  }

  createGadgets() {
    this.audioPositionGadget = new AudioPositionsTrackGadget();
    this.audioPositionGadget.setTransportState(transportState);
    this.audioPositionGadget.cursorWidth = 25;
    this.audioPositionGadget.cursorFillStyle = colors.teal;
    this.gadgets.push(this.audioPositionGadget);

    this.wordsGadget = new WordsTrackGadget();
    this.wordsGadget.handleFillStyle = colors.white;
    this.wordsGadget.rolloverHandleFillStyle = colors.lightYellow;
    this.wordsGadget.font = '300 35px Arial';
    this.gadgets.push(this.wordsGadget);

    this.cuesGadget = new NavigationTrackGadget();
    this.cuesGadget.doesRollover = false;
    this.cuesGadget.handleFillStyle = colors.teal;
    this.gadgets.push(this.cuesGadget);

    this.notchesGadget = new NavigationTrackGadget();
    this.notchesGadget.handleFillStyle = '#BBBBBB';
    this.gadgets.push(this.notchesGadget);

    this.segmentsGadget = new NavigationTrackGadget();
    this.segmentsGadget.handleFillStyle = colors.darkYellow;
    this.gadgets.push(this.segmentsGadget);

    this.warningsGadget = new NavigationTrackGadget();
    this.warningsGadget.handleFillStyle = colors.darkYellow;
    this.warningsGadget.handleFillStyleOverride = index => {
      return chaatToolModel.getWarningLevel(index) === 1 ? '#FF0000' : null;
    };

    this.gadgets.push(this.warningsGadget);

    this.infoGadget = new NavigationTrackGadget();
    this.infoGadget.handleFillStyle = colors.gray3;
    this.infoGadget.handleFillStyleOverride = index => {
      return chaatToolModel.interpolatedData[index] === 'interpolate'
        ? null
        : colors.gray1;
    };
    this.gadgets.push(this.infoGadget);

    this.waveformGadget = new WaveformTrackGadget();
    this.waveformGadget.fillStyle = colors.darkBlue;
    this.gadgets.push(this.waveformGadget);

    this.audioRegionsGadget = new NavigationTrackGadget();
    this.audioRegionsGadget.handleFillStyle = '#4C7FAA50';
    this.audioRegionsGadget.doesRollover = false;
    this.gadgets.push(this.audioRegionsGadget);

    this.forceLinearRegionsGadget = new NavigationTrackGadget();
    this.forceLinearRegionsGadget.handleFillStyle = '#63AA4C50';
    this.forceLinearRegionsGadget.doesRollover = false;
    this.gadgets.push(this.forceLinearRegionsGadget);

    this.audioMarkersGadget = new NavigationTrackGadget();
    this.audioMarkersGadget.handleFillStyle = '#ff752baa';
    this.audioMarkersGadget.doesRollover = false;
    this.gadgets.push(this.audioMarkersGadget);

    this.audioRegionSelectionGadget = new AudioRegionSelectionTrackGadget();
    this.audioRegionSelectionGadget.handleFillStyle = '#00995560';
    this.audioRegionSelectionGadget.setTransportState(transportState);
    this.audioRegionSelectionGadget.doesRollover = false;
    this.gadgets.push(this.audioRegionSelectionGadget);

    this.transcriptGadget = new WordsTrackGadget();
    this.transcriptGadget.handleFillStyle = colors.white;
    this.transcriptGadget.rolloverHandleFillStyle = colors.lightYellow;
    //this.transcriptGadget.font = "35px Arial";
    this.transcriptGadget.font = '300 35px Roboto ';
    this.transcriptGadget.fontColor = '#999';
    this.gadgets.push(this.transcriptGadget);

    // TODO implement this as autorun??
    // TODO disposers
    reaction(
      () => chaatToolModel.stateVersion,
      () => this.initGadgetsData(),
      { fireImmediately: true }
    );
    //appBus.subscribe('audioRegionEdit', () => this.audioRegionEdit());
    reaction(
      () => this.waveformData,
      () => this.waveformGadget.setWaveformData(this.waveformData),
      { fireImmediately: true }
    );
    appBus.subscribe('deselect', () => this.audioRegionDeselect());
  }

  layoutGadgetSlices() {
    let totalHeight = 0;
    let height;

    height = 40;
    this.segmentsGadget.setLayout(0, height);
    totalHeight += height;
    height = 23;
    const audioPositionStartY = totalHeight;
    this.audioPositionGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 65;
    this.waveformGadget.setLayout(totalHeight, height);
    this.audioRegionsGadget.setLayout(totalHeight + height / 2 - 35, 40);
    this.forceLinearRegionsGadget.setLayout(totalHeight + height / 2 - 35, 40);
    this.audioMarkersGadget.setLayout(totalHeight + height / 2 - 55, 65 + 23);
    totalHeight += height;
    height = 25;
    const cuesStartY = totalHeight;
    this.notchesGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.audioPositionGadget.positionLineExtension =
      totalHeight - audioPositionStartY;
    this.audioRegionSelectionGadget.setLayout(
      audioPositionStartY,
      totalHeight - audioPositionStartY
    );
    height = 50;
    this.wordsGadget.setLayout(totalHeight, height);
    totalHeight += height;
    this.cuesGadget.setLayout(cuesStartY, totalHeight - cuesStartY);
    height = 15;
    this.warningsGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 15;
    this.infoGadget.setLayout(totalHeight, height);
    totalHeight += height;
    height = 50;
    this.transcriptGadget.setLayout(totalHeight, height);
  }

  defaultNavigationReaction(gadget: NavigationTrackGadget) {
    gadget.onNavigationSelect((gadget, key, select) => {
      if (gadget.navigatorKey) {
        player.navigate(key, select.index);
      } else {
        player.seek(select.time);
      }
    });
  }

  defaultSetupNavigation(gadget: NavigationTrackGadget) {
    if (gadget.navigatorKey) {
      gadget.setNavigation(navigationState);
    }
    this.defaultNavigationReaction(gadget);
  }

  initGadgetsData() {
    const chaat = chaatToolModel;
    this.wordsGadget.setWordsIntervals(
      chaat.wordStrings,
      chaat.wordTimeIntervals
    );
    this.wordsGadget.onNavigationSelect((_, key, select) => {
      if (
        select.event.getModifierState('Alt') ||
        select.event.getModifierState('Ctrl') ||
        select.event.getModifierState('Shift')
      ) {
        // TODO need get id here
        appBus.emit('setCuePoint', chaat.words.getId(select.index));
      } else {
        player.seek(select.time);
      }
    });

    this.cuesGadget.setHandleIntervals(chaat.cueDisplayTimeIntervals);
    this.cuesGadget.onNavigationSelect((_, key, select) => {
      appBus.emit('setCuePoint', chaat.cuesData[select.index].wordId);
    });

    if (this.episodeKey !== chaat.episodeKey) {
      this.waveformData = [];
      if (chaat.audioUrls.transcribeAudioUrl) {
        getWaveformWithCache(chaat.audioUrls.transcribeAudioUrl).then(
          waveformData => (this.waveformData = waveformData as any)
        );
      }
    }
    // done by reaction this.waveformGadget.setWaveformData(this.waveformData);
    // TODO configure promise to load waveform data here and reaction to set again on waveformGadget
    this.waveformGadget.onTimeSelect(() => {
      const seekTo = this.waveformGadget.lastTimeSelect;
      // TODO factor this logic to player
      if (transportState.isPlaying) {
        player.seek(seekTo, true);
      } else {
        player.seek(seekTo);
      }
    });

    this.audioRegionSelectionGadget.onRegionSelect((_, region) => {
      chaat.setAudioRegionSelection(region); // TODO really
    });

    this.audioRegionsGadget.setHandleIntervals(
      chaat.nonVoiceAudioRegionIntervals
    );
    this.forceLinearRegionsGadget.setHandleIntervals(
      chaat.forceLinearAudioRegionIntervals
    );

    this.audioMarkersGadget.setHandleIntervals(chaat.audioMarkerHitIntervals);

    this.segmentsGadget.setHandleIntervals(chaat.segmentTimeIntervals);
    this.segmentsGadget.navigatorKey = 'SEGMENT';
    this.defaultSetupNavigation(this.segmentsGadget);

    this.warningsGadget.setHandleIntervals(chaat.warningTimeIntervals);

    this.infoGadget.setHandleIntervals(chaat.interpolatedTimeIntervals);

    this.notchesGadget.setHandleIntervals(chaat.notchTimeIntervals);
    this.notchesGadget.navigatorKey = 'NOTCH';
    this.defaultSetupNavigation(this.notchesGadget);

    this.transcriptGadget.setWordsIntervals(
      chaat.transcriptWords,
      chaat.transcriptWordTimeIntervals
    );
    this.defaultSetupNavigation(this.transcriptGadget);
  }

  drawForegroundLayer() {
    super.drawForegroundLayer();
  }

  doHover() {
    const hoverState = this.lastHoverState;
    const gadget = hoverState.gadget;
    const interval = hoverState.interval;

    const posY = this.waveformGadget.originY;
    let hoverText = null;

    if (
      gadget === this.wordsGadget ||
      gadget === this.audioRegionsGadget ||
      gadget === this.notchesGadget
    ) {
      // hoverText = ` ${(interval.start / 1000).toFixed(2)} - ${(
      //   interval.end / 1000
      // ).toFixed(2)} `;
      hoverText = intervalToMinSecMsDurationString(interval);
    }

    if (gadget === this.warningsGadget) {
      hoverText = ` ${chaatToolModel.getWarningUiText(hoverState.index)} `;
    }

    if (gadget === this.infoGadget) {
      const kind = chaatToolModel.interpolatedData[hoverState.index];
      hoverText = kind;
      // hoverText = ` ${chaat.utils.getInterpolationInfoUIText(
      //   hoverState.index
      // )} `;
    }

    if (hoverText) {
      this.showHover(gadget, hoverText, { interval, absolute: posY });
    }
  }

  // TODO audio region UI stuff
  // audioRegionEdit() {
  //   const selectedInterval = this.audioRegionSelectionGadget.selectionInterval;
  //   if (selectedInterval) {
  //     openAudioRegionLabelDialog(selectedInterval);
  //   }
  // }

  audioRegionDeselect() {
    this.audioRegionSelectionGadget.clearSelection();
  }

  toggleEnabled() {
    super.toggleEnabled();
    if (this.enabled) {
      this.audioPositionGadget.setTransportState(transportState);
    } else {
      this.audioPositionGadget.setTransportState(new TransportState());
    }
  }
}
