import { ScriptElement } from '@masala-lib/llm/llm-types';
import { styled } from '@naan/stitches.config';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';

const AnnotationWrapper = styled('div', {
  display: 'inline-flex',
  width: 20,
  height: 20,
  fontSize: 8,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  fontWeight: 900,
  color: '$colors$black-alpha-50',
  background: '$gray-50',
  borderRadius: 6,
});

export const ElementExchangeCodeAnnotation = ({
  element,
}: {
  element: ScriptElement;
}) => {
  const samosaModal = useSamosaModel();
  const exchange = samosaModal.exchangeForElement(element);
  const exchangeCode = exchange?.code;
  const parseOrigin = element.origin === 'PARSE' || element.origin === 'EDIT';
  if (exchangeCode && parseOrigin) {
    return <AnnotationWrapper>{exchangeCode}</AnnotationWrapper>;
  } else {
    return null;
  }
};
