import * as React from 'react';

const INPUT_MAX_ROWS = 8;
const INPUT_MIN_ROWS = 1;

function getDynamicRowsForText(
  text: string,
  max = INPUT_MAX_ROWS,
  min = INPUT_MIN_ROWS
) {
  const lines = text?.split('\n') || [];
  const rows = Math.min(max, Math.max(min, lines.length));
  return rows;
}

type ResizingState = 'AUTO' | 'EXPANDED' | 'COLLAPSED';

export const useResizeOverride = (initialState: ResizingState = 'AUTO') => {
  const [expandState, setExpandState] =
    React.useState<ResizingState>(initialState);

  const getRows = React.useCallback(
    (promptText: string) => {
      const rows =
        expandState === 'AUTO'
          ? getDynamicRowsForText(promptText)
          : expandState === 'EXPANDED'
          ? INPUT_MAX_ROWS
          : INPUT_MIN_ROWS;
      return rows;
    },
    [expandState]
  );

  const handleResizeOverride = React.useCallback(
    (rows: number) => {
      let nextExpandState: ResizingState;
      if (expandState === 'AUTO') {
        nextExpandState = rows < 2 ? 'EXPANDED' : 'COLLAPSED';
      } else if (expandState === 'EXPANDED') {
        nextExpandState = 'COLLAPSED';
      } else {
        nextExpandState = 'EXPANDED';
      }
      setExpandState(nextExpandState);
    },
    [expandState]
  );

  return { getRows, handleResizeOverride };
};
