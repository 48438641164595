import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Button, IconButton } from '@naan/primitives/button';
import {
  BsArrowRightShort,
  BsArrowLeftShort,
  BsArrowBarLeft,
  BsArrowUpLeftSquare,
} from 'react-icons/bs';
import { useTouchupEditorModal } from './use-editor-modal';
import { BiSolidArrowFromLeft, BiSolidArrowToLeft } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import { TranslationModalToolbar } from './translation-modal-toolbar';

const TopBar = styled('div', {
  backgroundColor: '$$chromeColor',
  color: '$colors$gray-100',
  display: 'flex',
  // height: '2em',
  justifyContent: 'flex-end',
  alignItems: 'center',
  // width: 'min( 100% - 32px, 920px )',
  borderBottom: '1px solid $colors$gray-200',
  borderRadius: '12px 12px 0 0',
  padding: '0px 16px',
  height: 'auto',

  '& .toolbar-container': {
    marginRight: 'auto',
  },

  '& .conflicts': {
    display: 'flex',
    gap: 8,
    // fontWeight: 'bold',
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: '0.15em',
    borderRight: '1px solid $colors$white-alpha-10',
    paddingRight: 8,
    '& span': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
  },
  '& .merge-button': {
    marginRight: 'auto',
  },

  '& .alerts-nav': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: '$colors$gray-100',
    fontSize: 11,
    textTransform: 'uppercase',
    letterSpacing: '0.15em',
  },
});

const AlertCount = styled('span', {
  color: '#f7c70a',
  fontWeight: 'bold',
  variants: {
    presentation: {
      none: {
        color: '$colors$green-500',
      },
      pending: {
        color: '$colors$red-300',
      },
      flagged: {
        color: '$colors$teal-500',
      },
    },
  },
});

const CurrentAlertIndex = styled('span', {
  width: '30px',
  // variants: {
  //   none: {
  //     true: {
  //       color: 'white',
  //     },
  //   },
  // },
});

// const mergeScriptViewMap: {
//   [K in ProjectTask]: () => JSX.Element;
// } = {
//   translation: MergeScriptView,
//   structural: StructuralMergeScriptView,
// };

export const TopBarComponent = observer(() => {
  const modal = useTouchupEditorModal();
  const showMergeButton = modal.enableMerge;
  console.log('rendering top bar');
  // const conflictCount = modal.mergeConflictsCount;
  // const missingCount = modal.missingCount;
  const incompleteCount = modal.pendingCount;
  const samosaFlaggedCount = modal.samosaFlaggedCount;
  // const masalaFlaggedCount = modal.masalaFlaggedCount;
  const alertNumber = modal.currentAlertNumber + 1;
  const alertNumberStr = alertNumber ? alertNumber.toString() : '_';
  // const totalAlerts = conflictCount + missingCount + samosaFlaggedCount; // TODO
  const totalAlerts = incompleteCount + samosaFlaggedCount;
  const canHaveAlerts =
    modal.canHaveConflicts ||
    modal.canHaveMissingReferences ||
    modal.canHaveLintAlerts;
  return (
    <TopBar>
      <div className="toolbar-container">
        <TranslationModalToolbar editor={modal} />
      </div>
      <div className="merge-button">
        {showMergeButton && (
          <>
            <Button
              size="small"
              onClick={() => modal.saveToMasala()}
              // icon={<BiSolidArrowToLeft />}
              // presentation={'whiteTransparent'}
              testId="merge-button"
              label={'Merge'}
            />
          </>
        )}
      </div>

      {canHaveAlerts ? (
        <>
          <div className="conflicts">
            <span>
              Pending
              <AlertCount
                presentation={incompleteCount === 0 ? 'none' : 'pending'}
              >
                {incompleteCount}
              </AlertCount>
            </span>
            {/* <span>
              Conflicts
              <AlertCount none={!conflictCount}>{conflictCount}</AlertCount>
            </span>
            <span>
              Missing
              <AlertCount none={!missingCount}>{missingCount}</AlertCount>
            </span> */}
            <span>
              Flagged
              <AlertCount
                presentation={samosaFlaggedCount === 0 ? 'none' : 'flagged'}
              >
                {samosaFlaggedCount}
              </AlertCount>
            </span>
            {/* <span>
              ( Later
              <AlertCount none={true}>{masalaFlaggedCount}</AlertCount>)
            </span> */}
          </div>
          <div className="alerts-nav">
            <div className="arrow-left">
              <IconButton
                size="small"
                onClick={() => modal.moveToPrevAlert()}
                icon={<MdArrowLeft />}
                presentation={'whiteTransparent'}
                testId="alerts-nav-left"
              />
            </div>
            <div className="numbers">
              <CurrentAlertIndex> {alertNumberStr} of </CurrentAlertIndex>{' '}
              {totalAlerts}
            </div>
            <div className="arrow-right">
              <IconButton
                size="small"
                onClick={() => modal.moveToNextAlert()}
                icon={<MdArrowRight />}
                presentation={'whiteTransparent'}
                testId="alerts-nav-right"
              />
            </div>
          </div>
        </>
      ) : null}
    </TopBar>
  );
});
