import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { TagData } from '@masala-lib/catalog/catalog-types';
import { useTag } from './use-tag';
import { TagForm } from './tag-form';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';

interface TagEditParams {
  stopEditing: () => void;
}

export const TagEditScreen = observer(({ stopEditing }: TagEditParams) => {
  const manager = TagManager.getInstance();
  const tag = useTag();

  if (!tag) {
    return null;
  }

  const onSubmit = (dto: TagData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    tag.fromFormData(dto);
    manager.update(tag).then(stopEditing);
  };

  return (
    <Page title={`Editing ${tag.name}`}>
      <TagForm
        dto={tag.toFormData()}
        onSubmit={onSubmit}
        onCancel={stopEditing}
      />
    </Page>
  );
});
