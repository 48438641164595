import { get, Controller } from 'react-hook-form';
import { InputStyleWrapper as Wrapper } from './input-style-wrapper';
import cx from 'classnames';
import { useUniqueId } from '../../lib/hooks/use-unique-id';
import { InputProps } from './input';
import { ErrorMessage } from './error-message';
import ReactSelect from 'react-select';
import { isArray } from 'lodash';

export interface SelectItem {
  label: string;
  value: string;
}

export type SelectProps<T> = InputProps<T> & {
  options: Array<SelectItem>;
  isMulti?: boolean;
};

export function Select<T>({
  name,
  label,
  formContext,
  required = false,
  options,
  hint,
  isMulti = false,
}: SelectProps<T>) {
  const {
    control,
    formState: { errors },
  } = formContext;

  const id = useUniqueId();
  const validationRules = { required };
  const error = get(errors, name, null);

  return (
    <Controller
      control={control}
      name={name}
      rules={validationRules}
      render={({ field: { onChange, value, ref } }) => {
        // console.log({ value });
        return (
          <Wrapper className={cx({ 'with-error': !!error })}>
            <label htmlFor={id}>{label}</label>
            <ReactSelect
              id={id}
              inputRef={ref}
              options={options}
              isMulti={isMulti}
              value={options.find(c => c.value === value)}
              defaultValue={value as any}
              onChange={(val: any) => {
                if (isArray(val)) {
                  onChange(val);
                } else {
                  onChange(val.value);
                }
              }}
            />

            <ErrorMessage name={name} error={error} />
            {hint ? <div className="hint">{hint}</div> : null}
          </Wrapper>
        );
      }}
    />
  );
}
