import { createLogger } from '@app/logger';
import { LocaleCode } from '@utils/util-types';
import { isEmpty } from 'lodash';

const log = createLogger('deploymet-config');

const consoleUrl = process.env.REACT_APP_CONSOLE_URL || 'http://localhost:4000';

const firebaseConfigKey =
  process.env.REACT_APP_FIREBASE_CONFIG_KEY || 'devtest';

const isDevtest = firebaseConfigKey === 'devtest';
// const isDevtest = false; // TEMP local testing

export const deploymentConfig = {
  firebaseConfigKey,

  // TODO: remove the need for separate bucket config (manual permission issue?)
  gcsAudioStorageBucket:
    process.env.REACT_APP_GCS_AUDIO_STORAGE_BUCKET || 'jw-devtest',

  gcsFirebaseBucket:
    process.env.REACT_APP_GCS_FIREBASE_BUCKET || 'jw-devtest-pub',

  // when true, then group media from all channels into a base 'dev' folder, otherwise segregate by channel slug.
  // allows better caching of ingestion assets for testing but better segregation for editorial deployment
  isDevtest,
  // defaultStorageSilo:
  //   process.env.REACT_APP_DEFAULT_STORAGE_SILO || isDevtest ? 'dev' : null,

  consoleUrl,
  // these are just aliases for 'consoleUrl' now that we've unified the apps
  scriptEditorUrl: consoleUrl,
  chaatUrl: consoleUrl,
  reviewToolUrl: consoleUrl,
  // scriptEditorUrl: process.env.REACT_APP_SCRIPT_EDITOR_URL || 'http://localhost:4100',
  // chaatUrl: process.env.REACT_APP_CHAAT_URL || 'http://localhost:4200',
  // reviewToolUrl: process.env.REACT_APP_REVIEW_TOOL_URL || 'http://localhost:4300',

  spaBaseUrl:
    process.env.REACT_APP_SPA_BASE_URL ||
    /*'http://localhost:3100',*/ 'https://editorial.jiveworld.app',

  masalaServerUrl:
    process.env.REACT_APP_MASALA_SERVER_URL || 'http://localhost:4500',
  falconServerUrl:
    process.env.REACT_APP_FALCON_SERVER_URL || 'http://localhost:4600',
  railsServerUrl:
    process.env.REACT_APP_RAILS_SERVER_URL || 'http://localhost:3000',

  // faunadbSecret:
  //   process.env.REACT_APP_FAUNADB_SECRET ||
  //   'fnAENPkFEnACQahQArJBLOnLk5wKebc3jkdBAu5H', // devtest-masala
  slackApiToken:
    process.env.REACT_APP_SLACK_API_TOKEN ||
    'xoxb-155822064134-1365738549143-y1ZXZZdDwjcedDCBKo6mF4qi',
  slackConversationsChannel:
    process.env.REACT_APP_SLACK_CONVERSATIONS_CHANNEL || 'masala-testing-feed',
  // these are used once to generate the token
  slackClientId: process.env.REACT_APP_SLACK_CLIENT_ID,
  slackClientSecret: process.env.REACT_APP_SLACK_CLIENT_SECRET,

  // bugsnagApiKeyConsole: process.env.REACT_APP_BUSGSNAG_APIKEY || null,
  bugsnagApiKeyServer: process.env.EXPRESS_APP_BUSGSNAG_APIKEY || null,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || null,
  errorReportingEnabled:
    process.env.REACT_APP_ERROR_REPORTING_ENABLED === 'true',

  linearApiKey:
    process.env.REACT_APP_LINEAR_API_KEY ||
    'lin_api_rkSY1WL8yKZ1cKG6b4NvLck5Fcy6JzpAAeTbbiwG',
  linearEditorialTeamId:
    process.env.REACT_APP_LINEAR_TEAM_ID ||
    'ee5e0373-b663-49b8-81d7-930e1b9ac3d1', // fake editorial prod

  // suppressAutoExport: false,
  suppressAutoExport: true, // tmp, makes testing faster

  // 'simulation' can be set into your local.env for UI testing
  defaultLlmModelKey:
    process.env.REACT_APP_DEFAULT_LLM_MODEL_KEY || 'openai/gpt-4-1106-preview', // 'openai/gpt-4-32k',
};

export const redactedConfig = () => {
  const { slackApiToken, /*faunadbSecret,*/ ...rest } = deploymentConfig;
  return rest; // todo include redacted tokens
};

log.info(JSON.stringify(deploymentConfig));

// type ConfigKey = keyof typeof deploymentConfig;
// export const getDeploymentConfig = (key: ConfigKey): string => {
//   // console.log(`getDeploymentConfig(${key}): ${JSON.stringify(deploymentConfig[key])}`);
//   return deploymentConfig[key];
// };

export const volumePreviewL2Url = (dataUrl: string, l2: LocaleCode): string => {
  if (isEmpty(dataUrl)) return null;
  return `${deploymentConfig.spaBaseUrl}/${l2}/masala-preview?url=${dataUrl}`;
};

export const soundbitePreviewL2Url = (
  dataUrl: string,
  l2: LocaleCode
): string => {
  if (isEmpty(dataUrl)) return null;
  return `${deploymentConfig.spaBaseUrl}/${l2}/admin/preview?url=${dataUrl}`;
};

export const gcsConsoleUrl = (path: string) => {
  const bucket = deploymentConfig.gcsFirebaseBucket;
  return `https://console.cloud.google.com/storage/browser/${bucket}/${path}`;
};
