import { TagType } from './../models/tag';
import { TagData } from '../catalog-types';
import { Tag } from '../models/tag';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { sortBy } from 'lodash';
import { StringToString } from '@tikka/basic-types';

// type TagTypes =

// export const topicTags = {
//   conflict: 'Conflict',
//   conversation: 'Conversation',
//   digital: 'Digital Culture',
//   econ: 'Economics',
//   education: 'Education',
//   environment: 'Environment',
//   family: 'Family',
//   fiction: 'Fiction',
//   food: 'Food',
//   'free-express': 'Freedom of Expression',
//   friendship: 'Friendship',
//   health: 'Health',
//   'history-mem': 'History and Memory',
//   language: 'Language',
//   legal: 'Legal Proceedings',
//   lgbt: 'LGBTQI+',
//   literature: 'Literature',
//   migration: 'Migration',
//   music: 'Music',
//   'nat-disasters': 'Natural Disasters',
//   essay: 'Personal Essays',
//   politics: 'Politics',
//   racism: 'Racism',
//   religion: 'Religion',
//   science: 'Science',
//   sexuality: 'Sexuality',
//   sports: 'Sports',
//   uplifting: 'Uplifting',
//   urbanism: 'Urbanism',
//   violence: 'Violence',
//   women: "Women's Rights",
//   worker: "Workers' Rights",
// };

// export const testCountryTags = {
//   arg: 'Argentina',
//   bol: 'Bolivia',
//   bra: 'Brazil',
// };

// export const countryTags = {
//   arg: 'Argentina',
//   bol: 'Bolivia',
//   bra: 'Brazil',
//   chl: 'Chile',
//   col: 'Colombia',
//   cri: 'Costa Rica',
//   cub: 'Cuba',
//   dnk: 'Denmark',
//   dom: 'Dominican Republic',
//   ecu: 'Ecuador',
//   slv: 'El Salvador',
//   deu: 'Germany',
//   gtm: 'Guatemala',
//   hti: 'Haiti',
//   hnd: 'Honduras',
//   jam: 'Jamaica',
//   jpn: 'Japan',
//   mex: 'Mexico',
//   nic: 'Nicaragua',
//   pan: 'Panama',
//   pry: 'Paraguay',
//   per: 'Peru',
//   prt: 'Portugal',
//   pri: 'Puerto Rico',
//   esp: 'Spain',
//   gbr: 'United Kingdom',
//   usa: 'United States',
//   ury: 'Uruguay',
//   ven: 'Venezuela',
// };

// export const apTags = {
//   'ap-famcom': 'Families and Communities',
//   'ap-scitech': 'Science and Technology',
//   'ap-beauty': 'Beauty and Aesthetics',
//   'ap-contemp': 'Contemporary Life',
//   'ap-global': 'Global Challenges',
//   'ap-identity': 'Personal and Public Identities',
// };

// export const ibTags = {
//   'ib-identity': 'Identities',
//   'ib-experience': 'Experiences',
//   'ib-ingenuity': 'Human Ingenuity',
//   'ib-socorg': 'Social Organization',
//   'ib-planet': 'Sharing the Planet',
// };

export class TagManager extends EntityManager<Tag, TagData> {
  get collectionName(): string {
    return CatalogCollections.TAG_METADATA;
  }

  createModel(data: {
    id: string;
    name: string;
    slug: string;
    tagType: TagType;
  }): Tag {
    return new Tag(data);
  }

  // async initializeTags() {
  //   await this.initializeTagSet('country', countryTags);
  //   await this.initializeTagSet('topic', topicTags);
  //   await this.initializeTagSet('ap', apTags);
  //   await this.initializeTagSet('ib', ibTags);
  // }

  async initializeTagSet(tagType: TagType, data: StringToString) {
    for (const entry of Object.entries(data)) {
      const slug = entry[0];
      const name = entry[1];
      console.log(`slug: ${slug}, name: ${name}`);
      const existing = this.getByTypeSlug(tagType, slug);
      if (existing) {
        if (slug !== existing.slug || name !== existing.name) {
          // TODO remove these direct assignments to data?
          existing.data.slug = slug;
          existing.data.name = name;
          console.log(`updating existing id: ${existing.id}`);
          await this.update(existing);
        } else {
          console.log(`skipping existing match: ${existing.id}`);
        }
      } else {
        console.log(`creating new tag: ${slug}, ${name}`);
        const dto = { id: '', slug, name, tagType };
        this.createWithDto(dto);
      }
    }
  }

  async removeAllTagsByType(tagType: TagType) {
    const tags = this.tagsByType(tagType);
    for (const tag of tags) {
      await this.delete(tag);
    }
  }

  /*
   * Getters
   * ===========================
   */

  tagsBySlugs(tagSlugs: string[]) {
    return this.list.filter(tag => {
      return tagSlugs?.includes(tag.data.slug);
    });
  }

  tagsByTypeSlugs(tagType: TagType, tagSlugs: string[]) {
    return this.tagsByType(tagType).filter(tag => {
      return tagSlugs?.includes(tag.data.slug);
    });
  }

  tagsByType(tagType: TagType) {
    return this.list
      .filter(tag => tag.data.tagType === tagType)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  getByTypeSlug(tagType: TagType, slug: string): Tag {
    return this.rawList.find(
      model => model.slug === slug && model.data.tagType === tagType
    );
  }

  get tableList() {
    return sortBy(this.list, ['data.tagType', 'name']);
  }

  get apTags() {
    return this.tagsByType('ap');
  }

  get ibTags() {
    return this.tagsByType('ib');
  }

  // get countryTags() {
  //   return this.tagsByType('country');
  // }

  // get topicTags() {
  //   return this.tagsByType('topic');
  // }

  getTagOptions(byType?: TagType) {
    const list = byType ? this.tagsByType(byType) : this.list;
    return list.map(item => {
      return {
        label: item.name,
        value: item.data.slug,
      };
    });
  }

  private static instance: TagManager;

  public static getInstance(): TagManager {
    if (!TagManager.instance) {
      TagManager.instance = new TagManager();
      TagManager.instance.listenAll();
    }
    return TagManager.instance;
  }
}
