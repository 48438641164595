import { IndexRange, NO_INDEX } from '@tikka/basic-types';
import { Word, WordGroup, ElementList } from '../../editor-aliases';
import { WordGroupSubKind } from '../../element-kinds';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { MembershipReconciler } from '@tikka/membership-reconciliation/membership-reconciler';

const wordOpening = '<span class="word ';
const wordClosing0 = '</span>';
const wordClosing = ' ' + wordClosing0;
const transitionClosing = wordClosing0;
const wordGroupOpening = '<span class="word-group ';
const wordGroupClosing = '</span> ';
export type WordGroupKindToString = {
  [index in WordGroupSubKind]: string;
};

const wordGroupKindStyles: WordGroupKindToString = {
  VOCAB: ' vocab ',
  TRICKY: ' tricky ',
  SIC: ' sic ',
};

export function renderWordRange(
  domScope: string,
  styleOverlays: MembershipReconciler,
  wordElements: ElementList<Word>,
  indexRange: IndexRange,
  wordGroups: ElementList<WordGroup>
) {
  let html = '';
  const words = wordElements.values;
  const wordGroupIntervals = wordGroups.wordIntervals;
  let wordGroupStartIndex = NO_INDEX;
  let wordGroupEnds = false;
  let nestLevel = 0;
  for (let i = indexRange.begin; i <= indexRange.end; i++) {
    const word = words[i];
    const text: string = (word as any).text; // TODO
    const wordId = word.id;
    const domId = elementIdToDomId(domScope, wordId);
    const wordStyles: string =
      styleOverlays.getJoinedMembershipStringForAddress(i) +
      ' ' +
      (word as any).style;
    wordGroupStartIndex = wordGroupIntervals.startsAt(i);
    if (wordGroupStartIndex !== NO_INDEX) {
      if (nestLevel > 0) {
        wordGroupStartIndex = NO_INDEX;
      }
      nestLevel++;
    }
    const wordGroupEndsIndex = wordGroupIntervals.endsAt(i);
    wordGroupEnds = wordGroupEndsIndex !== NO_INDEX;
    if (wordGroupEnds) {
      if (nestLevel > 1) {
        wordGroupEnds = false;
      }
      nestLevel--;
    }

    // TODO same logic as script editor but concerned about bounds, check
    if (wordGroupStartIndex !== NO_INDEX) {
      html += wordGroupOpening;
      const wordGroup = wordGroups.values[wordGroupStartIndex];
      const wordGroupId = wordGroup.id;
      const wordGroupKindStyle: string = wordGroupKindStyles[wordGroup.subKind];
      const wordGroupStyles =
        ' ' + styleOverlays.getJoinedMembershipStringForElement(wordGroupId);
      html +=
        wordGroupKindStyle +
        wordGroupStyles +
        '" id="' +
        elementIdToDomId(null, wordGroupId) +
        '" >';
    }
    html += wordOpening + wordStyles + '" id="' + domId + '" >';
    html += text;
    html += wordGroupEnds ? transitionClosing + wordGroupClosing : wordClosing;
  }
  return html;
}
