import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { stepTypeMap } from './step-type-map';

export const StepListChronological = observer(() => {
  const model = useSamosaModel();

  return (
    <>
      {model.sidebarExchanges.map(step => {
        const ItemComponent = stepTypeMap[step.kind];
        return <ItemComponent key={step.id} step={step} />;
      })}
    </>
  );
});
