import { DbPaths } from './db';
import { db } from '../../../platform/firebase-init';
import { isEmpty } from 'lodash';
import { ElementIdToString, StringToElement } from '@tikka/basic-types';
import {
  AuditLogDoc,
  EpisodeStructuralDoc,
  EpisodeTranslationDoc,
  EpisodeVerbatimDoc,
  EpisodeWordGroupDoc,
  WordGroupVersionsDoc,
} from './firestore-doc-types';
import { notEmpty } from '@utils/conditionals';
import {
  SentenceVersionData,
  StorageSentence,
  StorageStructural,
  StorageTranslation,
  StorageWordGroup,
  StructuralVersionData,
  TranslationVersionData,
  WordGroupVersionData,
} from '../../editorial-types';

export async function loadSentenceVersionsDoc(key: string) {
  const paths = new DbPaths(db, key);
  const docRef = paths.sentenceVersionsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadWordGroupVersionsDoc(key: string) {
  const paths = new DbPaths(db, key);
  const docRef = paths.wordGroupVersionsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadStructuralVersionsDoc(key: string) {
  const paths = new DbPaths(db, key);
  const docRef = paths.structuralVersionsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadTranslationsVersionsDoc(key: string) {
  const paths = new DbPaths(db, key);
  const docRef = paths.translationVersionsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadVerbatimDoc(
  key: string
): Promise<EpisodeVerbatimDoc> {
  const paths = new DbPaths(db, key);
  const docRef = paths.verbatimDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadWordGroupsDoc(
  key: string
): Promise<EpisodeWordGroupDoc> {
  const paths = new DbPaths(db, key);
  const docRef = paths.wordGroupsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadStructuralDoc(
  key: string
): Promise<EpisodeStructuralDoc> {
  const paths = new DbPaths(db, key);
  const docRef = paths.structuralDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadTranslationsDoc(
  key: string
): Promise<EpisodeTranslationDoc> {
  const paths = new DbPaths(db, key);
  const docRef = paths.translationsDocRef;
  let doc = await docRef.get();
  return doc.data();
}

export async function loadAuditLogDoc(key: string): Promise<AuditLogDoc> {
  const paths = new DbPaths(db, key);
  const docRef = paths.auditLogDocRef;
  let doc = await docRef.get();
  return doc.data();
}

function buildVersionsUpdate<VT, MT>(
  newVersions: VT,
  activeVersionIdMapDelta: MT
) {
  const data: { items?: VT; activeVersionIdMap?: MT } = {};
  if (notEmpty(newVersions)) {
    data.items = newVersions;
  }
  if (notEmpty(activeVersionIdMapDelta)) {
    data.activeVersionIdMap = activeVersionIdMapDelta;
  }
  if (isEmpty(data)) {
    return null;
  }
  return data;
}

export async function updateSentenceVersions(
  key: string,
  // newVersions: StringToElement<VersionableElement>
  newVersions: StringToElement<SentenceVersionData>,
  activeVersionIdMapDelta: ElementIdToString
) {
  const data = buildVersionsUpdate(newVersions, activeVersionIdMapDelta);
  if (isEmpty(data)) {
    return;
  }
  const paths = new DbPaths(db, key);
  const docRef = paths.sentenceVersionsDocRef;
  return docRef.set(data, { merge: true });
}

export async function updateWordGroupVersions(
  key: string,
  // newVersions: StringToElement<VersionableElement>
  newVersions: StringToElement<WordGroupVersionData>,
  activeVersionIdMapDelta: ElementIdToString
) {
  const data = buildVersionsUpdate(newVersions, activeVersionIdMapDelta);
  if (isEmpty(data)) {
    return;
  }
  const paths = new DbPaths(db, key);
  const docRef = paths.wordGroupVersionsDocRef;
  return docRef.set(data, { merge: true });
}

export async function updateStructuralVersions(
  key: string,
  // newVersions: StringToElement<VersionableElement>
  newVersions: StringToElement<StructuralVersionData>,
  activeVersionIdMapDelta: ElementIdToString
) {
  const data = buildVersionsUpdate(newVersions, activeVersionIdMapDelta);
  if (isEmpty(data)) {
    return;
  }
  const paths = new DbPaths(db, key);
  const docRef = paths.structuralVersionsDocRef;
  return docRef.set(data, { merge: true });
}

export async function updateTranslationVersions(
  key: string,
  // newVersions: StringToElement<VersionableElement>
  newVersions: StringToElement<TranslationVersionData>,
  activeVersionIdMapDelta: ElementIdToString
) {
  const data = buildVersionsUpdate(newVersions, activeVersionIdMapDelta);
  if (isEmpty(data)) {
    return;
  }
  const paths = new DbPaths(db, key);
  const docRef = paths.translationVersionsDocRef;
  return docRef.set(data, { merge: true });
}
