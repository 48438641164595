import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { VideoGuideData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { VideoGuideForm } from './video-guide-form';
import { VideoGuideManager } from '@masala-lib/catalog/db/video-guide-manager';
import { VideoGuide } from '@masala-lib/catalog/models/video-guide';
import { useNavigate } from 'react-router-dom';

export const VideoGuideCreateScreen = observer(() => {
  const manager = VideoGuideManager.getInstance();
  const navigate = useNavigate();
  const model = new VideoGuide();

  const goBack = () => {
    navigate(`/video-guides`);
  };

  const onSubmit = (dto: VideoGuideData) => {
    console.group('Creating video guide');
    console.log('From data', dto);
    model.fromFormData(dto);
    manager.create(model).then(goBack);
    console.groupEnd();
  };

  return (
    <Page title={'Creating new video guide'}>
      <OnePaneLayout>
        <VideoGuideForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
        />
      </OnePaneLayout>
    </Page>
  );
});
