import { isEmpty, pick } from 'lodash';
import {
  VideoGuideData,
  VIDEO_GUIDE_GCS_FOLDER,
} from '@masala-lib/catalog/catalog-types';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { Checkbox, FormButtons, Input } from '../../components/forms';
import { A } from '@masala-lib/editorial/ui/action-link';

interface Props {
  dto: VideoGuideData;
  onSubmit: (dto: VideoGuideData) => void;
  onCancel: () => void;
}

export const VideoGuideForm = ({ dto, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'title',
    'slug',
    'videoIdLandscape',
    'imageUrlLandscape',
    // 'videoIdPortrait',
    'imageUrlPortrait',
    'durationSeconds',
    'position',
    'active',
  ]);

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input {...getFieldProps('title')} label={'Title'} required />
      <Input {...getFieldProps('slug')} label={'Slug'} required />
      <Input
        {...getFieldProps('videoIdLandscape')}
        label={'Landscape Video ID'}
      />
      <A href={VIDEO_GUIDE_GCS_FOLDER}>[GCS Folder]</A>
      {' - '}
      Google cloud storage folder where images are expected to be placed and
      then referenced to from here
      <Input
        {...getFieldProps('imageUrlLandscape')}
        label={'Landscape Image GCS URL'}
      />
      {/* <Input
        {...getFieldProps('videoIdPortrait')}
        label={'Portrait Video Wistia ID'}
      /> */}
      <Input
        {...getFieldProps('imageUrlPortrait')}
        label={'Portrait Image GCS URL'}
      />
      <Input
        {...getFieldProps('durationSeconds')}
        label={'Duration (seconds)'}
      />
      <Input
        {...getFieldProps('position')}
        label={'Position (tentative sorting key)'}
      />
      <Checkbox {...getFieldProps('active')} label="Active"></Checkbox>
      <FormButtons
        submitLabel={isNew ? 'Create' : 'Save changes'}
        onCancel={onCancel}
      />
    </form>
  );
};
