import * as React from 'react';
import styled from 'styled-components';

const chevronDown = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.88 9.29L12 13.17L8.11998 9.29C7.72998 8.9 7.09998 8.9 6.70998 9.29C6.31998 9.68 6.31998 10.31 6.70998 10.7L11.3 15.29C11.69 15.68 12.32 15.68 12.71 15.29L17.3 10.7C17.69 10.31 17.69 9.68 17.3 9.29C16.91 8.91 16.27 8.9 15.88 9.29Z"
      fill="currentColor"
      fillOpacity="0.54"
    />
  </svg>
);

const chevronUp = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.29 8.70998L6.69997 13.3C6.30997 13.69 6.30997 14.32 6.69997 14.71C7.08997 15.1 7.71997 15.1 8.10997 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.70998C12.32 8.31998 11.68 8.31998 11.29 8.70998Z"
      fill="currentColor"
      fillOpacity="0.54"
    />
  </svg>
);

const Wrapper = styled.div`
  & > button {
    --button-background-color: var(--color-transparent);
    --button-color: var(--color-gray500);
    --button-border-color: var(--color-gray100);
    display: flex;
    background: none;
    background: var(--button-background-color);
    border: 1px solid var(--button-border-color);
    color: var(--button-color);
    padding: 4px 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    border-radius: 4px;
    outline: none;

    &:hover {
      --button-background-color: var(--color-gray50);
      --button-color: var(--color-gray600);
    }

    & > .btn-icon {
      color: var(--color-gray200);
      width: 20px;
      height: 20px;
    }
  }
`;

type PopButtonProps = {
  label: React.ReactNode;
  onClick: () => void;
  isOpen: boolean;
};

export const PopButton = ({ label, onClick, isOpen }: PopButtonProps) => {
  return (
    <Wrapper>
      <button onClick={onClick}>
        <span className="btn-label">{label}</span>
        <span className="btn-icon">{isOpen ? chevronUp : chevronDown}</span>
      </button>
    </Wrapper>
  );
};
