import { stripUnderscores } from '@utils/content-string-utils';
import { StringToString } from '@utils/util-types';
import {
  ElementId,
  WordIdRange,
  IndexRange,
  NO_INDEX,
  WordId,
  WordElement,
  ElementList as BaseElementList,
} from '@tikka/basic-types';
import {
  Element,
  ElementList,
  Sentence,
  Word,
  WordGroup,
} from './editor-aliases';
import { EKind, isStructuralElement } from './element-kinds';
import {
  onlyFirstCapitalized,
  stripAllPunctuation,
  trimWordGroupUsagePunctuation,
} from './misc/editorial-string-utils';
import {
  ElementIdToTranslation,
  ElementVersionData,
  SpanAnchor,
  StorageWordGroup,
} from './editorial-types';
import { getWordRecordsData } from './editorial-word-element-list';

// // TODO a good many of these functions work on the editorial models using wordId anchor but could have corresponding impls using word indexes

export function wordIndexRangeToWordStrings(
  indexRange: IndexRange,
  words: ElementList<Word>
) {
  // TODO strong typing for this instead?
  const wordElements = words.values;
  const result: string[] = [];
  // TODO create better way to iterate or map number range?
  for (let i = indexRange.begin; i <= indexRange.end; i++) {
    result.push(wordElements[i].text);
  }
  return result;
}

export function wordIdRangeToWordStrings(
  idRange: WordIdRange,
  words: BaseElementList<Word>
): string[] {
  // TODO stronger typing?
  const wordsRange = words.idRangeAsElements(idRange);
  return wordsRange.map(word => word['text']);
}

export function wordStringsToWordElements(
  words: string[],
  startIndex: number,
  indexToId: any
): WordElement[] {
  const wordElements: WordElement[] = [];
  for (const [index, word] of words.entries()) {
    wordElements.push({
      kind: EKind.WORD,
      id: indexToId[startIndex + index],
      text: word,
    } as WordElement);
  }
  return wordElements;
}

export function getContentStringFromWordIdMaxChars(
  wordId: WordId,
  words: ElementList<Word>,
  maxChars: number
) {
  const index = words.getIndex(wordId);
  if (index === NO_INDEX) {
    return '';
  }

  const resultWords = [];
  let charCount = 0;
  let i = index;
  const wordElements = words.values;
  while (charCount < maxChars && i < wordElements.length) {
    const word = wordElements[i];
    // TODO strong typing?
    const text: string = word['text'];
    resultWords.push(text);
    charCount += text.length;
    i++;
  }

  return resultWords.join(' ');
}

export function getSentenceWordIdRange(
  sentence: Sentence,
  words: ElementList<Word>
): WordIdRange {
  // TODO make generic handle both inclusive and exclusive with sniff of anchor properties??
  const anchor = sentence.anchor;
  const startWordId = anchor.wordId;
  let endWordId;
  if (sentence.endAddress === -1) {
    // endWordId = words.__wordRecordsData.allWordRecords[0].id; //first bookend
    endWordId = getWordRecordsData(words).allWordRecords[0].id; //first bookend
  } else {
    endWordId = words.values[sentence.endAddress].id;
  }
  return { begin: startWordId, end: endWordId } as WordIdRange;
}

export function getSentenceWordStrings(
  sentence: Sentence,
  words: ElementList<Word>
): string[] {
  const wordRange = getSentenceWordIdRange(sentence, words);
  return wordIdRangeToWordStrings(wordRange, words);
}

export function getSentencesWordStrings(
  sentences: ElementList<Sentence>
): string[] {
  const result = [];
  for (const sentence of sentences.values) {
    result.push(...getSentenceWordStrings(sentence, sentences.words));
  }
  return result;
}

export function getElementEditableContentString(
  element: Element,
  words: ElementList<Word>
): string {
  // TODO use for word groups also to get transcript content string?
  if (element.kind === EKind.SENTENCE) {
    return getSentenceWordStrings(element, words).join(' ');
  } else if (
    element.kind === EKind.METADATA_BLOCK ||
    element.kind === EKind.TRANSLATION
  ) {
    return element.content as string;
  } else if (isStructuralElement(element)) {
    return element.content.text;
  } else if (element.kind === EKind.WORD_GROUP) {
    throw new Error('word groups do not have editable content');
  }
}

export function getWordGroupTranscriptText(
  group: StorageWordGroup,
  words0: ElementList<Word>,
  normalize = false
): string {
  // const wordRecordsData = words0.__wordRecordsData;
  const wordRecordsData = getWordRecordsData(words0);
  const getIndex = wordRecordsData.getIndexActive;
  const anchor = <SpanAnchor>group.anchor;
  const startIndex = getIndex(anchor.wordId);
  const endWordId = anchor.endWordId;
  let index0 = getIndex(endWordId);
  if (wordRecordsData.isRemapped(endWordId) && index0 > 0) {
    index0--;
  }
  const wordRange = { begin: startIndex, end: index0 };
  let words = wordIndexRangeToWordStrings(wordRange, words0).join(' ');
  if (normalize) {
    words = stripAllPunctuation(words.toLowerCase());
  }
  return words;
}

export function getWordGroupUsageText(
  group: StorageWordGroup,
  words0: ElementList<Word>
) {
  if (group.content.usage && group.content.usage.trim().length > 0) {
    return group.content.usage.trim();
  }
  let text = stripUnderscores(getWordGroupTranscriptText(group, words0));
  if (onlyFirstCapitalized(text) && !group.content.preserveCase) {
    text = text.toLocaleLowerCase();
  }
  return trimWordGroupUsagePunctuation(text);
}

const wordGroupKindSymbols: StringToString = {
  VOCAB: '',
  TRICKY: '~',
  SIC: '$',
};

export function getWordGroupJWScriptRepresentation(
  group: StorageWordGroup,
  words: ElementList<Word>,
  note: string
) {
  // TODO
  let result = '<';
  note = note ?? '';
  const canonical = group.content?.canonical;
  const trimTrailing = /([!,\-./;]+)\s*$/g;
  if (canonical) {
    note = `${canonical} = ${note}`;
  }
  const delimiter = note.includes('=') ? '|' : '=';
  result += wordGroupKindSymbols[group.subKind];
  result += getWordGroupTranscriptText(group, words);
  result = result.replace(trimTrailing, '');
  if (note) {
    result += delimiter + note;
  }
  result += '>';
  return result;
}

export function getElementVersionDescriptiveContent(
  element: ElementVersionData
): string {
  if (element.kind === EKind.WORD_GROUP) {
    throw new Error(
      'Word groups not handled by getElementVersionDescriptiveContent'
    );
  } else if (element.kind === EKind.SENTENCE) {
    const sentenceWords = element.words;
    return sentenceWords.map(word => word.text).join(' ');
  } else if (element.kind === EKind.TRANSLATION) {
    return element.content as string; // TODO for real
  } else if (isStructuralElement(element)) {
    return (element.content as any).text; // TODO for real
  }
}

export function getRedactedWordText(word: Word) {
  return word.text.replace(/./g, '~');
}

export function redactWord(word: Word) {
  word.text = getRedactedWordText(word);
  return word;
}
