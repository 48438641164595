import React, { useEffect } from 'react';
import { useState } from 'react';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { useParams } from 'react-router-dom';

export const ExcerptDumpScreen = () => {
  const { excerptId } = useParams<{ excerptId: string }>();

  const [data, setData] = useState<string>('loading...');

  useEffect(() => {
    ExcerptManager.getInstance()
      .loadById(excerptId)
      .then(async (excerpt: Excerpt) => {
        if (excerpt) {
          setData(excerpt.dumpScript);
        } else {
          setData(`Failed to load excerpt[${excerptId}]`);
        }
      });
  }, [excerptId]);

  return <pre>{data}</pre>;
};
