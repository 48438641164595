import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { Prompt } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { useMasalaForm } from '@console/lib/hooks/use-masala-form';
import { Checkbox, Input, Select, Textarea } from '@console/components/forms';
import { SimpleDialog } from '../components/simple-dialog';
import { KeyboardService } from 'lib/services/keyboard-service';
import { languagesKeyOptions } from './prompt-manager-modal';

// import { createLogger } from '@app/logger';
// const log = createLogger('prmopt-form');

export const PromptFormModal = ({
  prompt,
  onDismiss,
}: {
  prompt: Prompt;
  onDismiss: () => void;
}) => {
  const model = useSamosaModel();
  const formRef = React.useRef<HTMLFormElement>(null);

  const defaultValues = {
    title: prompt.title,
    description: prompt.description,
    languagesKey: prompt.languagesKey,
    scope: prompt.scope,
    text: prompt.text,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getFieldProps,
  } = useMasalaForm<typeof defaultValues>({ defaultValues });
  // const formContext = getFieldProps('title').formContext;

  const onSubmit = React.useCallback(
    async (
      data: Prompt
      // event: any /*React.BaseSyntheticEvent*/
    ) => {
      prompt.title = data.title;
      prompt.description = data.description;
      prompt.text = data.text;
      prompt.languagesKey = data.languagesKey;
      if (data.scope && !prompt.scope) {
        prompt.scope = model.unitId;
      }
      if (!data.scope && prompt.scope) {
        prompt.scope = null;
      }
      // prompt.archived = data.archived;
      await model.updatePrompt(prompt);
      onDismiss();
    },
    [model, onDismiss, prompt]
  );

  // usePauseKeyboardService();
  React.useEffect(() => {
    return KeyboardService.instance.addShortcutSet('simple', {
      escape: [onDismiss, 'Close'],
      'ctrl+enter': [
        () => {
          // submit form programaticaly
          handleSubmit(onSubmit)();
        },
        'Save',
      ],
    });
  }, [handleSubmit, onDismiss, onSubmit]);

  return (
    <SimpleDialog
      title="Saved Prompt"
      onDismiss={onDismiss}
      disableContainerDismissal
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Dialog.Body>
          <Input
            {...getFieldProps('title')}
            label="Title"
            css={{
              $$maxWidth: 'auto',
            }}
          />
          <Select
            {...getFieldProps('languagesKey')}
            label="L2/L1"
            // formContext={formContext}
            options={languagesKeyOptions}
          />
          <Textarea
            {...getFieldProps('description')}
            label="Description"
            rows={2}
            css={{
              $$maxWidth: 'auto',
            }}
          />
          <Textarea
            {...getFieldProps('text')}
            label="Prompt"
            rows={10}
            css={{
              $$maxWidth: 'auto',
            }}
          />
          <Checkbox
            {...getFieldProps('scope')}
            label="restrict visibility to project (actually unit)"
          />
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={'Cancel'}
            presentation={'grayLight'}
            type="button"
          />
          <Dialog.Button
            onClick={handleSubmit(onSubmit)}
            label={'Save'}
            presentation={'blue'}
            type="submit"
          />
        </Dialog.ButtonsContainer>
      </form>
    </SimpleDialog>
  );
};
