import * as React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

type ToolTipType = {
  children: React.ReactElement;
  tipContent: React.ReactNode;
};

export const ToolTip = ({ children, tipContent }: ToolTipType) => (
  <Tippy content={tipContent} delay={250}>
    {children}
  </Tippy>
);
