import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import { Table } from '../shared/table';
import { A } from '@masala-lib/editorial/ui/action-link';

export const ExcerptsTable = observer(({ list }: { list: Excerpt[] }) => {
  return (
    <Table
      data={list}
      cells={[
        {
          header: 'Editorial summary',
          render: model => (
            <Link to={model.navPath}>{model.name || '[blank]'}</Link>
          ),
        },
        {
          header: 'Title',
          render: model => (
            <Link to={model.navPath}>{model.data.title || '[blank]'}</Link>
          ),
        },
        {
          header: 'Status',
          render: model => model.data.status,
        },
        {
          header: 'Category',
          render: model => model.data.categories,
        },
        { header: 'Difficulty', render: model => model.data.difficulty },
        // { header: 'Date', render: model => model.data.releaseDate },
        {
          header: 'Preview',
          render: model => <A href={model.soundbitePreviewUrl}>link</A>,
        },
        {
          header: 'Volume',
          render: model => model.volume?.name,
        },
        {
          header: 'Updated At',
          render: model => uiTimeString(model.data.updatedAt),
        },
      ]}
    />
  );
});
