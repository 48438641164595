import { VideoGuideManager } from '@masala-lib/catalog/db/video-guide-manager';
import { createAllFirestoreCollections } from '../editorial/db/mutation-actions';
import { ConversationManager } from '../editorial/models/conversation-manager';
import { UserManager } from '../editorial/models/user-manager';

export async function initFirestoreCollections(): Promise<void> {
  // await createAllFirestoreCollections(); // creates all the Unit__* collections
  // await ConversationManager.getInstance().initCollection();
  await VideoGuideManager.getInstance().initCollection();

  // const userManager = UserManager.getInstance();
  // await initUsers(userManager);
}

export async function initUsers(userManager: UserManager): Promise<void> {
  await userManager.saveUser({
    alias: 'joseph',
    email: 'joseph@jiveworld.com',
    id: 'joseph',
    isAdmin: true,
    name: 'Joseph',
    slackMemberId: 'U4KQ61W5C',
  });

  await userManager.saveUser({
    alias: 'jason',
    email: 'jason@jiveworld.com',
    id: 'jason',
    isAdmin: true,
    name: 'Jason',
    slackMemberId: 'UKD92SKJP',
  });
  await userManager.saveUser({
    alias: 'armando',
    email: 'armando@jiveworld.com',
    id: 'armando',
    isAdmin: true,
    name: 'Armando',
    slackMemberId: 'UCV3C8QV8',
  });
  await userManager.saveUser({
    alias: 'nuwan',
    email: 'nuwan@jiveworld.com',
    id: 'nuwan',
    isAdmin: true,
    name: 'Nuwan',
    slackMemberId: 'U013GD5UW6M',
  });
  await userManager.saveUser({
    alias: 'daniel',
    email: 'daniel@jiveworld.com',
    id: 'daniel',
    isAdmin: true,
    name: 'Daniel',
    slackMemberId: 'U4K25S927',
  });
  await userManager.saveUser({
    alias: 'cecilia',
    email: 'cecilia@jiveworld.com',
    id: 'cecilia',
    isAdmin: true,
    name: 'Cecilia',
    slackMemberId: 'UR8FMFYCU',
  });
  await userManager.saveUser({
    alias: 'slav',
    email: 'slav@jiveworld.com',
    id: 'slav',
    isAdmin: true,
    name: 'Slav',
    slackMemberId: 'U4K25S927',
  });
  await userManager.saveUser({
    alias: 'santi',
    email: 'santi@jiveworld.com',
    id: 'santi',
    isAdmin: true,
    name: 'Santi',
    slackMemberId: 'U97RA47A8',
  });
}
