import { LinearClient, Template } from '@linear/sdk';
import { sortBy } from 'lodash';
import { Unit } from '../catalog/models/unit';
import { deploymentConfig } from '../deployment-config';
import { removeLeadingNumber } from '../utils';

export const linear = new LinearClient({
  // apiKey: getDeploymentConfig('linearApiKey'),
  apiKey: deploymentConfig.linearApiKey,
});

// const teamId = getDeploymentConfig('linearEditorialTeamId');
const teamId = deploymentConfig.linearEditorialTeamId;

async function getEditorialTeam() {
  return await linear.team(teamId);
}

async function getEditorialTemplates() {
  const allTemplates = await linear.templates;
  const teamTemplates = allTemplates.filter(template => {
    // this is private, so it's really a hack. But it works.
    // I don't want to write custom GraphQL queries if I can avoid it.
    return (template as any)._team.id === teamId;
  });

  return sortBy(teamTemplates, ['templateData.title']);
}

function getGraphqlQuery({
  title,
  teamId,
  descriptionData,
  parentId = null,
  priority = 3, // medium
  stateId = null,
}: {
  title: string;
  teamId: string;
  descriptionData: any;
  parentId?: string;
  priority: number;
  stateId?: string;
}) {
  return `
    mutation {
      issueCreate(
        input: {
          title: "${title}"
          teamId: "${teamId}"
          descriptionData: ${descriptionData}
          ${parentId ? `parentId: "${parentId}"` : ''}
          priority: ${priority}
          stateId: "${stateId}"
        }
) {
        success
        issue {
          id
          identifier
          title
          url
        }
      }
    }
`;
}

async function createIssue({
  title,
  template,
  parentId = null,
}: {
  title: string;
  template: Template;
  parentId?: string;
}) {
  const issueConfig: any = { teamId, title };
  if (parentId) {
    issueConfig.parentId = parentId;
  }

  const {
    templateData: { descriptionData, priority, stateId },
  } = template;
  // console.log('TPL', templateData);
  // templateData.title = title;

  // why are we recreating the issueConfig data structure here?
  // isn't the same data already assembled within createIssueTree()?
  issueConfig.descriptionData = JSON.stringify(JSON.stringify(descriptionData));
  issueConfig.priority = priority;
  issueConfig.stateId = stateId;

  // const result = await linear.issueCreate(issueConfig);
  const query = getGraphqlQuery(issueConfig);
  console.log(`createIssue - query: ${query}`);

  // console.log(query);
  try {
    const result: any = await linear.client.rawRequest(query, {});
    // console.log(result);

    if (result?.data?.issueCreate?.issue) {
      return result.data.issueCreate.issue;
    }

    return null;
  } catch (error) {
    throw error;
  }
}

async function attachAttachment({
  issueId,
  title,
  url,
}: {
  issueId: string;
  title: string;
  url: string;
}) {
  return await linear.attachmentCreate({
    issueId,
    title,
    url,
  });
}

async function createIssueTree({
  unit,
  master,
  subs,
}: {
  unit: Unit;
  master: Template;
  subs: Template[];
}) {
  console.group('Creating linear workflow');

  const title = unit.name;
  let url = window.location.href.replace(/workflow$/, '');

  /// Linear doesn't like localhost URLs
  url = url.replace(
    'http://localhost:4000',
    'https://masala.devtest.jiveworld.com'
  );

  console.log('Will create parent issue');
  const parentIssue = await createIssue({ title, template: master });

  if (parentIssue) {
    console.log('Parent issue created', parentIssue);

    /// attach url
    console.log('Will attach URl to parent issue', url);
    await attachAttachment({
      issueId: parentIssue.id,
      title: `${unit.name} in Masala console`,
      url,
    });

    console.log('Will start creating sub issues', url);
    for (let i = 0; i < subs.length; i++) {
      const subTemplate = subs[i];
      // console.log(`sub template data: ${JSON.stringify(subTemplate)}`);
      const subIssueConfig = {
        title: `[${unit.name}] ${removeLeadingNumber(
          subTemplate.templateData?.title as string
        )}`,
        template: subTemplate,
        parentId: parentIssue.id,
        priority: subTemplate.templateData.priority || 3, // default to medium
      };

      console.log('Will create sub issue with config', subIssueConfig);
      const subIssue = await createIssue(subIssueConfig);
      console.log('Sub issue crated:', subIssue);

      console.log('Will attach URL to subIssue');
      await attachAttachment({
        issueId: subIssue.id,
        title: 'Masala Page',
        url,
      });
      console.log('Attachment created');
    }

    console.log('Will save identifier to DB');
  }

  console.groupEnd();

  return parentIssue;
}

export const useLinear = () => {
  return {
    linear,
    getEditorialTeam,
    getEditorialTemplates,
    createIssue,
    attachAttachment,
    createIssueTree,
  };
};

(window as any).linear = linear;
