import { useEffect } from 'react';
import { KeyboardService } from './keyboard-service';

export function usePauseKeyboardService() {
  useEffect(() => {
    const restore = KeyboardService.instance.pauseCurrentShortcutSet();
    return () => {
      restore();
    };
  }, []);
}
