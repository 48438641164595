import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { SoundbiteEditionData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { SoundbiteEditionForm } from './soundbite-edition-form';
import { useSoundbiteEdition } from './use-soundbite-edition';
import { SoundbiteEditionManager } from '@masala-lib/catalog/db/soundbite-edition-manager';

interface Params {
  stopEditing: () => void;
}

export const SoundbiteEditionEditScreen = observer(
  ({ stopEditing }: Params) => {
    const manager = SoundbiteEditionManager.getInstance();
    const model = useSoundbiteEdition();

    if (!model) {
      return null;
    }

    const onSubmit = (dto: SoundbiteEditionData) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      model.fromFormData(dto);
      manager.update(model).then(stopEditing);
    };

    return (
      <Page title={`Editing ${model.name}`}>
        <OnePaneLayout>
          <SoundbiteEditionForm
            dto={model.toFormData()}
            onSubmit={onSubmit}
            onCancel={stopEditing}
          />
        </OnePaneLayout>
      </Page>
    );
  }
);
