import { runInAction } from 'mobx';
import { EpisodeDataBase } from '../../episode-data/episode-data';
import { ChaatTimestampsDoc } from '../../db/firestore-doc-types';
import { updateChaatOutputs } from '../../db/mutation-actions';
import { TimestamperEpisodeData } from './timestamper-episode-data';
import { FirestoreLoader } from '../../../firestore-db/firestore-loader';
import { ChaatDocSet } from '../../db/chaat-doc-set';
import { Timestamper } from './core/timestamper';

export async function runTimestampingWithEpisodeData(
  episodeData: EpisodeDataBase
) {
  const timestamperEpisodeData = new TimestamperEpisodeData(episodeData);
  const timestamper = new Timestamper();
  timestamper.contentSpan = timestamperEpisodeData.contentSpan;
  timestamper.transcriptSpan = timestamperEpisodeData.transcriptSpan;
  timestamper.cuesSpan = timestamperEpisodeData.cuesSpan;
  timestamper.audioRegionsSpan = timestamperEpisodeData.audioRegionsSpan;
  timestamper.forceLinearAudioIntervals =
    episodeData.forceLinearAudioRegionIntervals;
  timestamper.startTime = timestamperEpisodeData.startTime;
  timestamper.endTime = timestamperEpisodeData.endTime;
  timestamper.language = episodeData.audioLanguage;

  timestamper.run();
  const result = timestamper.getResult();
  // TODO expose these as typed values on EpisodeData?
  const scriptTimestamp: any = episodeData.verbatimDoc.timestamp;
  const cuesTimestamp: any = episodeData.cuesDoc.timestamp;
  const regionsTimestamp: any = episodeData.audioRegionsDoc.timestamp;

  // TODO update transcript time intervals if changed
  await updateChaatOutputs(
    episodeData.episodeKey,
    result as any,
    scriptTimestamp,
    cuesTimestamp,
    regionsTimestamp,
    null,
    null
  );
  return result;
}

export async function runTimestamping(episodeKey: string) {
  const loader = new FirestoreLoader(ChaatDocSet);
  loader.loadEpisode(episodeKey, false);
  const loaded = loader.promiseWhenComplete();

  const doneLoading = await loaded;
  const episodeData = new EpisodeDataBase();
  runInAction(() => {
    episodeData.episodeKey = episodeKey;
    loader.docSet.copyTo(episodeData);
  });
  const result = await runTimestampingWithEpisodeData(episodeData);
  return result;
}
