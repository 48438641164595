import { computed, makeObservable, observable, runInAction } from 'mobx';
import { isEmpty, isNil } from 'lodash';
import firebase from 'firebase';
import { epochSecondsFloat } from '../../utils';
import { db } from '../../../platform/firebase-init';
import { loaderStatus } from '../../firestore-db/constants';
import { FilterViewManager } from '../../catalog/db/catalog-entity-manager';

type CollectionReference = firebase.firestore.CollectionReference;
type QuerySnapshot = firebase.firestore.QuerySnapshot;

const { NOT_INITIATED, IN_PROGRESS, COMPLETE } = loaderStatus;

export interface AppUser {
  id: string;
  alias?: string;
  email?: string;
  name?: string;
  slackMemberId?: string; // from slack profile view, i.e. 'U4KQ61W5C'
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  isReviewer?: boolean; // external 'final review' user (i.e. carlos) will only have write access to cloned (aka 'review copy') units
  mayMakeLive?: boolean; // special flag controlly access to the catalog 'Make Live' action
  scopedChannelId?: string; // if set, then restrict access to just the specified channel
  timestamp?: number;
}

type DisposerFn = () => void;

interface ConstructorOptions {
  // todo: remove, only use load params
  queriedEmail?: string | null;
  listenMode?: boolean;
}

export class UserManager implements FilterViewManager<AppUser> {
  collectionRef: CollectionReference;
  listenMode: boolean;
  disposers: DisposerFn[] = [];
  // queriedEmail: string; // todo: can probably remote this
  singleMode: boolean; // single user query mode

  @observable.ref
  status: string = NOT_INITIATED;

  @observable.ref
  data: AppUser[] = [];

  @observable.ref
  filterText = '';

  constructor({
    queriedEmail = null,
    listenMode = true,
  }: ConstructorOptions = {}) {
    // this.queriedEmail = queriedEmail;
    this.listenMode = listenMode;
    this.singleMode = !isEmpty(queriedEmail);
    // TODO: confirm if this is actually compatible - was needed by masala-server/node build
    this.collectionRef = db.collection(
      'User__metadata'
    ) as unknown as CollectionReference;
    makeObservable(this);
  }

  // match same interface as the faunadb manager
  async create(data: AppUser): Promise<AppUser> {
    await this.saveUser(data);
    return data;
  }

  async update(data: AppUser) {
    await this.saveUser(data);
  }

  async saveUser(data: AppUser) {
    if (isEmpty(data.alias)) {
      throw new Error('alias property required');
    }
    // default the doc id to the alias if not already assigned
    if (isEmpty(data.id)) {
      data.id = data.alias;
    }
    const docRef = this.collectionRef.doc(data.id);
    data.timestamp = epochSecondsFloat();
    await docRef.set(data, { merge: true });
  }

  async deleteUser(docId: string) {
    const docRef = this.collectionRef.doc(docId);
    await docRef.delete();
  }
  async delete({ id }: { id: string }) {
    const docRef = this.collectionRef.doc(id);
    await docRef.delete();
  }

  setFilterText(text: string) {
    this.filterText = text;
  }

  loadUserByEmail(email: string, listenMode = false) {
    // this.queriedEmail = email;
    this.singleMode = true;
    this.data = [];
    this.listenMode = listenMode;
    this.load({ email });
  }

  loadUserByAlias(alias: string, listenMode = true) {
    this.singleMode = true;
    this.data = [];
    this.listenMode = listenMode;
    this.load({ alias });
  }

  // loadAll(listenMode = true) {
  //   this.singleMode = false;
  //   this.data = [];
  //   this.listenMode = listenMode;
  //   this.load({});
  // }
  // unify interface with faunadb managers
  async loadAll(): Promise<void> {
    this.singleMode = false;
    this.data = [];
    this.load({});
  }

  // assumes the full list is already loaded and returns the matching user
  findByEmail(email: string): AppUser | undefined {
    return this.data.find(user => user.email === email);
  }

  // assumes the full list is already loaded and returns the matching user
  findByAlias(alias: string): AppUser | undefined {
    return this.data.find(user => user.alias === alias);
  }

  // just returns cached instance if available, otherwise return placeholder
  // todo: and do an async load
  fetchById(id: string): AppUser {
    if (isEmpty(id)) {
      return null;
    }
    let candidate = this.cacheGet(id);
    if (isNil(candidate)) {
      // candidate = this.createModel({ id });
      candidate = { id, alias: id };

      // runInAction(() => {
      //   this.modelMap.set(id, candidate);
      // });
      // this.loadById(id); // will run async and update the already returned instance
    }
    return candidate;
  }

  cacheGet(id: string): AppUser | undefined {
    return this.data.find(dto => dto.id === id);
  }

  @computed
  get list(): AppUser[] {
    if (!this.ready) {
      return [];
    }

    if (this.singleMode) {
      throw new Error('not a list query');
    }

    if (isEmpty(this.filterText)) {
      return this.data;
    } else {
      // todo: matched against more fields
      return this.data.filter(user => user.alias.includes(this.filterText));
    }
  }

  // fetching single user by email mode
  @computed
  get single(): AppUser {
    if (!this.ready) {
      return null;
    }
    if (!this.singleMode) {
      throw new Error('not a single-user query');
    }
    if (this.data.length > 1) {
      throw new Error('unexpected multiple results');
    }
    return this.data[0];
  }

  @computed
  get slackMembers() {
    return this.list.filter(user => !isEmpty(user.slackMemberId));
  }

  // substitute all '@{alias}' strings to slack compatible mentions
  slackifyMentions(text: string) {
    var result = text;
    // console.log(`slack members: ${JSON.stringify(this.slackMembers)}`);
    this.slackMembers.forEach(user => {
      // result = result.replace(`@${user.alias}`, `<@${user.slackMemberId}>`);
      result = result.replace(
        RegExp(String.raw`@${user.alias}(?=[\s]|$)`, 'gm'),
        `<@${user.slackMemberId}>`
      );
    });
    return result;
  }

  /** @return CollectionReference or DocumentReference of data to be loaded */
  loadReference({ email, alias }: { email?: string; alias?: string } = {}) {
    if (!isEmpty(email)) {
      return this.collectionRef.where('email', '==', email);
    }
    if (!isEmpty(alias)) {
      return this.collectionRef.where('alias', '==', alias);
    }
    return this.collectionRef;
  }

  load(options = {}): void {
    runInAction(() => (this.status = IN_PROGRESS));
    this.close();

    const handleResult = (result: QuerySnapshot) => {
      console.log(`um-handleResult`);
      runInAction(() => {
        this.data = this.snapshotToData(result);
        this.status = COMPLETE;
        console.log(`UserManager - status = COMPLETE`);
      });
    };

    const queryRef = this.loadReference(options);
    if (this.listenMode) {
      const unsubscribeFn = queryRef.onSnapshot(handleResult);
      this.disposers.push(unsubscribeFn);
    } else {
      queryRef.get().then(handleResult);
    }
  }

  /**
   * @snapshot QuerySnapshot or DocumentSnapshot
   * @return model data
   */
  snapshotToData(snapshot: QuerySnapshot): AppUser[] {
    const result: AppUser[] = [];
    // todo, think about specialized handling of document specific updates callbacks
    snapshot.forEach(documentSnapshot => {
      result.push(documentSnapshot.data() as AppUser);
    });
    return result;
  }

  get ready() {
    return this.status === COMPLETE;
  }

  close() {
    for (const disposer of this.disposers) {
      disposer();
    }
    this.disposers = [];
  }

  private static instance: UserManager;

  public static getInstance(): UserManager {
    if (!UserManager.instance) {
      UserManager.instance = new UserManager();
      // todo: remove the loadAll from here. shouldn't be needed expect for the console
      UserManager.instance.loadAll();
    }
    return UserManager.instance;
  }
}
