import {
  applySnapshot,
  bindery,
  getSnapshot,
  ModelTreeNode,
  volatile,
} from '@ts-state-tree/tst-core';
import { Auth } from '@masala-lib/editorial/db/auth';
import { db } from '../../../platform/firebase-init';
import { DocumentReference } from '../../../platform/firebase-types';
import clientDataSchema from './user-client-data-schema.json';
import { notEmpty } from '@utils/conditionals';
import { createLogger } from '@app/logger';
import { isEmpty } from 'lodash';

const log = createLogger('user-client-data');

const CLIENT_DATA_COLLECTION = 'User__clientData';

interface Favorite {
  id: string;
  location: string;
  name: string;
}

export const getLegacyFavorites = () => {
  const json = window.localStorage.getItem('faves') ?? '[]';
  const result = JSON.parse(json);
  return result;
};

export class UserClientData extends ModelTreeNode {
  static CLASS_NAME = 'UserClientData' as const;
  favorites: Favorite[] = [];

  @volatile initialized = false;

  @volatile clientDataDocRef: DocumentReference<any> = null;

  static create(snapshot: any): UserClientData {
    return super.create(UserClientData, snapshot) as UserClientData;
  }

  addFavorite(favorite: Favorite): Promise<void> {
    this.favorites.push(favorite);
    return this.saveSnapshot();
  }

  removeFavorite(id: string): Promise<void> {
    const index = this.favorites.findIndex(b => b.id === id);
    this.favorites.splice(index, 1);
    return this.saveSnapshot();
  }

  getSnapshot(): void {
    this.clientDataDocRef.get().then(snapshot => {
      const data = snapshot.data();
      log.debug(`firestore client data: ${JSON.stringify(data)}`);
      if (data) {
        applySnapshot(this, data);
      }
      if (isEmpty(this.favorites)) {
        const legacyFavorites = getLegacyFavorites();
        if (notEmpty(legacyFavorites)) {
          console.log(`legacyFavorites: ${JSON.stringify(legacyFavorites)}`);
          applySnapshot(this, { favorites: legacyFavorites });
          this.saveSnapshot();
        }
      }
    });
  }

  saveSnapshot(): Promise<void> {
    return this.clientDataDocRef.set(getSnapshot(this));
  }

  start() {
    if (this.initialized) {
      return;
    }
    const auth = Auth.getInstance();
    const userId = auth.appUser.alias;
    this.clientDataDocRef = db.collection(CLIENT_DATA_COLLECTION).doc(userId);
    this.initialized = true;
    this.getSnapshot();
  }

  private static instance: UserClientData;

  public static getInstance(): UserClientData {
    if (!UserClientData.instance) {
      bindery.mergeSchemaDefinitions(clientDataSchema as any);
      bindery.bind(UserClientData);
      bindery.compileBindings();

      UserClientData.instance = UserClientData.create({});
    }
    return UserClientData.instance;
  }
}
