import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { FeaturedRelease } from '@masala-lib/catalog/models/featured-release';
import { Table } from '../shared/table';

export const FeaturedReleasesTable = observer(
  ({ list }: { list: FeaturedRelease[] }) => {
    return (
      <Table
        data={list}
        cells={[
          {
            header: 'Title',
            render: model => <Link to={model.navPath}>{model.name}</Link>,
          },
          {
            header: 'Date',
            render: model => model.data.releaseDate,
          },
          {
            header: 'Channel',
            render: model => model.channel?.name,
          },
        ]}
      />
    );
  }
);
