import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > * + * {
    margin-left: 16px;
  }
`;

interface Props {
  children: React.ReactNode;
}

export const Shelf = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
