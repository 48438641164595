import * as React from 'react';
import { get } from 'react-hook-form';
import { InputStyleWrapper as Wrapper } from './input-style-wrapper';
import cx from 'classnames';
import { useUniqueId } from '../../lib/hooks/use-unique-id';
import { InputProps } from './input';
import { ErrorMessage } from './error-message';

export type CheckboxProps<T> = InputProps<T> & {
  type?: 'checkbox';
};

export function Checkbox<T>({
  name,
  label,
  formContext,
  required = false,
  hint,
  ...inputProps
}: CheckboxProps<T>) {
  const {
    register,
    formState: { errors },
  } = formContext;

  const id = useUniqueId();
  const validationRules = { required };
  const error = get(errors, name, null);

  return (
    <Wrapper className={cx({ 'with-error': !!error })}>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id={id}
          {...register(name, validationRules)}
          {...inputProps}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      <ErrorMessage name={name} error={error} />
      {hint ? <div className="hint">{hint}</div> : null}
    </Wrapper>
  );
}
