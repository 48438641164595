import * as React from 'react';
import { NavLink, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { OnePaneLayout } from '../shared/layouts';

import styled from 'styled-components';
import { UnitsTable } from '../units/units-table';
import { VolumesTable } from '../volumes/volumes-table';
import { ChannelsTable } from '../channels/channels-table';
import { PageHeading } from '../shared/page-heading';
import { XIcon } from '../../components/icons/x-icon';
import { confirm } from '../../lib/confirm';
import { observer } from 'mobx-react';
import { Tabs } from '../../components/tabs';
import { ActivityGuidesTable } from '../activity-guides/activity-guides-table';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';
import { ChannelManager } from '@masala-lib/catalog/db/channel-manager';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';
import { Auth } from '@masala-lib/editorial/db/auth';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import { ExcerptsTable } from '../excerpts/excerpts-table';
import { ArchivedJobsList } from '../jobs/archived-jobs-list';

const Button = styled.div`
  --button-background-color: var(--color-transparent);
  --button-color: var(--color-gray500);
  --button-border-color: var(--color-gray100);

  display: flex;
  margin-left: auto;
  cursor: default;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  background: none;
  background: var(--button-background-color);
  border: 1px solid var(--button-border-color);

  padding: 4px 12px;
  text-align: center;
  border-radius: 4px;
  outline: none;

  &:hover {
    --button-background-color: var(--color-gray50);
    --button-color: var(--color-gray600);
  }

  & > .icon {
    margin-top: -1px;
    width: 16px;
    height: 16px;
    margin-right: 4px;
    color: var(--color-gray200);
  }
`;

const DeleteAllButton = ({ action = () => {}, itemsName = 'items' }) => {
  if (!Auth.getInstance().can('permadelete_items')) {
    return null;
  }
  return (
    <Button onClick={confirm(action, 'Are you sure?')}>
      <span className="icon">
        <XIcon size={18} />
      </span>
      <span className="label">Purge all archived {itemsName}</span>
    </Button>
  );
};

export const ArchiveScreen = observer(() => {
  return (
    <>
      <Tabs>
        <ul>
          <li>
            <NavLink to="/archive/units">Units</NavLink>
          </li>
          <li>
            <NavLink to="/archive/excerpts">Soundbites</NavLink>
          </li>
          <li>
            <NavLink to="/archive/volumes">Volumes</NavLink>
          </li>
          <li>
            <NavLink to="/archive/channels">Channels</NavLink>
          </li>
          <li>
            <NavLink to="/archive/activity-guides">Activity guides</NavLink>
          </li>
          <li>
            <NavLink to="/archive/jobs">Jobs</NavLink>
          </li>
        </ul>
      </Tabs>
      <OnePaneLayout>
        <Routes>
          <Route index element={<Navigate to="units" replace />} />
          <Route
            path="units"
            element={
              <>
                <PageHeading>
                  <DeleteAllButton
                    action={UnitManager.getInstance().destroyArchived}
                    itemsName="units"
                  />
                </PageHeading>
                <UnitsTable units={UnitManager.getInstance().archivedList} />
              </>
            }
          />
          <Route
            path="volumes"
            element={
              <>
                <PageHeading>
                  <DeleteAllButton
                    action={VolumeManager.getInstance().destroyArchived}
                    itemsName="volumes"
                  />
                </PageHeading>
                <VolumesTable
                  volumes={VolumeManager.getInstance().archivedList}
                />
              </>
            }
          />
          <Route
            path="channels"
            element={
              <>
                <PageHeading>
                  <DeleteAllButton
                    action={ChannelManager.getInstance().destroyArchived}
                    itemsName="channels"
                  />
                </PageHeading>
                <ChannelsTable
                  channels={ChannelManager.getInstance().archivedList}
                />
              </>
            }
          />
          <Route
            path="excerpts"
            element={
              <>
                <PageHeading>
                  <DeleteAllButton
                    action={ExcerptManager.getInstance().destroyArchived}
                    itemsName="excerpts"
                  />
                </PageHeading>
                <ExcerptsTable
                  list={ExcerptManager.getInstance().archivedList}
                />
              </>
            }
          />
          <Route
            path="activity-guides"
            element={
              <>
                <PageHeading>
                  <DeleteAllButton
                    action={ActivityGuideManager.getInstance().destroyArchived}
                    itemsName="activityGuides"
                  />
                </PageHeading>
                <ActivityGuidesTable
                  activityGuides={
                    ActivityGuideManager.getInstance().archivedList
                  }
                />
              </>
            }
          />
          <Route path="jobs" element={<ArchivedJobsList />} />
        </Routes>
        <Outlet />
      </OnePaneLayout>
    </>
  );
});
