import * as React from 'react';
import { IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { ListItem } from '../../components/list';
import { ArrowRightCircleIcon } from '@naan/icons/arrow-right-circle-icon';
import { Box } from '@naan/primitives/box';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import { MinusSmallIcon } from '@naan/icons/minus-icon';

export const Panes = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: 8,
});

const Pane = styled('div', {
  // padding: '0 12px',
  flex: 1,
});

export const LeftPane = styled(Pane, {
  // flex: 2,
  // width: 200,
});

export const RightPane = styled(Pane, {
  background: '$colors$gray-20',
  padding: 0,
  borderRadius: 8,
  // width: 200,
});

const CheckboxWrapper = styled('div', {
  $$backgroundColor: '$colors$gray-10',
  $$borderColor: '$colors$gray-300',
  $$color: '$colors$gray-400',
  $$hoverBackgroundColor: '$colors$blue-50',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '4px',
  backgroundColor: '$$backgroundColor',
  color: '$$color',
  border: '1px solid $$borderColor',
  '&:hover': {
    $$backgroundColor: '$$hoverBackgroundColor',
  },
  variants: {
    disabled: {
      true: {
        $$backgroundColor: '$colors$gray-50',
        $$borderColor: '$colors$gray-200',
        cursor: 'not-allowed',
      },
    },
    selected: {
      true: {
        // $$backgroundColor: '$colors$blue-50',
        $$color: '$colors$gray-500',
        // $$borderColor: '$colors$blue-300',
      },
    },
  },
});

type CheckboxStatus = 'checked' | 'unchecked' | 'partial' | 'disabled';

export const FilterCheckbox = ({
  status = 'unchecked',
  ...props
}: {
  status?: CheckboxStatus;
} & React.ComponentProps<typeof CheckboxWrapper>) => {
  return (
    <CheckboxWrapper
      disabled={status === 'disabled'}
      selected={status === 'checked' || status === 'partial'}
      {...props}
    >
      {status === 'checked' ? <CheckmarkHeavySmallIcon /> : null}
      {status === 'partial' ? <MinusSmallIcon /> : null}
    </CheckboxWrapper>
  );
};

export const InjectButton = ({
  onClick,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <IconButton
      icon={
        <Box
          css={{
            // rotate 90deg
            transform: 'rotate(90deg)',
          }}
        >
          <ArrowRightCircleIcon />
        </Box>
      }
      presentation="grayTransparent"
      size="small"
      onClick={onClick}
      tabIndex={-1}
      testId="inject-button"
    />
  );
};

export const InjectSectionListItem = styled(ListItem, {
  gap: 8,
  fontSize: '14px',
  userSelect: 'none',
  cursor: 'default',
  variants: {
    selected: {
      true: {
        // backgroundColor: '$colors$blue-50',
        fontWeight: 500,
      },
    },
  },
});

export const InjectAllListItem = styled(InjectSectionListItem, {
  gap: 8,
  borderBottom: '1px solid $colors$gray-100',
  paddingBottom: 8,
  '& .label': {
    fontWeight: 500,
    fontSize: '14px',
  },
});

export const OptionListItem = styled(InjectSectionListItem, {});
