import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { VideoGuideData } from '@masala-lib/catalog/catalog-types';
import { OnePaneLayout } from '../shared/layouts';
import { useVideoGuides } from './use-video-guides';
import { VideoGuideForm } from './video-guide-form';
import { VideoGuideManager } from '@masala-lib/catalog/db/video-guide-manager';

interface Params {
  stopEditing: () => void;
}

export const VideoGuideEditScreen = observer(({ stopEditing }: Params) => {
  const manager = VideoGuideManager.getInstance();
  const model = useVideoGuides();

  if (!model) {
    return null;
  }

  const onSubmit = (dto: VideoGuideData) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    model.fromFormData(dto);
    manager.update(model).then(stopEditing);
  };

  return (
    <Page title={`Editing ${model.name}`}>
      <OnePaneLayout>
        <VideoGuideForm
          dto={model.toFormData()}
          onSubmit={onSubmit}
          onCancel={stopEditing}
        />
      </OnePaneLayout>
    </Page>
  );
});
