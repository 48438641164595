import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import type { AppUser } from '@masala-lib/editorial/models/user-manager';
import { UserForm } from './user-form';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';

// handles both edit and new
// if no 'id' route param, then presents the new user form
interface Props {
  manager: FilterViewManager<AppUser>; //UserManager;
  onStopEditing: () => void;
}

export const UserEdit = observer(
  ({ manager, onStopEditing = () => {} }: Props) => {
    const { id } = useParams<{ id: string }>();
    const isNew = !id;
    const model = isNew ? { id: '' } : manager.fetchById(id);

    const onCancel = () => {
      onStopEditing();
    };

    const onSubmit = (dto: AppUser) => {
      console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
      if (isNew) {
        manager.create(dto).then(onStopEditing);
      } else {
        manager.update(dto).then(onStopEditing);
      }
    };

    return <UserForm dto={model} onSubmit={onSubmit} onCancel={onCancel} />;
  }
);
