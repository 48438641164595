import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
} from 'mdbreact';
import React, { ChangeEvent } from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { verbatimActions } from '../models/app-root';
import { ElementId } from '@masala-lib/editor-aliases';

class CreateSentenceDialogState {
  @observable open = false;
  @observable sentenceText = '';
  aboveSentenceId: ElementId;

  constructor() {
    makeObservable(this);
  }
}

const dialogState = new CreateSentenceDialogState();

export function openCreateSentenceDialog(aboveSentenceId: ElementId) {
  if (!aboveSentenceId) {
    return;
  }
  dialogState.aboveSentenceId = aboveSentenceId;
  dialogState.sentenceText = '';
  dialogState.open = true;
}

export const CreateSentenceDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
  };

  const handleSentenceTextChange = (
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    dialogState.sentenceText = event.target.value;
  };

  const handleCreate = (above: boolean) => {
    verbatimActions.createSentence(
      dialogState.sentenceText,
      dialogState.aboveSentenceId,
      above
    );
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold"
        toggle={toggleModal}
      >
        {'New Sentence'}
      </MDBModalHeader>
      <MDBModalBody>
        <form className="mx-3">
          <div className="form-group">
            <textarea
              className="form-control"
              id="translationInput"
              value={dialogState.sentenceText}
              rows={5}
              onChange={handleSentenceTextChange}
            />
          </div>
        </form>
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <MDBBtn
          color="info"
          onClick={() => {
            handleCreate(true);
            toggleModal();
          }}
        >
          Create Above
        </MDBBtn>
        <MDBBtn
          color="info"
          onClick={() => {
            handleCreate(false);
            toggleModal();
          }}
        >
          Create Below
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
});
