import * as React from 'react';
import { observer } from 'mobx-react';
import { kindMarkdownPrefix } from '@masala-lib/llm/llm-funcs';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { SimpleDialog } from '../components/simple-dialog';
import { usePauseKeyboardService } from 'lib/services/keyboard-service';
import { DialogPresenter } from '../dialog-presenter';

export const ManageSectionsDialog = ({
  onDismiss,
}: {
  onDismiss: () => void;
}) => {
  usePauseKeyboardService();
  return (
    // <ActionDialog onDismiss={onDismiss} title={'Manage sections'}>
    //   <ManageSectionsBody />
    // </ActionDialog>
    <SimpleDialog title="Manage sections" onDismiss={onDismiss}>
      <ManageSectionsBody />
    </SimpleDialog>
  );
};

export const ManageSectionsBody = observer(() => {
  const model = useSamosaModel();

  // React.useEffect(() => {
  //   if (model.sectionCount === 0) {
  //     model.ensure
  //     model.loadSectionRanges();
  //   }
  // });

  // let isFirstSentence = true;
  return (
    <>
      {model.referenceScript.elements.map((el, index) => {
        const elementText = `${kindMarkdownPrefix[el.kind]}[${el.reference}] ${
          el.text
        }`;
        const isSentence = el.kind === 'SENTENCE';
        // const isHead =
        //   isSentence && (model.isSectionHead(el.reference) || isFirstSentence);
        // if (isSentence && isFirstSentence) {
        //   isFirstSentence = false;
        // }
        const isHead = isSentence && model.isSectionHead(el.reference);

        const row = (
          <>
            <ActionLink onPress={() => model.toggleSectionHead(el.reference)}>
              {isHead ? '--' : '+'}
            </ActionLink>{' '}
            {elementText}
          </>
        );
        return (
          <div key={index}>
            {isSentence ? (
              <>
                {isHead ? (
                  <strong>
                    <br />
                    {row}
                  </strong>
                ) : (
                  row
                )}
              </>
            ) : (
              <>{el.text}</>
            )}
            {/* <br /> */}
          </div>
        );
      })}
    </>
  );
});

export const showSectionsDialog =
  DialogPresenter.makePresenter(ManageSectionsDialog);
