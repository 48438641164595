import { Alert, AlertMessages } from '../../../misc/alert-messages';
import { WordId } from '@tikka/basic-types';
import { Word, ElementList } from '../../../chaat-aliases';
import { getContentStringFromWordIdMaxChars } from '../../../content-utils';
import { swalPromptYN } from '../../../misc/sweetalert-yn';
import { MutationActions } from '../../db/mutation-actions';
import { isNil } from 'lodash';
import { notNil } from '@utils/conditionals';
import { Cue } from '../../chaat/chaat-types';

export abstract class CueActions {
  // TODO: define type for this
  lastCue = {
    wordId: null as WordId,
    timestamp: 0,
    input: false,
    navStop: false,
  };

  audioPositionWordId = 0; // TODO compute from audio pos and words entity list?

  async addChaatInputCue(): Promise<void> {
    // TODO properly deal with promise
    const insertWordId = this.cueInsertWordId;
    if (isNil(insertWordId)) {
      this.alertMessages.add({
        ...Alert,
        text: 'Error:Trying to add cue without setting cue insertion position',
      });
      return;
    }

    const audioPos = this.currentAudioPosition;

    let problematic = false;
    if (
      insertWordId === this.lastCue.wordId &&
      Math.abs(audioPos - this.lastCue.timestamp) > 1000
    ) {
      problematic = true;
    }
    if (Math.abs(audioPos - this.words.time(insertWordId)) > 1500) {
      problematic = true;
    }

    let goahead = true;

    if (problematic) {
      goahead = await swalPromptYN({
        title: 'Mistake?',
        html:
          'Current audio position seems far from cue words<br> Cue at: ' +
          "<span style='color:red;'>" +
          getContentStringFromWordIdMaxChars(insertWordId, this.words, 40) +
          '</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Add cue',
      });
    }

    if (goahead) {
      const existing =
        this.getExistingCueForWordId(insertWordId) ?? ({} as any);
      this.lastCue = {
        ...existing,
        wordId: insertWordId,
        timestamp: audioPos,
        input: true,
      };
      this.mutationActions.addUpdateCue(this.lastCue);
    }
  }

  toggleNavStop(): void {
    const wordId = this.currentTimeWordId();
    if (!wordId) {
      return;
    }
    const existing = this.getExistingCueForWordId(wordId) ?? ({} as any);
    const isNavStop =
      existing.navStop || this.activeNavStops.hasElement(wordId);
    this.mutationActions.addUpdateCue({
      ...existing,
      wordId,
      navStop: !isNavStop,
    });
  }

  getExistingCueForWordId(wordId: WordId) {
    return this.existingCues.find(cue => cue.wordId === wordId);
  }

  currentTimeWordId(): WordId {
    const currentWord = this.words.getElementContainingTime(
      this.currentAudioPosition
    );
    return currentWord?.id ?? null;
  }

  addShiftCue() {
    this.cueInsertWordId = this.currentTimeWordId();
    this.addChaatInputCue();
  }

  addShiftEndCue() {
    const currentWordId = this.currentTimeWordId();
    if (notNil(currentWordId)) {
      this.cueInsertWordId = this.words.nextId(currentWordId) as WordId;
      this.addChaatInputCue();
    }
  }

  removeCueAtCurrent(): void {
    const insertWordId = this.cueInsertWordId;
    if (isNil(insertWordId)) {
      this.alertMessages.add({
        ...Alert,
        text: 'Error:Trying to remove cue without setting cue insertion position',
      });
      return;
    }
    const existing: Cue = this.getExistingCueForWordId(insertWordId);
    if (existing) {
      this.lastCue = existing;
      this.mutationActions.removeCue(existing.wordId, true);
    }
  }

  abstract get mutationActions(): MutationActions;
  abstract get alertMessages(): AlertMessages;
  abstract get cueInsertWordId(): WordId;
  abstract set cueInsertWordId(value: WordId);
  abstract get currentAudioPosition(): number;
  abstract get words(): ElementList<Word>;
  abstract get existingCues(): Cue[]; // TODO change to map from wordId???
  abstract get activeNavStops(): ElementList<Word>; // TODO change to map from wordId???
}
