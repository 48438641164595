import {
  ScriptOptions,
  ScriptOptionsKey,
} from '@masala-lib/llm/project/llm-project-types';
import { observable } from 'mobx';

// export type SectionSelectionMap = Map<number, boolean>;
// export type ScriptInjectOptionsMap = Map<ScriptOptionsKey, boolean>;

export class ScriptInjectParams {
  sections: Map<number, boolean> = observable.map({});

  flags: Map<ScriptOptionsKey, boolean> = observable.map({
    chapters: false,
    passages: false,
    speakers: true,
    sentences: true,
    numbers: true,
    translations: false,
  });

  // constructor() {
  //   makeObservable(this);
  // }

  stringify(): string {
    return JSON.stringify({
      sections: Object.fromEntries(this.sections),
      flags: Object.fromEntries(this.flags),
    });
  }

  parse(json: string) {
    const obj = JSON.parse(json);

    // this attempt resulted in string keys instead of numberic keys
    // this.sections = observable.map(numerifyKeys<boolean>(obj.sections));

    // this didn't work either
    // const obj2 = numerifyKeys<boolean>(obj.sections);
    // console.log(obj2);

    this.sections = observable.map({});
    for (const entry of Object.entries(obj.sections)) {
      this.sections.set(Number(entry[0]), entry[1] as boolean);
    }

    this.flags = observable.map(obj.flags);
  }

  static parse(json: string): ScriptInjectParams {
    const result = new ScriptInjectParams();
    result.parse(json);
    return result;
  }

  // SECTION SELECTION
  selectSection(index: number, selected: boolean) {
    this.sections.set(index, selected);
  }

  toggleSectionSelection(index: number) {
    this.sections.set(index, !this.isSectionSelected(index));
  }

  unselectAllSections() {
    this.sections.clear();
  }

  selectAllSections(count: number) {
    for (let i = 0; i < count; i++) {
      this.sections.set(i, true);
    }
  }

  toggleAllSections(count: number) {
    if (this.anySectionsSelected) {
      this.unselectAllSections();
    } else {
      this.selectAllSections(count);
    }
  }

  isSectionSelected(index: number): boolean {
    return this.sections.get(index);
  }

  get anySectionsSelected(): boolean {
    for (const bool of this.sections.values()) {
      if (bool) {
        return true;
      }
    }
    return false;
  }

  someButNotAllSectionsSelected(count: number): boolean {
    return this.anySectionsSelected && !this.allSectionsSelected(count);
  }

  allSectionsSelected(count: number): boolean {
    const selectedAndTrue = Array.from(this.sections).filter(
      ([_, selected]) => selected
    );
    return selectedAndTrue.length === count;
  }

  get noSectionsSelected(): boolean {
    return !this.anySectionsSelected;
  }

  get defaultPickerIndex(): number {
    if (this.noSectionsSelected) {
      return 0;
    }
    const max = Math.max(...this.selectedKeys);
    return max + 1;
  }

  get selectedKeys(): number[] {
    const result = Array.from(this.sections)
      .filter(([_, selected]) => selected)
      .map(([key, _]) => key);
    return result;
  }

  // SCRIPT INJECT OPTIONS
  toggleScriptOption(key: ScriptOptionsKey) {
    this.setScriptOption(key, !this.isScriptOptionEnabled(key));
  }

  setScriptOption(key: ScriptOptionsKey, value: boolean) {
    this.flags.set(key, value);
  }

  isScriptOptionEnabled(key: ScriptOptionsKey) {
    return !!this.flags.get(key);
  }

  get selectedScriptOptions(): ScriptOptions {
    const result = Object.fromEntries(this.flags);
    return result;
  }
}
