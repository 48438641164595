import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Auth } from '@masala-lib/editorial/db/auth';
import { sniffUnitHasUpperCaseWordIds } from '@masala-lib/editorial/db/mutation-actions';
import { A, ActionLink } from '@masala-lib/editorial/ui/action-link';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';

export const DebugStuff = observer(({ unit }: { unit: Unit }) => {
  const updateAllElementVersions = async () => {
    try {
      await unit.updateAllElementVersions();
    } catch (error) {
      window.alert(error);
      throw error;
    }
  };

  const handleResetVersionData = () => {
    if (window.confirm('Are you sure?\n(note, old data will be archived)')) {
      unit.resetVersionData();
    }
  };

  const handleTrimTranslationVersions = () => {
    if (window.confirm('Are you sure?')) {
      unit.trimTranslationVersions();
    }
  };

  const handleFixUpperCaseBase36 = async () => {
    if (window.confirm('Are you sure?')) {
      const result = await unit.repairWordIds();
      console.log('handleFixUpperCaseBase36: ' + result);
      window.alert('handleFixUpperCaseBase36: ' + result);
    }
  };

  const handleSniffHasUpperCaseBase36 = async () => {
    const result = await sniffUnitHasUpperCaseWordIds(unit.episodeKey);
    window.alert('sniffUnitHasUpperCaseWordIds: ' + result);
  };

  return (
    <>
      volume id: {unit.volumeId}, unit id: {unit.id}
      <br />
      {/* <NavLink to={`${unit.navPath}`}>[volume view]</NavLink>
      <br /> */}
      <A href={unit.resolveEditorUrl()}>[Script ^]</A>
      <br />
      <A href={unit.chaatUrl}>[Chaat ^]</A>
      <br />
      {/* <ActionLink onPress={() => unit.oldInitChaat()}>
        Old Init chaat
      </ActionLink>
      <br /> */}
      {!!unit.data.migrationSourceKey ? (
        <>
          Migration source key: {unit.data.migrationSourceKey}
          <br />
        </>
      ) : null}
      {!!unit.data.scriptImportSourceUrl ? (
        <>
          <A href={unit.data.scriptImportSourceUrl}>
            [JWScript import source ^]
          </A>
          <br />
        </>
      ) : null}
      <ActionLink onPress={updateAllElementVersions}>
        [update element versions]
      </ActionLink>
      <br />
      {/* <ActionLink onPress={() => unit.runAudioProcessing()}>
        [Force audio processing]
      </ActionLink>
      <br />
      <ActionLink onPress={() => unit.runTranscribe()}>
        [Run transcribe]
      </ActionLink>
      <br />
      {unit.transcriptionJobId ? (
        <>
          <a href={unit.processTranscribeUrl} target="_blank" rel="noreferrer">
            [Process aws transcribe] ({unit.transcriptionJobId})
          </a>
          <br />
        </>
      ) : null} */}
      ---
      <br />
      {!!unit.data.chapterDataUrls
        ? unit.data.chapterDataUrls.map(url => (
            <>
              <A href={url}>
                [ch {unit.data.chapterDataUrls.indexOf(url) + 1}]
              </A>{' '}
            </>
          ))
        : null}
      <br />
      <NavLink to={`${unit.unitNavPath}/unit-export-data`}>
        [Render unit export data]
      </NavLink>
      <br />
      <NavLink to={`${unit.unitNavPath}/catalog-data/${unit.defaultLocale}`}>
        [Render unit catalog data]
      </NavLink>
      <br />
      ---
      <br />
      {/* <A href={unit.exportScriptUrl}>[Export jwscript ^]</A>
      <br /> */}
      <A href={unit.exportTimestampsUrl}>[Export timestamps ^]</A>
      <br />
      created at: {uiTimeString(unit.data.createdAt)}
      <br />
      updated at: {uiTimeString(unit.data.updatedAt)}
      <br />
      contentU at: {uiTimeString(unit.data.contentUpdatedAt)}
      <br />
      volume duration minutes: {unit.data.volumeDurationMinutes}
      <br />
      {Auth.getInstance().isSuperAdmin ? (
        <>
          ---
          <br />
          <ActionLink onPress={handleResetVersionData}>
            [RESET version history]
          </ActionLink>
          <ActionLink onPress={handleTrimTranslationVersions}>
            [Trim translation version history]
          </ActionLink>
          <br />
          <ActionLink onPress={handleFixUpperCaseBase36}>
            [Fix uppercase base36]
          </ActionLink>
          <br />
          <ActionLink onPress={handleSniffHasUpperCaseBase36}>
            [Sniff uppercase base36]
          </ActionLink>
          <br />
        </>
      ) : null}
      {Auth.getInstance().isSuperAdmin ? (
        <>
          <br />
          ---
          <br />
          handy link for translation import testing:
          <br />
          <A href="https://docs.google.com/document/d/1Ttq9SrixXG3uSdu_fYvDuRzDVV4b4GLMKEWja7mMhUg/edit">
            (240 aves - translations doc)
          </A>
          <br />
        </>
      ) : null}
    </>
  );
});
