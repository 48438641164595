import * as React from 'react';
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBModalHeader,
} from 'mdbreact';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { scriptEditorModel, versions } from '../models/app-root';
import { HistoryItem } from '@masala-lib/editorial/ui/timeline-components';
import { openVersionsDialog } from './versions-dialog';
import { ChangeMessageData } from '@masala-lib/editorial/db/mutation-actions';
import { ElementId } from '@masala-lib/editor-aliases';

class HistoryDialogState {
  @observable.ref open = false;
  @observable.ref selectedTS: number = null;
  constructor() {
    makeObservable(this);
  }
}

const dialogState = new HistoryDialogState();

export function openHistoryDialog() {
  dialogState.open = true;
  scriptEditorModel.setKbDeactivated(true);
}

export const HistoryDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    dialogState.selectedTS = null;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  if (!dialogState.open) {
    return null;
  }

  const historyItems = versions.getLastChangeMessages(50);
  // const versionItems = elementVersions.map(v => {
  //   return {
  //     ...v,
  //     content: getElementVersionDescriptiveContent(
  //       v,
  //       scriptEditorModel.words,
  //       scriptEditorModel.translationsLookup
  //     ),
  //   };
  // });

  const setSelectedItem = (item: ChangeMessageData) => {
    dialogState.selectedTS = item.timestamp;
    scriptEditorModel.setVersionActionsId(item.id as ElementId);
  };

  return (
    <React.Fragment>
      <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
        <MDBModalHeader>Actions Navigator</MDBModalHeader>
        <MDBModalBody>
          <div
            className="comment-thread"
            style={{ overflow: 'scroll', height: '60vh' }}
          >
            {historyItems.map(item => (
              <HistoryItem
                key={item.timestamp}
                onClick={() => setSelectedItem(item)}
                className={
                  item.timestamp === dialogState.selectedTS ? 'selected' : ''
                }
                item={item}
              />
            ))}
          </div>
        </MDBModalBody>
        <MDBModalFooter className="justify-content-center">
          <>
            <MDBBtn
              color="info"
              onClick={() => {
                toggleModal();
                openVersionsDialog();
              }}
            >
              Open Versions Dialog
            </MDBBtn>
          </>
        </MDBModalFooter>
      </MDBModal>
    </React.Fragment>
  );
});
