import { JOBS_COLLECTION_NAME, JobData } from '@masala-lib/jobs/job-types';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';
import {
  collectionReference,
  docReference,
} from '@masala-lib/catalog/db/catalog-db-paths';
import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  QuerySnapshot,
} from '@platform/firebase-types';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { difference, isEmpty, isNil } from 'lodash';
import { Job } from './job';
import { createLogger } from '@app/logger';

const log = createLogger('job-manager');

const collectionName = JOBS_COLLECTION_NAME;

function docRef(id: string): DocumentReference<JobData> {
  return docReference<JobData>(collectionName, id);
}

function collectionRef(): CollectionReference<JobData> {
  return collectionReference<JobData>(collectionName);
}

async function loadById(id: string): Promise<Job> {
  console.log(`${collectionName}.loadById(${id})`);
  if (isEmpty(id)) {
    return null;
  }
  const docSnapshot = await docRef(id).get();
  // const model = this.applyDocSnapshot(docSnapshot);
  if (docSnapshot.exists === false) {
    log.warn(`jobLoadById - missing doc: ${id}`);
    return null;
  }
  const data = docSnapshot.data();
  const model = new Job(data);
  return model;
}

async function updatePartial(
  id: string,
  data: Partial<JobData>
): Promise<void> {
  // console.log(`${this.collectionName}.updatePartial(${JSON.stringify(data)})`);
  // this.applyTimestamps(data as BaseMetadata);
  // todo: factor out a docSetMerge() function with this guard
  if (isEmpty(data)) {
    // an empty object object will nuke the entire doc!
    return;
  }
  await docRef(id).set(data, { merge: true });
}

async function toggleArchive(data: JobData): Promise<void> {
  await updatePartial(data.id, { archived: !data.archived });
}

async function destroyArchived() {
  // runInAction(async () => {
  //   const list = [...this.archivedList]; // make a shallow copy to isolate the loop data from mutation
  //   for (const item of list) {
  //     // console.log(item.destroy);
  //     await item.destroy();
  //   }
  // });
  // alertError('not yet implemented');
  // todo: promote dialogs to base console layout
  alert('not yet implemented');
}

export const JobManager = {
  docRef,
  collectionRef,
  loadById,
  updatePartial,
  toggleArchive,
  destroyArchived,
};

//
// model behind job list views
//
export class JobListModel implements FilterViewManager<Job> {
  // @observable.ref
  // modelMap: Map<string, Job> = observable.map({}, { deep: false });

  // the ordered list as returned by each query snapshot
  // (the previous code wasn't maintaining the right sort order as new items were added)
  @observable.ref
  rawList: Job[] = [];

  @observable
  filterText: string;

  unsubscribeFn: () => void;

  constructor() {
    makeObservable(this);
  }

  setFilterText(text: string) {
    this.filterText = text;
  }

  // unneeded FilterViewManager interface - todo: reslice interfaces
  fetchById: (id: string) => Job; // = (id: string) => { alertError('not implemented'); }
  create: (model: Job) => Promise<Job>;
  update: (model: Job) => Promise<void>;
  delete: ({ id }: { id: string }) => Promise<void>;
  loadAll: () => Promise<void>;

  @computed
  get list(): Job[] {
    if (isEmpty(this.filterText)) {
      return this.rawList;
    } else {
      return this.rawList.filter(model => model.filterMatch(this.filterText));
    }
  }

  listenActive(): void {
    this.stopListening();
    this.unsubscribeFn = collectionRef()
      .where('archived', '==', false)
      // beware, had to manually create an index for this
      .orderBy('startTimestamp', 'desc')
      .onSnapshot(querySnapshot => {
        log.debug(`${collectionName}.onSnapshot`);
        this.applyQuerySnapshot(querySnapshot);
        // todo: error handling
      });
  }

  listenArchived(): void {
    this.stopListening();
    this.unsubscribeFn = collectionRef()
      .where('archived', '==', true)
      .orderBy('startTimestamp', 'desc')
      .onSnapshot(querySnapshot => {
        log.debug(`${collectionName}.onSnapshot`);
        this.applyQuerySnapshot(querySnapshot);
        // todo: error handling
      });
  }

  stopListening(): void {
    if (this.unsubscribeFn) {
      this.unsubscribeFn();
      this.unsubscribeFn = undefined;
    }
  }

  applyQuerySnapshot(snapshot: QuerySnapshot<JobData>): void {
    console.log(
      `${collectionName}.applySnapshot - count: ${snapshot.docs.length}`
    );
    this.rawList = snapshot.docs.map(doc => new Job(doc.data()));
  }

  // @computed
  // get rawList(): Job[] {
  //   let list = Array.from(this.modelMap.values());
  //   // if (this.scopedChannelId) {
  //   //   result = result.filter(
  //   //     entity => entity.channelId === this.scopedChannelId
  //   //   );
  //   // }
  //   // console.log(
  //   //   `${this.collectionName}.rawList - result size: ${result.length}`
  //   // );
  //   return list;
  // }

  // applyQuerySnapshot(snapshot: QuerySnapshot<JobData>): void {
  //   console.log(
  //     `${collectionName}.applySnapshot - count: ${snapshot.docs.length}`
  //   );
  //   const loadedIds: string[] = [];
  //   runInAction(() => {
  //     snapshot.docs.forEach(doc => {
  //       const id = doc.id;
  //       this.applyDocSnapshot(doc);
  //       loadedIds.push(id);
  //     });
  //     const orphanIds = difference(Array.from(this.modelMap.keys()), loadedIds);
  //     console.log(`orphanIds: ${orphanIds}`);
  //     orphanIds.forEach(id => this.modelMap.delete(id));
  //   });
  // }

  // applyDocSnapshot(docSnapshot: DocumentSnapshot<JobData>): Job {
  //   const data = docSnapshot.data();
  //   if (isNil(data)) {
  //     log.warn(
  //       `${collectionName}.ads ignoring missing data for doc.id: ${docSnapshot.id}`
  //     );
  //     // todo: should this be treated as a removal?
  //     return null;
  //   }
  //   if (data.id !== docSnapshot.id) {
  //     log.error('ads mismatch - id', docSnapshot.id, 'data', data);
  //     // todo: revisit how to best recover from corrupted data
  //     // right now this results in partial load of data which causes mayhem
  //     throw Error(
  //       `${collectionName}.${docSnapshot.id}/${data.id} doc/data id mismatch`
  //     );
  //     // console.log(
  //     //   `error - ${this.collectionName}.${docSnapshot.id}/${data.id} doc/data id mismatch - deleting`
  //     // );
  //     // this.delete({ id: docSnapshot.id });
  //   }
  //   let model = this.modelMap.get(data.id);

  //   runInAction(() => {
  //     if (isNil(model)) {
  //       model = new Job(data);
  //       this.modelMap.set(data.id, model);
  //     } else {
  //       Object.assign(model, data); // todo: think about this more
  //     }
  //   });
  //   return model;
  // }

  // listenById(id: string): () => void {
  //   this.stopListening();
  //   this.unsubscribeFn = docRef(id).onSnapshot(docSnapshot => {
  //     log.debug(`job(${id}).onSnapshot`);
  //     this.applyDocSnapshot(docSnapshot);
  //   });
  //   return this.unsubscribeFn;
  // }

  // // not currently used
  // async loadAll(): Promise<void> {
  //   const querySnapshot = await collectionRef().get();
  //   this.applyQuerySnapshot(querySnapshot);
  // }
}
