import * as React from 'react';
import { ModelScreen } from '../shared/model-screen';
import { ChannelListScreen } from './channel-list-screen';
import { ChannelDetailScreen } from './channel-detail-screen';
import { ChannelEditScreen } from './channel-edit-screen';
import { ChannelCreateScreen } from './channel-create-screen';
// import { VolumeEditScreen } from './volume-edit-screen';
// import { VolumeCreateScreen } from './volume-create-screen';

export const ChannelsScreen = () => {
  return (
    <ModelScreen
      path={'/channels'}
      renderList={() => <ChannelListScreen />}
      renderDetail={controller => (
        <ChannelDetailScreen
          closeDetail={controller.closeDetail}
          startEditing={controller.startEditing}
        />
      )}
      renderEditForm={controller => (
        <ChannelEditScreen stopEditing={controller.stopEditing} />
      )}
      renderCreateForm={_ => <ChannelCreateScreen />}
      // renderDetail={controller => (
      //   <VolumeDetailScreen
      //     closeDetail={controller.closeDetail}
      //     startEditing={controller.startEditing}
      //   />
      // )}
      // renderEditForm={controller => <VolumeEditScreen stopEditing={controller.stopEditing} />}
      // renderCreateForm={controller => <VolumeCreateScreen closeDetail={controller.closeDetail} />}
    />
  );
};
