import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { useVolume } from './use-volume';
import { InfoV5Data } from '@masala-lib/catalog/catalog-types';
import { InfoV5Form } from '../units/info-v5-form';
import { consoleAddAuditLogMessage } from '@masala-lib/editorial/db/mutation-actions';
import { useNavigate } from 'react-router-dom';

export const VolumeInfoEditScreen = observer(() => {
  const volume = useVolume();
  const navigate = useNavigate();

  if (!volume) {
    return null;
  }

  const formData = volume.data.infoV5;

  const goBack = () => {
    navigate(volume.navPath);
  };

  const onSubmit = (dto: InfoV5Data) => {
    console.log(`onSubmit, data: ${JSON.stringify(dto)}`);
    volume.updatePartial({ infoV5: dto }).then(goBack);
    consoleAddAuditLogMessage(volume.firstUnitKey, `updateVolumeInfo`);
  };

  return (
    <Page
      title={`Editing ${volume.name} - Volume level Info`} // (${flavor} flavor)`}
      onClose={goBack}
    >
      <InfoV5Form
        dto={formData}
        onSubmit={onSubmit}
        cancelNavPath={volume.navPath}
        entity={volume}
        l2Locale={volume.l2}
        l1Locale={volume.l1}
      />
    </Page>
  );
});
