import { ElementId } from '@tikka/basic-types';
import { MutationActions } from '../../db/mutation-actions';
import { getTranslationId } from '@tikka/elements/element-id-utils';
import { Alert, AlertMessages } from '../../../misc/alert-messages';
import {
  hasOtherDisallowedChars,
  normalizeSpecialChars,
} from '../../../misc/editorial-string-utils';
import { stripLinefeeds } from '@utils/content-string-utils';
import { ContentTranslation } from '../../../editorial-types';

// [<AbstractClass>]
export abstract class TranslationActions {
  addUpdate(id: ElementId, locale: string, translation: ContentTranslation) {
    if (typeof translation === 'string') {
      translation = stripLinefeeds(translation);
      translation = normalizeSpecialChars(translation);
      if (hasOtherDisallowedChars(translation)) {
        this.alertMessages.add({
          ...Alert,
          text: `edit contained disallowed characters: ${hasOtherDisallowedChars}`,
        });
        return null;
      }
    }
    this.mutationActions.addUpdateTranslation(id, locale, translation);
    return getTranslationId(id, locale);
  }

  abstract get mutationActions(): MutationActions;
  abstract get alertMessages(): AlertMessages;
}
