import * as React from 'react';
import { agePrettyTimestamp } from '@masala-lib/utils';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import {
  ProjectOverflowMenu,
  handleOverwriteImportBackToUnit,
} from './project-overflow-menu';
import { Badge } from '@naan/primitives/badge';
import { Button } from '@naan/primitives/button';

const Wrapper = styled('div', {
  fontWeight: 'bold',
  height: 48,
  background: 'color-mix(in srgb, $colors$gray-100 60%, $colors$blue-100)',
  color: '$gray-700',
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  padding: '0 18px',
  '& .inner': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 48,
    paddingLeft: 12,
    width: 'min( 100% - 32px, var(--maxChatWidth) )',
    margin: '0 auto',
    position: 'relative',
    gap: 8,
    '&  .project-name': {
      fontSize: '16px',
      color: '$gray-500',
      mixBlendMode: 'multiply',
      fontWeight: '600',
    },

    '& > .menu': {
      position: 'absolute',
      right: 0,
      top: 0,
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

const OverviewMeta = observer(() => {
  const model = useSamosaModel();
  const metadata = model.projectMetadata;
  const { incomplete, flagged, ready } = model.mergeReadinessStats;

  if (Boolean(metadata.completedTimestamp)) {
    return (
      <Badge>
        merged back {agePrettyTimestamp(metadata.completedTimestamp)}
      </Badge>
    );
  }

  if (ready) {
    return (
      <Button
        onClick={handleOverwriteImportBackToUnit}
        label="Merge back to script editor"
        size="extraSmall"
        presentation={'grayLight'}
        radius={'squared'}
      />
    );
  }

  return (
    <>
      {/* (M:{missing}, C:{conflict}, F:{flagged}) */}
      {/* (P:{incomplete}, F:{flagged}) */}
    </>
  );
});

export const Overview = observer(() => {
  const model = useSamosaModel();

  return (
    <Wrapper>
      <div className="inner">
        <div>
          <span className="project-name">
            {model.unitName} | {model.projectName}
          </span>
          {model.archived ? ' (ARCHIVED)' : null}
        </div>
        <div className="meta">
          <OverviewMeta />
        </div>
        <div className="menu">
          <ProjectOverflowMenu />
        </div>
      </div>
    </Wrapper>
  );
});
