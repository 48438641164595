import type { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import type { ScriptElement } from '@masala-lib/llm/llm-types';
import { observer } from 'mobx-react';
import { TextFormInline } from '../components/text-form-inline';

export const ScriptElementForm = observer(
  ({
    element,
    modal,
  }: {
    element: ScriptElement;
    modal: TouchUpEditorModal;
  }) => {
    return (
      <TextFormInline
        text={element.text}
        setText={(text: string) => modal.overrideElementText(element, text)}
        onDismiss={() => modal.stopEditing()}
      />
    );
  }
);
