import { styled, theme } from 'naan/stitches.config';

const { colors } = theme;

const hoverSelector = '&:hover:not(.disabled)';
const activeSelector = '&:active:not(.disabled), &[data-state=open]';
const focusSelector = '&:focus-visible:not(.disabled)';

export const IconButtonStyled = styled('button', {
  $$outerSize: '40px',
  $$innerSize: '40px',
  $$iconSize: '24px',
  $$iconColor: '$colors$gray-400',
  $$borderColor: '$colors$transparent',
  $$backgroundColor: '$colors$transparent',
  $$focusRingColor: '$colors$teal-500-alpha-50',

  color: '$$iconColor',
  border: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$$outerSize',
  height: '$$outerSize',
  backgroundColor: colors.transparent,
  borderRadius: 'calc($$outerSize / 2)',

  '&:focus': {
    // background: '$black-alpha-50',
    outline: 'none',
  },
  [focusSelector]: {
    // outline: 'none',
    '& .inner': {
      boxShadow: '0 0 0 4px $$focusRingColor',
    },
  },

  '& .button-inner': {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '$$innerSize',
    height: '$$innerSize',
    backgroundColor: '$$backgroundColor',
    border: '1px solid $$borderColor',
    borderSpacing: 'solid',
    borderRadius: 'calc($$innerSize / 2)',
    boxSizing: 'border-box',
    transitionProperty: 'background-color, color',
    transitionDuration: '0.15s',
  },
  '& svg': {
    width: '$$iconSize',
    height: '$$iconSize',
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.2, /// quick hack to have disabled states
      },
    },
    radius: {
      squared: {
        borderRadius: 'calc($$outerSize / 4)',
        '& .button-inner': {
          borderRadius: 'calc($$innerSize / 4)',
        },
      },
    },
    size: {
      default: {
        $$innerSize: '40px',
        $$iconSize: '24px',
      },
      extraSmall: {
        $$innerSize: '24px',
        $$iconSize: '16px',
      },
      masalaExtraSmall: {
        $$outerSize: '24px',
        $$innerSize: '24px',
        $$iconSize: '18px',
      },
      small: {
        $$innerSize: '32px',
        $$iconSize: '20px',
      },
      large: {
        $$outerSize: '48px',
        $$innerSize: '48px',
        $$iconSize: '28px',
      },
    },
    presentation: {
      grayTransparent: {
        $$iconColor: '$colors$black-alpha-40',
        $$borderColor: '$colors$transparent',
        $$backgroundColor: '$colors$transparent',
        [hoverSelector]: {
          $$backgroundColor: '$colors$black-alpha-06',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$black-alpha-10',
        },
      },
      greenTransparent: {
        $$iconColor: '$colors$green-600',
        $$borderColor: '$colors$transparent',
        $$backgroundColor: '$colors$transparent',
        [hoverSelector]: {
          $$backgroundColor: '$colors$black-alpha-06',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$black-alpha-10',
        },
      },

      teal: {
        $$iconColor: '$colors$white',
        $$backgroundColor: '$colors$teal-500',
        [hoverSelector]: {
          $$backgroundColor: '$colors$teal-600',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$teal-700',
        },
      },
      tealTransparent: {
        $$iconColor: '$colors$teal-500',
        $$borderColor: '$colors$transparent',
        $$backgroundColor: '$colors$transparent',
        [hoverSelector]: {
          $$backgroundColor: '$colors$teal-50',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$teal-100',
        },
      },
      whiteTransparent: {
        $$iconColor: '$colors$white',
        $$borderColor: '$colors$transparent',
        $$backgroundColor: '$colors$transparent',
        [hoverSelector]: {
          $$backgroundColor: '$colors$black-alpha-10',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$black-alpha-20',
        },
      },
      grayLight: {
        $$iconColor: '$colors$black-alpha-40',
        $$backgroundColor: '$colors$black-alpha-06',
        [hoverSelector]: {
          $$backgroundColor: '$colors$black-alpha-10',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$black-alpha-15',
        },
      },
      tealLight: {
        $$iconColor: '$colors$teal-500',
        $$backgroundColor: '$colors$teal-50',
        [hoverSelector]: {
          $$backgroundColor: '$colors$teal-100',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$teal-200',
        },
      },
      whiteDarken: {
        $$iconColor: '$colors$white',
        $$backgroundColor: '$colors$black-alpha-10',
        [hoverSelector]: {
          $$backgroundColor: '$colors$black-alpha-20',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$black-alpha-30',
        },
      },
      redLight: {
        $$iconColor: '$colors$red-500',
        $$backgroundColor: '$colors$red-50',
        [hoverSelector]: {
          $$backgroundColor: '$colors$red-100',
        },
        [activeSelector]: {
          $$backgroundColor: '$colors$red-200',
        },
      },
      // outlined: {
      //   $$borderColor: colors['gray-200'],
      //   $$backgroundColor: colors.transparent,
      //   [activeSelector]: {
      //     $$backgroundColor: colors['gray-100'],
      //   },
      // },
      // white: {
      //   $$iconColor: '$colors$white',
      //   $$backgroundColor: '$colors$transparent',
      //   [activeSelector]: {
      //     $$backgroundColor: colors['white-alpha-20'],
      //   },
      // },
      // 'white-outlined': {
      //   $$iconColor: colors.white,
      //   $$borderColor: colors['white-alpha-30'],
      //   $$backgroundColor: colors.transparent,
      //   [activeSelector]: {
      //     $$backgroundColor: colors['white-alpha-10'],
      //   },
      // },

      // activated: {
      //   $$borderColor: colors.transparent,
      //   $$backgroundColor: colors['teal-50'],
      //   $$iconColor: colors['teal-500'],
      //   [activeSelector]: {
      //     $$iconColor: colors['teal-700'],
      //     $$backgroundColor: colors['teal-100'],
      //   },
      // },
    },
    noFocus: {
      true: {
        $$focusRingColor: 'transparent',
      },
    },
  },
  defaultVariants: {
    presentation: 'grayTransparent',
    size: 'medium',
  },
});
