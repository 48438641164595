import { StructuredPlayer } from '@tikka/player/structured-player';
import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import {
  Navigation,
  NavigationPoint,
} from '@tikka/navigation/timeline-navigator';
import { makeObservable, observable } from 'mobx';

const REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS = 500;
export const NAVIGATION_SOFT_PAUSE_MS = 300;

export class NavigationState {
  navigationPoint: NavigationPoint;
  constructor() {
    this.navigationPoint = null;
    makeObservable(this, {
      navigationPoint: observable.ref,
    });
  }
}

export class ChaatPlayer extends StructuredPlayer {
  navigationState: NavigationState;
  nav: Navigation;
  peekDuration = 1500;

  constructor(
    audioTransport0: AudioTransport,
    transportState0: TransportState,
    navigationState0: NavigationState,
    navigation0: Navigation
  ) {
    super(audioTransport0, transportState0);
    this.navigationState = navigationState0;
    this.nav = navigation0;
    this.peekDuration = 1500;
  }

  next() {
    const currentPoint = this.navigationState.navigationPoint;
    const nextPoint = this.nav.next(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.navigationState.navigationPoint = nextPoint;
    }
  }

  prev() {
    const currentPoint = this.navigationState.navigationPoint;
    const nextPoint = this.nav.prev(currentPoint);
    if (nextPoint !== currentPoint) {
      this.seek(nextPoint.position);
      this.navigationState.navigationPoint = nextPoint;
    }
  }

  // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind, should probably always be true for non-chaat code
  nextClosest(keepPauseAfter = false) {
    const point = this.navigationState.navigationPoint;
    if (point) {
      const nextPoint = this.nav.nextClosest(point, this.getAudioPosition());
      if (nextPoint) {
        this.navigationState.navigationPoint = nextPoint;
        if (this.transportState.isPlaying) {
          this.pauseThenPlayAt(
            NAVIGATION_SOFT_PAUSE_MS,
            nextPoint.position,
            keepPauseAfter
          );
        } else {
          this.seek(nextPoint.position, keepPauseAfter);
        }
      }
    }
  }

  // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind, should probably always be true for non-chaat code
  prevClosest(keepPauseAfter = false) {
    const point = this.navigationState.navigationPoint;
    if (point) {
      const tolerance = this.transportState.isPlaying
        ? REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS
        : 10;
      const nextPoint = this.nav.prevClosest(
        point,
        this.getAudioPosition() - tolerance
      );
      // TODO factor out this code also in nextClosest??
      if (nextPoint) {
        this.navigationState.navigationPoint = nextPoint;
        if (this.transportState.isPlaying) {
          this.pauseThenPlayAt(
            NAVIGATION_SOFT_PAUSE_MS,
            nextPoint.position,
            keepPauseAfter
          );
        } else {
          this.seek(nextPoint.position, keepPauseAfter);
        }
      }
    }
  }

  playSection() {
    const point = this.navigationState.navigationPoint;
    if (point) {
      const nextPoint = this.nav.nextClosest(
        point,
        this.getAudioPosition() + 50
      );
      if (nextPoint) {
        this.audioTransport.setPauseAfter(nextPoint.position);
        this.audioTransport.clearAudioRestartPosition();
        this.play();
      }
    }
  }

  seekToNavigationPoint(point: NavigationPoint) {
    if (point) {
      this.seek(point.position);
    }
  }

  setNavigationPoint(point: NavigationPoint) {
    this.navigationState.navigationPoint = point;
    this.seekToNavigationPoint(point);
  }

  navigate(key: string, index: number) {
    const navigator = this.nav.getNavigatorForKey(key);
    const point = navigator.navigationPoint(index);
    this.setNavigationPoint(point);
  }

  playPeek() {
    if (
      this.transportState.isPlaying &&
      this.transportState.audioRestartPosition !== 0
    ) {
      this.audioTransport.seek(this.transportState.audioRestartPosition);
    } else {
      this.audioTransport.setPauseAfter(
        this.getAudioPosition() + this.peekDuration
      );
      this.transportState.audioRestartPosition = this.getAudioPosition();
      this.play();
    }
  }

  playPeekBack() {
    if (
      this.transportState.isPlaying &&
      this.transportState.audioRestartPosition !== 0
    ) {
      const playFrom =
        this.transportState.audioRestartPosition - this.peekDuration;
      this.pauseThenPlayAt(100, playFrom, true);
    } else if (this.transportState.audioRestartPosition !== 0) {
      this.audioTransport.setPauseAfter(
        this.transportState.audioRestartPosition
      );
      this.seek(
        this.transportState.audioRestartPosition - this.peekDuration,
        true
      );
      this.play();
    }
  }

  nudge(ms: number) {
    if (!this.transportState.isPlaying) {
      this.seek(this.transportState.audioPosition + ms, false);
    } else if (this.transportState.audioRestartPosition !== 0) {
      const nudgeTo = this.transportState.audioRestartPosition + ms;
      this.transportState.audioRestartPosition = nudgeTo;
      this.pauseThenPlayAt(100, nudgeTo, false);
      this.audioTransport.setPauseAfter(nudgeTo + this.peekDuration);
    }
  }
}

export function CreateChaatPlayer(
  audioTransport: AudioTransport,
  transportState: TransportState,
  navigationState: NavigationState,
  navigation: Navigation
) {
  const player = new ChaatPlayer(
    audioTransport,
    transportState,
    navigationState,
    navigation
  );
  return player;
}
