import { Link } from 'react-router-dom';
import { makeQueryPath } from '../../../lib/query-stuff';
import styled from 'styled-components';

const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--color-blue400);
  &:hover {
    color: var(--color-blue500);
  }
`;

const iconSize = 16;

const arrows: { [key: string]: React.ReactElement } = {
  asc: (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0121 19.162L14.8241 17.3397L16.2423 18.75L12.0116 23.0045L7.75684 18.7738L9.16708 17.3557L11.0121 19.1904L10.9976 0.99696L12.9976 0.995361L13.0121 19.162Z"
        fill="currentColor"
      />
    </svg>
  ),
  desc: (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0319 1.01709L7.75732 5.22758L9.16082 6.65243L11.0195 4.82162L10.9642 22.9768L12.9642 22.9828L13.0194 4.86971L14.8175 6.69522L16.2423 5.29172L12.0319 1.01709Z"
        fill="currentColor"
      />
    </svg>
  ),
};

interface SortableHeaderProps {
  label: string;
  path: string;
  fieldName: string;
  currentSortingFieldName?: string;
  currentSortingOrder?: string;
}

export const SortableTableHeader = ({
  label,
  path,
  fieldName,
  currentSortingFieldName,
  currentSortingOrder,
}: SortableHeaderProps) => {
  const isCurrent = currentSortingFieldName === fieldName;
  const displayOrder = isCurrent ? currentSortingOrder : 'asc';
  const oppositeOrder = currentSortingOrder === 'asc' ? 'desc' : 'asc';
  const nextOrder = isCurrent ? oppositeOrder : 'asc';
  return (
    <HeaderLink to={makeQueryPath(path, { sort: fieldName, order: nextOrder })}>
      {label}
      {isCurrent ? (
        <span className="header-icon">{arrows[displayOrder]}</span>
      ) : null}
    </HeaderLink>
  );
};
