import { numberProjectionSort } from '@masala-lib/utils';
import { NO_INDEX } from '@tikka/basic-types';
import { fromIntervals, Intervals } from '@tikka/intervals/intervals';
import { searchSorted } from '@tikka/intervals/search-sorted';
import { AudioRegion } from '../../chaat-types';
import {
  extractDataFromOutput,
  processWithMatchers,
  TimestamperEngine,
} from './engine';
import {
  LongWordMatcherOptions,
  longWordMatcherOpts,
  matchSectionCommonWordSequences,
  matchSectionContentCues,
  matchSectionInterpolate,
  matchSectionLongWords,
  matchSectionTrimSilence,
  matchSectionWordSequences,
  SeqMatcherOptions,
  seqMatcherOpts,
} from './matchers';
import { Section } from './sections';
import {
  AudioRegionsSpan,
  ContentSpan,
  CuesSpan,
  TranscriptSpan,
} from './spans';

export type TimestamperResult = {
  wordStartTimes: number[];
  wordEndTimes: number[];
  warnStartTimes: number[];
  warnEndTimes: number[];
  warnData: any[];
  interpolationStartTimes: number[];
  interpolationEndTimes: number[];
  interpolationData: any[];
};

export class Timestamper {
  contentSpan: ContentSpan = null;
  cuesSpan: CuesSpan = null;
  transcriptSpan: TranscriptSpan = null;
  audioRegionsSpan: AudioRegionsSpan = null;
  forceLinearAudioIntervals: Intervals = null;
  startTime = 0;
  endTime = 0;
  language: string = null;
  engine: TimestamperEngine = null;

  run() {
    const section: Section = {
      tag: 'SECTION',
      content: this.contentSpan,
      transcript: this.transcriptSpan,
      contentCues: this.cuesSpan,
      audioRegions: this.audioRegionsSpan,
      startTime: this.startTime,
      endTime: this.endTime,
    };
    let longWordsMatcher1;
    const options: LongWordMatcherOptions = {
      minWordLength: 10,
      prefixTest: 8,
      maxMatch: 0,
    };
    longWordsMatcher1 = (section: Section) =>
      matchSectionLongWords(options, section);
    const cueMatcher = matchSectionContentCues;
    let output = processWithMatchers([section], [cueMatcher]);
    output = processWithMatchers(output, [longWordsMatcher1]);
    const wordSequenceOptions2: SeqMatcherOptions = {
      ...seqMatcherOpts,
      seqMatchEnds: false,
      seqMatchLongest: true,
    };
    const wordSequenceOptions3: SeqMatcherOptions = {
      ...seqMatcherOpts,
      seqLevenshteinCutoff: 0.8,
    };
    const longWordsOptions2: LongWordMatcherOptions = {
      minWordLength: 4,
      prefixTest: 3,
      maxMatch: 1,
    };
    const matchers = [
      (section: Section) => matchSectionWordSequences(seqMatcherOpts, section),
      (section: Section) => matchSectionLongWords(longWordMatcherOpts, section),
      (section: Section) =>
        matchSectionWordSequences(wordSequenceOptions2, section),
      (section: Section) =>
        matchSectionCommonWordSequences(seqMatcherOpts, this.language, section),
      (section: Section) => matchSectionLongWords(longWordsOptions2, section),
      (section: Section) =>
        matchSectionWordSequences(wordSequenceOptions3, section),
      (section: Section) => matchSectionInterpolate(false, section),
      matchSectionTrimSilence,
      (section: Section) => matchSectionInterpolate(true, section),
    ];
    this.engine = new TimestamperEngine();
    const linearInterpolateIntervals = this.forceLinearAudioIntervals;
    const linearInterpolateMatchers = [
      matchSectionTrimSilence,
      (section: Section) => matchSectionInterpolate(true, section),
    ];
    const sectionOverride = (section: Section) => {
      const sectionDuration = section.endTime - section.startTime;
      if (sectionDuration <= 0) {
        return null;
      }
      const epsilon = Math.floor(sectionDuration / 5);
      const matchIndex = linearInterpolateIntervals.containing(
        section.startTime + epsilon
      );
      if (matchIndex === NO_INDEX) {
        return null;
      }
      if (
        matchIndex ===
        linearInterpolateIntervals.containing(section.endTime - epsilon)
      ) {
        return linearInterpolateMatchers;
      }
      return null;
    };

    this.engine.setDefaultMatchers(matchers);
    if (linearInterpolateIntervals) {
      this.engine.setSectionOverrideMatchers(sectionOverride);
    }
    this.engine.routeOutputs(output);
    this.engine.run();
  }

  getResult(): TimestamperResult {
    const result = extractDataFromOutput(
      this.engine.outputQueue,
      this.contentSpan.data.word,
      this.endTime
    );
    const words = result.words;
    const wordIntervals = result.wordIntervals;
    // let wordStartTimes:obj [] = [|for i in wordIntervals -> i.startTime|]
    const wordStartTimes = wordIntervals.map(i => i.startTime);
    // let wordEndTimes:obj [] = [|for i in wordIntervals -> i.endTime|]
    const wordEndTimes = wordIntervals.map(i => i.endTime);
    const warnData = result.warningData;
    const warnIntervals = result.warningIntervals;
    // let warnStartTimes:obj [] = [|for i in warnIntervals -> i.startTime|]
    const warnStartTimes = warnIntervals.map(i => i.startTime);
    // let warnEndTimes:obj [] = [|for i in warnIntervals -> i.endTime|]
    const warnEndTimes = warnIntervals.map(i => i.endTime);
    const interpolationData = result.interpolateData;
    const interpolationIntervals = result.interpolateIntervals;
    // let interpolationStartTimes:obj [] = [|for i in interpolationIntervals -> i.startTime|]
    const interpolationStartTimes = interpolationIntervals.map(
      i => i.startTime
    );
    // let interpolationEndTimes:obj [] = [|for i in interpolationIntervals -> i.endTime|]
    const interpolationEndTimes = interpolationIntervals.map(i => i.endTime);
    // let shortWarningIndexes = [|for i, warning in Array.indexed warnData do if !< warning = "short" then i|]
    const shortWarningIndexes = [...warnData.entries()]
      .filter(([i, warning]) => warning === 'short')
      .map(([i, warning]) => i);

    this.postProcessWordIntervalsWithShortWarnings(
      words,
      wordStartTimes,
      wordEndTimes,
      shortWarningIndexes,
      warnStartTimes,
      warnEndTimes
    );
    return {
      wordStartTimes,
      wordEndTimes,
      warnStartTimes,
      warnEndTimes,
      warnData,
      interpolationStartTimes,
      interpolationEndTimes,
      interpolationData,
    };
  }

  postProcessWordIntervalsWithShortWarnings(
    words: string[],
    wordStartTimes: number[],
    wordEndTimes: number[],
    shortWarningIndexes: number[],
    warnStartTimes: number[],
    warnEndTimes: number[]
  ) {
    const wordCount = wordStartTimes.length;
    for (const shortWarningIndex of shortWarningIndexes) {
      // const shortWarningIndex = shortWarningIndexes[idx] | 0;
      const warningStart = warnStartTimes[shortWarningIndex];
      const warningEnd = warnEndTimes[shortWarningIndex];
      // TODO
      const warningMidpoint = ~~((warningStart + warningEnd) / 2) | 0;
      const warningDuration = warningEnd - warningStart;
      let midpointWord = searchSorted(wordEndTimes, warningMidpoint, true);
      if (warningDuration === 0) {
        midpointWord = midpointWord - 1;
      }
      const word = midpointWord;
      const wordStart = wordStartTimes[word];
      const wordEnd = wordEndTimes[word];
      if (warningEnd !== wordEnd) {
        continue;
      }
      const wordDuration = wordStart - wordEnd;
      const wordLen = words[word].length;
      const minDuration = wordLen === 1 ? 30 : wordLen === 2 ? 50 : 80;
      if (wordDuration >= minDuration) {
        continue;
      }
      const previousWord = word - 1;
      const nextWord = word + 1;
      // TODO if nextWord >= wordCount || previousWord < 0 then
      if (nextWord >= wordCount ? true : previousWord < 0) {
        continue;
      }
      const testLeft = wordStart - 50;
      const testRight = wordEnd + 50;
      if (wordStartTimes[nextWord] > testRight) {
        if (wordEndTimes[previousWord] > testLeft) {
          continue;
        }
        const newWordStartTime = wordEnd - minDuration;
        if (newWordStartTime - 30 < wordStartTimes[previousWord]) {
          continue;
        }
        wordStartTimes[word] = newWordStartTime;
        wordEndTimes[previousWord] = newWordStartTime - 1;
        continue;
      }
      if (wordEndTimes[previousWord] < testLeft) {
        const newWordEndTime = wordStart + minDuration;
        if (newWordEndTime + 30 > wordEndTimes[nextWord]) {
          continue;
        }
        wordEndTimes[word] = newWordEndTime;
        wordStartTimes[nextWord] = newWordEndTime + 1;
        warnEndTimes[shortWarningIndex] = newWordEndTime;
        continue;
      }
      const wordMidpoint = warningMidpoint;
      // TODO
      const halfDuration = ~~(minDuration / 2) | 0;
      const newWordEndTime = wordMidpoint + halfDuration;
      const newWordStartTime = wordMidpoint - halfDuration;
      if (newWordEndTime + 30 > wordEndTimes[nextWord]) {
        continue;
      }
      if (newWordStartTime - 30 < wordStartTimes[previousWord]) {
        continue;
      }
      wordStartTimes[word] = newWordStartTime;
      wordEndTimes[word] = newWordEndTime;
      wordStartTimes[nextWord] = newWordEndTime + 1;
      wordEndTimes[previousWord] = newWordStartTime - 1;
      warnStartTimes[shortWarningIndex] = newWordStartTime;
      warnEndTimes[shortWarningIndex] = newWordEndTime;
    }
  }
}
