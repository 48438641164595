// fetches /version.txt upon initial load, then watches and prompts user if changed

const DEFAULT_INTERVAL_MILLIS = 1000 * 60 * 2;

export function startUpdateChecks(
  intervalMillis: number = DEFAULT_INTERVAL_MILLIS
) {
  fetch('/version.txt')
    .then(res => res.text())
    .then(text => {
      const initialVersion = text.trim();
      console.log(`initial version: ${initialVersion}`);
      checkForUpdate(initialVersion, intervalMillis);
    });
}

function checkForUpdate(initialVersion: string, intervalMillis: number) {
  setTimeout(() => {
    fetch('/version.txt')
      .then(res => res.text())
      .then(text => {
        const currentVersion = text.trim();
        // console.log({ initialVersion, currentVersion });
        if (currentVersion !== initialVersion) {
          const reload = window.confirm(
            'A new build has been deployed. Click ok reload the page, or cancel to reload later'
          );
          if (reload) {
            window.location.reload();
          }
        }
        checkForUpdate(initialVersion, intervalMillis);
      });
  }, intervalMillis);
}
