import { IElement } from './basic-types';
import {
  ElementListSelfReferential,
  ElementListType,
} from './elements/element-list';

export type IntersectOf<U extends any> = (
  U extends unknown ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

type Equals<A1 extends any, A2 extends any> = (<A>() => A extends A2
  ? 1
  : 0) extends <A>() => A extends A1 ? 1 : 0
  ? 1
  : 0;

export type IsOneType<U extends any> = IntersectOf<
  U extends unknown // Distribute U
    ? (x: U) => void
    : never // To intersect
> extends (x: infer P) => void
  ? Equals<P, U> extends 1
    ? P
    : never
  : never;

export type AllKeys<T> = T extends any ? keyof T : never;
export type Merge<U> = { [K in AllKeys<U>]: U[K] };
export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function entries<O extends object>(obj: O): Entries<O> {
  return Object.entries(obj) as any;
}

export type IsNotUnion<T> = [T] extends [IntersectOf<T>] ? true : false;
export type Strip0Bak<T, KEEP extends string> = Pick<
  T,
  {
    [K in keyof T]: T[K] extends never
      ? never
      : Equals<T[K], null> extends 1
      ? never
      : K extends KEEP
      ? K
      : T[K] extends (...args: any) => never
      ? never
      : K;
  }[keyof T]
>;

export type Strip0<T, KEEP extends string> = Pick<
  T,
  {
    [K in keyof T]: K extends KEEP
      ? K
      : T[K] extends never
      ? never
      : Equals<T[K], null> extends 1
      ? never
      : T[K] extends (...args: any) => never
      ? never
      : K;
  }[keyof T]
>;

export type NeverOnNeverElseND<T, RT> = [T] extends [never] ? never : RT;
export type NeverOnNullElseND<T, RT> = [T] extends [null] ? never : RT;
export type Unionize<L extends readonly any[]> = L[number];
export type Flatten<T> = T extends object ? {} & { [K in keyof T]: T[K] } : T;
export type DeepFlatten<T> = T extends object
  ? {} & { [K in keyof T]: DeepFlatten<T[K]> }
  : T;
export type Simplify<T> = T extends unknown ? Flatten<T> : never;
export type DeepSimplify<T> = T extends unknown ? DeepFlatten<T> : never;
export type KeysToLookup<U extends string> = { [K in U]: K };
export type OnlyReturnTypes<T> = {
  [K in keyof T]: T[K] extends (...args: any) => infer R ? R : T[K];
};
export function initLookup(lookup: any, list: readonly string[]) {
  for (const k of list) {
    lookup[k] = k;
  }
}

export type SimplifyFunction<F> = F extends (...args: infer PT) => infer R
  ? (...args: PT) => R
  : F;
export type ElementReturningFunction<F> = F extends (...args: any[]) => infer R
  ? R extends IElement | IElement[]
    ? true
    : false
  : false;

export type SimplifyElementFunction<F extends any> =
  ElementReturningFunction<F> extends true ? SimplifyFunction<F> : F;

export type SimplifyElementMethods<T> = {
  [K in keyof T]: SimplifyElementFunction<T[K]>;
};

export type LoosenConstraints<T> = {
  [K in keyof T]: SimplifyFunction<T[K]>;
};

export type DistributivePick<T, K extends keyof T> = T extends unknown
  ? Pick<T, K>
  : never;
export type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;
export type DistributivePartialBy<T, K extends keyof T> = T extends unknown
  ? Omit<T, K> & Partial<Pick<T, K>>
  : never;

export type RT<T extends (...args: any) => any> = ReturnType<T>;
type IfAny<T, Y, N> = 0 extends 1 & T ? Y : N;
export type IsAny<T> = IfAny<T, true, false>;
export type keyOfElementList = keyof ElementListType<any>;

export type ElementListSelfReferentialKeys = keyof ElementListSelfReferential<
  any,
  any
>;

export type StripElementList<T extends ElementListType<any>> = Strip0<
  T,
  ElementListSelfReferentialKeys
>;
