import { ElementId } from '@tikka/basic-types';
import { MutationActions } from '@masala-lib/editorial/db/mutation-actions';
import { auth, alertMessages } from './app-root';

export class ChaatMutationActions extends MutationActions {
  // constructor() {
  //   super();
  // }

  get auth() {
    return auth;
  }

  elementWillUpdate(id: ElementId) {}

  get alerts() {
    return alertMessages;
  }
}
