import { Intervals } from '@tikka/intervals/intervals';
import { Context2D } from './canvas';
import { NavigationTrackGadget } from './navigation-gadget';

export class WordsTrackGadget extends NavigationTrackGadget {
  words: string[] = [];
  font = '25px Arial';
  fontColor = '#000000';

  setWordsIntervals(words: string[], intervals: Intervals) {
    this.words = words;
    this.handleIntervals = intervals;
    this.requestFullRedraw();
  }

  drawHandles(ctx: Context2D) {
    ctx.save();
    ctx.font = this.font;
    ctx.textBaseline = 'middle';
    super.drawHandles(ctx);
    ctx.restore();
    this.drawGadgetSliceBounds(ctx);
  }

  drawRolloverHighlight(ctx: Context2D) {
    ctx.save();
    ctx.font = this.font;
    ctx.textBaseline = 'middle';
    super.drawRolloverHighlight(ctx);
    ctx.restore();
  }

  drawHandle(ctx: Context2D, index: number) {
    this.drawSimpleHandle(ctx, index);
    const saveFillStyle = ctx.fillStyle;
    ctx.fillStyle = this.fontColor;
    this.drawTextHandle(ctx, index, this.words[index]);
    this.drawHandleEdges(ctx, index, this.height);
    ctx.fillStyle = saveFillStyle;
  }
}
