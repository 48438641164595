import { Excerpt } from '@masala-lib/catalog/models/excerpt';
import { isEqual } from 'lodash';
import { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type ListItemProps = {
  divider?: boolean;
};

const CalendarCount = styled.div`
  color: red;
  font-weight: bold;
`;

const ListItem = styled.div<ListItemProps>`
  border: 1px solid lightgrey;
  padding: ${p => (p.divider ? '7px' : '3px')};
  background-color: ${p => (p.divider ? '#eee' : 'inherit')};
  user-select: none;
  font-weight: ${p => (p.divider ? 'bold' : '500')};
`;

const SoundbiteMetadata = styled.div`
  color: grey;
`;

const List = styled.div`
  border: 1px solid lightgrey;
`;

const SaveButton = styled.button`
  margin: 8px 0;
`;

export type SBEditionListItemData = {
  description: string;
  link: string;
  metadata: string;
  id: string;
};

export const SB_CALENDAR_DIVIDER = 'SB_CALENDAR_DIVIDER';

export interface EditionSoundbitesData {
  calendarSoundbiteIds: string[];
  auxilarySoundbiteIds: string[];
  allExcerpts?: Excerpt[];
}

export interface SoundbiteEditionCalendarEditParams
  extends EditionSoundbitesData {
  onSave: (update: EditionSoundbitesData) => void;
}

function generateSBEditionListItemData(editionData: EditionSoundbitesData) {
  const baseExcerpts: Excerpt[] = [];
  const { calendarSoundbiteIds, auxilarySoundbiteIds, allExcerpts } =
    editionData;
  const excerptMap: Map<string, Excerpt> = new Map();

  for (const excerpt of allExcerpts) {
    excerptMap.set(excerpt.id, excerpt);
  }

  if (calendarSoundbiteIds) {
    const excerpts = calendarSoundbiteIds
      .map(id => excerptMap.get(id))
      .filter(item => item);
    baseExcerpts.push(...excerpts);
  }
  baseExcerpts.push(null);

  if (auxilarySoundbiteIds) {
    const excerpts = auxilarySoundbiteIds
      .map(id => excerptMap.get(id))
      .filter(item => item);
    baseExcerpts.push(...excerpts);
  }
  baseExcerpts.push(null);

  const usedExcerptsIds = new Set(
    baseExcerpts.filter(item => item).map(e => e.id)
  );

  for (const excerpt of allExcerpts) {
    if (!usedExcerptsIds.has(excerpt.id)) {
      baseExcerpts.push(excerpt);
    }
  }

  let nullCount = 0;
  const calendarItems: SBEditionListItemData[] = baseExcerpts.map(excerpt => {
    if (excerpt) {
      return {
        description: `${excerpt.name} | ${excerpt.data.title} |`,
        metadata: `${excerpt.data.categories} | ${
          excerpt.data.difficulty || ''
        } | ${excerpt.data.status}`,
        link: excerpt.navPath,
        id: excerpt.id,
      };
    } else {
      const description = nullCount
        ? 'UNUSED SOUNDBITES...'
        : 'AUXILARY SOUNDBITES...';
      nullCount++;
      return {
        description,
        metadata: null,
        link: null,
        id: SB_CALENDAR_DIVIDER + nullCount,
      };
    }
  });

  return calendarItems;
}

function extractEditionSoundbitesData(
  calendarItems: SBEditionListItemData[]
): EditionSoundbitesData {
  const auxDividerIndex = calendarItems.findIndex(item =>
    item.id.startsWith(SB_CALENDAR_DIVIDER)
  );
  const calendarAssignedItems = calendarItems.slice(0, auxDividerIndex);
  const remainingItems = calendarItems.slice(auxDividerIndex + 1);
  const unusedDividerIndex = remainingItems.findIndex(item =>
    item.id.startsWith(SB_CALENDAR_DIVIDER)
  );
  const auxItems = remainingItems.slice(0, unusedDividerIndex);
  const calendarSoundbiteIds = calendarAssignedItems.map(item => item.id);
  const auxilarySoundbiteIds = auxItems.map(item => item.id);

  return {
    calendarSoundbiteIds,
    auxilarySoundbiteIds,
  };
}

export const SoundbiteEditionCalenderEdit = ({
  onSave,
  ...params
}: SoundbiteEditionCalendarEditParams) => {
  const inputItems = generateSBEditionListItemData(params);
  const [items, setItems] = useState(inputItems);

  const onDragEnd = (result: DropResult) => {
    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };

  const currentCalendarIdCount =
    extractEditionSoundbitesData(items).calendarSoundbiteIds.length;
  const badCount = /*currentCalendarIdCount &&*/ currentCalendarIdCount !== 7;
  const dirty = !isEqual(items, inputItems);

  return (
    <>
      {badCount ? (
        <CalendarCount>
          {`${currentCalendarIdCount} SOUNDBITES ON CALENDAR`}
        </CalendarCount>
      ) : null}
      <List>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="sbCalendarDroppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {items.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={item.id.startsWith(SB_CALENDAR_DIVIDER)}
                  >
                    {provided => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        divider={item.id.startsWith(SB_CALENDAR_DIVIDER)}
                      >
                        <div>
                          {item.description}
                          {item.link && (
                            <>
                              <Link to={item.link}> link</Link>
                            </>
                          )}
                        </div>
                        {item.metadata ? (
                          <SoundbiteMetadata>
                            &nbsp;{`${item.metadata}`}
                          </SoundbiteMetadata>
                        ) : null}
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided?.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
      <SaveButton
        onClick={() => onSave(extractEditionSoundbitesData(items))}
        disabled={!dirty}
      >
        Save changes
      </SaveButton>
    </>
  );
};
