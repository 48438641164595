import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const markdown = `
# Script Editor Cheat Sheet

J/K keys = down and up one line<br/>
0 = go to first line in script<br/>
X = collapse/expand view<br/>
Enter = edit selected structural content<br/>
Esc = cancel edit or clear selections<br/>
Ctrl-D = delete element line<br/>

**Creating word groups**<br/>
W = show options...<br/>

**Editing word groups**<br/>
click on a word in the word group and the context panel will show on right

**Creating structural content**<br/>
S = show options...

**Verbatim Actions** <br/>
V = show options...

**Search/Filter**<br/>
F = open filter flag dialog in mode that closes after making one filter selection when using hotkeys<br/>
Shift-F = open filter flag dialog in mode that lets you turn on/off multiple filters with check boxes or hotkeys
you can also edit in the search box all searches/filters are based on commands which start with either # or @
Right/Left keys = navigate forward and back in search results

**Translations**<br/>
edit and comment on in place the same as other elements<br/>
T = edit or create and edit the translation of the current element</br>
Shift-T = turn on and off display of translations, also works in outline mode</br>

**Moving structural content**<br/>
M = mark structural entity line for future actions like move<br/>
Ctrl-M = move the marked structural entity line

**Excerpts**<br/>
1. Create excerpt as a new kind of "structural element" (S, X)
2. Set end sentence: M (mark for move), select end sentence, Shift-M
3. From the right panel follow the '[Edit Soundbite^]' link, then provide title and other details
Note, for now it's probably best to use a google doc as the authoritative source for the prompt and explanation text and only copy into the masala console form once finalized. In the future, we hope to support a way to automatically import the text from the referenced google doc.


**Comments**<br/>
Can comment on word groups and line level entities, supporting @mention linked to Slack<br/>
The following / commands can be used in the comment box:
<pre>
  /resolve = hide the open comment thread indicator
  /slack = elevate discussion to slack thread, @mention also elevates
  /unslack = unelevate discussion from slack thread, /resolve also unelevates
</pre>

**Versions/Actions History**<br/>
H = show options<br/>
R = bring up versions revert dialog for selected element<br/>
A = bring up actions history for unit

**Playing Sentences**<br/>
Space = play single sentence/pause play<br/>
Shift+Space = play from start of current sentence continously<br/>

**Global operations (currently cleanup some elements with warnings)**<br/>
G = show options...

**Deep Links**<br/>
L = copy deep link URL for selected element to clipboard`;

export const ScriptEditorCheetSheet = () => (
  <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} />
);
