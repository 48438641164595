import { observer } from 'mobx-react';
import { VolumeDetail } from './volume-detail';
import { Page } from '../shared/page';
import { useVolume } from './use-volume';
import { PageActions } from '../shared/page-actions';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { DeleteActions } from '../shared/delete-actions';
import { VolumeManager } from '@masala-lib/catalog/db/volume-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

interface VolumeDetailsParams {
  closeDetail: () => void;
  startEditing: () => void;
}

export const VolumeDetailScreen = observer(
  ({ closeDetail, startEditing }: VolumeDetailsParams) => {
    const auth = Auth.getInstance();
    const navigate = useNavigate();
    const manager = VolumeManager.getInstance();
    const volume = useVolume();

    // console convenience
    (window as any).currentVolume = volume;

    if (!volume) {
      return null;
    }

    const handleIngest = async () => {
      try {
        await volume.invokeCaliIngest({ autoPublish: true });
      } catch (error) {
        volume.clearPendingOperation();
        window.alert(error);
        throw error;
      }
    };

    const writable = volume.hasWriteAccess(auth.appUser);

    const goBack = () => {
      navigate(volume.parentNavPath);
    };

    return (
      <Page
        title={`${volume.name} ${volume.archived ? '(Archived)' : ''}`}
        onClose={goBack}
        controls={
          <PageActions>
            {auth.can('manage_volumes') ? (
              <>
                <button onClick={startEditing} className="primary-action">
                  Edit
                </button>
                <NavLink to={volume.infoNavPath}>Volume Info</NavLink>
                <NavLink to={volume.creditsNavPath}>Credits</NavLink>
                {volume.canIngest && writable ? (
                  <button onClick={handleIngest}>Ingest</button>
                ) : null}
                <Link
                  to={`/units/new?volume=${volume.id}`}
                  className="secondary-action"
                >
                  New Unit
                </Link>
                <Link
                  to={`/soundbite-editions/new?volume=${volume.id}&channel=${volume.channelId}`}
                  className="secondary-action"
                >
                  New Soundbite Edition
                </Link>
                <DeleteActions model={volume} />
              </>
            ) : null}
          </PageActions>
        }
      >
        <VolumeDetail model={volume} manager={manager} unselect={closeDetail} />
      </Page>
    );
  }
);
