import { FeaturedReleaseData } from './../catalog-types';
import { CatalogEntityKind } from '../catalog-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { isEmpty, pick } from 'lodash';
import { FeaturedReleaseV3Data } from '../bogota/bogota-types';
import { FeaturedReleaseManager } from '../db/featured-release-manager';
import { Channel } from './channel';
import { ChannelManager } from '../db/channel-manager';

export const emptyData = {
  kind: CatalogEntityKind.ACTIVITY_GUIDE,
  channelId: '',
  slug: '',
  id: '',
  name: '',
  title: '',
  illustrationUrl: '',
  article: '',
  preview: '',
  storySlugs: '',
  accessibleDate: '',
  releaseDate: '',
  active: false,
};

export class FeaturedRelease
  extends BaseCatalogEntity<FeaturedReleaseData>
  implements CatalogEntity<FeaturedReleaseData>
{
  constructor(data?: { id: string }) {
    super(data);
    this.data = { ...emptyData, ...data };
  }

  theManager(): EntityManager<
    CatalogEntity<FeaturedReleaseData>,
    FeaturedReleaseData
  > {
    return FeaturedReleaseManager.getInstance() as unknown as EntityManager<
      CatalogEntity<FeaturedReleaseData>,
      FeaturedReleaseData
    >;
  }

  get name() {
    return this.data.title;
  }

  get channel(): Channel {
    return ChannelManager.getInstance().getById(this.data.channelId);
  }

  get navPath(): string {
    return `/featured-releases/${this.id}`;
  }

  destroy = async () => {
    await FeaturedReleaseManager.getInstance().delete(this);
  };

  get catalogV3Data(): FeaturedReleaseV3Data {
    const result = pick(
      this.data,
      'slug',
      'accessibleDate',
      'releaseDate',
      'title',
      'illustrationUrl',
      'article',
      'preview'
    ) as FeaturedReleaseV3Data;
    result['storySlugs'] = (this.data.storySlugs || '')
      .split(',')
      .map(s => s.trim());
    if (isEmpty(result.preview) && result.article) {
      // default to first paragraph if not explicitly provided
      result.preview = result.article.split('\n\n', 1)[0].trim();
    }
    return result;
  }
}
