import { VolumeData } from '../catalog-types';
import { Volume } from '../models/volume';
import { isEmpty } from 'lodash';
import {
  CatalogCollections,
  collectionReference,
  docReference,
} from './catalog-db-paths';
import {
  CollectionReference,
  DocumentReference,
} from '@platform/firebase-types';

import { createLogger } from '@app/logger';
import { epochSecondsFloat } from '@masala-lib/utils';
import { ChannelCrud } from './channel-crud';
import { UnitCrud } from './unit-crud';
import { Channel } from '../models/channel';
import { EntityCrud } from './entity-crud';
const log = createLogger('volume-manager');

const collectionName = CatalogCollections.VOLUME_METADATA;

function docRef(id: string): DocumentReference<VolumeData> {
  return docReference<VolumeData>(collectionName, id);
}

function collectionRef(): CollectionReference<VolumeData> {
  return collectionReference<VolumeData>(collectionName);
}

async function loadById(
  id: string,
  {
    fetchParents = true,
    fetchChildren = false,
  }: { fetchParents?: boolean; fetchChildren?: boolean }
): Promise<Volume> {
  console.log(`${collectionName}.loadById(${id})`);
  if (isEmpty(id)) {
    return null;
  }
  const docSnapshot = await docRef(id).get();
  if (docSnapshot.exists === false) {
    log.warn(`loadById - missing doc: ${id}`);
    return null;
  }
  const data = docSnapshot.data();
  const model = new Volume(data);
  if (fetchParents) {
    model.fetchedChannel = await ChannelCrud.loadById(model.data.channelId, {});
    const { forkParentId } = data;
    if (forkParentId) {
      model.fetchedMasterVolume = await loadById(forkParentId, {
        fetchParents: false,
      });
    }
  }
  if (fetchChildren) {
    // model.fetchedUnits = await UnitCrud.loadAllForVolume(model); // will assign fetchedUnits into volume
    await model.fetchUnits();
  }
  return model;
}

async function loadAllForChannel(channel: Channel): Promise<Volume[]> {
  const querySnapshot = await collectionRef()
    .where('channelId', '==', channel.id)
    // .where('archived', '==', false) // beware. i don't trust that an explicit values exists for all data, so filtering in client-side code
    // .orderBy('unitNumber', 'asc')
    .get();
  const queriedVolumes = querySnapshot.docs.map(doc => {
    const data = doc.data();
    const model = new Volume(data);
    model.fetchedChannel = channel;
    return model;
  });
  const volumes = queriedVolumes.filter(volume => !volume.archived);
  // channel.fetchedVolumes = volumes;
  return volumes;
}

async function updatePartial(
  id: string,
  data: Partial<VolumeData>
): Promise<void> {
  console.log(`${collectionName}.updatePartial(${JSON.stringify(data)})`);
  data.updatedAt = epochSecondsFloat();

  // todo: factor out a docSetMerge() function with this guard
  if (isEmpty(data)) {
    // an empty object object will nuke the entire doc!
    return;
  }
  await docRef(id).set(data, { merge: true });
}

async function toggleArchive(data: VolumeData): Promise<void> {
  await updatePartial(data.id, { archived: !data.archived });
}

async function destroyArchived() {
  alert('not yet implemented');
}

export const VolumeCrud: EntityCrud<Volume, VolumeData> = {
  docRef,
  collectionRef,
  loadById,
  loadAllForChannel,
  updatePartial,
  toggleArchive,
  destroyArchived,
};
