import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Table } from '../shared/table';
import { JobManager } from '@masala-lib/jobs/job-manager';
import { Shelf } from '../shared/shelf';
import { alertError } from 'ui/misc-utils';
import { Job } from '@masala-lib/jobs/job';

export const JobsTable = observer(({ list }: { list: Job[] }) => {
  return (
    <Table
      data={list}
      cells={[
        {
          header: 'Id',
          render: job => {
            // fully qualified because shared between archived and active list views
            return <Link to={job.navPath}>{job.id}</Link>;
          },
        },
        {
          header: 'Kind',
          render: job => job.kind,
        },
        {
          header: 'Status',
          render: job => job.status,
        },
        {
          header: 'Stage',
          render: job => job.lastRunStage,
        },
        {
          header: 'Started',
          render: job => job.agePretty,
        },
        {
          header: 'Actions',
          render: job => <JobActions job={job} />,
        },
      ]}
    />
  );
});

const JobActions = observer(({ job }: { job: Job }) => {
  const toggleArchive = () => {
    JobManager.toggleArchive(job).catch(alertError);
  };

  return (
    <Shelf>
      <button
        onClick={toggleArchive}
        className={job.archived ? '' : `danger-action`}
      >
        {job.archived ? 'Unarchive' : 'Archive'}
      </button>
    </Shelf>
  );
});
