import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { NavLink, Outlet, Route, Routes, useParams } from 'react-router-dom';
import {
  AppUser,
  UserManager,
} from '@masala-lib/editorial/models/user-manager';
import { Auth } from '@masala-lib/editorial/db/auth';
import { SearchBar } from '../shared/search-bar';
import { FilterViewManager } from '@masala-lib/catalog/db/catalog-entity-manager';
import { Page } from '../shared/page';

import styled from 'styled-components';
import { useSwitch } from '../../lib/hooks/use-switch';
import { UserCreate } from './user-create';
import { PageActions } from '../shared/page-actions';
import { Lightbox } from '../../components/modals';
import { UserDetailScreen } from './user-detail-screen';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;

  .users-list {
    grid-column: 1/2;
    background-color: var(--color-gray20);
    min-height: calc(100vh - 48px);

    ul {
      list-style-type: none;

      /* li + li {
        border-top: 1px solid var(--color-gray50);
      } */

      li > a {
        display: block;
        font-size: 15px;
        line-height: 36px;
        padding: 0 12px 0 24px;
        font-weight: 400;
        color: var(--color-gray700);
        background: var(--color-transparent);
        margin: 8px;
        border-radius: 4px;
        &:hover {
          background: var(--color-blue50);
        }
        &.active {
          background: var(--color-blue50);
        }
      }
    }
  }

  .users-detail {
    grid-column: 2/3;
    padding: 24px;
    padding-left: 36px;

    .empty-state {
      font-size: 36px;
      font-family: roboto;
      color: var(--color-gray300);
    }
  }
`;

export const UserListScreen = () => {
  const auth = Auth.getInstance();
  const [manager] = useState(UserManager.getInstance());

  useEffect(() => {
    manager.loadAll();
  }, [manager]);

  return <View manager={manager} auth={auth} />;
};

const View = observer(
  ({ manager, auth }: { manager: FilterViewManager<AppUser>; auth: Auth }) => {
    // let { path, url } = useRouteMatch();
    const { path, url } = useParams<{ path: string; url: string }>();

    const editSwitch = useSwitch(false);
    const createSwitch = useSwitch(false);

    const list: AppUser[] = manager.list;

    return (
      <Page
        title="Users"
        controls={
          <PageActions>
            {auth.isSuperAdmin ? (
              <button onClick={createSwitch.on} className={'primary-action'}>
                Create new user
              </button>
            ) : (
              <p>[Create new user - ask Joseph]</p>
            )}
            <SearchBar manager={manager} />
          </PageActions>
        }
      >
        <Wrapper>
          <div className="users-list">
            <ul>
              {list.map((user: AppUser) => (
                <li key={user.id}>
                  <NavLink to={`${user.id}`}>{user.name ?? user.alias}</NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="users-detail">
            {/* // todo: disable 'new' and 'edit' paths if not admin */}
            <Lightbox
              showing={createSwitch.value}
              title={`Creating new user`}
              onDismiss={createSwitch.off}
            >
              <UserCreate manager={manager} onCancel={createSwitch.off} />
            </Lightbox>
            <Routes>
              <Route
                index
                element={<div className="empty-state">No user selected</div>}
              />
              <Route path={':id'} element={<UserDetailScreen />} />
            </Routes>
            <Outlet />
          </div>
        </Wrapper>

        {/* important to guard against the 'new' link when already editing */}
      </Page>
    );
  }
);

const Row = observer(({ model }: { model: AppUser }) => {
  return <>{model.alias}</>;
});
