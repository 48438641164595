import * as React from 'react';
import createListener from 'keyboardist';

interface KeyboardSubscription {
  unsubscribe: () => void;
}

type callbackFn = () => void;

interface KeyboardListener {
  subscribe: (keyName: string, fn: callbackFn) => KeyboardSubscription;
}

type listeners = { [key: string]: KeyboardListener };

// cache and lazy-load the global listeners
let __listeners: listeners = {};

const getListener = (name: string): KeyboardListener => {
  if (!__listeners[name]) {
    __listeners[name] = createListener(name);
  }
  return __listeners[name];
};

/**
 * set only one keyboard binding
 */
export const useKeyboardBinding = (
  keyName = '',
  fn = () => {},
  eventName = 'keydown'
) => {
  React.useEffect(() => {
    if (keyName) {
      const keyboardListener = getListener(eventName);
      const subscription = keyboardListener.subscribe(keyName, fn);
      return subscription.unsubscribe;
    }
  }, [eventName, fn, keyName]);
};
