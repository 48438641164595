import * as React from 'react';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Textarea } from '../../components/forms';
import { OnePaneLayout } from '../shared/layouts';
import { NavLink } from 'react-router-dom';
import { Button } from 'rebass';
import {
  CatalogEntityKind,
  InfoV5Data,
} from '@masala-lib/catalog/catalog-types';
import { StringToString } from '@tikka/basic-types';
import { CatalogEntity } from '@masala-lib/catalog/db/catalog-entity-manager';

const { UNIT, VOLUME } = CatalogEntityKind;

interface Props {
  dto: InfoV5Data;
  onSubmit: (dto: InfoV5Data) => void;
  cancelNavPath: string;
  entity: CatalogEntity<any>;
  l1Locale: string;
  l2Locale: string;
}

export const InfoV5Form = ({
  dto,
  onSubmit,
  cancelNavPath,
  entity,
  l1Locale,
  l2Locale,
}: Props) => {
  const defaultValues = dto;

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isVolume = entity.kind === CatalogEntityKind.VOLUME;
  const isLupa = entity.data.flavor === 'LUPA';

  const titleHint = isVolume
    ? ''
    : 'When "part suffix" is provided above, then only include the base title here. (Will inherit from Volume info if blank here)';
  const inheritHint = isVolume ? '' : 'will inherit from volume if blank';

  return (
    <OnePaneLayout>
      {/* entity.kind: {entity.kind}
      <br /> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        {isVolume ? null : (
          <Input
            {...getFieldProps('partSuffix')}
            // size={3} TODO: figure out how to support 'size' property
            label="Ignored now! (was `Title - Part suffix`)"
            // hint="i.e. '1'. Optional. Expected for multi-part Lupa content. Not sure yet what convention to be used for Bolero content. Drives the two-tone '[base] - Part [#]' display of titles within the app."
            hint="No longer used. The `Part #` label displayed in the app is now derived from the unit ordering"
          />
        )}
        <p>
          <strong>
            {isVolume ? (
              <>
                Volume level info is optional for Lupa, but expected for new
                content moving forward. <br />
                Will be used as the default data for the Unit level during
                ingestion.
              </>
            ) : (
              <>
                {isLupa ? (
                  <>
                    Note, for Lupa content, it's expected that only the "Part
                    Suffix" field above is used, and everything below is
                    inherited from the volume level info data and can be left
                    blank on this screen.
                  </>
                ) : (
                  <>
                    Note, for Bolero content, Unit level titles and descriptions
                    can be optionally provided below, when those are unique from
                    the Volume level data (i.e. for Wondery content). But
                    unecessary when simply splitting up a single story volume.
                  </>
                )}
              </>
            )}
          </strong>
        </p>
        <Input
          {...getFieldProps('titleL2')}
          label={`Title L2 (${l2Locale})`}
          hint={titleHint}
        />
        <Input
          {...getFieldProps('titleL1')}
          label={`Title L1 (${l1Locale})`}
          hint={titleHint}
        />
        <Textarea
          {...getFieldProps('descriptionL2')}
          label={`Description L2 (${l2Locale})`}
          rows={5}
        />
        <Textarea
          {...getFieldProps('descriptionL1')}
          label={`Description L1 (${l1Locale})`}
          rows={5}
        />
        {isVolume || isLupa ? (
          <>
            <Input
              {...getFieldProps('taglineL2')}
              label={`Tagline L2 (${l2Locale})`}
              hint={inheritHint}
            />
            <Input
              {...getFieldProps('taglineL1')}
              label={`Tagline L1 (${l1Locale})`}
              hint={inheritHint}
            />
            <Input
              {...getFieldProps('weblink')}
              hint={'when available. ' + inheritHint}
            />
            <Input
              {...getFieldProps('originalBroadcastDate')}
              validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
              hint="yyyy-mm-dd - when applicable"
            />
            {isLupa ? null : (
              <Input
                {...getFieldProps('seasonNumber')}
                hint="currently only relevant for Wondery content"
              />
            )}
          </>
        ) : null}
        <NavLink to={cancelNavPath}>[Cancel]</NavLink> &nbsp;{' '}
        <Button type="submit" backgroundColor={'teal'} px={32}>
          Save changes
        </Button>
        {/* <FormButtons submitLabel={'Save changes'} onCancel={onCancel} /> */}
      </form>
    </OnePaneLayout>
  );
};
