import { ChannelData, VolumeData } from '../catalog-types';
import { Volume } from '../models/volume';
import { isEmpty } from 'lodash';
import {
  CatalogCollections,
  collectionReference,
  docReference,
} from './catalog-db-paths';
import {
  CollectionReference,
  DocumentReference,
} from '@platform/firebase-types';

import { createLogger } from '@app/logger';
import { Channel } from '../models/channel';
import { epochSecondsFloat } from '@masala-lib/utils';
import { EntityCrud } from './entity-crud';
const log = createLogger('channel-cruid');

const collectionName = CatalogCollections.CHANNEL_METADATA;

function docRef(id: string): DocumentReference<ChannelData> {
  return docReference<ChannelData>(collectionName, id);
}

function collectionRef(): CollectionReference<ChannelData> {
  return collectionReference<ChannelData>(collectionName);
}

async function loadById(id: string): Promise<Channel> {
  console.log(`${collectionName}.loadById(${id})`);
  if (isEmpty(id)) {
    return null;
  }
  const docSnapshot = await docRef(id).get();
  if (docSnapshot.exists === false) {
    log.warn(`loadById - missing doc: ${id}`);
    return null;
  }
  const data = docSnapshot.data();
  const model = new Channel(data);
  return model;
}

async function updatePartial(
  id: string,
  data: Partial<ChannelData>
): Promise<void> {
  console.log(`${collectionName}.updatePartial(${JSON.stringify(data)})`);
  data.updatedAt = epochSecondsFloat();

  // todo: factor out a docSetMerge() function with this guard
  if (isEmpty(data)) {
    // an empty object object will nuke the entire doc!
    return;
  }
  await docRef(id).set(data, { merge: true });
}

async function toggleArchive(data: ChannelData): Promise<void> {
  await updatePartial(data.id, { archived: !data.archived });
}

async function destroyArchived() {
  alert('not yet implemented');
}

export const ChannelCrud: EntityCrud<Channel, ChannelData> = {
  docRef,
  collectionRef,
  loadById,
  updatePartial,
  toggleArchive,
  destroyArchived,
};
