import { alertMessages, mutationActions, scriptEditorModel } from '../app-root';
import { StructuralActions } from '@masala-lib/editorial/models/actions/structural-actions';

export class ScriptEditorStructuralActions extends StructuralActions {
  get mutationActions() {
    return mutationActions;
  }
  get content() {
    return scriptEditorModel.elements;
  }
  get alertMessages() {
    return alertMessages;
  }
}
