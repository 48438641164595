import { observable, makeObservable } from 'mobx';
import firebase from 'firebase';
import { firebaseAuth } from '../../../platform/firebase-auth';
import { UserManager, AppUser } from '../models/user-manager';
import { deploymentConfig } from '../../deployment-config';
import { notEmpty } from '@utils/conditionals';

const aclActions = [
  'edit_items',
  'archive_items',
  'restore_items',
  'permadelete_items',
  'see_archived_items',
  'make_catalog_live',
  'manage_excerpts',
  'manage_channels',
  'manage_volumes',
  'manage_units',
  'manage_activity_guides',
] as const;

type ACLAction = typeof aclActions[number];

export class Auth {
  authUser: firebase.User | null;
  userManager: UserManager;

  constructor() {
    this.authUser = null; // the firebase auth provided user data
    this.userManager = new UserManager({ listenMode: false });

    makeObservable(this, {
      authUser: observable.ref,
    });

    firebaseAuth.onAuthStateChanged((firebaseUser: firebase.User) => {
      this.authUser = firebaseUser;
      if (firebaseUser) {
        this.userManager.loadUserByEmail(firebaseUser.email, false); // listenMode: false
      }
    });
  }

  get appUser(): AppUser | undefined {
    return this.userManager.single;
  }

  get appUserId(): string | undefined {
    return this.appUser?.id;
  }

  get isAdmin(): boolean {
    return !!this.appUser?.isAdmin || !!this.appUser?.isSuperAdmin;
  }

  // roles would be better than flags, but it's enoug for now
  get isSuperAdmin(): boolean {
    return !!this.appUser?.isSuperAdmin;
  }

  get isReviewer(): boolean {
    return !!this.appUser?.isReviewer;
  }

  get isPartner(): boolean {
    return notEmpty(this.appUser.scopedChannelId);
  }

  // get mayMakeLive(): boolean {
  //   return !!this.appUser.mayMakeLive;
  // }

  get isLiveDb(): boolean {
    return deploymentConfig.firebaseConfigKey === 'editorial';
  }

  can(action: ACLAction) {
    // there should be a better way to factor this, but it's enough for now
    switch (action) {
      case 'edit_items':
      case 'see_archived_items':
      case 'archive_items':
      case 'restore_items':
        return this.isAdmin;
      case 'permadelete_items':
        return this.isSuperAdmin && !this.isLiveDb;
      case 'make_catalog_live':
        return !!this.appUser.mayMakeLive;
      case 'manage_excerpts': // for now open to any normal user
        return !this.isReviewer;
      case 'manage_volumes':
        return this.isAdmin || this.isPartner;
      case 'manage_units':
        return this.isAdmin || this.isPartner;
      case 'manage_channels':
        return this.isAdmin;
      case 'manage_activity_guides':
        return this.isAdmin;

      default:
        return false;
    }
  }

  private static instance: Auth;

  public static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
      // useful for development
      if (process.env.NODE_ENV !== 'production') {
        (<any>global).auth = Auth.instance;
      }
    }
    return Auth.instance;
  }

  public static currentAlias(): string {
    return Auth.getInstance().appUser?.alias || 'SERVER';
  }
}
