import * as React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSwitch } from '../../lib/hooks/use-switch';

interface ModelScreenController {
  startEditing: () => void;
  stopEditing: () => void;
  closeDetail: () => void;
}

type ModelScreenRenderer = (
  controller: ModelScreenController
) => React.ReactNode;

interface ModelScreenProps {
  path: string;
  renderList?: ModelScreenRenderer;
  renderDetail?: ModelScreenRenderer;
  renderEditForm?: ModelScreenRenderer;
  renderCreateForm?: ModelScreenRenderer;
}

export const ModelScreen = ({
  path,
  renderList,
  renderDetail,
  renderEditForm,
  renderCreateForm,
}: ModelScreenProps) => {
  const editSwitch = useSwitch(false);
  const navigate = useNavigate();
  // const match = useRouteMatch();

  const controller: ModelScreenController = {
    startEditing: editSwitch.on,
    stopEditing: editSwitch.off,
    // closeDetail: () => history.replace(path),
    closeDetail: () => navigate(-1),
  };

  return (
    <Routes>
      <Route
        path={'new'}
        element={
          <div className="model-detail">{renderCreateForm(controller)}</div>
        }
      />
      <Route
        path={':id'}
        element={
          <div className="model-detail">
            {editSwitch.value
              ? renderEditForm(controller)
              : renderDetail(controller)}
          </div>
        }
      />
      <Route
        index
        element={<div className="model-list">{renderList(controller)}</div>}
      />
    </Routes>
  );
};
