import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import * as React from 'react';
import { BiSolidTrash } from 'react-icons/bi';
import { FaCheckCircle, FaClock, FaPlus } from 'react-icons/fa';
import { MdSubtitles } from 'react-icons/md';
import { TbFlag3Filled } from 'react-icons/tb';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';

const Wrapper = styled('div', {
  display: 'flex',
  gap: 4,
  transition: 'opacity 0.13s ease',
  '&.disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
    filter: 'grayscale(50%)',
  },
});

const ToolbarButtonWrapper = styled('button', {
  $$iconColor: '$colors$blue-300',
  $$backgroundColor: '$colors$black-alpha-10',
  all: 'unset',
  width: 42,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  cursor: 'pointer',
  backgroundColor: '$$backgroundColor',
  transition: 'background-color 0.15s ease, color 0.2s ease',
  '& .icon': {
    color: '$$iconColor',
    '& svg': {
      width: 14,
      height: 14,
    },
  },

  '&:hover': {
    $$iconColor: '$colors$blue-200',
    $$backgroundColor: '$colors$black-alpha-20',
  },
});

export const ToolbarButton = ({
  icon,
  ...props
}: React.ComponentProps<typeof ToolbarButtonWrapper> & {
  icon: React.ReactNode;
}) => {
  return (
    <ToolbarButtonWrapper {...props} tabIndex={-1}>
      <span className="icon">{icon}</span>
    </ToolbarButtonWrapper>
  );
};

export const TranslationModalToolbar = observer(
  ({ editor }: { editor: TouchUpEditorModal }) => {
    const element = editor.currentElement;
    const id = element?.id;
    const isRef = editor.elementIsReference(element);
    const parentFocusElement = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
      parentFocusElement.current = document.activeElement as HTMLElement;
    }, []);

    const disabled = !isRef;

    // if (!isRef) {
    //   // only needed for reference elements
    //   return null;
    // }

    return (
      <Wrapper className={disabled ? 'disabled' : ''}>
        <ToolbarButton
          icon={<FaPlus />}
          onClick={() => void editor.handleAddTranslation(id)}
          title={'Add/edit free-form translation (A)'}
          disabled={disabled}
          // testId="add-translation-button"
        />
        <ToolbarButton
          icon={<MdSubtitles />}
          onClick={() => void editor.editNote(id)}
          title={'Add/edit note (N)'}
          disabled={disabled}
          // testId="add-note-button"
        />
        <ToolbarButton
          icon={<TbFlag3Filled />}
          onClick={() => void editor.markPending(id)}
          title={'Flag (F)'}
          disabled={disabled}
          // testId="flag-button"
        />
        <ToolbarButton
          icon={<FaClock />}
          onClick={() => void editor.markDeferred(id)}
          title={'Defer (L)'}
          disabled={disabled}
          // testId="defer-button"
        />
        <ToolbarButton
          icon={<FaCheckCircle />}
          onClick={() => void editor.markResolved(id)}
          title={'Resolve (R)'}
          disabled={disabled}
          // testId="resolve-button"
        />
        <ToolbarButton
          icon={<BiSolidTrash />}
          onClick={() => void editor.deleteNote(id)}
          title={'Delete note'}
          disabled={disabled}
          // testId="delete-note-button"
        />
      </Wrapper>
    );
  }
);
