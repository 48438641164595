import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { ScriptElement } from '@masala-lib/llm/llm-types';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import { computed } from 'mobx';
import { IconButton } from '@naan/primitives/button';
import { TbFlag3Filled } from 'react-icons/tb';
import { FaClock } from 'react-icons/fa';
import { MdOutlineSubtitlesOff } from 'react-icons/md';
import { ElementExchangeCodeAnnotation } from './exchange-code-annotation';
import { elide } from '@utils/string-utils';

export type ElementStyleInfo = {
  prefix: string | ((s: ScriptElement) => string);
  styleName: string;
};

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 4,
});

const IconWrapper = styled('div', {
  color: 'var(--icon-color,currentColor)',
  // desaturate
  opacity: 0.8,
  '&:hover': {
    opacity: 1.0,
  },
});

const ElementIconButton = ({
  icon,
  color = 'currentColor',
  ...props
}: Omit<
  React.ComponentProps<typeof IconButton>,
  'size' | 'radius' | 'presentation'
> & {
  color?: string;
}) => {
  return (
    <IconButton
      {...props}
      size="masalaExtraSmall"
      radius={'squared'}
      icon={
        <IconWrapper style={{ '--icon-color': color } as React.CSSProperties}>
          {icon}
        </IconWrapper>
      }
      testId="toolbar-button" // should be qualified, but not worth messing with for masala
    />
  );
};

const ElidedWrapper = styled('div', {
  fontSize: 10,
  color: '$black-alpha-50',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100px',
  '&.unflagged': {
    color: '$black-alpha-20',
    textDecoration: 'line-through',
  },
});

export const ScriptElementControls = observer(
  ({
    element,
    modal,
  }: {
    element: ScriptElement;
    modal: TouchUpEditorModal;
  }) => {
    const id = element.id;
    // const kind = element.kind;
    const samosaFlagged = computed(() => modal.isPendingFlagged(id)).get();
    const masalaFlagged = computed(() => modal.isDeferFlagged(id)).get();
    const flagged = samosaFlagged || masalaFlagged;
    const hasNote = computed(() => modal.hasNote(id)).get();
    const noteText = computed(() => modal.activeNotes.get(id)).get() ?? '';
    const textClass = flagged ? undefined : 'unflagged';

    return (
      <Wrapper className="inline-controls">
        {modal.showNotes && hasNote ? (
          <ElidedWrapper className={textClass}>{noteText}</ElidedWrapper>
        ) : null}
        <ElementFlag element={element} modal={modal} />
        <ElementExchangeCodeAnnotation element={element} />
      </Wrapper>
    );
  }
);

export const ElementFlag = observer(
  ({
    element,
    modal,
  }: {
    element: ScriptElement;
    modal: TouchUpEditorModal;
  }) => {
    const id = element.id;
    // const kind = element.kind;
    const samosaFlagged = computed(() => modal.isPendingFlagged(id)).get();
    const masalaFlagged = computed(() => modal.isDeferFlagged(id)).get();
    const hasNote = computed(() => modal.hasNote(id)).get();

    // const isTranslation = kind === 'TRANSLATION';

    return (
      <Wrapper className="inline-controls">
        {samosaFlagged ? (
          // <IconButton
          //   onClick={() => modal.editNote(id)}
          //   size="masalaExtraSmall"
          //   icon={<MdOutlineStickyNote2 />}
          //   radius={'squared'}
          // />
          <ElementIconButton
            onClick={() => modal.editNote(id)}
            // icon={<MdStickyNote2 />}
            icon={<TbFlag3Filled />}
            color={'var(--color-teal600)'}
            testId="edit-note-flagged"
          />
        ) : null}
        {masalaFlagged ? (
          // <IconButton
          //   onClick={() => modal.editNote(id)}
          //   size="masalaExtraSmall"
          //   icon={<TbClockHour3 />}
          //   radius={'squared'}
          // />
          <ElementIconButton
            onClick={() => modal.editNote(id)}
            icon={<FaClock />}
            color={'var(--color-orange400)'}
            testId="edit-note-later"
          />
        ) : null}
        {hasNote && !samosaFlagged && !masalaFlagged ? (
          // note in conceptual 'resolved' state
          <IconButton
            onClick={() => modal.editNote(id)}
            size="masalaExtraSmall"
            icon={<MdOutlineSubtitlesOff />}
            radius={'squared'}
            testId="edit-note-resolved"
          />
        ) : null}
        {/* <ElementIconButton
          onClick={() => modal.editNote(id)}
          icon={<TbFlag3Filled />}
          color={'var(--color-teal500)'}
        /> */}
      </Wrapper>
    );
  }
);
