import * as NaanDialogs from '@naan/primitives/modals/dialog';
// import { usePauseKeyboardService } from 'lib/services/keyboard-service';

export const Heading = NaanDialogs.Heading;
export const Body = NaanDialogs.Body;
export const ButtonsContainer = NaanDialogs.ButtonsContainer;
export const Button = NaanDialogs.Button;
export const SmallButton = NaanDialogs.SmallButton;
export const CloseButton = NaanDialogs.CloseButton;
export const HtmlCloseButton = NaanDialogs.HtmlCloseButton;
export const Container = NaanDialogs.Container;

// export const Container = (
//   props: React.ComponentProps<typeof NaanDialogs.Container>
// ) => {
//   usePauseKeyboardService();
//   return <NaanDialogs.Container {...props} />;
// };
