import {
  ExcerptData,
  ExcerptKind,
  ExcerptStatus,
} from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Textarea } from '../../components/forms';
import { DataItem } from '../../components/data-display/data-item';
import { blankOption } from '@masala-lib/catalog/db/catalog-entity-manager';
import { Excerpt } from '@masala-lib/catalog/models/excerpt';

interface Props {
  dto: ExcerptData;
  onSubmit: (dto: ExcerptData) => void;
  onCancel: () => void;
  model: Excerpt;
}

const kindOptions = [
  blankOption,
  ...Object.values(ExcerptKind).map(key => ({ value: key, label: key })),
];

const statusOptions = [
  blankOption,
  ...Object.values(ExcerptStatus).map(key => ({ value: key, label: key })),
];

const categoryOptions = [
  'accents',
  'vernacular',
  'vocabulary',
  'grammar',
  'patterns',
  'idioms',
  'strategies',
  'culture',
];

const categorySet = new Set(categoryOptions);

function validateSoundbiteCategoriesString(categories: string): boolean {
  const categoriesArray = categories.split(',').map(s => s.trim());
  for (const category of categoriesArray) {
    if (!categorySet.has(category)) {
      return false;
    }
  }
  return true;
}

export const ExcerptForm = ({ dto, onSubmit, onCancel, model }: Props) => {
  const defaultValues = pick(dto, [
    'unitId',
    'name',
    'title',
    'slug',
    'elementId',
    'excerptKind',
    'status',
    'sourceDocUrl',
    'linearUrl',
    'internalNotes',
    'difficulty',
    'categories',
    'promptEn',
    'prompt',
    'vocabulary',
    'explanationEn',
    'explanation',
    'releaseDate',
  ]);

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);
  // const unitOptions = UnitManager.getInstance().selectOptionsWithBlank;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* <Select
        {...getFieldProps('unitId')}
        options={unitOptions}
        required={false}
      /> */}
      <Input
        {...getFieldProps('name')}
        label={'Editorial short summary (shown on list views)'}
        required
      />
      <DataItem label={'Unit id'} value={dto.unitId} />
      <DataItem label={'Element id'} value={dto.elementId} />
      <Select {...getFieldProps('excerptKind')} options={kindOptions} />
      <Select {...getFieldProps('status')} options={statusOptions} />
      <Input
        {...getFieldProps('sourceDocUrl')}
        label={'Google Doc URL'}
        hint="It is expected that a google doc will be used as the authoritative source for the prompt and explanation text and then copied from there into the form fields below when ready to be ingested. In the future, we hope to support a way to automatically import the text from the referenced google doc."
      />
      <Input
        {...getFieldProps('linearUrl')}
        label={'Linear URL'}
        hint="(optional)"
      />
      <Textarea
        {...getFieldProps('internalNotes')}
        label={'Internal notes'}
        rows={2}
      />
      <Input
        // @armando, how do we get this automatically handled as a number?
        {...getFieldProps('difficulty')}
        label={'Difficulty'}
        hint="1,2,3 (internal)"
      />
      <Input
        {...getFieldProps('categories')}
        validateFunc={validateSoundbiteCategoriesString}
        hint={categoryOptions.join(', ')}
        label={'Categories'}
      />
      <hr />
      <Input {...getFieldProps('title')} label={'Title'} required />
      {/* // todo: support multiselect */}
      {/* <Input {...getFieldProps('categories')} label={'Categories'} /> */}
      {model?.volume?.l1 !== 'en' ? (
        <Textarea
          {...getFieldProps('promptEn')}
          label={'English prompt text (Internal)'}
          hint={
            'Markdown (you can use **bold** _italics_ etc)' //. Should be manually copied from L1 if forked from an Eng L1 channel.'
          }
          rows={6}
        />
      ) : null}
      <Textarea
        {...getFieldProps('prompt')}
        label={`L1 [${model?.volume?.l1}] prompt text (User facing)`}
        hint={'Markdown (you can use **bold** _italics_ etc)'}
        rows={6}
      />
      <Textarea
        {...getFieldProps('vocabulary')}
        label={'Vocabulary text'}
        hint={'Markdown'}
        rows={6}
      />
      {model.volume?.l1 !== 'en' ? (
        <Textarea
          {...getFieldProps('explanationEn')}
          label={'English explanation text (Internal)'}
          hint={'Markdown'}
          rows={6}
        />
      ) : null}
      <Textarea
        {...getFieldProps('explanation')}
        label={`L1 [${model?.volume?.l1}] explanation text (User facing)`}
        hint={'Markdown'}
        rows={6}
      />
      <Input
        {...getFieldProps('releaseDate')}
        validPattern={/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/}
        hint={'YYYY-MM-DD'}
      />
      {/* <Checkbox {...getFieldProps('active')} label="Active"></Checkbox> */}
      <FormButtons
        submitLabel={isNew ? 'Create' : 'Save changes'}
        onCancel={onCancel}
      />
      <Input {...getFieldProps('slug')} label={'Slug'} required />
    </form>
  );
};
