import * as React from 'react';
import * as Dialog from '@console/components/samosa-dialogs';
import { useSamosaModel } from '../../models/samosa-model-handle';
import { agePrettyTimestamp } from '@masala-lib/utils';
import { Input } from '@console/components/forms';
import { useMasalaForm } from '@console/lib/hooks/use-masala-form';
import { styled } from '@naan/stitches.config';
// import { createLogger } from '@app/logger';
// const log = createLogger('project-name-form');

//
// place holder UI to edit project name
//

interface FormData {
  name: string;
}

export const EditNameFormModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const model = useSamosaModel();

  const { handleSubmit, getFieldProps } = useMasalaForm<FormData>({
    defaultValues: {
      name: model.projectName,
    },
  });

  const onSubmit = async (
    data: FormData
    // event: any /*React.BaseSyntheticEvent*/
  ) => {
    await model.updateProjectName(data.name);
    onDismiss();
  };
  return (
    <Dialog.Container open onDismiss={onDismiss} css={{ top: '50%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog.Body>
          <Input {...getFieldProps('name')} label={'Project name'} />
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label="Cancel"
            presentation="grayLight"
          ></Dialog.Button>
          <Dialog.Button
            label="Rename"
            presentation="blue"
            type="submit"
          ></Dialog.Button>
        </Dialog.ButtonsContainer>
      </form>
    </Dialog.Container>
  );
};

const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  '& > div': {
    display: 'flex',
    gap: 8,
    textStyle: 'body',
    padding: '8px 0',
    '& + &': {
      borderTop: '1px solid $black-alpha-06',
    },
    '& .label': {
      textStyle: 'body-bold',
      color: '$gray-500',
    },
  },
});

export const ProjectInfoModal = ({ onDismiss }: { onDismiss: () => void }) => {
  const model = useSamosaModel();
  const metadata = model.projectMetadata;

  return (
    <Dialog.Container open onDismiss={onDismiss} css={{ top: '50%' }}>
      <Dialog.Heading>Project info</Dialog.Heading>
      <Dialog.CloseButton />
      <Dialog.Body>
        <InfoWrapper>
          <div>
            <span className="value">{model.projectName}</span>
          </div>
          {model.archived ? <div className="">archived: (ARCHIVED)</div> : null}
          <div>
            <span className="label">Archived</span>
            <span className="value">{model.archived ? 'yes' : 'no'}</span>
          </div>
          <div>
            <span className="label">Started</span>
            {agePrettyTimestamp(metadata.timestamp)}
          </div>
          <div>
            <span className="label">Task</span>
            <span className="value">{metadata.task}</span>
          </div>
          <div>
            <span className="label">Sub-Task</span>
            <span className="value">{metadata.subtask}</span>
          </div>
          <div>
            <span className="label">Status </span>
            <span className="value">{model.state}</span>
          </div>

          {!!metadata.completedTimestamp ? (
            <div>
              <span className="label">merged back</span>
              <span className="value">
                {agePrettyTimestamp(metadata.completedTimestamp)}
              </span>
            </div>
          ) : null}
        </InfoWrapper>
      </Dialog.Body>
    </Dialog.Container>
  );
};
