import { Logger } from './logger';

const LoggerInstancesCache = new Map<string, Logger>();

export function createLogger(name: string) {
  if (LoggerInstancesCache.has(name)) {
    return LoggerInstancesCache.get(name)!;
  }
  const logger = new Logger(name);
  LoggerInstancesCache.set(name, logger);

  return logger;
}
