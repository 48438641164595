import { JobKind } from '@masala-lib/jobs/job-kind';
import { InputsNotReady, JobId, StageParams } from './job-types';
import { sleep } from '@utils/util';

import { createLogger } from '@app/logger';
const log = createLogger('hello-multi-stage-job');

const INITIALIZE_AND_START = 'INITIALIZE_AND_START';
const CHECK_STATUS_AND_PROCESS_RESULT = 'CHECK_STATUS_AND_PROCESS_RESULT';
const SAVE_RESULTS = 'SAVE_RESULTS';

interface HelloMultiParams {
  name: string;
  sleepSeconds: number;
  eventualResult: any;
}

interface StartStageOutput {
  result: string;
  actualDurationMillis: number;
}

type ProcessResultsStageOutput = {
  words: string[];
};

export async function createHelloMultiJob(
  params: HelloMultiParams
): Promise<JobId> {
  log.info(`createHelloMultiJob: ${JSON.stringify(params)} `);
  const jobId = await jobKind.createJob(params);
  log.info(`jobId: ${JSON.stringify(jobId)}`);
  return jobId;
}

export async function restartHelloMultiJobPoller() {
  log.info(`restartHelloMultiJobPoller`);
  jobKind.setupPollingTimerIfNeeded();
}

export class HelloMultiStageJobKind extends JobKind {
  get kind() {
    return 'HELLO-MULTI';
  }

  protected get stageMethods() {
    return [
      [INITIALIZE_AND_START, this.initializeAndStart],
      [CHECK_STATUS_AND_PROCESS_RESULT, this.checkStatusAndProcessResult],
      [SAVE_RESULTS, this.saveResults],
    ] as const;
  }

  private async initializeAndStart({
    jobData,
    stageData,
    stageOutputData,
    logger,
  }: StageParams): Promise<void> {
    const output = stageOutputData as StartStageOutput;
    const params = jobData.params as HelloMultiParams;

    (window as any).helloStatus = undefined;
    (window as any).helloResult = undefined;

    logger.info('inside initializeAndStart', params);
    this.setRelativePollTimesForStageAsyncOutputs(
      {
        start: 0,
        end: 5 * 60, // 5 minutes
      },
      stageData
    );

    setTimeout(() => {
      (window as any).helloStatus = 'processing';
    }, 1000);

    await sleep(params.sleepSeconds * 1000);
    logger.info('nap completed');

    output.result = params.eventualResult;
  }

  private async checkStatusAndProcessResult({
    jobData,
    stageOutputData,
    logger,
  }: StageParams): Promise<void> {
    const jobName = jobData.id;
    logger.info('first stage job name:', jobName);
    const output = stageOutputData as ProcessResultsStageOutput;

    this.checkCanPollAsyncOutForStage(INITIALIZE_AND_START, jobData);
    logger.info('reached: attempt poll status of first stage');

    await sleep(500);
    const helloStatus = (window as any).helloStatus;
    logger.info('fetched hello status:', helloStatus);

    logger.info('sleeping 5 seconds');
    await sleep(5000);

    if (helloStatus === 'fail') {
      throw Error('first stage failed to process');
    }

    if (helloStatus !== 'done') {
      throw new InputsNotReady('first stage not ready');
    }

    const resultText = (window as any).helloResult || 'how now';
    logger.info('resultText:', resultText);
    output.words = resultText.split(' ');
  }

  private async saveResults({ jobData, logger }: StageParams): Promise<void> {
    const startStageData = this.retrieveStageOutputData(
      INITIALIZE_AND_START,
      jobData
    ) as StartStageOutput;
    logger.info('startStageData', startStageData);

    const secondStageData = this.retrieveStageOutputData(
      CHECK_STATUS_AND_PROCESS_RESULT,
      jobData
    ) as ProcessResultsStageOutput;
    logger.info('resultStageData', secondStageData);
    if (secondStageData.words[0] === 'death') {
      throw Error(`second stage error: ${secondStageData.words.join(' ')}`);
    }
    window.alert(
      `Save results: ${JSON.stringify(startStageData)}, ${JSON.stringify(
        secondStageData
      )}`
    );
  }
}

const jobKind = new HelloMultiStageJobKind();
(window as any).helloMultiStageJobKind = jobKind;
