import {
  JobData,
  JobId,
  JobRequestParams,
  JobStatus,
  StageData,
} from '@masala-lib/jobs/job-types';
import { makeObservable, observable } from 'mobx';
import { JobManager } from './job-manager';
import { createLogger } from '@app/logger';
import { isEmpty } from 'lodash';
import { strongNormalizeWord } from '@masala-lib/misc/editorial-string-utils';
import { agePrettyTimestamp } from '@masala-lib/utils';

const log = createLogger('job');

export class Job implements JobData {
  id: JobId;

  @observable
  shallowCopy?: boolean;

  @observable
  kind: string;

  @observable
  params: JobRequestParams;

  @observable
  lastRunStage: string;

  @observable
  lastRunSubStage: string;

  @observable
  startTimestamp: number;

  @observable
  exitTimestamp: number;

  @observable
  status: JobStatus;

  @observable
  stages: { [index in string]: StageData };

  @observable
  archived: boolean;

  @observable
  errorMessage?: string; // capture unexpected error outside the scope of a stage
  @observable
  errorStack?: string;

  unsubscribeFn: () => void;

  constructor(data: Partial<JobData>) {
    makeObservable(this);
    Object.assign(this, data); // todo: think about this more
  }

  get stageList(): StageData[] {
    if (isEmpty(this.stages)) {
      return [];
    }

    const result = Object.entries(this.stages).map(([key, value]) => ({
      stageName: key,
      ...value,
    }));
    return result;
  }

  stageLogs(stageName: string): string {
    const stage = this.stages[stageName];
    if (isEmpty(stage?.logMessageMap)) {
      return '';
    }
    const result = Object.entries(stage.logMessageMap)
      .map(([counter, log]) => `${counter}) ${log.level}: ${log.message}`)
      .join('\n');
    return result;
  }

  get navPath(): string {
    return `/jobs/${this.id}`;
  }

  get agePretty(): string {
    return agePrettyTimestamp(this.startTimestamp);
  }

  async archive(): Promise<void> {
    await this.updatePartial({ archived: true });
  }

  async unarchive(): Promise<void> {
    await this.updatePartial({ archived: false });
  }

  async updatePartial(data: Partial<JobData>): Promise<void> {
    Object.assign(this, data); // todo: think about this more

    await JobManager.updatePartial(this.id, data);
  }

  // destroy = async () => {
  //   await JobManager.destroy(this);
  // };

  listen() {
    this.stopListening();
    log.debug(`job(${this.id}).listen`);
    this.unsubscribeFn = JobManager.docRef(this.id).onSnapshot(docSnapshot => {
      const data = docSnapshot.data();
      log.debug(`job(${this.id}).onSnapshot, data:`, data);
      Object.assign(this, data); // todo: think about this more
      this.archived = data.archived;
    });
  }

  stopListening() {
    if (this.unsubscribeFn) {
      log.debug(`job(${this.id}).stopListening`);
      this.unsubscribeFn();
      this.unsubscribeFn = undefined;
    }
  }

  // matches against either name or slug, ignoring case, accents and punctuation
  filterMatch(text: string): boolean {
    if (isEmpty(text)) {
      return true;
    }
    const match = strongNormalizeWord(text);
    return (
      strongNormalizeWord(this.id).includes(match) ||
      strongNormalizeWord(this.kind).includes(match) ||
      strongNormalizeWord(this.status).includes(match)
      // todo: log search
    );
  }
}
