import * as React from 'react';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { SettingsExtraSmallIcon } from '@naan/icons/settings-icon';
import { IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { SimpleDialog } from '../components/simple-dialog';
import { LlmSettingsForm } from './llm-settings-form';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { GearIcon } from '@naan/icons/gear-icon';

const Wrapper = styled('div', {
  // backgroundColor: '$colors$gray-800',
  backgroundColor: 'color-mix(in srgb, $colors$gray-800 80%, $colors$blue-800)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // padding: '12px 0',
  height: 48,
  color: '$colors$blue-50',
  '& .button-inner': { color: '$colors$blue-100' },
});

export const Settings = observer(({ goBack }: { goBack: () => void }) => {
  const model = useSamosaModel();

  return (
    <Wrapper>
      <IconButton
        icon={<CloseSmallIcon />}
        size={'small'}
        onClick={goBack}
        testId="close-settings-button"
      />
      {model.llmOptions?.model} {model.llmOptions?.temperature}
      <LlmSettingsForm />
    </Wrapper>
  );
});
