import {
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import React from 'react';
// import { createListener, KeyboardistListener } from 'keyboardist';
import Keyboardist from 'keyboardist';
import {
  scriptEditorModel,
  alertMessages,
  keyboardModes,
} from './models/app-root';
import { Alert } from '@masala-lib/misc/alert-messages';
import {
  Element,
  CreateElementList,
  ElementList,
  SimpleElementList,
  Structural,
  WordGroup,
  Sentence,
  EditorLineElement,
  ElementId,
} from '@masala-lib/editor-aliases';
import { WordId, idIsOfKind } from '@tikka/basic-types';
import {
  Location,
  getVisibility,
  scrollIfNotVisible,
} from '@masala-lib/editorial/ui/dom-scroll';
import { EKind, WordGroupSubKind } from '@masala-lib/element-kinds';
import { swalPromptYN } from '@masala-lib/misc/sweetalert-yn';
import {
  CHOICE_MODE,
  DEACTIVATED,
  EDITING,
  LOCKED,
  NORMAL,
  ScriptEditorModel,
} from './models/script-editor-model';
import { openFiltersDialog } from './ui/filters-dialog';
import { openVersionsDialog } from './ui/versions-dialog';
import { openWordGroupInspectorDialog } from './word-group-inspector/word-group-inspector-dialog';
import { makeAdhocRangeElement } from '@tikka/elements/ad-hoc-word-range';
import { observer } from 'mobx-react';

// import { renderView as renderScriptEditorView } from './views/script-editor-view.js';
import { ScriptLineEditor } from './ui/script-line-editor';
import { renderWordRange } from '@masala-lib/editorial/ui/word-range-render';
import {
  domIdToElementId,
  elementIdToDomId,
  getKindFromId,
} from '@tikka/elements/element-id-utils';
import { openHistoryDialog } from './ui/history-dialog';
import { openSidePopup } from '../console/views/shared/side-popup';
import { renderStylesForDomId } from '@masala-lib/editorial/ui/style-painting/dom-styles';
import {
  Translation,
  VersionableElement,
  WordGroupContent,
} from '@masala-lib/editorial-types';
import {
  MembershipList,
  CreateMembershipList,
  CreateMembershipReconciler,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { ETOf } from '@masala-lib/chaat-aliases';
// import { llmTestTranslationTaskFunctions } from '@masala-lib/llm/llm-funcs';

interface LineEditorProps {
  getIsEditingId: (id: ElementId) => boolean;
}

function versionedElementKey(element: VersionableElement) {
  // TODO move to utils in F#
  return element.id + ':' + element.timestamp;
}

function createChoice(text: string) {
  const highlight = (str: string) =>
    str.replace(/@(.)/, '<strong><u>$1</u></strong>');
  return `<div>${highlight(text)}</div>`;
}

const VERBATIM_OPS = 'VERBATIM_OPS';
const WORD_GROUP_CREATE = 'WORD_GROUP_CREATE';
const STRUCTURAL_CREATE = 'STRUCTURAL_CREATE';

const HISTORY_OPS = 'HISTORY_OPS';
const GLOBAL_OPS = 'GLOBAL_OPS';
const METADATA_BLOCK_OPS = 'METADATA_BLOCK_OPS';

interface Props {}

@observer
export class ScriptEditor extends React.Component<Props> {
  model: ScriptEditorModel = scriptEditorModel;
  activeLineEditor: any = null;
  lastClickTime = 0.0;
  currentChoiceMode: string = null;
  choiceModePopupContent: Map<string, string> = new Map();
  disposers: (() => void)[] = [];

  @observable.ref quantizedScrollPos = 0;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.initializeInputHandlers();
    this.adjustInputHandlingForMode();
    this.model.setGetLineEditBuffer(() => {
      return {
        modified: this.activeLineEditor.modified,
        text: this.activeLineEditor.getContentFromEditor(),
      };
    });

    this.disposers.push(
      reaction(
        () => this.membershipLists,
        () => this.renderMembershipLists()
      )
    );
    this.disposers.push(
      reaction(
        () => this.model.mode,
        () => this.adjustInputHandlingForMode()
      )
    );
    this.disposers.push(
      reaction(
        () => this.model.focusedLineId,
        () =>
          scrollIfNotVisible(this.model.focusedLineId, 'nearest', 'editorId')
      )
    );
    this.disposers.push(
      reaction(
        () => this.quantizedScrollPos,
        () => this.adjustCurrentChapterForScrollPos()
      )
    );
  }

  saveLineEdit() {}

  adjustInputHandlingForMode() {
    if (this.model.mode === DEACTIVATED) {
      keyboardModes.setKbDeactivated(true);
    } else {
      keyboardModes.setKbDeactivated(false);
    }

    if (this.model.mode === CHOICE_MODE) {
      keyboardModes.setMode(this.currentChoiceMode);
    } else if (this.model.mode !== DEACTIVATED) {
      keyboardModes.setMode(this.model.mode);
    }
  }

  handleKbFocusChange() {}

  enterChoiceModalMode(mode: string) {
    runInAction(() => {
      this.currentChoiceMode = mode;
      this.model.setChoiceModalMode(true);
      const popupHtml =
        this.choiceModePopupContent.get(this.currentChoiceMode) ?? null;
      this.model.setChoiceModalHtml(popupHtml);
    });
  }

  exitChoiceModalInputHandling() {
    runInAction(() => {
      this.model.setChoiceModalMode(false);
      this.model.setChoiceModalHtml('');
    });
  }

  handleKBModalChoiceMade() {
    this.exitChoiceModalInputHandling();
  }

  enterWordGroupCreateChoiceMode() {
    if (!this.model.standardAccessEnabled) {
      alertMessages.add({ ...Alert, text: 'insufficient access' });
      return;
    }
    this.enterChoiceModalMode(WORD_GROUP_CREATE);
  }

  enterStructuralCreateChoiceMode() {
    if (!this.model.standardAccessEnabled) {
      alertMessages.add({ ...Alert, text: 'insufficient access' });
      return;
    }
    this.enterChoiceModalMode(STRUCTURAL_CREATE);
  }

  enterVerbatimOpsChoiceMode() {
    if (!this.model.standardAccessEnabled) {
      alertMessages.add({ ...Alert, text: 'insufficient access' });
      return;
    }
    this.enterChoiceModalMode(VERBATIM_OPS);
  }

  enterHistoryOpsChoiceMode() {
    this.enterChoiceModalMode(HISTORY_OPS);
  }

  enterGlobalOpsChoiceMode() {
    this.enterChoiceModalMode(GLOBAL_OPS);
  }

  handleScroll(event: React.UIEvent<HTMLElement, UIEvent>) {
    this.quantizedScrollPos =
      Number((event.target as HTMLElement)['scrollTop']) % 20;
  }

  adjustCurrentChapterForScrollPos() {
    let chapter = null;
    const chapters = this.model.elements.filterByKind(
      EKind.CHAPTER
    ) as ElementList<Structural>;
    for (const element of chapters.values) {
      const placement = getVisibility(element.id, 'editorId');
      if (placement === Location.BelowScreen) {
        break;
      }
      chapter = element;
    }
    if (chapter && chapter.content) {
      this.model.currentChapterTitle = chapter.content.text;
    } else {
      this.model.currentChapterTitle = '';
    }
  }

  handleWordClick(event: MouseEvent, id: WordId) {
    if (event.getModifierState('Shift')) {
      this.model.wordRangeSelectTo(id);
    } else {
      this.model.setCurrentWordId(id);
    }
  }

  handleLineDoubleClick(event: MouseEvent, domId: string) {
    // TODO need to use domId or assume that prior click event already set focused line?
    if (this.model.editEnabled) {
      this.model.editFocusedLine();
    }
  }

  handleLineClick(event: MouseEvent, domId: string) {
    const currentTime = Date.now();
    if (currentTime < this.lastClickTime + 500.0) {
      this.lastClickTime = 0.0;
      this.handleLineDoubleClick(event, domId);
    } else {
      this.lastClickTime = currentTime;
    }
    const id = domIdToElementId(null, domId);
    // const kind = getKindFromId(id);
    // if (kind === EKind.WORD) {
    if (idIsOfKind(id, EKind.WORD)) {
      this.handleWordClick(event, id as WordId);
    } else {
      this.model.setFocusedLineId(
        domIdToElementId(
          null,
          (event.currentTarget as HTMLElement)['id']
        ) as ElementId
      );
    }
  }

  getLineStylesString(id: unknown) {
    // TODO no longer needed?
    return 'styles';
  }

  setActiveLineEditor(lineEditor: ScriptLineEditor, isActive: boolean) {
    if (isActive) {
      this.activeLineEditor = lineEditor;
    } else if (this.activeLineEditor === lineEditor) {
      this.activeLineEditor = null;
    }
  }

  toggleCollapseExpand() {
    this.model.toggleCollapseExpand();
    setTimeout(
      () => scrollIfNotVisible(this.model.focusedLineId, 'center', 'editorId'),
      100
    );
  }

  handleEnterInNormalMode() {
    if (this.model.collapse) {
      this.toggleCollapseExpand();
    } else {
      const focusedLine = this.model.focusedLineElement;
      if (focusedLine && focusedLine.kind === EKind.SENTENCE) {
        alertMessages.add({
          ...Alert,
          text: 'can only use mouse double click to edit verbatim',
        });
      } else {
        this.model.editFocusedLine();
      }
    }
  }

  abortLineEdit() {
    if (this.activeLineEditor) {
      this.activeLineEditor.revert();
      this.model.abortLineEdit();
    }
  }

  handleEnterInEdit() {
    if (this.activeLineEditor.handleEnter) {
      const handled: boolean = this.activeLineEditor.handleEnter(null);
      if (!handled) {
        this.model.saveLineEdit();
      }
    } else {
      this.model.saveLineEdit();
    }
  }

  async removeFocused() {
    // short-circuit before confirmation ui shown
    if (!this.model.standardAccessEnabled) {
      alertMessages.add({ ...Alert, text: 'insufficient access' });
      return;
    }

    let focused = this.model.focusedElement;
    if (focused && focused.kind === EKind.SENTENCE) {
      const sentenceNum = this.model.sentences.getIndex(focused.id) + 1;
      const gohead = await swalPromptYN({
        title: 'Delete Sentence?',
        html: `Do you really want to delete sentence number ${sentenceNum}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete Sentence',
      });
      if (gohead) {
        this.model.removeFocused();
      }
    } else if (focused && focused.kind === EKind.EXCERPT) {
      const gohead = await swalPromptYN({
        title: 'Delete Excerpt?',
        html: 'Are you sure you want to delete this excerpt? Make sure the excerpt is marked "archived" before deleting.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete Excerpt',
      });
      if (gohead) {
        this.model.removeFocused();
      }
    } else {
      this.model.removeFocused();
    }
  }

  // TODO move these to model?
  goToFirst() {
    const firstElementId = this.model.lineElements.values[0].id;
    this.model.setFocusedLineId(firstElementId);
  }

  goToLast() {
    const elements = this.model.lineElements.values;
    const lastElementId = elements[elements.length - 1].id;
    this.model.setFocusedLineId(lastElementId);
  }

  testingHook() {
    // alert('run tst test code via `npm start test.tst`');
    // llmTestTranslationTaskFunctions(this.model.episodeKey, {
    //   useDummyData: false,
    // });
    // test1(this.model.episodeKey);
  }

  initializeInputHandlers() {
    const getKeyboardist = () => {
      // const kb = Keyboardist();
      // if (kb) {
      //   return kb;
      // } else {
      //   throw Error('no keyboardist');
      // }
      // return createListener() as KeyboardistListener;
      return new Keyboardist();
    };

    let handler = getKeyboardist();

    handler.subscribe('Escape', () => this.model.cancelSelections());
    handler.subscribe('KeyJ', () => this.model.cursorLineDown());
    handler.subscribe('KeyK', () => this.model.cursorLineUp());
    handler.subscribe('Right', () => this.model.nextSearchResult());
    handler.subscribe('Left', () => this.model.prevSearchResult());
    handler.subscribe('KeyW', () => this.enterWordGroupCreateChoiceMode());
    handler.subscribe('KeyS', () => this.enterStructuralCreateChoiceMode());
    // handler.subscribe('KeyC', () => this.enterCastOpsChoiceMode());
    handler.subscribe('KeyH', () => this.enterHistoryOpsChoiceMode());
    handler.subscribe('KeyG', () => this.enterGlobalOpsChoiceMode());
    handler.subscribe('KeyV', () => this.enterVerbatimOpsChoiceMode());
    handler.subscribe('KeyI', () =>
      this.model.setFocusedWarningSuppression(true)
    );
    handler.subscribe('Shift+KeyI', () =>
      this.model.setFocusedWarningSuppression(false)
    );
    handler.subscribe('KeyX', () => this.toggleCollapseExpand());
    handler.subscribe('Shift+KeyT', () => this.model.toggleShowTranslations());
    handler.subscribe('KeyM', () => this.model.markFocusedLine());
    handler.subscribe('Ctrl+KeyD', () => {
      this.removeFocused();
      return;
    });
    handler.subscribe('Ctrl+KeyM', () => this.model.moveMarked());
    handler.subscribe('Shift+KeyM', () => this.model.moveExcerptEnd());
    handler.subscribe('KeyT', () =>
      this.model.createOrEditTranslationAtFocusedLine()
    );
    handler.subscribe('Enter', () => this.handleEnterInNormalMode());
    handler.subscribe('KeyF', () => openFiltersDialog(true));
    handler.subscribe('Shift+KeyF', () => openFiltersDialog(false));
    handler.subscribe('KeyR', () => openVersionsDialog());
    handler.subscribe('KeyA', () => openHistoryDialog());
    handler.subscribe('KeyL', () => {
      this.model.copySharableElementLinkToClipboard();
      return;
    });
    handler.subscribe('KeyB', () => this.model.setBaselineTimestamp());
    handler.subscribe('Digit0', () => this.goToFirst());
    handler.subscribe('Shift+Digit0', () => this.goToLast());
    handler.subscribe('Digit1', () => openWordGroupInspectorDialog());
    handler.subscribe('Digit2', () => this.testingHook());
    handler.subscribe('Shift+Slash', () => {
      openSidePopup();
    });
    handler.subscribe('Space', () => this.model.togglePlay(false));
    handler.subscribe('Shift+Space', () => this.model.togglePlay(true));
    keyboardModes.addMode(NORMAL, handler);

    // TODO factor this common initialization instead of duplicating?
    handler = getKeyboardist();
    // map some locked stuff to errors
    const lockedWarning = () =>
      alertMessages.add({
        ...Alert,
        text: 'action not permitted because unit is locked',
      });
    handler.subscribe('KeyW', lockedWarning);
    handler.subscribe('KeyS', lockedWarning);
    handler.subscribe('KeyG', lockedWarning);
    handler.subscribe('KeyV', lockedWarning);

    handler.subscribe('Escape', () => this.model.cancelSelections());
    handler.subscribe('KeyJ', () => this.model.cursorLineDown());
    handler.subscribe('KeyK', () => this.model.cursorLineUp());
    handler.subscribe('Right', () => this.model.nextSearchResult());
    handler.subscribe('Left', () => this.model.prevSearchResult());
    handler.subscribe('KeyI', () =>
      this.model.setFocusedWarningSuppression(true)
    );
    handler.subscribe('Shift+KeyI', () =>
      this.model.setFocusedWarningSuppression(false)
    );
    handler.subscribe('KeyX', () => this.toggleCollapseExpand());
    handler.subscribe('Shift+KeyT', () => this.model.toggleShowTranslations());
    handler.subscribe('KeyF', () => openFiltersDialog(true));
    handler.subscribe('Shift+KeyF', () => openFiltersDialog(false));
    handler.subscribe('KeyH', () => openVersionsDialog());
    handler.subscribe('KeyL', () => {
      this.model.copySharableElementLinkToClipboard();
      return;
    });
    handler.subscribe('Digit0', () => this.goToFirst());
    handler.subscribe('Shift+Digit0', () => this.goToLast());
    handler.subscribe('Digit1', () => openWordGroupInspectorDialog());
    handler.subscribe('Space', () => this.model.togglePlay(false));
    handler.subscribe('Shift+Space', () => this.model.togglePlay(true));
    handler.stopListening();
    keyboardModes.addMode(LOCKED, handler);

    handler = getKeyboardist();
    handler.subscribe('Ctrl+KeyS', () => this.model.saveLineEdit());
    handler.subscribe('Escape', () => this.abortLineEdit());
    handler.subscribe('Enter', () => this.handleEnterInEdit());
    handler.stopListening();
    keyboardModes.addMode(EDITING, handler);

    const defineKBChoice = (key: string, cb: () => void) =>
      handler.subscribe(key, () => {
        this.handleKBModalChoiceMade();
        cb();
      });
    handler = getKeyboardist();
    defineKBChoice('KeyV', () =>
      this.model.createWordGroupWithWordSelection(WordGroupSubKind.VOCAB)
    );
    defineKBChoice('KeyT', () =>
      this.model.createWordGroupWithWordSelection(WordGroupSubKind.TRICKY)
    );
    defineKBChoice('KeyS', () =>
      this.model.createWordGroupWithWordSelection(WordGroupSubKind.SIC)
    );
    handler.stopListening();
    // TODO use constants for these
    keyboardModes.addMode(WORD_GROUP_CREATE, handler);

    let html = '';
    const addChoice = (choiceText: string) =>
      (html += createChoice(choiceText));

    const addDisabled = (choiceText: string) =>
      (html += `<div><strike>${choiceText}</strike></div>`);

    addChoice('@Vocab');
    addChoice('@Tricky');
    addChoice('@Sic');
    this.choiceModePopupContent.set(WORD_GROUP_CREATE, html);

    handler = getKeyboardist();
    defineKBChoice('KeyC', () =>
      this.model.createStucturalAtFocusedLine(EKind.CHAPTER)
    );
    defineKBChoice('KeyP', () =>
      this.model.createStucturalAtFocusedLine(EKind.PASSAGE)
    );
    // defineKBChoice('KeyQ', () =>
    //   this.model.createStucturalAtFocusedLine(EKind.PASSAGE_QUESTION_HEAD)
    // );

    defineKBChoice('KeyX', () =>
      this.model.createStucturalAtFocusedLine(EKind.EXCERPT)
    );
    defineKBChoice('KeyS', () =>
      this.model.createStucturalAtFocusedLine(EKind.PARAGRAPH)
    );
    defineKBChoice('KeyN', () =>
      this.model.createStucturalAtFocusedLine(EKind.CHAPTER_NOTE)
    );
    defineKBChoice('KeyE', () =>
      this.model.createStucturalAtFocusedLine(EKind.CHAPTER_COMPLETE)
    );
    handler.stopListening();
    keyboardModes.addMode(STRUCTURAL_CREATE, handler);

    html = '';
    addChoice('@Chapter');
    addChoice('@Passage');
    // addChoice('Passage @Question');
    addChoice('E@xcerpt');
    addChoice('@Speaker Label (or paragraph break)');
    addChoice('Chapter @Note');
    addChoice('Chapter Complet@e');
    this.choiceModePopupContent.set(STRUCTURAL_CREATE, html);

    handler = getKeyboardist();
    defineKBChoice('KeyR', () => openVersionsDialog());
    defineKBChoice('KeyA', () => openHistoryDialog());
    defineKBChoice('KeyT', () => this.model.toggleTrackChanges());
    handler.stopListening();
    keyboardModes.addMode(HISTORY_OPS, handler);

    html = '';
    addChoice('Open @Revert dialog');
    addChoice('Open @Actions history dialog');
    addChoice('Toggle @Tracking mode on/off');
    this.choiceModePopupContent.set(HISTORY_OPS, html);

    handler = getKeyboardist();
    defineKBChoice('KeyC', () => this.model.cleanupEpisodeElements());
    handler.stopListening();
    keyboardModes.addMode(GLOBAL_OPS, handler);

    html = '';
    addChoice('@Correct unit elements');
    this.choiceModePopupContent.set(GLOBAL_OPS, html);

    handler = getKeyboardist();

    defineKBChoice('KeyU', () => this.model.createMetadataUrlBlock());
    defineKBChoice('KeyC', () => this.model.copyMetadataToClipboard());
    handler.stopListening();
    keyboardModes.addMode(METADATA_BLOCK_OPS, handler);

    html = '';
    addChoice('Add metadata @URL block');
    addChoice('@Copy metadata blocks to clipboard');
    this.choiceModePopupContent.set(METADATA_BLOCK_OPS, html);

    handler = getKeyboardist();
    defineKBChoice('KeyC', () => this.model.openCreateSentenceDialog());
    defineKBChoice('KeyS', () => this.model.splitSentence(false));
    handler.stopListening();
    keyboardModes.addMode(VERBATIM_OPS, handler);

    html = '';
    addChoice('@Create new sentence (above/below)');
    addChoice('@Split sentence (at selected word)');
    this.choiceModePopupContent.set(VERBATIM_OPS, html);

    this.disposers.push(() => keyboardModes.stopListening());
  }

  get focusedLineMembershipList(): MembershipList {
    const elements = this.model.focusedLineElement
      ? [this.model.focusedLineElement]
      : [];
    return CreateMembershipList({ memberships: ['focused-line'], elements });
  }

  get markedLineMembershipList(): MembershipList {
    let elements = this.model.markedLineElement
      ? [this.model.markedLineElement]
      : [];
    return CreateMembershipList({ memberships: ['marked-line'], elements });
  }

  get wordSelectionMembershipList(): MembershipList {
    const els = this.model.wordRangeSelection
      ? [makeAdhocRangeElement(this.model.wordRangeSelection, this.model.words)]
      : [];
    const elements = CreateElementList({
      elements: els,
      words: this.model.elements.words,
    });
    return CreateMembershipList({
      memberships: ['selected'],
      useRanges: true,
      elements,
    });
  }

  // TODO
  @computed
  get hasActiveThreadMembershipList(): MembershipList {
    const elements = this.model.elementsWithActiveThreads;
    return CreateMembershipList({ memberships: ['has-discussion'], elements });
  }

  @computed
  get unfilledMembershipList(): MembershipList {
    const elements = this.model.elements.filter(el => {
      if (
        el.kind === EKind.WORD_GROUP &&
        el.subKind === WordGroupSubKind.VOCAB
      ) {
        const translation = this.model.translationsLookup[el.id];
        if (translation) {
          return !(translation?.content as WordGroupContent)?.note;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    return CreateMembershipList({ memberships: ['unfilled'], elements });
  }

  get hasLintWarningMembershipList(): MembershipList {
    return CreateMembershipList({
      memberships: ['has-lint-warning'],
      elements: this.model.lintWarnings,
    });
  }

  get searchFilterMembershipList(): MembershipList {
    return CreateMembershipList({
      memberships: ['search-match'],
      elements: this.model.searchResult,
    });
  }

  get playingSentenceMembershipList(): MembershipList {
    const sentenceId = this.model.currentPlayingSentenceId;
    const sentence = this.model.sentences.getElement(sentenceId);
    const playingSentences = sentence ? [sentence] : [];
    return CreateMembershipList({
      memberships: ['playing-sentence'],
      elements: playingSentences,
    });
  }

  get currentExcerptSentencesMembershipList(): MembershipList {
    return CreateMembershipList({
      memberships: ['excerpt-sentence'],
      elements: this.model.currentExcerptSentences,
    });
  }

  @computed
  get membershipLists() {
    let result: Map<string, MembershipList> = new Map();
    // TODO should the individual style layers be @computed or @computedFunc, reference ids changing every time any changes?
    result.set('playingSentence', this.playingSentenceMembershipList);
    result.set('excerptSentence', this.currentExcerptSentencesMembershipList);
    result.set('focusedLine', this.focusedLineMembershipList);
    result.set('wordSelection', this.wordSelectionMembershipList);
    result.set('markedLine', this.markedLineMembershipList);
    result.set('hasDiscussion', this.hasActiveThreadMembershipList);
    result.set('unfilled', this.unfilledMembershipList);
    result.set('hasLintWarning', this.hasLintWarningMembershipList);
    result.set('searchMatch', this.searchFilterMembershipList);
    return result;
  }

  membershipListsRenderer = CreateMembershipReconciler(renderStylesForDomId);

  renderMembershipLists() {
    this.membershipListsRenderer.reconcileMembershipLists(
      this.model.episodeKey,
      this.membershipLists
    );
  }

  // TODO react to computed membershipLists instead?

  componentWillUnmount() {
    for (const disposer of this.disposers) {
      disposer();
    }
  }

  SentenceView = ({
    element,
    prefix,
  }: {
    element: Sentence;
    prefix: string;
  }) => {
    const sentenceHTML = renderWordRange(
      null,
      this.membershipListsRenderer,
      this.model.words,
      { begin: element.address, end: element.endAddress },
      this.model.wordGroups
    );
    // const sentenceNum = this.model.sentences.getIndex(element.id) + 1;
    prefix = `<span>${prefix}</span>`;
    const style = this.lineEditorConfigurations[EKind.SENTENCE].style;
    return (
      <div
        className={style}
        dangerouslySetInnerHTML={{ __html: prefix + sentenceHTML }}
      />
    );
  };

  // SentenceLineEditor = (props: any) => {
  //   const { element } = props;
  //   const sentenceNum = this.model.sentences.getIndex(element.id) + 1;
  //   const prefix = `[${sentenceNum}] `;
  //   props = { prefix, ...props };
  //   return <ScriptLineEditor {...props} />;
  // };

  ScriptElement = ({
    element,
    onClick,
    getIsEditingId,
    setActive,
  }: {
    element: EditorLineElement;
    onClick: any;
    getIsEditingId: (id: ElementId) => boolean;
    setActive: (lineEditor: ScriptLineEditor, isActive: boolean) => void;
  }) => {
    const membershipListsRenderer = this.membershipListsRenderer;
    const SentenceView = this.SentenceView;
    // const SentenceLineEditor = this.SentenceLineEditor;
    const kind = element.kind;
    const config = this.lineEditorConfigurations[kind];
    if (!config) {
      window.alert(`invalid element kind: ${JSON.stringify(element)}`);
      return null;
    }
    const styling = config.style;
    let baseClassName: string =
      typeof styling === 'function' ? styling(element) : styling;
    const subKind = (element as any).subKind;
    if (subKind) {
      baseClassName += ' ' + subKind.toLowerCase();
    }

    const prefixing = config.prefix;
    const prefix =
      typeof prefixing === 'function' ? prefixing(element) : prefixing;

    const isEditing = getIsEditingId(element.id);
    let LineComponent: any = null;
    if (isEditing) {
      LineComponent = ScriptLineEditor;
    } else {
      LineComponent = kind === EKind.SENTENCE ? SentenceView : ScriptLineEditor;
    }
    // const isEditor = isEditing || (LineComponent === ScriptLineEditor);
    const renderedStyles =
      'editor-line ' +
      membershipListsRenderer.getJoinedMembershipStringForElement(element.id);
    return (
      <div
        className={renderedStyles}
        id={elementIdToDomId(null, element.id)}
        onClick={onClick}
      >
        <LineComponent
          element={element as any}
          getIsEditingId={getIsEditingId as any}
          setActive={setActive}
          model={this.model}
          baseClassName={baseClassName}
          configurations={this.lineEditorConfigurations}
          prefix={prefix}
        />
      </div>
    );
  };

  lineEditorConfigurations: { [index in EKind]?: any } = {
    [EKind.CHAPTER]: {
      prefix: (element: ETOf<'CHAPTER'>) => {
        const chapterNum = this.model.chapters.getIndex(element.id) + 1;
        return `# ${chapterNum}. `;
      },
      style: 'chapter-title',
    },
    // [EKind.CHAPTER_SUMMARY]: {
    //   prefix: '%% ',
    //   style: 'chapter-summary',
    // },
    [EKind.CHAPTER_NOTE]: {
      prefix: '% ',
      style: 'cultural-note',
      opts: { multiline: true },
    },
    [EKind.PASSAGE]: {
      prefix: '## ',
      style: 'passage-hint',
    },
    // [EKind.PASSAGE_QUESTION]: {
    //   prefix: '## ',
    //   style: 'passage-hint',
    // },
    [EKind.EXCERPT]: {
      prefix: '**EXCERPT** ',
      style: 'excerpt-head',
    },
    [EKind.PARAGRAPH]: {
      // prefix: (element: Structural) => (isEmpty(element.content.text) ? '###' : '@'),
      prefix: '@',
      style: 'speaker-label',
    },
    [EKind.SENTENCE]: {
      prefix: (element: Sentence) => {
        const sentenceNum = this.model.sentences.getIndex(element.id) + 1;
        return `[${sentenceNum}] `;
      },
      style: 'chaat-sentence',
    },
    [EKind.METADATA_BLOCK]: {
      prefix: null as string,
      style: 'metadata-block',
      opts: { multiline: true },
    },
    [EKind.CHAPTER_COMPLETE]: {
      prefix: '//! CHAPTER-COMPLETE',
      style: 'chapter-complete',
    },
    [EKind.TRANSLATION]: {
      prefix: null as string,
      style: (element: Translation) =>
        'translation ' +
        this.lineEditorConfigurations[getKindFromId(element.elementId) as EKind]
          .style +
        '-translation',
    },
  };

  render() {
    const ScriptElement = this.ScriptElement;
    const model = this.model;
    let elements = model.visibleElements?.values;
    elements = elements || [];
    let touch = model.editingId;
    const getIsEditingId = (id: ElementId) => {
      return model.editingId === id;
    };

    const setActive = (lineEditor: ScriptLineEditor, isActive: boolean) =>
      this.setActiveLineEditor(lineEditor, isActive);

    const handleLineClick = (event: MouseEvent, id: string) => {
      if (event.target instanceof HTMLElement) {
        this.handleLineClick(event, event.target.id);
      } else {
        this.handleLineClick(event, id);
      }
    };

    return (
      <div
        className="editor"
        id="editorId"
        onScroll={event => this.handleScroll(event)}
      >
        {elements.map(el => (
          <ScriptElement
            key={versionedElementKey(el as VersionableElement)}
            element={el}
            onClick={(e: MouseEvent) =>
              handleLineClick(e, elementIdToDomId(null, el.id))
            }
            getIsEditingId={getIsEditingId}
            setActive={setActive}
          />
        ))}
      </div>
    );
  }
}
