import { FeaturedReleaseData } from '../catalog-types';
import { EntityManager } from './catalog-entity-manager';
import { CatalogCollections } from './catalog-db-paths';
import { FeaturedRelease } from '../models/featured-release';
import { isEmpty } from 'lodash';

export class FeaturedReleaseManager extends EntityManager<
  FeaturedRelease,
  FeaturedReleaseData
> {
  get collectionName(): string {
    return CatalogCollections.FEATURED_RELEASE_METADATA;
  }

  createModel(data: { id: string }): FeaturedRelease {
    return new FeaturedRelease(data);
  }

  // the instance of the model to be currently included in published catalogs
  // todo: scope to channel
  async currentFeaturedRelease(): Promise<FeaturedRelease> {
    if (isEmpty(this.list)) {
      await this.loadAll();
    }
    return this.list.find(item => {
      return item.data.active;
    });
  }

  async activeForChannel(channelId: string): Promise<FeaturedRelease[]> {
    if (isEmpty(this.list)) {
      await this.loadAll();
    }
    return this.list.filter(item => {
      return item.data.active && item.data.channelId === channelId;
    });
  }

  private static instance: FeaturedReleaseManager;

  public static getInstance(): FeaturedReleaseManager {
    if (!FeaturedReleaseManager.instance) {
      FeaturedReleaseManager.instance = new FeaturedReleaseManager();
      FeaturedReleaseManager.instance.listenAll();
    }
    return FeaturedReleaseManager.instance;
  }
}
