import { Link, NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ActionLink, A } from '@masala-lib/editorial/ui/action-link';
import { ImportTranslationsForm } from './import-translations-form';
import { Unit } from '@masala-lib/catalog/models/unit';
import { get, isEmpty, pick } from 'lodash';
import { UnitImport } from './unit-import';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import { Stack } from '../shared/stack';
import { DualPaneLayout } from '../shared/layouts';
import { DataCard } from '../../components/data-display/data-card';
import { Shelf } from '../shared/shelf';
import { phraseize } from '../../lib/phraseize';
import { formatDate } from '../../lib/formatters';
import { StateSwitch } from '../../lib/hooks/use-switch';
import { Text, Button } from 'rebass';
import { SelectItem } from '../../components/forms';
import { uiTimeString } from '@masala-lib/misc/timestamp-formats';
import { PageActions } from '../shared/page-actions';
import { Auth } from '@masala-lib/editorial/db/auth';
import { DebugStuff } from './unit-debug-stuff';
import { IngestionStatus } from '../volumes/volume-detail';
import { gcsConsoleUrl } from '@masala-lib/deployment-config';
import { Volume } from '@masala-lib/catalog/models/volume';
import { continueNewInitChaatUrl } from '@masala-lib/editorial/chaat/chaat-crud';
import {
  clearOutAllButLocaleTranslations,
  zorchWordGroups,
} from '@masala-lib/editorial/db/mutation-actions';
import {
  batchFillVocabDefinitions,
  zorchAllVocabDefinitions,
} from '@masala-lib/llm/batch-vocab';

interface UnitDetailProps {
  unit: Unit;
  showWorkflowDialog: StateSwitch;
}

function tagList(tagList?: SelectItem[]): string {
  if (!tagList || tagList.length === 0) {
    return 'N/A';
  }

  return tagList.map(item => item.label).join(', ');
}

export const LightboxTriggerButton = ({ ...props }) => (
  <Button
    backgroundColor="var(--color-white)"
    color="var(--color-gray500)"
    style={{
      border: '1px solid var(--color-gray100)',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      textAlign: 'center',
      borderRadius: 4,
      outline: 'none',
    }}
    {...props}
  />
);

export const UnitGeneralInfo = observer(
  ({ unit, showWorkflowDialog }: UnitDetailProps) => {
    console.log('unit ', unit.data.releaseDate);

    return (
      <DualPaneLayout rightPanel={<RightPane unit={unit} />}>
        <Stack>
          <DataCard
            data={{
              ...pick(unit, ['slug', 'operationStatus', 'durationMinutes']),
              volume: () => (
                <NavLink to={unit.volume.navPath}>{unit.volume.name}</NavLink>
              ),
              channel: () => (
                <NavLink to={unit.volume.channel.navPath}>
                  {unit.volume.channel.name}
                </NavLink>
              ),
            }}
          />
          <IngestionStatus volume={unit.volume} />
          <DataCard
            data={{
              ...pick(unit.data, 'workflowStatus'),
              linear: () => {
                if (unit.data.linearUrl) {
                  return <A href={unit.data.linearUrl} />;
                }
                return (
                  <LightboxTriggerButton onClick={showWorkflowDialog.on}>
                    Create Linear Workflow…
                  </LightboxTriggerButton>
                );
              },
              soundbiteEditorialDoc: () => {
                if (unit.data.soundbiteEditorialDocUrl) {
                  return <A href={unit.data.soundbiteEditorialDocUrl} />;
                }
                return (
                  <A href={unit.resolveSoundbiteEditorialDocGenUrl()}>
                    Generate Google Doc for Soundbites Work…
                  </A>
                );
              },
              activityGuide: () => {
                if (unit.activityGuide) {
                  return (
                    <Shelf>
                      <Link to={unit.activityGuide?.navPath}>
                        Open activity guide
                      </Link>
                      {/*
                    /// This is buggy. For some reason it doesn't update the components. Leaving it out for now.
                    <a onClick={confirm(unit.activityGuide?.dettachFromUnit)}>Unlink</a>
                    */}
                    </Shelf>
                  );
                }

                return (
                  <Link
                    style={{
                      padding: '8px 16px',
                      display: 'inline-block',
                      backgroundColor: 'var(--color-white)',
                      color: 'var(--color-gray500)',
                      border: '1px solid var(--color-gray100)',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '24px',
                      textAlign: 'center',
                      borderRadius: 4,
                      outline: 'none',
                    }}
                    to={`/activity-guides/new?unit=${unit.id}`}
                  >
                    Create activity guide…
                  </Link>
                );
              },
              ...pick(unit.data, 'flavor', 'l2Code', 'l1Code'),
              titleL2: unit.data.infoV5?.titleL2,
              partSuffix: unit.data.infoV5?.partSuffix,
              taglineL1: unit.data.infoV5?.taglineL1,
              descriptionL1: unit.data.infoV5?.descriptionL1,
              releaseDate: () => (
                <>
                  {formatDate(unit.data.releaseDate)}{' '}
                  <Text color={'var(--color-gray200)'}>
                    {' '}
                    {unit.data.releaseDate}
                  </Text>
                </>
              ),
            }}
          />
          {/* <UnitL1Card unit={unit} unitL1={unit.defaultUnitL1} /> */}

          {unit.hasUnitLevelTagData ? <TagsCard unit={unit} /> : null}

          <AssetCard
            prefixes={[
              'audioFinal',
              'audioTranscribe',
              'imageThumb',
              // 'imageBanner',
            ]}
            model={unit}
          />
          <Card>
            {/* {unit.data.dataUrl ? (
              <DataItem
                value={
                  <>
                    <A href={unit.data.dataUrl}>url</A> (v{unit.data.version},{' '}
                    {uiTimeString(unit.data.ingestedAt)})
                  </>
                }
                label="Bogota data url"
              />
            ) : null} */}
            {unit.scriptReviewPath ? (
              <DataItem
                value={<A href={unit.scriptReviewPath}>Review script</A>}
                label="Review script"
              />
            ) : null}
            {unit.structuralExamplePath ? (
              <DataItem
                value={
                  <NavLink to={unit.structuralExamplePath}>generate</NavLink>
                }
                label="Structural content example"
              />
            ) : null}
            {unit.data.scriptImportSourceUrl ? (
              <DataItem
                value={<A href={unit.data.scriptImportSourceUrl}>source url</A>}
                label="Script import source Url"
              />
            ) : null}
            {unit.data.metadataImportSourceUrl ? (
              <DataItem
                value={
                  <A href={unit.data.metadataImportSourceUrl}>source url</A>
                }
                label="Metadata import source Url"
              />
            ) : null}

            <DataItem
              label="volume speakers"
              value={
                <NavLink to={unit.volume.speakersNavPath}>view/edit</NavLink>
              }
            />
            {/* {Auth.getInstance().isSuperAdmin ? (
              <DataItem
                label="machine assisted editorial (WIP)"
                value={<NavLink to={unit.assistPath}>view/edit</NavLink>}
              />
            ) : null} */}
            {/* <DataItem
              label="samosa - dev 2"
              value={<NavLink to={unit.assistDev2NavPath}>view/edit</NavLink>}
            /> */}
            {/* <DataItem
              label="llm dev experiment 1"
              value={<NavLink to={unit.assistDev1NavPath}>view/edit</NavLink>}
            /> */}
          </Card>
        </Stack>
      </DualPaneLayout>
    );
  }
);

export const AssetCard = ({
  model,
  prefixes = [],
}: {
  model: Unit | Volume;
  prefixes: Array<string>;
}) => {
  return (
    <Card>
      {prefixes.map(prefix => (
        <DataItem
          key={prefix}
          label={phraseize(prefix)}
          value={
            <Shelf>
              <A href={get(model.data, prefix + 'Url')}>source url</A>
              <A href={get(model.data, prefix + 'StorageUrl')}>storage url</A>
              {prefix === 'imageThumb' ? (
                <A href={gcsConsoleUrl(model.baseImageStoragePath)}>
                  GCS console
                </A>
              ) : null}
            </Shelf>
          }
        />
      ))}
    </Card>
  );
};

// todo: remove once all data migrated to volume
export const TagsCard = ({ unit }: { unit: Unit }) => (
  <DataCard
    header="Tags are now edited via the volume detail screen"
    data={{
      topics: unit.topicTagNames.join(', '),
      countries: unit.countryTagNames.join(', '),
      ap: unit.apTagNames.join(', '),
      ib: unit.ibTagNames.join(', '),
    }}
  />
);
const RightPane = observer(({ unit }: { unit: Unit }) => {
  const auth = Auth.getInstance();
  const user = auth.appUser;
  const lockedForMe = !unit.hasWriteAccess(user);

  // todo: sort out better way to share this code with below
  const handleUnlock = async () => {
    try {
      await unit.unlockBy(user);
    } catch (error) {
      window.alert(error);
      throw error;
    }
  };

  if (lockedForMe) {
    if (unit.hasWriteAccess(user) || auth.can('manage_units')) {
      return (
        <>
          Locked by {unit.data.lockedByUserId}
          <br />
          <ActionLink onPress={handleUnlock}>[Unlock unit]</ActionLink>
          <br />
          <hr />
          <ExportManagement unit={unit} />
        </>
      );
    } else {
      return <>{`Locked by ${unit.data.lockedByUserId}`}</>;
    }
  }

  if (!unit.hasWriteAccess(user)) {
    return <>{'[Read only]'}</>;
  }

  if (user.isReviewer) {
    return null; // todo - provide links to master unit review tool and metadata?
  }

  // const handlePublishReview = async () => {
  //   try {
  //     await unit.publishReviewCatalogs();
  //   } catch (error) {
  //     unit.clearPendingOperation();
  //     window.alert(error);
  //     throw error;
  //   }
  // };

  const writable = unit.hasWriteAccess(auth.appUser);

  return (
    <>
      {unit.isLocked ? (
        <>
          {/* <PageActions>
            <A href={unit.volume.caliPreviewUrl}>Web player preview</A>
          </PageActions>
          <br /> */}
          Locked by {unit.data.lockedByUserId}
          <br />
          <ActionLink onPress={handleUnlock}>[Unlock unit]</ActionLink>
        </>
      ) : null}
      {/* {unit.canIngest && writable ? (
        <>
          Last web ingestion: {unit.volume.reviewIngestionAgePretty || 'n/a'}
          <br />
          <PageActions>
            <button onClick={handleCaliBuildAndPreview}>
              Web build and preview
            </button>
          </PageActions>
        </>
      ) : null} */}
      {/* {!!unit.volume.data.reviewVersion ? (
        <PageActions>
          <A href={unit.volume.caliPreviewUrl}>Web player preview</A>
        </PageActions>
      ) : null} */}
      <PageActions>
        <NavLink to={unit.volume.navPath}>Volume Details</NavLink>
      </PageActions>
      <PageActions>
        <NavLink to={unit.infoNavPath}>Unit Info</NavLink>
      </PageActions>
      <PageActions>
        {unit.volume?.data?.creditsData ? (
          <NavLink to={unit.creditsNavPath}>Volume Credits</NavLink>
        ) : null}
      </PageActions>
      <PageActions>
        {unit.volume?.data?.speakers ? (
          <NavLink to={unit.speakersNavPath}>Volume Speakers</NavLink>
        ) : null}
      </PageActions>
      {Auth.getInstance().isSuperAdmin ? (
        <>
          <PageActions>
            {/* <NavLink to={unit.speakersNavPath}>Volume Speakers</NavLink> */}
            <A href={`${unit.chaatUrl}/tricky`}>Tricky Assist Tool (WIP)</A>
          </PageActions>
          <A href="https://jiveworld.slite.com/app/docs/QRu8Hmjh6WPmNZ">
            slite guide
          </A>
        </>
      ) : null}
      <PageActions>
        {isEmpty(unit.data.metadataUrl) ? null : (
          <A href={unit.data.metadataUrl}>Metadata</A>
        )}
      </PageActions>
      {/* {isEmpty(unit.bogotaReviewUrl) ? null : (
        <PageActions>
          <button onClick={handlePublishReview}>Publish review catalogs</button>
        </PageActions>
      )} */}
      <hr />
      episodeKey: {unit.episodeKey}
      <br />
      L2: {unit.data.l2Code}; primary L1: {unit.data.l1Code}
      {/* ; audio code:{' '} {unit.data.audioLanguageCode} */}
      <br />
      {unit.scriptInitted ? (
        <EpisodeManagement unit={unit} />
      ) : (
        <UnitImport model={unit} />
      )}
      {/* {' - '}
      <NavLink to={`/conversations/episode/${model.episodeKey}`}>
        [conversations]
      </NavLink> */}
      {Auth.getInstance().isSuperAdmin ? (
        <>
          <hr />
          <Card>
            <DataItem label="debug stuff" value={<DebugStuff unit={unit} />} />
          </Card>
        </>
      ) : null}
    </>
  );
});

// export const BogotaImportView = observer(({ model }: { model: Unit }) => {
//   return (
//     <>
//       <hr />
//       ingestion version: {model.data.version}
//       <br />
//       <a href={model.data.dataUrl} target="_blank" rel="noreferrer">
//         [data url]
//       </a>
//       {' - '}
//       <a href={model.oldReviewToolUrl} target="_blank" rel="noreferrer">
//         [old review tool]
//       </a>
//     </>
//   );
// });

export const EpisodeManagement = observer(({ unit }: { unit: Unit }) => {
  const auth = Auth.getInstance();

  const handleFetchAssets = async () => {
    try {
      await unit.fetchAssets();
    } catch (error) {
      unit.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  // const handleImportMetadata = async () => {
  //   try {
  //     await unit.importMetadata();
  //   } catch (error) {
  //     unit.clearPendingOperation();
  //     window.alert(error);
  //     throw error;
  //   }
  // };

  const handleTrackRevisions = async () => {
    try {
      await unit.trackRevisions();
    } catch (error) {
      unit.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const handleUntrackRevisions = async () => {
    try {
      await unit.untrackRevisions();
    } catch (error) {
      unit.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  const handleLockForAll = async () => {
    try {
      const auth = Auth.getInstance();
      await unit.lockByForAll(auth.appUser);
    } catch (error) {
      window.alert(error);
      throw error;
    }
  };

  const handleLockFromOthers = async () => {
    try {
      const auth = Auth.getInstance();
      await unit.lockBy(auth.appUser);
    } catch (error) {
      window.alert(error);
      throw error;
    }
  };

  const handleUnlock = async () => {
    try {
      const auth = Auth.getInstance();
      await unit.unlockBy(auth.appUser);
    } catch (error) {
      window.alert(error);
      throw error;
    }
  };

  const handleResetAllData = () => {
    if (window.confirm('Are you sure?\n(note, old data will be archived)')) {
      unit.resetAllData();
    }
  };

  const handleResetChaatCues = () => {
    if (window.confirm('Are you sure?')) {
      unit.resetChaatCues();
    }
  };

  const handleZorchWordGroups = () => {
    if (window.confirm('Are you sure you want to delete all vocab?')) {
      zorchWordGroups(unit.id);
    }
  };

  const handleZorchVocabDefinitions = () => {
    if (
      window.confirm('Are you sure you want to delete all vocab definitions?')
    ) {
      zorchAllVocabDefinitions(unit.id);
    }
  };

  const handleGenerateVocabDefinitions = async () => {
    if (
      window.confirm('Are you sure you want to generate vocab definitions?')
    ) {
      await batchFillVocabDefinitions(unit.id);
      window.alert('vocab definitions generated');
    }
  };

  const handleZorchAncillaryTranslations = () => {
    if (
      window.confirm(
        'Are you sure you want to delete all ancillary translations?'
      )
    ) {
      clearOutAllButLocaleTranslations(unit.id, unit.defaultLocale);
    }
  };

  const chaatInitLabelPrefix = unit.chaatInitted ? 'Force ' : '';

  return (
    <Stack>
      <TranslationManagement unit={unit} />
      <hr />
      <ActionLink onPress={handleFetchAssets}>[Re-fetch assets]</ActionLink>
      {/* {unit.data.metadataUrl || true ? (
        <>
          {' - '}
          <ActionLink onPress={handleImportMetadata}>
            [Re-import metadata]
          </ActionLink>
        </>
      ) : null}
      <br /> */}
      <A href={unit.auditLogUrl}>[Audit logs ^]</A>
      <br />
      <A href={unit.exportVocabUrl()}>
        [Export vocab ({unit.defaultLocale}) ^]
      </A>
      <br />
      <ExportManagement unit={unit} />

      {auth.can('manage_units') ? (
        <>
          {unit.data.trackingEnabled ? (
            <>
              <ActionLink onPress={handleUntrackRevisions}>
                [Stop tracking revisions]
              </ActionLink>
              <br />- baseline:{' '}
              {uiTimeString(unit.data.defaultBaselineTimestamp)})
              <br />
            </>
          ) : (
            <>
              <ActionLink onPress={handleTrackRevisions}>
                [Start tracking revisions]
              </ActionLink>
              <br />
            </>
          )}
          {/* <ActionLink onPress={() => unit.oldInitChaat()}>
            [{chaatInitLabelPrefix}Old Init chaat]
          </ActionLink>
          <br /> */}
          <ActionLink onPress={() => unit.newInitChaat()}>
            [{chaatInitLabelPrefix}Init chaat]
          </ActionLink>
          <br />
          {unit.data.chaatInitJobId ? (
            <>
              <NavLink to={`/jobs/${unit.data.chaatInitJobId}`}>
                [chaat init job status]
              </NavLink>{' '}
              ({unit.data.chaatInitJobId})
              <br />
            </>
          ) : null}
          {/* {unit.data.chaatInitJobId ? (
            <>
              <A href={continueNewInitChaatUrl(unit.data.chaatInitJobId)}>
                continue chaat init
              </A>
              <br />
            </>
          ) : null} */}
          {/* job data: {JSON.stringify(unit.data.chaatInitJobData)} */}
          <br />
          <br />
          {unit.isLocked ? (
            <ActionLink onPress={handleUnlock}>[Unlock unit]</ActionLink>
          ) : (
            <>
              <ActionLink onPress={handleLockForAll}>
                [Lock unit for all]
              </ActionLink>{' '}
              (read-only for all users)
              <br />
              <ActionLink onPress={handleLockFromOthers}>
                [Lock unit from others]
              </ActionLink>{' '}
              (locking user may still edit)
            </>
          )}
          <br />
        </>
      ) : null}
      {unit.chaatInitted && unit.chaatReady ? (
        <>
          <ActionLink onPress={() => unit.runChaat()}>
            [Refresh timestamping]
          </ActionLink>{' '}
          (fixes some chaat issues)
          <br />
        </>
      ) : null}
      <ActionLink onPress={handleZorchWordGroups}>
        [Delete all word groups]
      </ActionLink>
      <br />
      <ActionLink onPress={handleZorchVocabDefinitions}>
        [Delete all vocab definitions]
      </ActionLink>
      <br />
      <ActionLink onPress={handleZorchAncillaryTranslations}>
        [Delete all ancillary translations]
      </ActionLink>
      <br />
      <ActionLink onPress={handleGenerateVocabDefinitions}>
        [Generate all vocab definitions]
      </ActionLink>
      <br />

      {Auth.getInstance().can('manage_units') ? (
        <>
          ---
          <br />
          <ActionLink onPress={handleResetAllData}>
            [RESET all script editor and chaat data]
          </ActionLink>
          <br />
          <ActionLink onPress={handleResetChaatCues}>
            [RESET chaat cues]
          </ActionLink>
          <br />
        </>
      ) : null}
    </Stack>
  );
});

export const ExportManagement = observer(({ unit }: { unit: Unit }) => {
  const handleExportAll = async () => {
    try {
      await unit.exportAllData();
    } catch (error) {
      unit.clearPendingOperation();
      window.alert(error);
      throw error;
    }
  };

  return (
    <>
      <ActionLink onPress={handleExportAll}>[Generate new backup]</ActionLink>
      <br />
      {unit.data.exportedUrl ? (
        <>
          - <A href={unit.data.exportedUrl}>latest backup data</A> (
          {uiTimeString(unit.data.exportedAt)})
          <br />
        </>
      ) : null}
    </>
  );
});

export const TranslationManagement = observer(({ unit }: { unit: Unit }) => (
  <>
    <A href={unit.exportTranslationsUrl()}>[Export translations ^]</A>
    {unit.auxL1s.map(locale => (
      <>
        {' '}
        <A href={unit.exportTranslationsUrl(locale)}>({locale})</A>{' '}
      </>
    ))}{' '}
    (to memsource tsv)
    <br />
    <A href={unit.importTranslationsFormUrl}>[Import translations ^]</A> (from
    memsource tsv)
    <ImportTranslationsForm
      episodeKey={unit.episodeKey}
      defaultLocale={unit.defaultLocale}
    />
  </>
));

// export const UnitL1Card = observer(
//   ({ unit, unitL1 }: { unit: Unit; unitL1: UnitL1 }) => {
//     const user = Auth.getInstance().appUser;
//     const lockedForMe = !unit.hasWriteAccess(user);

//     if (!unitL1) {
//       return null;
//     }

//     let internalActions = null;
//     if (unit.showPromoteInternalToStaged(unitL1)) {
//       const handlePromoteToStaged = () => {
//         if (window.confirm('Are you sure?')) {
//           unit.promoteInternalToStaged();
//         }
//       };
//       if (!lockedForMe && user.isAdmin) {
//         internalActions = (
//           <ActionLink onPress={handlePromoteToStaged}>
//             [Stage for public release]
//           </ActionLink>
//         );
//       } else {
//         internalActions = <strong>"stage for public release" pending</strong>;
//       }
//     }

//     return (
//       <Card>
//         <DataItem label="Bogota ingestion status:" />
//         <DataItem
//           label="Review version"
//           value={
//             <VersionInfo
//               data={unitL1.internalVersion}
//               actions={internalActions}
//               unit={unit}
//               level="internal"
//               locked={lockedForMe}
//             />
//           }
//         />
//         {/* <DataItem
//           label="External review version"
//           value={
//             <VersionInfo
//               data={unitL1.externalVersion}
//               // actions={externalActions}
//               unit={unit}
//               level="external"
//               locked={lockedForMe}
//             />
//           }
//         /> */}
//         <DataItem
//           label="Staged version"
//           value={
//             <VersionInfo
//               data={unitL1.publicVersion}
//               // actions={stagedActions}
//               unit={unit}
//               level="public"
//               locked={lockedForMe || !user.isAdmin}
//             />
//           }
//         />
//         <DataItem
//           label="Live version"
//           value={
//             <VersionInfo
//               data={unitL1.liveVersion}
//               unit={unit}
//               level="live"
//               locked={true}
//             />
//           }
//         />
//       </Card>
//     );
//   }
// );

// export const VersionInfo = observer(
//   ({
//     data,
//     unit,
//     level,
//     actions,
//     locked,
//   }: {
//     data: UnitL1Version;
//     unit: Unit;
//     level: string; // internal, external, public
//     actions?: React.ReactNode;
//     locked: boolean; // no write access
//   }) => {
//     if (data) {
//       // const publishHandler = () => unit.publishCatalogLevel(level);
//       const stale = level === 'internal' && unit.ingestionNeeded;

//       return (
//         <>
//           <A href={data.dataV3Url}>url</A> (v
//           {data.versionNumber}, {uiTimeString(data.timestamp)})
//           {stale ? ', stale ingestion' : null}
//           {data.pendingPublish && !stale ? (
//             <>
//               {', '}
//               publish pending
//               {/* {locked ? null : (
//                 <>
//                   {' - '}
//                   <ActionLink onPress={publishHandler}>
//                     [Republish <strong>{level}</strong> catalog]
//                   </ActionLink>
//                 </>
//               )} */}
//             </>
//           ) : null}
//           {actions ? <> - {actions}</> : null}
//         </>
//       );
//     } else {
//       return <>{actions}</>;
//     }
//   }
// );
