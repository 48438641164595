import { LocaleCode } from '@utils/util-types';
import {
  BatchVocabContextData,
  batchVocabContextData,
} from './batch-vocab-prompt';
import { LLMMessage } from './project/llm-project-types';
import { ElementList, Sentence, WordGroup } from '@masala-lib/editor-aliases';
import {
  EditorElement,
  ElementIdToTranslation,
  StorageTranslation,
  Translation,
  VocabContent,
} from '@masala-lib/editorial-types';
import {
  getElementEditableContentString,
  getWordGroupUsageText,
} from '@masala-lib/content-utils';
import { stripUnderscores } from '@utils/content-string-utils';
import { epochSecondsFloat } from '@masala-lib/utils';
import {
  getKindFromId,
  getTranslationId,
} from '@tikka/elements/element-id-utils';
import { EpisodeTranslationDoc } from '@masala-lib/editorial/db/firestore-doc-types';
import { DbPaths } from '@masala-lib/editorial/db/db';
import { db } from '@platform/firebase-init';
import {
  openAiLlmEndpoint,
  openAiLlmHeaders,
} from './project/llm-project-funcs';
import { CreateChatCompletionResponse } from 'openai';
import {
  basicScriptElementsFromEpisodeData,
  loadScriptEpisodeData,
} from '@masala-lib/editorial/db/loader-funcs';
import { BatchProcessor } from './batch-processor';

export type VocabSelection = {
  sentence: string;
  translation: string;
  headwords: string;
};

export type VocabDefinition = {
  definition: string;
  id?: string;
};

function formattedJSONStringify(obj: any): string {
  return JSON.stringify(obj, null, 2);
}

function vocabSelectionToLLMUserMessage(
  vocabSelection: VocabSelection
): LLMMessage {
  return {
    role: 'user',
    content: formattedJSONStringify(vocabSelection),
  };
}

function vocabDefinitionToLLMAssistantMessage(
  vocabDefinition: VocabDefinition
): LLMMessage {
  return {
    role: 'assistant',
    content: formattedJSONStringify(vocabDefinition),
  };
}

const languageNames = {
  en: 'English',
  pt: 'Portuguese',
  es: 'Spanish',
  ja: 'Japanese',
  de: 'German',
  da: 'Danish',
  fr: 'French',
};

function localeToName(locale: LocaleCode): string {
  return languageNames[locale];
}

function createBatchVocabContext(
  contextData: BatchVocabContextData
): LLMMessage[] {
  const result: LLMMessage[] = [];
  const { l1, l2 } = contextData;
  const exchanges = contextData.exchanges.slice();
  result.push({ role: 'system', content: 'You are a helpful assistant' });
  const firstExchange = exchanges.shift();
  const firstUserMessage = {
    role: 'user' as const,
    content: `In this exercise, you will be presented with a vocabulary selection object for a sentence in ${localeToName(
      l2
    )}
     along with a translation in ${localeToName(
       l1
     )}. The json object will contain the sentence, translation, and headwords. The task is to provide a definition for the headwords in
     ${localeToName(
       l1
     )} in the context of the sentence and the translation in the form of a json object with a definition field.\n\nHere is the first vocabulary selection:\n${formattedJSONStringify(
      firstExchange.selection
    )}`,
  };
  result.push(firstUserMessage);
  result.push(vocabDefinitionToLLMAssistantMessage(firstExchange.definition));
  for (const exchange of exchanges) {
    result.push(vocabSelectionToLLMUserMessage(exchange.selection));
    result.push(vocabDefinitionToLLMAssistantMessage(exchange.definition));
  }
  return result;
}

function vocabScriptElementToVocabSelection(
  vocabElement: WordGroup,
  sentences: ElementList<Sentence>,
  translations: { [key: string]: Translation }
): VocabSelection {
  // TODO implement correct underscore behavior
  const words = sentences.words;
  if (vocabElement.subKind !== 'VOCAB') {
    throw new Error('Expected vocab');
  }
  const sentence = sentences.getElementContainingWordAddress(
    vocabElement.address
  );
  const translationElement = translations[sentence.id];
  if (!translationElement) {
    throw new Error('sentence translation not found');
  }
  const translation = stripUnderscores(translationElement.content as string);
  if (!translation) {
    throw new Error('sentence translation empty');
  }
  let headwords = vocabElement.content.canonical;
  if (!headwords) {
    headwords = getWordGroupUsageText(vocabElement, words);
  }
  const sentenceText = stripUnderscores(
    getElementEditableContentString(sentence, words)
  );
  return {
    sentence: sentenceText,
    translation,
    headwords,
  };
}

function llmResponseContentToVocabDefinition(content: string): VocabDefinition {
  const obj = JSON.parse(content);
  return {
    definition: obj.definition,
  };
}

function unfilledVocabs(
  content: ElementList<EditorElement>,
  translations: { [key: string]: Translation }
) {
  const result: WordGroup[] = [];
  const wordGroups = content.filterByKind('WORD_GROUP');
  const vocabs = wordGroups.filterBySubKind('VOCAB').values;
  for (const vocab of vocabs) {
    const translation = translations[vocab.id];
    const vocabContent: VocabContent = translation?.content as VocabContent;
    if (!vocabContent?.note) {
      result.push(vocab);
    }
  }
  return result;
}

async function importVocabDefinitionsIntoMasala(
  unitId: string,
  locale: LocaleCode,
  definitions: VocabDefinition[]
) {
  const timestamp = epochSecondsFloat();
  const definitionTranslations: StorageTranslation[] = [];
  for (const definition of definitions) {
    const definitionTranslation: StorageTranslation = {
      id: getTranslationId(definition.id as any, locale),
      kind: 'TRANSLATION',
      elementId: definition.id as any,
      locale,
      content: {
        note: definition.definition,
      },
      author: 'SAMOSA',
      timestamp,
    };
    definitionTranslations.push(definitionTranslation);
  }
  if (definitionTranslations.length === 0) {
    return;
  }
  const translations: ElementIdToTranslation = {};
  for (const translation of definitionTranslations) {
    translations[translation.elementId] = translation;
  }
  const translationUpdate: EpisodeTranslationDoc = {
    items: { [locale]: { translations } },
  };
  const dbPaths = new DbPaths(db, unitId);
  const translationDocRef = dbPaths.translationsDocRef;
  await translationDocRef.set(translationUpdate, { merge: true });
}

export async function makeVocabDefinitionLlmRequestWithSelection(
  vocab: VocabSelection,
  contextData: BatchVocabContextData
) {
  const context = createBatchVocabContext(contextData);
  context.push(vocabSelectionToLLMUserMessage(vocab));

  // TODO update openai package and use type
  const data = {
    model: 'gpt-4-turbo',
    response_format: { type: 'json_object' },
    messages: context,
  };

  const response = await fetch(openAiLlmEndpoint, {
    method: 'POST',
    headers: openAiLlmHeaders,
    body: JSON.stringify(data),
  });
  const result: CreateChatCompletionResponse = await response.json();
  const assistantMessage = result.choices[0].message.content;
  return llmResponseContentToVocabDefinition(assistantMessage);
}

export async function makeVocabDefinitionLlmRequest(
  vocab: WordGroup,
  sentences: ElementList<Sentence>,
  translations: { [key: string]: Translation },
  contextData: BatchVocabContextData
) {
  const vocabSelection = vocabScriptElementToVocabSelection(
    vocab,
    sentences,
    translations
  );
  const result = await makeVocabDefinitionLlmRequestWithSelection(
    vocabSelection,
    contextData
  );
  result.id = vocab.id;
  return result;
}

export async function batchFillVocabDefinitions(unitId: string) {
  const episodeData = await loadScriptEpisodeData(unitId);
  const content = basicScriptElementsFromEpisodeData(episodeData);
  const sentences = content.filterByKind('SENTENCE');
  const translations = episodeData.translations;
  const unitMetadata = episodeData.unitMetadataDoc;
  // TODO not 100% sure these language codes always populated correctly
  const l1 = unitMetadata.l1Code;
  const l2 = unitMetadata.l2Code;
  const contextData = batchVocabContextData.find(
    data => data.l1 === l1 && data.l2 === l2
  );
  const unfilled = unfilledVocabs(content, translations);
  const f = async (vocab: WordGroup) => {
    const vocabSelection = vocabScriptElementToVocabSelection(
      vocab,
      sentences,
      translations
    );
    const result = await makeVocabDefinitionLlmRequestWithSelection(
      vocabSelection,
      contextData
    );
    result.id = vocab.id;
    // console.log('processed vocab', vocabSelection.headwords);
    return result;
  };
  const batchProcessor = new BatchProcessor(unfilled, 20, f);
  await batchProcessor.run();
  const definitions = batchProcessor.results;
  await importVocabDefinitionsIntoMasala(unitId, l1, definitions);
}

export async function zorchAllVocabDefinitions(unitId: string) {
  // TODO hacky because does not deal with translation versions
  const episodeData = await loadScriptEpisodeData(unitId);
  const translations = episodeData.translations;
  const unitMetadata = episodeData.unitMetadataDoc;
  const locale = unitMetadata.l1Code;
  const outTranslations: typeof translations = {};
  for (const key in translations) {
    const translation = translations[key as any];
    const elementId = translation.elementId;
    if (getKindFromId(elementId) !== 'WORD_GROUP') {
      outTranslations[key as any] = translation;
    }
  }
  const translationUpdate: EpisodeTranslationDoc = {
    items: { [locale]: { translations: outTranslations } },
  };
  const dbPaths = new DbPaths(db, unitId);
  const translationDocRef = dbPaths.translationsDocRef;
  await translationDocRef.set(translationUpdate);
}
