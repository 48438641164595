import { MDBModal, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';
import React from 'react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { scriptEditorModel, versions } from '../models/app-root';
import { getElementVersionDescriptiveContent } from '@masala-lib/content-utils';
import { VersionItem } from '@masala-lib/editorial/ui/timeline-components';
import { getKindFromId } from '@tikka/elements/element-id-utils';
import { EKind } from '@masala-lib/element-kinds';
import { ElementId } from '@masala-lib/editor-aliases';

class VersionsDialogState {
  @observable.ref open = false;
  @observable.ref selectedTS: number = null;
  constructor() {
    makeObservable(this);
  }
}

const dialogState = new VersionsDialogState();

export function openVersionsDialog() {
  const kind = getKindFromId(scriptEditorModel.versionActionsElementId);
  // if (kind === EKind.PASSAGE_QUESTION || kind === EKind.EXCERPT) {
  if (kind === EKind.EXCERPT) {
    return;
  }
  dialogState.open = true;
  scriptEditorModel.setKbDeactivated(true);
}

export function canRevert(id: ElementId) {
  // return getKindFromId(id) !== EKind.SENTENCE;
  return true;
}

export const VersionsDialog = observer(() => {
  const toggleModal = () => {
    dialogState.open = !dialogState.open;
    dialogState.selectedTS = null;
    scriptEditorModel.setKbDeactivated(dialogState.open);
  };

  if (!dialogState.open) {
    return null;
  }

  const elementId = scriptEditorModel.versionActionsElementId;
  const versionItems = elementId ? versions.getVersions(elementId) : [];
  // const versionItems = elementVersions.map(v => {
  //   return {
  //     ...v,
  //     content: getElementVersionDescriptiveContent(
  //       v,
  //       scriptEditorModel.words,
  //       scriptEditorModel.translationsLookup
  //     ),
  //   };
  // });

  const revertTo = (ts: number) => {
    if (!ts) {
      return;
    }
    const version = versionItems.find(version => version.timestamp === ts);
    scriptEditorModel.revertToVersion(version);
  };

  const setSelectedTS = (ts: number) => {
    dialogState.selectedTS = ts;
  };

  return (
    <MDBModal isOpen={dialogState.open} toggle={toggleModal}>
      <MDBModalBody>
        <div className="comment-thread">
          {versionItems.map(item => (
            <VersionItem
              key={item.timestamp}
              onClick={() => setSelectedTS(item.timestamp)}
              className={
                item.timestamp === dialogState.selectedTS ? 'selected' : ''
              }
              item={item}
            />
          ))}
        </div>
      </MDBModalBody>
      {canRevert(elementId) && versionItems.length > 1 ? (
        <MDBModalFooter className="justify-content-center">
          <>
            <MDBBtn
              color="info"
              onClick={() => {
                revertTo(dialogState.selectedTS);
                toggleModal();
              }}
            >
              Revert To
            </MDBBtn>
          </>
        </MDBModalFooter>
      ) : null}
    </MDBModal>
  );
});
