import React from 'react';

export const InfoIcon = ({ width = 16, height = 16, color = '#000000' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <circle cx="16" cy="16" r="16" fill={color} fillRule="nonzero" />
        <path
          fill="#FFF"
          d="M12.612 23.571c0-.69.204-1.761.357-2.527l1.302-6.151-1.813-.179.154-.97 5.436-1.633.408.357-1.914 8.857c-.127.46-.178.817-.178 1.097 0 .332.178.51.433.51.434 0 .996-.51 1.838-1.582l.766.511c-1.021 1.736-2.63 3.395-4.824 3.395-1.2 0-1.965-.587-1.965-1.685zm4.62-13.834c-.97 0-1.864-.689-1.864-1.812 0-1.276 1.047-2.17 2.195-2.17 1.072 0 1.863.74 1.863 1.787 0 1.25-1.046 2.195-2.195 2.195z"
        />
      </g>
    </svg>
  );
};
