import { Slice, TimeInterval } from './timestamper-types';
import { isEmpty, isNull, zip, range } from 'lodash';
import { searchSorted } from '@tikka/intervals/search-sorted';

export type TranscriptFrame = {
  word: string[];
  startTime: number[];
  endTime: number[];
  originalWord: string[];
};

export type TranscriptSpan = {
  data: TranscriptFrame;
  startTime: number;
  endTime: number;
};

export type ContentFrame = {
  word: string[];
  globalPosition: number[];
  originalWord: string[];
};

export type ContentSpan = {
  data: ContentFrame;
  // TODO are startTime and endTime used?
  startTime: number;
  endTime: number;
};

export type CuesFrame = {
  globalPosition: number[];
  timestamp: number[];
};

export type CuesSpan = {
  data: CuesFrame;
  startTime: number;
  endTime: number;
};

export type AudioRegionsFrame = {
  timestamp: number[];
  silenceStartTime: number[];
  silenceEndTime: number[];
};

export type AudioRegionsSpan = {
  data: AudioRegionsFrame;
  startTime: number;
  endTime: number;
};

export function spanGetWords(
  span: { data: ContentFrame } | { data: TranscriptFrame }
): string[] {
  return span.data.word;
}

export function intervalIntersection(
  a: TimeInterval,
  b: TimeInterval
): TimeInterval {
  const lower = Math.max(a.startTime, b.startTime);
  const upper = Math.min(a.endTime, b.endTime);
  if (lower > upper) {
    return null;
  } else {
    return {
      startTime: lower,
      endTime: upper,
    };
  }
}

export function intervalDuration(a: TimeInterval): number {
  return a.endTime - a.startTime;
}

export function computeTranscriptSliceByTime(
  transcriptFrame: TranscriptFrame,
  startTime: number,
  endTime: number
): Slice {
  const startTimes = transcriptFrame.startTime;
  if (isEmpty(startTimes)) {
    return null;
  }
  const endTimes = transcriptFrame.endTime;
  if (startTimes[0] > endTimes[0]) {
    return [0, 0];
  }
  const spanInterval: TimeInterval = {
    startTime,
    endTime,
  };

  let idx = searchSorted(endTimes, startTime, false);
  let firstWordInterval: TimeInterval = {
    startTime: startTimes[idx],
    endTime: endTimes[idx],
  };
  let firstWordIndex = idx;
  let overlap = intervalIntersection(firstWordInterval, spanInterval);
  if (overlap) {
    if (intervalDuration(overlap) / intervalDuration(firstWordInterval) < 0.5) {
      firstWordIndex = firstWordIndex + 1;
    }
  }
  idx = searchSorted(startTimes, endTime, false) - 1;
  let lastWordInterval: TimeInterval = {
    startTime: startTimes[idx],
    endTime: endTimes[idx],
  };
  let lastWordIndex = idx;
  overlap = intervalIntersection(lastWordInterval, spanInterval);
  if (overlap) {
    if (intervalDuration(overlap) / intervalDuration(lastWordInterval) < 0.5) {
      lastWordIndex = lastWordIndex - 1;
    }
  }
  if (lastWordIndex >= firstWordIndex) {
    return [firstWordIndex, lastWordIndex];
  } else {
    return [firstWordIndex, firstWordIndex - 1];
  }
}

export function computeCueFrameSliceByTime(
  cueFrame: CuesFrame,
  startTime: number,
  endTime: number
): Slice {
  const cueTimes = cueFrame.timestamp;
  const firstCueIndex = searchSorted(cueTimes, startTime, false);
  const lastCueIndex = searchSorted(cueTimes, endTime, false) - 1;
  if (lastCueIndex >= firstCueIndex) {
    return [firstCueIndex, lastCueIndex];
  } else {
    return [firstCueIndex, firstCueIndex - 1];
  }
}

export function findAudioIntervalContains(
  intervals: number[],
  point: number
): [number, number, number] {
  const startTimeIdx = searchSorted(intervals, point, false) - 1;
  // TODO if startTimeIdx >= intervals.lastIndex || startTimeIdx < 0 then
  if (startTimeIdx >= intervals.length - 1 ? true : startTimeIdx < 0) {
    return [null, null, null];
  } else {
    const startTime = intervals[startTimeIdx];
    const endTime = intervals[startTimeIdx + 1];
    return [startTime, endTime, startTimeIdx];
  }
}

export function audioRegionsSpanFindAudioIntervalContains(
  regionsSpan: AudioRegionsSpan,
  point: number
): [number, number, number] {
  return findAudioIntervalContains(regionsSpan.data.timestamp, point);
}

export function audioRegionsFrameIntervalIsSilence(
  regionsFrame: AudioRegionsFrame,
  idx: number
): boolean {
  return idx % 2 === 0;
}

export function audioRegionsSpanIntervalIsSilence(
  regionsSpan: AudioRegionsSpan,
  idx: number
): boolean {
  return audioRegionsFrameIntervalIsSilence(regionsSpan.data, idx);
}

export function audioRegionSpanIsContinuousAudio(
  regionsSpan: AudioRegionsSpan
): boolean {
  const epsilon = 20;
  const [startTime, endTime, intervalIdx] =
    audioRegionsSpanFindAudioIntervalContains(
      regionsSpan,
      regionsSpan.startTime + epsilon
    );
  if (intervalIdx == null) {
    return true;
  }
  // const startTime = patternInput[0] | 0;
  // const intervalIdx = patternInput[2] | 0;
  // const endTime = patternInput[1] | 0;
  // TODO if audioRegionsFrameIntervalIsSilence(regionsSpan.data, intervalIdx) then
  if (audioRegionsFrameIntervalIsSilence(regionsSpan.data, intervalIdx)) {
    return false;
    // TODO elif startTime > (regionsSpan.startTime + epsilon) || endTime < (regionsSpan.endTime - epsilon) then
  } else if (
    startTime > regionsSpan.startTime + epsilon
      ? true
      : endTime < regionsSpan.endTime - epsilon
  ) {
    return false;
  } else {
    return true;
  }
}

export function reconcileAudioIntervals(
  targetIntervals: number[],
  exclusionBeginnings: number[],
  exclusionEndings: number[],
  conflict: (i: number) => boolean
) {
  const badSet = new Set<number>();
  for (const [i, times] of zip(
    exclusionBeginnings,
    exclusionEndings
  ).entries()) {
    const [startTime, endTime] = times;
    // const forLoopVar = arr[idx];
    // const startTime = forLoopVar[1][0] | 0;
    // const i = forLoopVar[0] | 0;
    // const endTime = forLoopVar[1][1] | 0;
    const [_, __, startIdx] = findAudioIntervalContains(
      targetIntervals,
      startTime
    );
    const [_start, _end, endIdx] = findAudioIntervalContains(
      targetIntervals,
      endTime
    );
    if (isNull(startIdx)) {
      continue;
    }
    if (startIdx === endIdx) {
      const value = badSet.add(i);
      void value;
    }
  }
  // const arr_1 = indexed(exclusionEndings);
  for (const [i, excludeEndingTime] of exclusionEndings.entries()) {
    // const forLoopVar_1 = arr_1[idx_1];
    // const i_1 = forLoopVar_1[0] | 0;
    // const excludeEndingTime = forLoopVar_1[1] | 0;
    if (badSet.has(i)) {
      continue;
    }
    const [_, __, idx] = findAudioIntervalContains(
      targetIntervals,
      excludeEndingTime
    );
    if (isNull(idx)) {
      continue;
    }
    if (conflict(idx)) {
      targetIntervals[idx] = excludeEndingTime + 2;
    }
  }
  // const arr_2 = indexed(exclusionBeginnings);
  for (const [i, excludeBeginTime] of exclusionBeginnings.entries()) {
    // const forLoopVar_2 = arr_2[idx_3];
    // const i_2 = forLoopVar_2[0] | 0;
    // const excludeBeginTime = forLoopVar_2[1] | 0;
    if (badSet.has(i)) {
      continue;
    }
    const [_, __, idx] = findAudioIntervalContains(
      targetIntervals,
      excludeBeginTime
    );
    if (isNull(idx)) {
      continue;
    }
    if (conflict(idx)) {
      targetIntervals[idx + 1] = excludeBeginTime - 2;
    }
  }
}

export function intervalsToInterleaved(
  intervals: TimeInterval[],
  endTime: number,
  terminated: boolean
): number[] {
  const result: number[] = [];
  if (terminated) {
    // System_Array__$005B$005D$1_append_1505(result, 0);
    result.push(0);
  }
  for (const interval of intervals) {
    // System_Array__$005B$005D$1_append_1505(result, interval.startTime);
    result.push(interval.startTime);
    // System_Array__$005B$005D$1_append_1505(result, interval.endTime);
    result.push(interval.endTime);
  }
  if (terminated) {
    // System_Array__$005B$005D$1_append_1505(result, endTime);
    result.push(endTime);
  }
  return result;
}

export function startsAndEndsToInterleaved(
  startTimes: number[],
  endTimes: number[],
  terminated: boolean,
  endTime: number
): number[] {
  const result: number[] = [];
  if (terminated) {
    // System_Array__$005B$005D$1_append_1505(result, 0);
    result.push(0);
  }
  // const arr = zip(startTimes, endTimes);
  for (const [starts, ends] of zip(startTimes, endTimes)) {
    // const forLoopVar = arr[idx];
    // const starts = forLoopVar[0] | 0;
    // const ends = forLoopVar[1] | 0;
    // System_Array__$005B$005D$1_append_1505(result, starts);
    result.push(starts);
    // System_Array__$005B$005D$1_append_1505(result, ends);
    result.push(ends);
  }
  if (terminated) {
    // System_Array__$005B$005D$1_append_1505(result, endTime);
    result.push(endTime);
  }
  return result;
}

export function interleavedToStartsEnds(
  interleaved: number[],
  terminated: boolean
): [number[], number[]] {
  const startIndex = terminated ? 1 : 0;
  const endIndex = terminated
    ? // ? System_Array__$005B$005D$1_get_lastIndex(interleaved) - 1
      interleaved.length - 2
    : // : System_Array__$005B$005D$1_get_lastIndex(interleaved) | 0;
      interleaved.length - 1;
  // const startTimes = Int32Array.from(
  //   delay(() => map(i => interleaved[i], rangeNumber(startIndex, 2, endIndex)))
  // );
  const startTimes = range(startIndex, endIndex + 1, 2).map(
    i => interleaved[i]
  );
  // const endTimes = Int32Array.from(
  //   delay(() =>
  //     map(i_1 => interleaved[i_1], rangeNumber(startIndex + 1, 2, endIndex))
  //   )
  // );
  const endTimes = range(startIndex + 1, endIndex + 1, 2).map(
    i => interleaved[i]
  );
  return [startTimes, endTimes];
}

export function interleavedToIntervals(
  interleaved: number[],
  terminated: boolean
): TimeInterval[] {
  const [startTimes, endTimes] = interleavedToStartsEnds(
    interleaved,
    terminated
  );
  // const startTimes = patternInput[0];
  // const endTimes = patternInput[1];
  // [| for starts, ends in Array.zip startTimes endTimes -> {startTime=starts; endTime=ends}|]
  return zip(startTimes, endTimes).map(([starts, ends]) => {
    return {
      startTime: starts,
      endTime: ends,
    };
  });
  // return Array.from(
  //   delay(() =>
  //     collect(matchValue => {
  //       const starts = matchValue[0] | 0;
  //       const ends = matchValue[1] | 0;
  //       return singleton(new TimeInterval(starts, ends));
  //     }, zip(startTimes, endTimes))
  //   )
  // );
}
