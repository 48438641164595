import { isFunction } from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { useTitle } from '../../lib/hooks/use-title';

const Wrapper = styled.div`
  --pageHeaderHeight: 48px;
  min-height: 100vh;
  & > header {
    font-family: Roboto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: var(--pageHeaderHeight);
    padding: 0 16px 0 24px;
    /* margin-bottom: 12px; */
    border-bottom: 1px solid var(--color-gray50);

    .close-button {
      margin-right: 8px;
      border: none;
      background: none;
      display: flex;
      place-content: center;
      color: var(--color-gray300);
      border-radius: 4px;
      margin-left: -16px;
      &:hover,
      &:active {
        color: var(--color-gray600);
        background-color: var(--color-gray50);
      }
    }

    & > .page-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    & > .page-controls {
      flex: 1;
      /* background: blue; */
      margin-left: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  & > main {
    overflow: auto;
    height: calc(100vh - 48px);
  }
`;

const CloseIcon = (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3002 5.70997C17.9102 5.31997 17.2802 5.31997 16.8902 5.70997L12.0002 10.59L7.11022 5.69997C6.72022 5.30997 6.09021 5.30997 5.70021 5.69997C5.31021 6.08997 5.31021 6.71997 5.70021 7.10997L10.5902 12L5.70021 16.89C5.31021 17.28 5.31021 17.91 5.70021 18.3C6.09021 18.69 6.72022 18.69 7.11022 18.3L12.0002 13.41L16.8902 18.3C17.2802 18.69 17.9102 18.69 18.3002 18.3C18.6902 17.91 18.6902 17.28 18.3002 16.89L13.4102 12L18.3002 7.10997C18.6802 6.72997 18.6802 6.08997 18.3002 5.70997Z"
      fill="currentColor"
    />
  </svg>
);

interface PageProps {
  title?: false | string;
  onClose?: () => void;
  children: React.ReactNode;
  controls?: React.ReactNode;
}

export const Page = ({
  title = false,
  onClose,
  children,
  controls,
}: PageProps) => {
  const showCloseButton = isFunction(onClose);
  const showControls = !!controls;
  useTitle(title || '');
  return (
    <Wrapper>
      <header>
        {showCloseButton ? (
          <button className="close-button" onClick={onClose}>
            {CloseIcon}
          </button>
        ) : null}
        {title ? <h2 className="page-title">{title}</h2> : null}
        {showControls ? <div className="page-controls">{controls}</div> : null}
      </header>
      <main>{children}</main>
    </Wrapper>
  );
};
