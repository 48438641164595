import { DbPaths, DocKeys, isForkedUnitId, sharedDocKeys } from './db';
import { firebase, db } from '../../../platform/firebase-init';
import * as Diff from 'diff';
import { randomString, epochSecondsFloat } from '../../utils';
import {
  ElementId,
  ElementIdToAny,
  ElementIdToElement,
  idIsOfKind,
  WordId,
} from '@tikka/basic-types';
import {
  ChaatTimestampsDoc,
  EpisodeMetadataBlockDoc,
  // EpisodeMetadataDoc,
  EpisodeStructuralDoc,
  EpisodeTranslationDoc,
  EpisodeVerbatimDoc,
  EpisodeWordGroupDoc,
  SentenceVersionsDoc,
  StructuralVersionsDoc,
  TranslationVersionsDoc,
  TrickyEdit,
  WordGroupVersionsDoc,
} from './firestore-doc-types';
import { DocumentReference } from '../../../platform/firebase-types';
import { Auth } from './auth';
import { Alert, AlertMessages } from '../../misc/alert-messages';
import {
  BasicElementKind,
  EKind,
  StructuralKindsList,
} from '../../element-kinds';
import { ElementList, Sentence } from '../../editor-aliases';
import {
  getKindFromId,
  getTranslationId,
} from '@tikka/elements/element-id-utils';
import { getElementVersionDescriptiveContent } from '../../content-utils';
import { UnitData } from '../../catalog/catalog-types';
import { comparer } from 'mobx';
import { Perms } from './permissions';
import {
  loadStructuralDoc,
  loadTranslationsVersionsDoc,
  loadVerbatimDoc,
} from './versions-db';
import { computeFilteredVersionsDoc, versionKey } from './versions-update';
import { copyWordRecords, createWordRecordsData } from '../ids/word-ids';
import { normalizeSpecialChars } from '../../misc/editorial-string-utils';
import {
  AudioMarker,
  AudioRegionKind,
  AudioRegionWriteDto,
  Cue,
} from '../chaat/chaat-types';
import {
  Attributed,
  AttributedElement,
  Content,
  ContentOfStructural,
  EditorElement,
  EditorStructural,
  Element,
  ElementIdToTranslation,
  ElementVersionData,
  MetadataBlockWriteDto,
  PointAnchor,
  SentenceVersionData,
  SentenceWriteDto,
  SpanAnchor,
  StorageMetadataBlock,
  StorageSentence,
  StorageStructural,
  StorageWordGroup,
  StructuralWriteDto,
  Timestamped,
  Translation,
  TranslationVersionData,
  WordGroupVersionData,
  WordGroupWriteDto,
} from '../../editorial-types';
import { WordRecord } from '../../editorial-types-aside';
import { getWordRecordsData } from '../../editorial-word-element-list';
import { isEmpty } from 'lodash';
import { TrickyBitsSignalTransforms } from '../chaat/quanta-funcs';
import { LocaleCode } from '@utils/util-types';

/******* WHOLE EPISODE DB CREATE/UPDATE *********/
export async function createUpdateEpisodeDB(
  episodeKey: string,
  data: any,
  unitData: UnitData
  // metadata: EpisodeMetadataDoc
) {
  const dbPaths = new DbPaths(db, episodeKey);
  // const metadataDocRef = dbPaths.metadataDocRef;
  const unitMetadataDocRef = dbPaths.unitMetadataDocRef;
  const verbatimDocRef = dbPaths.verbatimDocRef;
  const structuralDocRef = dbPaths.structuralDocRef;
  const wordGroupsDocRef = dbPaths.wordGroupsDocRef;
  const metadataBlocksDocRef = dbPaths.metadataBlocksDocRef;
  // const threadsDocRef = dbPaths.threadsDocRef;
  const translationsDocRef = dbPaths.translationsDocRef;
  const warningSuppressionsDocRef = dbPaths.warningSuppressionsDocRef;

  const sentenceVersionsDocRef = dbPaths.sentenceVersionsDocRef;
  const structuralVersionsDocRef = dbPaths.structuralVersionsDocRef;
  const wordGroupVersionsDocRef = dbPaths.wordGroupVersionsDocRef;
  const translationVersionsDocRef = dbPaths.translationVersionsDocRef;

  const auditLogDocRef = dbPaths.auditLogDocRef;

  const promises = [];
  // const learnersLanguage = metadata ? metadata.learnersLanguage : 'en'; // TODO
  const learnersLanguage = unitData.l1Code;
  console.log(`createUpdateEpisodeDB - learnersLanguage: ${learnersLanguage}`);
  console.log(`data.translations: ${JSON.stringify(data.translations)}`);

  // promises.push(
  //   metadataDocRef.set({
  //     editEnabled: true,
  //     learnersLanguage,
  //     schemaVersion: '0.5',
  //   })
  // );
  promises.push(
    unitMetadataDocRef.update({
      scriptInitTime: epochSecondsFloat(),
      schemaVersion: '2.0',
    })
  );

  // TODO add timestamp?
  promises.push(verbatimDocRef.set(data.verbatim));

  promises.push(structuralDocRef.set({ items: data.structural }));
  promises.push(wordGroupsDocRef.set({ items: data.wordGroups }));
  // TODO
  promises.push(metadataBlocksDocRef.set({ items: data.metadataBlocks }));
  // promises.push(threadsDocRef.set({threads:{}}));
  promises.push(
    translationsDocRef.set({
      items: { [learnersLanguage]: { translations: data.translations } },
    })
  );
  promises.push(warningSuppressionsDocRef.set({ items: [] }));

  // TODO merge or not?
  promises.push(
    sentenceVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    wordGroupVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    structuralVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    translationVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );

  promises.push(auditLogDocRef.set({ items: {} }));

  return Promise.all(promises);
}

export async function resetVersionDocs(episodeKey: string) {
  consoleAddAuditLogMessage(episodeKey, 'resetVersionDocs');
  const dbPaths = new DbPaths(db, episodeKey);

  const sentenceVersionsDocRef = dbPaths.sentenceVersionsDocRef;
  const structuralVersionsDocRef = dbPaths.structuralVersionsDocRef;
  const wordGroupVersionsDocRef = dbPaths.wordGroupVersionsDocRef;
  const translationVersionsDocRef = dbPaths.translationVersionsDocRef;

  const promises = [];
  promises.push(
    sentenceVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    wordGroupVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    structuralVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );
  promises.push(
    translationVersionsDocRef.set({ items: {}, activeVersionIdMap: {} })
  );

  return Promise.all(promises);
}

export async function resetTranslationVersionDoc(episodeKey: string) {
  const dbPaths = new DbPaths(db, episodeKey);
  const translationVersionsDocRef = dbPaths.translationVersionsDocRef;

  await translationVersionsDocRef.set({ items: {}, activeVersionIdMap: {} });
}

export async function trimTranslationVersions(
  episodeKey: string,
  cutoffTimestamp: number
) {
  const dbPaths = new DbPaths(db, episodeKey);
  const translationVersionsDoc = await loadTranslationsVersionsDoc(episodeKey);
  const versionLists: { [index in string]: TranslationVersionData[] } =
    {} as any;
  for (const version of Object.values(translationVersionsDoc.items)) {
    const key = version.id + ':' + version.locale;
    const versionList = versionLists[key] ?? [];
    versionList.push(version);
    versionLists[key] = versionList;
  }
  const trim = {} as any;
  for (const versionList of Object.values(versionLists)) {
    versionList.sort((a, b) => a.timestamp - b.timestamp);
    const elementId = versionList[0].elementId;
    if (idIsOfKind(elementId, EKind.SENTENCE)) {
      while (versionList.length > 2) {
        const old =
          versionList[0].timestamp < cutoffTimestamp &&
          versionList[1].timestamp < cutoffTimestamp;
        if (old) {
          trim[versionKey(versionList.shift())] =
            firebase.firestore.FieldValue.delete();
        } else {
          break;
        }
      }
    }
    let i = 0;
    while (versionList.length > 1 && i < versionList.length - 1) {
      if (
        versionList[i].content &&
        versionList[i].content === versionList[i + 1].content
      ) {
        trim[versionKey(versionList[i])] =
          firebase.firestore.FieldValue.delete();
        versionList.splice(i, 1);
      } else {
        i++;
      }
    }
  }
  const translationsVersionsDocRef = dbPaths.translationVersionsDocRef;
  await translationsVersionsDocRef.set({ items: trim }, { merge: true });
}

export function computeTrimmedLocaleTranslationVersion(
  locale: LocaleCode,
  translationVersionsDoc: TranslationVersionsDoc
): TranslationVersionsDoc {
  const items = translationVersionsDoc.items;
  const outItems: typeof items = {};
  for (const [id, version] of Object.entries(items)) {
    if (version.locale === locale) {
      outItems[id] = version;
    }
  }
  const activeVersionIdMap = translationVersionsDoc.activeVersionIdMap;
  const outActiveMap: typeof activeVersionIdMap = {};
  for (const [id, versionId] of Object.entries(activeVersionIdMap)) {
    if (versionId in outItems) {
      outActiveMap[id as any] = versionId;
    }
  }
  return { items: outItems, activeVersionIdMap: outActiveMap };
}

export async function clearOutAllButLocaleTranslations(
  unitId: string,
  locale: LocaleCode
) {
  const dbPaths = new DbPaths(db, unitId);
  const translationVersionsDoc = await loadTranslationsVersionsDoc(unitId);
  const update = computeTrimmedLocaleTranslationVersion(
    locale,
    translationVersionsDoc
  );
  const translationVersionsDocRef = dbPaths.translationVersionsDocRef;
  await translationVersionsDocRef.set(update);

  const translationsDocRef = dbPaths.translationsDocRef;
  const translationsDoc = (await translationsDocRef.get()).data();
  const translationSet = translationsDoc.items;
  const nukeLocaleList: LocaleCode[] = [];
  for (const lang of Object.keys(translationSet)) {
    if (lang !== locale) {
      nukeLocaleList.push(lang as LocaleCode);
    }
  }
  const translationItemsUpdate = {} as any;
  for (const lang of nukeLocaleList) {
    translationItemsUpdate[lang] = firebase.firestore.FieldValue.delete();
  }
  await translationsDocRef.set(
    { items: translationItemsUpdate },
    { merge: true }
  );
}

export async function createAllFirestoreCollections() {
  const createDoc = async (docRef: DocumentReference) => {
    docRef.set({});
  };
  const dummyDocName = '!dummy';
  const dbPaths = new DbPaths(db, dummyDocName);

  await createDoc(dbPaths.unitMetadataDocRef);
  // await createDoc(dbPaths.metadataDocRef);
  // await createDoc(dbPaths.chaatMetadataDocRef);
  await createDoc(dbPaths.verbatimDocRef);
  await createDoc(dbPaths.structuralDocRef);
  await createDoc(dbPaths.wordGroupsDocRef);
  await createDoc(dbPaths.translationsDocRef);
  await createDoc(dbPaths.metadataBlocksDocRef);
  await createDoc(dbPaths.chaatCuesDocRef);
  await createDoc(dbPaths.chaatTimestampsDocRef);
  await createDoc(dbPaths.chaatAudioAnalysisDocRef);
  await createDoc(dbPaths.chaatAudioRegionsDocRef);
  await createDoc(dbPaths.chaatAudioMarkersDocRef);
  await createDoc(dbPaths.chaatSpeechTranscriptsDocRef);
  await createDoc(dbPaths.sentenceVersionsDocRef);
  await createDoc(dbPaths.wordGroupVersionsDocRef);
  await createDoc(dbPaths.structuralVersionsDocRef);
  await createDoc(dbPaths.translationVersionsDocRef);
  await createDoc(dbPaths.warningSuppressionsDocRef);
  // await createDoc(dbPaths.chaatAudioProcessingJobDocRef);
  // await createDoc(dbPaths.chaatTranscriptionJobDocRef);
  await createDoc(dbPaths.chaatSignoffsDocRef);
  await createDoc(dbPaths.auditLogDocRef);
}

export async function zorchWordGroups(
  episodeKey: string,
  keep: (wg: StorageWordGroup) => boolean = null
) {
  const dbPaths = new DbPaths(db, episodeKey);
  const items: ElementIdToElement<StorageWordGroup> = {};
  const wordGroupsDocRef = dbPaths.wordGroupsDocRef;
  const wordGroupVersionsDocRef = dbPaths.wordGroupVersionsDocRef;
  // const filter = (wordGroup: StorageWordGroup) => wordGroup.subKind !== 'VOCAB';
  keep ??= (wg: StorageWordGroup) => false;
  const wordGroupsDoc = (await wordGroupsDocRef.get()).data();
  for (const [id, wordGroup] of Object.entries(wordGroupsDoc.items)) {
    if (keep(wordGroup)) {
      items[wordGroup.id] = wordGroup;
    }
  }
  const wordGroupVersionsDoc = (await wordGroupVersionsDocRef.get()).data();
  const wordGroupVersionsUpdate =
    computeFilteredVersionsDoc<WordGroupVersionData>(
      wordGroupVersionsDoc,
      keep
    );
  await wordGroupVersionsDocRef.set(wordGroupVersionsUpdate);
  await wordGroupsDocRef.set({ items });
}

export async function getUnitExportData(
  episodeKey: string,
  includeShared = true
): Promise<any> {
  const dbPaths = new DbPaths(db, episodeKey);
  const result = {} as any;
  for (const docKey of Object.values(DocKeys)) {
    if (!includeShared && sharedDocKeys.includes(docKey)) {
      continue;
    }
    const docRef = dbPaths.getDocRef(docKey);
    const snapshot = await docRef.get();
    const data = snapshot.data();
    result[docKey] = data;
  }
  return result;
}

export async function importUnitData(episodeKey: string, jsonData: any) {
  const dbPaths = new DbPaths(db, episodeKey);
  for (const [docKey, value] of Object.entries(jsonData) as [DocKeys, any]) {
    if (value === undefined) {
      continue;
    }
    const docRef = dbPaths.getDocRef(docKey) as DocumentReference<any>;
    await docRef.set(value);
  }
}

// export async function partialCloneEpisodeDocs(src: string, dst: string) {
//   console.log(`partialCloneEpisodeDocs - src: ${src}, dst: ${dst}`);
//   const srcPaths = new DbPaths(db, src);
//   const dstPaths = new DbPaths(db, dst);
//   const clone = async (docKey: DocKeys) => {
//     const srcDocRef = srcPaths.getDocRef(docKey);
//     const snapshot = await srcDocRef.get();
//     const data = snapshot.data();
//     if (!data) {
//       throw Error(`[clone episode]: missing source data. source id: ${src}`);
//     }
//     const dstDocRef = dstPaths.getDocRef(docKey) as DocumentReference<any>;
//     await dstDocRef.set(data);
//   };

//   // sentenceVersionsDocRef
//   await dstPaths.sentenceVersionsDocRef.set({
//     items: {},
//     activeVersionIdMap: {},
//   });
//   // wordGroupVersionsDocRef
//   await dstPaths.wordGroupVersionsDocRef.set({
//     items: {},
//     activeVersionIdMap: {},
//   });
//   // structuralVersionsDocRef
//   await dstPaths.structuralVersionsDocRef.set({
//     items: {},
//     activeVersionIdMap: {},
//   });
//   // translationVersionsDocRef
//   await dstPaths.translationVersionsDocRef.set({
//     items: {},
//     activeVersionIdMap: {},
//   });

//   // // metadataDocRef
//   // await clone(DocKeys.EPISODE_METADATA_DOC);
//   // verbatimDocRef
//   await clone(DocKeys.VERBATIM_DOC);
//   // structuralDocRef
//   await clone(DocKeys.STRUCTURAL_DOC);
//   // wordGroupsDocRef
//   await clone(DocKeys.WORD_GROUP_DOC);
//   // translationsDocRef
//   await clone(DocKeys.TRANSLATIONS_DOC);
//   // metadataBlocksDocRef
//   await clone(DocKeys.METADATA_BLOCKS_DOC);
//   // chaatCuesDocRef
//   await clone(DocKeys.CHAAT_CUES_DOC);
//   // chaatTimestampsDocRef
//   await clone(DocKeys.CHAAT_TIMESTAMPS_DOC);
//   // chaatAudioAnalysisDocRef
//   await clone(DocKeys.CHAAT_AUDIO_ANALYSIS_DOC);
//   // chaatAudioRegionsDocRef
//   await clone(DocKeys.CHAAT_AUDIO_REGIONS_DOC);
//   // chaatAudioMarkersDocRef
//   await clone(DocKeys.CHAAT_AUDIO_MARKERS_DOC);
//   // chaatSpeechTranscriptsDocRef
//   await clone(DocKeys.CHAAT_SPEECH_TRANSCRIPT_DOC);
//   // warningSuppressionsDocRef
//   await clone(DocKeys.WARNING_SUPPRESSIONS_DOC);
//   // chaatAudioProcessingJobDocRef
//   // await clone(DocKeys.CHAAT_AUDIO_PROCESSING_JDB_DOC); //??
//   // // chaatTranscriptionJobDocRef
//   // await clone(DocKeys.CHAAT_TRANSCRIPTION_JOB_DOC); //??
//   // // chaatSignoffsDocRef
//   await clone(DocKeys.CHAAT_SIGNOFFS_DOC);

//   const srcUnitData = (await srcPaths.unitMetadataDocRef.get()).data();
//   await dstPaths.unitMetadataDocRef.update({
//     scriptInitTime: srcUnitData.scriptInitTime,
//     chaatInitTime: srcUnitData.chaatInitTime,
//     audioProcessingJob: srcUnitData.audioProcessingJob,
//     transcriptionJob: srcUnitData.transcriptionJob,
//     cloneSourceUnitId: src,
//   });
// }

// export async function partialMergeEpisodeDocs(src: string, dst: string) {
//   const srcPaths = new DbPaths(db, src);
//   const dstPaths = new DbPaths(db, dst);
//   const changeList = await generateDocChangeList(src);
//   const changeSet = new Set(changeList.map(change => change.baseVersion.id));
//   const pickChanged = (items: any) => {
//     const result: any = {};
//     for (const [id, element] of Object.entries(items)) {
//       if (changeSet.has(id)) {
//         result[id] = element;
//       }
//     }
//     return result;
//   };

//   const simpleMerge = async (docKey: DocKeys) => {
//     const srcDocRef = srcPaths.getDocRef(docKey);
//     const snapshot = await srcDocRef.get();
//     const data: any = snapshot.data();
//     const items = { items: pickChanged(data.items) };
//     if (isEmpty(items.items)) {
//       // set with empty items and merge = true will nuke everything in items as opposed to noop
//       return;
//     }
//     const dstDocRef = dstPaths.getDocRef(docKey) as DocumentReference<any>;
//     await dstDocRef.set(items, { merge: true });
//   };

//   // structural
//   await simpleMerge(DocKeys.STRUCTURAL_DOC);
//   // word groups
//   await simpleMerge(DocKeys.WORD_GROUP_DOC);

//   // translations
//   const snapshot = await srcPaths.translationsDocRef.get();
//   const data = snapshot.data();
//   const update: any = { items: {} };
//   for (const [lang, translations0] of Object.entries(data.items)) {
//     const { translations } = translations0;
//     const changed: any = {};
//     for (const translation of Object.values(translations)) {
//       if (changeSet.has(translation.id)) {
//         changed[translation.elementId] = translation;
//       }
//     }
//     if (isEmpty(changed)) {
//       // set with empty object and merge = true will nuke everything as opposed to noop
//       continue;
//     }
//     update.items[lang] = { translations: changed };
//   }
//   if (isEmpty(update.items)) {
//     // set with empty object and merge = true will nuke everything as opposed to noop
//     return;
//   }
//   await dstPaths.translationsDocRef.set(update, { merge: true });
// }

type VersionDocRef =
  | DocumentReference<SentenceVersionsDoc>
  | DocumentReference<WordGroupVersionsDoc>
  | DocumentReference<StructuralVersionsDoc>
  | DocumentReference<TranslationVersionsDoc>;

export interface ChangeRecord {
  baseVersion: ElementVersionData;
  version: ElementVersionData;
}

export type ChangeList = ChangeRecord[];

export interface ChangeMessageData {
  id: ElementId;
  author: string;
  timestamp: number;
  message: string;
}

export function getBaselineVersions(
  versions: ElementVersionData[],
  timestamp: number = null
): { [index in ElementId]: ElementVersionData } {
  const elementVersionsList: { [index in ElementId]: ElementVersionData[] } =
    {};
  const result: { [index in ElementId]: ElementVersionData } = {};

  if (!timestamp) {
    return {};
  }

  for (const versionData of versions) {
    const versionList = elementVersionsList[versionData.id] ?? [];
    versionList.push(versionData);
    elementVersionsList[versionData.id] = versionList;
  }

  for (let versionList of Object.values(elementVersionsList)) {
    if (timestamp) {
      versionList = versionList.filter(v => v.timestamp <= timestamp);
    }
    versionList.sort((a, b) => a.timestamp - b.timestamp);
    if (versionList.length) {
      const baseLineVersion = timestamp
        ? versionList[versionList.length - 1]
        : versionList[0];
      result[baseLineVersion.id] = baseLineVersion;
    }
  }
  return result;
}

export function getChangeRecordFromBaseline(
  version: ElementVersionData,
  baselineVersions: { [index in ElementId]: ElementVersionData }
) {
  const baselineVersion = baselineVersions[version.id];
  if (
    version !== baselineVersion &&
    !versionsAreEquivalent(version, baselineVersion)
  ) {
    return { version, baseVersion: baselineVersion };
  }
  return null;
}

// TODO refactor move all this stuff to version-funcs.ts??
export async function generateDocChangeList(episodeKey: string) {
  const dbPaths = new DbPaths(db, episodeKey);
  const loadVersionDoc = async (docRef: VersionDocRef) => {
    const snapshot = await docRef.get();
    return snapshot.data();
  };

  const sentenceVersionsData = await loadVersionDoc(
    dbPaths.sentenceVersionsDocRef
  );
  const structuralVersionsData = await loadVersionDoc(
    dbPaths.structuralVersionsDocRef
  );
  const wordGroupVersionsData = await loadVersionDoc(
    dbPaths.wordGroupVersionsDocRef
  );
  const translationsVersionsData = await loadVersionDoc(
    dbPaths.translationVersionsDocRef
  );

  const versionsItems = {
    ...sentenceVersionsData.items,
    ...structuralVersionsData.items,
    ...wordGroupVersionsData.items,
    ...translationsVersionsData.items,
  };

  const activeVersionsIds = {
    ...sentenceVersionsData.activeVersionIdMap,
    ...structuralVersionsData.activeVersionIdMap,
    ...wordGroupVersionsData.activeVersionIdMap,
    ...translationsVersionsData.activeVersionIdMap,
  };

  const activeVersions = Object.values(activeVersionsIds)
    .filter(id => id)
    .map(id => versionsItems[id]);

  const baselineVersions = getBaselineVersions(Object.values(versionsItems));
  const changeList: ChangeList = [];

  for (const activeVersion of activeVersions) {
    const changeRecord = getChangeRecordFromBaseline(
      activeVersion,
      baselineVersions
    );
    if (changeRecord) {
      changeList.push(changeRecord);
    }
  }

  return changeList;
}

export function wordsShowDiff(oldStr: string, newStr: string) {
  let result = '';
  oldStr = oldStr ?? '';
  newStr = newStr ?? '';
  const diff = Diff.diffWordsWithSpace(oldStr, newStr);
  for (const part of diff) {
    const word = part.value;
    const partStr = part.added
      ? `<span class="added">${word}</span>`
      : part.removed
      ? `<span class="removed">${word}</span>`
      : word;
    result += partStr;
  }
  return result;
}

export function versionsAreEquivalent(
  current: ElementVersionData,
  previous: ElementVersionData
): boolean {
  const textOf = getElementVersionDescriptiveContent;
  if (!previous) {
    return false;
  }
  if (current.kind === EKind.WORD_GROUP) {
    const previousWordGroupData = previous as WordGroupVersionData;
    if (current.content.canonical !== previousWordGroupData.content.canonical) {
      return false;
    }
    if (current.content.usage !== previousWordGroupData.content.usage) {
      return false;
    }
    if (
      !!current.content.preserveCase !==
      !!previousWordGroupData.content.preserveCase
    ) {
      return false;
    }
    if (!comparer.shallow(current.anchor, previousWordGroupData.anchor)) {
      return false;
    }
    return true;
    // for now this code path is only used in review
  }
  if (current.kind === EKind.TRANSLATION) {
    const kind = getKindFromId(current.elementId);
    if (idIsOfKind(current.elementId, EKind.WORD_GROUP)) {
      // if (kind === EKind.WORD_GROUP) {
      return (<any>previous).content.note === (<any>current).content.note;
    }
  }
  return textOf(previous) === textOf(current);
}

const kindToUIText: { [index in EKind]?: string } = {
  [EKind.SENTENCE]: 'Sentence',
  [EKind.CHAPTER]: 'Chapter Title',
  [EKind.CHAPTER_NOTE]: 'Chapter Note',
  [EKind.PARAGRAPH]: 'Paragraph',
  [EKind.PASSAGE]: 'Passage',
  [EKind.WORD_GROUP]: 'Word Group',
  [EKind.TRANSLATION]: 'Translation',
  // [EKind.PASSAGE_QUESTION]: 'Passage Question',
  [EKind.EXCERPT]: 'Excerpt',
};

export function generateChangeMessageData(
  current: ElementVersionData,
  previous: ElementVersionData,
  generalMessage: boolean = false
): ChangeMessageData {
  const part = {
    id: current.id,
    timestamp: current.timestamp,
    author: current.author,
  };
  const textOf = getElementVersionDescriptiveContent;
  const getKindDescription = (version: ElementVersionData) => {
    if (version.kind === EKind.TRANSLATION) {
      return `${kindToUIText[getKindFromId(version.elementId) as EKind]} ${
        kindToUIText[version.kind]
      }`;
    }
    return kindToUIText[version.kind];
  };
  if (current && !previous) {
    let id = current.id;
    if (current.kind === EKind.TRANSLATION) {
      if (isEmpty(current.content)) {
        return null;
      }
      // if (getKindFromId(current.elementId) === EKind.WORD_GROUP) {
      if (idIsOfKind(current.elementId, EKind.WORD_GROUP)) {
        id = current.elementId;
        const note = (current.content as any).note;
        if (isEmpty(note)) {
          return null;
        }
        return {
          ...part,
          id,
          timestamp: current.timestamp,
          message: 'New ' + getKindDescription(current) + ': ' + note,
        };
      }
    }
    return {
      ...part,
      id,
      message: 'New ' + getKindDescription(current),
    };
  }
  if (current.deleted) {
    return {
      ...part,
      message: 'Deleted ' + getKindDescription(current),
    };
  }
  const generalMessageText = generalMessage
    ? 'Modified ' + getKindDescription(current) + ': '
    : '';
  if (current.kind === EKind.WORD_GROUP) {
    const previousWordGroupData = previous as WordGroupVersionData;
    // TODO generate multiple change comments
    if (current.content.canonical !== previousWordGroupData.content.canonical) {
      return {
        ...part,
        message:
          generalMessageText +
          `Canonical: ${wordsShowDiff(
            previousWordGroupData.content.canonical,
            current.content.canonical
          )}`,
      };
    } else if (current.content.usage !== previousWordGroupData.content.usage) {
      return {
        ...part,
        message:
          generalMessageText +
          `Usage: ${wordsShowDiff(
            previousWordGroupData.content.usage,
            current.content.usage
          )}`,
      };
    } else if (
      !!current.content.preserveCase !==
      !!previousWordGroupData.content.preserveCase
    ) {
      return {
        ...part,
        message:
          generalMessageText +
          `Preserve case: ${previousWordGroupData.content.preserveCase} -> ${current.content.preserveCase}`,
      };
    } else if (
      !comparer.shallow(current.anchor, previousWordGroupData.anchor)
    ) {
      return {
        ...part,
        message: 'Modified Word Group Range',
      };
    } else {
      return null;
    }
  }
  if (current.kind === EKind.TRANSLATION) {
    const kind = getKindFromId(current.elementId);
    // if (kind === EKind.WORD_GROUP) {
    if (idIsOfKind(current.elementId, EKind.WORD_GROUP)) {
      return {
        ...part,
        id: current.elementId,
        message:
          generalMessageText +
          wordsShowDiff(
            (<any>previous).content.note,
            (<any>current).content.note
          ),
      };
    }
  }
  return {
    ...part,
    message:
      generalMessageText + wordsShowDiff(textOf(previous), textOf(current)),
  };
}

export function generateChangeMessageDataForChangeRecord(
  change: ChangeRecord,
  generalMessage: boolean = false
) {
  return generateChangeMessageData(
    change.version,
    change.baseVersion,
    generalMessage
  );
}

export function generateChangeListMessageData(changeList: ChangeList) {
  return changeList
    .map(change => generateChangeMessageDataForChangeRecord(change))
    .filter(item => item);
}

export async function assertExistVersionDocs(episodeKey: string) {
  // TODO
}

export async function assertExistChaatDocs(episodeKey: string) {
  const dbPaths = new DbPaths(db, episodeKey);
  // const chaatMetadataDocRef = dbPaths.chaatMetadataDocRef;
  const chaatCuesDocRef = dbPaths.chaatCuesDocRef;
  const chaatTimestampsDocRef = dbPaths.chaatTimestampsDocRef;
  const chaatAudioAnalysisDocRef = dbPaths.chaatAudioAnalysisDocRef;
  const chaatAudioRegionsDocRef = dbPaths.chaatAudioRegionsDocRef;
  const chaatAudioMarkersDocRef = dbPaths.chaatAudioMarkersDocRef;
  const chaatSpeechTranscriptsDocRef = dbPaths.chaatSpeechTranscriptsDocRef;
  // const chaatAudioProcessingJobDocRef = dbPaths.chaatAudioProcessingJobDocRef;
  // const chaatTranscriptionJobDocRef = dbPaths.chaatTranscriptionJobDocRef;
  const chaatSignoffsDocRef = dbPaths.chaatSignoffsDocRef;

  const promises = [];
  // promises.push(chaatMetadataDocRef.set({}));
  // TODO add timestamps
  promises.push(chaatCuesDocRef.set({ cues: {}, timestamp: 1.0 })); // TODO how to merge array?
  promises.push(
    chaatSpeechTranscriptsDocRef.set({ timestamp: 1.0 }, { merge: true })
  );
  promises.push(
    chaatAudioRegionsDocRef.set({ items: {}, timestamp: 1.0 }, { merge: true })
  );

  promises.push(chaatTimestampsDocRef.set({ timestamp: 0.0 }, { merge: true }));
  promises.push(chaatAudioAnalysisDocRef.set({}, { merge: true }));
  promises.push(chaatAudioMarkersDocRef.set({ items: {} }, { merge: true }));
  // promises.push(chaatAudioProcessingJobDocRef.set({}, { merge: true }));
  // promises.push(chaatTranscriptionJobDocRef.set({}, { merge: true }));
  promises.push(chaatSignoffsDocRef.set({ items: [] }, { merge: true }));
  return Promise.all(promises);
}

// // old chaat init
// export async function initChaatForUnit(
//   episodeKey: string,
//   localJobFlowFn: () => Promise<string>
// ) {
//   const dbPaths = new DbPaths(db, episodeKey);
//   // const chaatMetadataDocRef = dbPaths.chaatMetadataDocRef;
//   const unitMetadataDocRef = dbPaths.unitMetadataDocRef;
//   // const chaatAudioProcessingJobDocRef = dbPaths.chaatAudioProcessingJobDocRef;
//   let metadata = {
//     // ...metadata0, // todo: transform
//     chaatInitTime: epochSecondsFloat(),
//     audioProcessingJob: {
//       inputAudioUpdateTimestamp: epochSecondsFloat(),
//     },
//   };
//   // console.log(`setting chaat metadata: ${JSON.stringify(metadata)}`);
//   console.log(
//     `setting Unit[${episodeKey}] chaat data: ${JSON.stringify(metadata)}`
//   );

//   // creation of this doc is expected to poke a firestore trigger which will then invoke
//   //   `${masalaFalconServerUrl}/audio_processing_next?key=${context.params.episodeKey}`
//   // TODO: consider just directly invoking
//   let result = await unitMetadataDocRef.update(metadata);
//   // const localJobFlow = !!localJobFlowFn;
//   // result = await chaatAudioProcessingJobDocRef.set(
//   //   {
//   //     localJobFlow, // disable firestore trigger flow
//   //     inputAudioUpdateTimestamp: epochSecondsFloat(),
//   //   },
//   //   { merge: true }
//   // );

//   if (localJobFlowFn) {
//     console.log(`invoking local job flow`);
//     await localJobFlowFn();
//   } else {
//     throw Error(`localJobFlowFn required`);
//   }
//   return result;
// }

export async function deleteAllEpisodeDocs(episodeKey: string) {
  console.log(`deleteAllEpisodeDocs(${episodeKey})`);
  consoleAddAuditLogMessage(episodeKey, 'deleteAllEpisodeDocs');
  const dbPaths = new DbPaths(db, episodeKey);
  const promises = [];

  const docKeys = Object.values(DocKeys).filter(
    k => k !== DocKeys.UNIT_METADATA_DOC
  );

  const isFork = isForkedUnitId(episodeKey);

  promises.push(
    dbPaths.unitMetadataDocRef.update({
      scriptInitTime: null,
      chaatInitTime: null,
      // audioProcessingJob: null,
      transcriptionJob: null,
      // todo: should probably clear more unit data
    })
  );

  for (const docKey of docKeys) {
    const docRef = dbPaths.getDocRef(docKey);
    if (!isFork || !sharedDocKeys.includes(docKey)) {
      promises.push(docRef.delete());
    }
  }

  // promises.push(dbPaths.metadataBlocksDocRef.delete());
  // promises.push(dbPaths.verbatimDocRef.delete());
  // promises.push(dbPaths.wordGroupsDocRef.delete());
  // promises.push(dbPaths.translationsDocRef.delete());

  // promises.push(dbPaths.sentenceVersionsDocRef.delete());
  // promises.push(dbPaths.wordGroupVersionsDocRef.delete());
  // promises.push(dbPaths.structuralVersionsDocRef.delete());
  // promises.push(dbPaths.translationVersionsDocRef.delete());

  // promises.push(dbPaths.chaatAudioAnalysisDocRef.delete());
  // promises.push(dbPaths.chaatAudioRegionsDocRef.delete());
  // promises.push(dbPaths.chaatCuesDocRef.delete());
  // promises.push(dbPaths.chaatTimestampsDocRef.delete());
  // promises.push(
  //   dbPaths.unitMetadataDocRef.update({
  //     chaatInitTime: null,
  //     scriptInitTime: null,
  //   })
  // );
  // promises.push(dbPaths.chaatSpeechTranscriptsDocRef.delete());
  // promises.push(dbPaths.chaatSignoffsDocRef.delete());

  // promises.push(dbPaths.auditLogDocRef.delete());

  return Promise.all(promises);
}

export async function resetChaatCues(episodeKey: string) {
  const dbPaths = new DbPaths(db, episodeKey);
  const chaatCuesDocRef = dbPaths.chaatCuesDocRef;
  const result = await chaatCuesDocRef.set(
    { timestamp: epochSecondsFloat(), cues: {} },
    { merge: true }
  );
  return result;
}

// not relevant with 2.x
// export async function setEditorFlags(episodeKey: string, flags: any) {
//   const dbPaths = new DbPaths(db, episodeKey);
//   const metadataDocRef = dbPaths.metadataDocRef;
//   const editEnabled = !!flags.editEnabled;
//   const result = await metadataDocRef.update({ editEnabled });
//   return result;
// }

export async function testLoadEpisodeDB(episodeKey: string) {
  const dbPaths = new DbPaths(db, episodeKey);
  // random syntax tests
  // const metadataDocRef = dbPaths.metadataDocRef;
  const verbatimDocRef = dbPaths.verbatimDocRef;
  const structuralDocRef = dbPaths.structuralDocRef;
  const wordGroupsDocRef = dbPaths.wordGroupsDocRef;
  const metadataBlocksDocRef = dbPaths.metadataBlocksDocRef;
  // const threadsDocRef = dbPaths.threadsDocRef;
  const translationsDocRef = dbPaths.translationsDocRef;

  const verbatimDoc = (await verbatimDocRef.get()).data();
  const structuralDoc = (await structuralDocRef.get()).data();
  const wordGroupsDoc = (await wordGroupsDocRef.get()).data();
  // const metadataDoc = (await metadataDocRef.get()).data();

  return {
    verbatimDoc,
    structuralDoc,
    wordGroupsDoc,
    // metadataDoc,
  };
}

export async function updateChaatOutputs(
  episodeKey: string,
  chaatResults: any, // TimestamperResult
  scriptTimestamp: any,
  cuesTimestamp: any,
  regionsTimestamp: any,
  transcriptStartTimes: any, // not used
  transcriptEndTimes: any // not used
) {
  const dbPaths = new DbPaths(db, episodeKey);
  const chaatResultsDocRef = dbPaths.chaatTimestampsDocRef;
  scriptTimestamp = scriptTimestamp || 0.0;
  cuesTimestamp = cuesTimestamp || 0.0;
  regionsTimestamp = regionsTimestamp || 0.0;
  // TODO better naming consistency for firestore data structure and chaat results object?
  // chaatResults = instanceToObject(chaatResults); // TODO would not be needed if could turn off typed arrays
  const chaatTimestamps: ChaatTimestampsDoc = {
    wordTimeIntervals: {
      startTimes: chaatResults.wordStartTimes,
      endTimes: chaatResults.wordEndTimes,
    },
    warningTimeIntervals: {
      startTimes: chaatResults.warnStartTimes,
      endTimes: chaatResults.warnEndTimes,
    },
    warningData: chaatResults.warnData,
    interpolationTimeIntervals: {
      startTimes: chaatResults.interpolationStartTimes,
      endTimes: chaatResults.interpolationEndTimes,
    },
    interpolationData: chaatResults.interpolationData,
    scriptTimestamp,
    cuesTimestamp,
    regionsTimestamp,
    timestamp: epochSecondsFloat(),
  };
  let result = await chaatResultsDocRef.set(chaatTimestamps);
  // TODO think about this
  if (transcriptStartTimes) {
    const transcriptDocRef = dbPaths.chaatSpeechTranscriptsDocRef;
    result = await transcriptDocRef.update({
      transcriptWordTimeIntervals: {
        startTimes: transcriptStartTimes,
        endTimes: transcriptEndTimes,
      },
    });
  }
}

export async function updateTranslations(
  episodeKey: string,
  translations: ElementIdToTranslation,
  language: string,
  merge: boolean
) {
  if (isEmpty(translations)) {
    return;
  }
  const dbPaths = new DbPaths(db, episodeKey);
  const translationsDocRef = dbPaths.translationsDocRef;

  await translationsDocRef.set(
    { items: { [language]: { translations } } },
    { merge }
  );
}

// const instanceToObject = instance => {
//   const instance = instance || {}
//   const keys = Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
//   if (!keys) {
//     return instance;
//   }
//   return keys.reduce((asObj, key) => {
//     asObj[key] = instance[key]
//     return asObj;
//   }, {})
// }

// TODO jrw move this
export async function scanForUnitsCreatedBetween(
  beginTimestamp: number,
  endTimestamp: number
) {
  const unitsRef = db.collection('Unit__metadata');
  const units = await unitsRef.where('scriptInitTime', '<', endTimestamp).get();
  const result: { [index in string]: string } = {};
  units.forEach(doc => {
    const data = doc.data();
    const slug = data.slug as string;
    if (data.scriptInitTime > beginTimestamp) {
      result[doc.id] = slug + ' ' + data.scriptInitTime;
    }
  });
  return result;
}

function sniffUpperCaseWordIds(wordRecords: WordRecord[]) {
  const tail = wordRecords.slice(-400);
  for (const record of tail) {
    if (record.id.toLowerCase() !== record.id) {
      return true;
    }
  }
  return false;
}

export async function sniffUnitHasUpperCaseWordIds(episodeKey: string) {
  const verbatimDoc = await loadVerbatimDoc(episodeKey);
  if (verbatimDoc) {
    return sniffUpperCaseWordIds(verbatimDoc.wordRecords);
  } else {
    console.log(
      `sniffUnitHasUpperCaseWordIds(${episodeKey}) - unexpectedly missing verbatim doc`
    );
    return false;
  }
}

function sniffDuplicateWordIds(wordRecords: WordRecord[]) {
  const idSet: Set<string> = new Set();
  for (const record of wordRecords) {
    if (idSet.has(record.id)) {
      return true;
    }
    idSet.add(record.id);
  }
  return false;
}

export async function sniffUnitHasDuplicateWordIds(episodeKey: string) {
  const verbatimDoc = await loadVerbatimDoc(episodeKey);
  if (verbatimDoc) {
    return sniffDuplicateWordIds(verbatimDoc.wordRecords);
  } else {
    console.log(
      `sniffUnitHasDuplicateWordIds(${episodeKey}) - unexpectedly missing verbatim doc`
    );
    return false;
  }
}

export async function zorchWordRecordsIdsBetween(
  episodeKey: string,
  id1: string,
  id2: string
) {
  const verbatimDoc = await loadVerbatimDoc(episodeKey);
  const trimmedWordRecords = verbatimDoc.wordRecords.filter(
    r => r.id < id1 || r.id > id2
  );

  const zorchSentenceIds = Object.values(verbatimDoc.sentences)
    .filter(s => s.anchor.wordId >= id1 && s.anchor.wordId <= id2)
    .map(s => s.id);

  const deleteSentences = {} as any;
  for (const id of zorchSentenceIds) {
    deleteSentences[id] = firebase.firestore.FieldValue.delete();
  }

  const dbPaths = new DbPaths(db, episodeKey);
  const verbatimDocRef = dbPaths.verbatimDocRef;
  await verbatimDocRef.set(
    {
      wordRecords: trimmedWordRecords,
      sentences: deleteSentences,
      timestamp: epochSecondsFloat(),
    },
    { merge: true }
  );
}

export async function unitBase36ToLowercase(episodeKey: string) {
  const mapAnchor = <T extends { anchor: any }>(el: T) => {
    if (!el.anchor) {
      throw new Error('no anchor in mapAnchors');
    }
    const anchor: SpanAnchor = { ...el.anchor };
    if (!anchor.wordId) {
      throw new Error('no wordId in anchor');
    }
    anchor.wordId = anchor.wordId.toLowerCase() as WordId;
    if (anchor.endWordId) {
      anchor.endWordId = anchor.endWordId.toLowerCase() as WordId;
    }
    return { anchor } as T;
  };

  const mapId = <T extends { id: string }>(el: T) => {
    let id = el.id;
    if (!id) {
      throw new Error('no id in mapId');
    }
    if (id.includes(':')) {
      throw new Error('wrong kind of id in mapId');
    }
    id = id.toLowerCase();
    return { ...el, id } as T;
  };

  const mapEntries = <T extends { [index in string]: any }>(
    items: T,
    f: (el: any) => any
  ) => {
    const result = {} as any;
    for (const [key, value] of Object.entries(items)) {
      result[key] = f(value);
    }
    return result as T;
  };

  const mapEntryAnchors = <T extends { [index in string]: any }>(items: T) => {
    return mapEntries(items, mapAnchor);
  };

  const mapIds = <T extends { id: string }>(els: T[]) => {
    return els.map(mapId);
  };

  const mapItemAnchors = <T extends { items: { [index in string]: any } }>(
    doc: T
  ) => {
    return { items: mapEntryAnchors(doc.items) } as T;
  };

  const mapSentenceVersion = (version: SentenceVersionData) => {
    const update = mapAnchor(version);
    update.words = mapIds(version.words);
    return update;
  };

  const dbPaths = new DbPaths(db, episodeKey);

  const verbatimDocRef = dbPaths.verbatimDocRef;
  const verbatimDoc = (await verbatimDocRef.get()).data();

  if (!sniffUpperCaseWordIds(verbatimDoc.wordRecords)) {
    return 'No uppercase base36 finished without processing';
  }

  const structuralDocRef = dbPaths.structuralDocRef;
  const structuralDoc = (await structuralDocRef.get()).data();
  const wordGroupDocRef = dbPaths.wordGroupsDocRef;
  const wordGroupDoc = (await wordGroupDocRef.get()).data();

  const structuralVersionsDocRef = dbPaths.structuralVersionsDocRef;
  const structuralVersionsDoc = (await structuralVersionsDocRef.get()).data();
  const wordGroupVersionsDocRef = dbPaths.wordGroupVersionsDocRef;
  const wordGroupVersionsDoc = (await wordGroupVersionsDocRef.get()).data();
  const sentenceVersionsDocRef = dbPaths.sentenceVersionsDocRef;
  const sentenceVersionsDoc = (await sentenceVersionsDocRef.get()).data();

  const structuralUpdates = mapItemAnchors(structuralDoc);
  const wordGroupUpdates = mapItemAnchors(wordGroupDoc);
  const verbatimUpdates = {
    sentences: mapEntryAnchors(verbatimDoc.sentences),
    wordRecords: mapIds(verbatimDoc.wordRecords),
  };

  const structuralVersionsUpdates = mapItemAnchors(structuralVersionsDoc);
  const wordGroupVersionsUpdates = mapItemAnchors(wordGroupVersionsDoc);
  const sentenceVersionUpdates = {
    items: mapEntries(sentenceVersionsDoc.items, mapSentenceVersion),
  };

  await structuralVersionsDocRef.set(structuralVersionsUpdates, {
    merge: true,
  });
  await wordGroupVersionsDocRef.set(wordGroupVersionsUpdates, { merge: true });
  await sentenceVersionsDocRef.set(sentenceVersionUpdates, { merge: true });

  await structuralDocRef.set(structuralUpdates, { merge: true });
  await wordGroupDocRef.set(wordGroupUpdates, { merge: true });
  await verbatimDocRef.set(verbatimUpdates, { merge: true });

  return 'DONE';
}

export async function addAuditLogMessage(
  episodeKey: string,
  message: string,
  author: string
) {
  const dbPaths = new DbPaths(db, episodeKey);
  const auditLogDocRef = dbPaths.auditLogDocRef;
  const record = {
    timestamp: epochSecondsFloat(),
    message,
    author,
  };

  await auditLogDocRef.set(
    { items: { [`LOG:${randomString(12)}`]: record } },
    { merge: true }
  );
}

export async function consoleAddAuditLogMessage(
  episodeKey: string,
  message: string
) {
  await addAuditLogMessage(
    episodeKey,
    message,
    Auth.currentAlias() // getInstance().appUser?.alias || 'SERVER'
  );
}

export function cleanupEpisodeElements(
  elements: ElementList<EditorElement>,
  episodeKey: string,
  author: string
) {
  // const originalWords = elements.words.__wordRecordsData;
  const originalWords = getWordRecordsData(elements.words);
  const sentences = elements.filterByKind(EKind.SENTENCE);
  const wordRecords = copyWordRecords(originalWords.allWordRecords);
  const words = createWordRecordsData(wordRecords);
  const activeWords = words.activeWordRecords;
  const modifiedSentenceSet = new Set<ElementId>();
  const timestamp = epochSecondsFloat();

  for (const record of activeWords) {
    const str = record.text;
    const newStr = normalizeSpecialChars(str);
    if (str !== newStr) {
      record.text = newStr;
      const sentence = sentences.getElementContainingWordId(record.id);
      modifiedSentenceSet.add(sentence.id);
    }
  }

  const modifiedSentences = [...modifiedSentenceSet.values()];

  const structural = elements.filterByKinds(StructuralKindsList);

  const structuralEdits = { items: {} } as EpisodeStructuralDoc;
  for (const element of structural.values) {
    const str = element.content?.text;
    if (typeof str !== 'string') {
      continue;
    }
    const newStr = normalizeSpecialChars(str);
    if (str !== newStr) {
      const edit = {
        content: { text: newStr },
        timestamp,
        author,
      };
      structuralEdits.items[element.id] = edit as StorageStructural;
    }
  }

  const hasStructuralEdits = !isEmpty(structuralEdits.items);

  const translations = elements.filterByKind(
    EKind.TRANSLATION
  ) as ElementList<Translation>;

  const translationEdits = { items: {} } as EpisodeTranslationDoc;
  let hasTranslationEdits = false;

  for (const element of translations.values) {
    const str = element.content;
    if (typeof str !== 'string') {
      continue;
    }

    const newStr = normalizeSpecialChars(str);
    if (str !== newStr) {
      hasTranslationEdits = true;
      const localItems =
        translationEdits.items[element.locale]?.translations ?? {};
      localItems[element.elementId] = {
        content: newStr,
        timestamp,
        author,
      } as Translation;
      translationEdits.items[element.locale] = { translations: localItems };
    }
  }

  const dbPaths = new DbPaths(db, episodeKey);

  if (modifiedSentences.length > 0) {
    const verbatimDocRef = dbPaths.verbatimDocRef;
    const sentencesUpdate = {} as { [index in ElementId]: StorageSentence };
    for (const sentence of modifiedSentences) {
      sentencesUpdate[sentence] = {
        author,
        timestamp,
      } as StorageSentence;
    }
    const verbatim = {
      wordRecords,
      sentences: sentencesUpdate,
      timestamp,
    };
    verbatimDocRef.set(verbatim, { merge: true });
  }

  if (hasStructuralEdits) {
    const structuralDocRef = dbPaths.structuralDocRef;
    structuralDocRef.set(structuralEdits, { merge: true });
  }

  if (hasTranslationEdits) {
    const translationsDocRef = dbPaths.translationsDocRef;
    translationsDocRef.set(translationEdits, { merge: true });
  }
}

const instanceToObject = (instance: any) => {
  return JSON.parse(JSON.stringify(instance));
};

export abstract class MutationActions {
  // TODO consider converting some update() to set()
  dbPaths: DbPaths;
  lastLocalChaatInputMutationTimestamp: number;
  lastUsedTimestamp: number = 0;

  constructor() {
    this.dbPaths = null; // create using episodeKey in constructor?
    this.lastLocalChaatInputMutationTimestamp = 0;
    // initialized by app specific subclasses
  }

  recordLocalChaatMutation() {
    this.lastLocalChaatInputMutationTimestamp = Date.now();
    this.elementWillUpdate(null);
  }

  get chaatInputRecentLocalModification() {
    return this.lastLocalChaatInputMutationTimestamp > Date.now() - 5000;
  }

  setEpisodeKey(episodeKey: string) {
    this.dbPaths = new DbPaths(db, episodeKey);
  }

  checkPermissions(action?: any) {
    // TODO
    if (!this.auth.appUser) {
      console.log('ALERT: action requires login');
      this.alerts.add({ ...Alert, text: 'action requires login', level: 3 });
      throw Error('checkPermission: action requires login');
    }
  }
  abstract get auth(): Auth;
  abstract get alerts(): AlertMessages;
  abstract elementWillUpdate(id: ElementId): void;

  addTimestamp(element: Timestamped) {
    const newTimestamp = epochSecondsFloat();
    // TODO hacking timestamps as transaction ids, better solution later
    if (newTimestamp > this.lastUsedTimestamp + 1.0) {
      element.timestamp = newTimestamp;
      this.lastUsedTimestamp = element.timestamp;
    } else {
      element.timestamp = this.lastUsedTimestamp;
    }
  }

  addAttribution(element: AttributedElement) {
    element.author = this.auth.appUser?.id;
    this.addTimestamp(element);
    this.elementWillUpdate(element.id);
    return element;
  }

  addNewIdIfMissing(element: Element, kind?: EKind) {
    if (!element.id) {
      element.id = (element.kind + ':' + randomString(12)) as any;
    }
    return element;
  }

  wrapMapToId(element: Element, map?: ElementIdToAny) {
    map = map || {};
    map[element.id] = element;
    return map;
  }

  // used?
  getElementURI(element: { elementId: string }) {
    // TODO translations case, think again
    return element.elementId;
  }

  /******* VERBATIM *********/

  async updateVerbatim(
    wordRecords: WordRecord[],
    sentenceDTOs: SentenceWriteDto[] = null
  ) {
    this.checkPermissions(/* TODO update verbatim action*/);
    const update: EpisodeVerbatimDoc = {
      wordRecords,
      // sentences...
    } as EpisodeVerbatimDoc; // should really use a 'dto' type here
    this.addTimestamp(update);
    if (sentenceDTOs) {
      for (const sentenceDTO of sentenceDTOs) {
        const elementData = instanceToObject(sentenceDTO);
        elementData.kind = EKind.SENTENCE;
        this.addAttribution(elementData);
        this.addNewIdIfMissing(elementData);
        // the firestore update() api requires each top-level key to be a full path to the changed value
        (update as any)['sentences.' + elementData.id] = elementData;
      }
    }
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    this.recordLocalChaatMutation();
    const result = await verbatimDocRef.update(update);
  }

  async splitSentence(
    sentenceId: ElementId,
    wordId: WordId,
    above: boolean,
    sentences: ElementList<Sentence>
  ) {
    this.checkPermissions(Perms.STANDARD /* TODO update verbatim action*/);
    const sentence = sentences.getElement(sentenceId);
    const update = {} as EpisodeVerbatimDoc;
    let newSentenceAnchor: PointAnchor;
    const sentencePath = 'sentences.' + sentence.id;
    if (above) {
      // change name to above everywhere is bool?
      (update as any)[sentencePath + '.anchor.wordId'] = wordId;
      newSentenceAnchor = {
        wordId: sentence.anchor.wordId,
      };
    } else {
      newSentenceAnchor = {
        wordId: wordId,
      };
    }
    let newSentence: Sentence = {
      kind: BasicElementKind.SENTENCE, //'SENTENCE',
      anchor: newSentenceAnchor,
    } as Sentence;
    this.addAttribution(newSentence);
    this.addNewIdIfMissing(newSentence);
    const newSentencePath = 'sentences.' + newSentence.id;
    (update as any)[newSentencePath] = newSentence;

    (update as any)[sentencePath + '.author'] = newSentence.author;
    (update as any)[sentencePath + '.timestamp'] = newSentence.timestamp;
    // TODO error when either sentence is zero length wordId === endWordIdExclusive
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    this.recordLocalChaatMutation();
    const result = await verbatimDocRef.update(update);
  }

  async removeSentence(sentenceId: ElementId) {
    this.checkPermissions(Perms.STANDARD /* TODO update verbatim action*/);
    const update = {
      sentences: {
        [sentenceId]: this.addAttribution({
          deleted: true,
          id: sentenceId,
        } as unknown as AttributedElement),
      },
    } as EpisodeVerbatimDoc;

    this.recordLocalChaatMutation();
    const verbatimDocRef = this.dbPaths.verbatimDocRef;
    const result = await verbatimDocRef.set(update, { merge: true });
  }

  /******* STRUCTURAL *********/

  async addUpdateStructural(elementDTO: StructuralWriteDto) {
    if (!elementDTO.id) {
      // check can create
      this.checkPermissions(Perms.STANDARD /* TODO create structural action*/);
    }
    if (elementDTO.anchor?.wordId) {
      this.checkPermissions(Perms.STANDARD /* TODO create structural action*/);
    }
    this.checkPermissions(/* TODO update structural action*/);
    /* TODO check if needed new id set type*/
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const result = await structuralDocRef.update({
      ['items.' + elementData.id]: elementData,
    });
  }

  async updateTextContentStructural(id: ElementId, content: string) {
    this.checkPermissions(/* TODO update structural action*/);
    const elementData = { id, content: { text: content } } as EditorStructural;
    this.addAttribution(elementData);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    console.log(`updating ${id} with ${content}`);
    const result = await structuralDocRef.set(
      { items: this.wrapMapToId(elementData) },
      { merge: true }
    );
  }

  async updateContentStructural(id: ElementId, content: ContentOfStructural) {
    this.checkPermissions(/* TODO update structural action*/);
    const elementData = { id, content: {} as any } as any;
    for (const [key, value] of Object.entries(content)) {
      if (key !== 'text') {
        elementData.content[key] = value;
      }
    }
    this.addAttribution(elementData as any);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    console.log(`updating ${id} with ${content}`);
    const result = await structuralDocRef.set(
      { items: this.wrapMapToId(elementData) },
      { merge: true }
    );
  }

  async updateExcerptEnd(id: ElementId, anchor: PointAnchor) {
    this.checkPermissions(/* TODO update structural action*/);
    const elementData = { endAnchor: anchor } as any;
    this.addAttribution(elementData);

    const structuralDocRef = this.dbPaths.structuralDocRef;
    console.log(`updating ${id} with endAnchor: ${anchor}`);
    const result = await structuralDocRef.set(
      { items: { [id]: elementData } },
      { merge: true }
    );
  }

  async removeStructural(elementId: ElementId) {
    this.checkPermissions(Perms.STANDARD /* TODO remove structural action*/);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const update = {
      items: {
        [elementId]: this.addAttribution({
          deleted: true,
          id: elementId,
        } as unknown as AttributedElement),
      },
    } as unknown as EpisodeStructuralDoc;
    const result = await structuralDocRef.set(update, { merge: true });
    // const result = await structuralDocRef.update({['structural.' + elementId]: firebase.firestore.FieldValue.delete()});
  }

  async revertStructural(version: StorageStructural) {
    this.checkPermissions(/* TODO update structural action*/);
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const elementId = version.id;
    const newVersion = { ...version };
    this.addAttribution(newVersion);
    const update = { items: { [elementId]: newVersion } };
    const result = await structuralDocRef.set(update, { merge: true });
  }

  async replaceSpeakerLabels(oldLabel: string, newLabel: string) {
    const structural = await loadStructuralDoc(this.dbPaths.key);
    const speakerLabels = Object.values(structural.items).filter(
      el => el.kind === EKind.PARAGRAPH
    );
    const updates = {} as any;
    for (const label of speakerLabels) {
      if (label.content.text === oldLabel) {
        const update = { content: { text: newLabel } };
        this.addAttribution(update as any as AttributedElement);
        updates[label.id] = update;
      }
    }
    const structuralDocRef = this.dbPaths.structuralDocRef;
    const result = await structuralDocRef.set(
      { items: updates },
      { merge: true }
    );
  }

  /******* METADATA BLOCKS *********/

  async addUpdateMetadataBlock(elementDTO: MetadataBlockWriteDto) {
    this.checkPermissions(Perms.STANDARD /* TODO update metadata action*/);
    /* TODO check if needed new id set type*/
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    elementData.kind = EKind.METADATA_BLOCK;
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    const result = await metadataBlocksDocRef.update({
      ['items.' + elementData.id]: elementData,
    });
  }

  async updateContentMetadataBlock(id: ElementId, content: string) {
    this.checkPermissions(Perms.STANDARD /* TODO update metadata action*/);
    const elementData = { id, content } as StorageMetadataBlock;
    this.addAttribution(elementData);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    console.log(`updating ${id} with ${content}`);
    const result = await metadataBlocksDocRef.set(
      { items: this.wrapMapToId(elementData) },
      { merge: true }
    );
  }

  async removeMetadataBlock(elementId: ElementId) {
    this.checkPermissions(Perms.STANDARD /* TODO update metadata action*/);
    const metadataBlocksDocRef = this.dbPaths.metadataBlocksDocRef;
    const update = {
      items: { [elementId]: firebase.firestore.FieldValue.delete() },
    } as unknown as EpisodeMetadataBlockDoc;
    const result = await metadataBlocksDocRef.set(update, { merge: true });
    // const result = await structuralDocRef.update({['structural.' + elementId]: firebase.firestore.FieldValue.delete()});
  }

  /******* WORD GROUPS *********/

  async addUpdateWordGroup(elementDTO: WordGroupWriteDto) {
    // TODO factor code with addUpdateStructural???
    if (!elementDTO.id) {
      this.checkPermissions(Perms.STANDARD /* TODO create word groups action*/);
    }
    this.checkPermissions(/* TODO update word groups action*/);
    // const elementData = {...elementDTO}
    const elementData = instanceToObject(elementDTO);
    elementData.kind = EKind.WORD_GROUP;
    this.addNewIdIfMissing(elementData);
    this.addAttribution(elementData);
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    console.log(`updating ${elementData.id} with:`);
    console.log(elementData);
    const result = await wordGroupsDocRef.update({
      ['items.' + elementData.id]: elementData,
    });
  }

  async removeWordGroup(elementId: ElementId) {
    // this.checkPermissions(/* TODO update word groups action*/);
    this.checkPermissions(Perms.STANDARD); // TODO: enum
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    const update = {
      items: {
        [elementId]: this.addAttribution({
          deleted: true,
          id: elementId,
        } as unknown as AttributedElement),
      },
    } as unknown as EpisodeWordGroupDoc;
    const result = await wordGroupsDocRef.set(update, { merge: true });
    // const result = await wordGroupsDocRef.update( { ['wordGroups.' + elementId]: firebase.firestore.FieldValue.delete() });
  }

  async revertWordGroup(version: StorageWordGroup) {
    this.checkPermissions(/* TODO update word groups action*/);
    const wordGroupsDocRef = this.dbPaths.wordGroupsDocRef;
    const id = version.id;
    const newVersion = { ...version };
    this.addAttribution(newVersion);
    const update = { items: { [id]: newVersion } };
    const result = await wordGroupsDocRef.set(update, { merge: true });
  }

  /******* TRANSLATIONS *********/

  async addUpdateTranslation(id: ElementId, locale: string, content: Content) {
    this.checkPermissions(/* TODO update translations action*/);
    const data = {
      id: getTranslationId(id, locale),
      kind: BasicElementKind.TRANSLATION,
      elementId: id,
      locale,
      content,
    } as Translation;
    this.addAttribution(data);
    const update = { [locale]: { translations: { [id]: data } } };
    const translationsDocRef = this.dbPaths.translationsDocRef;
    const result = await translationsDocRef.set(
      { items: update },
      { merge: true }
    );
  }

  async revertTranslation(version: Translation) {
    this.checkPermissions(/* TODO update translations action*/);
    const translationsDocRef = this.dbPaths.translationsDocRef;
    const elementId = version.elementId;
    const locale = version.locale;
    const newVersion = { ...version };
    this.addAttribution(newVersion);
    const update = {
      items: { [locale]: { translations: { [elementId]: newVersion } } },
    };
    const result = await translationsDocRef.set(update, { merge: true });
  }

  /******* THREADS *********/

  // async addUpdateMessage(messageData0) {
  //   this.checkPermissions(/* TODO update threads action*/);
  //   const messageData = {...messageData0};
  //   this.addNewIdIfMissing(messageData);
  //   this.addAttribution(messageData);
  //   const elementURI = this.getElementURI(messageData);
  //   const update = {[elementURI]:{id:elementURI, messages:this.wrapMapToId(messageData)}}
  //   const threadsDocRef= this.dbPaths.threadsDocRef;
  //   console.log(`updating ${elementURI} to:`);
  //   console.log(update);
  //   const result = await threadsDocRef.set({threads:update}, {merge:true});
  // }

  /******* LINTS *********/

  async setElementWarningSuppression(warningKey: string, suppress: boolean) {
    const warningSuppressionsDocRef = this.dbPaths.warningSuppressionsDocRef;
    const update = suppress
      ? firebase.firestore.FieldValue.arrayUnion(warningKey)
      : firebase.firestore.FieldValue.arrayRemove(warningKey);
    const result = await warningSuppressionsDocRef.update({ items: update });
  }

  /******* CHAAT SIGNOFFS *********/

  async setChaatSignoff(key: string, signoff: boolean) {
    const signoffsDocRef = this.dbPaths.chaatSignoffsDocRef;
    const update = signoff
      ? firebase.firestore.FieldValue.arrayUnion(key)
      : firebase.firestore.FieldValue.arrayRemove(key);
    const result = await signoffsDocRef.update({ items: update });
  }

  /******* CUES *********/

  async addUpdateCue(data: Cue) {
    this.checkPermissions(/* TODO update cues action*/);
    const cuesDocRef = this.dbPaths.chaatCuesDocRef;
    data = instanceToObject(data); // TODO many of these conversions not needed anymore after move to TS?
    const update: any = { cues: { [data.wordId]: data } };
    if (data.input) {
      this.recordLocalChaatMutation();
      update.timestamp = epochSecondsFloat();
    }
    const result = await cuesDocRef.set(update, { merge: true });
  }

  async removeCue(wordId: WordId, input: boolean) {
    this.checkPermissions(/* TODO update cues action*/);
    if (input) {
      this.recordLocalChaatMutation();
    }
    const cuesDocRef = this.dbPaths.chaatCuesDocRef;
    const result = await cuesDocRef.update({
      ['cues.' + wordId]: firebase.firestore.FieldValue.delete(),
      timestamp: epochSecondsFloat(),
    });
    return result;
  }

  /******* AUDIO REGIONS *********/

  async addUpdateChaatAudioRegion(
    audioRegionData0: AudioRegionWriteDto,
    kind: AudioRegionKind
  ) {
    this.checkPermissions(/* TODO update audio regions action*/);
    const regionData = instanceToObject(audioRegionData0);
    regionData.kind = kind;
    if (!regionData.id) {
      regionData.id = `AUDIO_REGION:${randomString(12)}`;
    }
    const regionsDocRef = this.dbPaths.chaatAudioRegionsDocRef;
    this.recordLocalChaatMutation();
    const result = await regionsDocRef.update({
      ['items.' + regionData.id]: regionData,
      timestamp: epochSecondsFloat(),
    });
  }

  async removeChaatAudioRegion(audioRegionId: string) {
    this.checkPermissions(/* TODO update audio regions action*/);
    const regionsDocRef = this.dbPaths.chaatAudioRegionsDocRef;
    this.recordLocalChaatMutation();
    const result = await regionsDocRef.update({
      ['items.' + audioRegionId]: firebase.firestore.FieldValue.delete(),
      timestamp: epochSecondsFloat(),
    });
  }

  /******* TODO AUDIO MARKERS *********/

  async addUpdateChaatAudioMarker(audioMarkerData0: AudioMarker) {
    this.checkPermissions(/* TODO update audio marker action*/);
    const markerData = instanceToObject(audioMarkerData0);
    if (!markerData.id) {
      markerData.id = `AUDIO_MARKER:${randomString(12)}`;
    }
    const markersDocRef = this.dbPaths.chaatAudioMarkersDocRef;
    this.recordLocalChaatMutation();
    const result = await markersDocRef.update({
      ['items.' + markerData.id]: markerData,
    });
  }

  async removeChaatAudioMarker(audioMarkerId: ElementId) {
    this.checkPermissions(/* TODO update audio markers action*/);
    const markersDocRef = this.dbPaths.chaatAudioMarkersDocRef;
    this.recordLocalChaatMutation();
    const result = await markersDocRef.update({
      ['items.' + audioMarkerId]: firebase.firestore.FieldValue.delete(),
    });
  }

  /******* TRICKY *********/
  async updateTrickyEdits(edits: Map<number, TrickyEdit>) {
    const trickyEditsDocRef = this.dbPaths.chaatTrickyEditsDocRef;
    const items = {} as any;
    for (const [key, value] of edits.entries()) {
      items[key.toString()] = value;
    }
    const result = await trickyEditsDocRef.set({ items }, { merge: true });
  }

  async updateTrickySignalTransforms(transforms: TrickyBitsSignalTransforms) {
    const trickySignalTransformsDocRef = this.dbPaths.chaatTrickyEditsDocRef;
    const update = { transforms };
    const result = await trickySignalTransformsDocRef.set(update, {
      merge: true,
    });
  }

  async updateTrickyTrackDisableMap(map: { [index in string]: boolean }) {
    const trickySignalTransformsDocRef = this.dbPaths.chaatTrickyEditsDocRef;
    if (isEmpty(map)) {
      return;
    }
    const update = { trackDisable: map };
    const result = await trickySignalTransformsDocRef.set(update, {
      merge: true,
    });
  }

  async updateTrickyTrackScaleFactors(map: { [index in string]: number }) {
    const trickySignalTransformsDocRef = this.dbPaths.chaatTrickyEditsDocRef;
    if (isEmpty(map)) {
      return;
    }
    const update = { trackScaleFactor: map };
    const result = await trickySignalTransformsDocRef.set(update, {
      merge: true,
    });
  }

  async updateTrickyCompressionSetting(map: { [index in string]: number }) {
    const trickySignalTransformsDocRef = this.dbPaths.chaatTrickyEditsDocRef;
    if (isEmpty(map)) {
      return;
    }
    const update = { compressionSettings: map };
    const result = await trickySignalTransformsDocRef.set(update, {
      merge: true,
    });
  }
}
