import { FieldError } from 'react-hook-form';

interface ErrorMessageProps {
  error: FieldError;
  name: string;
}

export function ErrorMessage<T>({ error, name }: ErrorMessageProps) {
  if (!error) {
    return null;
  }

  let errorMessage;

  switch (error.type) {
    case 'required':
      errorMessage = 'is required';
      break;
    case 'pattern':
      errorMessage = 'is invalid';
      break;
    default:
      errorMessage = 'is invalid';
      break;
  }

  return (
    <div className="error">
      {name} {errorMessage}
    </div>
  );
}
