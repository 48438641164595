import { observer } from 'mobx-react';
import { Page } from '../shared/page';
import { ActivityGuideData } from '@masala-lib/catalog/catalog-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActivityGuideForm } from './activity-guide-form';
import { ActivityGuide } from '@masala-lib/catalog/models/activity-guide';
import { getQueryParamsFromLocation } from '../../lib/query-stuff';
import { OnePaneLayout } from '../shared/layouts';
import { ActivityGuideManager } from '@masala-lib/catalog/db/activity-guide-manager';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';

export const ActivityGuideCreateScreen = observer(() => {
  const activityGuideManager = ActivityGuideManager.getInstance();
  const navigate = useNavigate();
  const activityGuide = new ActivityGuide();

  const location = useLocation();
  const { unit: unitId }: { unit: string } =
    getQueryParamsFromLocation(location);

  const unit = UnitManager.getInstance().fetchById(unitId);

  const goBack = () => {
    if (unit) {
      navigate(unit.navPath);
    } else {
      navigate(`/activity-guides`);
    }
  };

  const onSubmit = (dto: ActivityGuideData) => {
    console.group('Creating activity guide');
    console.log('From data', dto);
    activityGuide.fromFormData(dto);
    activityGuideManager.create(activityGuide).then(goBack);
    console.groupEnd();
  };

  return (
    <Page title={'Creating new activity guide'}>
      <OnePaneLayout>
        <ActivityGuideForm
          dto={activityGuide.toFormData()}
          onSubmit={onSubmit}
          onCancel={goBack}
          unit={unit}
        />
      </OnePaneLayout>
    </Page>
  );
});
