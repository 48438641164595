import {
  autorun,
  comparer,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import {
  Element,
  Word,
  ElementList,
  SimpleElementList,
  WordGroup,
  MetadataBlock,
  Structural,
  Sentence,
  EditorLineElement,
  ElementId,
  ElementTracker,
  ETOf,
} from '@masala-lib/editor-aliases';
import {
  alertMessages,
  auth,
  metadataBlockActions,
  mutationActions,
  structuralActions,
  translationActions,
  linter,
  verbatimActions,
  wordGroupActions,
  conversationManager,
  versions,
} from './app-root';
import { Alert, Info } from '@masala-lib/misc/alert-messages';
import {
  WordIdRange,
  WordId,
  idIsOfKind,
  SentenceId,
  EmptyElementList,
  ExtractIndexedET,
  ExtractIntervalIndexedET,
} from '@tikka/basic-types';
import {
  FilterModel,
  FilterTerm,
  regexTermParser,
} from '@masala-lib/editorial/filters/filters-model';
import {
  BaseFilterKinds,
  byFilter,
  sentenceFilter,
  sicFilter,
  structuralFilter,
  trickyFilter,
  vocabFilter,
  wordGroupFilter,
} from '@masala-lib/editorial/filters/base-filter-defs';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import {
  EKind,
  isStructuralKind,
  StructuralKind,
  WordGroupSubKind,
} from '@masala-lib/element-kinds';
import { CreatePrecedence } from '@tikka/elements/precedence';
import { deploymentConfig } from '@masala-lib/deployment-config';
import { Unit } from '@masala-lib/catalog/models/unit';
import { isEmpty, isNil, isNull, update } from 'lodash';
import { notNull } from '@utils/conditionals';
import {
  getGlobalExcerptId,
  getKindFromId,
  getTranslationId,
} from '@tikka/elements/element-id-utils';
import { UnitData } from '@masala-lib/catalog/catalog-types';
import { openCreateSentenceDialog } from '../ui/create-sentence-dialog';
import { cleanupEpisodeElements } from '@masala-lib/editorial/db/mutation-actions';
import { StructuredPlayer } from '@tikka/player/structured-player';
import { fromIntervals, Interval, size } from '@tikka/intervals/intervals';
import { CreateTracker } from '@tikka/tracking/tracker';
import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import { createAudioSource } from './script-editor-audio-sources';
import { ExcerptManager } from '@masala-lib/catalog/db/excerpt-manager';
import {
  EditorSentence,
  EditorStructural,
  ElementIdToTranslation,
  Excerpt,
  Translation,
  VersionableElement,
  VersionItem,
  WordGroupContent,
} from '@masala-lib/editorial-types';
import { createLogger } from '@app/logger';
import { numberProjectionSort } from '@masala-lib/utils';
import {
  computeElementsTimeRanges,
  interleave,
} from '@masala-lib/editorial/episode-data/episode-data';
import { msToMinSecString } from '@masala-lib/misc/timestamp-formats';
const log = createLogger('script-editor-model');

export interface LineEditBuffer {
  modified: boolean;
  text: string;
}

export const collapseOrder = CreatePrecedence([
  EKind.CHAPTER_COMPLETE,
  // EKind.CHAPTER_SUMMARY,
  EKind.PARAGRAPH,
  EKind.CHAPTER_NOTE,
  EKind.SENTENCE,
  EKind.EXCERPT,
  // EKind.PASSAGE_QUESTION,
  EKind.PASSAGE,
  EKind.CHAPTER,
  // |])
] as const);

export const NORMAL = 'NORMAL';
export const EDITING = 'EDITING';
export const LOCKED = 'LOCKED';
export const DEACTIVATED = 'DEACTIVATED';
export const CHOICE_MODE = 'CHOICE_MODE';

export class ScriptEditorModel {
  @observable.ref unit: Unit;

  @observable.ref elements: ElementList<Element> = EmptyElementList;

  words: ElementList<Word> = EmptyElementList;

  translations: ElementList<Translation> = EmptyElementList;

  @observable.ref translationsLookup: ElementIdToTranslation = {};

  @observable.ref showTranslations = true;

  l1locale: string = null;
  l2locale: string = null;

  @observable.ref editEnabled = true;

  // false for 'external reviewer' users. they should only be allowed to
  // add comments and update existing element content attributes.
  @observable.ref standardAccessEnabled = false;

  @observable.ref writeAccessEnabled = false;

  @observable.ref collapse = false;

  @observable.ref _focusedLineId: ElementId = null;

  @observable.ref wordRangeSelection: WordIdRange = null;

  @observable.ref editingId: ElementId = null;

  @observable.ref _versionActionsElementId: ElementId = null;

  @observable.ref currentChapterTitle = '';

  @observable.ref episodeKey = '';

  @observable.ref markedLineId: ElementId = null;

  @observable.ref kbDeactivated = false;

  getLineEditBuffer: () => LineEditBuffer = null;

  @observable.ref choiceModalMode = false;

  @observable.ref choiceModalHtml = '';

  @observable.ref
  unitTrackingEnabled = false;

  @observable.ref
  overrideTrackingEnabled: boolean = null;

  lastLocalUpdateTimestamp = 0;

  lastStatusSyncTransition = {} as any;

  player: StructuredPlayer = null;

  @observable.ref playingMode: null | 'sentence' | 'continuous' = null;

  @observable.ref sparseWordTimes: { [index: string]: Interval };

  disposers: (() => void)[] = [];

  constructor() {
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.focusedLineId,
        () => this.adjustCurrentWordIdForFocusedLineElement()
      )
    );
    this.disposers.push(
      reaction(
        () => conversationManager.baselineTimestamp,
        () => this.setBaselineTimestamp(conversationManager.baselineTimestamp),
        { fireImmediately: true }
      )
    );
    this.disposers.push(autorun(() => this.syncStatusInfoRollupsIfNeeded()));
    this.disposers.push(
      autorun(() => conversationManager.setTracking(this.trackingEnabled))
    );
  }

  setElementList(elements: ElementList<Element>) {
    let firstTime = false;
    // TODO fix this
    if (elements.values.length > 2) {
      firstTime = true;
    }
    if (firstTime) {
      this.loadSentencePlayerData();
    }
    this.words = elements.words;
    this.elements = elements;
    this.translations = this.elements.filterByKind(
      EKind.TRANSLATION
    ) as ElementList<Translation>;
  }

  setEpisodeKey(episodeKey: string) {
    this.episodeKey = episodeKey;
  }

  setL1Locale(language: string) {
    this.l1locale = language;
  }

  setL2Locale(language: string) {
    this.l2locale = language;
  }

  setBaselineTimestamp(timestamp: number = null) {
    versions.setBaselineTimestamp(timestamp);
  }

  get baselineTimestamp() {
    return versions.baselineTimestamp;
  }

  setUnitTracking(enabled: boolean) {
    this.unitTrackingEnabled = enabled;
  }

  setOverrideTracking(enabled: boolean) {
    this.overrideTrackingEnabled = enabled;
  }

  @computed
  get trackingEnabled() {
    if (this.overrideTrackingEnabled !== null) {
      return this.overrideTrackingEnabled;
    } else {
      return this.unitTrackingEnabled;
    }
  }

  elementWillUpdate(id: ElementId) {
    if (id) {
      conversationManager.elementRevised(id);
    }
    if (mutationActions.lastUsedTimestamp > this.lastLocalUpdateTimestamp) {
      if (!this.unit?.data) {
        return;
      }
      const updates = {
        contentUpdatedAt: mutationActions.lastUsedTimestamp,
      } as any as UnitData;
      console.log('updating unit updated timestamp:', updates);
      this.lastLocalUpdateTimestamp = mutationActions.lastUsedTimestamp;
      this.unit.updatePartial(updates);
    }
  }

  setTrackChanges(value: boolean) {
    conversationManager.trackingEnabled = value;
  }

  get trackChanges() {
    return conversationManager.trackingEnabled;
  }

  toggleTrackChanges() {
    conversationManager.setTracking(!conversationManager.trackingEnabled);
  }

  // @computed
  get onMasterLanguageFork() {
    return this.l1locale === this.unit.data?.l1Code;
  }

  get wordGroups() {
    return this.elements.filterByKind(EKind.WORD_GROUP);
  }

  get sentences() {
    return this.elements.filterByKind(EKind.SENTENCE);
  }

  get chapters() {
    return this.elements.filterByKind(EKind.CHAPTER);
  }

  get threadsLookup() {
    return conversationManager.obj;
  }

  @computed
  get lineElements(): ElementList<EditorLineElement> {
    // TODO remove conditional?
    if (this.elements) {
      // TODO make more generic?
      return this.elements.filter(el => el.kind !== EKind.WORD_GROUP) as any;
    } else {
      return null;
    }
  }

  @computed
  get visibleElements() {
    // TODO in future lineElements always valid but maybe empty?
    const all = this.lineElements;
    if (all) {
      if (this.collapse && !this.showTranslations) {
        return all.filter(collapseOrder.precedenceAtLeastFilter(EKind.EXCERPT));
      } else if (this.collapse && this.showTranslations) {
        // TODO do map translations
        const result = all.filter(
          collapseOrder.precedenceAtLeastFilter(EKind.EXCERPT, true)
        );
        if (!result) {
          return result;
        }
        // hacking translations into result
        const lookup = ((id: ElementId) => this.translationsLookup[id]) as any;
        result.values = interleave(result.values, lookup, 1) as any;
        return result;
      } else if (!this.showTranslations) {
        return all.filter(
          (e: EditorLineElement) => e.kind !== EKind.TRANSLATION
        );
      } else {
        return all;
      }
    } else {
      return all;
    }
  }

  toggleCollapseExpand() {
    this.collapse = !this.collapse;
  }

  toggleShowTranslations() {
    this.showTranslations = !this.showTranslations;
  }

  setEditingId(id: ElementId) {
    this.editingId = id;
  }

  get isEditing(): boolean {
    return !!this.editingId;
  }

  get isVerbatimEditing() {
    // TODO getter? computed?
    return false;
  }

  @computed
  get mode() {
    if (this.kbDeactivated) {
      return DEACTIVATED;
    } else if (this.choiceModalMode) {
      return CHOICE_MODE;
    } else if (!this.editEnabled) {
      return LOCKED;
    } else if (this.isEditing) {
      return EDITING; // TODO change to some kind of enum or constants
    } else {
      return NORMAL;
    }
  }

  setGetLineEditBuffer(f: () => LineEditBuffer) {
    this.getLineEditBuffer = f;
  }

  saveLineBuffer(id: ElementId, lineBuffer: LineEditBuffer) {
    const kind = getKindFromId(id);
    if (isStructuralKind(kind)) {
      structuralActions.updateTextContent(id, lineBuffer.text);
    } else if (kind === EKind.SENTENCE) {
      // TODO resolve difference in type of content param versus even though they are actually both strings??
      return verbatimActions.updateSentence(id as SentenceId, lineBuffer.text);
    } else if (kind === EKind.TRANSLATION) {
      const translationElement = this.elements.getElement(id) as Translation;
      // TODO typing?
      const elementId = translationElement.elementId;
      const language = translationElement.locale;
      translationActions.addUpdate(elementId, language, lineBuffer.text);
    } else if (kind === EKind.METADATA_BLOCK) {
      metadataBlockActions.updateContent(id, lineBuffer.text);
    }
  }

  saveLineEdit() {
    if (this.getLineEditBuffer) {
      const buffer = this.getLineEditBuffer();
      if (!buffer.modified) {
        this.setEditingId(null);
        return;
      }
      this.saveLineBuffer(this.editingId, buffer);
      this.setEditingId(null);
    }
  }

  abortLineEdit() {
    this.setEditingId(null);
  }

  cancelSelections() {
    this.markedLineId = null;
    this.wordRangeSelection = null;
    // TDDO more?
  }

  setFocusedLineId(id: ElementId) {
    this._focusedLineId = id;
    if (this.isEditing && this.focusedLineId !== this.editingId) {
      this.saveLineEdit();
    }
    if (this.isPlaying) {
      if (!this.seekToPlayCurrentSentence(this.playingMode === 'continuous')) {
        this.playingMode = null;
      }
    } else {
      this.playingMode = null;
    }
  }

  @computed
  get focusedLineId(): ElementId {
    if (this.elements.hasElement(this._focusedLineId)) {
      return this._focusedLineId;
    }
    return null;
  }

  setVersionActionsId(id: ElementId) {
    const activeElement = this.elements.getElement(id);
    if (activeElement) {
      this.setFocusedElementId(id);
    } else {
      runInAction(() => {
        this.markedLineId = null;
        this.wordRangeSelection = null;
        this.setFocusedLineId(null);
        this._versionActionsElementId = id;
      });
    }
  }

  @computed
  get versionActionsElementId() {
    return this.focusedElementId ?? this._versionActionsElementId;
  }

  normalizeWordRange(range: WordIdRange) {
    if (range) {
      const startIndex = this.words.getIndex(range.begin);
      const endIndex = this.words.getIndex(range.end);
      if (startIndex > endIndex) {
        return { begin: range.end, end: range.begin };
      } else {
        return range;
      }
    } else {
      return range;
    }
  }

  setWordRangeSelection(range0: WordIdRange) {
    const range = this.normalizeWordRange(range0);
    if (!comparer.shallow(range, this.wordRangeSelection) || this.isPlaying) {
      runInAction(() => {
        if (range) {
          const shouldFocusSentence = this.sentences.getElementContainingWordId(
            range.begin
          );
          const shouldFocusLineId = shouldFocusSentence
            ? shouldFocusSentence.id
            : null;
          this.setFocusedLineId(shouldFocusLineId);
        }
        this.wordRangeSelection = range;
      });
    }
  }

  setCurrentWordId(id: WordId) {
    if (isNull(id)) {
      // TODO isNull does not really tell null and 0 apart
      this.wordRangeSelection = null;
    }
    this.setWordRangeSelection({ begin: id, end: id });
  }

  wordRangeSelectTo(id: WordId) {
    if (this.wordRangeSelection) {
      this.setWordRangeSelection({
        begin: this.wordRangeSelection.begin,
        end: id,
      });
    } else {
      this.setCurrentWordId(id);
    }
  }

  setFocusedElementId(id: ElementId) {
    if (id) {
      const kind = getKindFromId(id);
      if (kind === EKind.WORD_GROUP) {
        const group = <WordGroup>this.elements.getElement(id);
        if (group?.anchor) {
          // TODO typing?
          this.setCurrentWordId(group.anchor.wordId);
        } else {
          // this might happen sometimes perhaps due to a loading race condition
          alert(`missing anchor data for elementId: ${id}`);
        }
      } else if (kind === EKind.WORD) {
        this.setCurrentWordId(id as WordId);
      } else if (kind === EKind.SENTENCE) {
        this.setCurrentWordId(null);
        this.setFocusedLineId(id);
      } else {
        this.setFocusedLineId(id);
      }
    }
  }

  get focusedLineElement() {
    // TODO remove conditional
    return this.elements ? this.elements.getElement(this.focusedLineId) : null;
  }

  @computed
  get currentWordId(): WordId {
    if (this.wordRangeSelection) {
      // todo: consider fix this typing higher up the chain
      return this.wordRangeSelection.begin as WordId;
    } else {
      // TODO check ambiguity at usages for Null for wordId 0
      return null;
    }
  }

  @computed
  get focusedElementId(): ElementId {
    const focusedWordId = this.currentWordId;
    if (focusedWordId) {
      const focusedWordGroup =
        this.wordGroups.getElementContainingWordId(focusedWordId);
      if (focusedWordGroup) {
        return focusedWordGroup.id;
      } else {
        return this.focusedLineId;
      }
    } else {
      return this.focusedLineId;
    }
  }

  get focusedElement(): Element {
    // TODO take out conditional?
    return this.elements
      ? this.elements.getElement(this.focusedElementId)
      : null;
  }

  adjustCurrentWordIdForFocusedLineElement() {
    const focused = this.focusedLineElement;
    if (focused && this.currentWordId) {
      if (focused.kind !== EKind.SENTENCE) {
        this.wordRangeSelection = null; // TODO change to setCurrentWordId when null ambiguity issue resolved
      } else if (
        !(
          focused ===
          this.sentences.getElementContainingWordId(this.currentWordId)
        )
      ) {
        this.wordRangeSelection = null;
      }
    }
  }

  searchResultCursorPointId(): ElementId {
    // getKindFromId(this.focusedElementId) === EKind.SENTENCE &&
    if (
      this.focusedElementId &&
      idIsOfKind(this.focusedElementId, EKind.SENTENCE) &&
      this.currentWordId
    ) {
      return this.currentWordId;
    } else {
      return this.focusedElementId;
    }
  }

  cursorLineUp() {
    const next = this.visibleElements.prevId(
      this.focusedLineId,
      this.lineElements
    );
    if (next) {
      this.setFocusedLineId(next);
    }
  }

  cursorLineDown() {
    const next = this.visibleElements.nextId(
      this.focusedLineId,
      this.lineElements
    );
    if (next) {
      this.setFocusedLineId(next);
    }
  }

  editFocusedLine() {
    this.setEditingId(this.focusedLineId);
  }

  markFocusedLine() {
    if (this.focusedLineId) {
      const kind = getKindFromId(this.focusedLineId);
      if (isStructuralKind(kind)) {
        this.markedLineId = this.focusedLineId;
      }
    }
  }

  get markedLineElement() {
    return this.markedLineId
      ? this.elements.getElement(this.markedLineId)
      : null;
  }

  moveMarked() {
    if (this.markedLineId && this.focusedLineId) {
      structuralActions.move(this.markedLineId, this.focusedLineId);
      this.markedLineId = null;
    }
  }

  moveExcerptEnd() {
    const markedExcerpt = this.markedLineElement;
    if (markedExcerpt && this.focusedLineElement) {
      if (markedExcerpt.kind !== EKind.EXCERPT) {
        alertMessages.add({
          ...Alert,
          text: 'cannot use special excerpt move key combo with non excerpt element.',
        });
        return;
      }
      if (this.focusedLineElement.kind !== EKind.SENTENCE) {
        alertMessages.add({
          ...Alert,
          text: 'end of excerpt must be sentence.',
        });
        return;
      }
      const endSentence = this.focusedLineElement;
      const sentences = this.sentences;
      const startSentence = sentences.getElementContainingWordId(
        markedExcerpt.anchor.wordId
      );
      if (startSentence.address > endSentence.address) {
        alertMessages.add({
          ...Alert,
          text: 'end of excerpt cannot be before start.',
        });
        return;
      }
      structuralActions.updateExcerptEnd(markedExcerpt.id, {
        wordId: endSentence.anchor.wordId,
      });
      runInAction(() => {
        this.markedLineId = null;
        this.setFocusedLineId(markedExcerpt.id);
      });
    }
  }

  @computed
  get currentExcerpt() {
    const maybeExcerpt = this.markedLineElement || this.focusedElement;
    if (!maybeExcerpt || maybeExcerpt.kind !== EKind.EXCERPT) {
      return null;
    }
    return maybeExcerpt as unknown as Excerpt;
  }

  @computed
  get currentExcerptSentences() {
    const excerpt = this.currentExcerpt;
    if (!excerpt) {
      return [];
    }
    const sentences = this.sentences;
    const startSentenceWordId = excerpt.anchor.wordId;
    const startSentence =
      sentences.getElementContainingWordId(startSentenceWordId);
    const endSentenceWordId = excerpt?.endAnchor?.wordId;
    if (!endSentenceWordId) {
      return [startSentence];
    }
    return sentences.getElementsIntersectWordIdRange({
      begin: startSentenceWordId,
      end: endSentenceWordId,
    });
  }

  createElementHook(id: ElementId) {
    if (!id) {
      return;
    }
    // const kind = getKindFromId(id);
    // if (kind === EKind.EXCERPT) {
    if (idIsOfKind(id, EKind.EXCERPT)) {
      const excerptPathId = getGlobalExcerptId(this.episodeKey, id);
      // const excerptUrl = `${deploymentConfig.consoleUrl}/excerpts/${excerptId}`;
      // setTimeout(() => {
      //   fetch(excerptUrl);
      // }, 5000);
      // will execute async and create new excerpt entity if needed
      ExcerptManager.getInstance().ensuredEntityForPath(excerptPathId);
    }
  }

  createWordGroupWithWordSelection(kind: WordGroupSubKind) {
    wordGroupActions.createFromCurrentWordSelection(kind);
  }

  createStucturalAtFocusedLine(kind: StructuralKind) {
    const newId = structuralActions.create(kind, this.focusedLineId);
    this.createElementHook(newId);
    this.setFocusedLineId(newId);
    this.editFocusedLine();
  }

  async createSpeakerLabelForFocus({ naBio }: { naBio: boolean }) {
    try {
      await this.unit.volume.createSpeaker(this.speakerLabelForFocus, naBio);
      // todo: force refresh of script editor lint warnings
    } catch (error) {
      window.alert(error);
    }
  }

  async removeSpeakerDataForFocus() {
    try {
      await this.unit.volume.removeSpeaker(this.speakerLabelForFocus);
    } catch (error) {
      window.alert(error);
    }
  }

  get speakerLabelForFocus(): string {
    const focusedLineElement = this.focusedLineElement;
    return focusedLineElement?.kind === EKind.PARAGRAPH
      ? focusedLineElement.content.text
      : null;
  }

  editSpeakerDataForFocus() {
    const label = this.speakerLabelForFocus;
    const url = this.unit.volume.resolveSpeakerEditUrl(label);
    window.open(url, '_blank');
  }

  createOrEditTranslationAtFocusedLine() {
    if (this.focusedLineId) {
      // const kind = getKindFromId(this.focusedLineId);
      // if (kind === EKind.TRANSLATION) {
      if (idIsOfKind(this.focusedLineId, EKind.TRANSLATION)) {
        this.editFocusedLine();
      } else {
        const translationId = getTranslationId(
          this.focusedLineId,
          this.l1locale
        );
        const translation = this.translations.getElement(translationId);
        if (translation) {
          this.setFocusedLineId(translationId);
          this.editFocusedLine();
        } else {
          this.setFocusedLineId(
            translationActions.addUpdate(this.focusedLineId, this.l1locale, '')
          );
          this.editFocusedLine();
        }
      }
    }
  }

  removeFocused() {
    // TODO make isStructural?
    if (this.focusedLineId) {
      const kind = getKindFromId(this.focusedLineId);
      if (isStructuralKind(kind)) {
        structuralActions.remove(this.focusedLineId);
      } else if (kind === EKind.SENTENCE) {
        verbatimActions.removeSentence(this.focusedLineId as SentenceId);
      } else if (kind === EKind.METADATA_BLOCK) {
        metadataBlockActions.remove(this.focusedLineId);
      } else {
        return;
      }
      this.cursorLineDown();
    }
  }

  // TODO generalize with step direction?
  nextSearchResult() {
    const cursorPointId = this.searchResultCursorPointId();
    let nextResultId: ElementId = null;
    const currentResults = this.searchResult;
    if (cursorPointId) {
      nextResultId = currentResults.nextId(cursorPointId, this.elements, true);
    }
    if (!nextResultId && this.searchResult.values.length > 0) {
      nextResultId = currentResults.values[0].id;
    }

    this.setFocusedElementId(nextResultId);
  }

  prevSearchResult() {
    // TODO factor with above
    const cursorPointId = this.searchResultCursorPointId();
    let prevResultId: ElementId = null;
    const currentResults = this.searchResult;
    if (cursorPointId) {
      prevResultId = currentResults.prevId(cursorPointId, this.elements, true);
    } else if (this.searchResult.values.length > 0) {
      prevResultId = currentResults.values[0].id;
    }
    this.setFocusedElementId(prevResultId);
  }

  splitSentence(direction: boolean) {
    const splitWordId = this.currentWordId;
    if (splitWordId) {
      const sentence = this.focusedLineElement;
      if (sentence.kind === EKind.SENTENCE) {
        verbatimActions.splitSentence(
          sentence.id,
          splitWordId as WordId,
          direction
        );
      }
    }
  }

  openCreateSentenceDialog() {
    const currentLineId = this.focusedLineId;
    if (!currentLineId) {
      return;
    }
    // if (getKindFromId(currentLineId) === EKind.SENTENCE) {
    if (idIsOfKind(currentLineId, EKind.SENTENCE)) {
      openCreateSentenceDialog(currentLineId);
    } else {
      openCreateSentenceDialog(
        this.sentences.stepId(currentLineId, 1, this.elements)
      );
    }
  }

  cleanupEpisodeElements() {
    cleanupEpisodeElements(this.elements, this.episodeKey, auth.appUser.id);
  }

  createMetadataUrlBlock() {
    // TODO move to metadataBlockActions?
    const blocks = this.elements.filterByKind(EKind.METADATA_BLOCK).values;
    const metadataUrlBlock = blocks.find(
      (b: MetadataBlock) => b.subKind === EKind.METADATA_URL
    );
    if (metadataUrlBlock) {
      alertMessages.add({
        ...Alert,
        text: 'cannot add more than one METADATA URL',
      });
    } else {
      metadataBlockActions.create(EKind.METADATA_URL);
    }
  }

  async copyMetadataToClipboard() {
    // TODO move to metadataBlockActions?
    let lines = [];

    const prefixes: any = {
      [EKind.NOTES]: '/**! NOTES\n',
      [EKind.MISC]: '/**! MISC\n',
      [EKind.METADATA]: '/**! METADATA\n',
      [EKind.ASSET_LINKS]: '/**! ASSET-LINKS\n',
      [EKind.METADATA_URL]: '/**! METADATA URL\n',
      [EKind.CAST]: '/**! CAST\n',
      [EKind.SPEAKERS]: '/**! SPEAKERS\n',
    };
    const blocks = this.elements.filterByKind(EKind.METADATA_BLOCK).values;
    for (const block of blocks) {
      lines.push(prefixes[block.subKind]);
      lines.push(block.content);
      lines.push('*/\n\n');
    }

    const output = lines.join('\n');
    // TODO: @Jason review this code actually works as expected. getting a typescript error after upgrading
    const permission = await (navigator.permissions as any).query({
      name: 'clipboard-write',
    });
    return await navigator.clipboard.writeText(output);
  }

  async copySharableElementLinkToClipboard() {
    // @jrw, either form can be used, which do you prefer?
    // let baseUrl = Utils.getDeploymentConfig("scriptEditorUrl")
    let baseUrl = deploymentConfig.scriptEditorUrl;
    let target = this.focusedElementId ?? this.currentWordId;
    if (isNil(target)) {
      return;
    }
    // if (getKindFromId(target) === EKind.SENTENCE && this.currentWordId) {
    if (idIsOfKind(target, EKind.SENTENCE) && this.currentWordId) {
      target = this.currentWordId;
    }
    // const url = `${baseUrl}/episodes/${this.episodeKey}/editor/${target}`;
    const url = this.unit.resolveEditorUrl(null /*default locale*/, target);
    // TODO figure out why have to make the PermissionsDescriptor an anonymous record when already have the record type
    const permission = await (navigator.permissions as any).query({
      name: 'clipboard-write',
    });
    await navigator.clipboard.writeText(url);
    alertMessages.add({ ...Info, text: 'URL copied to clipboard' });
    return;
  }

  globallyReplaceSpeakerLabel(oldLabel: string, newLabel: string) {
    mutationActions.replaceSpeakerLabels(oldLabel, newLabel);
  }

  @computed
  get structuralDurationWarnings() {
    const warnings: LintWarning[] = [];
    if (this.sparseWordTimes) {
      const breaks = this.elements.filterByKinds([
        EKind.PASSAGE,
        EKind.CHAPTER,
      ]);
      const breakTimes = computeElementsTimeRanges(
        breaks,
        this.sparseWordTimes
      );
      const suppressions = linter.warningSuppressions;
      for (const el of breaks.values) {
        const interval = breakTimes[el.id];
        if (!interval) {
          continue;
        }
        const duration = size(interval);
        if (
          el.kind === EKind.PASSAGE &&
          (duration < 30000 || duration > 60000)
        ) {
          const warning: LintWarning = {
            key: `${el.id}:timeDuration`,
            elementId: el.id,
            message: `Passage duration ${Math.round(
              duration / 1000.0
            )} seconds`,
          };
          if (suppressions.has(warning.key)) {
            warning.suppressed = true;
          }

          warnings.push(warning);
        } else {
          const info: LintWarning = {
            key: `${el.id}:timeInfo`,
            elementId: el.id,
            message: `duration ${msToMinSecString(duration)}`,
            infoOnly: true,
          };
          warnings.push(info);
        }
      }
    }
    return warnings;
  }

  @computed
  get lintWarnings() {
    const els: Element[] = [];
    for (const warning of linter.activeWarnings) {
      els.push(this.elements.getElement(warning.elementId));
    }
    const passages = this.elements.filterByKind(EKind.PASSAGE);
    for (const warning of this.structuralDurationWarnings) {
      if (!(warning.suppressed || warning.infoOnly)) {
        els.push(passages.getElement(warning.elementId));
      }
    }
    const content = this.elements;
    numberProjectionSort(els, e => {
      return content.getIndex(e.id);
    });

    return SimpleElementList(els);
  }

  @computed
  get elementsWithActiveThreads() {
    const elements = this.elements.filter(el => {
      // if (el.kind !== EKind.WORD_GROUP) {
      const thread = this.threadsLookup[el.id];
      if (thread) {
        return thread.withMessages && !thread.resolved;
      }
      // }
      return false;
    });
    return elements;
  }

  syncStatusInfoRollupsIfNeeded() {
    if (this.elements.values.length === 0) {
      return;
    }
    if (!this.unit?.data) {
      return;
    }
    const updates = {} as any as UnitData;
    const warningCount = linter.activeWarnings.length;
    if (this.unit.data.warningCount !== warningCount) {
      updates.warningCount = warningCount;
    }
    const openThreadCount = this.elementsWithActiveThreads.values.length;
    if (this.unit.data.openThreadCount !== openThreadCount) {
      updates.openThreadCount = openThreadCount;
    }
    if (isEmpty(updates)) {
      return;
    }
    const from = {
      openThreadCount: this.unit.data.openThreadCount,
      warningCount: this.unit.data.warningCount,
    };
    const transition = {
      from,
      to: {
        ...from,
        ...updates,
      },
    };
    if (comparer.structural(this.lastStatusSyncTransition, transition)) {
      console.log('bailing on sync status to unit for: ', transition);
      return;
    }
    this.lastStatusSyncTransition = transition;
    console.log('updating rollup stats:', updates);
    this.unit.updatePartial(updates);
  }

  revertToVersion(versionItem: VersionItem) {
    versions.revertTo(versionItem);
  }

  get focusedElementLintWarnings(): LintWarning[] {
    const focusedId = this.focusedElementId;
    // TODO optimize the whole warning lookup thing
    if (notNull(focusedId)) {
      const warnings = linter.allWarnings.filter(
        (warn: LintWarning) => warn.elementId === focusedId
      );
      if (
        [EKind.PASSAGE, EKind.CHAPTER].includes(getKindFromId(focusedId) as any)
      ) {
        const durationWarnings = this.structuralDurationWarnings.filter(
          (warn: LintWarning) => warn.elementId === focusedId
        );
        warnings.push(...durationWarnings);
      }
      return warnings;
    } else {
      return null;
    }
  }

  setWarningSuppression(warning: LintWarning, suppress: boolean) {
    mutationActions.setElementWarningSuppression(warning.key, suppress);
  }

  setFocusedWarningSuppression(suppress: boolean) {
    const focusedLintWarnings = this.focusedElementLintWarnings;
    if (!focusedLintWarnings) {
      return;
    }
    if (focusedLintWarnings.length > 1) {
      alertMessages.add({
        ...Alert,
        text: 'more than one lint warning at focused, use sidepanel',
      });
      return;
    }
    this.setWarningSuppression(focusedLintWarnings[0], suppress);
  }

  openFilter = {
    kind: BaseFilterKinds.OPEN,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#open ',
    parse: regexTermParser(BaseFilterKinds.OPEN, /#open/),
    func: (term: FilterTerm) => (el: Element) => {
      const thread = this.threadsLookup[el.id];
      if (thread) {
        return thread.withMessages && !thread.resolved;
      } else {
        return false;
      }
    },
  };

  unfilledFilter = {
    kind: BaseFilterKinds.UNFILLED,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#unfilled ',
    parse: regexTermParser(BaseFilterKinds.UNFILLED, /#unfilled/),
    func: (term: FilterTerm) => (el: WordGroup) => {
      if (
        el.kind === EKind.WORD_GROUP &&
        el.subKind === WordGroupSubKind.VOCAB
      ) {
        const translation = this.translationsLookup[el.id];
        if (translation) {
          return !(translation?.content as WordGroupContent)?.note;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  };

  warningFilter = {
    kind: BaseFilterKinds.WARNING,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#warning ',
    parse: regexTermParser(BaseFilterKinds.WARNING, /#warning/),
    // TODO optimize lookup
    func: (term: FilterTerm) => (el: Element) =>
      !!this.lintWarnings.getElement(el.id),
  };

  selfAssigned(el: Element) {
    const thread = this.threadsLookup[el.id];
    if (thread) {
      return thread.assignee === auth.appUser.id;
    } else {
      return false;
    }
  }

  selfParticipant(el: Element) {
    const thread = this.threadsLookup[el.id];
    if (thread) {
      const currentUserId: string = auth.appUser.id;
      const participants: string[] = thread.participants;
      if (participants) {
        return participants.includes(currentUserId);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  changed(el: VersionableElement) {
    if (this.baselineTimestamp === null) {
      return false;
    }
    return el.timestamp > this.baselineTimestamp;
  }

  assignedToSelfFilter = {
    kind: BaseFilterKinds.ASSIGNED,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#assigned ',
    parse: regexTermParser(BaseFilterKinds.ASSIGNED, /#assigned/),
    // TODO is there no interface for our user type?
    func: (term: FilterTerm) => (el: Element) => this.selfAssigned(el),
  };

  areParticipantFilter = {
    kind: BaseFilterKinds.AREPARTICIPANT,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#areparticipant ',
    parse: regexTermParser(BaseFilterKinds.AREPARTICIPANT, /#areparticipant/),
    // TODO is there no interface for our user type?
    func: (term: FilterTerm) => (el: Element) => this.selfParticipant(el),
  };

  changedFilter = {
    kind: BaseFilterKinds.CHANGED,
    isFlag: true,
    canonicalText: (t: FilterTerm) => '#changed',
    parse: regexTermParser(BaseFilterKinds.CHANGED, /#changed/),
    // TODO is there no interface for our user type?
    func: (term: FilterTerm) => (el: Element) =>
      this.changed(el as VersionableElement),
  };

  filters: FilterModel = new FilterModel([
    wordGroupFilter,
    vocabFilter,
    trickyFilter,
    sicFilter,
    sentenceFilter,
    structuralFilter,
    this.unfilledFilter,
    this.openFilter,
    byFilter,
    this.warningFilter,
    this.assignedToSelfFilter,
    this.areParticipantFilter,
    this.changedFilter,
  ]);

  @computed
  get searchResult() {
    return this.elements.filter(this.filters.filterFunction);
  }

  setKbDeactivated(value: boolean) {
    this.kbDeactivated = value;
  }

  setChoiceModalMode(on: boolean) {
    this.choiceModalMode = on;
  }

  setChoiceModalHtml(html: string) {
    this.choiceModalHtml = html;
  }

  @computed({ keepAlive: true })
  get sentenceTimes() {
    if (!this.sparseWordTimes) {
      return null;
    }
    return computeElementsTimeRanges(this.sentences, this.sparseWordTimes);
  }

  @computed
  get sentenceTracker() {
    const player = this.player;
    const sentenceTimes = this.sentenceTimes;
    if (!(player && sentenceTimes)) {
      return null;
    }
    const transportState = this.player.audioTransport.transportState;
    const sentencesWithTimes = this.sentences.filter(
      (sentence: EditorSentence): boolean => !!sentenceTimes[sentence.id]
    );

    const sentenceTimeIntervals = fromIntervals(
      sentencesWithTimes.values.map(
        (sentence: EditorSentence) => sentenceTimes[sentence.id]
      )
    );

    const tracker: ElementTracker<Sentence> = CreateTracker({
      elements: sentencesWithTimes,
      positionFunction: () => transportState.audioPosition,
      triggerFunction: () => transportState.audioPosition,
      intervals: () => sentenceTimeIntervals,
    });

    return tracker;
  }

  @computed
  get currentPlayingSentenceId(): SentenceId {
    if (!this.playingMode) {
      return null;
    }
    return this.sentenceTracker?.observableIsUnder();
  }

  get isPlaying() {
    return this.player?.transportState.isPlaying;
  }

  seekToPlayCurrentSentence(continuous: boolean) {
    log.info(`seekToPlay, focusedLineId: ${this.focusedLineId}`);
    if (!(this.focusedLineId && this.player)) {
      return false;
    }

    // if (getKindFromId(this.focusedLineId) !== EKind.SENTENCE) {
    if (!idIsOfKind(this.focusedLineId, EKind.SENTENCE)) {
      return false;
    }

    const sentenceTimes = this.sentenceTimes;
    if (!sentenceTimes) {
      return false;
    }
    const times = sentenceTimes[this.focusedLineId];
    log.info('times', JSON.stringify(times));
    if (!times) {
      return false;
    }
    this.player.seek(times.begin);
    if (!continuous) {
      this.player.audioTransport.setPauseAfter(times.end);
    } else {
      this.player.audioTransport.clearPauseAfter();
    }
    return true;
  }

  togglePlay(continuous: boolean) {
    if (!this.player) {
      // TODO warning
      return;
    }

    if (this.isPlaying) {
      this.player.pause(this.playingMode === 'sentence');
    } else {
      if (this.playingMode === 'continuous') {
        this.player.play();
      } else {
        if (!this.seekToPlayCurrentSentence(continuous)) {
          return;
        }
        this.player.play();
        this.playingMode = continuous ? 'continuous' : 'sentence';
      }
    }
  }

  async loadSentencePlayerData() {
    if (this.isPlaying) {
      this.player.pause();
    }
    const playerDataUrl = `${deploymentConfig.masalaServerUrl}/simple_player_data?key=${this.episodeKey}`;
    const resp = await fetch(playerDataUrl);
    const data = await resp.json();
    // console.log('sentence player data', JSON.stringify(data));
    // TODO no changes if empty result?
    const audioSource = createAudioSource();
    audioSource.setAudioSourceDefinitions(this.episodeKey, {
      audioUrl: data.audioUrl,
    });
    const transportState = new TransportState();
    const audioTransport = new AudioTransport(transportState, {
      exactPauseAfter: true,
    });
    audioTransport.setAudioSource(audioSource);
    const player = new StructuredPlayer(audioTransport, transportState);
    runInAction(() => {
      this.sparseWordTimes = data.wordTimes;
      this.player = player;
    });
  }
}
