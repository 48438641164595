export function keynameForEvent(event: KeyboardEvent) {
  if (!event.code) {
    return 'unknown';
  }

  const keynameParts = [];

  if (event.altKey) {
    keynameParts.push('alt');
  }

  if (event.shiftKey) {
    keynameParts.push('shift');
  }

  if (event.ctrlKey) {
    keynameParts.push('ctrl');
  }

  if (event.metaKey) {
    keynameParts.push('meta');
  }

  keynameParts.push(
    event.code
      .replace(/^Key/, '')
      .replace(/^Arrow/, '')
      .replace(/^Digit/, '')
  );

  const keyname = keynameParts.join('+').toLowerCase();
  // console.log(keyname);

  return keyname;
}
