// import { computed, makeObservable, observable, runInAction } from 'mobx';
import { ExcerptData, UnitData } from '../catalog-types';
import { isEmpty } from 'lodash';
import { Unit } from '../models/unit';
import {
  CatalogCollections,
  collectionReference,
  docReference,
} from './catalog-db-paths';
import { createLogger } from '@app/logger';
import {
  CollectionReference,
  DocumentReference,
} from '@platform/firebase-types';
import { epochSecondsFloat } from '@masala-lib/utils';
import { Excerpt } from '../models/excerpt';
import { EntityCrud } from './entity-crud';

const log = createLogger('unit-manager');

const collectionName = CatalogCollections.EXCERPT_METADATA;

function docRef(id: string): DocumentReference<ExcerptData> {
  return docReference<ExcerptData>(collectionName, id);
}

function collectionRef(): CollectionReference<ExcerptData> {
  return collectionReference<ExcerptData>(collectionName);
}

async function loadById(
  id: string,
  { fetchParents = false }: { fetchParents?: boolean }
): Promise<Excerpt> {
  console.log(`${collectionName}.loadById(${id})`);
  if (isEmpty(id)) {
    return null;
  }
  const docSnapshot = await docRef(id).get();
  if (docSnapshot.exists === false) {
    log.warn(`loadById - missing doc: ${id}`);
    return null;
  }
  const data = docSnapshot.data();
  const model = new Excerpt(data);
  if (fetchParents) {
    await model.fetchUnit();
  }
  return model;
}

async function loadAllForUnit(unit: Unit): Promise<Excerpt[]> {
  const querySnapshot = await collectionRef()
    .where('unitId', '==', unit.id)
    // .where('archived', '==', false) // not trusting that an explicit values exists for all data, so filtering in client-side code
    // .orderBy('unitNumber', 'asc')
    .get();
  const queried = querySnapshot.docs.map(doc => {
    const data = doc.data();
    const model = new Excerpt(data);
    model.fetchedUnit = unit;
    return model;
  });
  const result = queried.filter(entity => !entity.archived);
  return result;
}

async function updatePartial(
  id: string,
  data: Partial<ExcerptData>
): Promise<void> {
  console.log(`${collectionName}.updatePartial(${JSON.stringify(data)})`);
  data.updatedAt = epochSecondsFloat();

  // todo: factor out a docSetMerge() function with this guard
  if (isEmpty(data)) {
    // an empty object object will nuke the entire doc!
    return;
  }
  await docRef(id).set(data, { merge: true });
}

async function toggleArchive(data: ExcerptData): Promise<void> {
  await updatePartial(data.id, { archived: !data.archived });
}

async function destroyArchived() {
  alert('not yet implemented');
}

export const ExcerptCrud: EntityCrud<Excerpt, ExcerptData> = {
  docRef,
  collectionRef,
  loadById,
  loadAllForUnit,
  updatePartial,
  toggleArchive,
  destroyArchived,
};
