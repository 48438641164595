import * as React from 'react';
import styled from 'styled-components';
import { TagType } from '@masala-lib/catalog/models/tag';
import { XIcon } from '../../components/icons/x-icon';
import ReactSelect from 'react-select';
import StateManager from 'react-select';
import { observer } from 'mobx-react';
import { useSwitch } from '../../lib/hooks/use-switch';
import { isEmpty } from 'lodash';
import { PlusIcon } from '../../components/icons/plus-icon';
import { Button, TextButton } from '../../components/button';
import { Volume } from '@masala-lib/catalog/models/volume';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';

const Wrapper = styled.div`
  .tags-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > .tag {
      display: flex;
      align-items: center;
      background: var(--color-gray700);
      color: var(--color-gray200);
      font-size: 14px;
      font-weight: 500;
      padding: 6px 10px;
      margin: 4px;
      border-radius: 4px;
      cursor: default;

      .label {
        margin-top: -3px;
      }

      .remove {
        display: flex;
        color: var(--color-gray200);
        background: var(--color-transparent);
        padding: 4px;
        margin-left: 4px;
        margin-right: -4px;
        border-radius: 4px;
        &:hover {
          color: var(--color-white);
          background: var(--color-gray800);
        }
      }
    }

    .plus-icon {
      border: none;
      display: flex;
      align-items: center;
      background: var(--color-gray100);
      color: var(--color-gray400);
      font-size: 14px;
      font-weight: 500;
      padding: 6px 10px;
      margin: 4px;
      border-radius: 4px;
      cursor: pointer;
      .label {
        margin-left: 4px;
      }
    }
  }
  .selector {
    margin-top: 16px;
    max-width: 400px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--color-gray100);

    .buttons {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
    }

    .label {
      display: block;
      font-size: 17px;
      line-height: 22px;
      font-weight: 400;
      color: var(--color-gray500);
      margin-bottom: 8px;
    }
  }
`;

interface VolumeTagEditorProps {
  volume: Volume;
  tagType: TagType;
  editable?: boolean;
}

export const VolumeTagEditor = observer(
  ({ volume, tagType, editable = true }: VolumeTagEditorProps) => {
    const currentTags = volume.tagsByType(tagType);
    // const currentSlugs = unit.data.tagSlugs ?? [];
    const currentSlugs = currentTags.map(tag => tag.slug);
    const availableTags = TagManager.getInstance()
      .getTagOptions(tagType)
      .filter(tag => {
        // const tags = Array.from(currentTags);
        return !currentSlugs.includes(tag.value);
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    const [selectedTag, setSelectedTag] = React.useState<string>('');

    const addingTag = useSwitch(false);

    const ref = React.useRef<StateManager>();

    const handleTagSelection = (selectedOption: any) => {
      setSelectedTag(selectedOption?.value ?? '');
      // addingTag.off();
    };

    const handleInsertTag = () => {
      if (!isEmpty(selectedTag)) {
        const tagName = selectedTag;
        setSelectedTag('');
        volume.addTypeTag(tagType, tagName).then(addingTag.off);
      }
    };

    const getTagRemover =
      (tagSlug: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to remove this tag?')) {
          volume.removeTypeTag(tagType, tagSlug);
        }
      };

    return (
      <>
        <Wrapper>
          <div className="tags-container">
            {currentTags.map(tag => {
              return (
                <div className="tag" key={tag.name}>
                  <span className="label">{tag.name}</span>
                  {editable ? (
                    <a
                      href="#"
                      className="remove"
                      onClick={getTagRemover(tag.slug)}
                    >
                      <XIcon size={14} />
                    </a>
                  ) : null}
                </div>
              );
            })}
            {addingTag.value === false && editable ? (
              <button
                onClick={addingTag.on}
                className="plus-icon"
                title="insert new tag"
              >
                <PlusIcon />
                {currentTags.length === 0 ? (
                  <span className="label">Insert tag…</span>
                ) : null}
              </button>
            ) : null}
          </div>
          {addingTag.value ? (
            <div className="selector">
              <div className="label">Insert a new tag</div>
              <ReactSelect
                options={availableTags}
                ref={ref}
                placeholder={'Select tag'}
                value={availableTags.find(
                  option => option.value === selectedTag
                )}
                onChange={handleTagSelection}
                isClearable
              />
              <div className="buttons">
                <TextButton onClick={addingTag.off} label="cancel" />
                <Button
                  onClick={handleInsertTag}
                  disabled={!selectedTag}
                  label={'Insert tag'}
                />
              </div>
            </div>
          ) : null}
        </Wrapper>
      </>
    );
  }
);
