import { ActivityGuideData } from '@masala-lib/catalog/catalog-types';
import { isEmpty, pick } from 'lodash';
import { useMasalaForm } from '../../lib/hooks/use-masala-form';
import { FormButtons, Input, Select, Textarea } from '../../components/forms';
import { Unit } from '@masala-lib/catalog/models/unit';
import { Card } from '../../components/data-display/card';
import { DataItem } from '../../components/data-display/data-item';
import { AuthorManager } from '@masala-lib/catalog/db/author-manager';
import { UnitManager } from '@masala-lib/catalog/db/unit-manager';

interface Props {
  dto: ActivityGuideData;
  onSubmit: (dto: ActivityGuideData) => void;
  onCancel: () => void;
  unit?: Unit;
}

export const ActivityGuideForm = ({ dto, unit, onSubmit, onCancel }: Props) => {
  const defaultValues = pick(dto, [
    'id',
    'authorId',
    'authorNote',
    'resourceUrl',
    'unitId',
  ]);
  if (unit) {
    defaultValues.unitId = unit.id;
  }

  const { handleSubmit, getFieldProps } = useMasalaForm<typeof defaultValues>({
    defaultValues,
  });

  const isNew = isEmpty(dto.id);

  const authorOptions = AuthorManager.getInstance().selectOptions;
  const unitOptions = UnitManager.getInstance().selectOptionsWithBlank;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {unit ? (
        <Card>
          <DataItem label={'Adding to unit'} value={unit.name} />
        </Card>
      ) : (
        <Select {...getFieldProps('unitId')} options={unitOptions}></Select>
      )}
      <Select {...getFieldProps('authorId')} options={authorOptions}></Select>
      <Input {...getFieldProps('resourceUrl')} label={'Resource Url'} />
      <Textarea
        {...getFieldProps('authorNote')}
        label={'Author‘s Note'}
        hint={'You can use **bold** _italics_'}
        rows={6}
      />
      <FormButtons
        submitLabel={isNew ? 'Create activity guide' : 'Save changes'}
        onCancel={onCancel}
      />
    </form>
  );
};
