import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { SearchBar } from '../shared/search-bar';
import { Page } from '../shared/page';
import { OnePaneLayout } from '../shared/layouts';
import { PageActions } from '../shared/page-actions';
import { TagsTable } from './tags-table';
import { TagManager } from '@masala-lib/catalog/db/tag-manager';
import { Auth } from '@masala-lib/editorial/db/auth';

export const TagsListScreen = observer(() => {
  const auth = Auth.getInstance();
  const manager = TagManager.getInstance();
  const list = manager.tableList;

  return (
    <Page
      title="Manage Tags"
      controls={
        <PageActions>
          {auth.isAdmin ? (
            <Link to={'/tags/new'} className="primary-action">
              Create new tag
            </Link>
          ) : null}
          <SearchBar manager={manager} />
        </PageActions>
      }
    >
      <OnePaneLayout>
        <TagsTable tags={list} />
      </OnePaneLayout>
    </Page>
  );
});
