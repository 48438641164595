import { observer } from 'mobx-react';
import { ScriptElement } from '@masala-lib/llm/llm-types';
import { computed } from 'mobx';
import { useEffect } from 'react';
import { useTranslationKeyboardBindings } from './translation-keyboard-bindings';
import { ScriptElementForm } from './script-element-form';
import { ScriptElementItem } from './script-element-item';
import { styleLookup } from './style-lookup';
import { useTouchupEditorModal } from './use-editor-modal';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { useVocabKeyboardBindings } from './vocab-keyboard-bindings';
import { useStructuralKeyboardBindings } from './structural-keyboard-bindings';

export type ElementStyleInfo = {
  prefix: string | ((s: ScriptElement) => string);
  styleName: string;
};

export const ScriptElementView = observer(
  ({ element }: { element: ScriptElement }) => {
    const modal = useTouchupEditorModal();
    const id = element.id;
    // const kind = element.kind;
    const isLoser = computed(() => modal.isLoser(id)).get();
    const hasSelectedReference = computed(() =>
      modal.hasSelectedSlotHead(id)
    ).get();
    const editing = computed(() => modal.editingElementId === id).get();

    // const style = styleLookup[kind];
    // const elementCategory = modal.elementCategory(element);
    // const isOutput = elementCategory === 'OUTPUT';
    // const isReference = elementCategory === 'REFERENCE';
    element =
      computed(() => modal.activeEditsMap.get(element.id)).get() ?? element;
    // let prefix =
    //   typeof style.prefix === 'function' ? style.prefix(element) : style.prefix;
    // if (isReference) {
    //   prefix += `[${element.reference}] `;
    // }

    if (editing) {
      return <ScriptElementForm element={element} modal={modal} />;
    }

    // expand losers automatically when cursoring through
    if (isLoser && !modal.showLosers && !hasSelectedReference) {
      return null;
    }

    return <ScriptElementItem element={element} modal={modal} />;
  }
);

export function sentencePrefix(sentence: ScriptElement) {
  return `[${sentence.reference}] `;
}

export const TranslationMergeView = observer(
  ({ onResolve }: { onResolve: (value: boolean) => void }) => {
    const editorModal = useTouchupEditorModal();
    // TODO hacking
    const samosaModel = useSamosaModel();
    const task = samosaModel.task;
    if (task === 'translation') {
      useTranslationKeyboardBindings(editorModal, onResolve);
    } else if (task === 'vocab') {
      useVocabKeyboardBindings(editorModal, onResolve);
    } else if (task === 'structural') {
      useStructuralKeyboardBindings(editorModal, onResolve);
    }

    useEffect(() => {
      return () => {
        // setTouchupEditorModal(null); // TODO
      };
    }, []);

    const elements = editorModal.displayScript;

    return (
      <>
        {elements.map(element => {
          return <ScriptElementView key={element.id} element={element} />;
        })}
      </>
    );
  }
);

// export const TableTranslationMergeScriptView = () => {
//   const editorModal = useTouchupEditorModal();
//   useKeyboardBindings(editorModal);
//   useEffect(() => {
//     return () => {
//       // setTouchupEditorModal(null); // TODO
//     };
//   }, []);

//   const elements = editorModal.displayScript;

//   return (
//     <>
//       {elements.map(element => {
//         return <ScriptElementView key={element.id} element={element} />;
//       })}
//     </>
//   );
// };
