import { observer } from 'mobx-react';
import { Exchange } from '@masala-lib/llm/project/llm-project-types';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { presentResponseOverrideModal } from './response-override-modal';
import { HiPencil } from 'react-icons/hi';
import { BiEraser, BiTrash } from 'react-icons/bi';
import { styled } from '@naan/stitches.config';
import { ToolbarButton, ToolbarContainer } from '../components/toolbar';
import { IconButton } from '@naan/primitives/button';
// import { presentEditLabelModal } from './editable-exchange-label';

const ResponseOverflowMenu = observer(
  ({ exchange }: { exchange: Exchange }) => {
    const model = useSamosaModel();

    const overridden = !!exchange.rawResponse.overridden;

    const resetResponseOverride = () => {
      // todo: figure out how to cleanly trigger a rerender for this flow (right now relying on the form toggle)
      model.resetResponseOverride(exchange);
    };

    return (
      <Menu
        trigger={
          <IconButton
            icon={<OverflowIcon />}
            size="small"
            testId="response-overflow-menu"
          />
        }
      >
        {model.showExploratoryUI && !model.isSingleStepThread(exchange) ? (
          <MenuItem
            action={() => void model.touchupExchangeResponse(exchange)}
            label={'Touchup this response'}
          />
        ) : null}

        {model.showExploratoryUI ? (
          <MenuItem
            action={() => void model.touchupThread(exchange)}
            label={'Touchup thread'}
          />
        ) : null}
        <MenuItem
          action={() => void model.handleEditExchangeLabel(exchange)}
          label={'Edit label'}
        />

        {model.showExploratoryUI ? (
          <>
            <MenuItem
              action={() => presentResponseOverrideModal(exchange)}
              label={'Raw edit response data'}
            />
            {overridden ? (
              <MenuItem action={resetResponseOverride} label="Reset override" />
            ) : null}
          </>
        ) : null}

        {model.isTerminalStep(exchange) ? (
          model.isThreadSuppressed(exchange) ? (
            <MenuItem
              action={() => model.asyncSetSuppressThread(exchange, false)}
              label={'Unsuppress this thread'}
            />
          ) : (
            <MenuItem
              action={() => model.asyncSetSuppressThread(exchange, true)}
              label={'Suppress this thread'}
            />
          )
        ) : null}

        {model.showExploratoryUI ? (
          <>
            {model.isSingleStepThread(
              exchange
            ) ? null : model.isExchangeSuppressed(exchange) ? (
              <MenuItem
                action={() => model.asyncSetSuppressExchange(exchange, false)}
                label={'Unsuppress this response'}
              />
            ) : (
              <MenuItem
                action={() => model.asyncSetSuppressExchange(exchange, true)}
                label={'Suppress this response'}
              />
            )}

            {exchange.request.llmOptions.parsingDisabled ? (
              <MenuItem
                action={() =>
                  model.asyncSetResponseParsingDisabled(exchange, false)
                }
                label={'Enable parsing for this response'}
              />
            ) : (
              <MenuItem
                action={() =>
                  model.asyncSetResponseParsingDisabled(exchange, true)
                }
                label={'Disable parsing for this response'}
              />
            )}
          </>
        ) : null}

        {model.isTerminalStep(exchange) ? (
          <MenuItem
            action={() => void model.confirmAndDeleteExchange(exchange)}
            label="Hard delete this request/response"
          />
        ) : (
          <MenuItem
            action={() => void model.setCurrentStepId(exchange.id)}
            label="Fork new thread from here"
          />
        )}
      </Menu>
    );
  }
);

// export const ToolbarButton = (
//   props: React.ComponentProps<typeof IconButton>
// ) => {
//   return (
//     <IconButton
//       {...props}
//       size={'small'}
//       css={{
//         $$iconColor: '$colors$blue-600',
//       }}
//     />
//   );
// };

export const ResponseToolbar = observer(
  ({ exchange }: { exchange: Exchange }) => {
    const model = useSamosaModel();
    return (
      <ToolbarContainer presentation={'blue'}>
        {/* {model.showExploratoryUI ? ( */}
        <ToolbarButton
          icon={<HiPencil />}
          onClick={() => presentResponseOverrideModal(exchange)}
          testId="response-edit-button"
        />
        {/* ) : null} */}
        <ToolbarButton
          icon={<BiEraser />}
          onClick={() => void model.toggleSuppressExchange(exchange)}
          testId="response-suppress-button"
        />
        <ToolbarButton
          icon={<BiTrash />}
          onClick={() => model.confirmAndDeleteExchange(exchange)}
          testId="response-delete-button"
        />
        <ResponseOverflowMenu exchange={exchange} />
      </ToolbarContainer>
    );
  }
);
