export const BackArrowIcon = ({ size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 100 100"
    width={size}
    height={size}
  >
    <path d="M62.5,62.5H100v-25H62.5V17.734L34.609,32.109L1.719,48.359v3.281l32.891,16.25L62.5,82.266V62.5"></path>
  </svg>
);
