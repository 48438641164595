import { observer } from 'mobx-react';
import { StepList } from './step-list';
import { Settings } from './settings';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';
import { ReferenceScriptItem } from './reference-script-item';
import { MergeScriptItem } from './merge-script-item';
import { styled } from '@naan/stitches.config';

const SidebarWrapper = styled('div', {
  height: '100vh',
  overflowY: 'hidden',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: '"fixed" "scrollable" "bottom"',
  '& .sidebar-fixed': {
    gridArea: 'fixed',
  },
  '& .sidebar-scrollable': {
    gridArea: 'scrollable',
    overflowY: 'auto',
  },
  '& .sidebar-bottom': {
    backgroundColor: '$blue-100',
    gridArea: 'bottom',
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    padding: 12,
    borderTop: '1px solid $colors$black-alpha-06',
  },
});

//
// left nav list of selectable exchanges, etc
//
export const Sidebar = observer(({ goBack }: { goBack: () => void }) => {
  const model = useSamosaModel();

  return (
    <SidebarWrapper>
      <div className="sidebar-fixed">
        <Settings goBack={goBack} />
      </div>
      <div className="sidebar-scrollable">
        <StepList />
      </div>
      <div className="sidebar-bottom">
        <MergeScriptItem />
        <ReferenceScriptItem step={model.referenceScript} />
      </div>
    </SidebarWrapper>
  );
});
