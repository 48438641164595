const TOKEN_UNDERSCORE = '_';
export const markderp = string => {
  // very basic emdash support
  string = string.replace(/--/gi, '—');
  let pos = 0;
  const tokens = {};
  while (pos < string.length) {
    const char = string[pos];
    // if (char === '<') {
    //   if (string.substr(pos, 4) === '<br>') {
    //     tokens[pos] = { type: 'br', start: pos, length: 4, content: '' };
    //     pos += 3;
    //     continue;
    //   }
    //   if (string.substr(pos, 5) === '<br/>') {
    //     tokens[pos] = { type: 'br', start: pos, length: 5, content: '' };
    //     pos += 4;
    //     continue;
    //   }
    // }

    if (char === TOKEN_UNDERSCORE) {
      let subpos = 1;
      while (pos + subpos < string.length) {
        const subchar = string[pos + subpos];
        if (subchar === TOKEN_UNDERSCORE) {
          tokens[pos] = {
            type: 'i',
            start: pos,
            length: subpos + 1,
            content: string.substr(pos + 1, subpos - 1),
          };
          pos += subpos;
          break;
        }
        subpos += 1;
      }
    }

    // if (char === '*') {
    //   let subpos = 1;
    //   while (pos + subpos < string.length) {
    //     const subchar = string[pos + subpos];
    //     if (subchar === '*') {
    //       tokens[pos] = {
    //         type: 'i',
    //         start: pos,
    //         length: subpos + 1,
    //         content: string.substr(pos + 1, subpos - 1),
    //       };
    //       pos += subpos;
    //       break;
    //     }
    //     subpos += 1;
    //   }
    // }

    pos += 1;
  }

  pos = 0;
  let normalStart = 0;
  const parts = [];
  while (pos < string.length) {
    const token = tokens[pos] || null;
    if (token) {
      const normalLength = pos - normalStart;
      parts.push({
        type: 'normal',
        content: string.substr(normalStart, normalLength),
      });

      parts.push({
        type: token.type,
        content: token.content,
      });

      pos += token.length;
      normalStart = pos;
    }
    pos += 1;
    if (pos === string.length) {
      parts.push({
        type: 'normal',
        content: string.substr(normalStart, string.length - normalStart),
      });
    }
  }
  return parts;
};
