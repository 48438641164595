import { observer } from 'mobx-react';
import { keyframes, styled } from '@naan/stitches.config';
import { ScriptElement } from '@masala-lib/llm/llm-types';
import { TouchUpEditorModal } from 'samosa/models/touchup-editor-modal';
import { computed } from 'mobx';
import { ActionLink } from '@masala-lib/editorial/ui/action-link';
import { FaStar, FaRegStar } from 'react-icons/fa6';

const pulseTwice = keyframes({
  '0%': {
    transform: 'scale(1)',
    $$iconColor: '$colors$yellow-300',
  },
  '50%': {
    transform: 'scale(1.3)',
    $$iconColor: '$colors$yellow-600',
  },
  '100%': {
    transform: 'scale(1)',
    $$iconColor: '$colors$yellow-300',
  },
});

const WinningIndicatorWrapper = styled('div', {
  $$iconColor: '$colors$gray-100',
  $$iconHoverColor: '$colors$yellow-300',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  color: '$$iconColor',
  transition: 'color 0.2s ease',
  '&:hover': {
    color: '$$iconHoverColor',
  },

  '&.winner': {
    $$iconColor: '$colors$yellow-500',
    $$iconHoverColor: '$colors$yellow-700',
    animation: `${pulseTwice} 0.3s ease`,
  },

  '& > button': {
    all: 'unset',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    color: 'inherit',
    '& > svg': {
      width: 14,
      height: 14,
    },
  },
});

export const WinningIndicator = observer(
  ({
    element,
    modal,
  }: {
    element: ScriptElement;
    modal: TouchUpEditorModal;
  }) => {
    const id = element.id;
    const picked = computed(() => modal.isPicked(id)).get();

    return (
      <WinningIndicatorWrapper className={picked ? 'winner' : 'supressed'}>
        <button onClick={() => modal.toggleWinner(id)}>
          {picked ? <FaStar /> : <FaRegStar />}
        </button>
      </WinningIndicatorWrapper>
    );
  }
);
