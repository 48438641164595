import { alertMessages, mutationActions } from '../app-root';
import { TranslationActions } from '@masala-lib/editorial/models/actions/translation-actions';

export class ScriptEditorTranslationActions extends TranslationActions {
  get mutationActions() {
    return mutationActions;
  }

  get alertMessages() {
    return alertMessages;
  }
}
