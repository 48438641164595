import styled from 'styled-components';

export const Tabs = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--color-gray100);
  background-color: var(--color-blue20);

  .one-pane & {
    margin-top: -16px;
    margin-left: -12px;
    padding-left: 24px;
    margin-bottom: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
    padding-left: 18px;
    display: flex;
    list-style-type: none;
    li {
      display: block;
      a {
        display: block;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        padding: 8px 24px;
        margin-top: 8px;
        border-radius: 4px 4px 0 0;
        color: var(--color-blue500);
        position: relative;
        border: 1px solid var(--color-transparent);
        border-bottom-color: var(--color-transparent);
        top: 1px;
        &:hover {
          background-color: var(--color-blue500);
          color: var(--color-blue50);
        }
        &.active {
          background-color: var(--color-white);
          border-color: var(--color-gray100);
          border-bottom-color: var(--color-white);
          color: var(--color-gray700);
          &:hover {
            color: var(--color-gray700);
            cursor: default;
          }
        }
      }
    }
  }
`;
