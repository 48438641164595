import { Slice } from './timestamper-types';
import { sliceWith } from './util_ts';

export function sliceFrame<T>(frame0: T, slice: Slice): T {
  const frame = frame0 as any;
  const newFrame = {} as any;
  for (const field of Object.getOwnPropertyNames(frame)) {
    const fieldArr = frame[field];
    if (Array.isArray(fieldArr)) {
      newFrame[field] = sliceWith(frame[field], slice);
    } else {
      throw new Error();
    }
  }
  return newFrame;
}
