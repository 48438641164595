import { GenericFirestoreDocSet } from '../../firestore-db/firestore-doc-set';
import { DocKeys } from './db';
import {
  SentenceVersionsDoc,
  StructuralVersionsDoc,
  TranslationVersionsDoc,
  WordGroupVersionsDoc,
} from './firestore-doc-types';

// TODO include rollback actions doc in future

type VersionsDocs = {
  sentenceVersionsDoc: SentenceVersionsDoc;
  wordGroupVersionsDoc: WordGroupVersionsDoc;
  structuralVersionsDoc: StructuralVersionsDoc;
  translationVersionsDoc: TranslationVersionsDoc;
};

export class VersionsDocSet0 extends GenericFirestoreDocSet {
  constructor(key: string, listenMode = true) {
    super(key, null, listenMode);
  }

  getDocKeys(): DocKeys[] {
    return [
      DocKeys.SENTENCE_VERSIONS_DOC,
      DocKeys.WORD_GROUP_VERSIONS_DOC,
      DocKeys.STRUCTURAL_VERSIONS_DOC,
      DocKeys.TRANSLATION_VERSIONS_DOC,
    ];
  }
}

export function VersionsDocSet(
  key: string,
  listenMode?: boolean
): VersionsDocSet0 & { docs: VersionsDocs } {
  return new VersionsDocSet0(key, listenMode);
  // TODO something cleaner with the return typing
}
