import * as React from 'react';
import ReactSelect from 'react-select';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectOption } from '@masala-lib/catalog/db/catalog-entity-manager';
import {
  getQueryParamsFromLocation,
  makeQueryPath,
  serializeQueryString,
} from '../../../lib/query-stuff';

import { get } from 'lodash';
import { FilterControlWrapper } from './filter-control-wrapper';
import StateManager from 'react-select';
import { useKeyboardBinding } from '../../../lib/hooks/use-keyboard-binding';

// const xIcon = (
//   <svg width={18} height={18} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path
//       d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"
//       fill="currentColor"
//       fillOpacity="0.54"
//     />
//   </svg>
// );

interface FilterSelectorProps {
  path: string;
  fieldName: string;
  options: Array<SelectOption>;
  label: string;
  placeholder?: string;
  shortcutKey?: string;
}

export const FilterSelector = observer(
  ({
    label,
    path,
    fieldName,
    options,
    placeholder,
    shortcutKey,
  }: FilterSelectorProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const param = get(getQueryParamsFromLocation(location), fieldName);

    const ref = React.useRef<StateManager>();

    useKeyboardBinding(shortcutKey, () => {
      ref.current.focus();
    });

    const handleChange = (selectedOption: SelectOption, { action = '' }) => {
      switch (action) {
        case 'select-option':
          navigate(
            makeQueryPath(path, {
              [fieldName]: selectedOption.value,
            }),
            { replace: true }
          );
          ref.current.blur();
          break;

        case 'clear':
          removeFilter();
          break;

        default:
          break;
      }
      console.log(action);
    };

    const removeFilter = () => {
      const { pathname } = location;
      const curQuery = getQueryParamsFromLocation(location, false);
      curQuery[fieldName] = null;
      const newSearchString = serializeQueryString(curQuery);
      navigate(`${pathname}?${newSearchString}`);
    };

    return (
      <FilterControlWrapper
        label={label}
        hint={shortcutKey?.replace(/^key/, '')}
      >
        <ReactSelect
          ref={ref}
          placeholder={placeholder}
          value={options.find(option => option.value === param)}
          options={options}
          onChange={handleChange}
          isClearable
        />
      </FilterControlWrapper>
    );
  }
);
