import { computed } from 'mobx';
import { EpisodeDataBase } from '@masala-lib/editorial/episode-data/episode-data';
import { EKind } from '@masala-lib/element-kinds';
import { sortElements } from '@masala-lib/element-sort';
import {
  Element,
  CreateElementList,
  Structural,
} from '@masala-lib/chaat-aliases';
import {
  EdgeEnum,
  TimelineNavigator,
} from '@tikka/navigation/timeline-navigator';
import { ChaatStructural } from '@masala-lib/editorial-types';

export class ChaatEpisodeData extends EpisodeDataBase {
  stateVersion0 = 1;

  @computed({ keepAlive: true })
  get content() {
    const result: Element[] = [];
    result.push(...this.sentencesWithTimes);
    const displayStructuralKinds: string[] = [
      EKind.CHAPTER,
      EKind.PASSAGE,
      EKind.PARAGRAPH,
    ];
    const chaatDisplayStructuralElement = (e: any): boolean =>
      displayStructuralKinds.includes(e.kind);
    result.push(
      ...(this.structural.filter(
        chaatDisplayStructuralElement
      ) as ChaatStructural[])
    );
    sortElements(result);
    return CreateElementList({
      elements: result,
      words: this.wordsWithTimes,
    });
  }

  @computed({ keepAlive: true })
  get warningSentences() {
    return this.content.fromIds(this.warningSentenceIds);
  }

  @computed({ keepAlive: true })
  get segmentNavigator(): TimelineNavigator {
    const navigator = new TimelineNavigator('SEGMENT');
    // TODO consider better way besides option param??
    navigator.setIntervals(this.segmentTimeIntervals, EdgeEnum.Midpoint);
    return navigator;
  }

  @computed({ keepAlive: true })
  get notchNavigator(): TimelineNavigator {
    const navigator = new TimelineNavigator('NOTCH');
    navigator.setIntervals(this.notchTimeIntervals, EdgeEnum.Midpoint);
    return navigator;
  }

  @computed({ keepAlive: true })
  get playableElements() {
    const result = [];
    result.push(...this.content.filterByKind(EKind.SENTENCE).values);
    result.push(...this.wordsWithTimes.values);
    sortElements(result);
    return CreateElementList({
      elements: result,
      words: this.wordsWithTimes,
    });
  }

  get stateVersion() {
    const touch = [this.wordsWithTimes, this.content];
    this.stateVersion0 = this.stateVersion0 + 1;
    return this.stateVersion0;
  }
}
