import { observer } from 'mobx-react';
import { JobsTable } from './jobs-table';
import { JobListModel } from '@masala-lib/jobs/job-manager';
import React from 'react';
import { PageHeading } from '../shared/page-heading';
import { SearchBar } from '../shared/search-bar';

export const ArchivedJobsList = observer(() => {
  // const auth = Auth.getInstance();

  const [model, setModel] = React.useState<JobListModel>();

  React.useEffect(() => {
    const listModel = new JobListModel();
    listModel.listenArchived();
    setModel(listModel);

    return () => listModel.stopListening();
  }, []);

  if (!model) {
    return null;
  }
  return (
    <>
      <PageHeading>
        {/* <DeleteAllButton
          action={() => JobManager.getInstance().destroyArchived()}
          itemsName="jobs"
        /> */}
        <SearchBar manager={model} />
      </PageHeading>
      <JobsTable list={model.list} />
    </>
  );
});
