import { autorun, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { wordGroupActions, translationActions } from '../models/app-root';
import { WordIdRange, WordId } from '@tikka/basic-types';
import {
  ElementList,
  Element,
  WordGroup,
  Word,
  Sentence,
} from '@masala-lib/editor-aliases';
import {
  getSentenceWordIdRange,
  wordIdRangeToWordStrings,
} from '@masala-lib/content-utils';
import { WordRangeSelect } from '@masala-lib/editorial/ui/word-range-select';
import { MDBBtn, MDBInput } from 'mdbreact';
import { ConversationView } from './conversation-view';
import { TargetedEvent } from '@masala-lib/misc/ui-types';
import { EKind, WordGroupSubKind } from '@masala-lib/element-kinds';
import { LintWarning } from '@masala-lib/editorial/linter/linter';
import { LintWarningItem } from './lint-warning';
import { TipContents } from '../../strings/tip-contents';
import { ToolTip } from '../../ui/tooltip';
import { InfoIcon } from '../../console/components/icons/info-icon';
import {
  onlyFirstCapitalized,
  trimWordGroupUsagePunctuation,
} from '@masala-lib/misc/editorial-string-utils';
import { stripUnderscores } from '@utils/content-string-utils';
import { Translation, WordGroupContent } from '@masala-lib/editorial-types';

const NOTE_LABELS = {
  VOCAB: 'Definition',
  TRICKY: 'Note (optional)',
  SIC: 'Note (optional)',
};

const CANONICAL_LABELS = {
  VOCAB: 'Headword (when needed)',
  TRICKY: '(n/a)',
  SIC: 'Intended',
};

interface Props {
  l1locale: string;
  onMasterLanguageFork: boolean;
  translations: { [index: string]: Translation };
  element: WordGroup;
  content: ElementList<Element>;
  warnings: LintWarning[];
}
@observer
export class WordGroupEditor extends React.Component<Props> {
  disposers: (() => void)[] = [];

  constructor(props: Props) {
    super(props);
    makeObservable(this);
    this.disposers.push(autorun(() => this.configureEditableValues()));
  }

  get wordGroup(): WordGroup {
    return this.props.element;
  }

  get lintWarnings(): LintWarning[] {
    return this.props.warnings;
  }

  get content(): ElementList<Element> {
    return this.props.content;
  }

  get onMasterLanguageFork() {
    return this.props.onMasterLanguageFork;
  }

  get translations() {
    return this.props.translations;
  }
  get domainWords(): ElementList<Word> {
    return this.content.words;
  }

  // TODO factor lookup containing sentences to new module ContentFuncs
  get sentences() {
    return this.content.filterByKind(EKind.SENTENCE);
  }

  @computed
  get wordGroupSentence(): Sentence {
    return this.sentences.getElementContainingWordAddress(
      this.wordGroup.address
    );
  }

  get sentenceWordRange(): WordIdRange {
    return getSentenceWordIdRange(this.wordGroupSentence, this.domainWords);
  }

  get wordGroups(): ElementList<WordGroup> {
    return this.content.filterByKind(EKind.WORD_GROUP);
  }

  sentenceTranslation: string = ''; // TODO

  noteInputEl: HTMLElement = null;

  @observable.ref selectedWordRange: WordIdRange = null;

  @observable noteTextValue: string = '';

  @observable canonicalTextValue: string = '';

  @observable preserveCaseValue: boolean = false;

  @observable usageTextValue: string = '';

  configureEditableValues() {
    const note =
      (this.translations[this.wordGroup.id]?.content as WordGroupContent)
        ?.note ?? '';
    const content = this.wordGroup.content as WordGroupContent;
    const canonical = content?.canonical ?? '';
    const usage = content?.usage ?? '';
    this.noteTextValue = note.replace(/^\s*/, '');
    this.canonicalTextValue = canonical.replace(/^\s*/, '');
    this.preserveCaseValue = !!content.preserveCase;
    this.usageTextValue = usage.replace(/^\s*/, '');
    // TODO factor this to ContentFuncs
    this.selectedWordRange = {
      begin: this.wordGroup.anchor.wordId,
      end: this.wordGroup.anchor.endWordId,
    };
  }

  revert() {
    this.configureEditableValues();
  }

  getSelectedWordRange() {
    return this.selectedWordRange;
  }

  setSelectedWordRange(range: WordIdRange) {
    this.selectedWordRange = range;
  }

  handleNoteTextChange(e: TargetedEvent) {
    this.noteTextValue = e.target['value'];
  }

  handleCanonicalTextChange(e: TargetedEvent) {
    this.canonicalTextValue = e.target['value'];
  }

  handleUsageTextChange(e: TargetedEvent) {
    this.usageTextValue = e.target['value'];
  }

  handlePreserveCaseChange(e: TargetedEvent) {
    this.preserveCaseValue = e.target['checked'];
  }

  focusNoteInput() {
    if (this.noteInputEl) {
      this.noteInputEl.focus();
      // TODO check if works without additional call in settimeout
      setTimeout(() => this.noteInputEl.focus(), 400);
    }
  }

  get rangeValuesChanged(): boolean {
    const origRange: WordIdRange = {
      begin: this.wordGroup.anchor.wordId,
      end: this.wordGroup.anchor.endWordId,
    };
    return (
      origRange.begin !== this.selectedWordRange.begin ||
      origRange.end !== this.selectedWordRange.end
    );
  }

  get noteValueChanged(): boolean {
    let edited = false;
    const origNote: string = (
      this.translations[this.wordGroup.id]?.content as WordGroupContent
    )?.note as string;
    if (origNote) {
      if (this.noteTextValue !== origNote.replace(/^\s*/, '')) {
        edited = true;
      }
    } else if (this.noteTextValue.length > 0) {
      edited = true;
    }
    return edited;
  }

  handleInputFieldKeypress(e: any) {
    if (e.code === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      this.handleWordGroupChangeSubmit();
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }

  get contentValuesChanged(): boolean {
    let edited = false;
    const origCanonical: string = this.wordGroup.content.canonical;
    if (origCanonical) {
      if (this.canonicalTextValue !== origCanonical.replace(/^\s*/, '')) {
        edited = true;
      }
    } else if (this.canonicalTextValue.length > 0) {
      edited = true;
    }

    const origUsage: string = this.wordGroup.content.usage;
    if (origUsage) {
      if (this.usageTextValue !== origUsage.replace(/^\s*/, '')) {
        edited = true;
      }
    } else if (this.usageTextValue.length > 0) {
      edited = true;
    }

    if (!!this.wordGroup.content.preserveCase !== this.preserveCaseValue) {
      edited = true;
    }
    return edited;
  }

  get valuesChanged(): boolean {
    return (
      this.rangeValuesChanged ||
      this.noteValueChanged ||
      this.contentValuesChanged
    );
  }

  handleWordGroupChangeSubmit() {
    const group = this.wordGroup;
    if (
      this.rangeValuesChanged ||
      this.contentValuesChanged ||
      this.noteValueChanged
    ) {
      if (
        this.rangeValuesChanged ||
        this.contentValuesChanged ||
        this.onMasterLanguageFork
      ) {
        let canonical = this.canonicalTextValue.trim();
        let usage = this.usageTextValue.trim();
        const content: WordGroupContent = {};
        if (canonical) {
          content.canonical = canonical;
        }
        if (usage) {
          content.usage = usage;
        }
        content.preserveCase = this.preserveCaseValue;
        const data = {
          id: group.id,
          subKind: group.subKind,
          anchor: {
            wordId: this.selectedWordRange.begin as WordId,
            endWordId: this.selectedWordRange.end as WordId,
          },
          content,
        };
        wordGroupActions.update(data);
      }
      if (this.noteValueChanged || this.onMasterLanguageFork) {
        translationActions.addUpdate(group.id, this.props.l1locale, {
          note: this.noteTextValue,
        });
      }
    }
  }

  handleWordGroupDeleteSubmit() {
    wordGroupActions.removeFocused();
  }

  render() {
    const getSelectedWordRange = () => this.getSelectedWordRange();
    const setSelectedWordRange = (selection: WordIdRange) =>
      this.setSelectedWordRange(selection);
    const givenMembershipLists = new Map();
    const noteLabel = NOTE_LABELS[this.wordGroup.subKind]; // 'Definition';
    const canonicalLabel = CANONICAL_LABELS[this.wordGroup.subKind];
    const showPreserveCase = this.wordGroup.subKind === WordGroupSubKind.VOCAB; // todo: also check to only show when usage is capitalized
    const anonymous = false; // TODO

    const element = this.wordGroup;
    const warnings = this.lintWarnings;
    let usageText = stripUnderscores(
      wordIdRangeToWordStrings(this.selectedWordRange, this.domainWords).join(
        ' '
      )
    );
    if (onlyFirstCapitalized(usageText) && !this.preserveCaseValue) {
      usageText = usageText.toLocaleLowerCase();
    }
    usageText = trimWordGroupUsagePunctuation(usageText);
    if (this.usageTextValue) {
      usageText = this.usageTextValue;
    }

    return (
      <>
        <div className="right-sidepanel-header">
          Word Group Editor - {element.subKind}
        </div>
        {warnings
          ? warnings.map(warning => <LintWarningItem warning={warning} />)
          : null}
        <div className="word-group-editor">
          <div className="word-group-edit-form">
            <WordRangeSelect
              getWordRangeSelection={getSelectedWordRange} // TODO consistent naming
              setWordRangeSelection={setSelectedWordRange} // TODO consistent naming
              displayWordRange={this.sentenceWordRange}
              domainWords={this.domainWords}
              givenMembershipLists={givenMembershipLists}
              wordGroups={this.wordGroups}
              disabled={!this.onMasterLanguageFork}
            />
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: 'max-content' }}></div>
                <div style={{ flexGrow: 1 }}>
                  <MDBInput
                    label={`${noteLabel}:`}
                    disabled={anonymous}
                    type="text"
                    style={{ width: '100%' }}
                    className="word-group-note-edit"
                    value={this.noteTextValue}
                    onChange={e => this.handleNoteTextChange(e)}
                    onKeyPress={(e: any) => this.handleInputFieldKeypress(e)}
                    ref={element =>
                      (this.noteInputEl = element as unknown as HTMLElement)
                    }
                  />
                </div>
              </div>
              {this.wordGroup.subKind !== WordGroupSubKind.TRICKY ? (
                <>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 'max-content' }}></div>
                    <div style={{ flexGrow: 1 }}>
                      <MDBInput
                        label={`${canonicalLabel}:`}
                        disabled={anonymous || !this.onMasterLanguageFork}
                        type="text"
                        style={{ width: '100%' }}
                        className="word-group-note-edit"
                        value={this.canonicalTextValue}
                        onChange={e => this.handleCanonicalTextChange(e)}
                        onKeyPress={(e: any) =>
                          this.handleInputFieldKeypress(e)
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: 'max-content' }}></div>
                    <div style={{ flexGrow: 1 }}>
                      <MDBInput
                        label={'Usage override:'}
                        disabled={anonymous || !this.onMasterLanguageFork}
                        type="text"
                        style={{ width: '100%' }}
                        className="word-group-note-edit"
                        value={this.usageTextValue}
                        onChange={e => this.handleUsageTextChange(e)}
                        onKeyPress={(e: any) =>
                          this.handleInputFieldKeypress(e)
                        }
                      >
                        <div id="usageHelperText" className="form-helper">
                          usage override text will compleletely replace the
                          selected usage text
                        </div>
                        <br />
                      </MDBInput>
                    </div>
                  </div>
                </>
              ) : null}
              {showPreserveCase ? (
                <>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      checked={this.preserveCaseValue}
                      onChange={e => this.handlePreserveCaseChange(e)}
                    />
                    <span>
                      {' '}
                      Force preserve case{' '}
                      <ToolTip tipContent={TipContents.preserveCaseCheckBox}>
                        <label>
                          <InfoIcon />
                        </label>
                      </ToolTip>
                    </span>
                  </div>
                  <div>
                    Displayed usage text:{' '}
                    <span className="vocab">{usageText}</span>
                  </div>
                  <br />
                </>
              ) : null}
              <MDBBtn
                color="info"
                size="sm"
                disabled={anonymous || !this.valuesChanged}
                onClick={() => this.handleWordGroupChangeSubmit()}
              >
                Update
              </MDBBtn>
              <MDBBtn
                color="info"
                size="sm"
                disabled={anonymous}
                onClick={() => this.handleWordGroupDeleteSubmit()}
              >
                Delete
              </MDBBtn>
            </div>
          </div>
          <ConversationView element={element} showChanges />
        </div>
      </>
    );
  }
}
