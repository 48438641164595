import { VideoGuideData } from './../catalog-types';
import { CatalogEntityKind } from '../catalog-types';
import { CatalogEntity, EntityManager } from '../db/catalog-entity-manager';
import { BaseCatalogEntity } from './base-catalog-entity';
import { pick } from 'lodash';
import { VideoGuideManager } from '../db/video-guide-manager';

export const emptyData = {
  kind: CatalogEntityKind.VIDEO_GUIDE,
  id: '',
  slug: '',
  title: '',
  videoIdLandscape: '',
  imageUrlLandscape: '',
  videoIdPortrait: '',
  imageUrlPortrait: '',
  durationSeconds: 0,
  position: 0,
  active: false,
};

export class VideoGuide
  extends BaseCatalogEntity<VideoGuideData>
  implements CatalogEntity<VideoGuideData>
{
  constructor(data?: { id: string }) {
    super(data);
    this.data = { ...emptyData, ...data };
  }

  theManager(): EntityManager<CatalogEntity<VideoGuideData>, VideoGuideData> {
    return VideoGuideManager.getInstance() as unknown as EntityManager<
      CatalogEntity<VideoGuideData>,
      VideoGuideData
    >;
  }

  get name() {
    return this.data.title;
  }

  get navPath(): string {
    return `/video-guides/${this.id}`;
  }

  destroy = async () => {
    await VideoGuideManager.getInstance().delete(this);
  };

  get catalogData(): Partial<VideoGuideData> {
    const result = pick(
      this.data,
      'slug',
      'title',
      'videoIdLandscape',
      'imageUrlLandscape',
      'videoIdPortrait',
      'imageUrlPortrait',
      'durationSeconds',
      'position'
    );
    return result;
  }
}
